<template>
  <b-modal
    :id="id"
    size="lg"
    modal-class="add-timeline-image__modal"
    centered
    hide-header
    hide-footer
    @show="selectedImage = null"
  >
    <div class="position-relative mt-2">
      <button
        class="btn btn-close d-flex justify-content-center align-items-center position-absolute close-image"
        @click="$bvModal.hide(id)"
      >
        <b-icon icon="x" />
      </button>
      <vs-tabs color="#6D75F6">
        <vs-tab label="Gallery">
          <div class="gallery mt-2">
            <div style="height: 28rem">
              <label :for="`${_uid}__input-search`">
                <input
                  type="text"
                  :for="`${_uid}__input-search`"
                  class="search-bar"
                  placeholder="Find a picture"
                  v-model.lazy="galleryKeyword"
                >
              </label>
              <div class="d-flex align-items-center ml-3 mt-2">
                <span class="text-muted powered-by">Powered By </span>
                <img
                  src="@/assets/images/icons/unsplash-logo.svg"
                  alt="Unsplash"
                  class="img-fluid ml-2"
                  width="70"
                />
              </div>
              <div class="container-fluid overflow-auto container-thumbnail mt-3">
                <b-card-group columns>
                  <b-card
                    v-for="image in galleryImages"
                    :key="image.id"
                    class="px-0"
                    :img-src="image.thumbnailSrc"
                    :img-alt="image.name"
                    img-top
                    body-class="p-0 m-0"
                    :class="{ 'image-selected': selectedImage?.id === image.id }"
                    @click="handleClickedGallery(image)"
                  />
                </b-card-group>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Image Generator">
          <div class="gaim">
            <div class="mt-3">
              <AiGeneratorImage
                  v-model="aiGeneratorData"
                  :is-generating="isAiGenerating"
                  @generate="handleGenerateAiImages"
                >
                <template #settings>
                  <div class="ac__settings align-items-center p-3 text-white text-left">
                    <div class="ac__settings-section">
                      <p class="ac__settings-title m-0">Resolution</p>
                      <span class="ac__settings-divider">:</span>
                      <ul class="ac__settings-list d-flex m-0 list-unstyled">
                        <li v-for="resolution in resolutions" :key="resolution.value">
                          <button
                            type="button"
                            class="ac__settings-button ac__settings-button--resolution btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                            :class="{ 'ac__settings-button--active': resolution.value === aiGeneratorData.size }"
                            :title="`Select ${resolution.value}`"
                            @click="aiGeneratorData.size = resolution.value"
                          >
                          <!-- <img :src="resolution.icon" :alt="resolution.value"> &nbsp; -->
                            {{ resolution.label }}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="ac__settings-section">
                      <p class="ac__settings-title m-0">Outputs</p>
                      <span class="ac__settings-divider">:</span>
                      <ul class="ac__settings-list d-flex m-0 list-unstyled">
                        <li v-for="output in outputs" :key="output">
                          <button
                            type="button"
                            class="ac__settings-button ac__settings-button--output btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                            :class="{ 'ac__settings-button--active': output === aiGeneratorData.output }"
                            :title="`Select ${output}`"
                            @click="aiGeneratorData.output = output"
                          >
                            {{ output }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </AiGeneratorImage>
            </div>
            <!-- <p class="agi__text-info mt-2 mb-3">
              <img src="@/assets/images/icons/ic_info_navtt.svg" alt="Information" width="14" />
              &nbsp;you will be charged 5 SWC
            </p> -->

            <ul class="agi__list mt-3 mb-0 list-unstyled">
              <li
                v-for="image in aiImages"
                :key="image.id"
                class="agi__item position-relative"
                :class="{ 'agi__item--selected' : selectedImage?.id === image.id }"
              >
                <img :src="image.src" :alt="image.name" class="agi__item-image" />
                <div class="agi__item-actions d-flex flex-column justify-content-center position-absolute p-4">
                  <button
                    type="button"
                    class="agi__item-action btn text-white"
                    :title="'Preview of ' + image.name"
                    @click="previewImage(image.src, image.name)"
                  >
                    Preview
                  </button>
                  <button
                    type="button"
                    class="agi__item-action btn text-white"
                    :title="'Use ' + image.name"
                    @click="handleClickedGallery(image)"
                  >
                    Use
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </vs-tab>
        <vs-tab label="Upload">
          <div
            class="upload-img"
            @dragover.prevent
            @dragenter.prevent
            @dragleave.prevent
            @drop.prevent
          >
            <div class="container-fluid">
              <label
                :for="`${_uid}__input-upload`"
                class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3"
                @drop="handleUploadImage([...$event.dataTransfer.files][0])"
              >
                <div class="h-100 d-flex align-items-center">
                  <div v-if="!isLoadingUploadImage">
                    <img
                      src="@/assets/images/logo/paper_impor.svg"
                      alt=""
                      class="img-fluid"
                      width="30"
                    />
                    <h5 class="font-weight-normal mt-2" style="color: #6D75F6">Choose File or Drag Here</h5>
                    <h6 class="font-weight-normal text-muted mt-1"><i>format file : .jpg .png</i></h6>
                    <h6 class="font-weight-normal text-muted mt-1"><i>max. file size 1MB</i></h6>
                  </div>
                  <div v-else class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <input
                  v-if="!isLoadingUploadImage"
                  :id="`${_uid}__input-upload`"
                  type="file"
                  accept="image/jpeg, image/png"
                  style="display: none"
                  @change="handleUploadImage($event.target.files[0])"
                />
              </label>
            </div>
          <div class="container-fluid">
            <h6 class="mt-4 font-weight-normal">Past Uploads</h6>
              <div v-if="!isLoadingGetUploadedImages">
                <div v-if="uploadedImages.length > 0" class="row past-upload overflow-auto mt-1 pb-2">
                  <div
                    v-for="(image, index) in uploadedImages"
                    :key="image.id"
                    class="col-3 mt-3"
                  >
                    <div
                      class="card cursor-pointer position-relative"
                      :class="index % 4 == 0 ? 'mr-2 ml-3' : index % 4 == 4 ? 'ml-2 mr-3' : 'mx-2'"
                      :style="selectedImage?.id === image.id && 'border: 3px solid #6D75F6'" @click="handleClickedGallery(image)"
                    >
                      <img
                        :src="image.src"
                        :alt="image.name"
                        class="card-img-top"
                      />
                      <img
                        v-if="selectedImage?.id === image.id"
                        src="@/assets/images/icons/ic_imglib_selected.svg"
                        alt=""
                        class="position-absolute selected-img"
                      />
                      <div class="card-body p-2">
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="mb-0 text-truncate" style="font-size: 10px">
                            {{ image.name || '-' }}
                          </p>
                          <div class="dropdown">
                            <button
                              class="btn btn-transparent p-0 d-flex align-items-start"
                              type="button"
                              :id="`${_uid}__dropdown-${image.id}`"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <b-icon
                                icon="three-dots-vertical"
                                style="color: #FFFFFF"
                                font-scale="0.8"
                              />
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="`${_uid}__dropdown-${image.id}`">
                              <button class="btn btn-transparent pl-1 pr-2 py-0" @click="deleteUploadedImage(image.id)">
                                <div class="d-flex align-items-center">
                                  <span class="ml-2 text-white">Delete</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="past-upload">
                  <div class="d-flex align-items-center justify-content-center h-100">
                    <h6 class="font-weight-normal" style="color: #979797">
                      You don't have any uploads
                    </h6>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row past-upload overflow-auto mt-1 pb-2">
                  <div
                    v-for="index in 4"
                    :key="index"
                    class="col-3 mt-3"
                  >
                    <div class="card" :class="index % 4 == 0 ? 'mr-2 ml-3' : index % 4 == 4 ? 'ml-2 mr-3' : 'mx-2'">
                      <b-skeleton-img card-img="top" />
                      <div class="card-body p-2">
                        <b-skeleton animation="wave" width="30%" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
      <hr style="background-color: #2D2D2D" />
      <div class="float-right">
        <button class="btn btn-md btn-grey mr-2" @click="$bvModal.hide(id)">
          <p class="mb-0 mx-2">Cancel</p>
        </button>
        <button
          class="btn btn-purple btn-md text-white"
          :disabled="selectedImage === null"
          @click="$emit('use:image', selectedImage)"
        >
          <p class="mb-0 mx-2">Use</p>
        </button>
      </div>
      <ImagePreviewModal id="image-preview" :src="imagePreview.src" :alt="imagePreview.alt" />
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";

import randomUUID from '@/helper/uuid';
import { getSearchImage } from "@/services/course/course.service";
import { getImageAiGenerator } from '@/services/ttv/ttv.service.js';
import {postImageLibrary, getImageLibrary, deleteImageLibraryById } from "@/services/contents/contents.service";

import AiGeneratorImage from '@/components/generative-ai-text-to-audio/ai-generator-image';
import ImagePreviewModal from '@/components/modal/ImagePreview';

export default {
  components: {
    AiGeneratorImage,
    ImagePreviewModal,
  },
  props: {
    id: {
      type: String,
      default: 'add-timeline-image',
    },
  },
  emits: ['use:image'],
  data() {
    return {
      selectedImage: null,

      galleryImages: [],
      galleryKeyword: '',
      totalGalleryImagesPerPage: 30,
      currentGalleryPage: 1,

      aiImages: [],
      aiGeneratorData: {
        text: '',
        engine: '1',
        size: '512x512',
        output: 2,
      },
      resolutions: [
        {
          icon : require('@/assets/images/icons/ar_11.svg'),
          label : '256px',
          value : '256x256'
        },
        {
          icon : require('@/assets/images/icons/ar_169.svg'),
          label : '512px',
          value : '512x512'
        },
        {
          icon : require('@/assets/images/icons/ar_45.svg'),
          label : '1024px',
          value : '1024x1024'
        },
        // {
        //   icon : require('@/assets/images/icons/ar_32.svg'),
        //   label : '3:2',
        //   value : '768x512'
        // },
      ],
      outputs: [1, 2, 3],
      isAiGenerating: false,

      uploadedImages: [],
      isLoadingUploadImage: false,
      isLoadingGetUploadedImages: false,

      imagePreview: {
        src: '',
        alt: '',
      },
    };
  },
  watch: {
    galleryKeyword: {
      async handler(newGalleryKeyword) {
        await this.getGalleryImages(newGalleryKeyword);
      },
      immediate: true,
    },
  },
  methods: {
    async getGalleryImages(keyword = 'wave audio') {
      this.galleryImages = [];

      try {
        const res = await getSearchImage(keyword || 'wave audio', this.totalGalleryImagesPerPage, this.currentGalleryPage);

        if (res.status) {
          for (const image of res.data.results) {
            this.galleryImages.push({
              type: 'gallery',
              id: image.id,
              name: image.description,
              src: image.urls.regular,
              thumbnailSrc: image.urls.thumb,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async handleGenerateAiImages() {
      this.aiImages = [];
      this.isAiGenerating = true;

      try {
        const res = await getImageAiGenerator(this.aiGeneratorData);

        if (res.status == 200) {
          for (const [index, image] of Object.entries(res.data)) {
            this.aiImages.push({
              type: 'ai',
              id: randomUUID(),
              name: 'AI Image ' + (Number(index) + 1),
              src: image,
            });
          }

          this.aiGeneratorData.text = '';
        } else if(res.status == 403) {
          this.$bvModal.show('img-quota-modal')
          this.aiGeneratorData.text = '';
        } else {
          this.$bvModal.show('server-error-modal');
        }
      } catch (error) {
        this.$bvModal.show('server-error-modal');
        console.error(error);
      }

      this.isAiGenerating = false;
    },
    async handleUploadImage(file) {
      const { type, size } = file;

      if (type === "image/png" || type === "image/jpeg") {
        if (size > 1000000){
          Swal.fire({
            position: 'top',
            background: '#FF000080',
            imageWidth: 18,
            imageHeight: 18,
            color: 'white',
            title: this.$t("file-size-max-1mb"),
            titleFontSize: '14px',
            showConfirmButton: false,
            toast: true,
            timer: 2000,
            customClass: {
              container: 'p-0'
            },
            didOpen: () => {
              const titleElement = document.querySelector('.swal2-title');
              titleElement.style.display = 'inline-block';
              titleElement.style.setProperty('padding-top', '0px');
              titleElement.style.setProperty('margin', '0px');
              titleElement.style.verticalAlign = 'middle';
              titleElement.style.fontSize = '14px';
              titleElement.style.fontWeight = 'normal';

              const containerElement = document.querySelector('.swal2-popup');
              containerElement.style.display = 'flex';
              containerElement.style.justifyContent = 'center';
              containerElement.style.alignItems = 'center';
              containerElement.style.setProperty('padding', '15px 30px 15px 30px');
              containerElement.style.setProperty('margin-top', '15px');
              containerElement.style.setProperty('width', 'auto');
            },
          });
        } else {
          const reader = new FileReader();

          reader.addEventListener('loadend', () => {
            this.uploadImage(reader.result, file.name);
          });

          reader.readAsDataURL(file);
        }
      } else {
        // this.alertFail("The file must be JPG or PNG format");
      }
    },
    async uploadImage(file, name){
      this.isLoadingUploadImage = true;

      const formData = {
        images: file,
        filename: name,
      };

      try {
        const res = await postImageLibrary(formData);

        if (res.status) {
          await this.getUploadedImages();
        }
      } catch (error) {
        console.error(error);
      }

      this.isLoadingUploadImage = false;
    },
    async getUploadedImages() {
      this.uploadedImages = [];
      this.isLoadingGetUploadedImages = true;

      try {
        const res = await getImageLibrary();

        for (const image of res.data || []) {
          this.uploadedImages.push({
            type: 'upload',
            id: image._id,
            name: image.filename,
            src: image.image_url,
          });
        }
      } catch (error) {
        console.error(error);
      }

      this.isLoadingGetUploadedImages = false;
    },
    async deleteUploadedImage(id) {
      try {
        const res = await deleteImageLibraryById(id);

        if (res.status) {
          await this.getUploadedImages();
        }
      } catch (error) {
        console.error(error);
      }
    },
    previewImage(src, alt) {
      this.imagePreview = { src, alt };
      this.$bvModal.show('image-preview');
    },
    handleClickedGallery(val) {
      if(this.selectedImage == val) {
        this.$emit('use:image', this.selectedImage)
      }
      this.selectedImage = val
    }
  },
  async mounted() {
    await this.getUploadedImages();
  }
};
</script>

<style scoped>
.btn-close {
  width: 15px;
  height: 26px;
  background-color: #EDEDED;
  border-radius: 50%;
}

.close-image {
  z-index: 100;
  right : 5px;
}

.gallery label {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 25px !important;
}

.gallery label:before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 1rem;
  width: 40px;
  background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
  background-size: 20px;
}

.search-bar {
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding-left: 48px !important;
  padding-right: 20px;
  background-color: transparent;
  border-radius: 25px;
  border: 1px solid #2D2D2D;
  color: white;
}

.powered-by {
  font-size: 12px;
}

.container-thumbnail {
  height: 25rem;
}

.container-thumbnail::-webkit-scrollbar, .past-upload::-webkit-scrollbar {
  width: 6px;
}

.container-thumbnail::-webkit-scrollbar-track, .past-upload::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

.container-thumbnail::-webkit-scrollbar-thumb, .past-upload::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  background-clip: padding-box;
  border-radius: 4px;
}

.container-thumbnail::-webkit-scrollbar-thumb:hover, .past-upload::-webkit-scrollbar-thumb:hover {
  background: #888888;
}

.image-selected {
  border: 3px solid #6D75F6;
  border-radius: 10px;
}

.upload-img, .gaim {
  height : 28rem;
}

.ac__settings {
  display: grid;
  grid-template-columns: 70px 15px auto;
  gap: 8px 0;
  background-color: #2D2D2D;
  border-radius: 8px;
  font-size: 12px;
}

.ac__settings-section {
  display: contents;
}

.ac__settings-list {
  gap: 6px;
}

.ac__settings-button {
  gap: 4px;
  background-color: #414141;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ac__settings-button:not(.ac__settings-button--active):hover {
  background-color: #515151;
}

.ac__settings-button--active {
  background-color: #6D75F6;
}

.ac__settings-button--resolution {
  min-width: 50px;
}

.ac__settings-button--ratio {
  min-width: 50px;
}

.ac__settings-ratio-box {
  height: 12px;
  border-radius: 2px;
  border: 1px solid #FFFFFF;
}

.ac__settings-button--output {
  min-width: 40px;
}

.agi__text-info {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.agi__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  gap: 8px;
  padding-right: 24px;
  margin-right: 18px;
  overflow-y: auto;
}

.agi__item {
  width: 100%;
  height: 125px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: background-color 0.25s, border 0.25s;
}

.agi__item--selected {
  border-color: #6D75F6;
}

.agi__item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.agi__item-actions {
  opacity: 0;
  visibility: hidden;

  inset: 0;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.25s, visibility 0.25s;
}

.agi__item:hover .agi__item-actions {
  opacity: 1;
  visibility: visible;
}

.agi__item-action {
  padding: 2px;
  background-color: #6D75F6;
  font-size: 12px;
  transition: background-color 0.25s;
}

.agi__item-action:hover {
  background-color: #545EED;
}

.upload-img .btn-impor-custom {
  height : 10rem;
}

.btn-impor-custom {
  border-radius: 10px;
  border: 2px dashed #2D2D2D;
}

.past-upload {
  height: 15rem;
}

.past-upload .card {
  background-color: #2D2D2D;
  border-radius: 8px;
}

.past-upload .card img {
  height: 109px;
  border-radius: 8px 8px 0px 0px !important;
}

.card {
  border-radius: 6px;
}

.selected-img {
  top: 10px;
  right: 10px;
  width: 11px;
  height: 11px !important;
}

.dropdown span, .dropdown-menu span {
  font-size: 12px;
}

.dropdown .btn {
  box-shadow: none !important;
}

.btn-transparent {
  background-color: transparent !important;
  box-shadow: none;
  color: white !important;
}

.dropdown-menu {
  min-width: 5rem;
  background-color: #2D2D2D;
  box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.75);
}

.btn-grey {
  background-color: #2D2D2D !important;
  border: 0px !important;
  color: white;
  transition: background-color 0.5s;
}

.btn-grey:hover {
  background-color: #414141 !important;
}

.btn-purple {
  background-color: #6D75F6;
  border: 1px solid #6D75F6;
  color: white;
}
</style>

<style>
.add-timeline-image__modal .vs-tabs--li:nth-child(2) button {
  background-image:
    url('~@/assets/images/icons/ic_generator_star_default.svg'),
    url('~@/assets/images/icons/ic_generator_star_default.svg');
  background-repeat: no-repeat;
  background-position: left 13%, right 68%;
  background-size: 14px, 8px;
}

.add-timeline-image__modal .vs-tabs--li:nth-child(2).activeChild button {
  background-image:
    url('~@/assets/images/icons/ic_generator_star_active.svg'),
    url('~@/assets/images/icons/ic_generator_star_active.svg');
  background-position: left 8%, right 63%;
}
</style>

<style>
.add-timeline-image__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
</style>
