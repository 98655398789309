<template>
    <div>
        <b-modal id="pvi-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
            <div class="container-fluid px-4 py-4 text-left text-white">
                <h3 class="pvi__title">{{ $t('instructions') }}</h3>
                <h4 class="pvi__subtitle mt-3">{{ $t('instructions--p') }}</h4>
                <ol class="pvi__list d-flex flex-column pl-4 mt-2">
                    <li>{{ $t('instructions--li1') }}</li>
                    <li>{{ $t('instructions--li2') }}</li>
                    <li>{{ $t('instructions--li3') }}</li>
                    <li>{{ $t('instructions--li4') }}</li>
                </ol>
                <div class="d-flex justify-content-center mt-4">
                    <button class="btn pvi__countinue--button" @click="$emit('continue')">{{ $t('continue') }}</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        clickUpgrade: {
            type: Function,
            default: null,
        },
    },
    methods: {

    },
};
</script>

<style scoped>
.pvi__title {
    font-size: 24px;
}

.pvi__subtitle {
    font-size: 16px;
    font-weight: normal;
}

.pvi__list {
    /* list-style-type: disc; */
    gap: 6px;
    /* list-style-image: url('~@/assets/images/dot_li.svg') */
}

.pvi__list li {
    font-size: 16px;
}

.pvi__countinue--button {
    background-color: #6D75F6;
    padding: 5px 20px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;

    transition: background-color 0.25s;
}

.pvi__countinue--button:hover {
    background-color: #545EED;
    font-size: 16px;
}
</style>

<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#pvi-modal .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#pvi-modal .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
</style>