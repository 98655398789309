import axios from "../../config/axios"
import { base_url_machine_learning } from "../../config/base_url";

export async function getMasterLanguages(params) {
    return axios({
        method: 'GET',
        url: base_url_machine_learning + 'master_languages',
        params: params
    })
    .then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message
        }
        return data;
    })
    .catch((error) => {
        const data = error.response.data

        return data;
    })
}

export async function getMasterLanguagesAutodub() {
    return axios({
        method: 'GET',
        url: base_url_machine_learning + 'master_languages?autodub=true',
    })
    .then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message
        }
        return data;
    })
    .catch((error) => {
        const data = error.response.data

        return data;
    })
}