<template>
  <div class="qai d-flex justify-content-center align-items-center">
    <div v-if="audioUrl" class="qai__audio-wrapper d-flex justify-content-center">
      <div class="qai__audio d-flex justify-content-center align-items-center p-3">
        <div class="flex-grow-1">
          <AudioPlayer :src="audioUrl" name="Audio 1" />
        </div>
      </div>
      <button
        type="button"
        class="qai__remove-button btn d-flex justify-content-center align-items-center"
        title="Remove Audio"
        @click="audioUrl = ''"
      >
        <img src="@/assets/images/icons/ic_trash_fill.svg" alt="" height="16" />
      </button>
    </div>

    <button
      v-else
      type="button"
      class="qai__insert-button btn d-flex flex-column justify-content-center align-items-center py-3 text-white"
      title="Upload or Voice Record"
      @click="$modal.show(`add-audio-modal-${_uid}`)"
    >
      <span class="qai__insert-icon d-flex justify-content-center align-items-center">
        <font-awesome-icon icon="fa-solid fa-volume-high" />
      </span>
      Upload or Voice Record
    </button>

    <AddAudioModal :name="`add-audio-modal-${_uid}`" v-model="audioUrl" />
  </div>
</template>

<script>
import AudioPlayer from '@/components/audio-player/AudioPlayer';

import AddAudioModal from '../../modals/AddAudioModal';

export default {
  components: {
    AudioPlayer,
    AddAudioModal,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  data() {
    return {
      audioUrl: this.value,
    };
  },
  watch: {
    audioUrl(newAudioUrl) {
      this.$emit('input', newAudioUrl);
    },
    value(newValue) {
      this.audioUrl = newValue;
    },
  },
};
</script>

<style scoped>
.qai {
  height: 100%;
}

.qai__audio-wrapper {
  width: min(500px, 100%);
  height: 100%;
  gap: 16px;
}

.qai__audio {
  width: 100%;
  background-color: #2D2D2D;
  border-radius: 10px;
}

.qai__audio-player {
  width: 100%;
}

.qai__remove-button {
  width: 40px;
  height: 40px;
  background-color: #2D2D2D;
  border-radius: 50%;
  transition: background-color 0.25s;
}

.qai__remove-button:hover {
  background-color: #F34D4D;
}

.qai__insert-button {
  width: 100%;
  height: 100%;
  gap: 8px;
  background-color: #2D2D2D;
  border-radius: 10px;
  font-size: 18px;
  transition: background-color 0.25s;
}

.qai__insert-button:hover {
  background-color: #3D3D3D;
}

.qai__insert-icon {
  color: #FFFFFF;
  font-size: 38px;
}
</style>
