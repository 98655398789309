<template>
	<section class="vg d-flex flex-column">
		<header class="vg__header d-flex align-items-center mb-2 justify-content-between pl-4 pr-3 w-100">
			<div class="vg__keyword-wrapper position-relative w-100">
				<font-awesome-icon
					icon="fa-solid fa-magnifying-glass"
					class="vg__keyword-icon position-absolute"
				/>
				<input
					type="text"
					v-model="keyword"
					class="vg__keyword-field"
					:title="$t('find-video--placeholder')"
					:placeholder="$t('find-video--placeholder')"
				/>
			</div>
		</header>

		<ul class="vg__main-list mb-0 list-unstyled pl-4" :class="{ 'vg__main-list-hide-shadow': scrolledBottom }" ref="mainList" @scroll="checkScroll">
			<li v-for="(data, index) in video" :key="index" :class="{ 'vg__video-button-image-potrait' : data.orientation == 'portrait', 'vg__video-button-image-landscape': data.orientation == 'landscape' }">
				<button type="button" class="vg__video-button position-relative btn p-0" @click="handleChooseVideo(data)">
					<img :src="data.video_thumbnail" :alt="data.video_name" class="vg__video-button-image">
					<div class="vg__video-button-duration position-absolute">
						<span class="vg__video-button-duration-text">{{ formatDuration(data.duration) }}</span>
					</div>
				</button>
			</li>
		</ul>
		<p v-if="video.length == 0" class="m-0 text-center text-white">Sorry, we do not have what you are looking for. <br> Try other keywords</p>
	</section>
</template>

<script>
import { trimVideos, getStockVideo } from "@/services/my-assets/my-assets.service"

export default {
	data() {
		return {
			keyword: null,
			choosedVideo: null,
			scrolledBottom: false,
			isTrim: false,
			activeOrientation: 'landscape',
			video: []
		}
	},
	watch: {
		keyword: {
			async handler(newKeyword) {
				await this.searchVideo(newKeyword)
			},
			immediate: true
		},
	},
	methods: {
		checkScroll() {
			const mainList = this.$refs.mainList;
			this.scrolledBottom = mainList.scrollTop + mainList.clientHeight === mainList.scrollHeight;
		},
		async searchVideo(keyword) {
			await getStockVideo(keyword || '')
			.then((response) => {
				if(response.status) {
					this.video = response.data
				}
			})
		},
		formatDuration(duration) {
			if (!duration) {
				return '00:00';
			}

			const durationValue = parseInt(duration, 10);

			if (isNaN(durationValue)) {
				return '00:00';
			}

			// Menghitung menit dan detik
			const minutes = Math.floor(durationValue / 60);
			const seconds = durationValue % 60;

			// Mengonversi menjadi format '00:00'
			const formattedMinutes = minutes.toString().padStart(2, '0');
			const formattedSeconds = seconds.toString().padStart(2, '0');

			return `${formattedMinutes}:${formattedSeconds}`;
		},
		handleChooseVideo(data) {
			if(data) {
				this.$emit('click:preview-trim-stock-video', data)
			}
		},
		handleTrimVideo(val) {
			this.isTrim = true
			if(val.isTrim) {
				let formData = {
					id_video : this.choosedVideo.id,
					start : val.durationStartCalculate || 0,
					end : val.durationEnd,
					save_to_asset : "False",
					from_stock : "True"
				}

				trimVideos(formData)
				.then((response) => {
					if(response.status == 200) {
						let usedVideo = {
							type: 'video',
							id: response.data.id_video,
							name: this.choosedVideo.name ? this.choosedVideo.name : 'Video',
							src: this.choosedVideo.video_thumbnail,
							videoDurationOrigin: val.durationEnd - val.durationStartCalculate || 0
						}
						this.$emit('use:video', usedVideo)
						this.$bvModal.hide('vtp-modal-library')
					}
					this.isTrim = false
				})
			} else {
				let usedVideo = {
					type: 'video',
					id: this.choosedVideo.id,
					name: this.choosedVideo.name ? this.choosedVideo.name : 'Video',
					src: this.choosedVideo.video_thumbnail,
					videoDurationOrigin: val.durationEnd - val.durationStartCalculate || 0
				}
				this.isTrim = false
				this.$emit('use:video', usedVideo)
				this.$bvModal.hide('vtp-modal-library')
			}
		}
	},
	mounted() {
		this.searchVideo()
	}
}
</script>

<style scoped>
.vg {
  gap: 8px;
  min-height: 0;
}

.vg__header {
	gap: 4px;
}

.vg__keyword-wrapper {
  border: 1px solid rgba(88, 88, 89, 0.50);
  border-radius: 36px;
  overflow: hidden;
  transition: border 0.25s;
  background-color: #2D2D2D;
  height: 41px;
}

.vg__keyword-wrapper:focus-within {
  border-color: #555555;
}

.vg__keyword-icon {
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #C4C4C4;
}

.vg__keyword-field {
  width: 100%;
  padding: 7px 7px 7px 30px;
  background: none;
  border: none;
  font-size: 12px;
  height: 100%;
  color: #FFFFFF;
}

.vg__keyword-field::placeholder {
  color: #555555;
}

.vg__rectangle {
	border-radius: 3px;
	border: 1px solid #D9D9D9;
	cursor: pointer;

	transition: border 0.25s;
}

.vg__rectangle {
	width: 38px;
	height: 20px;
}

.vg__rectangle--vertical {
	width: 34px;
	height: 20px;
	transform: rotate(-90deg);
}

.vg__rectangle:not(:disabled):hover, .vg__rectangle--active {
	border-radius: 3px;
	border: 1px solid #6D75F6;
}

.vg__orientation .vg__text {
	font-size: 8px;
	color: #C4C4C4;
}

.vg__main-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* Adjust the column width as needed */
	gap: 8px;
	padding-right: 10px;
	overflow-y: auto;
	scrollbar-gutter: stable;
}

.vg__main-list::after {
  opacity: 1;
  visibility: visible;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
  transition: opacity 0.25s, visibility 0.25s;
}

.vg__main-list-hide-shadow::after {
	opacity: 0;
	visibility: hidden;
}

.vg__main-list li {
  display: flex;
}

.vg__video-button {
  flex: 1;
  overflow: hidden;
  border-radius: 6px;
  border: 2px solid transparent;
  position: relative;
}

.vg__video-button-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.vg__video-button-duration {
  bottom: 5px;
  right: 5px;
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
}

.vg__main-list li.vg__video-button-image-potrait {
  grid-row: span 3;
}

.vg__main-list li.vg__video-button-image-landscape {
  grid-row: span 1;
}

.vg__video-button-duration {
	border-radius: 11.5px;
	background: rgba(0, 0, 0, 0.30);
	backdrop-filter: blur(3px);
	right: 10px;
	bottom: 10px;
	padding: 0 5px;
}

.vg__video-button-duration-text {
	font-size: 10px;
	color: white;
	font-weight: 500;
}
</style>