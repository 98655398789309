import axios from "../../config/axios"
import { base_url_machine_learning, base_url_pexels } from '../../config/base_url'
import { access_key } from "../../config/pexels";

export async function checkStatusApi(){
    return axios({
        method : 'GET',
        url : base_url_machine_learning + 'jobs_status/status_api'
      }).then((res) => {
        const data = {
            status:res.data.status,
            data:res.data.data,
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }
        return data;
      })
}

export async function getStockVideo(search, orientation) {
  try {
    const url = base_url_pexels + 'videos/search?query=' + search + '&orientation=' + orientation;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: access_key
      }
    });

    const data = await response.json();

    if (response.ok) {
      return {
        status: true,
        data: data
      };
    } else {
      return {
        status: false,
        response: data.errors
      };
    }
  } catch (error) {
    return {
      status: false,
      response: error
    };
  }
}