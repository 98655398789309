<template>
  <section class="voice-section d-flex flex-column p-4">
    <VoiceSectionCard
      name="AI Voices"
      :icon-src="require('@/assets/images/icons/ic_magic_wand_default.svg')"
      :active-icon-src="require('@/assets/images/icons/ic_magic_wand_active.svg')"
      :visible="aiVoiceShow"
      @update:show="(newVal) => aiVoiceShow = newVal"
    >
      <div class="voice-section__ai-voices d-flex flex-column">
        <!-- <AIGenerator
          ref="aiGenerator"
          v-model="aiGeneratorData"
          class="voice-section__ai-generator"
          :placeholders="aiGeneratorPlaceholders"
          :disable-options="{
            settings: true,
          }"
          :disable-generate="!aiGeneratorData.text"
          :is-generating="isAiGenerating"
          @click:voice-type="$bvModal.show('select-voice-type--ai-generator')"
          @generate="handleGenerateAiGenerator"
        /> -->
        <ul class="voice-section__ai-voices-list d-flex flex-column list-unstyled">
          <li v-for="(aiVoice, index) in internalAiVoices" :key="aiVoice.id">
            <ScriptEditor
              ref="scriptEditors"
              :text="aiVoice.text"
              :character-name="aiVoice.voiceType?.character.name"
              :character-avatar-src="aiVoice.voiceType?.character.avatarSrc"
              :action-state="aiVoice.actionState"
              :disable-action="aiVoice.disableAction || disabledAction"
              @change:text="(newText) => handleChangeAiVoiceText(index, newText)"
              @click:voice-type="$bvModal.show(`select-voice-type--script-editor-${index}`)"
              @click:action="(state) => handleClickAiVoiceAction(index, state)"
              @click:delete="aiVoices.splice(index, 1), $emit('delete-se-internal', aiVoice.id)"
            />
            <SelectVoiceTypeModal
              :id="`select-voice-type--script-editor-${index}`"
              :selected-language="aiVoice.voiceType?.language"
              :selected-character="aiVoice.voiceType?.character"
              :initial-languages="languages"
              @change:voice-type="(newVoiceType) => handleChangeAiVoiceType(index, newVoiceType)"
            />
          </li>
        </ul>
        <audio ref="aiAudioPlayer" @timeupdate="updateCurrentTime"/>
      </div>
    </VoiceSectionCard>

    <VoiceSectionCard
      name="Personal Voice"
      :icon-src="require('@/assets/images/icons/ic_microphone_default.svg')"
      :active-icon-src="require('@/assets/images/icons/ic_microphone_active.svg')"
      :visible="personalVoicesShow"
      @update:show="(newVal) => personalVoicesShow = newVal"
    >
      <div class="voice-section__personal-voice d-flex flex-column text-left">
        <div
          v-if="personalVoices?.length > 0"
          class="voice-section__voice-collection flex-grow-1 d-flex flex-column"
        >
          <header class="voice-section__voice-collection-header d-flex justify-content-between align-items-center">
            <p class="voice-section__voice-collection-title m-0">Choose a model</p>
            <button
              v-if="personalVoices.length > 0"
              type="button"
              class="voice-section__voice-collection-reset btn px-0"
              title="Reset Voice Clone"
              @click="$emit('reset:personal-voices')"
              :disabled="loadingPv"
            >
              Reset
            </button>
          </header>
          <ul class="voice-section__voice-collection-list d-flex flex-column mb-3 list-unstyled">
            <li
              v-for="personalVoice in personalVoices"
              :key="personalVoice.id"
              ref="voiceCollectionItem"
            >
              <button
                type="button"
                class="voice-section__voice-collection-button btn d-flex align-items-center p-3"
                :class="{ 'voice-section__voice-collection-button--selected': selectedPersonalVoice === personalVoice.id }"
                :title="`Select ${personalVoice.character_name}`"
                @click="selectedPersonalVoice = personalVoice.id"
              >
                <div
                  class="voice-section__voice-collection-checkbox d-inline-flex justify-content-center align-items-center"
                  :class="{ 'voice-section__voice-collection-checkbox--checked': selectedPersonalVoice === personalVoice.id }"
                >
                  <font-awesome-icon
                    v-if="selectedPersonalVoice === personalVoice.id"
                    icon="fa-solid fa-check"
                  />
                </div>
                <AudioPlayer
                  :src="personalVoice.path_voice"
                  :name="personalVoice.character_name"
                  size="small"
                  class="flex-grow-1"
                  @play="(pauseAudio, audioId) => handlePlayAudioPlayer(pauseAudio, audioId)"
                />
              </button>
            </li>
          </ul>
          <div class="voice-section__textarea-container d-flex flex-column mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <label
                for="input-script"
                class="voice-section__textarea-label m-0"
              >
                Describe your script
              </label>
              <button
                v-if="dataPv && dataPv.id"
                type="button"
                class="vs__play-button btn d-inline-flex justify-content-center align-items-center ml-auto text-white"
                :title="dataPv.actionState == 'play' ? 'Play' : 'Pause' "
                @click="$emit('play-pvg', dataPv.id)"
              >
                <font-awesome-icon
                  :icon="`fa-solid ${dataPv.actionState === 'pause' ? 'fa-pause' : 'fa-play'}`"
                />
              </button>
            </div>
            <div class="ta--wrapper px-4 py-3 ">
              <textarea
                v-model="script"
                id="input-script"
                class="voice-section__textarea-field text-white"
                spellcheck="false"
                :placeholder="activePlaceholderPv"
                maxlength="250"
              />
              <p class="text-right m-0" style="font-size:12px">{{ script.length }}/250</p>
            </div>
          </div>
          <footer class="voice-section__voice-collection-footer d-flex justify-content-end mt-auto">
            <button
              type="button"
              class="voice-section__record-button btn"
              title="Clone Voice"
              @click="$emit('click:record')"
              :disabled="loadingPv"
            >
              Clone Voice
            </button>
            <!-- <button
              type="button"
              class="voice-section__use-button btn text-white"
              title="Use Voice"
              :disabled="!selectedPersonalVoice"
              @click="$emit('click:use', selectedPersonalVoice)"
            >
              Use
            </button> -->
            <button
              type="button"
              class="voice-section__use-button btn text-white"
              title="Generate Voice"
              @click="$emit('click:generate', selectedPersonalVoice, script)"
              :disabled="loadingPv || !selectedPersonalVoice || !script || disabledAction"
            >
              {{ loadingPv ? 'Generating...' : 'Generate' }}
            </button>
          </footer>
        </div>
        <div
          v-else
          class="voice-section__empty-voices flex-grow-1 d-flex flex-column justify-content-center align-items-center text-center"
        >
          <p class="voice-section__empty-voices-message m-0">
            You don't have any voices.
            <br />
            Clone your voice now.
          </p>
          <button
            type="button"
            class="voice-section__record-button btn"
            title="Clone Voice"
            @click="$emit('click:record')"
          >
            Clone Voice
          </button>
        </div>
      </div>
    </VoiceSectionCard>

    <SelectVoiceTypeModal
      id="select-voice-type--ai-generator"
      :selected-language="aiGeneratorData.voiceType?.language"
      :selected-character="aiGeneratorData.voiceType?.character"
      @get:languages="(newLanguages) => languages = newLanguages"
      @change:voice-type="(newVoiceType) => aiGeneratorData.voiceType = newVoiceType"
    />
    <QuotaHabisModal />
    <!-- <ServerErrorModal /> -->
    <SentimentAnalysisWarningModal @hide="handleHideSentimentAnalysisWarning" />
  </section>
</template>

<script>
import randomUUID from '@/helper/uuid';
import { base_url_machine_learning } from '@/config/base_url';
import { getGeneratedTextToAudio, getGeneratedTextToAudioSSML, getGeneratedTextToAudioWithAgreements } from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
import { EventBus } from '@/helper/event-bus.js';

import VoiceSectionCard from './Card';
// import AIGenerator from '../ai-generator';
import ScriptEditor from '../script-editor';
import AudioPlayer from '@/components/audio-player/AudioPlayer';
import SelectVoiceTypeModal from '@/components/modal/SelectVoiceType';
import QuotaHabisModal from '@/components/modal/QuotaHabis';
// import ServerErrorModal from '@/components/modal/ServerError';
import SentimentAnalysisWarningModal from '@/components/modal/SentimentAnalysisWarning';

export default {
  components: {
    VoiceSectionCard,
    // AIGenerator,
    ScriptEditor,
    AudioPlayer,
    SelectVoiceTypeModal,
    QuotaHabisModal,
    // ServerErrorModal,
    SentimentAnalysisWarningModal,
  },
  props: {
    aiVoices: {
      type: Array,
      required: true,
    },
    personalVoices: {
      type: Array,
      required: true,
    },
    loadingPv: {
      type: Boolean,
      default: false,
    },
    deletedSeiId: {
      type: String,
      default: null
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    generatedPvld: {
      type: Object,
      default: () => {}
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click:record', 'click:use', 'change:ai-voices'],
  data() {
    return {
      internalAiVoices: this.aiVoices,
      audioCurrentTime: this.currentTime,
      dataPv: this.generatedPvld,

      aiGeneratorPlaceholders: [
        "Introducing our new product - the XYZ Widget! It's sleek, powerful, and designed to make your life easier.",
        "Learn how to make delicious chocolate chip cookies from scratch with this step-by-step recipe.",
        "Explore the breathtaking beauty of the majestic mountains in our travel destination.",
      ],
      aiGeneratorData: {
        text: '',
        scriptType: {
          id: 'lessons',
          name: 'LESSON',
        },
        voiceType: null,
      },
      isAiGenerating: false,
      aiVoiceShow: false,
      personalVoicesShow: true,

      languages: [],

      selectedPersonalVoice: null,
      latestPlayingAudioId: null,
      pauseLatestPlayingAudio: null,
      typingInterval: null,
      pvPlaceholder: "A group of Indonesian students harness the power of Soca AI to create engaging educational content, bridging the learning gap in their nation with creativity and innovation.",
      activePlaceholderPv: "",

      script: '',
    };
  },
  created() {
    EventBus.$on('pauseAudioAi', () => {
      this.handlePauseAudioAi();
    });
  },
  watch: {
    aiVoices: {
      handler(newAiVoices) {
        this.internalAiVoices = newAiVoices;
      },
      deep: true,
    },
    internalAiVoices: {
      handler(newInternalAiVoices) {
        if (newInternalAiVoices.length < 1) {
          this.internalAiVoices.push({
            id: randomUUID(),
            courseId: '',
            ttsId: '',
            text: '',
            scriptType: 'lessons',
            voiceType: null,
            src: null,
            duration: null,
            actionState: 'generate',
            disableAction: true,
            generateText: null,
            generateSpeaker: null,
          });
        }

        this.$emit('change:ai-voices', newInternalAiVoices);
      },
      deep: true,
      immediate: true,
    },
    deletedSeiId(newVal) {
      this.handleDeletedSeiId(newVal)
    },
    currentTime(newCurrentTime) {
      this.audioCurrentTime = newCurrentTime
    },
    aiVoiceShow(newVal) {
      if(newVal){
        this.personalVoicesShow = false
      }
    },
    personalVoicesShow(newVal) {
      if(newVal){
        this.aiVoiceShow = false
      }
    },
    generatedPvld(newVal) {
      this.dataPv = newVal
    }
  },
  methods: {
    async handleGenerateAiGenerator() {
      this.isAiGenerating = true;

      const formData = {
        course_id: '',
        text: this.aiGeneratorData.text,
        context: this.aiGeneratorData.scriptType.id,
        params: {
          speaker: this.aiGeneratorData.voiceType.character.name.toLowerCase(),
          pitch: 0,
          speed: 1,
          emotion: 'normal',
          regenerate: 'true',
          lang: this.aiGeneratorData.voiceType.language.id,
        },
      };

      try {
        const res = await getGeneratedTextToAudio(formData);

        if (res.status === 403) {
          this.$bvModal.show('audio-quota');
        } else if (res.status === 402 || res.status === 500) {
          this.$bvModal.show('server-error-modal');
        } else if (res.status === 406) {
          sessionStorage.setItem('sentimentFormData', JSON.stringify(formData));
          sessionStorage.setItem('sentimentSource', JSON.stringify({
            inputType: 'ai-generator',
            data: this.aiGeneratorData,
          }));
          sessionStorage.setItem('isAgreedSentiment', false);
          this.$bvModal.show('sentiment-analysis-warning');
        } else {
          await this.populateGeneratedAiData({
            reader: res.body.getReader(),
            scriptType: this.aiGeneratorData.scriptType.id,
            voiceType: this.aiGeneratorData.voiceType,
          });
          this.aiGeneratorData.text = '';
        }
      } catch (error) {
        console.log(error);
        this.$bvModal.show('server-error-modal');
      }

      this.isAiGenerating = false;
    },
    async populateGeneratedAiData({ data, reader, scriptType, voiceType }) {
        let index = 0;

        if (this.internalAiVoices.length > 1 ||this.internalAiVoices[0]?.text.trim() !== '') {
          this.internalAiVoices.push({
            id: randomUUID(),
            courseId: '',
            ttsId: '',
            text: '',
            scriptType: scriptType ?? 'lessons',
            voiceType: voiceType ?? null,
            src: null,
            duration: null,
            actionState: 'loading',
            disableAction: true,
            generateText: null,
            generateSpeaker: null,
          });
          index = this.internalAiVoices.length - 1;
        }

        this.internalAiVoices[index].courseId = data?.course_id ?? '';
        this.internalAiVoices[index].ttsId = data?.tts_id ?? '';
        this.internalAiVoices[index].text = data?.text ?? '';
        this.internalAiVoices[index].scriptType = scriptType ?? 'lessons';
        this.internalAiVoices[index].voiceType = voiceType ?? null;
        this.internalAiVoices[index].src = data?.path ? base_url_machine_learning + data.path : null;
        this.internalAiVoices[index].duration = data?.['length'] ?? null;
        this.internalAiVoices[index].actionState = 'loading';
        this.internalAiVoices[index].disableAction = true;
        this.internalAiVoices[index].generateText = data?.text ?? null;
        this.internalAiVoices[index].generateSpeaker = voiceType?.character?.name ?? null;

        this.$nextTick(() => {
          this.$refs.scriptEditors[index].$el.scrollIntoView();
        });

        if (reader) {
          let isReading = true;

          while (isReading) {
            const { value, done } = await reader.read();

            if (done) {
              isReading = false;
              break;
            }

            if (value) {
              this.$refs.scriptEditors[index].$refs.textarea.focus();
              this.internalAiVoices[index].text += new TextDecoder('utf-8').decode(value).replaceAll('\n', '');
              this.$refs.scriptEditors[index].$refs.textarea.selectionStart = this.internalAiVoices[index].text.length;
              this.$refs.scriptEditors[index].$refs.textarea.scrollTop = this.$refs.scriptEditors[index].$refs.textarea.scrollHeight - this.$refs.scriptEditors[index].$refs.textarea.offsetHeight;
            }
          }
        }

        if (this.internalAiVoices[index].text.trim() !== '') {
          this.internalAiVoices[index].disableAction = false;
        }
        this.internalAiVoices[index].actionState = 'generate';

        return Promise.resolve();
    },
    handleChangeAiVoiceText(index, newText) {
      this.internalAiVoices[index].text = newText;
      this.internalAiVoices[index].disableAction = newText.trim() === '';
      this.handleAiVoiceActionState(index);
    },
    handleChangeAiVoiceType(index, newVoiceType) {
      this.internalAiVoices[index].voiceType = newVoiceType;
      this.handleAiVoiceActionState(index);
    },
    handleAiVoiceActionState(index) {
      if (this.internalAiVoices[index].actionState !== 'loading') {
        if (
          this.internalAiVoices[index].src
          && this.internalAiVoices[index].text === this.internalAiVoices[index].generateText
          && this.internalAiVoices[index].voiceType.character.name === this.internalAiVoices[index].generateSpeaker
        ) {
          this.internalAiVoices[index].actionState = 'play';
        } else {
          this.internalAiVoices[index].actionState = 'generate';
        }
      }
    },
    async handleClickAiVoiceAction(index, state) {
      if (state === 'generate') {
        await this.handleGenerateAiVoice(index);
      } else if (state === 'play') {
        this.internalAiVoices[index].actionState = 'loading';

        if (this.$refs.aiAudioPlayer.src !== this.internalAiVoices[index].src) {
          this.$refs.aiAudioPlayer.src = this.internalAiVoices[index].src;
        }

        await this.$refs.aiAudioPlayer.play();
        this.$emit('update-needle-timeline', this.internalAiVoices[index].id)

        const playMediaEvent = new CustomEvent('play:media', {
          detail: {
            componentId: this.internalAiVoices[index].id,
            name: 'ttv::script-editor'
          },
        });
        document.dispatchEvent(playMediaEvent);

        this.$refs.aiAudioPlayer.addEventListener('ended', () => {
          if (this.$refs.aiAudioPlayer.src === this.internalAiVoices[index].src) {
            this.internalAiVoices[index].actionState = 'play';
            this.$emit('reset:current-time')
          }
        }, { once: true });

        const pauseWhenAnotherMediaPlay = async (e) => {
          if (e.detail.componentId !== this.internalAiVoices[index].id) {
            this.internalAiVoices[index].actionState = 'loading';
            if (e.detail.name !== 'ttv::script-editor') {
              await this.$refs.aiAudioPlayer.pause();
            }
            this.internalAiVoices[index].actionState = 'play';
            document.removeEventListener('play:media', pauseWhenAnotherMediaPlay);
          }
        }
        document.addEventListener('play:media', pauseWhenAnotherMediaPlay);

        this.internalAiVoices[index].actionState = 'pause';
      } else if (state === 'pause') {
        this.internalAiVoices[index].actionState = 'loading';
        await this.$refs.aiAudioPlayer.pause();
        this.internalAiVoices[index].actionState = 'play';
      }
    },
    async handleGenerateAiVoice(index) {
      const voicesOnTimelineInStorage = await JSON.parse(sessionStorage.getItem('ttv::voices-on-timeline'));
      const voicesOnTimelineInStorageCourseId = voicesOnTimelineInStorage && voicesOnTimelineInStorage.length > 0 ? voicesOnTimelineInStorage[0].course_id : null
      const prevActionState = this.internalAiVoices[index].actionState;

      this.internalAiVoices[index].actionState = 'loading';

      const formData = {
        course_id: voicesOnTimelineInStorageCourseId || this.internalAiVoices[0]?.courseId || "",
        text: this.internalAiVoices[index].text,
        context: this.internalAiVoices[index].scriptType,
        params: {
          speaker: this.internalAiVoices[index].voiceType.character.name.toLowerCase(),
          pitch: 0,
          speed: 1,
          emotion: 'normal',
          regenerate: 'True',
          lang: this.internalAiVoices[index].voiceType.language.id,
        },
      };

      try {
        const res = await getGeneratedTextToAudioSSML(formData);

        if (res.status === 403) {
          this.$bvModal.show('audio-quota');
        } else if (res.status === 402 || res.status === 500) {
          this.$bvModal.show('server-error-modal');
        } else if (res.status === 406) {
          sessionStorage.setItem('sentimentFormData', JSON.stringify(formData));
          sessionStorage.setItem('sentimentSource', JSON.stringify({
            inputType: 'script-editor',
            data: this.internalAiVoices[index],
            index,
          }));
          sessionStorage.setItem('isAgreedSentiment', false);
          this.$bvModal.show('sentiment-analysis-warning');
        } else {
          const { data } = res;
          await this.populateGeneratedAiVoice({ index, data, speaker: this.internalAiVoices[index].voiceType.character.name });
          this.internalAiVoices[index].actionState = 'play';
        }
      } catch (error) {
        console.log(error);
        this.$bvModal.show('server-error-modal');
      }

      if (this.internalAiVoices[index].actionState === 'loading') {
        this.internalAiVoices[index].actionState = prevActionState;
      }

      return Promise.resolve();
    },
    async populateGeneratedAiVoice({ index, data, speaker = 'Sovia' }) {
      this.internalAiVoices[index].courseId = data.course_id;
      this.internalAiVoices[index].ttsId = data.tts_id;
      this.internalAiVoices[index].src = base_url_machine_learning + data.path;
      this.internalAiVoices[index].duration = data['length'];
      this.internalAiVoices[index].generateText = data.text;
      this.internalAiVoices[index].generateSpeaker = speaker;
      this.$emit('push-to-timeline', this.internalAiVoices[index])

      return Promise.resolve();
    },
    async handleHideSentimentAnalysisWarning() {
      const source = await JSON.parse(sessionStorage.getItem('sentimentSource'));

      if (source.inputType) {
        if (source.inputType === 'ai-generator') {
          this.$refs.aiGenerator.$refs.inputText.focus();
          this.$refs.aiGenerator.$refs.inputText.selectionStart = this.aiGeneratorData.text.length;
          this.$refs.aiGenerator.$refs.inputText.scrollTop = this.$refs.aiGenerator.$refs.inputText.scrollHeight - this.$refs.aiGenerator.$refs.inputText.offsetHeight;
          this.$refs.aiGenerator.$el.scrollIntoView({ behavior: 'smooth' });
        } else if (source.inputType === 'script-editor') {
          this.$refs.scriptEditors[source.index].$refs.textarea.focus();
          this.$refs.scriptEditors[source.index].$refs.textarea.selectionStart = this.internalAiVoices[source.index].text.length;
          this.$refs.scriptEditors[source.index].$refs.textarea.scrollTop = this.$refs.scriptEditors[source.index].$refs.textarea.scrollHeight - this.$refs.scriptEditors[source.index].$refs.textarea.offsetHeight;
          this.$refs.scriptEditors[source.index].$el.scrollIntoView({ behavior: 'smooth' });
        }
      }

      this.removeSentimentStorage();
    },
    async handleSentimentAnalysisData() {
      if (sessionStorage.getItem('sentimentFormData')) {
        const source = await JSON.parse(sessionStorage.getItem('sentimentSource'));

        if (source.inputType === 'ai-generator') {
          this.aiGeneratorData = source.data;
          this.$refs.aiGenerator.$refs.inputText.focus();
          this.$refs.aiGenerator.$refs.inputText.selectionStart = this.aiGeneratorData.text.length;
          this.$refs.aiGenerator.$refs.inputText.scrollTop = this.$refs.aiGenerator.$refs.inputText.scrollHeight - this.$refs.aiGenerator.$refs.inputText.offsetHeight;
          this.$refs.aiGenerator.$el.scrollIntoView({ behavior: 'smooth' });
        } else if (source.inputType === 'script-editor') {
          this.$nextTick(() => {
            this.$refs.scriptEditors[source.index].$refs.textarea.focus();
            this.$refs.scriptEditors[source.index].$refs.textarea.selectionStart = this.internalAiVoices[source.index].text.length;
            this.$refs.scriptEditors[source.index].$refs.textarea.scrollTop = this.$refs.scriptEditors[source.index].$refs.textarea.scrollHeight - this.$refs.scriptEditors[source.index].$refs.textarea.offsetHeight;
            this.$refs.scriptEditors[source.index].$el.scrollIntoView({ behavior: 'smooth' });
          });
        }

        if (sessionStorage.getItem('isAgreedSentiment')) {
          if (source.inputType === 'ai-generator') {
            this.isAiGenerating = true;
          } else if (source.inputType === 'script-editor') {
            this.$nextTick(() => {
              this.internalAiVoices[source.index].actionState = 'loading';
            });
          }

          const formData = await JSON.parse(sessionStorage.getItem('sentimentFormData'));
          formData.result = '';
          formData.status = 'agree';

          let data = null;

          try {
            const res = await getGeneratedTextToAudioWithAgreements(formData);

            if (res.status === 403) {
              this.$bvModal.show('audio-quota');
            } else if (res.status === 402 || res.status === 500) {
              this.$bvModal.show('server-error-modal');
            } else if (res.status === 200) {
              ({ data } = res);
            }
          } catch (error) {
            console.log(error);
            this.$bvModal.show('server-error-modal');
          }

          if (data !== null) {
            if (source.inputType === 'ai-generator') {
              this.populateGeneratedAiData({
                data,
                scriptType: source.data.scriptType.id,
                voiceType: source.data.voiceType,
              });
            } else if (source.inputType === 'script-editor') {
              this.populateGeneratedAiVoice({
                index: source.index,
                speaker: formData.speaker,
                data,
              });
            }
          }

          if (source.inputType === 'ai-generator') {
            this.isAiGenerating = false;
          } else if (source.inputType === 'script-editor') {
            if (data !== null) {
              this.internalAiVoices[source.index].actionState = 'play';
            } else {
              this.internalAiVoices[source.index].actionState = 'generate';
            }
          }
        }

        this.removeSentimentStorage();
      }
    },
    removeSentimentStorage() {
      sessionStorage.removeItem('sentimentFormData');
      sessionStorage.removeItem('sentimentInput');
      sessionStorage.removeItem('sentimentSource');
      sessionStorage.removeItem('isAgreedSentiment');
    },
    async handlePlayAudioPlayer(pauseAudio, audioId) {
      const playMediaEvent = new CustomEvent('play:media', {
        detail: {
          componentId: audioId,
        },
      });

      document.dispatchEvent(playMediaEvent);

      this.latestPlayingAudioId = audioId;
      this.pauseLatestPlayingAudio = pauseAudio;
    },
    async handlePlayMedia(e) {
      if (e.detail.componentId !== this.latestPlayingAudioId && this.pauseLatestPlayingAudio) {
        await this.pauseLatestPlayingAudio();
      }
    },
    unselectVoiceWhenClickOutside(e) {
      if (this.selectedPersonalVoice) {
        for (const voiceCollectionItem of this.$refs.voiceCollectionItem) {
          if (voiceCollectionItem.contains(e.target)) {
            return;
          }
        }

        this.selectedPersonalVoice = null;
      }
    },
    handleDeletedSeiId(id){
      if(id){
        this.internalAiVoices = this.internalAiVoices.filter((iav) => iav.id !== id);
        this.$emit('reset-deleted-sei-id')
      }
    },
    updateCurrentTime() {
      const audioElement = this.$refs.aiAudioPlayer;
      this.$emit('update:current-time', audioElement.currentTime);
    },
    typingTextareaPlaceholder() {
      const speed = 100;
      let currentChar = 0;
      const placeholder = this.pvPlaceholder;

      this.typingInterval = setInterval(() => {
        if (currentChar < placeholder.length) {
          this.activePlaceholderPv += placeholder.charAt(currentChar);
          currentChar++;
        } else {
          clearInterval(this.typingInterval);
          setTimeout(() => {
            this.activePlaceholderPv = ""
            this.typingTextareaPlaceholder()
          }, 1000);
        }
      }, speed);
    },
    handlePauseAudioAi(){
      if (this.$refs.aiAudioPlayer) {
        this.$refs.aiAudioPlayer.pause()
        this.internalAiVoices[0].actionState = 'play';
      }
    }
  },
  async mounted() {
    // document.addEventListener('click', this.unselectVoiceWhenClickOutside);
    document.addEventListener('play:media', this.handlePlayMedia);
    await this.handleSentimentAnalysisData();
    this.typingTextareaPlaceholder()
  },
  beforeDestroy() {
    // document.removeEventListener('click', this.unselectVoiceWhenClickOutside);
    document.removeEventListener('play:media', this.handlePlayMedia);
  },
};
</script>

<style scoped>
.voice-section {
  gap: 16px;
}

.voice-section__ai-voices {
  gap: 16px;
}

.voice-section__ai-generator {
  background-color: #1F1F1F;
}

.voice-section__ai-voices-list {
  gap: 16px;
}

.voice-section__personal-voice {
  gap: 16px;
  min-height: 200px;
}

.voice-section__voice-collection {
  gap: 8px;
}

.voice-section__voice-collection-title {
  font-size: 14px;
}

.voice-section__voice-collection-reset {
  color: #6D75F6;
  text-decoration-thickness: 1px;
  text-decoration-line: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.25s;
}

.voice-section__voice-collection-reset:not(:disabled):hover {
  text-decoration-color: #6D75F6;
}

.voice-section__voice-collection-list {
  gap: 8px;
}

.voice-section__voice-collection-checkbox {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  font-size: 8px;
  transition: background-color 0.25s, border-color 0.25s;
}

.voice-section__voice-collection-checkbox--checked {
  background-color: #6D75F6;
  border-color: #6D75F6;
  color: #FFFFFF;
}

.voice-section__voice-collection-button {
  gap: 16px;
  width: 100%;
  background: #2D2D2D;
  border-radius: 6px;
  border: 1px solid transparent;
  overflow: hidden;
  transition: border-color 0.25s;
}

.voice-section__voice-collection-button:not(
  .voice-section__voice-collection-button--selected
):hover {
  border-color: #6D75F655;
}

.voice-section__voice-collection-button--selected {
  border-color: #6D75F6;
}

.voice-section__voice-collection-footer {
  gap: 8px;
}

.voice-section__empty-voices {
  gap: 14px;
}

.voice-section__empty-voices-message {
  font-size: 14px;
}

.voice-section__textarea-container {
  gap: 8px;
}

.voice-section__textarea-field {
  height: 100px;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  resize: none;
  width: 100%;
}

.ta--wrapper {
  background-color: #1F1F1F;
  border-radius: 6px;
  width: 100%;
}

.voice-section__textarea-field::placeholder {
  color: #585859;
}

.voice-section__record-button {
  background: none;
  border-radius: 4px;
  border: 1px solid #6D75F6;
  color: #6D75F6;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.25s;
}

.voice-section__record-button:not(:disabled):hover {
  background-color: #6D75F633;
}

.voice-section__use-button {
  min-width: 90px;
  background-color: #6D75F6;
  border: 1px solid #6D75F6;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.25s, border-color;
}

.voice-section__use-button:not(:disabled):hover {
  background-color: #545EED;
  border-color: #545EED;
}

.voice-section__use-button:disabled {
  background-color: #4D4D4D;
  border-color: #4D4D4D;
}

.vs__play-button {
  width: 25px;
  height: 25px;
  background-color: #3D3D3D;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.25s;
}

.vs__play-button:not(:disabled):hover {
  background-color: #4D4D4D;
}
</style>
