<template>
    <div>
        <b-modal id="ttsse-modal" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="next"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Service Unavailable">
                <div class="mt-3 text-white">
                  <h4 class="font-weight-bold">{{ $t('service-unavailable') }}</h4>
                    <h5 class="font-weight-normal mt-2 mb-4" v-html="$t('service-unavailable-p')"></h5>
                    <span class="contact-us mt-5">{{ $t('service-unavailable--p') }} <a href="https://wa.me/6282126719955" target="_blank">{{ $t('here--1') }}</a>.</span>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-primary btn-purple-nb" @click="next"><p class="mx-2 mb-0 font-weight-bold">Try Again</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>

export default {
  methods : {
    next(){
		this.$bvModal.hide('ttsse-modal')
		this.$emit('clear-storage')
		this.$router.push({ name : 'Home' })
    }
  }
}

</script>

<style scoped>
.contact-us a{
  color: #6D75F6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}
.contact-us{
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.btn-close {
  background-color: #ededed;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#ttsse-modal .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#ttsse-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#ttsse-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

.btn-purple {
  background-color: #6d75f6;
  border: 1px solid #6d75f6;
  color: white;
  box-shadow: none !important;
}

button {
  box-shadow: none !important;
}
</style>