<template>
  <b-modal
    :id="id"
    size="sm"
    dialog-class="if__dialog"
    content-class="if mx-auto text-white text-center"
    body-class="p-0"
    centered
    hide-header
    hide-footer
    @show="init"
    @hidden="destroy"
  >
    <header class="if__header d-flex justify-content-center align-items-center px-4 pt-4 pb-1">
      <img
        v-if="withNewIcon"
        src="@/assets/images/icons/ic_new_ai_speech_editor.svg"
        alt="New"
        class="if__title-icon"
      />
      <h3 class="if__title">{{ title }}</h3>
    </header>

    <section class="if__main d-flex flex-column py-4">
      <ul class="if__slider list-unstyled m-0" :data-slider-id="_uid">
        <li v-for="(slide, index) in slides" :key="index">
          <div class="if__slide d-flex flex-column">
            <div
              v-show="!slide.gifPath"
              class="if__slide-animation lottie"
              :data-animation-path="slide.animationPath"
              data-anim-loop="true"
              :data-name="slide.name"
            />
            <img v-show="slide.gifPath" :src="slide.gifPath" :alt="slide.name" class="if__slide-animation">
            <p v-html="slide.rawText" class="if__slide-text m-0" />
          </div>
        </li>
      </ul>
      <ul class="if__navigation-bullets d-flex justify-content-center align-items-center list-unstyled m-0">
        <li v-for="(_, index) in slides" :key="index">
          <button
            type="button"
            class="if__navigation-bullet btn p-0"
            :class="{ 'if__navigation-bullet--active': index === activeSlide }"
            :title="`Step ${index + 1}`"
            @click="activeSlide = index"
          >
            <span class="sr-only">{{ `Step ${index + 1}` }}</span>
          </button>
        </li>
      </ul>
    </section>

    <footer class="if__footer d-flex justify-content-between align-items-center p-4">
      <button
        v-if="activeSlide < 1"
        type="button"
        class="if__navigation-button if__navigation-button--skip d-flex align-items-center btn text-white"
        title="Skip"
        @click="$bvModal.hide(id)"
      >
        Skip
      </button>
      <button
        v-else
        type="button"
        class="if__navigation-button if__navigation-button--back d-flex align-items-center btn text-white"
        title="Back"
        @click="activeSlide--"
      >
        Back
      </button>
      <button
        type="button"
        class="if__navigation-button if__navigation-button--next d-flex align-items-center btn text-white"
        title="Next"
        @click="handleClickNext"
      >
        Next
        <font-awesome-icon
          icon="fa-solid fa-chevron-right"
          class="if__navigation-button-icon"
        />
      </button>
    </footer>
  </b-modal>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';
import lottie from 'lottie-web';

import 'tiny-slider/dist/tiny-slider.css';

export default {
  props: {
    id: {
      type: String,
      default: 'introduce-ai-speech-editor',
    },
    title: {
      type: String,
      default: 'New Feature',
    },
    withNewIcon: {
      type: Boolean,
      default: false,
    },
    slides: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slider: null,
      activeSlide: 0,
    };
  },
  watch: {
    activeSlide(newActiveSlide) {
      this.slider.goTo(newActiveSlide);
    },
  },
  methods: {
    handleClickNext() {
      if (this.activeSlide + 1 < this.slides.length) {
        this.activeSlide++;
      } else {
        this.$bvModal.hide(this.id);
      }
    },
    init() {
      this.$nextTick(() => {
        lottie.searchAnimations();

        this.slider = tns({
          container: `.if__slider[data-slider-id="${this._uid}"]`,
          center: true,
          controls: false,
          nav: false,
          speed: 500,
          loop: false,
          gutter: 24,
          edgePadding: 24,
        });
      });
    },
    destroy() {
      lottie.destroy();
      this.slider.destroy();
    },
  },
};
</script>

<style scoped>
.if__header {
  gap: 6px;
}

.if__title-icon {
  width: 60px;
  height: 35px;
  margin-bottom: 4px;
  object-fit: contain;
  object-position: center;
}

.if__title {
  font-size: 18px;
  font-weight: 600;
}

.if__main {
  gap: 8px;
}

.if__slide {
  gap: 32px;
}

.if__slide-animation {
  height: 180px;
  background-color: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.if__slide-text {
  font-size: 16px;
}

.if__navigation-bullets {
  gap: 4px;
}

.if__navigation-bullet {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.25s;
}

.if__navigation-bullet--active {
  width: 15px;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.if__footer {
  gap: 16px;
  border-top: 1px solid #2D2D2D;
}

.if__navigation-button {
  gap: 6px;
  padding: 4px 20px;
  border-radius: 6px;
  font-size: 14px;
  transition: background-color 0.25s;
}

.if__navigation-button--skip {
  background-color: transparent;
  font-weight: 600;
}

.if__navigation-button--skip:hover {
  background: #2D2D2D;
}

.if__navigation-button--back {
  background-color: #2D2D2D;
  font-weight: 600;
}

.if__navigation-button--back:hover {
  background-color: #3D3D3D;
}

.if__navigation-button--next {
  background-color: #6D75F6;
  font-weight: 600;
}

.if__navigation-button--next:hover {
  background-color: #545EED;
}

.if__navigation-button-icon {
  margin-top: 2px;
  font-size: 10px;
}
</style>

<style>
.if__dialog {
  max-width: none;
}

.if {
  width: 400px;
  background-color: #1F1F1F;
  border-radius: 10px;
}
</style>
