<template>
	<section class="ma--table">
		<b-table :items="dataTable" :fields="fields" class="mat--custom" hover @row-clicked="handleRowClick" :tbody-tr-class="getRowClass">
			<template v-if="activeTabs == 'all' || activeTabs == 'image'" #head(selectAll)>
				<vs-checkbox color="#6D75F6" v-model="isAll"></vs-checkbox>
			</template>

			<template #cell(selectAll)="data">
				<vs-checkbox v-if="data.item.file_type != 'file_group' && data.item.file_type != 'video'" color="#6D75F6" v-model="data.item.selected"></vs-checkbox>
			</template>

			<template #cell(name)="data">
				<div class="ma__name-field d-flex align-items-center">
					<img v-if="data.item.file_type == 'video'" :src="data.item.video_thumbnail" :alt="data.item.name" class="mat__img">
					<div v-else-if="data.item.file_type == 'file_group'" class="mat__folder d-flex align-items-center justify-content-center">
						<img src="@/assets/images/icons/ic_stack-file.svg" alt="Folder">
					</div>
					<img v-else :src="data.item.src" :alt="data.item.name" class="mat__img">
					<div class="name--detail d-flex flex-column justify-content-center">
						<p class="m-0" :title="data.item.name">{{ truncatedText(data.item.name) }}</p>
						<p v-if="data.item.file_type == 'file_group'" class="na__small m-0">{{ data.item.total_file ? data.item.total_file : '-' }} files</p>
					</div>
				</div>
			</template>

			<template #cell(file_size)="data">
				<p class="m-0">{{ data.item.file_size ? convertToBytes(data.item.file_size) : '-' }}</p>
			</template>

			<template #cell(file_type)="data">
				<p class="m-0">{{ data.item.file_type == 'file_group' ? '-' : data.item.file_type }}</p>
			</template>

			<template #cell(createdAt)="data">
				<p class="m-0">{{ data.item.createdAt ? getDate(data.item.createdAt) : '-' }}</p>
			</template>

			<template #cell(action)="data">
				<div class="dropdown mat__dropdown">
					<button class="mat__button-action btn p-0" type="button" data-toggle="dropdown" aria-expanded="false">
						<font-awesome-icon icon="fa-solid fa-ellipsis" class="mat__ba-icon"/>
					</button>
					<div class="dropdown-menu">
						<a v-if="data.item.file_type == 'file_group'" class="dropdown-item text-white px-2 cursor-pointer" @click="openFolder(data.item.id, data.item.name)">{{ $t('open') }}</a>
						<a class="dropdown-item text-white px-2 cursor-pointer" @click.stop="downloadImage(data.item.id, data.item.src, data.item.name, data.item.file_type)">{{ $t('download') }}</a>
						<a v-if="data.item.file_type != 'file_group'" class="dropdown-item text-white px-2 cursor-pointer" @click="rename(data.item.id, data.item.name)">{{ $t('change-name') }}</a>
						<a class="dropdown-item text-white px-2 cursor-pointer" @click="$emit('click:delete-asset', { id : data.item.id, file_type : data.item.file_type})">{{ $t('delete') }}</a>
					</div>
				</div>
			</template>
		</b-table>
	</section>
</template>

<script>
import moment from 'moment';

import { exportUtils } from "@/mixins/ExportUtils";
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
	mixins:[exportUtils, AlertUtils],
	props: {
		items : {
			type: Array,
			default : () => {
				return []
			}
		},

		fields : {
			type: Array,
			default : () => {
				return []
			}
		},

		selectedData: {
			type: Object,
			default: null,
		},

		selectAll: {
			type: Boolean,
			default: false
		},

		activeTabs: {
			type: String,
			// validator(value) {
			// 	return ['all', 'image', 'video', 'files'].includes(value);
			// },
			default: 'all',
		},
	},
	data() {
		return {
			isAll: this.selectAll,
			dataTable: this.items
		}
	},

	watch: {
		selectAll(newVal) {
			this.isAll = newVal
		},

		isAll(newVal) {
			this.$emit('update:select-all', newVal)
		},

		items: {
			handler(newVal) {
				this.dataTable = newVal
			},
			deep: true
		},
	},

	methods: {
		async downloadImage(id, url, name, type) {
			try {
				const response = await fetch(url)

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const blobImage = await response.blob();

				if(type == 'video') {
					this.exportVidio(blobImage, name ? name : 'Video')
				} else if(type == 'file_group') {
					this.$emit('click:download-all-image', { id, name })
				} else {
					this.exportImage(blobImage, name ? name : 'Image')
				}
				if( type != 'file_group' ) { this.alertSuccess(`${type == 'video' ? 'Video' : 'Image'} successfully downloaded`) }
			} catch (error) {
				console.error('Error during fetch:', error);
				this.alertFail("Failed to download image.")
			}
		},
		rename(id, name) {
			this.$emit('click:rename-asset', { id, name })
		},
		handleRowClick(val){
			this.$emit('click:row-table', val)
		},
		getRowClass(item) {
			if (this.isSelected(item)) {
				return 'row-active';
			}
			return '';
		},
		isSelected(item) {
			if(this.selectedData) {
				return item === this.selectedData; // Contoh: Membandingkan ID
			} else {
				return item.selected
			}
		},
		truncatedText(text) {
			const maxLength = 25;
			const ellipsis = '...';

			return text.length > maxLength ? text.substring(0, maxLength - ellipsis.length) + '...' : text;
		},
		convertToBytes(num) {
			const number = parseInt(num);
			const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			let unitIndex = 0;
			let size = number;

			while (size >= 1024 && unitIndex < units.length - 1) {
				size /= 1024;
				unitIndex++;
			}

			return `${size.toFixed(2)} ${units[unitIndex]}`;
		},
		getDate(item){
			const date = moment(item);
			let now = moment();
			now = now.add(420, 'minutes')
			const diff = now.diff(date, 'seconds');

			if (diff < 1) {
				return this.$t('a-few-secs-ago');
			} else if (diff < 60) {
				return diff + ' ' + this.$t('secs-ago');
			} else if (diff < 3600) {
				const minutes = Math.floor(diff / 60);
				return minutes + ' ' + this.$t('minutes-ago');
			} else if (diff < 86400) {
				const hours = Math.floor(diff / 3600);
				return hours + ' ' + this.$t('hours-ago');
			} else if (diff < 604800) {
				const days = Math.floor(diff / 86400);
				return days + ' ' + this.$t('days-ago');
			} else if (diff < 2592000) {
				const weeks = Math.floor(diff / 604800);
				return weeks + ' ' + this.$t('weeks-ago');
			} else if (diff < 31536000) {
				const months = Math.floor(diff / 2592000);
				return months + ' ' + this.$t('months-ago');
			} else {
				const years = Math.floor(diff / 31536000);
				return years + ' ' + this.$t('years-ago');
			}
		},
		openFolder(id, name) {
			this.$emit('click:open-folder', { id, name })
		},
	}
}
</script>

<style scoped>
.mat--custom {
	color: #8c8c8c;
}

.mat__button-action {
	box-shadow: none;
	color: #fff;
}

.mat__ba-icon {
	font-size: 16px;
}

.mat__img, .mat__folder {
	width: 38px;
	height: 38px;
	border-radius: 6px;
}

.mat__folder {
	background-color: #2D2D2D;
}

.ma__name-field {
	gap: 8px;
}

.na__small {
	font-size: 10px;
	color: #c4c4c4;
}
</style>

<style>
.mat--custom thead th,  .mat--custom tbody td{
	border-bottom: 0.1px solid #2d2d2d;
	font-weight: 400;
}

.mat--custom thead th {
	font-size: 10px;
}

.mat--custom tbody td {
	color: white;
	vertical-align: middle;
	font-size: 12px;
}

.mat--custom tbody tr {
	cursor: pointer;
}

.mat--custom tbody tr:hover, .row-active {
	outline:#6D75F6 1px solid;
	border: 1px solid #6D75F6;
	background: rgba(109, 117, 246, 0.20);
}

.mat__dropdown .dropdown-menu{
	background: #2D2D2D;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
	border-radius: 6px;
	color: #fff;
	min-width: 100px !important;
	max-width: 100px !important;
	font-size: 12px;
}

.mat__dropdown .dropdown-item:hover {
	background-color: #8c8c8c;
	color: #fff;
}
</style>