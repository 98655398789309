export default (question) => {
  const isDataAvailable = {
    question: !!question.question?.trim() && question.question?.trim() !== '<p></p>',
    option_1: !!question.option_1?.trim() && question.option_1?.trim() !== '<p></p>',
    option_2: !!question.option_2?.trim() && question.option_2?.trim() !== '<p></p>',
    option_3: !!question.option_3?.trim() && question.option_3?.trim() !== '<p></p>',
    option_4: !!question.option_4?.trim() && question.option_4?.trim() !== '<p></p>',
    correct_answer: !!question.correct_answer?.trim(),
    image_url: !!question.image_url?.trim(),
    video: !!question.video?.trim(),
    voice: !!question.voice?.trim(),
  }

  if (!isDataAvailable.question && !isDataAvailable.image_url && !isDataAvailable.video && !isDataAvailable.voice) {
    return false;
  }

  if (
    !isDataAvailable.option_1
    || !isDataAvailable.option_2
    || (question.layout !== 'true-false' && !isDataAvailable.option_3)
    || (question.layout !== 'true-false'&& !isDataAvailable.option_4)
    || !isDataAvailable.correct_answer
  ) {
    return false;
  }

  return true;
};
