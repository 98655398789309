import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import SSMLNode from '../nodes/SSMLNode';

export default Node.create({
  name: 'ssml',
  content: 'inline*',
  group: 'inline',
  inline: true,
  addAttributes() {
    return {
      type: {
        default: null,
      },
      pause: {
        default: null,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'ssml',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['ssml', HTMLAttributes, 0];
  },
  addNodeView() {
    return VueNodeViewRenderer(SSMLNode);
  },
});
