<template>
  <BubbleMenu
    :editor="editor"
    :tippy-options="tippyOptions()"
    :should-show="handleShouldShow"
  >
    <SSMLOptionsMenu
      v-model="options"
      :plain-text="plainText"
      @generate-preview-ssml="(data) => $emit('generate-preview-ssml', data)"
    />
  </BubbleMenu>
</template>

<script>
import { isTextSelection } from '@tiptap/core';
import { BubbleMenu, Editor } from '@tiptap/vue-2';

import 'tippy.js/animations/shift-away.css';

import SSMLOptionsMenu from './';

export default {
  components: {
    BubbleMenu,
    SSMLOptionsMenu,
  },
  props: {
    editor: {
      type: Editor,
      required: true,
    },
  },
  emits: ['generate-preview-ssml'],
  data() {
    return {
      options: {
        speed: 'fe-normal',
        pause: null,
        tone: null,
      },
      plainText: '',
      currentSelectionRange: null,
    };
  },
  methods: {
    tippyOptions() {
      return {
        zIndex: 9999,
        placement: 'bottom',
        maxWidth: 'none',
        animation: 'shift-away',
        duration: 150,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                boundary: this.editor.view.dom,
                fallbackPlacements: ['top'],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                boundary: this.editor.view.dom,
                altAxis: true,
                padding: 24,
              },
            },
          ],
        },
        onBeforeUpdate: () => {
          this.options = {
            speed: 'fe-normal',
            pause: null,
            tone: null,
          };
          this.plainText = this.editor.commands.getSelectionText();
          this.currentSelectionRange = this.editor.commands.getSelectionRange();
        },
        onHidden: () => {
          if (this.options.tone || this.options.pause || this.options.speed !== 'fe-normal') {
            this.editor
              .chain()
              .focus()
              .insertContentAt(
                this.currentSelectionRange,
                `<ssml type="${this.options.tone || this.options.speed}"${this.options.pause ? ` pause="${this.options.pause}"` : ''}>${this.plainText}</ssml>`
              )
              .run();
          }
        },
      };
    },
    handleShouldShow({ editor, view, state, from, to }) {
      if (!view.hasFocus()) {
        return false;
      }

      const { doc, selection } = state;

      const isText = isTextSelection(selection);
      if (!isText) {
        return false;
      }

      const isEmpty = selection.empty || (isText && doc.textBetween(from, to).length === 0);
      if (isEmpty) {
        return false;
      }

      if (editor.isActive('ssml')) {
        return false;
      }
      
      let shouldShow = true;

      doc.nodesBetween(from, to, (node) => {
        if (node.type.name === 'ssml') {
          shouldShow = false;
        }
      });

      return shouldShow;
    },
  },
};
</script>
