<template>
  <span
    class="ssmlnc"
    :class="{ 'ssmlnc--active': active }"
  >
    <NodeViewContent
      as="span"
      class="ssmlnc__text"
      :class="{ 'ssmlnc__text--active': active }"
    />
    <button
      type="button"
      class="ssmlnc__button btn d-inline-flex p-0 ml-1 border-0"
      @click="$emit('click:button')"
      contenteditable="false"
      :title="
        editor.storage.fullscreenStorage?.isFullscreen
        ? `${active ? 'Hide Options' : 'Show Options'}`
        : `${active ? 'Hide Highlight' : 'Show Highlight'}`
      "
    >
      {{ buttonText }}
    </button>
  </span>
</template>

<script>
import { Editor, NodeViewContent } from '@tiptap/vue-2';

export default {
  components: {
    NodeViewContent,
  },
  props: {
    editor: {
      type: Editor,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  emits: ['click:button'],
};
</script>

<style scoped>
.ssmlnc {
  transition: background-color 0.25s;
}

.ssmlnc--active {
  background-color: #6D75F633;
}

.ssmlnc__text {
  transition: background-color 0.25s, color 0.25s;
}

.ssmlnc__text::selection {
  background-color: #6D75F680;
  color: #FFFFFF;
}

.ssmlnc__text--active {
  background-color: #6D75F680;
  color: #FFFFFF;
}

.ssmlnc__button {
  background: none;
  border-radius: 0;
  vertical-align: inherit;
  font-size: inherit;
  color: #6D75F6;
}
</style>
