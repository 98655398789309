<template>
	<section class="aig--suggestion d-flex flex-column overflow-auto py-3" :class="{ 'aig--suggestion-show' : isShow }">
		<h5 class="aig--title mt-2">Recomended prompts (Generate something similar)</h5>
		<div v-for="(data, index) in suggestionPrompt" :key="index" class="sp--list d-flex align-items-center" @click="$emit('suggest-prompt', data.prompt)">
			<img :src="data.image" :alt="data.image" class="spl--image">
			<h5 class="m-0 font-weight-normal text-truncate" :title="data.prompt">{{ data.prompt }}</h5>
		</div>
	</section>
</template>

<script>
export default {
	props : {
		isVisible: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			isShow: this.isVisible,
			suggestionPrompt: [
				{
					image : require('@/assets/images/layouts/sgp-1.png'),
					prompt : 'Cup of coffee on Placemat on a table next to bowls of berries'
				},
				{
					image : require('@/assets/images/layouts/sgp-2.png'),
					prompt : 'Camera on a wet surface surrounded by rain, in front of a spotlight and strong shadows'
				},
				{
					image : require('@/assets/images/layouts/sgp-3.png'),
					prompt : 'Wallet on a circular platform surrounded by splashing water, in front of gradient background'
				}
			]
		}
	},
	watch: {
		isVisible(newVal) {
			this.isShow = newVal
		}
	},
	methods: {
		klik() {
			console.log("DI KLIK")
		}
	}
}
</script>

<style scoped>
.aig--suggestion {
	position: absolute;
	width: 100%;
	background-color: #1F1F1F !important;
  	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 6px;
	left: 0;
	right: 0;
	top: 30px;
	z-index: 999;
	padding: 10px 0;
	max-height: 200px;
	visibility: hidden;
  	opacity: 0;
	gap: 10px;
  	transition: visibility 0.25s, opacity 0.25s;
}

.aig--title {
	text-align: left;
	padding: 0 20px;
}

.aig--suggestion-show {
	visibility: visible;
	opacity: 1;
}

.sp--list {
	cursor: pointer;
	transition: background-color 0.25s;
	padding: 10px 20px;
	text-align: left;
	gap: 15px;
	width: 100%;
}

.sp--list:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.spl--image {
	max-height: 80px;
	min-height: 80px;
	max-width: 80px;
	min-width: 80px;
	border-radius: 6px;
	object-fit: cover;
}
</style>