<template>
  <div class="rr d-flex flex-column">
    <section class="rr__box rr__prompt d-flex flex-column">
      <header class="rr__prompt-header d-flex align-items-center px-4 py-3">
        <div class="rr__language d-flex align-items-center">
          <p class="rr__language-text m-0">Language :</p>
          <b-dropdown
            boundary="window"
            toggle-class="rr__language-toggle btn d-inline-flex align-items-center text-white"
            menu-class="rr__language-menu"
            no-caret
            title="Select Language"
            :disabled="recordControlState === 'stop' || playControlState === 'pause'"
          >
            <template #button-content>
              <img
                :src="selectedLanguageData.flagSrc"
                height="14"
                :alt="selectedLanguageData.name"
              />
              {{ selectedLanguageData.shortName }}
              <font-awesome-icon icon="fa-solid fa-chevron-down" />
            </template>
            <b-dropdown-item-button
              v-for="language in languages"
              :key="language.id"
              button-class="rr__language-button d-inline-flex align-items-center px-3 py-2 text-white"
              :title="`Select ${language.name}`"
              @click="$emit('change:language', language.id)"
            >
              <img
                :src="language.flagSrc"
                height="14"
                :alt="language.name"
              />
              {{ language.name }}
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
      </header>
      <p class="rr__prompt-text px-4 py-3 m-0 text-center">
        "{{ suggestionScript }}"
      </p>
    </section>
    <section class="rr__box rr__record d-flex flex-column align-items-center px-3 py-4">
      <div
        v-show="stream === null && !recordedAudioSrc"
        class="rr__record-default-wave flex-column justify-content-center"
      >
        <div class="rr__record-default-wave-inner" />
      </div>
      <audio
        ref="audioPlayer"
        :src="recordedAudioSrc"
      />
      <av-waveform
        v-if="recordedAudioSrc"
        ref-link="audioPlayer"
        :audio-controls="false"
        :canv-width="600"
        :canv-height="80"
        :playtime="false"
        played-line-color="#6D75F6"
        noplayed-line-color="#8C8C8C"
        playtime-slider-color="#C43D30"
      />
      <av-media
        v-show="stream && !recordedAudioSrc"
        type="frequ"
        :media="stream"
        :audio-controls="false"
        :canv-width="600"
        :canv-height="80"
        :frequ-lnum="200"
        :radius="16"
        line-color="#6D75F6"
        frequ-direction="mo"
      />
      <div class="rr__record-controls d-flex flex-column align-items-center">
        <p class="rr__record-time m-0">{{ recordFullTime }}</p>
        <button
          type="button"
          class="rr__record-control btn d-inline-flex justify-content-center align-items-center rounded-circle"
          :class="{
            'rr__record-control--record': recordControlState === 'record',
            'rr__record-control--stop': recordControlState === 'stop',
            'rr__record-control--loading': recordControlState === 'loading',
          }"
          :disabled="recordControlState === 'loading' || playControlState === 'pause'"
          :title="recordControlState.charAt(0).toUpperCase() + recordControlState.slice(1)"
          @click="handleClickRecordControl"
        >
          <font-awesome-icon
            :icon="`fa-solid fa-${recordControlState === 'record' ? 'circle' : recordControlState === 'stop' ? 'stop' : 'spinner'}`"
            :spin="recordControlState === 'loading'"
          />
        </button>
        <p class="rr__record-status m-0">
          {{ recordControlState.charAt(0).toUpperCase() + recordControlState.slice(1) }}
        </p>
      </div>
    </section>
    <section class="rr__buttons d-flex justify-content-center">
      <button
        type="button"
        class="rr__play-control btn d-inline-flex justify-content-center align-items-center p-2"
        :class="{
          'rr__play-control--play': playControlState === 'play',
          'rr__play-control--pause': playControlState === 'pause',
        }"
        :title="playControlState.charAt(0).toUpperCase() + playControlState.slice(1)"
        :disabled="playControlState === 'loading' || recordControlState === 'stop' || !recordedAudioSrc"
        @click="handleClickPlayControl"
      >
        <font-awesome-icon
          :icon="`fa-solid fa-${playControlState === 'play' ? 'play' : playControlState === 'pause' ? 'pause' : 'spinner'}`"
          :spin="playControlState === 'loading'"
        />
        {{ playControlState.charAt(0).toUpperCase() + playControlState.slice(1) }}
      </button>
      <button
        type="button"
        class="rr__next-button btn p-2"
        title="Next"
        :disabled="!recordedAudioSrc || recordControlState === 'pause'"
        @click="$emit('click:next')"
      >
        Next
      </button>
    </section>
  </div>
</template>

<script>
import RecordRTC from 'recordrtc';
import getBlobDuration from 'get-blob-duration';

export default {
  props: {
    recordedVoiceSrc: {
      type: String,
      default: null,
    },
    languages: {
      type: Array,
      default: () => [],
    },
    selectedLanguage: {
      type: String,
      default: null,
    },
    suggestionScript: {
      type: String,
      default: null,
    },
  },
  emits: ['click:next', 'change:language'],
  data() {
    return {
      stream: null,
      recorder: null,

      recordTime: 0,
      recordTimeInterval: null,
      recordedAudioSrc: this.recordedVoiceSrc,
      recordedAudioCurrentTime: 0,
      recordedAudioDuration: 0,

      playControlState: 'play', // ['play', 'pause', 'loading']
      recordControlState: 'record', // ['record', 'stop', 'loading']
    };
  },
  computed: {
    recordFullTime() {
      if (this.recordedAudioSrc) {
        return `${this.formatTime(this.recordedAudioCurrentTime)} / ${this.formatTime(this.recordedAudioDuration)}`;
      } else {
        return this.formatTime(this.recordTime);
      }
    },
    selectedLanguageData() {
      return this.languages.find((language) => language.id === this.selectedLanguage);
    },
  },
  watch: {
    recordedVoiceSrc(newRecordedVoiceSrc) {
      this.recordedAudioSrc = newRecordedVoiceSrc;
    },
    async recordedAudioSrc(newRecordedAudioSrc) {
      this.$emit('change:recorded-voice-src', newRecordedAudioSrc);
      if (newRecordedAudioSrc) {
        this.recordedAudioDuration = await getBlobDuration(newRecordedAudioSrc);
      } else {
        this.recordedAudioDuration = 0;
      }
    },
  },
  methods: {
    async handleClickRecordControl() {
      if (this.recordControlState === 'record') {
        this.recordControlState = 'loading';

        this.recordedAudioSrc = null;

        await this.activateRecorder();
        await this.recorder.startRecording();

        this.recordTime = 0;
        this.recordTimeInterval = setInterval(() => this.recordTime++, 1000);

        this.recordControlState = 'stop';
      } else if (this.recordControlState === 'stop') {
        this.recordControlState = 'loading';

        clearInterval(this.recordTimeInterval);
        this.recordTime = 0;

        await this.recorder.stopRecording((blob) => {
          this.recordedAudioSrc = blob;
        });
        await this.deactivateRecorder();

        this.stream = null;
        this.recorder = null;

        this.recordControlState = 'record';
      }
    },
    async handleClickPlayControl() {
      if (this.playControlState === 'play') {
        this.playControlState = 'loading';
        await this.$refs.audioPlayer.play();
        this.playControlState = 'pause';
      } else if (this.playControlState === 'pause') {
        this.playControlState = 'loading';
        await this.$refs.audioPlayer.pause();
        this.playControlState = 'play';
      }
    },
    async activateRecorder() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.recorder = new RecordRTC(this.stream, { type: 'audio' });
      } catch (error) {
        console.log(error);
      }
    },
    async deactivateRecorder() {
      for (const track of this.stream.getTracks()) {
        track.stop();
      }
    },
    formatTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
    async handleTimeupdateAudioPlayer() {
      this.recordedAudioCurrentTime = this.$refs.audioPlayer.currentTime;

      const duration = await getBlobDuration(this.recordedAudioSrc);

      if (this.$refs.audioPlayer.currentTime >= duration && this.$refs.audioPlayer.paused) {
        this.playControlState = 'play';
      }
    },
  },
  async mounted() {
    this.$refs.audioPlayer.addEventListener('timeupdate', this.handleTimeupdateAudioPlayer);
    if (this.recordedAudioSrc) {
      this.recordedAudioDuration = await getBlobDuration(this.recordedAudioSrc);
    }
  },
  async beforeDestroy() {
    this.$refs.audioPlayer.removeEventListener('timeupdate', this.handleTimeupdateAudioPlayer);
    if (this.stream !== null) {
      await this.deactivateRecorder();
    }
  },
};
</script>

<style scoped>
.rr {
  min-height: 0;
  width: min(950px, 95%);
  gap: 26px;
}

.rr__box {
  background-color: #1F1F1F;
  border-radius: 8px;
}

.rr__prompt {
  min-height: 150px;
}

.rr__prompt-header {
  gap: 16px;
  border-bottom: 1px solid #585859;
}

.rr__language {
  gap: 8px
}

.rr__prompt-text {
  height: 100%;
  overflow-y: auto;
  font-size: 18px;
  line-height: 1.6;
}

.rr__record {
  gap: 16px;
}

.rr__record-default-wave {
  display: flex;
  width: 600px;
  height: 86px;
}

.rr__record-default-wave-inner {
  background-color: #6D75F6;
  width: 100%;
  height: 2px;
  margin-top: -6px;
}

.rr__record-controls {
  gap: 8px;
}

.rr__record-time {
  font-size: 12px;
}

.rr__record-control {
  width: 45px;
  height: 45px;
  font-size: 20px;
  transition: background-color 0.25s, color 0.25s;
}

.rr__record-control--record {
  background-color: #2D2D2D;
  color: #C43D30;
}

.rr__record-control--record:hover {
  background-color: #333333;
}

.rr__record-control--stop {
  background-color: #C43D30;
  color: #FFFFFF;
}

.rr__record-control--stop:hover {
  background-color: #DC4638;
}

.rr__record-control--loading {
  background-color: #2D2D2D;
  color: #FFFFFF;
}

.rr__record-status {
  font-size: 12px;
}

.rr__buttons {
  gap: 12px;
}

.rr__play-control {
  width: 100px;
  gap: 6px;
  background-color: transparent;
  border-width: 2px;
  font-weight: 600;
  transition: background-color 0.25s, border-color 0.25s, color 0.25s;
}

.rr__play-control--play {
  border-color: #6D75F6;
  color: #6D75F6;
}

.rr__play-control--play:not(:disabled):hover {
  background-color: #6D75F633;
}

.rr__play-control--pause {
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.rr__play-control--pause:not(:disabled):hover {
  background-color: #FFFFFF22;
}

.rr__play-control:disabled {
  border-color: #1F1F1F;
  color: #585859;
}

.rr__next-button {
  width: 100px;
  background-color: #6D75F6;
  border-radius: 6px;
  border-width: 2px;
  color: #FFFFFF;
  font-weight: 600;
  transition: background-color 0.25s, color 0.25s;
}

.rr__next-button:not(:disabled):hover {
  background-color: #545EED;
}

.rr__next-button:disabled {
  background-color: #1F1F1F;
  color: #585859;
}
</style>

<style>
.rr__language-toggle {
  gap: 4px;
  padding: 2px 6px;
  background-color: #2D2D2D !important;
  border: none;
  border-radius: 16px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.rr__language-toggle:hover, .show > .rr__language-toggle {
  background-color: #4D4D4D !important;
}

.rr__language-menu {
  min-width: auto;
  background-color: #2D2D2D;
}

.rr__language-button {
  gap: 8px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.rr__language-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
