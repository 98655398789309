<template>
  <div class="vs-card">
    <div class="vs-card__wrapper d-flex flex-column">
      <button
        type="button"
        class="vs-card__toggle btn d-flex justify-content-between align-items-center px-4 rounded-0"
        :aria-expanded="isVisible ? 'true' : 'false'"
        :aria-controls="`collapse-${_uid}`"
        :title="isVisible ? `Hide ${name}` : `Show ${name}`"
        @click="isVisible = !isVisible, $emit('update:show', isVisible)"
      >
        <div class="vs-card__toggle-content d-flex align-items-center">
          <img
            :src="isVisible ? activeIconSrc : iconSrc"
            class="vs-card__toggle-content-icon"
            alt=""
          />
          <span
            class="vs-card__toggle-content-text text-nowrap"
            :class="{ 'vs-card__toggle-content-text--active': isVisible }"
          >
            {{ name }}
          </span>
        </div>
        <font-awesome-icon
          icon="fa-solid fa-chevron-right"
          class="vs-card__toggle-chevron-icon"
          :class="{ 'vs-card__toggle-chevron-icon--active': isVisible }"
        />
      </button>

      <b-collapse
        v-model="isVisible"
        :id="`collapse-${_uid}`"
        class="vs-card__collapse"
      >
        <div class="vs-card__collapse-wrapper px-4 pt-3 pb-4">
          <slot />
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    iconSrc: {
      required: true,
    },
    activeIconSrc: {
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.visible,
    };
  },
  watch: {
    visible(newVisible) {
      this.isVisible = newVisible;
    },
  },
};
</script>

<style scoped>
.vs-card__wrapper {
  border-radius: 8px;
  overflow: hidden;
}

.vs-card__toggle {
  gap: 16px;
  padding-block: 12px;
  background-color: #1F1F1F;
  transition: background-color 0.25s;
}

.vs-card__toggle:hover {
  background-color: #252525;
}

.vs-card__toggle-content {
  gap: 12px;
}

.vs-card__toggle-content-icon {
  height: 18px
}

.vs-card__toggle-content-text {
  color: #FFFFFF;
  font-size: 14px;
  transition: font-weight 0.25s, color 0.25s;
}

.vs-card__toggle-content-text--active {
  font-weight: 600;
  color: #6D75F6;
}

.vs-card__toggle-chevron-icon {
  color: #FFFFFF;
  font-size: 14px;
  transition: rotate 0.25s, color 0.25s;
}

.vs-card__toggle-chevron-icon--active {
  rotate: 90deg;
  color: #6D75F6;
}

.vs-card__collapse {
  background-color: #161616;
}
</style>
