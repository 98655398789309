var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"som d-flex align-items-center py-2 px-3 text-white"},[_vm._m(0),_c('ul',{staticClass:"som__list d-flex m-0 list-unstyled"},[_c('li',[_c('MenuItem',{attrs:{"items":_vm.listSpeed,"name":_vm.$t('speed'),"iconSrc":require('@/assets/images/icons/ic_speed_small.svg'),"disabled":['loading', 'pause'].includes(_vm.previewButtonState),"required":"","show":_vm.showSpeed},on:{"update:show":(val) => _vm.showSpeed = val},model:{value:(_vm.options.speed),callback:function ($$v) {_vm.$set(_vm.options, "speed", $$v)},expression:"options.speed"}})],1),_c('li',[_c('MenuItem',{attrs:{"items":_vm.listPause,"name":_vm.$t('pause'),"iconSrc":require('@/assets/images/icons/ic_pause_small.svg'),"disabled":['loading', 'pause'].includes(_vm.previewButtonState),"show":_vm.showPause},on:{"update:show":(val) => _vm.showPause = val},model:{value:(_vm.options.pause),callback:function ($$v) {_vm.$set(_vm.options, "pause", $$v)},expression:"options.pause"}})],1),_c('li',[_c('MenuItem',{attrs:{"items":_vm.listTone,"name":_vm.$t('tone'),"iconSrc":require('@/assets/images/icons/ic_tone_small.svg'),"disabled":['loading', 'pause'].includes(_vm.previewButtonState),"show":_vm.showTone},on:{"update:show":(val) => _vm.showTone = val},model:{value:(_vm.options.tone),callback:function ($$v) {_vm.$set(_vm.options, "tone", $$v)},expression:"options.tone"}})],1)]),_c('button',{staticClass:"som__preview-button btn d-flex justify-content-center align-items-center ml-1 text-white",class:{
      'som__preview-button--black': _vm.previewButtonState !== 'generate'
    },attrs:{"type":"button","title":_vm.previewButtonState === 'generate' ? 'Generate Preview'
      : _vm.previewButtonState === 'loading' ? 'Loading'
      : _vm.previewButtonState === 'play' ? 'Play Preview'
      : 'Pause Preview',"disabled":_vm.previewButtonState === 'loading'},on:{"click":_vm.handleClickPreviewButton}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.previewButtonState === 'loading'),expression:"previewButtonState === 'loading'"}]},[_c('b-spinner',{attrs:{"variant":"light","label":"Loading...","small":""}})],1),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.previewButtonState !== 'loading'),expression:"previewButtonState !== 'loading'"}],attrs:{"icon":`fa-solid ${_vm.previewButtonState === 'pause' ? 'fa-pause' : 'fa-play'}`}})],1),_c('audio',{ref:"audioPlayer",staticClass:"d-none",attrs:{"src":_vm.previewAudioSrc}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"som__title d-flex align-items-center py-1 pr-3 m-0"},[_c('img',{staticClass:"som__title-icon",attrs:{"src":require("@/assets/images/icons/ic_magic_wand_small.svg"),"alt":""}}),_vm._v(" AI Speech Edit ")])
}]

export { render, staticRenderFns }