import { render, staticRenderFns } from "./ChooseCloneType.vue?vue&type=template&id=7fc90be9&scoped=true&"
import script from "./ChooseCloneType.vue?vue&type=script&lang=js&"
export * from "./ChooseCloneType.vue?vue&type=script&lang=js&"
import style0 from "./ChooseCloneType.vue?vue&type=style&index=0&id=7fc90be9&prod&scoped=true&lang=css&"
import style1 from "./ChooseCloneType.vue?vue&type=style&index=1&id=7fc90be9&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc90be9",
  null
  
)

export default component.exports