<template>
	<div class="ag d-flex flex-column px-4 pt-3" :class="{ 'bg--lighter' : lighter }">
		<div class="position-relative" ref="suggestionAi">
			<textarea  @input="adjustHeight" :style="{height: computedHeight}" v-model="data.text" ref="inputText" class="ag__input-text text-white" spellcheck="false" @click="!data.text ? isSuggestionShown = true : ''" title="Type prompt here ...">
			</textarea>
			<AiGeneratorImageSuggestion :is-visible="isSuggestionShown" @suggest-prompt="(val) => data.text = val"/>
		</div>
		<section class="ag__bottom d-flex align-items-center">
			<tippy
				size="small"
				trigger="click"
				boundary="window"
				placement="bottom-start"
				theme="ag__settings"
				:distance="5"
				:max-width="750"
				:animate-fill="false"
				:duration="250"
				:on-show="() => isSettingsShown = true"
				:on-hide="() => {
				isSettingsShown = false;
				return true;
				}"
				interactive
			>
				<template v-slot:trigger>
				<button
					class="ag__settings-toggle btn d-inline-flex align-items-center px-2 py-1 text-white"
					:class="{ 'ag__settings-toggle--active': isSettingsShown }"
					:title="`${isSettingsShown ? 'Hide' : 'Show'} Settings`"
				>
				<img
					:src="require('@/assets/images/icons/ic_gear.svg')"
					class="ag__settings-toggle-icon"
					:class="{ 'ag__settings-toggle-icon--active': isSettingsShown }"
					alt=""
				>
				{{ $t('settings') }}
				</button>
				</template>

				<slot name="settings" />
			</tippy>

			<button
				type="button"
				class="ag__generate-button btn d-inline-flex align-items-center px-2 py-1 ml-auto"
				title="Generate"
				:disabled="!data.text || isGenerating"
				@click="$emit('generate')"
			>
				{{ isGenerating ? 'Generating' : 'Generate' }}
			</button>
		</section>
	</div>
</template>

<script>
import AiGeneratorImageSuggestion from '@/components/generative-ai-text-to-video/ai-generator/Suggestion.vue'

export default {
	components : {
		AiGeneratorImageSuggestion
	},

	data() {
		return {
			isSettingsShown: false,
			isSuggestionShown: false,
			data: this.value,
			placeholders: [
                'Design a mythical creature with the head of a lion, wings of an eagle, and the tail of a serpent.',
                'Illustrate a face expressing deep sadness and tears rolling down the cheeks.',
                'Generate an image resembling Leonardo da Vinci, the famous Italian artist and polymath.',
            ],
			lineHeight: 24
		}
	},
	computed: {
		computedHeight() {
			const numOfLines = this.data.text.split('\n').length;
			return `${numOfLines * this.lineHeight}px`;
		}
	},

	props: {
		isGenerating: {
			type: Boolean,
			default: false,
		},
		lighter: {
			type: Boolean,
			default: false,
		},
		value: {
			default: () => ({
				text: '',
				engine: '1'
			})
		},
	},

	watch: {
		value: {
			handler(newValue) {
				this.data = newValue;
			},
			deep: true,
		},
		data: {
			handler(newData) {
				this.$emit('input', newData);
			},
			deep: true,
		},
		'data.text'() {
			this.isSuggestionShown = false
		},
	},

	methods: {
		adjustHeight() {
			const textarea = this.$refs.inputText;
			textarea.style.height = 'auto'; 
			textarea.style.height = textarea.scrollHeight + 'px'; // Sesuaikan tinggi textarea
		},
		typingTextareaPlaceholder() {
			const speed = 100;
			const waitingStep = 50;

			let activePlaceholderIndex = 0;
			let currentChar = 0;
			let currentWaitingStep = 0;
			let placeholder = '';

			this.$watch('placeholders', () => {
				activePlaceholderIndex = 0;
				currentChar = 0;
				currentWaitingStep = 0;
				placeholder = '';
			});

			this.typingPlaceholderInterval = () => {
				if (this.placeholders && this.$refs.inputText) {
					if (this.$refs.inputText.getAttribute('placeholder') === this.placeholders[activePlaceholderIndex]) {
						if (currentWaitingStep >= waitingStep) {
							if (activePlaceholderIndex < this.placeholders.length - 1)  {
								activePlaceholderIndex++;
							} else {
								activePlaceholderIndex = 0;
							}

							currentChar = 0;
							currentWaitingStep = 0;
							placeholder = '';

							this.$refs.inputText.setAttribute('placeholder', placeholder);
						} else {
							currentWaitingStep++;
						}
					} else {
						placeholder += this.placeholders[activePlaceholderIndex].charAt(currentChar);
						this.$refs.inputText.setAttribute('placeholder', placeholder);
						currentChar++;
					}
				}
			};

			setInterval(this.typingPlaceholderInterval, speed);
		},
		hidePopoverWhenClickOutside(e) {
			if (!this.$refs.suggestionAi.contains(e.target) && this.isSuggestionShown) {
				this.isSuggestionShown = false
			}
		},
	},

	mounted() {
		this.typingTextareaPlaceholder();
		document.addEventListener('click', this.hidePopoverWhenClickOutside);
	},
	beforeDestroy() {
		document.removeEventListener('click', this.hidePopoverWhenClickOutside);
	},
}
</script>

<style scoped>
textarea{
	resize: none !important;
}
.ag {
  border-radius: 8px;
  border: 1px solid #6D75F6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.bg--lighter {
	background-color: #2D2D2D;
}

.ag__input-text {
  width: 100%;
  background: none;
  border: none;
  max-height: 300px;
}

.ag__input-text::placeholder {
  color: #585859;
}

.ag__textarea {
  width: 100%;
  height: 20px;
  max-height: 100px;
  background: none;
  border: none;
  resize: none;
}

.ag__textarea::placeholder {
  color: #585859;
}

.ag__textarea--ph {
  height: 42px !important;
}

.ag__bottom {
  gap: 8px;
  padding-block: 12px;
}

.ag__script-type-toggle-icon {
  font-size: 8px;
}

.ag__settings-toggle-icon {
  height: 14px;
  transition: rotate 0.5s;
}

.ag__voice-type-button {
  gap: 4px;
  padding: 2px 6px;
  border-radius: 16px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ag__voice-type-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.ag__settings-toggle {
  gap: 4px;
  background-color: #414141;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ag__settings-toggle--active {
  background-color: #515151;
}

.ag__settings-toggle:hover {
  background-color: #515151;
}

.ag__settings-toggle-icon--active {
  rotate: 270deg;
}

.ag__generate-button {
  gap: 8px;
  background-color: #6D75F6;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.25s;
}

.ag__generate-button:not(:disabled):hover {
  background-color: #545EED;
}

.ag__generate-button:disabled {
  background-color: #4D4D4D;
}

.ag--ovyv {
  overflow-y: visible;
}

.ag--ovyh {
  overflow: hidden;
}
</style>

<style>
.ag__script-type-toggle {
  gap: 4px;
  background: none !important;
  border: none;
  font-size: 12px;
}

.ag__script-type-menu {
  min-width: auto;
  background-color: #2D2D2D;
}

.ag__script-type-button {
  font-size: 12px;
  transition: background-color 0.25s;
}

.ag__script-type-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.ag__settings-toggle {
  gap: 4px;
  background-color: #414141 !important;
  border-radius: 4px;
  border: none;
  font-size: 12px;
}

.ag__settings-toggle:hover, .show > .ag__settings-toggle {
  background-color: #515151 !important;
}

.show > .ag__settings-toggle .ag__settings-toggle-icon {
  rotate: 270deg;
}

.ag__settings-menu {
  min-width: auto;
  background-color: #2D2D2D;
}

.ag__settings-theme {
  padding: 0 !important;
  border-radius: 8px;
  max-width: 450px !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}
</style>