<template>
	<div class="ma--img py-3 pl-3 w-100">
		<div class="d-flex justify-content-end">
			<div class="ma--img__subscription d-flex align-items-center dropdown mb-1">
				<img
					:src="require('@/assets/images/icons/ic_subscription_info.svg')"
					class="ma--img__subscription-icon"
					alt=""
				/>
				<div class="ma--img__tooltip dropdown-menu">
					<span> {{ $t('sisa') }} {{ allocation?.image_generates_quota ? allocation?.image_generates_quota : '0' }} {{ $t('image-generation-left') }} </span>
					<br>
					<span>{{ $t('upgrade--image-generator') }}</span>
				</div>
				<p class="ma--img__subscription-text m-0">{{ allocation?.image_generates_quota ? allocation?.image_generates_quota : '0' }} {{ $t('img') }}</p>
			</div>
		</div>
		<AiGeneratorImage lighter v-model="aiGeneratorData" :is-generating="isAiGenerating" @generate="handleGenerateAiImages">
			<template #settings>
				<div class="ac__settings align-items-center p-3 text-white text-left">
					<div class="ac__settings-section">
						<p class="ac__settings-title m-0">{{ $t('resolutions') }}</p>
						<span class="ac__settings-divider">:</span>
						<ul class="ac__settings-list d-flex m-0 list-unstyled">
							<li v-for="resolution in resolutions" :key="resolution.value">
								<button type="button" class="ac__settings-button ac__settings-button--resolution btn d-inline-flex justify-content-center align-items-center py-1 text-white" :class="{ 'ac__settings-button--active': resolution.value === aiGeneratorData.size }" :title="`Select ${resolution.value}`" @click="aiGeneratorData.size = resolution.value">
									{{ resolution.label }}
								</button>
							</li>
						</ul>
					</div>
					<div class="ac__settings-section">
						<p class="ac__settings-title m-0">{{ $t('outputs') }}</p>
						<span class="ac__settings-divider">:</span>
						<ul class="ac__settings-list d-flex m-0 list-unstyled">
							<li v-for="output in outputs" :key="output">
								<button type="button" class="ac__settings-button ac__settings-button--output btn d-inline-flex justify-content-center align-items-center py-1 text-white" :class="{ 'ac__settings-button--active': output === aiGeneratorData.output }" :title="`Select ${output}`" @click="aiGeneratorData.output = output">
									{{ output }}
								</button>
							</li>
						</ul>
					</div>
				</div>
			</template>
		</AiGeneratorImage>
		<ul class="agi__list mt-3 mb-0 list-unstyled">
			<li v-for="image in aiImages" :key="image.id" class="agi__item position-relative" :class="{ 'agi__item--selected' : selectedImage?.id === image.id }">
				<img :src="image.src" :alt="image.name" class="agi__item-image" />
				<div class="agi__item-actions d-flex flex-column justify-content-center position-absolute p-5" :class="{ 'active': activeTippy === image.id }">
					<tippy :to="`agi-action-toggle-${image.id}`" theme="agi__wrapper-tippy" trigger="click" boundary="window" placement="right" :animate-fill="false" :duration="250" :z-index="9999999999" :max-width="500" visible interactive @shown="activeTippy = image.id" @hidden="activeTippy = null">
						<ul class="agi-at--action d-flex flex-column list-unstyled text-left m-0">
							<li @click="downloadImage(image.src, image.name)">
								<a class="mx-2">{{ $t('download') }}</a>
							</li>
							<li @click="saveToMyAssets(image.src)">
								<a class="mx-2">{{ $t('save-as-assets') }}</a>
							</li>
						</ul>
					</tippy>
					<button :name="`agi-action-toggle-${image.id}`" class="agi__button-action btn p-0" type="button">
						<font-awesome-icon icon="fa-solid fa-ellipsis" class="agi__ba-icon"/>
					</button>
					<button type="button" class="agi__item-action-preview btn" :title="'Preview of ' + image.name" @click="previewImage(image.src, image.name)">
						{{ $t('preview') }}
					</button>
					<button type="button" class="agi__item-action btn text-white" :title="'Use ' + image.name" @click="$emit('click:new-image', image)">
						{{ $t('use') }}
					</button>
				</div>
			</li>
		</ul>
		<div class="note mt-3" v-if="aiImages.length > 0">
			<small class="agi__note">Important : <br> To preserve your images, save them as assets. Failure to do so may result in permanent deletion. <br> Take action now to secure your valuable data !</small>
		</div>
		<ImagePreviewModal id="image-preview-ma" :src="imagePreview.src" :alt="imagePreview.alt" />
	</div>
</template>

<script>
import AiGeneratorImage from '@/components/generative-ai-text-to-audio/ai-generator-image';
import ImagePreviewModal from '@/components/modal/ImagePreview';
import randomUUID from '@/helper/uuid';

import { getImageAiGenerator } from '@/services/ttv/ttv.service.js';
import { exportUtils } from "@/mixins/ExportUtils";
import { AlertUtils } from "@/mixins/AlertUtils";
import { base_url_machine_learning } from "../../../config/base_url"

import axios from 'axios'

export default {
	mixins:[exportUtils, AlertUtils],
	components : {
		AiGeneratorImage,
		ImagePreviewModal,
	},
	props: {
		image: {
			type: Object,
			default: null,
		},
		stateUpgrade: {
			type: Function,
			default: null,
		},
		allocation: {
			type: Object,
			default : () => {
				return null
			}
		},
	},

	watch : {
		image: {
			handler(newVal) {
				this.selectedImage = newVal
			},
			deep: true
		}
	},
	data() {
		return {
			outputs: [1, 2, 3],
			resolutions: [
				{
					icon : require('@/assets/images/icons/ar_11.svg'),
					label : '256px',
					value : '256x256'
				},
				{
					icon : require('@/assets/images/icons/ar_169.svg'),
					label : '512px',
					value : '512x512'
				},
				{
					icon : require('@/assets/images/icons/ar_45.svg'),
					label : '1024px',
					value : '1024x1024'
				},
				// {
				//   icon : require('@/assets/images/icons/ar_32.svg'),
				//   label : '3:2',
				//   value : '768x512'
				// },
			],
			aiGeneratorData: {
				text: '',
				engine: '1',
				size: '512x512',
				output: 2,
			},
			imagePreview: {
				src: '',
				alt: '',
			},
			aiImages : [],
      		isAiGenerating: false,
			selectedImage : this.image,
			activeTippy : null
		}
	},

	methods: {
		previewImage(src, alt) {
			this.imagePreview = { src, alt };
			this.$bvModal.show('image-preview-ma');
		},

		async handleGenerateAiImages() {
			this.aiImages = []
			this.isAiGenerating = true

			try {
				const res = await getImageAiGenerator(this.aiGeneratorData)

				if(res.status == 200) {
					for(const [index, image] of Object.entries(res.data)){
						this.$emit('update:allocation')
						this.aiImages.push({
							type: 'ai',
							id: randomUUID(),
							name: 'AI Image - ' + (Number(index) + 1),
							src: base_url_machine_learning + image
						});
					}
				} else if(res.status == 403) {
					this.$bvModal.show('img-quota-modal')
					this.aiGeneratorData.text = ''
				} else {
					this.$bvModal.show('server-error-modal');
				}
			} catch(error) {
				this.$bvModal.show('server-error-modal');
				console.error(error)
			}
			this.isAiGenerating = false;
		},

		async downloadImage(url, name) {
			try {
				const response = await axios.get(url, { responseType: 'blob' });
  				const blobImage = response.data;

				this.exportImage(blobImage, name ? name : 'Image')
				this.alertSuccess("Image successfully downloaded")
			} catch (error) {
				console.error('Error during fetch:', error);
				this.alertFail("Failed to download image.")
			}
		},

		saveToMyAssets(url) {
			this.$emit('click:stma', url)
		}
	}
}
</script>

<style scoped>
textarea{
	resize: none !important;
}
.ac__settings {
  display: grid;
  grid-template-columns: 70px 15px auto;
  gap: 8px 0;
  background-color: #2D2D2D;
  border-radius: 8px;
  font-size: 12px;
}

.ac__settings-section {
  display: contents;
}

.ac__settings-list {
  gap: 6px;
}

.ac__settings-button {
  gap: 4px;
  background-color: #414141;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ac__settings-button:not(.ac__settings-button--active):hover {
  background-color: #515151;
}

.ac__settings-button--active {
  background-color: #6D75F6;
}

.ac__settings-button--resolution {
  min-width: 50px;
}

.ac__settings-button--ratio {
  min-width: 50px;
}

.ac__settings-ratio-box {
  height: 12px;
  border-radius: 2px;
  border: 1px solid #FFFFFF;
}

.ac__settings-button--output {
  min-width: 40px;
}	

.agi__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 8px;
  padding-right: 24px;
  margin-right: 18px;
  overflow-y: auto;
}

.agi__item {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: background-color 0.25s, border 0.25s;
}

.agi__item--selected {
  border-color: #6D75F6;
}

.agi__item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.agi__item-actions {
  opacity: 0;
  visibility: hidden;

  inset: 0;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.25s, visibility 0.25s;
}

.agi__item-actions.active {
  opacity: 1;
  visibility: visible;
}

.agi__item:hover .agi__item-actions, .agi__item-actions.active {
  opacity: 1;
  visibility: visible;
}

.agi__item-action, .agi__item-action-preview {
  padding: 2px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.agi__item-action {
  	background-color: #6D75F6;
	color: white;
}

.agi__item-action-preview {
	color: #6D75F6;
	background-color: white;
}

.agi__item-action-preview:hover {
	background-color: #ffffffea;
}

.agi__item-action:hover {
  background-color: #545EED;
}

.agi__button-action {
	box-shadow: none;
	position: absolute;
	color: #fff;
	top: 10px;
	right: 10px;
	rotate: 90deg;
}

.agi__ba-icon {
	font-size: 16px;
}

.agi-at--action {
	padding: 5px;
	padding: 10px 0px;
}

.agi-at--action li {
	padding: 3px 0px;
	cursor: pointer;
}

.agi-at--action li:hover {
	background-color: #8c8c8c;
}

.agi__note {
	color: #8C8C8C;
}

.ma--img__subscription {
  gap: 6px;
}

.ma--img__subscription:hover .ma--img__tooltip {
  display: block;
}

.ma--img__tooltip {
  left: -265px;
  top: 0px;
  width: 260px !important;
  background-color: #1F1F1F;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}

.ma--img__subscription-icon {
  height: 14px;
}

.ma--img__subscription-text {
  font-size: 14px;
  font-weight: 500;
}
</style>

<style>
.agi__wrapper-tippy-theme {
	background-color: #2D2D2D;
	padding: 0;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}
</style>