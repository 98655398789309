<template>
	<b-modal :id="id" size="xl" content-class="mym__content" modal-class="my-assets__modal p-0" body-class="p-0" dialog-class="mym__dialog d-flex justify-content-center" centered hide-header hide-footer @show="getAssets('all')" @hidden="resetData">
		<template v-if="!isUpload">
			<header class="mym__header">
				<div class="container-fluid d-flex align-items-center justify-content-between">
					<h4 class="mym__title">{{ $t('uploud-file') }}</h4>
					<button
						class="btn btn-close d-flex justify-content-center align-items-center close-image"
						:disabled="isUpload"
						@click="$bvModal.hide(id), selectedImage = null"
					>
						<b-icon icon="x" />
					</button>
				</div>
			</header>
			<main class="mym__main">
				<div class="container-fluid h-100">
					<div class="mam__inner d-flex w-100 h-100">
						<div class="mym__col-first">
							<div class="mt-3 pr-3">
								<ul class="ma__tab-list d-flex flex-column list-unstyled">
									<li v-for="(tab, index) in tabs" :key="index">
										<button type="button" :disabled="isUpload" class="ma__side-tab-button d-flex align-items-center btn" :class="{ 'ma__side-tab-button--active' : index === activeTabIndex }" :title="`Open ` + tab.title" @click="activeTabIndex = index">
											<div class="container-fluid">
												<div class="row">
													<div class="col-2 d-flex align-items-center justify-content-center">
														<img :src="tab.iconTab" :alt="tab.title">
													</div>
													<div class="col-10 d-flex justify-content-between align-items-center">
														{{ tab.title }}
														<font-awesome-icon icon="fa-solid fa-chevron-right" class="ma__side-tab-icon mr-2"/>
													</div>
												</div>
											</div>
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div class="mym__col-second">
							<keep-alive>
								<component
									:is="tabs[activeTabIndex].component"
									:image="selectedImage"
									@click:new-image="handleClickImage"
									v-bind="tabs[activeTabIndex].props"
									v-on="tabs[activeTabIndex].events"
								/>
							</keep-alive>
						</div>
					</div>
				</div>
			</main>
			<footer class="mym__footer">
				<div class="container-fluid">
					<div class="mymf--action d-flex justify-content-end">
						<button type="button" class="button__footer-cancel btn" :disabled="isUpload" @click="$bvModal.hide(id), selectedImage = null">{{ $t('cancel') }}</button>
						<button type="button" class="button__footer-use btn" :disabled="!selectedImage && selectedMa.length == 0 " @click="handleUse">{{ $t('use') }}</button>
					</div>
				</div>
			</footer>
		</template>
		<template v-else>
			<div class="mym__loading d-flex flex-column align-items-center justify-content-center">
				<vue-lottie
					:options="animationOptions"
					:height="150" :width="150"
				></vue-lottie>
				<h5 class="font-weight-normal text-muted mt-1"><i>Uploading...</i></h5>
			</div>
		</template>
		<VideoTrimPreview id="vtp-modal" :video="videoTrim" :loading="isTrim" @click:trim-video="handleTrimVideo" @reset:video-trim="videoTrim = null"/>
		<RenameModal content v-model="fileName" @cancel-rename="handleCancelAction" @save-name="handleSaveName"/>
	</b-modal>
</template>

<script>
import MyAssets from './MyAssets.vue'
import ImageGenerator from './ImageGenerator.vue'
import Unsplash from './Unsplash.vue'
import VideoTrimPreview from '@/components/generate-videos/video-trim-preview';
import JSZip from 'jszip';
import RenameModal from '@/components/modal/RenameVoiceModel.vue'
import VueLottie from 'vue-lottie';
import loadingAnimation from '@/assets/soca-load.json';

import { saveAs } from 'file-saver';
import { AlertUtils } from "@/mixins/AlertUtils";
import { postVideos, postImageConverter, getAssets, getDetailAssets, trimVideos, saveToAssets, renameAsset, deleteAsset, deleteFolder } from "@/services/my-assets/my-assets.service"

export default {
	mixins: [AlertUtils],
	components: {
		VideoTrimPreview,
		RenameModal,
		VueLottie
	},
	props: {
		id: {
			type: String,
			default: 'my-assets-modal'
		},
		stateUpgrade: {
			type: Function,
			default: null,
		},
		allocation: {
			type: Object,
			default : () => {
				return null
			}
		},
	},

	data() {
		return {
			activeTabIndex: 0,
			selectedImage: null,
			folderName: null,
			videoTrim: null,
			isUpload: false,
			isDetail: false,
			isTrim: false,
			tabActive: this.$t('all'),
			myAssets: [],
			detailAssets: [],

			// select property
			selectedMa: [],
			selectAll: false,

			//detail folder
			idFolder: null,

			// Action
			fileName: null,
			idFile: null,

			animationOptions: {
                animationData: loadingAnimation,
                loop: true,
                autoplay: true,
                scale: 0.1,
            },
		}
	},
	computed: {
		tabs() {
			return [
				{
					title: 'My Assets',
					iconTab : require('@/assets/images/icons/ic_ma-asset.svg'),
					component: MyAssets,
					props: {
						assets: this.myAssets,
						detailAssets: this.detailAssets,
						activeTabs: this.tabActive,
						uploading: this.isUpload,
						isDetail: this.isDetail,
						folderName: this.folderName,
						selectAll: this.selectAll
					},
					events: {
						'click:upload-image' : this.handleUploadImage,
						'click:upload-video' : this.handleUploadVideo,
						'update:active-tab' : (newVal) => this.tabActive = newVal,
						'change:my-asset' : this.resetData,
						'click:open-folder' : this.handleOpenFolder,
						'click:download-all-image' : this.beforeHandleDownloadFolder,
						'click:rename-asset' : this.handleRenameAsset,
						'update:select-all' : (newVal) => this.selectAll = newVal,
						'click:delete-asset' : this.handleDeleteAsset
					}
				},
				{
					title: 'Image Generator',
					iconTab : require('@/assets/images/icons/ic_ma-ig.svg'),
					component: ImageGenerator,
					props: {
						stateUpgrade: this.stateUpgrade,
						allocation: this.allocation
					},
					events: {
						'click:handle-upgrade-gv' : this.handleUpgradeGv,
						'close:imgq-modal' : this.handleCloseModalQuotaImg,
						'click:stma' : this.handleStma,
						'update:allocation' : this.handleUpdateAlloc
					}
				},
				{
					title: 'Unsplash',
					iconTab : require('@/assets/images/icons/ic_ma-unsplash.svg'),
					component: Unsplash,
					props: {},
					events: {
						'click:stma' : this.handleStma
					}
				},
			]
		}
	},

	mounted() {
		this.getAssets('all')
	},

	watch: {
		tabActive(newVal) {
			this.getAssets(newVal)
		},

		selectAll(newVal) {
			this.populateAllAsset(newVal)
		},

		activeTabIndex() {
			this.idFolder = null
			this.selectAll = false
			this.selectedMa = []
			this.populateAllAsset(false)
		},

		myAssets: {
			handler() {
				this.pushSelectedAsset()
			},
			deep: true
		},

		detailAssets: {
			handler() {
				this.pushSelectedAsset()
			},
			deep: true
		},

		selectedMa: {
			handler(newVal) {
				if(newVal && newVal.length > 0) {
					this.selectedImage = null
				}
			},
			deep: true
		}
	},

	methods: {
		handleClickImage(val) {
			if(val) {
				if(val.file_type == "file_group") {
					this.selectAll = false
					this.populateAllAsset(false)
					if(this.selectedImage == val) {
						this.getDetailFileGroup(val.id, "use")
					}
					this.selectedImage = val
				} else if(val.file_type == 'video') {
					this.selectAll = false
					this.populateAllAsset(false)
					this.videoTrim = val
					this.$bvModal.show('vtp-modal')
				} else {
					if(val.type == 'upload') {
						if(this.isDetail) {
							let index = this.detailAssets.findIndex(item => item.id === val.id);
							this.detailAssets[index].selected = !this.detailAssets[index].selected
						} else {
							let index = this.myAssets.findIndex(item => item.id === val.id);
							this.myAssets[index].selected = !this.myAssets[index].selected
						}
					} else {
						if(this.selectedImage == val) {
							this.$emit('use:image', this.selectedImage)
						}
						this.selectedImage = val
					}
				}
			}
		},
		handleUse() {
			if(this.selectedImage?.file_type == "file_group") {
				this.getDetailFileGroup(this.selectedImage.id, "use")
			} else {
				if(this.selectedMa.length > 0) {
					this.selectedMa.forEach((e) => {
						this.$emit('use:image', {
							type: 'upload',
							id: e.id,
							name: e.name,
							src: e.src,
						})
					})
				} else {
					this.$emit('use:image', this.selectedImage)
				}
			}
		},
		handleStma(val) {
			let formData = {
				image_url : val
			}
			saveToAssets(formData)
			.then((response) => {
				if(response.status) {
					this.alertSuccess('Image successfully saved')
					this.getAssets(this.tabActive)
				} else {
					this.alertFailIcon('Image failed to save')
				}
			})
			.catch((error) => {
				this.alertFailIcon('Image failed to save')
				console.error(error)
			})
		},
		handleUploadImage(val) {
			this.postImage(val, 'upload');
		},
		async handleUploadVideo(file) {
			this.isUpload = true
			let formData = new FormData();
  			formData.append('file', file);

			await postVideos(formData)
			.then((response) => {
				if(response.status == 200) {
					this.alertSuccess('Video successfully uploaded')
					this.getAssets(this.tabActive)
				} else if(response.status == 405 || response.response.status == 405) {
					this.alertFailIcon('Video size must less than 20MB');
				}
				this.isUpload = false
			})
			.catch((error) => {
				this.alertFail('Something went wrong')
				this.isUpload = false
				console.error(error)
			})
		},
		async postImage(file, type) {
			this.isUpload = true
			let typeFile = this.getFileTypeText(file.type)
			let formData = new FormData();
  			formData.append('uploadfile', file);

			try {
				const res = await postImageConverter(formData)

				if(res.status) {
					this.isUpload = false
					this.alertSuccess(`${typeFile} successfully ${type == 'upload' ? 'uploaded' : 'saved'}`)
					await this.getAssets(this.tabActive)
				} else {
					this.isUpload = false
					this.alertFailIcon(res.message)
				}

			} catch (error) {
				this.isUpload = false
				console.error(error)
			}
		},
		async getAssets(type) {
			this.selectAll = false

			if(type == 'gambar'){
				type = 'image'
			}else if(type == 'semua'){
				type = 'all'
			}else if(type == 'dokumen'){
				type = 'files'
			}
			
			getAssets(type)
			.then((response) => {
				if(response.status) {
					this.myAssets = []
					console.log(response)
					console.log(type)
					let data = response.data ? response.data : []
					data.forEach(img => {
						this.myAssets.push({
							type: 'upload',
							id: img._id,
							name: img.filename,
							src: img.url,
							file_type: img.file_type,
							file_size: img.file_size,
							createdAt: img.createdAt,
							video_thumbnail: img.video_thumbnail || null,
							total_file: img.total_file || null,
							selected: false
						})
					})
				}
			})
		},
		getFileTypeText(fileType) {
			let fileTypeText = '';

			switch (fileType) {
				case 'image/jpeg':
				case 'image/png':
					fileTypeText = 'Image';
					break;
				case 'video/mp4':
				case 'video/quicktime':
					fileTypeText = 'Video';
					break;
				case 'application/pdf':
					fileTypeText = 'PDF';
					break;
				case 'application/vnd.ms-powerpoint':
				case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
					fileTypeText = 'PPT';
					break;
				default:
					fileTypeText = 'Image';
					break;
			}

			return fileTypeText;
		},
		async getDetailFileGroup(id, action) {
			await getDetailAssets(id)
			.then((response) => {
				if(response.status) {
					this.detailAssets = []
					let data = response.data ? response.data : []
					data.forEach(img => {
						if(action == 'use') {
							this.$emit('use:image', {
								type: 'upload',
								id: img._id,
								name: img.filename,
								src: img.url,
							})
						} else {
							this.detailAssets.push({
								type: 'upload',
								id: img._id,
								name: img.filename,
								src: img.url,
								file_type: img.file_type,
								file_size: img.file_size,
								createdAt: img.createdAt,
								selected: false
							})
						}
					})
					if(action == 'download') { this.handleDownloadFolder() }
					if(action == 'open') { this.isDetail = true }
				}
			})
		},
		handleTrimVideo(val) {
			this.isTrim = true
			if(val.isTrim) {
				let formData = {
					id_video : this.videoTrim.id,
					start : val.durationStartCalculate || 0,
					end : val.durationEnd,
					save_to_asset : "False"
				}

				trimVideos(formData)
				.then((response) => {
					if(response.status == 200) {
						let usedVideo = {
							type: 'video',
							id: response.data.id_video,
							name: this.videoTrim.name ? this.videoTrim.name : 'Video',
							src: this.videoTrim.video_thumbnail,
							videoDurationOrigin: val.durationEnd - val.durationStartCalculate || 0
						}
						this.$emit('use:image', usedVideo)
						this.$bvModal.hide('vtp-modal')
					}
					this.isTrim = false
				})
			} else {
				let usedVideo = {
					type: 'video',
					id: this.videoTrim.id,
					name: this.videoTrim.name ? this.videoTrim.name : 'Video',
					src: this.videoTrim.video_thumbnail,
					videoDurationOrigin: val.durationEnd - val.durationStartCalculate || 0
				}
				this.isTrim = false
				this.$emit('use:image', usedVideo)
				this.$bvModal.hide('vtp-modal')
			}
		},
		handleOpenFolder(val) {
			this.tabActive = 'all'
			this.selectedImage = null
			this.selectAll = false
			this.folderName = val.name
			this.idFolder = val.id
			this.populateAllAsset(false)
			this.getDetailFileGroup(val.id, "open")
		},
		beforeHandleDownloadFolder(val) {
			this.folderName = val.name
			this.getDetailFileGroup(val.id, "download")
		},
		async handleDownloadFolder() {
			if(this.detailAssets.length > 0) {
				const zip = new JSZip();

				await Promise.all(
					this.detailAssets.map(async (img) => {
						try {
							const response = await fetch(img.src);
							const blob = await response.blob();
							zip.file(`${img.name}`, blob);
						} catch (error) {
							console.error(`Error fetching image at URL: ${img.src}`, error);
						}
					})
				);

				// Generate ZIP
				await zip.generateAsync({ type: 'blob' }).then((content) => {
					saveAs(content, `${this.folderName || 'Folder'}.zip`);
					this.alertSuccess('Folder successfully downloaded')
				});

				await this.resetData()
			}
		},
		handleUpgradeGv() {
			this.$emit('click:handle-upgrade-gv')
		},
		handleCloseModalQuotaImg() {
			this.$emit('close:imgq-modal')
		},
		handleCancelAction() {
			this.fileName = null
			this.idFile = null
		},
		handleSaveName() {
			let formData = {
				rename : this.fileName
			}

			renameAsset(this.idFile, formData)
			.then((response) => {
				if(response.status) {
					this.getAssets(this.tabActive)
					if(this.isDetail && this.idFolder) {
						this.getDetailFileGroup(this.idFolder, "open")
					}
					this.alertSuccess('Changes saved successfully')
					this.$bvModal.hide('rvm-modal')
				} else {
					this.alertFailIcon('Changes failed save')
					this.$bvModal.hide('rvm-modal')
				}
			})
			.catch(error => {
				this.alertFailIcon('Changes failed save')
				this.$bvModal.hide('rvm-modal')
				console.error(error)
			})

		},
		handleRenameAsset(val) {
			this.idFile = val.id
			this.fileName = val.name.slice(0, val.name.lastIndexOf('.'))
			this.$bvModal.show('rvm-modal')
		},
		resetData() {
			this.selectedImage = null
			this.folderName = null
			this.videoTrim = null
			this.idFolder = null
			this.isDetail = false
			this.selectAll = false
			this.detailAssets = []
			this.selectedMa = []
		},
		populateAllAsset(val) {
			if(this.isDetail) {
				this.detailAssets.forEach(e => {
					if(e.file_type != 'file_group' && e.file_type != 'video'){
						e.selected = val
					}
				})
			} else {
				this.myAssets.forEach(e => {
					if(e.file_type != 'file_group' && e.file_type != 'video'){
						e.selected = val
					}
				})
			}
		},
		pushSelectedAsset() {
			this.selectedMa = []
			if(this.isDetail) {
				this.detailAssets.forEach(e => {
					if(e.file_type != 'file_group'  && e.file_type != 'video' && e.selected){
						this.selectedMa.push(e)
					}
				})
			} else {
				this.myAssets.forEach(e => {
					if(e.file_type != 'file_group'  && e.file_type != 'video' && e.selected){
						this.selectedMa.push(e)
					}
				})
			}
			console.log(this.selectedMa)
		},
		handleDeleteAsset(val) {
			console.log(val)
			if(val.file_type != 'file_group') {
				deleteAsset(val.id)
				.then((response) => {
					if(response.status) {
						this.getAssets(this.tabActive)
						if(this.isDetail && this.idFolder) {
							this.getDetailFileGroup(this.idFolder, "open")
						}
						this.alertSuccess("File deleted successfully")
					} else {
						this.alertFailIcon("File failed to delete")
					}
				})
				.catch((error) => {
					this.alertFailIcon("File failed to delete")
					console.error(error)
				})
			} else {
				deleteFolder(val.id)
				.then((response) => {
					if(response.status) {
						this.getAssets(this.tabActive)
						if(this.isDetail && this.idFolder) {
							this.getDetailFileGroup(this.idFolder, "open")
						}
						this.alertSuccess("Folder deleted successfully")
					} else {
						this.alertFailIcon("Folder failed to delete")
					}
				})
				.catch((error) => {
					this.alertFailIcon("Folder failed to delete")
					console.error(error)
				})
			}

		},
		handleUpdateAlloc() {
			this.$emit('update:allocation')
		}
	}
}
</script>

<style scoped>

.btn-close {
  width: 15px;
  height: 26px;
  background-color: #EDEDED;
  border-radius: 50%;
}

.mym__header {
	padding: 20px 25px;
	border-bottom: 0.1px solid #2D2D2D;
	height: var(--mym-header-height);
}

.mym__main {
	padding: 0px 25px;
	height: var(--mym-main-height);
}

.mym__footer {
	border-top: 0.1px solid #2D2D2D;
	height: var(--mym-footer-height);
}

.mym__loading {
	height: calc(var(--mym-header-height) + var(--mym-main-height) + var(--mym-footer-height));
}

.mym__title {
	font-size: 20px;
	font-weight: normal;
}

.mym__col-first {
	width: 28%;
	border-right: 0.1px solid #2D2D2D;
}

.mym__col-second {
	width: 72%;
}

.ma__side-tab-button {
	color: white;
	border-radius: 8px;
	background: #2D2D2D;
	font-weight: 500;
	width: 100%;
	font-size: 16px;
	padding: 12px 10px;
	gap: 6px;

	transition: background-color 0.25s;
}

.ma__side-tab-button--active {
	background-color: #6D75F6;
}

.ma__side-tab-button:not(.ma__side-tab-button--active):hover {
	background-color: #585859;
}

.ma__side-tab-icon {
	font-size: 12px;
}

.ma__tab-list {
	gap: 16px;
}

.mam__inner {

}

.mymf--action {
	gap: 8px;
	padding: 20px 25px;
}

.button__footer-cancel, .button__footer-use {
	border-radius: 8px;
	font-size: 16px;
	font-weight: 600;
	padding: 6px 20px;
	color: #fff;

	transition: background-color 0.25s
}

.button__footer-cancel {
	background-color: #2D2D2D;
}

.button__footer-cancel:hover {
	background-color: #2C2C2C;
}

.button__footer-use {
	background-color: #6D75F6;
}

.button__footer-use:disabled, .button__footer-cancel:disabled {
	background-color: #2D2D2D;
	color: #585859;
}

.button__footer-use:not(:disabled):hover {
	background-color: #545EED;
}
</style>

<style>
:root {
  --mym-header-height: 68px;
  --mym-main-height: 500px;
  --mym-footer-height: 79px;
}

.mym__dialog {
	max-width: none;
}

.mym__content {
	width: 1100px;
}

.my-assets__modal + .modal-backdrop {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
  	backdrop-filter: blur(2px);
	color: white;
}
</style>