<template>
  <section class="ql d-flex flex-column" :class="{ 'justify-content-between' : internalQuestions.length > 0, 'justify-content-end' : internalQuestions.length == 0 }">
    <VueDraggable
      v-if="internalQuestions.length > 0"
      tag="ul"
      ref="numberList"
      v-model="internalQuestions"
      class="ql__list d-flex flex-column list-unstyled"
      ghost-class="ql__list-item--ghost"
      :animation="200"
      @start="dragQuestion = true"
      @end="handleEndDragQuestion"
    >
      <transition-group
        type="transition"
        :name="!dragQuestion ? 'change-order' : null"
        class="ql__list-wrapper"
      >
        <li
          v-for="(question, index) in internalQuestions"
          :key="index + 1"
          class="ql__list-item position-relative"
        >
          <button
            type="button"
            class="ql__number-button btn d-flex flex-column justify-content-center align-items-center"
            :class="{
              'ql__number-button--invalid': validateQuestion(question) && index + 1 !== activeNumber,
              'ql__number-button--active': index + 1 === activeNumber,
            }"
            :title="`Question #${index + 1}`"
            :disabled="disabledAction"
            @click.self="$emit('click-number', index + 1)"
          >
            <QuestionView
              type="thumbnail"
              :value="question"
              :layout="question.layout"
            />
            <div class="ql__number-button-side-menu position-absolute d-flex flex-column justify-content-between align-items-center px-2">
              <span class="ql__number-button-text">{{ index + 1 }}</span>
              <div class="ql__number-button-actions d-flex flex-column">
                <button
                  v-if="question.layout !== 'code-test'"
                  type="button"
                  class="ql__number-button-action ql__number-button-action--duplicate btn d-inline-flex justify-content-center align-items-center p-0"
                  title="Duplicate"
                  @click="$emit('click:duplicate', index)"
                >
                  <span class="sr-only">Duplicate</span>
                  <img
                    :src="require('@/assets/images/icons/ic_duplicate.svg')"
                    alt="Duplicate"
                    width="10"
                  />
                </button>
                <button
                  type="button"
                  class="ql__number-button-action ql__number-button-action--delete btn d-inline-flex justify-content-center align-items-center p-0"
                  title="Delete"
                  @click="$emit('click:delete', index)"
                >
                  <span class="sr-only">Delete</span>
                  <img
                    :src="require('@/assets/images/icons/ic_delete_2.svg')"
                    alt="Delete"
                    width="10"
                  />
                </button>
              </div>
            </div>
          </button>
        </li>
      </transition-group>
    </VueDraggable>

    <div ref="addNewQuestion" class="ql__add position-relative pl-5">
      <button
        type="button"
        class="ql__add-button btn d-flex flex-column justify-content-center align-items-center"
        title="Add New Question"
        :disabled="disabledAction"
        @click="isLayoutsShown = !isLayoutsShown"
      >
        <span class="ql__add-button-icon-wrapper d-flex justify-content-center align-items-center">
          <font-awesome-icon icon="fa-solid fa-plus" class="ql__add-button-icon" />
        </span>
        Add New
      </button>

      <div
        class="ql__layout position-absolute d-flex flex-column"
        :class="{
          'ql__layout--snap-top': layoutsSnapPosition === 'top',
          'ql__layout--snap-bottom': layoutsSnapPosition === 'bottom',
          'ql__layout--show': isLayoutsShown
        }"
      >
        <h3 class="ql__layout-title">Choose layout</h3>

        <div class="ql__layout-wrapper d-flex flex-column">
          <ul class="ql__layout-block m-0 list-unstyled">
            <li>
              <button
                type="button"
                class="ql__layout-button btn d-flex flex-column justify-content-center align-items-center p-0 w-100"
                :title="blankTemplate.text"
                :disabled="disabledAction"
                @click="blankTemplate.onClick"
              >
                {{ blankTemplate.text }}
                <img :src="blankTemplate.imageSrc" :alt="blankTemplate.text" class="ql__layout-button-image" height="64" />
              </button>
            </li>
          </ul>
          <ul class="ql__layout-list m-0 list-unstyled">
            <li v-for="layout in layouts" :key="layout.text">
              <button
                type="button"
                class="ql__layout-button btn d-flex flex-column justify-content-center align-items-center p-0"
                :title="layout.text"
                :disabled="disabledAction"
                @click="layout.onClick"
              >
                {{ layout.text }}
                <img :src="layout.imageSrc" :alt="layout.text" class="ql__layout-button-image" height="64" />
              </button>
            </li>
          </ul>
        </div>

        <p class="ql__layout-divider m-0">Or</p>
        <button
          type="button"
          class="ql__import-button btn d-flex justify-content-center align-items-center"
          title="Import from Excel"
          @click="$emit('import')"
        >
          <img
            src="@/assets/images/icons/ic_excel_smaller.svg"
            alt="Excel"
            height="12"
          />
          Import from excel
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { base_url } from "../../../config/base_url";
import { v4 as uuidv4 } from 'uuid';

import VueDraggable from 'vuedraggable';
import QuestionView from '../question-view/QuestionView';

import isTTQQuestionValid from '@/helper/is-ttq-question-valid';

export default {
  components: {
    VueDraggable,
    QuestionView,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    activeNumber: {
      type: Number,
      default: 0,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['click-number', 'add-question', 'click:duplicate', 'click:delete', 'after-drag:question'],
  data() {
    return {
      internalQuestions: this.questions,
      dragQuestion: false,
      layoutsSnapPosition: 'top',
      isLayoutsShown: false,
      blankTemplate:{
        text: 'Start with a Blank Page',
        imageSrc: require('@/assets/images/layouts/blank.svg'),
        onClick: () => {
          this.$emit('add-question', {
            layout: 'blank',
            layoutThumbnail: base_url + '/layout-thumbnail/video_and_answers_wide.svg',
            point: null,
            duration: 30,
            video: '',
            option_1: '',
            option_2: '',
            option_3: '',
            option_4: '',
            correct_answer: null,
            image_url: null,
            voice: null,
            question: '',
            title: '',
            subtitle: '',
            text: null,
            idDum : uuidv4(),
            question_type: 'blank'
          });
          this.isLayoutsShown = false;
        },
      },
      layouts: [
        {
          text: 'Video & Answers',
          imageSrc: require('@/assets/images/layouts/video_and_answers.svg'),
          onClick: () => {
            this.$emit('add-question', {
              layout: 'text-only',
              layoutThumbnail: base_url + '/layout-thumbnail/video_and_answers_wide.svg',
              point: 10,
              duration: 30,
              video: '',
              option_1: '',
              option_2: '',
              option_3: '',
              option_4: '',
              correct_answer: null,
              image_url: null,
              voice: null,
              question: '',
              idDum : uuidv4(),
              question_type: 'Multiple Choice'
            });
            this.isLayoutsShown = false;
          },
        },
        {
          text: 'Audio Listening',
          imageSrc: require('@/assets/images/layouts/audio_listening.svg'),
          onClick: () => {
            this.$emit('add-question', {
              layout: 'text-only',
              layoutThumbnail: base_url + '/layout-thumbnail/audio_listening_wide.svg',
              point: 10,
              duration: 30,
              voice: '',
              option_1: '',
              option_2: '',
              option_3: '',
              option_4: '',
              correct_answer: null,
              image_url: null,
              video: null,
              question: '',
              idDum : uuidv4(),
              question_type: 'Multiple Choice'
            });
            this.isLayoutsShown = false;
          },
        },
        {
          text: 'Text & Image',
          imageSrc: require('@/assets/images/layouts/text_and_image_options.svg'),
          onClick: () => {
            this.$emit('add-question', {
              // layout: 'text-and-image-options',
              layout: 'text-only',
              layoutThumbnail: base_url + '/layout-thumbnail/text_and_image_options_wide.svg',
              point: 10,
              duration: 30,
              question: '',
              option_1: '',
              option_2: '',
              option_3: '',
              option_4: '',
              correct_answer: null,
              image_url : null,
              video: null,
              voice: null,
              idDum : uuidv4(),
              question_type: 'Multiple Choice'
            });
            this.isLayoutsShown = false;
          },
        },
        {
          text: 'True False',
          imageSrc: require('@/assets/images/layouts/true_false.svg'),
          onClick: () => {
            this.$emit('add-question', {
              layout: 'true-false',
              layoutThumbnail: base_url + '/layout-thumbnail/true_false_wide.svg',
              point: 10,
              duration: 30,
              question: '',
              option_1: 'TRUE',
              option_2: 'FALSE',
              correct_answer: null,
              image_url: null,
              video: null,
              voice: null,
              idDum : uuidv4(),
              question_type: 'True or False'
            });
            this.isLayoutsShown = false;
          },
        },
        {
          text: 'Text Options',
          imageSrc: require('@/assets/images/layouts/fill_the_blank.svg'),
          onClick: () => {
            this.$emit('add-question', {
              layout: 'text-only',
              layoutThumbnail: base_url + '/layout-thumbnail/fill_the_blank_wide.svg',
              point: 10,
              duration: 30,
              question: '',
              option_1: '',
              option_2: '',
              option_3: '',
              option_4: '',
              correct_answer: null,
              image_url : null,
              video : null,
              voice : null,
              idDum : uuidv4(),
              question_type: 'Multiple Choice'
            });
            this.isLayoutsShown = false;
          },
        },
        {
          text: 'Video & Images',
          imageSrc: require('@/assets/images/layouts/video_and_images.svg'),
          onClick: () => {
            this.$emit('add-question', {
              // layout: 'video-and-images',
              layout: 'text-only',
              layoutThumbnail: base_url + '/layout-thumbnail/video_and_images_wide.svg',
              point: 10,
              duration: 30,
              question: '',
              option_1: '',
              option_2: '',
              option_3: '',
              option_4: '',
              correct_answer: null,
              image_url : null,
              video: '',
              voice: null,
              idDum : uuidv4(),
              question_type: 'Multiple Choice'
            });
            this.isLayoutsShown = false;
          },
        },
        // {
        //   text: 'Code Test (For Testing)',
        //   imageSrc: require('@/assets/images/layouts/video_and_images.svg'),
        //   onClick: () => {
        //     this.$emit('add-question', {
        //       layout: 'code-test',
        //       layoutThumbnail: require('@/assets/images/layouts/code_test_wide.svg'),
        //       point: 10,
        //       duration: 30,
        //       question: '<p>Modul apa yang digunakan untuk melakukan koneksi ke basis data MySQL di Python?</p>',
        //       code: 'import mysql.connector\n\nmydb = mysql.connector.connect(\n\thost="localhost",\n\tuser="username",\n\tpassword="password",\n\tdatabase="mydatabase",\n)\nprint(mydb)',
        //       codeLanguage: 'py',
        //       option_1: 'mysql.connector',
        //       option_2: 'sqlite.connector',
        //       option_3: 'postgresql.connector',
        //       option_4: 'mongodb.connector',
        //       correct_answer: 1,
        //     });
        //     this.isLayoutsShown = false;
        //   },
        // },
      ],
      isTTQQuestionValid,
    };
  },
  watch: {
    questions(newQuestions) {
      this.internalQuestions = newQuestions;
    },
    isLayoutsShown(newStatus) {
      if(newStatus) {
        this.$emit('update:hide-prompt', false)
      }
    }
  },
  methods: {
    setLayoutsSnapPosition() {
      this.layoutsSnapPosition = this.$refs.addNewQuestion.getBoundingClientRect().bottom < window.screen.height / 2 ? 'top' : 'bottom';
    },
    closeLayoutsWhenClickOutside(e) {
      if (this.isLayoutsShown && !this.$refs.addNewQuestion.contains(e.target)) {
        this.isLayoutsShown = false;
      }
    },
    handleEndDragQuestion(e) {
      this.dragQuestion = false;

      const oldNumber = e.oldIndex + 1;
      const newNumber = e.newIndex + 1;

      if (oldNumber !== newNumber) {
        this.$emit('after-drag:question', this.internalQuestions, oldNumber, newNumber);
      }
    },
    validateQuestion(question) {
      if(question.layout != 'blank') {
        return !isTTQQuestionValid(question)
      } else {
        let title = !!question.title?.trim() && question.title?.trim() !== '<p></p>'
        if(title) {
          return false
        }
      }

      return true
    }
  },
  mounted() {
    this.setLayoutsSnapPosition();
    window.addEventListener('resize', this.setLayoutsSnapPosition);
    document.addEventListener('click', this.closeLayoutsWhenClickOutside);
  },
  updated() {
    this.setLayoutsSnapPosition();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setLayoutsSnapPosition);
    document.removeEventListener('click', this.closeLayoutsWhenClickOutside);
  },
};
</script>

<style scoped>
.ql {
  gap: 12px;
  padding: 1.5rem 0.65rem 1.5rem 0;
  border-right: 1px solid #2D2D2D;
}

.ql__list {
  gap: 12px;
  margin: 0;
  padding-left: 12px;
  padding-right: 0.85rem;
  overflow-x: visible;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.ql__list-wrapper {
  display: contents;
}

.ql__list-item {
  padding-left: 36px;
}

.ql__number-button {
  padding: 4px;
  font-size: 18px;
  font-size: 600;
  transition: opacity 0.5s;
}

.ql__number-button--invalid  {
  border: 1px solid #F34D4D;
}

.ql__number-button--active  {
  border: 1px solid #6D75F6;
}


.ql__number-button-image {
  height: 100%;
}

.ql__number-button-side-menu {
  inset: 0 auto 0 0;
  gap: 4px;
}

.ql__number-button-text {
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
}

.ql__number-button-actions {
  gap: 4px;
}

.ql__number-button-action {
  visibility: hidden;
  opacity: 0;

  width: 20px;
  height: 20px;
  background-color: #1F1F1F;
  transition: visibility 0.25s, opacity 0.25s, background-color 0.25s;
}

.ql__number-button:hover .ql__number-button-action {
  visibility: visible;
  opacity: 1;
}

.ql__number-button-action:hover {
  background-color: #3F3F3F;
}

.ql__number-button-action--delete:hover {
  background-color: #F34D4D;
}

.ql__add {
  padding-right: 0.85rem;
}

.ql__add-button {
  font-size: 12px;
}

.ql__number-button, .ql__add-button {
  width: 138px;
  height: 78px;
  gap: 8px;
  background-color: #1F1F1F;
  color: #FFFFFF;
  transition: background-color 0.25s, border 0.25s;
}

:is(.ql__number-button, .ql__add-button):hover {
  background-color: #282828;
}

.ql__add-button-icon-wrapper {
  width: 24px;
  height: 24px;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.ql__add-button-icon {
  font-size: 16px;
  color: #1F1F1F;
}

.ql__layout {
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  left: calc(100% - 16px);

  z-index: 600;
  gap: 8px;
  padding: 16px 8px;
  background-color: #2D2D2D;
  border-radius: 10px;
  transition: all 0.25s;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}

.ql__layout--snap-top {
  top: 0;
  transform-origin: top left;
}

.ql__layout--snap-bottom {
  bottom: 0;
  transform-origin: bottom left;
}

.ql__layout--show {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  left: 100%;
}

.ql__layout-title {
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
}

.ql__layout-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 8px;
}

.ql__layout-block{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px 8px;
}


.ql__layout-button {
  gap: 4px;
  font-size: 10px;
  color: #FFFFFF;
}

.ql__layout-button-image {
  border: 1px solid transparent;
  border-radius: 4px;
  transition: border 0.25s;
}

.ql__layout-button:hover .ql__layout-button-image {
  border: 1px solid #6D75F6;
}

.ql__layout-divider {
  font-size: 11px;
  color: #565656;
}

.ql__import-button {
  gap: 4px;
  border: 1px solid #4D9940;
  color: #4D9940;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ql__import-button:hover {
  background-color: #4D994011;
}

.ql__layout-wrapper {
  gap: 8px;
}
</style>

<style>
.ql__list-item--ghost .ql__number-button {
  opacity: 0.5;
}

.change-order-move {
  transform: all 0.5s;
}
</style>
