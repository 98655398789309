<template>
  <div class="se d-flex flex-column" :class="{ 'se--active' : isActive }">
    <header class="se__header d-flex align-items-center px-3 py-2">
      <button
        type="button"
        class="se__voice-type-button btn d-inline-flex align-items-center px-2 py-1 text-white"
        title="Select Voice Type"
        @click="$emit('click:voice-type')"
      >
        <img
          :src="characterAvatarSrc"
          class="rounded-circle"
          width="20"
          height="20"
          :alt="characterName"
        />
        {{ characterName }}
      </button>

      <button
        type="button"
        class="se__expand-button btn d-inline-flex justify-content-center align-items-center p-0 ml-auto text-white"
        title="Expand"
        @click="$emit('show:expanded-script-editor'); $bvModal.show(expandedModalId)"
      >
        <font-awesome-icon icon="fa-solid fa-expand" />
      </button>

      <button
        type="button"
        class="se__play-button btn d-inline-flex justify-content-center align-items-center p-0 text-white"
        :class="{
          'se__play-button--black': playButtonState !== 'generate'
        }"
        :title="
          playButtonState === 'generate' ? 'Generate Voice'
          : playButtonState === 'loading' ? 'Loading'
          : playButtonState === 'play' ? 'Play'
          : 'Pause'
        "
        :disabled="disableAction || playButtonState === 'loading'"
        @click="$emit('click:action', playButtonState)"
      >
        <div v-show="playButtonState === 'loading'" class="se__play-button-spinner-wrapper">
          <b-spinner
            variant="light"
            label="Loading..."
            class="se__play-button-spinner"
            small
          />
        </div>
        <font-awesome-icon
          v-show="playButtonState !== 'loading'"
          :icon="`fa-solid ${playButtonState === 'pause' ? 'fa-pause' : 'fa-play'}`"
        />
      </button>

      <b-dropdown
        boundary="window"
        toggle-class="se__options-toggle d-inline-flex justify-content-center align-items-center p-0"
        menu-class="se__options-menu"
        :disabled="playButtonState === 'loading'"
        title="Options"
        no-caret
      >
        <template #button-content>
          <img
            :src="require('@/assets/images/icons/ic_options_editor.svg')"
            alt="Options"
            height="14"
          />
          <span class="sr-only">Options</span>
        </template>
        <b-dropdown-item-button
          button-class="se__options-button d-flex align-items-center px-3 py-1 text-white"
          title="Delete"
          :disabled="disableDelete || playButtonState === 'loading'"
          @click="$emit('delete')"
        >
          Delete
        </b-dropdown-item-button>
      </b-dropdown>
    </header>
    <section class="se__main d-flex flex-column">
      <TTAScriptEditor
        v-model="scriptText"
        :placeholder="placeholder"
        small
        @get-editor="(editor) => $emit('get:editor', editor)"
        @focus="handleFocusEditor"
        @blur="handleBlurEditor"
      />
      <!-- <textarea
        ref="textarea"
        v-model="scriptText"
        class="se__textarea px-3 py-3 text-white"
        placeholder="Input your text"
        spellcheck="false"
        :readonly="actionState === 'loading'"
        title="Type script here ..."
      /> -->
    </section>

    <b-modal
      :id="expandedModalId"
      size="xl"
      body-class="p-0"
      centered
      hide-header
      hide-footer
      busy
    >
      <header class="se__header se__header--expanded d-flex align-items-center px-3 py-2">
        <button
          type="button"
          class="se__voice-type-button se__voice-type-button--expanded btn d-inline-flex align-items-center px-2 py-1 text-white"
          title="Select Voice Type"
          @click="$emit('click:voice-type')"
        >
          <img
            :src="characterAvatarSrc"
            class="rounded-circle"
            width="25"
            height="25"
            :alt="characterName"
          />
          {{ characterName }}
        </button>

        <button
          type="button"
          class="se__expand-button se__expand-button--expanded btn d-inline-flex justify-content-center align-items-center p-0 ml-auto text-white"
          title="Compress"
          @click="$emit('hide:expanded-script-editor'); $bvModal.hide(expandedModalId)"
        >
          <font-awesome-icon icon="fa-solid fa-compress" />
        </button>

        <button
          type="button"
          class="se__play-button se__play-button--expanded btn d-inline-flex justify-content-center align-items-center p-0 text-white"
          :class="{
            'se__play-button--black': playButtonState !== 'generate'
          }"
          :title="
            playButtonState === 'generate' ? 'Generate Voice'
            : playButtonState === 'loading' ? 'Loading'
            : playButtonState === 'play' ? 'Play'
            : 'Pause'
          "
          :disabled="disableAction || playButtonState === 'loading'"
          @click="$emit('click:action', playButtonState, true)"
        >
          <div v-show="playButtonState === 'loading'" class="se__play-button-spinner-wrapper">
            <b-spinner
              variant="light"
              label="Loading..."
              class="se__play-button-spinner se__play-button-spinner--expanded"
              small
            />
          </div>
          <font-awesome-icon
            v-show="playButtonState !== 'loading'"
            :icon="`fa-solid ${playButtonState === 'pause' ? 'fa-pause' : 'fa-play'}`"
          />
        </button>

        <b-dropdown
          boundary="window"
          toggle-class="se__options-toggle se__options-toggle--expanded d-inline-flex justify-content-center align-items-center p-0"
          menu-class="se__options-menu"
          :disabled="playButtonState === 'loading'"
          title="Options"
          no-caret
        >
          <template #button-content>
            <img
              :src="require('@/assets/images/icons/ic_options_editor.svg')"
              alt="Options"
              height="16"
            />
            <span class="sr-only">Options</span>
          </template>
          <b-dropdown-item-button
            button-class="se__options-button d-flex align-items-center px-3 py-1 text-white"
            title="Delete"
            :disabled="disableDelete || playButtonState === 'loading'"
            @click="handleClickDeleteFromExpandedModal"
          >
            {{ $t('delete') }}
          </b-dropdown-item-button>
        </b-dropdown>
      </header>
      <section class="se__main d-flex flex-column">
        <TTAScriptEditor
          v-model="scriptText"
          placeholder="Input your text"
          fullscreen
          :disable-ssml="disableSsml"
          @get-editor="(editor) => $emit('get:expanded-editor', editor)"
          @generate-preview-ssml="(object) => $emit('generate:preview-ssml', object)"
        />
      <!-- <p class="m-0 mr-3 text-counter ml-auto mb-2">{{ scriptText.length }} / 5000</p> -->
      </section>
      <footer class="se__footer px-3 py-3">
        <div class="se__footer-inner px-3 py-2">
          <p class="m-0 se__footer-inner--text">{{ $t('script-editor--note') }}</p>
        </div>
      </footer>
    </b-modal>
  </div>
</template>

<script>
import TTAScriptEditor from '@/components/tta-script-editor/TTAScriptEditor';

export default {
  components: {
    TTAScriptEditor,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    actionState: {
      type: String,
      validator(value) {
        return ['generate', 'loading', 'play', 'pause'].includes(value);
      },
      default: 'generate',
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    characterAvatarSrc: {
      type: String,
      default: require('@/assets/images/icons/sovia_editor.svg'),
    },
    characterName: {
      type: String,
      default: 'Sovia',
    },
    disableSsml: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'change:text',
    'click:voice-type',
    'click:action',
    'delete',
    'get:editor',
    'get:expanded-editor',
    'show:expanded-script-editor',
    'hide:expanded-script-editor',
    'generate:previeww-ssml',
  ],
  data() {
    return {
      isMounted: false,
      scriptText: this.text,
      playButtonState: this.actionState,
      placeholder: ''
    };
  },
  computed: {
    expandedModalId() {
      return `${this._uid}-expanded-script-editor`;
    },
  },
  watch: {
    actionState(newActionState) {
      this.playButtonState = newActionState;
    },
    text(newText) {
      this.scriptText = newText;
    },
    scriptText: {
      handler(newScriptText) {
        this.$emit('change:text', newScriptText);

        // if (this.isMounted) {
        //   this.autoResizeTextarea();
        // }
      },
      immediate: true,
    },
  },
  methods: {
    autoResizeTextarea() {
      const defaultHeight = 55;

      this.$refs.textarea.style.height = defaultHeight + 'px';
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
    },
    handleClickDeleteFromExpandedModal() {
      const modalDurationInMs = 250;

      this.$bvModal.hide(this.expandedModalId);
      setTimeout(() => {
        this.$emit('delete');
      }, modalDurationInMs);
    },
    handleFocusEditor() {
      this.placeholder = ''
      this.$emit('focus:box-script')
    },
    handleBlurEditor() {
      this.placeholder = 'Input your text'
      this.$emit('blur:box-script')
    }
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped>
.se {
  background-color: #1F1F1F;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid transparent;

  transition: border 0.25s;
}

.se--active {
  border: 1px solid #6D75F6;
}

.se__header {
  gap: 8px;
  border-bottom: 0.5px solid #2D2D2D;
}

.se__header--expanded {
  gap: 10px;
}

.se__footer {
  border-top: 0.5px solid #2D2D2D;
}

.se__footer-inner {
  border-radius: 6px;
  background: #2D2D2D;
  width: 100%;
}

.se__footer-inner--text {
  color: #D9D9D9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.se__voice-type-button {
  gap: 6px;
  border-radius: 20px;
  border: 1px solid #2D2D2D;
  font-size: 12px;
  transition: background-color 0.25s;
}

.se__voice-type-button--expanded {
  font-size: 14px;
}

.se__voice-type-button:hover {
  background-color: #414141;
}

.se__expand-button {
  width: 20px;
  height: 20px;
  background-color: #2d2d2d;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.se__expand-button--expanded {
  width: 25px;
  height: 25px;
  font-size: 16px;
}

.se__expand-button:hover {
  background-color: #3D3D3D;
}

.se__play-button-spinner-wrapper {
  display: contents;
}

.se__play-button {
  width: 20px;
  height: 20px;
  background-color: #6D75F6;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.se__play-button:disabled {
  background-color: #3D3D3D;
}

.se__play-button--expanded {
  width: 25px;
  height: 25px;
  font-size: 16px;
}

.se__play-button:not(:disabled):hover {
  background-color: #545EED;
}

.se__play-button--black {
  background-color: #3D3D3D;
}

.se__play-button--black:not(:disabled):hover {
  background-color: #4D4D4D;
}

.se__play-button-spinner {
  width: 12px;
  height: 12px;
}

.se__play-button-spinner--expanded {
  width: 16px;
  height: 16px;
}

.se__textarea {
  width: 100%;
  height: 55px;
  max-height: 100px;
  background: none;
  border: none;
  resize: none;
}

.se__textarea::placeholder {
  color: #585859;
}

.text-counter {
  font-size: 10px;
  color: #8c8c8c;
}
</style>

<style>
.se__options-toggle {
  width: 20px;
  height: 20px;
  background-color: transparent !important;
  border-radius: 4px;
  border: none;
  transition: background-color 0.25s;
}

.se__options-toggle--expanded {
  width: 25px;
  height: 25px;
}

.se__options-toggle:not(:disabled):hover, .show > .se__options-toggle {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.se__options-menu {
  min-width: auto;
  background-color: #2D2D2D;
}

.se__options-button {
  gap: 8px;
  font-size: 14px;
  transition: background-color 0.25s;
}

.se__options-button:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
