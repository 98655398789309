<template>
  <section class="ctv d-flex flex-column text-left" :class="isGenerate ? 'position-relative' : 'px-5 py-3'">
      <header class="d-flex justify-content-between">
        <div class="ml-auto">
          <QuestionOptions
            v-model="question"
            @delete-question="$emit('delete-question')"
          />
        </div>
      </header>

      <div class="ctv__editor-wrapper">
        <CodeEditor
          :language="question.codeLanguage || 'py'"
          :value="question.code"
          title="Block Code"
          readonly
        />
      </div>

      <div
        v-html="question.question"
        class="ctv__question"
      />

      <div class="ctv__answers d-flex flex-column">
        <p class="ctv__answers-title py-1 m-0">Answer Choices</p>
        <ul class="ctv__answer-list m-0 list-unstyled">
          <li v-for="(answer, index) in [question.option_1, question.option_2, question.option_3, question.option_4]" :key="index">
            <div
              class="ctv__answer-text d-flex justify-content-center align-items-center p-4"
              :class="{
                'ctv__answer-text--correct' : question.correct_answer === (index + 1).toString(),
                'ctv__answer-text--h100' : type == 'default',
                'ctv__answer-text--h65' : type == 'thumbnail',
              }"
            >
              <span v-html="answer"></span>
            </div>
          </li>
        </ul>
      </div>
    <Soca v-if="isGenerate" type="generate"/>
  </section>
</template>

<script>
import QuestionOptions from './QuestionOptions';
import CodeEditor from '@/components/code-editor/CodeEditor';
import Soca from '@/components/loading/Soca'

export default {
  components: {
    QuestionOptions,
    CodeEditor,
    Soca
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    type: {
      validator(value) {
        return ['default', 'thumbnail'].includes(value);
      },
      default: 'default',
    },
  },
  emits: ['input', 'delete-question'],
  data() {
    return {
      question: this.value,
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        this.$emit('input', newQuestion);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.question = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.ctv {
  gap: 16px;
  max-height: 100%;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.ctv__editor-wrapper {
  overflow-y: auto;
}

.ctv__question {
  font-size: 18px;
}

.ctv__answers {
  gap: 16px;
}

.ctv__answers-title {
  color: #8C8C8C;
  border-bottom: 1px solid #2D2D2D;
}

.ctv__answer-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.ctv__answer-text {
  background-color: #2D2D2D;
  border-radius: 10px;
  font-size: 18px;
  color: #FFFFFF;
  overflow-y: auto;
}

.ctv__answer-text span p {
  margin: 0 !important;
}

.ctv__answer-text--correct {
  background-color: #40BE45;
}

.ctv__answer-text::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.ctv__answer-text::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Thumb (scrollbar) */
.ctv__answer-text::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 999px;
}

.ctv__answer-text::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.ctv__answer-text--h100{
  height: 100px
}

.ctv__answer-text--h65{
  height: 65px
}
</style>

<style>
.ctv__question > * {
  margin: 0;
}

.ctv__question > * + * {
  margin-top: 16px;
}
</style>
