import axios from "../../config/axios"
import customFetch from '@/config/custom-fetch';
import { base_url_machine_learning } from '../../config/base_url'

export async function getGeneratedTextToAudio(formData) {
    const finalFormData = JSON.stringify({
        ...formData,
        params: {
            ...formData.params,
            stream: 'true',
        },
    });

    return customFetch(base_url_machine_learning + 'tts/v2', {
        method: 'POST',
        cache: 'no-cache',
        keepalive: true,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'text/event-stream',
        },
        body: finalFormData,
    });
}

export async function getGeneratedTextToAudioWithAgreements(formData) {
    return axios.post(base_url_machine_learning + 'tts/v1/user-agreements', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = error.response.data
            return data;
        })
}

export async function getGeneratedTextToAudioSSML(formData) {
    const finalFormData = {
        ...formData,
        params: {
            ...formData.params,
            ssml: 'true',
        },
    };

    return axios.post(base_url_machine_learning + 'tts/v2/audio', finalFormData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = error.response.data
            return data;
        })
}

export async function getGeneratedTextToAudioFinal(formData) {
    return axios.post(base_url_machine_learning + 'prosa/final', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getJobStatus(id) {
    return axios.get(base_url_machine_learning + 'jobs_status?job_id=' + id)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function importScriptAudio(formData, config) {
    return axios.post('/api/contents/import/audio', formData, config)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getNewsScrapper(formData) {
    return axios.post(base_url_machine_learning + 'scrapper', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}