<template>
  <NodeViewWrapper as="span">
    <span
      class="math-equation"
      draggable="true"
      title="Drag"
      data-drag-handle
    >
      <span
        :key="node.attrs.tex"
        class="math-equation__tex"
        v-katex:auto
      >
        {{ node.attrs.tex }}
      </span>
      <ul class="math-equation__buttons">
        <li class="math-equation__buttons-item">
          <button
            type="button"
            class="math-equation__button math-equation__button--edit"
            title="Edit"
            @click="handleEdit"
          >
            <font-awesome-icon icon="fa-solid fa-pen" />
          </button>
        </li>
        <li class="math-equation__buttons-item">
          <button
            type="button"
            class="math-equation__button math-equation__button--remove"
            title="Remove"
            @click="deleteNode()"
          >
            <font-awesome-icon icon="fa-solid fa-trash" />
          </button>
        </li>
      </ul>
    </span>
  </NodeViewWrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';

import MathEquationModal, {
  properties as mathEquationModalProperties
} from '../modals/MathEquationModal.vue';

export default {
  components: {
    NodeViewWrapper,
  },
  props: {
    nodeViewProps,
  },
  methods: {
    handleEdit() {
      this.$modal.show(
        MathEquationModal,
        {
          onInsert: (tex) => {
            if (tex) {
              this.updateAttributes({ tex });
            } else {
              this.deleteNode();
            }
          },
          value: this.node.attrs.tex.replace(/\\\(\s|\s\\\)/g, ''),
        },
        mathEquationModalProperties,
      );
    },
  },
};
</script>

<style scoped>
.math-equation {
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
  cursor: grab;
  transition: padding 0.25s, background-color 0.25s;
}

.math-equation:hover {
  padding: 0.75rem 1.75rem 0.75rem 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
}

.math-equation__tex {
  user-select: text;
}

.math-equation__buttons {
  visibility: hidden;
  opacity: 0;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  transition: visibility 0.25s, opacity 0.25s;
}

.math-equation:hover .math-equation__buttons {
  visibility: visible;
  opacity: 1;
}

.math-equation__buttons-item {
  flex-grow: 1;
}

.math-equation__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 100%;
  font-size: 0.75rem;
  color: #fff;
  border: none;
}

.math-equation__button--edit {
  background-color: rgba(255, 255, 255, 0.2);
}

.math-equation__button--remove {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
