<template>
  <form
    method="post"
    class="image-popover"
    @submit.prevent="handleSubmit"
  >
    <img
      v-if="src"
      class="image-popover__image"
      loading="lazy"
      :src="src"
      :alt="alt"
      :title="alt"
    >
    <div class="image-popover__src">
      <input
        type="text"
        class="image-popover__src-input"
        placeholder="Image URL"
        title="Image URL"
        :readonly="useUploadedImage"
        v-model="src"
        required
      />
      <label
        tabindex="0"
        class="image-popover__src-upload-label"
        title="Upload Image"
      >
        <input
          type="file"
          accept="image/*"
          class="image-popover__src-upload-input"
          @change="handleUploadImage"
        />
        <font-awesome-icon icon="fa-solid fa-upload" />
      </label>
    </div>
    <input
      type="text"
      class="image-popover__alt-input"
      placeholder="Image Alt/Title Text"
      title="Image Alt/Title Text"
      v-model="alt"
    />
    <button
      type="submit"
      class="image-popover__insert-button"
      title="Insert Image"
    >
      Insert Image
    </button>
  </form>
</template>

<script>
export default {
  props: {
    onInsert: {
      type: Function,
      required: true,
    },
  },
  emits: ['hidePopover'],
  data() {
    return {
      src: '',
      alt: '',
      useUploadedImage: false,
    };
  },
  methods: {
    handleUploadImage(e) {
      const fileReader = new FileReader();

      const handleChangeSrc = (newSrc) => this.src = newSrc;

      fileReader.addEventListener('load', (frEvent) => {
        handleChangeSrc(frEvent.target.result);
      });

      fileReader.readAsDataURL(e.target.files[0]);

      this.useUploadedImage = true;
    },
    handleSubmit() {
      this.onInsert({
        src: this.src,
        alt: this.alt,
      });

      this.src = '';
      this.alt = '';
      this.useUploadedImage = false;

      this.$emit('hidePopover');
    },
  },
};
</script>

<style scoped>
.image-popover {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 12.5rem;
}

.image-popover__image {
  max-height: 5rem;
  margin-bottom: 0.5rem;
  object-fit: contain;
}

.image-popover__src {
  display: flex;
  gap: 0.3rem;
}

.image-popover__src-input {
  flex-grow: 1;
}

.image-popover__src-input, .image-popover__alt-input {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  color: #fff;
  font-size: 0.8rem;
  text-align: left;
  border: 1px solid #585859;
  border-radius: 0.25rem;
  outline: 2px solid transparent;
  outline-offset: 1px;
  transition: outline 0.25s;
}

:is(.image-popover__src-input, .image-popover__alt-input):focus {
  outline: 2px solid rgba(255, 255, 255, 0.1);
}

:is(.image-popover__src-input, .image-popover__alt-input)::placeholder {
  color: #585859;
  text-align: left;
}

.image-popover__src-input:read-only {
  color: #585859;
}

.image-popover__src-upload-input {
  display: none;
}

.image-popover__src-upload-label, .image-popover__insert-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: #6d75f6;
  color: #fff;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  outline: 2px solid transparent;
  outline-offset: 1px;
  transition: outline 0.25s;
}

:is(.image-popover__src-upload-label, .image-popover__insert-button):focus {
  outline: 2px solid rgba(109, 117, 246, 0.5);
}

.image-popover__src-upload-label {
  margin: 0;
  cursor: pointer;
}

.image-popover__insert-button {
  align-self: flex-end;
  margin-top: 0.25rem;
  border: none;
}
</style>
