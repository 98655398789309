<template>
	<div>
		<b-modal id="eport-gv-modal" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md" dialog-class="egv__dialog">
          <div class="container-fluid my-3">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ $t('export-share-content') }}</h4>
              <button class="btn btn-close d-flex justify-content-center align-items-center close-image" @click="$bvModal.hide('eport-gv-modal')"><b-icon icon="x"></b-icon></button>
            </div>
            <div class="egv__list--action mt-3">
				<div class="egv__card d-flex flex-column align-items-center justify-content-center">
					<img src="@/assets/images/icons/export_mp3r.svg" alt="Export MP3" class="egv__img">
                    <p class="mb-5 mt-1">{{ $t('audio-only') }}</p>
					<button :disabled="loadingAudio || disableAudio" class="egv__button-action btn mt-auto" @click="$emit('click:download-audio')">{{ loadingAudio ? 'loading...' : $t('btn-download') }}</button>
				</div>
				<div class="egv__card d-flex flex-column align-items-center justify-content-center">
					<img src="@/assets/images/icons/export_mp4r.svg" alt="Export MP3" class="egv__img">
                    <p class="mb-5 mt-1">{{ $t('video-format') }}</p>
					<button :disabled="loadingVideo" class="egv__button-action btn mt-auto" @click="$emit('click:download-video')">{{ loadingVideo ? 'loading...' : $t('btn-download') }}</button>
				</div>
				<div class="egv__card d-flex flex-column align-items-center justify-content-center">
					<img src="@/assets/images/icons/export_copylink.svg" alt="Export MP3" class="egv__img">
                    <p class="mb-5 mt-1">{{ $t('share-link') }}</p>
					<button :disabled="loadingCl" class="egv__button-action btn mt-auto" @click="$emit('click:copy-link')">{{ loadingCl ? 'loading...' : $t('btn-copy-link') }}</button>
				</div>
			</div>
          </div>
        </b-modal>
	</div>
</template>

<script>
export default {
	props: {
		loadingAudio: {
			type: Boolean,
			default: false
		},
		loadingVideo: {
			type: Boolean,
			default: false
		},
		loadingCl: {
			type: Boolean,
			default: false
		},
    disableAudio: {
      type: Boolean,
      default: false
    }
	},
}
</script>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#eport-gv-modal .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#eport-gv-modal .modal-content {
    background-color: #1F1F1F;
    color: white;
    border-radius: 15.0499px;
}

#eport-gv-modal .modal-md {
    max-width: 590px !important;
}

.btn-primary{
    background-color: #6D75F6;
    border: none;
}
.btn-primary:hover{
    background-color: #6D75F6;
    /* border: none; */
}
.btn-secondary{
    background-color: #2D2D2D;
    border: #2D2D2D;
    color: #ffffff;
}
.btn-secondary:hover{
    background-color: #2D2D2D;
    border: #2D2D2D;
    color: #ffffff;
}
</style>

<style scoped>
.egv__list--action {
	gap: 16px;
	display: grid;
  	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  	grid-gap: 1rem;
}

.egv__dialog {
	max-width: none;
}

.btn-close{
  background-color: #EDEDED;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}

.egv__card {
  background-color: #2D2D2D !important;
  border-radius: 10px;
  padding: 10px;
}

.egv__button-action {
	background-color: #6D75F6;
	color: white;
  	width: 130px;
	font-size: 12px;
	transition: background-color 0.25s;
}

.egv__button-action:not(:disabled):hover {
	background-color: #545EED;
}

.egv__button-action:disabled {
  /* background-color: #1F1F1F;
  border-color: #1F1F1F;
  color: #585859; */
}

.egv__img {
	width: 65px;
}
</style>