<template>
  <b-modal
    :id="id"
    size="sm"
    dialog-class="saw__dialog"
    content-class="saw mx-auto text-white text-center"
    body-class="p-1 pb-5"
    centered
    hide-header
    hide-footer
    :no-close-on-backdrop="isLoading"
    :no-close-on-esc="isLoading"
    @hide="handleHide"
  >
    <header class="saw__header d-flex justify-content-end">
      <button
        type="button"
        class="saw__close-button btn d-flex justify-content-center align-items-center p-0"
        title="Close"
        :disabled="isLoading"
        @click="handleHide"
      >
        &times;
      </button>
    </header>
    <div class="saw__main d-flex flex-column justify-content-center align-items-center mt-4 px-3">
      <img
        src="@/assets/images/icons/modal_warning.svg"
        alt=""
        class="saw__warning-icon"
      >
      <h3 class="saw__title">Profanity Warning</h3>
      <p class="saw__description">We identified inappropriate word(s). To process your request click continue and accept our ethical use.</p>
    </div>
    <footer class="saw__footer d-flex justify-content-center align-items-center">
      <button
        type="button"
        class="saw__continue-button btn px-3 text-white"
        title="Continue"
        :disabled="isLoading"
        @click="handleClickContinue"
      >
        Continue
      </button>
      <button
        type="button"
        class="saw__change-button btn px-3 text-white"
        title="Change"
        :disabled="isLoading"
        @click="handleHide"
      >
        Change
      </button>
    </footer>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'sentiment-analysis-warning',
    },
  },
  emits: ['hide'],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleClickContinue() {
      this.isLoading = true;
      this.$router.push('/terms-&-conditions/sentiment-analysis');
    },
    handleHide() {
      this.$bvModal.hide(this.id);
      this.$emit('hide');
    },
  },
};
</script>

<style scoped>
.saw__close-button {
  width: 32px;
  height: 32px;
  font-family: monospace;
  font-size: 24px;
  color: #FFFFFF;
  transition: color 0.25s;
}

.saw__close-button:not(:disabled):hover {
  color: #CCCCCC;
}

.saw__main {
  gap: 12px;
}

.saw__warning-icon {
  aspect-ratio: 1 / 1;
}

.saw__title {
  font-size: 20px;
  font-weight: 600;
}

.saw__description {
  font-size: 16px;
  font-weight: 400;
}

.saw__footer {
  gap: 8px;
}

.saw__continue-button, .saw__change-button {
  border-radius: 6px;
  font-weight: 600;
  transition: background-color 0.25s;
}

.saw__continue-button {
  background-color: #2D2D2D;
}

.saw__continue-button:not(:disabled):hover {
  background-color: #3D3D3D;
}

.saw__change-button {
  background-color: #6D75F6;
}

.saw__change-button:not(:disabled):hover {
  background-color: #545EED;
}
</style>

<style>
.saw__dialog {
  max-width: none;
}

.saw {
  width: 310px;
  background-color: #1F1F1F;
  border-radius: 16px;
}
</style>
