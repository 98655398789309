<template>
  <section class="cc d-flex flex-column">
    <header class="cc__header">
      <ul class="cc__tab-list d-flex px-5 m-0 list-unstyled">
        <li v-for="(tab, index) in tabs" :key="index">
          <button
            type="button"
            class="cc__tab-button btn d-inline-flex align-items-center px-3 py-2 rounded-0 text-nowrap"
            :class="{ 'cc__tab-button--active': index === activeTabIndex }"
            :title="tab.title"
            @click="activeTabIndex = index"
          >
            <img
              :src="index === activeTabIndex ? tab.activeIconSrc : tab.iconSrc"
              class="cc__tab-button-icon"
              alt=""
            />
            {{ tab.title }}
          </button>
        </li>
      </ul>
    </header>
    <keep-alive>
      <component :is="tabs[activeTabIndex].component" v-model="selectedCharacter" :disabled-action="disabledAnyAction" :show-suggestion-agi="showSuggestionAgi" @show-agi="(newVal) => this.$emit('show-agi', newVal)"/>
    </keep-alive>
  </section>
</template>

<script>
import RegularCharacters from './RegularCharacters';
import AICharacters from './AICharacters';

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    showSuggestionAgi: {
      type: Boolean,
      default: false,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedCharacter: this.value,
      tabs: [
        {
          title: 'Characters',
          iconSrc: require('@/assets/images/icons/ic_character_default.svg'),
          activeIconSrc: require('@/assets/images/icons/ic_character_active.svg'),
          component: RegularCharacters,
        },
        {
          title: 'Generate AI Characters',
          iconSrc: require('@/assets/images/icons/ic_magic_wand_default.svg'),
          activeIconSrc: require('@/assets/images/icons/ic_magic_wand_active.svg'),
          component: AICharacters,
        },
      ],
      activeTabIndex: 0,
      showSuggestion: this.showSuggestionAgi,
      disabledAnyAction : this.disabledAction
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.selectedCharacter = newValue;
      },
      deep: true,
    },
    selectedCharacter: {
      handler(newSelectedCharacter) {
        this.$emit('input', newSelectedCharacter);
      },
      deep: true,
    },
    showSuggestionAgi(newVal) {
      this.showSuggestion = newVal
    },
    disabledAction(newVal) {
      this.disabledAnyAction = newVal
    }
  },
};
</script>

<style scoped>
.cc {
  gap: 16px;
  width: 100%;
  min-height: 0;
}

.cc__header {
  border-bottom: 0.5px solid #2D2D2D;
}

.cc__tab-button {
  gap: 8px;
  background: none;
  border-bottom: 2px solid transparent;
  color: #FFFFFF;
  font-size: 14px;
  transition: border-color 0.25s, color 0.25s, font-weight 0.25s;
}

.cc__tab-button--active {
  border-bottom-color: #6D75F6;
  color: #6D75F6;
  font-weight: 600;
}

.cc__tab-button-icon {
  height: 18px;
}
</style>
