<template>
    <div>
        <b-modal id="rvm-modal" content-class="rvm mx-auto text-white text-center" dialog-class="rvm__dialog" no-close-on-backdrop centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md" @hidden="$emit('cancel-rename')">
            <div class="rvm--inner p-4">
				<h3 class="rvm__title">{{ newsReader ? $t('save-readers') : $t('change-name') }}</h3>
				<div class="text-left mt-4">
					<label for="vm" class="rvm__label">{{ content ? $t('input-file-name'): $t('voice-name') }}</label>
					<input type="text" id="vm" :placeholder="$t('input-file-name--placeholder')" v-model="name" @keyup.enter="handleEnterKey" class="form-control rvm__input" :class="{ 'is-valid' : name, 'is-invalid' : !name}" aria-describedby="nameValidation" required>
				</div>
				<div class="rvm__action d-flex align-items-center justify-content-center mt-4">
					<button type="button" class="btn b--rvm-cancel" @click="$bvModal.hide('rvm-modal')">{{ $t('back') }}</button>
					<button type="button" class="btn b--rvm-save" :disabled="!name" @click="$emit('save-name')">{{ $t('save') }}</button>
				</div>
			</div>
        </b-modal>
    </div>
</template>

<script>

export default {
	props: {
		value : {
			type: String,
			default: null
		},
		content : {
			type: Boolean,
			default: false,
		},
		newsReader: {
			type: Boolean,
			default: false,
		}
	},

	watch: {
		value: {
			handler(newValue) {
				this.name = newValue;
			},
			deep: true,
		},
		name: {
			handler(newName) {
				this.$emit('input', newName);
			},
			deep: true,
		},
	},

    data() {
		return {
			name : this.value,
		}
    },

    methods : {
		handleEnterKey() {
			if(this.name) {
				this.$emit('save-name')
			}
		}
    }
}
</script>

<style scoped>
.rvm__title {
	font-size: 28px;
}

.rvm__label {
	font-size: 18px;
}

.rvm__input {
	border-radius: 6px;
	border: 1px solid #585859;
	background-color: transparent;
	box-shadow: none;
	color: #fff;
}

.rvm__action {
	gap: 8px;
}

.b--rvm-cancel, .b--rvm-save {
	color: #fff;
	transition: background-color 0.25s;
	font-size: 18px;
	font-weight: 600;
	padding: 6px 18px;
	border-radius: 6px;
}

.b--rvm-cancel {
	background: #2D2D2D;
}

.b--rvm-save {
	background-color: #6d75f6;
}

.b--rvm-cancel:hover {
	background: #292828;
}

.b--rvm-save:not(:disabled):hover {
	background-color: #545EED;
}
</style>

<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#rvm-modal .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#rvm-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#rvm-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

button {
  box-shadow: none !important;
}

.rvm__dialog {
  max-width: none;
}

.rvm {
	width: 500px;
}
</style>