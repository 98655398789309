<template>
  <section class="tlt d-flex">
    <div class="tlt__icons d-flex flex-column">
      <div class="tlt__icons-space" />
      <div
        class="tlt__collapse"
        :class="{ 'tlt__collapse--show': isTimelineShown }"
      >
        <div class="tlt__collapse-inner">
          <div class="tlt__icon-container d-flex justify-content-center align-items-center">
            <img
              :src="require('@/assets/images/icons/ic_mic_editor.svg')"
              alt="Voice"
              class="tlt__icon-image"
              height="16"
            />
          </div>
          <div class="tlt__icon-container d-flex justify-content-center align-items-center">
            <img
              :src="require('@/assets/images/icons/ic_music_editor.svg')"
              alt="Music"
              class="tlt__icon-image"
              height="16"
            />
          </div>
          <div class="tlt__icon-container d-flex justify-content-center align-items-center" :class="{ 'tlt__icon-container--image-hidden' : disableImages }">
            <img
              :src="require('@/assets/images/icons/ic_video_editor.svg')"
              alt="Image"
              class="tlt__icon-image"
              :class="{ 'tlt__icon--image-hidden' : disableImages }"
              height="16"
            />
          </div>
        </div>
      </div>
    </div>

    <div ref="trackContent" class="tlt__content position-relative d-flex flex-column">
      <div
        class="tlt__pointer position-absolute"
        :style="{ left: (currentTime * secondDistance) + 12 + 'px' }"
        @mousedown="dragTime = true"
      />

      <div
        class="tlt__time d-flex align-items-center px-2"
        ref="tltTime"
        :style="{ 'gap' : secondDistance - 10 + 'px' }"
        @click="(e) => handleChangeCurrentTime(e.clientX)"
        @mousedown="dragTime = true"
      >
        <span
          v-for="second in visualDuration"
          :key="second"
          class="tlt__second d-inline-flex justify-content-center align-items-center"
        >
          <span
            v-if="((second - 1) % 5 !== 0) && secondDistance > 18"
            class="tlt__second-dot d-inline-flex justify-content-center align-items-center"
          />
          <span
            v-else-if="((second - 1) % 5 === 0)"
            class="tlt__second-number position-relative d-inline-flex justify-content-center align-items-center"
          >
            {{ (second - 1) % 5 === 0 ? (formatTime(second - 1) + 's') : '' }}
          </span>
        </span>
      </div>

      <div
        class="tlt__media tlt__collapse"
        :class="{ 'tlt__collapse--show': isTimelineShown }"
      >
        <div class="tlt__collapse-inner">
          <div class="tlt__media-list-container tlt__media-list-container--voice p-0 position-relative d-flex align-items-center" ref="voiceWrapperList">
            <ul
              v-if="internalVoices.length > 0"
              ref="voiceList"
              class="tlt__media-list d-flex align-items-center m-0 list-unstyled"
              :style="{ width : getVoicesWidth * secondDistance + 'px'}"
            >
                <li v-for="(voice, index) in internalVoices" :key="index + voice.duration" class="position-relative" :style="{ left: index == 0 ? voice.start * secondDistance + 'px' : (voice.start - calculateDurationVoice(index)) * secondDistance + 'px'  }">
                  <tippy
                    size="small"
                    trigger="contextmenu"
                    boundary="window"
                    follow-cursor="horizontal"
                    theme="tlt__media-item-menu"
                    :animate-fill="false"
                    :duration="250"
                    interactive
                    arrow
                    sticky
                  >
                    <template v-slot:trigger>
                      <div
                        class="tlt__media-block-container tlt__media-block-container--voice d-flex align-items-center rounded-sm"
                        :class="{
                          'tlt__media-block-container--selected': selectedVoice === voice.timelineId,
                          'tlt__media-block-container--disabled': disableEdit,
                        }"
                        :data-voice-index="index"
                        :style="{ width: voice.duration * secondDistance + 'px' }"
                        @click.left="selectedVoice = voice.timelineId"
                        @click.right.prevent
                      >
                        <av-waveform
                          :key="'waveform-voice-' + index + '-' + secondDistance"
                          :audio-src="voice.src"
                          :audio-controls="false"
                          :canv-width="voice.duration * secondDistance"
                          :canv-height="30"
                          :playtime="false"
                          :playtime-slider="false"
                          :playtime-clickable="false"
                          played-line-color="#000000"
                          noplayed-line-color="#000000"
                          playtime-slider-color="#00000000"
                        />
                      </div>
                    </template>

                    <button
                      type="button"
                      class="tlt__media-delete-button btn position-relative d-inline-flex align-items-center px-3 py-2 text-white"
                      :title="`Delete ${voice.name}`"
                      :disabled="disableEdit"
                      @click="$emit('delete:voice', voice.timelineId, voice.iav_id)"
                    >
                      <img src="@/assets/images/icons/ic_delete_2.svg" alt="" height="14" />
                      {{ $t('delete') }}
                    </button>
                  </tippy>
                </li>
            </ul>
          </div>
          <div class="tlt__media-list-container p-0">
            <div class="tlt__media-list-container--music p-0 position-relative d-flex align-items-center" ref="musicWrapperList" :style="{ width : extraDuration ? timeWidth + 'px' : '100%' }">
              <ul
                v-if="internalMusics.length > 0"
                ref="musicList"
                class="tlt__media-list d-flex align-items-center m-0 list-unstyled"
              >
                  <li v-for="(music, index) in internalMusics" :key="music.timelineId" class="position-relative" :style="{ left: index == 0 ? music.start * secondDistance + 'px' : (music.start - calculateDurationMusic(index)) * secondDistance + 'px'  }">
                    <tippy
                      size="small"
                      trigger="contextmenu"
                      boundary="window"
                      follow-cursor="horizontal"
                      theme="tlt__media-item-menu"
                      :animate-fill="false"
                      :duration="250"
                      interactive
                      arrow
                      sticky
                    >
                      <template v-slot:trigger>
                        <div
                          class="tlt__media-block-container tlt__media-block-container--music position-relative d-flex align-items-center rounded-sm"
                          :class="{
                            'tlt__media-block-container--selected': selectedMusic === music.timelineId,
                            'tlt__media-block-container--disabled': disableEdit,
                          }"
                          :style="{ width: music.duration * secondDistance + 'px' }"
                          :data-music-index="index"
                          @click.left="!disableEdit ? selectedMusic = music.timelineId : true"
                          @click.right.prevent
                        >
                          <av-waveform
                            :key="'waveform-music-' + index + '-' + secondDistance"
                            :audio-src="music.src"
                            :audio-controls="false"
                            :canv-width="music.durationOrigin * secondDistance"
                            :canv-height="30"
                            :playtime="false"
                            :playtime-slider="false"
                            :playtime-clickable="false"
                            played-line-color="#000000"
                            noplayed-line-color="#000000"
                            playtime-slider-color="#00000000"
                          />
                        </div>
                      </template>

                      <button
                        type="button"
                        class="tlt__media-delete-button btn position-relative d-inline-flex align-items-center px-3 py-2 text-white"
                        :title="`Delete ${music.name}`"
                        :disabled="disableEdit"
                        @click="$emit('delete:music', index)"
                      >
                        <img src="@/assets/images/icons/ic_delete_2.svg" alt="" height="14" />
                        {{ $t('delete') }}
                      </button>
                    </tippy>
                  </li>
              </ul>
              <button
                v-if="!isDragingMusic"
                id="v-step-2"
                type="button"
                class="tlt__add-media-button btn d-inline-flex justify-content-center align-items-center rounded-sm text-white position-absolute"
                title="Add Music"
                :style="{ left: internalMusics?.length == 1 ? ((internalMusics[0].end * secondDistance) + 5) + 'px' : internalMusics?.length > 1 ? ((internalMusics[internalMusics.length - 1].end * secondDistance) + 5) + 'px' : '0px' }"
                :disabled="disableEdit"
                @click="$emit('click:add-music')"
              >
                <font-awesome-icon icon="fa-solid fa-plus" />
                <span class="sr-only">Add Music</span>
              </button>
            </div>
          </div>
          <div
            class="tlt__media-list-container position-relative d-flex align-items-center"
            :class="{ 'tlt__media-list-container--hidden': disableImages }"
          >
            <VueDraggable
              v-if="internalImages.length > 0"
              tag="ul"
              ref="imageList"
              v-model="internalImages"
              class="tlt__media-list d-flex align-items-center m-0 list-unstyled"
              ghost-class="tlt__media-item--ghost"
              :animation="200"
              :disabled="disableImages || disableEdit"
              @start="dragImage = true"
              @end="handleEndDragImage"
            >
              <transition-group
                type="transition"
                :name="!dragImage ? 'change-order' : null"
                class="tlt__media-list-inner"
              >
                <li v-for="(image, index) in internalImages" :key="image.timelineId">
                  <tippy
                    size="small"
                    trigger="contextmenu"
                    boundary="window"
                    follow-cursor="horizontal"
                    theme="tlt__media-item-menu"
                    :animate-fill="false"
                    :duration="250"
                    interactive
                    arrow
                    sticky
                  >
                    <template v-slot:trigger>
                      <div
                        class="tlt__media-block-container tlt__media-block-container--image position-relative d-flex align-items-center rounded-sm"
                        :class="{
                          'tlt__media-block-container--selected': selectedImage === image.timelineId,
                          'tlt__media-block-container--disabled': disableImages || disableEdit,
                        }"
                        :style="{
                          width: image.duration * secondDistance + 'px',
                          backgroundImage: `url('${image.src}')`,
                        }"
                        :data-image-index="index"
                        @click.left="setImage(index, image.timelineId)"
                        @click.right.prevent
                      />
                    </template>

                    <button
                      type="button"
                      class="tlt__media-delete-button btn position-relative d-inline-flex align-items-center px-3 py-2 text-white"
                      :title="`Delete ${image.name}`"
                      :disabled="disableImages || disableEdit"
                      @click="$emit('delete:image', index)"
                    >
                      <img src="@/assets/images/icons/ic_delete_2.svg" alt="" height="14" />
                      {{ $t('delete') }}
                    </button>
                  </tippy>
                </li>
              </transition-group>
            </VueDraggable>
            <button
              type="button"
              class="tlt__add-media-button btn d-inline-flex justify-content-center align-items-center rounded-sm text-white"
              title="Add Image"
              :disabled="disableImages || disableEdit"
              @click="$emit('click:add-image')"
            >
              <font-awesome-icon icon="fa-solid fa-plus" />
              <span class="sr-only">Add Image</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import interact from 'interactjs';
import VueDraggable from 'vuedraggable';

export default {
  components: {
    VueDraggable,
  },
  props: {
    isTimelineShown: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    extraDuration: {
      type: Boolean,
      default: false,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    voices: {
      type: Array,
      default: () => [],
    },
    musics: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
    disableImages: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    activeImageIndex: {
      type: Number,
      default: 0,
    },
    secondDistanceSet: {
      type: Number,
      default: 20
    },
    activeVoices: {
      type: String,
      default: null
    }
  },
  emits: [
    'delete:voice',
    'change:voices-order',

    'click:add-music',
    'delete:music',
    'change:musics-order',
    'change:music-duration',

    'click:add-image',
    'delete:image',
    'change:images-order',
    'change:image-duration',

    'change:current-time',
  ],
  data() {
    return {
      displayDuration: Math.ceil(this.duration),
      totalVisibleSeconds: Math.ceil(this.duration),

      internalVoices: this.voices,
      internalMusics: this.musics,
      internalImages: this.images,

      selectedVoice: this.activeVoices,
      selectedMusic: null,
      selectedImage: null,

      dragVoice: false,
      dragMusic: false,
      dragImage: false,
      dragTime: false,

      deleteMusicMenu: null,
      deleteImageMenu: null,

      isResizingMusic: false,
      isResizingImage: false,
      isDragingMusic: false,

      selectedImageIndex: 0,

      secondDistance: this.secondDistanceSet,

      timeWidth: null,
    };
  },
  computed: {
    visualDuration() {
      if (this.extraDuration && this.duration + 1 >= this.totalVisibleSeconds) {
        let extraDuration = this.secondDistance == 10 ? 5 : this.secondDistance >= 28 ? 2 : 3
        return this.displayDuration + extraDuration;
      }

      return this.displayDuration;
    },
    getVoicesWidth() {
      if(this.internalVoices.length > 0) {
        return this.internalVoices[this.internalVoices.length - 1].end
      } else {
        return this.internalVoices[0].end
      }
    }
  },
  watch: {
    voices(newVoices) {
      this.internalVoices = newVoices;
    },
    musics(newMusics) {
      this.internalMusics = newMusics;
    },
    images(newImages) {
      this.internalImages = newImages;
    },
    disableImages(newDisableImages) {
      if (newDisableImages === true) {
        this.selectedImage = null;
        this.selectedImageIndex = 0;
      }
    },
    disableEdit(newDisableEdit) {
      if (newDisableEdit === true) {
        this.selectedVoice = null;
        this.selectedMusic = null;
        this.selectedImage = null;
        this.selectedImageIndex = 0;
      }
    },
    duration() {
      this.setDisplayDuration();
    },
    selectedImageIndex(newVal) {
      this.$emit('update:active-image-index', newVal)
    },
    currentTime(newCurrentTime) {
      const trackContentScrollWidth = this.$refs.trackContent.scrollWidth;
      const trackContentOffsetWidth = this.$refs.trackContent.offsetWidth;
      const trackContentScrollLeft = this.$refs.trackContent.scrollLeft;

      const secondTravelDistance = this.secondDistance;
      let startVisibleSecond = 0;
      let endVisibleSecond = Math.round(this.duration);

      if (trackContentScrollWidth > trackContentOffsetWidth) {
        startVisibleSecond = Math.round(trackContentScrollLeft > 0 ? trackContentScrollLeft / secondTravelDistance : trackContentScrollLeft);
        endVisibleSecond = Math.round((trackContentOffsetWidth + trackContentScrollLeft) / secondTravelDistance - 1);
      }

      if (newCurrentTime < startVisibleSecond || newCurrentTime > endVisibleSecond) {
        if (newCurrentTime > this.totalVisibleSeconds) {
          this.$refs.trackContent.scrollLeft = secondTravelDistance * (newCurrentTime - this.totalVisibleSeconds);
        } else {
          this.$refs.trackContent.scrollLeft = 0;
        }
      }
    },
    secondDistanceSet(newVal) {
      this.secondDistance = newVal
      this.setDisplayDuration()
    },
    activeVoices(newVal) {
      this.selectedVoice = newVal
    },
    selectedVoice(newVal) {
      this.$emit('update:selected-voice', newVal)
    }
  },
  methods: {
    handleUnselectVoiceWhenClickOutside(e) {
      if (this.$refs.voiceList) {
        if (!this.$refs.voiceList.contains(e.target) && this.selectedVoice) {
          // this.selectedVoice = null;
        }
      }
    },
    handleUnselectMusicWhenClickOutside(e) {
      if (this.$refs.musicList) {
        if (!this.$refs.musicList.contains(e.target) && this.selectedMusic) {
          this.selectedMusic = null;
        }
      }
    },
    handleUnselectImageWhenClickOutside(e) {
      if (this.$refs.imageList) {
        if (!this.$refs.imageList.$el.contains(e.target) && this.selectedImage) {
          this.selectedImage = null;
        }
      }
    },
    handleDeleteSelectedVoice(e) {
      if (e.isComposing || e.keyCode === 229) {
        return;
      }

      const isShiftPressed = e.shiftKey;

      // When press backspace or delete
      if ((isShiftPressed && [8, 46].includes(e.keyCode)) && this.selectedVoice && !this.disableEdit) {
        this.$emit('delete:voice', this.selectedVoice);
        this.selectedVoice = null;
      }
    },
    handleDeleteSelectedMusic(e) {
      if (e.isComposing || e.keyCode === 229) {
        return;
      }

      // When press backspace or delete
      if ([8, 46].includes(e.keyCode) && this.selectedMusic && !this.disableImages && !this.disableEdit) {
        const musicIndex = this.internalMusics.findIndex((music) => music.timelineId === this.selectedMusic);

        this.$emit('delete:music', musicIndex);
        this.selectedMusic = null;
      }
    },
    handleDeleteSelectedImage(e) {
      if (e.isComposing || e.keyCode === 229) {
        return;
      }

      // When press backspace or delete
      if ([8, 46].includes(e.keyCode) && this.selectedImage && !this.disableImages && !this.disableEdit) {
        const imageIndex = this.internalImages.findIndex((image) => image.timelineId === this.selectedImage);

        this.$emit('delete:image', imageIndex);
        this.selectedImage = null;
        this.selectedImageIndex = 0
      }
    },
    handleChangeCurrentTime(cursorPosition) {
      const trackContentScrollLeft = this.$refs.trackContent.scrollLeft;

      const trackContentOffsetLeft = this.$refs.trackContent.getBoundingClientRect().left;
      const trackContentCursorPositionAbsolute = cursorPosition - trackContentOffsetLeft;
      const trackContentCursorPositionRelative = trackContentCursorPositionAbsolute + trackContentScrollLeft;

      const paddingWidth = 12;
      const secondTravelDistance = this.secondDistance;

      const currentTime = (trackContentCursorPositionRelative - paddingWidth) / secondTravelDistance;

      let newCurrentTime = currentTime;

      if (currentTime <= 0) {
        newCurrentTime = 0;
      } else if (currentTime >= this.duration) {
        newCurrentTime = this.duration;
      }

      this.$emit('change:current-time', newCurrentTime);
    },
    handleMousemoveTime(e) {
      if (this.dragTime) {
        const cursorPosition = e.clientX;

        const trackContentScrollWidth = this.$refs.trackContent.scrollWidth;
        const trackContentOffsetWidth = this.$refs.trackContent.offsetWidth; // Visible width
        const trackContentScrollLeft = this.$refs.trackContent.scrollLeft;

        const trackContentOffsetLeft = this.$refs.trackContent.getBoundingClientRect().left;
        const trackContentCursorPosition = cursorPosition - trackContentOffsetLeft;

        // Handle auto-scroll
        if (trackContentScrollWidth > trackContentOffsetWidth) {
          const scrollTriggerOffset = 30;
          const scrollStepDefault = this.dragTime ? 40 : 5000;
          const scrollToRightStepPossible = trackContentScrollWidth - trackContentOffsetWidth - trackContentScrollLeft;
          const scrollToLeftStepPossible = trackContentScrollLeft;

          if (trackContentCursorPosition >= (trackContentOffsetWidth - scrollTriggerOffset)) {
            this.$refs.trackContent.scrollLeft += scrollStepDefault < scrollToRightStepPossible ? scrollStepDefault : scrollToRightStepPossible;
          } else if (trackContentCursorPosition <= scrollTriggerOffset) {
            this.$refs.trackContent.scrollLeft -= scrollStepDefault < scrollToLeftStepPossible ? scrollStepDefault : scrollToLeftStepPossible;
          }
        }

        this.handleChangeCurrentTime(cursorPosition);
      }
    },
    handleMouseupTime() {
      this.dragTime = false;
    },
    handleEndDragVoice() {
      this.dragVoice = false;
      this.$emit('change:voices-order', this.internalVoices);
    },
    handleEndDragMusic(e) {
      this.dragMusic = false;
      this.$emit('change:musics-order', this.internalMusics, e.oldIndex, e.newIndex);
    },
    handleEndDragImage(e) {
      this.dragImage = false;
      this.$emit('change:images-order', this.internalImages, e.oldIndex, e.newIndex);
    },
    dragVoiceListener() {
      this.$nextTick(() => {
        if(this.$refs.voiceWrapperList) {
          let fixedStart = 0

          const moveListener = (event) => {
            this.$nextTick(() => {
              const target = event.target;
              const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
              target.style.transform = `translate(${x}px)`;
              target.setAttribute('data-x', x);

              const parentRect = this.$refs?.voiceWrapperList.getBoundingClientRect();
              const elementRect = target.getBoundingClientRect();

              const relativeX = elementRect.left - parentRect.left;
              fixedStart = relativeX / this.secondDistance;

              if(fixedStart < 0) {
                fixedStart = 0
              }
            })
          }

          const dragEndListener = (event) => {
            const target = event.target;
            const { voiceIndex } = target.dataset;
            target.style.transform = `none`;
            target.setAttribute('data-x', 0);

            this.$emit('update:start-voices', fixedStart, voiceIndex);
          };

          interact('.tlt__media-block-container--voice')
            .draggable({
              restrict: {
                restriction: this.$refs.voiceWrapperList,
                elementRect: { top: 0, left: 0, bottom: 0, right: 0 },
              },
              axis: 'x',
              onmove: moveListener,
              onend: dragEndListener,
            });
        }
      })
    },
    endDragVoice() {
      const positions = {};
      const voiceItems = this.$refs.voiceList.querySelectorAll('.tlt__media-block-container--voice');

      voiceItems.forEach((element, index) => {
        const x = parseFloat(element.getAttribute('data-x')) || 0;
        positions[index] = x;
      });
    },
    resizeMusicListener() {
      this.$nextTick(() => {
        let fixedStart = 0
        const resizeListener = (e) => {
          const { musicIndex } = e.target.dataset;

          const music = this.internalMusics[musicIndex];

          const secondTravelDistance = this.secondDistance;
          const newDurationDiff = e.deltaRect.right / secondTravelDistance;

          let newDuration = music.duration + newDurationDiff;

          if (newDuration < 0) {
            newDuration = 0;
          } else if (newDuration > music.durationOrigin) {
            newDuration = music.durationOrigin;
          }

          this.$emit('change:music-duration', musicIndex, Number(newDuration.toFixed(2)));
        };

        const dragStartListener = (e) => {
          this.isDragingMusic = true
          const target = e.target;
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + e.dx;
          target.style.transform = `translate(${x}px)`;
          target.style.zIndex = '2';
          target.setAttribute('data-x', x);

          const parentRect = this.$refs?.musicWrapperList.getBoundingClientRect();
          const elementRect = target.getBoundingClientRect();

          const relativeX = elementRect.left - parentRect.left;
          fixedStart = relativeX / this.secondDistance;

          if(fixedStart < 0) {
            fixedStart = 0
          }
        }

        const dragEndListener = (event) => {
          const target = event.target;
          const { musicIndex } = target.dataset;
          target.style.transform = `none`;
          target.setAttribute('data-x', 0);

          this.$emit('update:start-music', fixedStart, musicIndex);
          this.isDragingMusic = false
        };

        interact('.tlt__media-block-container--music')
          // RESIZE
          .resizable({
            edges: { right: true },
            listeners: {
              move: resizeListener,
            },
          })
          .on('resizestart', () => this.isResizingMusic = true)
          .on('resizeend', () => this.isResizingMusic = false)

          // DRAG
          .draggable({
            restrict: {
              restriction: this.$refs.musicWrapperList,
              elementRect: { top: 0, left: 0, bottom: 0, right: 0 },
            },
            axis: 'x',
            onmove: dragStartListener,
            onend: dragEndListener,
          })
      })
    },
    resizeImageListener() {
      const moveListener = (e) => {
        const { imageIndex } = e.target.dataset;

        const image = this.internalImages[imageIndex];

        const secondTravelDistance = this.secondDistance;
        const newDurationDiff = e.deltaRect.right / secondTravelDistance;

        let newDuration = image.duration + newDurationDiff;

        if (newDuration < 0) {
          newDuration = 0;
        } else if (newDuration > image.durationOrigin) {
          newDuration = image.durationOrigin;
        } else if (newDuration > image.videoDurationOrigin) {
          newDuration = image.videoDurationOrigin
        }

        this.$emit('change:image-duration', imageIndex, Number(newDuration.toFixed(2)));
      };

      interact('.tlt__media-block-container--image')
        .resizable({
          edges: { right: true },
          listeners: {
            move: moveListener,
          },
        })
        .on('resizestart', () => this.isResizingImage = true)
        .on('resizeend', () => this.isResizingImage = false);
    },
    setDisplayDuration() {
      const trackContentOffsetWidth = this.$refs.trackContent.offsetWidth;
      const secondTravelDistance = this.secondDistance;

      this.totalVisibleSeconds = Math.round(trackContentOffsetWidth / secondTravelDistance) - 1;

      if (this.duration > this.totalVisibleSeconds) {
        this.displayDuration = Math.ceil(this.duration);
      } else {
        this.displayDuration = this.totalVisibleSeconds;
      }

      setTimeout(() => {
        this.timeWidth = this.$refs.tltTime.offsetWidth
      }, 100)
    },
    formatTime(second) {
      return Intl.NumberFormat('en', { notation: 'compact' }).format(second);
    },
    setImage(index, timelineId) {
      if (!(this.disableImages || this.disableEdit)) {
        this.selectedImage = timelineId;
        this.selectedImageIndex = index;
      }
    },
    calculateDurationVoice(index) {
      let totalDuration = 0;
      for (let i = 0; i <= index-1; i++) {
        if (this.internalVoices[i]?.duration) {
          totalDuration += this.internalVoices[i]?.duration;
        }
      }
      return totalDuration;
    },
    calculateDurationMusic(index) {
      let totalDuration = 0;
      for (let i = 0; i <= index-1; i++) {
        if (this.internalMusics[i]?.duration) {
          totalDuration += this.internalMusics[i]?.duration;
        }
      }
      return totalDuration;
    }
  },
  mounted() {
    window.addEventListener('resize', this.setDisplayDuration);
    this.setDisplayDuration();

    document.addEventListener('click', this.handleUnselectVoiceWhenClickOutside);
    document.addEventListener('click', this.handleUnselectMusicWhenClickOutside);
    document.addEventListener('click', this.handleUnselectImageWhenClickOutside);
    document.addEventListener('keyup', this.handleDeleteSelectedVoice);
    document.addEventListener('keyup', this.handleDeleteSelectedMusic);
    document.addEventListener('keyup', this.handleDeleteSelectedImage);
    document.addEventListener('mousemove', this.handleMousemoveTime);
    document.addEventListener('mouseup', this.handleMouseupTime);

    this.resizeImageListener();
    this.$nextTick(() => {
      this.resizeMusicListener();
      this.dragVoiceListener();
    })
    setTimeout(() => {
      this.timeWidth = this.$refs.tltTime.offsetWidth
    }, 100)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDisplayDuration);
    document.removeEventListener('click', this.handleUnselectVoiceWhenClickOutside);
    document.removeEventListener('click', this.handleUnselectMusicWhenClickOutside);
    document.removeEventListener('click', this.handleUnselectImageWhenClickOutside);
    document.removeEventListener('keyup', this.handleDeleteSelectedVoice);
    document.removeEventListener('keyup', this.handleDeleteSelectedMusic);
    document.removeEventListener('keyup', this.handleDeleteSelectedImage);
    document.removeEventListener('mousemove', this.handleMousemoveTime);
    document.removeEventListener('mouseup', this.handleMouseupTime);
    interact('.tlt__media-block-container--voice').unset();
    interact('.tlt__media-block-container--music').unset();
  },
};
</script>

<style scoped>
.tlt__icons-space {
  width: 60px;
  height: 20px;
  border-bottom: 1px solid #2D2D2D;
}

.tlt__icon-container {
  width: 60px;
  height: 50px;
  border-right: 1px solid #2D2D2D;
  user-select: none;
}

.tlt__icon-container + .tlt__icon-container {
  border-top: 1px solid #2D2D2D;
}

.tlt__icon-container--image-hidden {
  height: 0px;
}

.tlt__icon-image {
  opacity: 1;
  visibility: visible;
}

.tlt__icon-image--disabled {
  opacity: 0.25;
}

.tlt__icon--image-hidden {
  opacity: 0;
  visibility: hidden;
}

.tlt__content {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.tlt__content::-webkit-scrollbar {
  display: none;
}

.tlt__pointer {
  z-index: 4;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #DF2723;
  cursor: ew-resize;
  transition: left 0.1s;
}

.tlt__pointer::before {
  content: "";
  position: absolute;
  top: 0;
  translate: -50% 0;
  border-inline: 6px solid transparent;
  border-top: 8px solid #DF2723;
}


.tlt__time {
  width: fit-content;
  min-width: 100%;
  height: 20px;
  /* gap: 10px; */
  border-bottom: 1px solid #2D2D2D;
  cursor: pointer;
}

.tlt__second {
  flex-grow: 0;
  flex-shrink: 0;
  width: 10px;
  height: 100%;
  color: #4D4D4D;
  user-select: none;
}

.tlt__second-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #2D2D2D;
}

.tlt__second-number {
  height: 100%;
  font-size: 10px;
}

.tlt__second-number::after {
  content: "";
  position: absolute;
  inset: auto auto 0 auto;
  width: 1px;
  height: 2px;
  background-color: #2D2D2D;
}

.tlt__collapse {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.25s;
}

.tlt__media .tlt__collapse-inner {
  width: fit-content;
  min-width: 100%;
}

.tlt__collapse--show {
  grid-template-rows: 1fr;
}

.tlt__collapse-inner {
  overflow: hidden;
}

.tlt__media-list-container {
  width: 100%;
  height: 50px;
  gap: 8px;
  padding-inline: 12px;
}

.tlt__media-list-container--voice, .tlt__media-list-container--music {
  width: 100%;
  height: 50px;
  gap: 8px;
  margin-inline: 12px;
}

.tlt__media-list-container + .tlt__media-list-container {
  border-top: 1px solid #2D2D2D;
}

.tlt__media-list-container::after {
  opacity: 0;
  visibility: hidden;

  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.25s, visibility 0.25s;
}

.tlt__media-list-container--hidden {
  height: 0px;
  opacity: 0;
  visibility: hidden;
}

.tlt__media-list-container--disabled::after {
  opacity: 1;
  visibility: visible;
}

.tlt__media-list-inner {
  display: contents;
}

.tlt__media-block-container {
  box-shadow: inset 0 0 0 1px #FFFFFF22;
  overflow: hidden;
  transition: box-shadow 0.25s;
}

.tlt__media-block-container--voice {
  background-color: #A5FFA8;
}

.tlt__media-block-container--music {
  background-color: #B3B7FA;
}

.tlt__media-block-container--image {
  height: 35.625px;
  background-size: contain;
  background-repeat: repeat;
}

.tlt__media-block-container:not(.tlt__media-block-container--selected):not(.tlt__media-block-container--disabled):hover {
  box-shadow: inset 0 0 0 1px #FFFFFF66;
}

.tlt__media-block-container--selected {
  box-shadow: inset 0 0 0 1.5px #6D75F6;
}

.tlt__media-delete-button {
  z-index: 1;
  gap: 6px;
  transition: background-color 0.25s;
}

.tlt__media-delete-button:not(:disabled):hover {
  background-color: #C43D30;
}

.tlt__add-media-button {
  width: 35px;
  height: 35px;
  background-color: #2D2D2D;
  font-size: 18px;
  transition: background-color 0.25s;
}

.tlt__add-media-button:not(:disabled):hover {
  background-color: #3D3D3D;
}
</style>

<style>
.tlt__media-item--ghost {
  opacity: 0.5;
}

.change-order-move {
  transform: all 0.5s;
}

.tlt__media-item-menu-theme {
  padding: 0 !important;
}
</style>
