<template>
    <div>
        <b-modal id="audio-quota" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('audio-quota')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center text-white mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Alert" class="img-fluid">
                <div class="mt-3">
                    <h5 class="font-weight-bold mb-2" style="font-size:16px;" v-if="$route.name == 'Home' || $route.name == 'Generate-Videos' || $route.name == 'NewsReader' ">{{ $t('audio-maximum-limit') }}</h5>
                    <h5 class="font-weight-bold mb-2" style="font-size:16px;" v-else-if="$route.name == 'AutoDubbing'">Video Maximum Limit</h5>
                    <h5 v-if="$route.name == 'Generate-Videos' || $route.name == 'Home' || $route.name == 'NewsReader'" class="font-weight-normal" style="font-size:14px;" v-html="$t('audio-maximum--p')"></h5>
                    <h5 v-else-if="$route.name == 'AutoDubbing'" class="font-weight-normal" style="font-size:14px;">Your video duration has exceeded <br> the maximum limit.</h5>
                    <h5 v-else class="font-weight-normal" style="font-size:14px;">Your quiz quota has been exceeded <br> the maximum limit.</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-purple" @click="handleClickUpgrade"><p class="mx-2 mb-0 font-weight-bold text-white">{{ $t('upgrade-now') }}</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        clickUpgrade: {
            type: Function,
            default: null,
        },
    },
    methods: {
        handleClickUpgrade() {
            if (this.clickUpgrade !== null) {
                if(this.$route.name == 'Generate-Text-To-Audio') {
                    this.$emit('handle-upgrade-tta')
                } else if (this.$route.name == 'Generate-Videos'){
                    this.$emit('click:handle-upgrade-gv')
                } else {
                    this.clickUpgrade();
                }
            } else {
                this.$router.push({ name: 'TopUp' });
            }
        },
    },
};
</script>

<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#audio-quota .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#audio-quota .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
#audio-quota___BV_modal_backdrop_.modal-backdrop {
    background: rgb(0, 0, 0, 0.5) !important
}
</style>