<template>
    <div>
        <b-modal id="svta-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$emit('switch-nn'), $bvModal.hide('svta-modal')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center text-white mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Alert" class="img-fluid">
                <div class="mt-3">
                    <h5 class="font-weight-bold mb-2" style="font-size:16px;">Change to {{ mode == 'voice-only' ? 'AI Characters' : 'Voice Only'}}</h5>
                    <h5 v-if="mode == 'voice-only'" class="font-weight-normal" style="font-size:14px;">Please note that AI Characters is limited <br> to 30 seconds of video duration </h5>
                    <h5 v-else class="font-weight-normal" style="font-size:14px;">Are you sure to change to Voice Only? <br> The characters cannot be used. </h5>
                </div>
                <div class="svta--action d-flex justify-content-center mt-3">
                    <button class="btn btn-nn" @click="$bvModal.hide('svta-modal')"><p class="mx-2 mb-0 font-weight-bold text-white">Not now</p></button>
                    <button class="btn btn-purple" @click="$emit('reset-data')"><p class="mx-2 mb-0 font-weight-bold text-white">Continue</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            validator(value) {
                return ['voice-only', 'ai-characters'].includes(value);
            },
        },
    },
    methods: {

    },
};
</script>

<style scoped>
.btn-nn, .btn-purple {
	height: 40px;
	padding: 0 20px;
	transition: background-color 0.25s;
}

.btn-nn {
	background-color: #2D2D2D;
}

.btn-nn:hover {
	background-color: #292828;
}

.svta--action {
	gap: 14px;
}
</style>

<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#svta-modal .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#svta-modal .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
</style>