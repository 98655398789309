<template>
  <input
    type="radio"
    :name="`${_uid}-correct-answer`"
    v-model="correct"
    :value="answerNumber.toString()"
    class="aci position-absolute d-flex justify-content-center align-content-center"
    title="Mark as Correct"
    :readonly="readonly"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "0",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    answerNumber: {
      type: Number,
      default: 1,
    },
  },
  emits: ['input'],
  data() {
    return {
      correct: this.value ? this.value.toString() : null,
    };
  },
  watch: {
    correct: {
      handler(newCorrect) {
        this.$emit('input', newCorrect);
      },
      deep: true
    },
    value: {
      handler(newValue) {
        this.correct = newValue ? newValue.toString() : null;
      },
      deep: true
    },
  },
   computed: {
    answers: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
};
</script>

<style scoped>
.aci {
  appearance: none;
  top: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  background: none;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.25s;
}

.aci:checked {
  background-color: #FFFFFF;
}

.aci::after {
  visibility: hidden;
  opacity: 0;

  content: '\2714';
  transition: all 0.25s;
}

.aci:checked::after {
  visibility: visible;
  opacity: 1;

  color: #40BE45;
}
</style>
