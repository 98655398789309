import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import MathEquationNode from './MathEquationNode.vue';

export default Node.create({
  name: 'mathEquation',
  group: 'inline',
  inline: true,
  atom: true,
  draggable: true,
  addAttributes() {
    return {
      tex: {
        default: '',
        parseHTML: (element) => element.textContent,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'math-equation',
      },
      {
        tag: 'span',
        getAttrs: (element) => element.className === 'tex' && null,
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', { class: 'tex' }, HTMLAttributes.tex];
  },
  addNodeView() {
    return VueNodeViewRenderer(MathEquationNode);
  },
});
