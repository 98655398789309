<template>
  <section class="tlt d-flex">
    <div class="tlt__icons d-flex flex-column">
      <div class="tlt__icons-space" />
      <div
        class="tlt__collapse"
        :class="{ 'tlt__collapse--show': isTimelineShown }"
      >
        <div class="tlt__collapse-inner">
          <div class="tlt__icon-container d-flex justify-content-center align-items-center">
            <img
              :src="require('@/assets/images/icons/ic_mic_editor.svg')"
              alt="Voice"
              height="16"
            />
          </div>
          <div class="tlt__icon-container d-flex justify-content-center align-items-center">
            <img
              :src="require('@/assets/images/icons/ic_music_editor.svg')"
              alt="Music"
              height="16"
            />
          </div>
        </div>
      </div>
    </div>

    <div ref="trackContent" class="tlt__content position-relative d-flex flex-column">
      <div
        class="tlt__pointer position-absolute"
        :style="{ left: (currentTime * 20) + 12 + 'px' }"
        @mousedown="dragTime = true"
      />

      <div
        class="tlt__time d-flex align-items-center px-2"
        @click="(e) => handleChangeCurrentTime(e.clientX)"
        @mousedown="dragTime = true"
      >
        <span
          v-for="second in visualDuration"
          :key="second"
          class="tlt__second d-inline-flex justify-content-center align-items-center"
        >
          <span
            v-if="((second - 1) % 5 !== 0)"
            class="tlt__second-dot d-inline-flex justify-content-center align-items-center"
          />
          <span
            v-else-if="((second - 1) % 5 === 0)"
            class="tlt__second-number position-relative d-inline-flex justify-content-center align-items-center"
          >
            {{ (second - 1) % 5 === 0 ? (formatTime(second - 1) + 's') : '' }}
          </span>
        </span>
      </div>

      <div
        class="tlt__audios tlt__collapse"
        :class="{ 'tlt__collapse--show': isTimelineShown }"
      >
        <div class="tlt__collapse-inner">
          <div class="tlt__audio-list-container d-flex align-items-center">
            <VueDraggable
              v-if="internalVoices.length > 0"
              tag="ul"
              ref="voiceList"
              v-model="internalVoices"
              class="tlt__audio-list d-flex align-items-center m-0 list-unstyled"
              ghost-class="tlt__audio-item--ghost"
              :animation="200"
              :disabled="disableEdit"
              @start="dragVoice = true"
              @end="handleEndDragVoice"
            >
              <transition-group
                type="transition"
                :name="!dragVoice ? 'change-order' : null"
                class="tlt__audio-list-wrapper"
              >
                <li v-for="voice in internalVoices" :key="voice.timelineId">
                  <tippy
                    size="small"
                    trigger="contextmenu"
                    boundary="window"
                    follow-cursor="horizontal"
                    theme="tlt__audio-item-menu"
                    :animate-fill="false"
                    :duration="250"
                    interactive
                    arrow
                    sticky
                  >
                    <template v-slot:trigger>
                      <div
                        class="tlt__audio-wave-container d-flex align-items-center rounded"
                        :class="{
                          'tlt__audio-wave-container--selected': selectedVoice === voice.timelineId,
                          'tlt__audio-wave-container--disabled': disableEdit,
                        }"
                        @click.left="selectedVoice = voice.timelineId"
                        @click.right.prevent
                      >
                        <av-waveform
                          :audio-src="voice.src"
                          :audio-controls="false"
                          :canv-width="Math.round(voice.duration) * 20"
                          :canv-height="35"
                          :playtime="false"
                          :playtime-slider="false"
                          :playtime-clickable="false"
                          played-line-color="#AEAEAE"
                          noplayed-line-color="#FFFFFF"
                          playtime-slider-color="#00000000"
                        />
                      </div>
                    </template>

                    <button
                      type="button"
                      class="tlt__audio-delete-button btn position-relative d-inline-flex align-items-center px-3 py-2 text-white"
                      :title="`Delete ${voice.name}`"
                      :disabled="disableEdit"
                      @click="$emit('delete:voice', voice.timelineId, voice.iav_id)"
                    >
                      <img src="@/assets/images/icons/ic_delete_2.svg" alt="" height="14" />
                      Delete
                    </button>
                  </tippy>
                </li>
              </transition-group>
            </VueDraggable>
          </div>
          <div class="tlt__audio-list-container d-flex align-items-center">
            <VueDraggable
              v-if="internalMusics.length > 0"
              tag="ul"
              ref="musicList"
              v-model="internalMusics"
              class="tlt__audio-list d-flex align-items-center m-0 list-unstyled"
              ghost-class="tlt__audio-item--ghost"
              :animation="200"
              :disabled="disableEdit"
              @start="dragMusic = true"
              @end="handleEndDragMusic"
            >
              <transition-group
                type="transition"
                :name="!dragMusic ? 'change-order' : null"
                class="tlt__audio-list-wrapper"
              >
                <li v-for="(music, index) in internalMusics" :key="music.timelineId">
                  <tippy
                    size="small"
                    trigger="contextmenu"
                    boundary="window"
                    follow-cursor="horizontal"
                    theme="tlt__audio-item-menu"
                    :animate-fill="false"
                    :duration="250"
                    interactive
                    arrow
                    sticky
                  >
                    <template v-slot:trigger>
                      <div
                        class="tlt__audio-wave-container tlt__audio-wave-container--music position-relative d-flex align-items-center rounded"
                        :class="{
                          'tlt__audio-wave-container--selected': selectedMusic === music.timelineId,
                          'tlt__audio-wave-container--disabled': disableEdit,
                        }"
                        :style="{ width: Math.round(music.duration) * 20 + 'px' }"
                        :data-music-index="index"
                        @click.left="!disableEdit ? selectedMusic = music.timelineId : true"
                        @click.right.prevent
                      >
                        <av-waveform
                          :audio-src="music.src"
                          :audio-controls="false"
                          :canv-width="Math.round(music.durationOrigin) * 20"
                          :canv-height="35"
                          :playtime="false"
                          :playtime-slider="false"
                          :playtime-clickable="false"
                          played-line-color="#AEAEAE"
                          noplayed-line-color="#FFFFFF"
                          playtime-slider-color="#00000000"
                        />
                      </div>
                    </template>

                    <button
                      type="button"
                      class="tlt__audio-delete-button btn position-relative d-inline-flex align-items-center px-3 py-2 text-white"
                      :title="`Delete ${music.name}`"
                      :disabled="disableEdit"
                      @click="$emit('delete:music', index)"
                    >
                      <img src="@/assets/images/icons/ic_delete_2.svg" alt="" height="14" />
                      Delete
                    </button>
                  </tippy>
                </li>
              </transition-group>
            </VueDraggable>
            <button
              type="button"
              class="tlt__add-audio-button btn d-inline-flex justify-content-center align-items-center text-white"
              title="Add Music"
              :disabled="disableEdit"
              @click="$emit('click:add-music')"
            >
              <font-awesome-icon icon="fa-solid fa-plus" />
              <span class="sr-only">Add Music</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import interact from 'interactjs';
import VueDraggable from 'vuedraggable';

export default {
  components: {
    VueDraggable,
  },
  props: {
    isTimelineShown: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    extraDuration: {
      type: Boolean,
      default: false,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    voices: {
      type: Array,
      default: () => [],
    },
    musics: {
      type: Array,
      default: () => [],
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click:add-music', 'delete:voice', 'delete:music', 'change:voices-order', 'change:musics-order', 'change:music-duration', 'change:current-time'],
  data() {
    return {
      displayDuration: Math.round(this.duration),

      internalVoices: this.voices,
      internalMusics: this.musics,
      selectedVoice: null,
      selectedMusic: null,
      dragVoice: false,
      dragMusic: false,
      dragTime: false,

      deleteMusicMenu: null,

      isResizingMusic: false,
    };
  },
  computed: {
    visualDuration() {
      if (
        this.extraDuration
        && Math.round(this.duration) > 0
        && Math.round(this.duration) === this.displayDuration
      ) {
        return this.displayDuration + 3;
      }

      if (!this.extraDuration) {
        return this.displayDuration + 1;
      }

      return this.displayDuration;
    },
  },
  watch: {
    voices(newVoices) {
      this.internalVoices = newVoices;
    },
    musics(newMusics) {
      this.internalMusics = newMusics;
    },
    disableEdit(newDisableEdit) {
      if (newDisableEdit === true) {
        this.selectedVoice = null;
        this.selectedMusic = null;
      }
    },
    duration() {
      this.setDisplayDuration();
    },
    currentTime(newCurrentTime) {
      const trackContentScrollWidth = this.$refs.trackContent.scrollWidth;
      const trackContentOffsetWidth = this.$refs.trackContent.offsetWidth;
      const trackContentScrollLeft = this.$refs.trackContent.scrollLeft;

      const secondTravelDistance = 20;
      let startVisibleSecond = 0;
      let endVisibleSecond = Math.round(this.duration);
      const totalVisibleSeconds = Math.round(trackContentOffsetWidth / secondTravelDistance - 1);

      if (trackContentScrollWidth > trackContentOffsetWidth) {
        startVisibleSecond = Math.round(trackContentScrollLeft > 0 ? trackContentScrollLeft / secondTravelDistance : trackContentScrollLeft);
        endVisibleSecond = Math.round((trackContentOffsetWidth + trackContentScrollLeft) / secondTravelDistance - 1);
      }

      if (newCurrentTime < startVisibleSecond || newCurrentTime > endVisibleSecond) {
        if (newCurrentTime > totalVisibleSeconds) {
          this.$refs.trackContent.scrollLeft = secondTravelDistance * (newCurrentTime - totalVisibleSeconds);
        } else {
          this.$refs.trackContent.scrollLeft = 0;
        }
      }
    },
  },
  methods: {
    handleUnselectVoiceWhenClickOutside(e) {
      if (this.$refs.voiceList) {
        if (!this.$refs.voiceList.$el.contains(e.target) && this.selectedVoice) {
          this.selectedVoice = null;
        }
      }
    },
    handleUnselectMusicWhenClickOutside(e) {
      if (this.$refs.musicList) {
        if (!this.$refs.musicList.$el.contains(e.target) && this.selectedMusic) {
          this.selectedMusic = null;
        }
      }
    },
    handleDeleteSelectedVoice(e) {
      if (e.isComposing || e.keyCode === 229) {
        return;
      }

      // When press backspace or delete
      if ([8, 46].includes(e.keyCode) && this.selectedVoice && !this.disableEdit) {
        this.$emit('delete:voice', this.selectedVoice);
        this.selectedVoice = null;
      }
    },
    handleDeleteSelectedMusic(e) {
      if (e.isComposing || e.keyCode === 229) {
        return;
      }

      // When press backspace or delete
      if ([8, 46].includes(e.keyCode) && this.selectedMusic && !this.disableEdit) {
        const musicIndex = this.internalMusics.findIndex((music) => music.timelineId === this.selectedMusic);

        this.$emit('delete:music', musicIndex);
        this.selectedMusic = null;
      }
    },
    handleChangeCurrentTime(cursorPosition) {
      const trackContentScrollLeft = this.$refs.trackContent.scrollLeft;

      const trackContentOffsetLeft = this.$refs.trackContent.getBoundingClientRect().left;
      const trackContentCursorPositionAbsolute = cursorPosition - trackContentOffsetLeft;
      const trackContentCursorPositionRelative = trackContentCursorPositionAbsolute + trackContentScrollLeft;

      const paddingWidth = 12;
      const secondTravelDistance = 20;

      const currentTime = Math.round(
        (trackContentCursorPositionRelative - paddingWidth) / secondTravelDistance
      );

      let newCurrentTime = currentTime;

      if (currentTime <= 0) {
        newCurrentTime = 0;
      } else if (currentTime >= Math.round(this.duration)) {
        newCurrentTime = Math.round(this.duration);
      }

      this.$emit('change:current-time', newCurrentTime);
    },
    handleMousemoveTime(e) {
      if (this.dragTime) {
        const cursorPosition = e.clientX;

        const trackContentScrollWidth = this.$refs.trackContent.scrollWidth;
        const trackContentOffsetWidth = this.$refs.trackContent.offsetWidth; // Visible width
        const trackContentScrollLeft = this.$refs.trackContent.scrollLeft;

        const trackContentOffsetLeft = this.$refs.trackContent.getBoundingClientRect().left;
        const trackContentCursorPosition = cursorPosition - trackContentOffsetLeft;

        // Handle auto-scroll
        if (trackContentScrollWidth > trackContentOffsetWidth) {
          const scrollTriggerOffset = 30;
          const scrollStepDefault = this.dragTime ? 40 : 5000;
          const scrollToRightStepPossible = trackContentScrollWidth - trackContentOffsetWidth - trackContentScrollLeft;
          const scrollToLeftStepPossible = trackContentScrollLeft;

          if (trackContentCursorPosition >= (trackContentOffsetWidth - scrollTriggerOffset)) {
            this.$refs.trackContent.scrollLeft += scrollStepDefault < scrollToRightStepPossible ? scrollStepDefault : scrollToRightStepPossible;
          } else if (trackContentCursorPosition <= scrollTriggerOffset) {
            this.$refs.trackContent.scrollLeft -= scrollStepDefault < scrollToLeftStepPossible ? scrollStepDefault : scrollToLeftStepPossible;
          }
        }

        this.handleChangeCurrentTime(cursorPosition);
      }
    },
    handleMouseupTime() {
      this.dragTime = false;
    },
    handleEndDragVoice() {
      this.dragVoice = false;
      this.$emit('change:voices-order', this.internalVoices);
    },
    handleEndDragMusic(e) {
      this.dragMusic = false;
      this.$emit('change:musics-order', this.internalMusics, e.oldIndex, e.newIndex);
    },
    resizeMusicListener() {
      const moveListener = (e) => {
        const { musicIndex } = e.target.dataset;

        const music = this.internalMusics[musicIndex];

        const secondTravelDistance = 20;
        const newDurationDiff = e.deltaRect.right / secondTravelDistance;

        let newDuration = music.duration + newDurationDiff;

        if (newDuration < 0) {
          newDuration = 0;
        } else if (newDuration > music.durationOrigin) {
          newDuration = music.durationOrigin;
        }

        this.$emit('change:music-duration', musicIndex, Number(newDuration.toFixed(2)));
      };

      interact('.tlt__audio-wave-container--music')
        .resizable({
          edges: { right: true },
          listeners: {
            move: moveListener,
          },
        })
        .on('resizestart', () => this.isResizingMusic = true)
        .on('resizeend', () => this.isResizingMusic = false);
    },
    setDisplayDuration() {
      const trackContentOffsetWidth = this.$refs.trackContent.offsetWidth;
      const secondTravelDistance = 20;

      const totalVisibleSeconds = Math.round(trackContentOffsetWidth / secondTravelDistance - 1);

      if (Math.round(this.duration) > totalVisibleSeconds) {
        this.displayDuration = Math.round(this.duration);
      } else {
        this.displayDuration = totalVisibleSeconds;
      }
    },
    formatTime(second) {
      return Intl.NumberFormat('en', { notation: 'compact' }).format(second);
    },
  },
  mounted() {
    window.addEventListener('resize', this.setDisplayDuration);
    this.setDisplayDuration();

    document.addEventListener('click', this.handleUnselectVoiceWhenClickOutside);
    document.addEventListener('click', this.handleUnselectMusicWhenClickOutside);
    document.addEventListener('keyup', this.handleDeleteSelectedVoice);
    document.addEventListener('keyup', this.handleDeleteSelectedMusic);
    document.addEventListener('mousemove', this.handleMousemoveTime);
    document.addEventListener('mouseup', this.handleMouseupTime);

    this.resizeMusicListener();

    // const mockPlayTimeline = setInterval(() => {
    //   if (this.currentTime >= this.duration) {
    //     clearInterval(mockPlayTimeline);
    //   } else {
    //     if (!this.dragTime) {
    //       this.$emit('change:current-time', this.currentTime + 1);
    //     }
    //   }
    // }, 250);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDisplayDuration);
    document.removeEventListener('click', this.handleUnselectVoiceWhenClickOutside);
    document.removeEventListener('click', this.handleUnselectMusicWhenClickOutside);
    document.removeEventListener('keyup', this.handleDeleteSelectedVoice);
    document.removeEventListener('keyup', this.handleDeleteSelectedMusic);
    document.removeEventListener('mousemove', this.handleMousemoveTime);
    document.removeEventListener('mouseup', this.handleMouseupTime);
  },
};
</script>

<style scoped>
.tlt__icons-space {
  width: 60px;
  height: 20px;
  border-bottom: 1px solid #2D2D2D;
}

.tlt__icon-container {
  width: 60px;
  height: 60px;
  border-right: 1px solid #2D2D2D;
  user-select: none;
}

.tlt__icon-container + .tlt__icon-container {
  border-top: 1px solid #2D2D2D;
}

.tlt__content {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.tlt__content::-webkit-scrollbar {
  display: none;
}

.tlt__pointer {
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #DF2723;
  cursor: ew-resize;
  transition: left 0.1s;
}

.tlt__pointer::before {
  content: "";
  position: absolute;
  top: 0;
  translate: -50% 0;
  border-inline: 6px solid transparent;
  border-top: 8px solid #DF2723;
}


.tlt__time {
  width: fit-content;
  min-width: 100%;
  height: 20px;
  gap: 10px;
  border-bottom: 1px solid #2D2D2D;
  cursor: pointer;
}

.tlt__second {
  flex-grow: 0;
  flex-shrink: 0;
  width: 10px;
  height: 100%;
  color: #4D4D4D;
  user-select: none;
}

.tlt__second-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #2D2D2D;
}

.tlt__second-number {
  height: 100%;
  font-size: 10px;
}

.tlt__second-number::after {
  content: "";
  position: absolute;
  inset: auto auto 0 auto;
  width: 1px;
  height: 2px;
  background-color: #2D2D2D;
}

.tlt__collapse {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.25s;
}

.tlt__audios .tlt__collapse-inner {
  width: fit-content;
  min-width: 100%;
}

.tlt__collapse--show {
  grid-template-rows: 1fr;
}

.tlt__collapse-inner {
  overflow: hidden;
}

.tlt__audio-list-container {
  width: 100%;
  height: 60px;
  gap: 8px;
  padding-inline: 12px;
}

.tlt__audio-list-container + .tlt__audio-list-container {
  border-top: 1px solid #2D2D2D;
}

.tlt__audio-list-wrapper {
  display: contents;
}

.tlt__audio-wave-container {
  box-shadow: inset 0 0 0 1px #2D2D2D;
  overflow: hidden;
  transition: box-shadow 0.25s;
}

.tlt__audio-wave-container:not(
  .tlt__audio-wave-container--selected,
  .tlt__audio-wave-container--disabled,
):hover {
  box-shadow: inset 0 0 0 1px #4D4D4D;
}

.tlt__audio-wave-container--selected {
  box-shadow: inset 0 0 0 1px #6D75F6;
}

.tlt__audio-delete-button {
  z-index: 1;
  gap: 6px;
  transition: background-color 0.25s;
}

.tlt__audio-delete-button:not(:disabled):hover {
  background-color: #C43D30;
}

.tlt__add-audio-button {
  width: 35px;
  height: 35px;
  background-color: #2D2D2D;
  font-size: 18px;
  transition: background-color 0.25s;
}

.tlt__add-audio-button:not(:disabled):hover {
  background-color: #3D3D3D;
}
</style>

<style>
.tlt__audio-item--ghost {
  opacity: 0.5;
}

.change-order-move {
  transform: all 0.5s;
}

.tlt__audio-item-menu-theme {
  padding: 0 !important;
}
</style>
