<template>
  <section class="gv-library position-relative d-flex flex-column text-left" :class="{ 'gv-library__nexpanded' : !isExpanded, 'gv-library--takeaour' : isTakeatour && takeatourStep == 4 }">
    <h2
      class="gv-library__title mt-4"
      :class="{
        'px-4': !isExpanded,
        'gv-library__title--nexpanded pl-4 pr-3': isExpanded,
      }"
    >
      Library
    </h2>
    <button
      id="v-step-4"
      type="button"
      class="gv-library__expand-toggle btn position-absolute d-flex justify-content-center align-items-center text-white"
      :class="{ 'gv-library__expand-toggle--expanded p-0 rounded-circle': isExpanded, 'gv-library__expand-toggle--nexpanded' : !isExpanded }"
      :title="isExpanded ? 'Collapse' : 'Expand'"
      @click="disableLibrary == 'nok' ? $bvModal.show('sttvm-modal') : isExpanded = !isExpanded"
    >
      <span class="gv-library__expand-text" :class="{ 'gv-library__expand-text--expanded' : !isExpanded }">
        {{  !isExpanded ? 'Library' : '' }}
      </span>
      <font-awesome-icon
        icon="fa-solid fa-chevron-right"
        class="gv-library__expand-toggle-icon"
        :class="{ 'gv-library__expand-toggle-icon--expanded': isExpanded }"
      />
    </button>
    <div
      class="gv-library__content"
      :class="{ 'gv-library__content--expanded': isExpanded }"
    >
      <div class="gv-library__content-inner">
        <LibraryGroup
          :character="character"
          :mode="mode"
          @change:mode="(val) => $emit('change:mode', val)"
          @change:character="(newCharacter) => $emit('change:character', newCharacter)"
          @use:video="(val) => $emit('use:video', val)"
          @click:preview-trim-stock-video="(val) => this.$emit('click:preview-trim-stock-video', val)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import LibraryGroup from './Group';

export default {
  props: {
    character: {
      type: Object,
      default: null,
    },
    isLibraryExpanded: {
      type: Boolean,
      default: false
    },
    isTakeatour: {
      type: Boolean,
      default: false,
    },
    takeatourStep: {
      type: Number,
      default: 0
    },
    mode: {
      validator(value) {
        return ['voice-only', 'ai-characters'].includes(value);
      }
    },
    disableLibrary: {
      validator(value) {
        return ['ok', 'nok'].includes(value);
      },
    }
  },
  emits: ['change:character'],
  components: {
    LibraryGroup,
  },
  data() {
    return {
      isExpanded: this.isLibraryExpanded,
    };
  },
  watch: {
    isLibraryExpanded(newVal) {
      this.isExpanded = newVal
    },
    isExpanded: {
      handler(newVal) {
        this.$emit('change:expanded', newVal);
      },
      deep: true,
    },
  }
};
</script>

<style scoped>
.gv-library {
  gap: 8px;
  border-right: 1px solid #2D2D2D;

  transition: background-color 0.25s;
}

.gv-library--takeaour {
  z-index: 2001;
}

.gv-library__nexpanded {
  border: none;
  background-color: #585859;
}

.gv-library__expand-toggle {
  inset: 50px 45px auto auto;
  width: auto;
  height: auto;
  background-color: #1F1F1F;
  font-size: 12px;
  transition: background-color 0.25s, rotate 0.25s;
}

.gv-library__expand-toggle:hover {
  background-color: #2F2F2F;
}

.gv-library__expand-toggle--expanded {
  rotate: 180deg;
  inset: 50px -14px auto auto;
  width: 28px;
  height: 28px;
}

.gv-library__expand-toggle--nexpanded {
  padding: 10px 6px;
  border-radius: 36px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.30) !important;
}

.gv-library__title {
  font-size: 18px;
  font-weight: 500;

  visibility: hidden;
  opacity: 0;

  transition: padding 0.25s, visibility 0.65s, opacity 0.55s;
}

.gv-library__title--nexpanded {
  opacity: 1;
  visibility: visible;
}

.gv-library__content {
  visibility: hidden;
  opacity: 0;
  max-width: 0;

  width: 350px;
  max-height: 100%;
  overflow-x: hidden;
  transition: visibility 0.5s, opacity 0.45s, max-width 0.5s;
}

.gv-library__content--expanded {
  visibility: visible;
  opacity: 1;
  max-width: 350px;
}

.gv-library__content-inner {
  width: 350px;
  height: 100%;
}

.gv-library__expand-text {
  opacity: 0;
  visibility: hidden;

  color: #fff;
  writing-mode: vertical-lr;
  transform: rotate(0deg);
  line-height: 16px;
  font-size: 18px;

  transition: opacity 0.25s, visibility 0.25s;
}

.gv-library__expand-text--expanded {
  opacity: 1;
  visibility: visible;
}
</style>
