var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type == 'question')?_c('div',[_c('Toolbar',[_c('ToolbarItem',{attrs:{"name":"Bold","isActive":_vm.editor?.isActive('bold')},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-bold"}})],1),_c('ToolbarItem',{attrs:{"name":"Italic","isActive":_vm.editor?.isActive('italic')},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-italic"}})],1),_c('ToolbarItem',{attrs:{"name":"Underline","isActive":_vm.editor?.isActive('underline')},on:{"click":function($event){_vm.editor.chain().focus().toggleUnderline().run()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-underline"}})],1),_c('ToolbarItem',{attrs:{"name":"Align Left","isActive":_vm.editor?.isActive({ textAlign: 'left' })},on:{"click":function($event){_vm.editor.chain().focus().setTextAlign('left').run()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-align-left"}})],1),_c('ToolbarItem',{attrs:{"name":"Align Center","isActive":_vm.editor?.isActive({ textAlign: 'center' })},on:{"click":function($event){_vm.editor.chain().focus().setTextAlign('center').run()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-align-center"}})],1),_c('ToolbarItem',{attrs:{"name":"Align Right","isActive":_vm.editor?.isActive({ textAlign: 'right' })},on:{"click":function($event){_vm.editor.chain().focus().setTextAlign('right').run()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-align-right"}})],1),_c('ToolbarItem',{attrs:{"name":"Align Justify","isActive":_vm.editor?.isActive({ textAlign: 'justify' })},on:{"click":function($event){_vm.editor.chain().focus().setTextAlign('justify').run()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-align-justify"}})],1),_c('ToolbarItem',{attrs:{"name":"Text Color","popover":{
          component: _vm.TextColorPopover,
          props: {
            color: _vm.editor?.getAttributes('textStyle').color || '#ffffff',
            onSet(color) {
              _vm.editor
                .chain()
                .focus()
                .setColor(color)
                .run();
            },
            onUnset() {
              _vm.editor
                .chain()
                .focus()
                .unsetColor()
                .run();
            },
          },
        }}},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-a"}}),_c('div',{style:({
              height: '3px',
              width: 'calc(100% + 2px)',
              marginTop: '1px',
              backgroundColor: _vm.editor?.getAttributes('textStyle').color || '#FFFFFF',
            })})],1)])],1)],1):_c('div',{staticClass:"tlbr--answer"},[_c('Toolbar',[_c('ToolbarItem',{attrs:{"name":"Image","popover":{
          component: _vm.ImagePopover,
          props: {
            onInsert({ src, alt }) {
              _vm.editor.chain().focus().setImage({ src, alt, title: alt }).run();
            },
          },
        }}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-image"}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }