<template>
    <div>
        <b-modal id="package-error-msg" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('package-error-msg'), $router.replace({ params: {} })"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Warn">
                <div class="mt-4 text-white" v-if="tipe_package == 'Professional'">
                    <h4 class="font-weight-bold mb-4">{{ $t('multi-pro-subscription-required') }}</h4>
                    <h5 class="font-weight-normal" v-html="$t('multi-pro-subscription-required__desc--package')"></h5>
                </div>
                <div class="mt-4 text-white" v-if="tipe_package == 'Creator'">
                    <h4 class="font-weight-bold mb-4">{{ $t('multi-creator-subscription-required') }}</h4>
                    <h5 class="font-weight-normal" v-html="$t('multi-creator-subscription-required__desc--package')"></h5>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4" v-if="$route.name == 'Home'">
                    <button class="btn btn-grey mr-1" @click="$bvModal.hide('package-error-msg')"><p class="mx-2 mb-0 font-weight-bold text-white">{{ $t('later') }}</p></button>
                    <button class="btn btn-purple ml-1" @click="$router.push({ name:'TopUp' })"><p class="mx-2 mb-0 font-weight-bold text-white">{{ $t('upgrade-now') }}</p></button>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4" v-else>
                    <button class="btn btn-grey mr-1" @click="$bvModal.hide('package-error-msg')"><p class="mx-2 mb-0 font-weight-bold text-white">{{ $t('close') }}</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
  props: {
    tipe_package: {
      type: String,
      default: ""
    }
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
.btn-purple {
  background-color: #6d75f6;
  color: white;
  box-shadow: none !important;
  transition: background-color 0.25s;
}

.btn-grey {
  background-color: #2D2D2D;
  color: white;
  box-shadow: none !important;
  transition: background-color 0.25s;
}

.btn-purple:hover {
	background-color: #545EED;
}

.btn-grey:hover {
	background-color: #292828;
}
</style>
<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#package-error-msg .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#package-error-msg .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#package-error-msg .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

button {
  box-shadow: none !important;
}
</style>