<template>
  <modal
    :name="name"
    classes="mc d-flex flex-column text-left"
    :width="width"
    height="auto"
    scrollable
    @before-open="$emit('before-open')"
    @opened="$emit('opened')"
    @before-close="$emit('before-close')"
    @closed="$emit('closed'); activeTab = 1"
  >
    <header class="mc__header d-flex align-items-center justify-content-between px-4 pt-4 pb-2">
      <h2 class="mc__title">{{ title }}</h2>
      <button
        type="button"
        class="mc__close-button btn d-flex justify-content-center align-items-center"
        title="Close"
        @click="$modal.hide(name)"
      >
        &times;
      </button>
    </header>

    <div class="px-3 pb-3">
      <div v-if="tabNames.length > 0" class="mc__tab-content-wrapper d-flex flex-column">
        <ul class="mc__tab-list d-flex m-0 list-unstyled">
          <li v-for="(tabName, index) in tabNames" :key="index">
            <button
              type="button"
              class="mc__tab-button btn"
              :class="{ 'mc__tab-button--active': index + 1 === activeTab }"
              :title="tabName"
              @click="activeTab = index + 1; $emit(`open-tab-${index + 1}`)"
            >
              {{ tabName }}
            </button>
          </li>
        </ul>

        <slot :name="`tab-${activeTab}`" />
      </div>
      <slot v-else name="default" />
    </div>

    <footer class="mc__footer d-flex justify-content-end px-4 py-3">
      <button
        type="button"
        class="mc__cancel-button btn"
        title="Cancel"
        @click="$modal.hide(name)"
      >
        Cancel
      </button>
      <button
        type="button"
        class="mc__save-button btn"
        :title="saveText"
        :disabled="disableSave"
        @click="$emit('save'); $modal.hide(name)"
      >
        {{ saveText }}
      </button>
    </footer>
  </modal>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '800',
    },
    tabNames: {
      type: Array,
      default() {
        return [];
      },
    },
    saveText: {
      type: String,
      default: 'Save',
    },
    disableSave: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['before-open', 'opened', 'before-close', 'closed', 'save'],
  data() {
    return {
      activeTab: 1,
    };
  },
};
</script>

<style scoped>
.mc__header {
  gap: 16px;
}

.mc__title {
  font-size: 18px;
  font-weight: 600;
}

.mc__close-button {
  width: 28px;
  height: 28px;
  background-color: #D9D9D9;
  border-radius: 50%;
  font-size: 20px;
  color: #1C1C1C;
  transition: background-color 0.25s;
}

.mc__close-button:hover {
  background-color: #FFFFFF;
}

.mc__tab-content-wrapper {
  gap: 20px;
}

.mc__tab-list {
  /* border-bottom: 1px solid #2D2D2D; */
}

.mc__tab-button {
  padding: 6px 10px;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  transition: color 0.25s, border 0.25s;
}

.mc__tab-button--active {
  border-bottom-color: rgb(188, 59, 242);;
  color: rgb(188, 59, 242);
}

.mc__footer {
  gap: 8px;
  border-top: 0.1px solid #2d2d2d;
}

.mc__cancel-button, .mc__save-button {
  padding: 6px 20px;
  border-radius: 8px;
  color: #FFFFFF;
  transition: background-color 0.25s, color 0.25s;
}

.mc__cancel-button {
  background-color: #2D2D2D;
}

.mc__cancel-button:hover {
  background-color: #3D3D3D;
}

.mc__save-button {
  background-color: #6D75F6;
}

.mc__save-button:disabled {
  background-color: #2D2D2D;
  color: #585859;
}

.mc__save-button:not(:disabled):hover {
  background-color: #545EED;
}
</style>

<style>
.mc {
  gap: 8px;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.vm--container::-webkit-scrollbar-thumb {
  background-color: #EEEEEE;
}

.vm--overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
</style>
