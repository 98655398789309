<template>
  <section class="tl d-flex flex-column" :class="{ 'tl--takeatour' : isTakeatour && takeatourStep == 2 }">
    <TimelineHeader
      :mode="mode"
      :is-timeline-shown="isTimelineShown"
      :disable-backward="disableBackward"
      :disable-main-control="disableMainControl"
      :disable-forward="disableForward"
      :main-control-state="mainControlState"
      :current-time="currentTime"
      :duration="duration"
      :voice-volume="voiceVolume"
      :music-volume="musicVolume"
      :video-volume="videoVolume"
      :video-ratios="videoRatios"
      :video-ratio="videoRatio"
      :second-distance="parseInt(secondDistance)"
      @update:second-distance="(newVal) => secondDistance = newVal"
      @click:timeline-toggle="isTimelineShown = !isTimelineShown"
      @click:backward="handleClickBackward"
      @click:main-control="$emit('click:main-control')"
      @click:forward="handleClickForward"
      @change:voice-volume="(newVoiceVolume) => $emit('change:voice-volume', newVoiceVolume)"
      @change:music-volume="(newMusicVolume) => $emit('change:music-volume', newMusicVolume)"
      @change:video-volume="(newVideoVolume) => $emit('change:video-volume', newVideoVolume)"
      @change:video-ratio="(newVideoRatio) => $emit('change:video-ratio', newVideoRatio)"
    />
    <TimelineTrack
      :second-distance-set="parseInt(secondDistance)"
      :is-timeline-shown="isTimelineShown"
      :current-time="currentTime"
      :duration="duration"
      :extra-duration="isWithExtraDuration"
      :disable-edit="mainControlState === 'generating' || mainControlState === 'pause'"
      @change:current-time="(newCurrentTime) => $emit('change:current-time', newCurrentTime)"

      :voices="voices"
      :active-voices="activeVoices"
      @update:selected-voice="(newSelectedVoice) => $emit('update:selected-voice', newSelectedVoice)"
      @delete:voice="(id) => $emit('delete:voice', id)"
      @change:voices-order="(newVoices) => $emit('change:voices-order', newVoices)"
      @update:start-voices="(newStartVoices, index) => $emit('update:start-voices', newStartVoices, index)"

      :musics="musics"
      @click:add-music="$emit('click:add-music')"
      @delete:music="(id) => $emit('delete:music', id)"
      @change:music-duration="(musicTimelineId, newMusicDuration) => $emit('change:music-duration', musicTimelineId, newMusicDuration)"
      @change:musics-order="(musics, oldIndex, newIndex) => $emit('change:musics-order', musics, oldIndex, newIndex)"
      @update:start-music="(newStartmusic, index) => $emit('update:start-music', newStartmusic, index)"

      :images="images"
      :disable-images="disableImages"
      :active-image-index="activeImageIndex"
      @update:active-image-index="(val) => $emit('update:active-image-index', val)"
      @click:add-image="$emit('click:add-image')"
      @delete:image="(id) => $emit('delete:image', id)"
      @change:image-duration="(imageTimelineId, newImageDuration) => $emit('change:image-duration', imageTimelineId, newImageDuration)"
      @change:images-order="(images, oldIndex, newIndex) => $emit('change:images-order', images, oldIndex, newIndex)"
    />
  </section>
</template>

<script>
import TimelineHeader from './Header';
import TimelineTrack from './Track';

export default {
  components: {
    TimelineHeader,
    TimelineTrack,
  },
  props: {
    voices: {
      type: Array,
      default: () => [],
    },
    musics: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
    disableImages: {
      type: Boolean,
      default: false,
    },
    mainControlState: {
      type: String,
      validator(value) {
        return ['generate', 'generating', 'play', 'pause'].includes(value);
      },
      default: 'generate',
    },
    disableMainControl: {
      type: Boolean,
      default: false,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    preciseDuration: {
      type: Number,
      default: null,
    },
    voiceVolume: {
      type: Number,
      default: 0.5,
    },
    musicVolume: {
      type: Number,
      default: 0.5,
    },
    videoVolume: {
      type: Number,
      default: 0.5,
    },
    videoRatios: {
      type: Array,
      default: () => [],
    },
    videoRatio: {
      type: String,
      default: null,
    },
    isTakeatour: {
      type: Boolean,
      default: false,
    },
    takeatourStep: {
      type: Number,
      default: 0
    },
    activeImageIndex: {
      type: Number,
      default: 0,
    },
    mode: {
      validator(value) {
        return ['voice-only', 'ai-characters'].includes(value);
      }
    },
    activeVoices: {
      type: String,
      default: null
    }
  },
  emits: [
    'click:main-control',
    'change:current-time',

    'delete:voice',
    'change:voices-order',

    'click:add-music',
    'delete:music',
    'change:music-duration',
    'change:musics-order',

    'click:add-image',
    'delete:image',
    'change:image-duration',
    'change:images-order',

    'change:voice-volume',
    'change:music-volume',
    'change:video-ratio',
  ],
  data() {
    return {
      isTimelineShown: true,
      secondDistance: 40
    };
  },
  computed: {
    duration() {
      // if (this.preciseDuration !== null || typeof(this.preciseDuration) === 'number' ) {
      //   return this.preciseDuration;
      // }

      const voicesTotalDuration = this.voices[this.voices.length-1]?.end || 0

      const musicsTotalDuration = this.musics[this.musics.length-1]?.end || 0

      const imagesTotalDuration = this.disableImages ? 0 :this.images.reduce((totalDuration, image) => {
        return totalDuration + image.duration;
      }, 0);

      return Math.max(...[voicesTotalDuration, musicsTotalDuration, imagesTotalDuration]);
    },
    isWithExtraDuration() {
      const voicesTotalDuration = this.voices.reduce((totalDuration, voice) => {
        return totalDuration + voice.duration;
      }, 0);
      const musicsTotalDuration = this.musics.reduce((totalDuration, music) => {
        return totalDuration + music.duration;
      }, 0);
      const imagesTotalDuration = this.images.reduce((totalDuration, image) => {
        return totalDuration + image.duration;
      }, 0);

      return musicsTotalDuration > voicesTotalDuration || imagesTotalDuration > voicesTotalDuration;
    },
    disableBackward() {
      return this.currentTime === 0 || this.mainControlState === 'pause';
    },
    disableForward() {
      return this.currentTime === this.duration || this.mainControlState === 'pause';
    },
  },
  watch: {
    voices() {
      if (this.currentTime > this.duration) {
        this.$emit('change:current-time', 0);
      }
    },
    musics() {
      if (this.currentTime > this.duration) {
        this.$emit('change:current-time', 0);
      }
    },
    images() {
      if (this.currentTime > this.duration) {
        this.$emit('change:current-time', 0);
      }
    },
  },
  methods: {
    handleClickBackward() {
      this.$emit('change:current-time', 0);
    },
    handleClickForward() {
      this.$emit('change:current-time', this.duration);
    },
  },
};
</script>

<style scoped>
.tl {
  z-index: 100;
  background-color: #000000;
}

.tl--takeatour {
  z-index: 2001;
}
</style>
