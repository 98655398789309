import { Extension } from '@tiptap/core';

export default Extension.create({
  name: 'textSelection',
  addCommands() {
    return {
      getSelectionText: () => ({ editor }) => {
        const { from, to, empty } = editor.state.selection;

        if (empty) {
          return null;
        }

        return editor.state.doc.textBetween(from, to, ' ');
      },
      getSelectionRange: () => ({ editor }) => {
        const { from, to, empty } = editor.state.selection;

        if (empty) {
          return null;
        }

        return { from, to };
      }
    };
  },
});
