<template>
    <div>
        <b-modal id="std-gv" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm" @hidden="$emit('reset-egv')">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="close()"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Save to Draft">
                <div class="mt-3">
                    <h5 class="font-weight-bold mb-2">{{ $t('wait') }}</h5>
                    <div class="d-flex flex-column">
                        <span class="">{{ $t('your-content-almost-finished') }}</span>
                        <span v-if="$route.params.mode == 'new'" class="">{{ $t('save-it-as-draft') }}</span>
                        <span v-else class="">Do you want to save changes?</span>
                    </div>
                </div>
                <div v-if="$route.params.mode == 'new'" class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$emit('cancel-draft')"><p class="mb-0 font-weight-bold">{{ $t('delete') }}</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="$emit('save-draft', true)"><p class="mx-2 mb-0 font-weight-bold">{{ $t('save-to-draft') }}</p></button>
                </div>
                <div v-else class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$emit('cancel-draft')"><p class="mb-0 font-weight-bold">Cancel</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="$emit('save-draft', false)"><p class="mx-2 mb-0 font-weight-bold">Save Changes</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>

export default{
    data(){
        return {

        }
    },

    props: {

    },

	methods: {
		close() {
			this.$bvModal.hide('std-gv')
		}
	}

}
</script>
<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#std-gv .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#std-gv .modal-content {
    background-color: #1F1F1F;
    color: white;
    border-radius: 15.0499px;
}
.btn-primary{
    background-color: #6D75F6;
    border: none;
}
.btn-primary:hover{
    background-color: #6D75F6;
    /* border: none; */
}
.btn-secondary{
    background-color: #2D2D2D;
    border: #2D2D2D;
    color: #ffffff;
}
.btn-secondary:hover{
    background-color: #2D2D2D;
    border: #2D2D2D;
    color: #ffffff;
}
</style>
<style scoped>
button, .btn{
    box-shadow: none;
}
</style>