<template>
  <div class="se d-flex flex-column">
    <header class="se__header d-flex align-items-center px-4 py-2">
      <button
        type="button"
        class="se__voice-type-button btn d-inline-flex align-items-center px-2 py-1 text-white"
        title="Select Voice Type"
        @click="$emit('click:voice-type')"
      >
        <img
          :src="characterAvatarSrc"
          width="20"
          height="20"
          :alt="characterName"
        />
        {{ characterName }}
      </button>

      <button
        type="button"
        class="se__play-button btn d-inline-flex justify-content-center align-items-center ml-auto text-white"
        :class="{
          'se__play-button--black': playButtonState !== 'generate'
        }"
        :title="
          playButtonState === 'generate' ? 'Generate Voice'
          : playButtonState === 'loading' ? 'Loading'
          : playButtonState === 'play' ? 'Play'
          : 'Pause'
        "
        :disabled="disableAction || playButtonState === 'loading'"
        @click="$emit('click:action', playButtonState)"
      >
        <div v-show="playButtonState === 'loading'">
          <b-spinner
            variant="light"
            label="Loading..."
            small
          />
        </div>
        <font-awesome-icon
          v-show="playButtonState !== 'loading'"
          :icon="`fa-solid ${playButtonState === 'pause' ? 'fa-pause' : 'fa-play'}`"
        />
      </button>

      <b-dropdown
        boundary="window"
        toggle-class="se__options-toggle d-inline-flex justify-content-center align-items-center p-0"
        menu-class="se__options-menu"
        :disabled="disableAction || playButtonState === 'loading'"
        title="Options"
        no-caret
      >
        <template #button-content>
          <img
            :src="require('@/assets/images/icons/ic_options_editor.svg')"
            class="se__options-toggle-icon"
            alt="Options"
          />
          <span class="sr-only">Options</span>
        </template>
        <b-dropdown-item-button
          button-class="se__options-button d-flex align-items-center px-3 py-1 text-white"
          title="Delete"
          :disabled="disableAction || playButtonState === 'loading'"
          @click="$emit('click:delete')"
        >
          <img
            :src="require('@/assets/images/icons/ic_delete_sb.svg')"
            class="se__options-button-icon"
            alt=""
          />
          Delete
        </b-dropdown-item-button>
      </b-dropdown>
    </header>
    <section class="se__main d-flex flex-column">
      <textarea
        ref="textarea"
        v-model="scriptText"
        class="se__textarea px-4 py-3 text-white"
        placeholder="Input your text"
        spellcheck="false"
        :readonly="actionState === 'loading'"
        title="Type script here ..."
      />
    </section>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    actionState: {
      type: String,
      validator(value) {
        return ['generate', 'loading', 'play', 'pause'].includes(value);
      },
      default: 'generate',
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
    characterAvatarSrc: {
      type: String,
      default: require('@/assets/images/icons/sovia_editor.svg'),
    },
    characterName: {
      type: String,
      default: 'Sovia',
    },
  },
  emits: ['change:text', 'click:voice-type', 'click:action', 'click:delete'],
  data() {
    return {
      isMounted: false,
      scriptText: this.text,
      playButtonState: this.actionState,
    };
  },
  watch: {
    actionState(newActionState) {
      this.playButtonState = newActionState;
    },
    text(newText) {
      this.scriptText = newText;
    },
    scriptText: {
      handler(newScriptText) {
        this.$emit('change:text', newScriptText);

        if (this.isMounted) {
          this.autoResizeTextarea();
        }
      },
      immediate: true,
    },
  },
  methods: {
    autoResizeTextarea() {
      const defaultHeight = 55;

      this.$refs.textarea.style.height = defaultHeight + 'px';
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped>
.se {
  background-color: #1F1F1F;
  border-radius: 6px;
  overflow: hidden;
}

.se__header {
  gap: 8px;
  border-bottom: 0.5px solid #2D2D2D;
}

.se__voice-type-button {
  gap: 6px;
  border-radius: 20px;
  border: 1px solid #2D2D2D;
  font-size: 12px;
  transition: background-color 0.25s;
}

.se__voice-type-button:hover {
  background-color: #414141;
}

.se__play-button {
  width: 25px;
  height: 25px;
  background-color: #6D75F6;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.25s;
}

.se__play-button:not(:disabled):hover {
  background-color: #545EED;
}

.se__play-button--black {
  background-color: #3D3D3D;
}

.se__play-button--black:not(:disabled):hover {
  background-color: #4D4D4D;
}

.se__options-toggle-icon {
  height: 14px;
}

.se__textarea {
  width: 100%;
  height: 55px;
  max-height: 100px;
  background: none;
  border: none;
  resize: none;
}

.se__textarea::placeholder {
  color: #585859;
}
</style>

<style>
.se__options-toggle {
  width: 25px;
  height: 25px;
  background-color: transparent !important;
  border-radius: 4px;
  border: none;
  transition: background-color 0.25s;
}

.se__options-toggle:not(:disabled):hover, .show > .se__options-toggle {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.se__options-menu {
  min-width: auto;
  background-color: #2D2D2D;
}

.se__options-button {
  gap: 8px;
  font-size: 14px;
  transition: background-color 0.25s;
}

.se__options-button:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.se__options-button-icon {
  height: 14px;
}
</style>
