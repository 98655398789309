
<template>
	<div>
		<b-modal id="alert-response" no-close-on-backdrop centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('alert-response')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img :src="iconAlert" alt="sovia">
                <div class="mt-2">
                    <h5 class="font-weight-bold">{{ titleAlert }}</h5>
                </div>
                <div class="mt-2">
                    <span v-html="textAlert1"></span>
                    <br>
                    <span>{{ textAlert2 }}</span>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-purple" @click="$bvModal.hide('alert-response')"><p class="mx-2 mb-0 text-white">Try Again</p></button>
                </div>
            </div>
        </b-modal>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	props: {
		iconAlert: {
			type: String,
			default: null,
		},
		titleAlert: {
			type: String,
			default: null,
		},
		textAlert1: {
			type: String,
			default: null,
		},
		textAlert2: {
			type: String,
			default: null,
		},
	}
}
</script>

<style>
#alert-response .modal-content {
    background-color: #1F1F1F;
    color: white;
    border-radius: 15.0499px;
}

.btn-primary{
    background-color: #6D75F6;
    border: none;
}
</style>