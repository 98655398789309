<template>
	<b-modal :id="id" size="xl" no-close-on-backdrop content-class="vtp__content" modal-class="vtp__modal p-0" body-class="p-0" dialog-class="vtp__dialog d-flex justify-content-center" centered hide-header hide-footer @shown="modalOpen" @hide="modalHide" @hidden="resetData">
		<video ref="video" :src="video?.src" preload="metadata" controls class="vtp__video w-100" @loadedmetadata="handleVideoMetadata" @timeupdate="handleVideoTimeUpdate" @error="handleVideoError"></video>
		<div class="p-3 mt-2">
			<div ref="trim" class="trim position-relative w-100">
				<div class="trim__image--dark position-absolute" :style="{ width: tooltipLeftPosition + 'px' }"></div>
				<div class="trim__image--dark dark--right position-absolute" :style="{ width: darkRight + 'px' }"></div>
				<div
					v-if="!isTrimVideo"
					class="trim__pointer position-absolute"
					:style="{ left: pointerPosition + 'px' }"
				/>
				<div class="trim__image w-100 h-100" :style="{ backgroundImage: `url('${video?.video_thumbnail}')`, }"></div>
				<div class="trim__image trim__image--slider position-absolute h-100 cursor-pointer" @click="handleSliderClick"></div>
				<div class="trim__tooltip position-absolute" :style="{ left: (tooltipLeftPosition - 16) + 'px' }">{{ durationStartCalculate ? calculateTime(durationStartCalculate, true) : '00:00' }}</div>
				<div class="trim__tooltip position-absolute" :style="{ left: (tooltipRightPosition - 16) + 'px' }">{{ durationEnd ? calculateTime(durationEnd, true) : '00:00' }}</div>
			</div>
			<div class="d-flex justify-content-between mt-2">
				<small>00:00:00</small>
				<small>{{ calculateTime(durationVideo, false) }}</small>
			</div>
		</div>
		<div class="vtp__a--wrapper">
			<div class="vtp__action d-flex align-items-center justify-content-end p-3">
				<button class="btn button__cancel" :disabled="loading" @click="$bvModal.hide(id)">{{ $t('cancel') }}</button>
				<button class="btn button__att" :disabled="loading || durationVideo == 0" @click="handleClickAddToTimeline">{{ loading ? 'Loading...' : $t('add-to-timeline') }}</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import interact from 'interactjs';

export default {
	props: {
		id: {
			type: String,
			default: 'vtp-modal'
		},
		video: {
			type: Object,
			default: () => {
				return null
			}
		},
		loading: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			image : "https://images.unsplash.com/photo-1611532736576-78f7b69f4b9d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzOTA5Njl8MHwxfHNlYXJjaHwxfHx3YXZlJTIwYXVkaW98ZW58MHx8fHwxNjg5MTI2MTgyfDA&ixlib=rb-4.0.3&q=80&w=1080",
			durationStart: 0,
			durationEnd: 0,
			durationStartCalculate: 0,
			durationVideo: 0,
			pointerPosition: 0,
			tooltipLeftPosition: 0,
			tooltipRightPosition: 0,
			darkRight: 0,
			dragNeedle: false,
			isTrimVideo: false,
		}
	},
	methods: {
		updatePointerPosition() {
			this.$nextTick(() => {
				const parentWidth = this.$refs.trim.clientWidth;
				this.pointerPosition = (parentWidth / this.durationVideo) * this.$refs.video.currentTime;
			});
		},
		updateVideoTime() {
			this.$refs.video.pause();
			const parentWidth = this.$refs.trim.clientWidth;
			const videoDuration = this.$refs.video.duration;
			const currentTime = (this.pointerPosition / parentWidth) * videoDuration;
			// const currentTime = (this.durationStart / parentWidth) * videoDuration;
			this.$refs.video.currentTime = currentTime;
			// this.durationStartCalculate = currentTime
		},
		handleVideoTimeUpdate() {
			const parentWidth = this.$refs.trim.clientWidth;
			const videoDuration = this.$refs.video.duration;
			const currentTime = (this.durationStart / parentWidth) * videoDuration;
			this.durationStartCalculate = currentTime

			if (this.$refs.video.currentTime < currentTime) {
				this.$refs.video.currentTime = currentTime;
			} else if (this.$refs.video.currentTime >= this.durationEnd) {
				this.$refs.video.pause();
				this.$refs.video.currentTime = currentTime;
			}
		},
		handleResizeEnd(event) {
			const parentWidth = this.$refs.trim.clientWidth;
			const initialSliderOffset = event.target.getAttribute('data-x'); // Mengambil posisi x awal slider
  			const sliderOffset = parseFloat(initialSliderOffset) || 0;
			const sliderWidth = event.rect.width;

			const videoDuration = this.$refs.video.duration;

			const durationStart = (sliderOffset / parentWidth) * videoDuration;
			const durationSlider = (sliderWidth / parentWidth) * videoDuration;
			const durationEnd = durationStart + durationSlider;
			this.durationEnd = durationEnd;
			this.updateVideoTime();
		},
		resizeVideoDuration() {
			const parentWidth = this.$refs.trim.clientWidth;
			var vm = this
			interact('.trim__image--slider')
			.resizable({
				edges: { right: true, left: true },
				listeners: {
					move (event) {
						var target = event.target
						var x = (parseFloat(target.getAttribute('data-x')) || 0)

						// update the element's style
						target.style.width = event.rect.width + 'px'

						// translate when resizing from left edges
						x += event.deltaRect.left
						vm.pointerPosition = x
						vm.durationStart = x
						vm.tooltipLeftPosition = x
						vm.updateVideoTime()
						vm.tooltipRightPosition = x + event.rect.width
						vm.darkRight = parentWidth - vm.tooltipRightPosition

						target.style.transform = 'translate(' + x + 'px'

						target.setAttribute('data-x', x)
					},
					end: vm.handleResizeEnd
				},
				modifiers: [
					// keep the edges inside the parent
					interact.modifiers.restrictEdges({
						outer: 'parent'
					}),

					// minimum size
					interact.modifiers.restrictSize({
						min: { width: 100 }
					})
				],
				inertia: true
			})
			.on('resizestart', () => this.isTrimVideo = true)
			.on('resizeend', () => this.isTrimVideo = false)
		},
		handleVideoMetadata() {
			var videoElement = document.querySelector('.vtp__video');
			const parentWidth = this.$refs.trim.clientWidth;
			this.tooltipRightPosition = parentWidth
			this.durationVideo = videoElement.duration; // Mendapatkan durasi video dari tag video
			this.durationEnd = this.durationVideo
			this.resizeVideoDuration();
		},
		modalOpen() {
			const videoElement = document.querySelector('.vtp__video');
			videoElement.addEventListener('timeupdate', this.updatePointerPosition);
			this.resizeVideoDuration();
		},
		modalHide() {
			const videoElement = document.querySelector('.vtp__video');
			videoElement.removeEventListener('timeupdate', this.updatePointerPosition);
		},
		calculateTime(secs, withoutMills) {
			const minutes = Math.floor(secs / 60);
			const seconds = Math.floor(secs % 60);
			const centiseconds = Math.floor((secs - Math.floor(secs)) * 100);

			const minutesString = (minutes < 10 ? '0' : '') + minutes;
			const secondsString = (seconds < 10 ? '0' : '') + seconds;
			const centisecondsString = (centiseconds < 10 ? '0' : '') + centiseconds;
			if(withoutMills) {
				return minutesString + ':' + secondsString;
			}

			return minutesString + ':' + secondsString + ':' + centisecondsString;
		},
		resetData() {
			this.durationStart = 0
			this.durationEnd = 0
			this.durationStartCalculate = 0
			this.durationVideo = 0
			this.pointerPosition = 0
			this.tooltipLeftPosition = 0
			this.tooltipRightPosition = 0
			this.darkRight = 0
			this.$emit('reset:video-trim')
		},
		handleSliderClick(event) {
			if(!this.isTrimVideo) {
				const parentDiv = this.$refs.trim;
				const parentRect = parentDiv.getBoundingClientRect();
				const clickX = event.clientX - parentRect.left;
				const pointerPosition = clickX

				this.pointerPosition = pointerPosition;
				this.updateVideoTime();
			}
		},
		handleVideoError() {
			console.log("ERROR")
		},
		handleClickAddToTimeline() {
			let isTrim = true
			if(this.durationStartCalculate == 0 && this.durationEnd == this.$refs.video.duration) {
				isTrim = false
			}
			this.$emit('click:trim-video', { durationStartCalculate : this.durationStartCalculate, durationEnd : this.durationEnd, isTrim : isTrim })
		}
	},
}
</script>

<style scoped>
.trim {
	height: 65px;
	/* background-size: contain;
  	background-repeat: repeat; */
}

.trim__image {
	background-size: contain;
  	background-repeat: repeat-x;
}

.trim__image--slider {
	outline: 3px solid white;
	filter: none;
	top: 0;
	width: 100%;
	background-color: transparent;
}

.trim__image--dark {
	/* filter: brightness(0.5); */
	background-color: rgba(0, 0, 0, 0.86);
	height: 100%;
}

.dark--right {
	right: 0;
}

.trim__pointer {
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #DF2723;
  /* cursor: ew-resize; */
  transition: left 0.1s;
}

.trim__pointer::before {
  content: "";
  position: absolute;
  top: -2px;
  translate: -40% 0;
  border-inline: 6px solid transparent;
  border-top: 8px solid #DF2723;
}

.trim__tooltip {
	background-color: #6D75F6;
	padding: 0px 3px;
	border-radius: 5px;
	font-size: 8px;
	top: -20px;
}

.vtp__video {
	border-radius: 10px 10px 0 0;
	aspect-ratio: 16 / 9;
}

.vtp__a--wrapper {
	border-top: 0.1px solid #2D2D2D;;
}

.vtp__action {
	gap: 8px;
}

.button__cancel, .button__att {
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	color: #fff;
	padding: 5px 15px;
	border-radius: 8px;

	transition: background-color 0.25s;
}

.button__cancel {
	background-color: #2D2D2D;
}

.button__cancel:hover {
	background-color: #2C2C2C;
}

.button__att {
	background-color: #6D75F6;
}

.button__att:not(:disabled):hover {
	background-color: #545EED;
}
</style>

<style>
.vtp__dialog {
	max-width: none;
}

.vtp__content {
	width: 800px;
	border-radius: 10px;
	background-color: #1F1F1F;
    color: white;
}
.vtp__modal + .modal-backdrop {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
  	backdrop-filter: blur(2px);
	color: white;
}
</style>