import axios from "../../config/axios"
import { base_url_unsplash, base_url_machine_learning } from "../../config/base_url";
import { access_key } from "../../config/unsplash";
import apiRoute from "../api.route";

export async function getSearchImage(search, per_page) {
    return axios({
        method: 'GET',
        url: base_url_unsplash + '/search/photos?query=' + search + "&per_page=" + per_page,
        headers: {
            Authorization: "Client-ID " + access_key
        }
    }).then((res) => {
        var data = {}
        if (res.data['errors'] === undefined) {
            data = {
                status: true,
                data: res.data
            }
        } else {
            data = {
                status: false,
                response: res.data.errors
            }
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function getMasterMusics(params) {
    return axios({
        method: 'GET',
        url: base_url_machine_learning + 'master_musics',
        params : params
    })
        .then((res) => {
            const data = {
                status: res.data.status,
                data: res.data.data,
                totalPage : res.data.total_page,
                message: res.data.message
            }
            return data;
        })
        .catch((error) => {
            const data = {
                status: false,
                response: error
            }
            return data;
        })
}

export async function getMasterMusicsAll() {
    return axios({
        method: 'GET',
        url: base_url_machine_learning + 'master_musics/all',
    })
        .then((res) => {
            const data = {
                status: res.data.status,
                data: res.data.data,
                totalPage : res.data.total_page,
                message: res.data.message
            }
            return data;
        })
        .catch((error) => {
            const data = {
                status: false,
                response: error
            }
            return data;
        })
}

export async function textToSpeech(formData) {
    return axios.post(base_url_machine_learning + '/prosa', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            console.log(error)
            return data;
        })
}

export async function videoGenerator(formData) {
    return axios.post(base_url_machine_learning + 'vgen', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            console.log(error)
            return data;
        })
}

export async function getStatusJob(job_id) {
    return axios({
        method: 'GET',
        url: base_url_machine_learning + 'vgen?job_id=' + job_id,
    }).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function getMasterMusicsByClass(formData) {
    return axios.post(base_url_machine_learning + apiRoute.masterMusic, formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            console.log(error)
            return data;
        })
}


// OLD
// export async function getMasterMusicsByClass(params) {
//     return axios({
//         method: 'GET',
//         url: base_url_machine_learning + apiRoute.masterMusic,
//         params: params
//     })
//         .then((res) => {
//             const data = {
//                 status: res.data.status,
//                 data: res.data.data,
//                 message: res.data.message
//             }
//             return data;
//         })
//         .catch((error) => {
//             const data = {
//                 status: false,
//                 response: error
//             }
//             return data;
//         })
// }