<template>
  <div class="aii d-flex flex-column">
    <div
      class="aii__wrapper d-flex flex-column justify-content-center align-items-center p-2 text-center"
      :class="{ 'aii__wrapper--correct': correct }"
      :title="`Choice #${answerNumber}`"
    >
      <div v-if="imageUrl" class="aii__image-wrapper position-relative">
        <img
          :src="imageUrl"
          alt=""
          class="aii__image"
        />

        <button
          type="button"
          class="aii__remove-button btn d-flex justify-content-center align-items-center"
          title="Remove Image"
          @click="imageUrl = ''"
        >
          <img src="@/assets/images/icons/ic_trash_fill.svg" alt="" height="12" />
        </button>
      </div>

      <div v-else class="aii__upload-wrapper">
        <img src="@/assets/images/icons/ic_upload_image.svg" alt="" class="aii__upload-image" />
        <button
          type="button"
          class="aii__upload-button btn"
          :class="{ 'aii__upload-button--correct': correct }"
          title="Upload Image"
          @click="$bvModal.show(`add-image-modal-answer-${_uid}-${answerNumber}`)"
        >
          Upload image
        </button>
      </div>
    </div>

    <AddImageModal
      :name="`add-image-modal-answer-${_uid}-${answerNumber}`"
      v-model="imageUrl"
    />
    <Gallery :name="`add-image-modal-answer-${_uid}-${answerNumber}`" v-model="imageUrl" />
  </div>
</template>

<script>
import AddImageModal from '../../modals/AddImageModal';
import Gallery from '../../modals/Gallery';


export default {
  components: {
    AddImageModal,
    Gallery
  },
  props: {
    answerNumber: {
      type: Number,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    correct: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      imageUrl: this.value,
    };
  },
  watch: {
    imageUrl(newImageUrl) {
      this.$emit('input', newImageUrl);
    },
    value(newValue) {
      this.image = newValue;
    },
  },
};
</script>

<style scoped>
.aii {
  height: 100%;
}

.aii__wrapper {
  gap: 8px;
  height: 100%;
  background-color: #2D2D2D;
  border-radius: 10px;
  transition: background-color 0.25s;
}

.aii__wrapper--correct {
  background-color: #40BE45;
}

.aii__image-wrapper {
  height: 100%;
}

.aii__image {
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.aii__remove-button {
  position: absolute;
  top: 0;
  left: calc(100% + 8px);
  width: 28px;
  height: 28px;
  background-color: #585859;
  border-radius: 50%;
  transition: background-color 0.25s;
}

.aii__remove-button:hover {
  background-color: #F34D4D;
}

.aii__upload-wrapper {
  display: contents;
}

.aii__upload-image {
  height: 100%;
}

.aii__upload-button {
  padding: 4px 8px;
  background-color: #585859;
  border-radius: 4px;
  font-size: 10px;
  color: #FFFFFF;
  transition: background-color 0.25s, color 0.25s;
}

.aii__upload-button:hover {
  background-color: #777777;
}

.aii__upload-button--correct {
  background-color: #FFFFFF;
  color: #1F1F1F;
}

.aii__upload-button--correct:hover {
  background-color: #EEEEEE;
}
</style>
