<template>
  <div class="som d-flex align-items-center py-2 px-3 text-white">
    <p class="som__title d-flex align-items-center py-1 pr-3 m-0">
      <img
        src="@/assets/images/icons/ic_magic_wand_small.svg"
        alt=""
        class="som__title-icon"
      />
      AI Speech Edit
    </p>
    <ul class="som__list d-flex m-0 list-unstyled">
      <li>
        <MenuItem
          v-model="options.speed"
          :items="listSpeed"
          :name="$t('speed')"
          :iconSrc="require('@/assets/images/icons/ic_speed_small.svg')"
          :disabled="['loading', 'pause'].includes(previewButtonState)"
          required
          :show="showSpeed"
          @update:show="(val) => showSpeed = val"
        />
      </li>
      <li>
        <MenuItem
          v-model="options.pause"
          :items="listPause"
          :name="$t('pause')"
          :iconSrc="require('@/assets/images/icons/ic_pause_small.svg')"
          :disabled="['loading', 'pause'].includes(previewButtonState)"
          :show="showPause"
          @update:show="(val) => showPause = val"
        />
      </li>
      <li>
        <MenuItem
          v-model="options.tone"
          :items="listTone"
          :name="$t('tone')"
          :iconSrc="require('@/assets/images/icons/ic_tone_small.svg')"
          :disabled="['loading', 'pause'].includes(previewButtonState)"
          :show="showTone"
          @update:show="(val) => showTone = val"
        />
      </li>
    </ul>
    <button
      type="button"
      class="som__preview-button btn d-flex justify-content-center align-items-center ml-1 text-white"
      :class="{
        'som__preview-button--black': previewButtonState !== 'generate'
      }"
      :title="
        previewButtonState === 'generate' ? 'Generate Preview'
        : previewButtonState === 'loading' ? 'Loading'
        : previewButtonState === 'play' ? 'Play Preview'
        : 'Pause Preview'
      "
      :disabled="previewButtonState === 'loading'"
      @click="handleClickPreviewButton"
    >
      <div v-show="previewButtonState === 'loading'">
        <b-spinner
          variant="light"
          label="Loading..."
          small
        />
      </div>
      <font-awesome-icon
        v-show="previewButtonState !== 'loading'"
        :icon="`fa-solid ${previewButtonState === 'pause' ? 'fa-pause' : 'fa-play'}`"
      />
    </button>
    <audio
      ref="audioPlayer"
      :src="previewAudioSrc"
      class="d-none"
    />
  </div>
</template>

<script>
import MenuItem from './MenuItem';

export default {
  components: {
    MenuItem,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    plainText: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'generate-preview-ssml'],
  data() {
    return {
      options: {
        speed: this.value.speed || 'fe-normal',
        pause: this.value.pause || null,
        tone: this.value.tone || null,
      },
      previewButtonState: 'generate', // ['generate', 'loading', 'play', 'pause']
      previewAudioSrc: null,
      listSpeed: [
        {
          id: 'super_slow',
          text: this.$t('speed--1'),
        },
        {
          id: 'slow',
          text: this.$t('speed--2'),
        },
        {
          id: 'fe-normal',
          text: this.$t('speed--3'),
        },
        {
          id: 'fast',
          text: this.$t('speed--4'),
        },
        {
          id: 'super_fast',
          text: this.$t('speed--5'),
        },
      ],
      listPause: [
        {
          id: 'br1',
          text: this.$t('pause--1'),
        },
        {
          id: 'br2',
          text: this.$t('pause--2'),
        },
        {
          id: 'br3',
          text: this.$t('pause--3'),
        },
      ],
      listTone: [
        {
          id: 'calm',
          text: this.$t('tone--1'),
        },
        {
          id: 'disappointed',
          text: this.$t('tone--2'),
        },
        {
          id: 'excited',
          text: this.$t('tone--3'),
        },
        {
          id: 'fear',
          text: this.$t('tone--4'),
        },
        {
          id: 'happy',
          text: this.$t('tone--5'),
        },
        {
          id: 'sad',
          text: this.$t('tone--6'),
        },
        {
          id: 'surprised',
          text: this.$t('tone--7'),
        },
      ],

      showSpeed: false,
      showPause: false,
      showTone: false,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (
          newValue.speed !== this.options.speed
          || newValue.pause !== this.options.pause
          || newValue.tone !== this.options.tone
        ) {
          this.options = newValue;
        }
      },
      deep: true,
    },
    options: {
      handler(newOptions) {
        this.$emit('input', newOptions);
        this.refreshPreviewButtonState();
      },
      deep: true,
    },
    plainText() {
      this.refreshPreviewButtonState();
    },
    'options.speed'(newSpeed) {
      if (newSpeed !== 'fe-normal') {
        this.options.tone = null;
      }
    },
    'options.tone'(newTone) {
      if (newTone) {
        this.options.speed = 'fe-normal';
      }
    },
    showSpeed(newVal) {
      if(newVal) {
        this.showPause = false
        this.showTone = false
      }
    },
    showPause(newVal) {
      if(newVal) {
        this.showSpeed = false
        this.showTone = false
      }
    },
    showTone(newVal) {
      if(newVal) {
        this.showSpeed = false
        this.showPause = false
      }
    }
  },
  methods: {
    async handleClickPreviewButton() {
      if (this.previewButtonState === 'generate') {
        const currentOptions = this.options;

        this.previewButtonState = 'loading';

        const type = this.options.tone || this.options.speed;

        const prefix = `<${type}>`;
        const suffix = `${type === 'fe-normal' ? '<fe-end>' : '<end>'}${this.options.pause ? `<${this.options.pause}>`: ''}`;
        const SSMLText = prefix + this.plainText + suffix;

        const setAudioSrc = async (audioSrc, speaker) => {
          if (audioSrc && speaker) {
            this.previewAudioSrc = audioSrc;

            const SSMLPreviewHistory = await JSON.parse(sessionStorage.getItem('SSMLPreviewHistory')) || [];
            SSMLPreviewHistory.push({ ...currentOptions, speaker, plainText: this.plainText, audioSrc });

            sessionStorage.setItem('SSMLPreviewHistory', JSON.stringify(SSMLPreviewHistory));
          }

          this.refreshPreviewButtonState();
        }

        this.$emit('generate-preview-ssml', { plainText: this.plainText, SSMLText, setAudioSrc, options: currentOptions });
      } else if (this.previewButtonState === 'play') {
        try {
          this.previewButtonState = 'loading';
          await this.$refs.audioPlayer.play();
          document.dispatchEvent(new Event('play-ssml-audio-tta'));

          const playMediaEvent = new CustomEvent('play:media', {
            detail: {
              componentId: this._uid,
            },
          });
          document.dispatchEvent(playMediaEvent);

          this.previewButtonState = 'pause';
        } catch (error) {
          console.log(error);
        }
      } else if (this.previewButtonState === 'pause') {
        try {
          this.previewButtonState = 'loading';
          await this.$refs.audioPlayer.pause();
          this.previewButtonState = 'play';
        } catch (error) {
          console.log(error);
        }
      }
    },
    async refreshPreviewButtonState() {
      let previewButtonState = 'generate';

      const SSMLPreviewHistory = await JSON.parse(sessionStorage.getItem('SSMLPreviewHistory')) || [];

      for (const history of SSMLPreviewHistory) {
        if (
          history.speed === this.options.speed
          && history.pause === this.options.pause
          && history.tone === this.options.tone
          && history.speaker === sessionStorage.getItem('SSMLActiveSpeaker')
          && history.plainText === this.plainText
          ) {
          previewButtonState = 'play';
          this.previewAudioSrc = history.audioSrc;
        }
      }

      this.previewButtonState = previewButtonState;
    },
    async playCardAudioTTAListener() {
      await this.$refs.audioPlayer.pause();
      this.refreshPreviewButtonState();
    },
    async handlePlayMedia(e) {
      if (e.detail.componentId !== this._uid && this.previewButtonState === 'pause') {
        await this.$refs.audioPlayer.pause();
        this.refreshPreviewButtonState();
      }
    },
    endedAudioPlayerListener() {
      this.previewButtonState = 'play';
    },
  },
  mounted() {
    document.addEventListener('play-card-audio-tta', this.playCardAudioTTAListener);
    document.addEventListener('play:media', this.handlePlayMedia);
    document.addEventListener('change-ssml-active-speaker', this.refreshPreviewButtonState);
    this.$refs.audioPlayer.addEventListener('ended', this.endedAudioPlayerListener);
  },
  beforeDestroy() {
    document.removeEventListener('play-card-audio-tta', this.playCardAudioTTAListener);
    document.removeEventListener('play:media', this.handlePlayMedia);
    document.removeEventListener('change-ssml-active-speaker', this.refreshPreviewButtonState);
    this.$refs.audioPlayer.removeEventListener('ended', this.endedAudioPlayerListener);
  },
};
</script>

<style scoped>
.som {
  gap: 8px;
  background-color: #2D2D2D;
  border-radius: 8px;
}

.som__title {
  gap: 8px;
  border-right: 1px solid #585859;
  font-size: 14px;
  font-weight: 600;
  color: #6D75F6;
  line-height: 1;
  white-space: nowrap;
}

.som__title-icon  {
  width: 13px;
  height: 14px;
}

.som__list {
  gap: 6px;
}

.som__preview-button {
  width: 25px;
  height: 25px;
  background-color: #6D75F6;
  border-radius: 3.5px;
  font-size: 14px;
  transition: background-color 0.25s;
}

.som__preview-button:not(:disabled):hover {
  background-color: #545EED;
}

.som__preview-button--black {
  background-color: #3D3D3D;
}

.som__preview-button--black:not(:disabled):hover {
  background-color: #4D4D4D;
}
</style>
