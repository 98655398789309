<template>
  <div class="math-equation-modal">
    <header class="math-equation-modal__header">
      <h2 class="math-equation-modal__header-text">
        <font-awesome-icon icon="fa-solid fa-square-root-variable" />
        <span>Math Equation</span>
      </h2>
      <button
        type="button"
        class="math-equation-modal__close-button"
        title="Close"
        @click="$emit('close')"
      >
        <b-icon icon="x" />
      </button>
    </header>
    <div class="math-equation-modal__content">
      <div class="math-equation-modal__math-field-wrapper">
        <div
          class="math-equation-modal__math-field-placeholder"
          v-show="!mathValue"
        >
          Insert math equation here ...
        </div>
        <div
          class="math-equation-modal__math-field"
          ref="mathField"
        >
          {{ mathValue }}
        </div>
      </div>
      <MathEquationModalButtons @click="(tex) => { mathField.write(tex); mathField.focus() }" />
    </div>
    <footer class="math-equation-modal__footer">
      <button
        type="button"
        class="math-equation-modal__insert-button"
        title="Insert"
        @click="handleInsert"
        :disabled="!mathValue"
      >
        Insert
      </button>
    </footer>
  </div>
</template>

<script>
import MathQuill from 'mathquill-node';

import MathEquationModalButtons from './MathEquationModalButtons.vue';

export default {
  components: {
    MathEquationModalButtons,
  },
  props: {
    onInsert: {
      type: Function,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      mathField: null,
      mathValue: this.value,
    };
  },
  methods: {
    handleInsert() {
      if (this.mathValue) {
        this.onInsert(`\\( ${this.mathValue} \\)`);
      } else {
        this.onInsert(false);
      }

      this.$emit('close');
    },
  },
  mounted() {
    const MQ = MathQuill.getInterface(2);
    this.mathField = MQ.MathField(this.$refs.mathField, {
      spaceBehavesLikeTab: true,
      handlers: {
        edit: () => {
          this.mathValue = this.mathField.latex();
        },
        enter: () => {
          this.handleInsert();
        },
      },
    });
  },
};

export const properties = {
  width: '500px',
  height: 'auto',
  scrollable: true,
  styles: {
    backgroundColor: '#1f1f1f',
    color: '#fff',
    borderRadius: '0.5rem',
  },
};
</script>

<style scoped>
.math-equation-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.math-equation-modal__header {
  padding: 2.5rem;
}

.math-equation-modal__header-text {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.math-equation-modal__close-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  background-color: rgba(255, 255, 255, 0.75);
  color: '#1c1c1c';
  font-size: 1.25rem;
  border: none;
  border-radius: 50%;
  transition: background-color 0.25s;
}

.math-equation-modal__close-button:hover {
  background-color: #fff;
}

.math-equation-modal__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 2.5rem;
}

.math-equation-modal__math-field-wrapper {
  position: relative;
}

.math-equation-modal__math-field-placeholder {
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
  color: #585859;
  pointer-events: none;
}

.math-equation-modal__math-field {
  width: 100%;
  padding: 0.5rem 1.5rem;
  border: 1px solid #585859;
  border-radius: 0.5rem;
}

.math-equation-modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem 2.5rem;
  border-top: 1px solid #2d2d2d;
}

.math-equation-modal__insert-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: #6d75f6;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border: none;
  border-radius: 0.5rem;
  outline: 2px solid transparent;
  outline-offset: 1px;
  transition: outline 0.25s;
}

.math-equation-modal__insert-button:focus {
  outline: 2px solid rgba(109, 117, 246, 0.5);
}

.math-equation-modal__insert-button:not(:disabled):hover {
  background-color: #545EED;
  border-color: #545EED;
}

.math-equation-modal__insert-button:disabled {
  background-color: #2D2D2D;
  border-color: #2D2D2D;
  color: #585859;
}
</style>

<style>
.mq-editable-field .mq-cursor {
  border-left-color: #fff;
}

.mq-math-mode .mq-empty {
  background-color: rgba(255, 255, 255, 0.25);
}

.mq-editable-field .mq-root-block {
  padding: 0.5rem 2px;
}
</style>
