var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',[(_vm.popover)?_c('div',{ref:"itemPopover",staticClass:"item-popover"},[_c('button',{staticClass:"item-popover__button",class:{
        'item-popover__button--normal': _vm.type === 'normal',
        'item-popover__button--minimal': _vm.type === 'minimal',
        'item-popover__button--active': _vm.showPopover,
      },attrs:{"type":"button","title":_vm.name},on:{"click":function($event){_vm.showPopover = !_vm.showPopover}}},[_vm._t("default")],2),_c('div',{staticClass:"item-popover__popover",class:{
        'item-popover__popover--show': _vm.showPopover,
      }},[_c(_vm.popover.component,_vm._b({tag:"component",on:{"hidePopover":function($event){_vm.showPopover = false}}},'component',{ ..._vm.popover.props },false))],1)]):_c('button',{staticClass:"item-regular",class:{
      'item-regular--normal': _vm.type === 'normal',
      'item-regular--minimal': _vm.type === 'minimal',
      'item-regular--active': _vm.isActive,
    },attrs:{"type":"button","title":_vm.name},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }