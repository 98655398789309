<template>
  <div
    class="tei d-flex flex-column"
    :class="{
      'tei--question': type === 'question',
      'tei--answer': type === 'answer' && typeLayout === 'default',
      'tei--correct': correct,
      'tei--focus' : editor && editor.isFocused === true && !withSubTitle,
    }"
  >
    <div v-if="withToolbar" class="p-3 d-flex align-items-center justify-content-between" >
      <TextEditorToolbarInput :editor="editor" :type="type"/>
      <button
        type="button"
        class="qo__delete-button btn d-flex justify-content-center align-items-center"
        title="Delete Text"
        @click="$emit('delete-text')"
      >
        <img src="@/assets/images/icons/ic_delete_2.svg" alt="Delete" height="12">
      </button>
    </div>
    <div class="tei__editor-container d-flex flex-grow-1">
      <EditorContent
        :editor="editor"
        spellcheck="false"
        style="height: 100%; width:100%"
      />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Image from '@tiptap/extension-image';

import MathEquationExtension from '@/components/creator-text-editor/extensions/MathEquationExtension';

import TextEditorToolbarInput from './TextEditorToolbarInput';

export default {
  components: {
    EditorContent,
    TextEditorToolbarInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      validator(type) {
        return ['question', 'answer'].includes(type);
      },
      default: 'question',
    },
    typeLayout: {
      validator(type) {
        return ['default', 'thumbnail'].includes(type);
      },
      default: 'default',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    correct: {
      type: Boolean,
      default: false,
    },
    withToolbar: {
      type: Boolean,
      default: false,
    },
    withSubTitle: {
      type: Boolean,
      default: false,
    },
    withDelete: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'get-editor', 'focus'],
  data() {
    return {
      editor: null,
      editorSubTitle: null
    };
  },
  watch: {
    value(newValue) {
      if (this.editor.getHTML() !== newValue) {
        this.editor.commands.setContent(newValue, false);

      }
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      editable: this.readonly === false,
      extensions: [
        StarterKit.configure({
          orderedList: false,
          bulletList: false,
          listItem: false,
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Underline,
        TextAlign.configure({
          types: ['paragraph'],
          defaultAlignment: 'center',
        }),
        Color,
        TextStyle,
        MathEquationExtension,
        Image.configure({
          allowBase64: true,
        }),
      ],
      editorProps: {
        attributes: {
          class: 'tei__editor',
        },
      },
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      },
      onFocus: () => {
        this.$emit('focus',{ editor :  this.editor, type : this.type ? this.type : 'answer' });
      },
    });
    this.$emit('get-editor', this.editor);
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style scoped>
.tei {
  height: 100%;
  background-color: #2D2D2D;
  border-radius: 10px;
  transition: background-color 0.25s;
}

.tei--focus{
  border: 1px solid #6D75F6;
}

.tei--correct {
  background-color: #40BE45;
}

.tei__editor-container {
  height: 100%;
  overflow: hidden;
}

.tei__editor-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
}

.qo__delete-button {
  height: 100%;
  padding: 4px 10px;
  background-color: #1F1F1F;
  border-radius: 4px;
  transition: background-color 0.25s;
}

.qo__delete-button:hover {
  background-color: #F34D4D;
}
</style>

<style>
div:has(> .tei__editor) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}


.tei__editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  text-align: center;
  font-size: 18px;
  color: #FFFFFF;
  height: 100%;
  overflow-y: auto;
  isolation: isolate;
}

.tei__withSubTitle-title p{
  font-size: 40px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.tei__withSubTitle-sub p{
  font-size: 24px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.tei__editor::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}

.tei__editor > * {
  word-break: break-word;
  margin: 0;
}

.tei__editor > *:first-child {
  margin-top: auto;
}

.tei__editor > *:last-child {
  margin-bottom: auto;
}

.tei__editor > * + * {
  margin-top: 0.25rem;
}

.tei__editor p {
  text-align: center;
}

.tei__editor img {
  width: 100%;
  max-height: 65px;
  object-fit: contain;
  object-position: center;
}

.tei__editor p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  z-index: -1;
  position: absolute;
  transform: translateX(-50%);
  height: 0;
  color: #8C8C8C;
  pointer-events: none;
  transition: color 0.25s;
}

.tei--correct .tei__editor p.is-editor-empty:first-child::before {
  color: #CECECE;
}

.tei__editor u {
  text-decoration: underline;
}

.tei--fixed {
  position: fixed;
  z-index: 99;
}

.tei--answer {
  height: 120px !important
}

.tei--with-subTitle{
  height: 577px !important;
}
</style>
