<template>
  <div class="mi position-relative">
    <tippy v-bind="tippyProps">
      <template v-slot:trigger>
        <button
          type="button"
          class="mi__toggle btn d-flex align-items-center p-1 text-white"
          :class="{ 'mi__toggle--active': isListShown }"
          :title="name"
          @click="isListShown = !isListShown"
        >
          <img :src="iconSrc" alt="" class="mi__toggle-icon" />
          {{ name }}
          <font-awesome-icon
            icon="fa-solid fa-chevron-down"
            class="mi__toggle-chevron"
            :class="{ 'mi__toggle-chevron--active': isListShown }"
          />
        </button>
      </template>

      <ul class="mi__list d-flex flex-column p-2 m-0 list-unstyled">
        <li v-for="item in items" :key="item.id">
          <button
            type="button"
            class="mi__item-button btn d-flex justify-content-between align-items-center py-1 px-2 text-white"
            :class="{ 'mi__item-button--selected': item.id === selectedValue }"
            :title="item.text"
            :disabled="disabled"
            @click="handleClickItem(item.id)"
          >
            {{ item.text }}
            <font-awesome-icon
              v-if="item.id === selectedValue"
              icon="fa-solid fa-check"
              class="mi__item-check ml-2"
            />
          </button>
        </li>
        <li v-if="custom">
          <div class="px-2 text-left">
            <label :for="`input${name}`" class="mi__custom-text">Custom (in second)</label>
            <div class="cp__slider w-100 mt-3" @click.stop>
              <vue-slider v-model="customPause" :min="2" :max="8" :interval="2" :tooltip="'always'" dot-style="tlh__equalizer-slider-dot"></vue-slider>
              <div class="d-flex justify-content-between">
                <p class="cp__label-val m-0">2</p>
                <p class="cp__label-val m-0">4</p>
                <p class="cp__label-val m-0">6</p>
                <p class="cp__label-val m-0">8</p>
              </div>
              <button type="button" class="mi__custom-button btn w-100 mt-2" title="Confirm the pause value" :disabled="!customPause" @click="handleCustomPause">
                Add
              </button>
            </div>
          </div>
        </li>
      </ul>
    </tippy>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default {
  components: {
    VueSlider
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    iconSrc: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['input'],
  data() {
    return {
      isListShown: false,
      selectedValue: this.value,
      customPause: 2,
    };
  },
  computed: {
    tippyProps() {
      return {
        interactive: true,
        placement: 'bottom-start',
        flip: ['bottom-start', 'bottom', 'top-start', 'top'],
        maxWidth: 'none',
        animation: 'shift-away',
        duration: 100,
        delay: 0,
        hideOnClick: false,
        animateFill: false,
        theme: 'mi__menu',
        trigger: 'manual',
        visible: this.isListShown,
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue !== this.selectedValue && !this.items.some(item => item.id === newValue)) {
        this.customPause = this.shortTextCustom(this.value) || 2;
        this.selectedValue = newValue;
      } else {
        this.selectedValue = newValue;
      }
    },
    selectedValue(newSelectedValue) {
      this.$emit('input', newSelectedValue);
    },
  },
  methods: {
    handleClickItem(itemId) {
      if (!this.required && this.selectedValue === itemId) {
        this.selectedValue = null;
      } else {
        this.selectedValue = itemId;
      }

      this.isListShown = false;
      this.customPause = 2
    },
    handleCustomPause() {
      let customPauseTag = "brxx" + this.customPause + "yy"

      if (!this.required && this.selectedValue === customPauseTag) {
        this.selectedValue = null;
      } else {
        this.selectedValue = customPauseTag;
      }

      this.isListShown = false;
    },
    hideListWhenClickOutside(e) {
      const listContainer = document.querySelector('.mi__menu-theme');

      if (
        !this.$el.contains(e.target)
        && !listContainer?.contains(e.target)
        && this.isListShown
      ) {
        this.isListShown = false;
      }
    },
    shortTextCustom(item) {
      if (item) {
        const numberMatch = item.match(/\d+/);
        return numberMatch ? parseInt(numberMatch[0]) : null;
      }
      return null;
    }
  },
  mounted() {
    document.addEventListener('click', this.hideListWhenClickOutside);
    if (this.value && !this.items.some(item => item.id === this.value)) {
      this.customPause = this.shortTextCustom(this.value) || 2;
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideListWhenClickOutside);
  },
};
</script>

<style scoped>
.mi__toggle {
  gap: 4px;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.mi__toggle:hover, .mi__toggle--active {
  background-color: #585859;
}

.mi__toggle-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
}

.mi__toggle-chevron {
  font-size: 10px;
  color: #8C8C8C;
  transition: rotate 0.25s;
}

.mi__toggle-chevron--active {
  rotate: -180deg;
}

.mi__list {
  gap: 2px;
  background-color: #2D2D2D;
  border-radius: 4px;
}

.mi__item-button {
  gap: 4px;
  width: 100%;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  transition: background-color 0.25s;
}

.mi__item-button:not(.mi__item-button--selected, :disabled):hover {
  background-color: #585859;
}

.mi__item-button--selected {
  background-color: #6D75F6;
}

.mi__item-check {
  font-size: 8px;
}

.mi__custom-action {
  gap: 4px;
}

.mi__custom-text {
  font-size: 12px;
  color: white;
}

.mi__custom-input {
  border-radius: 4px;
  background: transparent;
  border:1px solid #585859;
  box-shadow: none;
  padding: 6px 8px;
  color: white;
  width: 40px;
  text-align: center;

  transition: border-color 0.25s;
}

.mi__custom-inputed {
  border-color: #6D75F6;
}

.mi__custom-input::-webkit-inner-spin-button,
.mi__custom-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mi__custom-input {
  -moz-appearance: textfield;
}

.mi__custom-button {
  color: white;
  background-color: #6D75F6;
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 2px;

  transition: background-color 0.25s;
}

.mi__custom-button:disabled {
  background-color: #585859;
  color: #8C8C8C;
}

.mi__custom-button:not(:disabled):hover {
  background-color: #545EED;
}

.cp__label-val {
  font-size: 10px;
}
</style>

<style>
.mi__menu-theme {
  padding-left: 0;
  padding-right: 0;
  background: none;
}

.cp__slider .vue-slider-dot-handle {
  border: none;
}

.cp__slider .vue-slider-dot-tooltip-inner{
  font-size: 10px;
  background-color: #6D75F6;
  color: white;
  padding: 2px 6px;
}

.cp__slider .vue-slider-dot-tooltip-top {
  top: -5px;
}

.cp__slider .vue-slider-dot-tooltip-inner-top::after{
  border: none;
}

.cp__slider .vue-slider {
  height: 6px;
}

.cp__slider .vue-slider-process, .cp__slider .vue-slider:hover .vue-slider-rail, .cp__slider .vue-slider:hover .vue-slider-process, .cp__slider .vue-slider .vue-slider-rail  {
  background: #8C8C8C;
}
</style>
