export default function generateRandomUUID() {
  const characters = '0123456789abcdef';
  let uuid = '';

  for (let i = 0; i < 32; i++) {
    const randomNumber = Math.floor(Math.random() * characters.length);
    const randomCharacter = characters.charAt(randomNumber);

    uuid += randomCharacter;

    if ([7, 11, 15, 19].includes(i)) {
      uuid += '-';
    }
  }

  return uuid;
}
