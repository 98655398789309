<template>
  <header class="ttv-header align-items-center px-5 py-3">
    <section class="ttv-header__left d-flex align-items-center">
      <button
        type="button"
        class="ttv-header__back-button btn p-0 rounded-0"
        title="Back"
        @click="$emit('click:back')"
      >
        <img
          src="@/assets/images/icons/ic_back_nav.svg"
          alt="Back"
          height="18"
        />
        <span class="sr-only">Back</span>
      </button>
      <h1 class="ttv-header__title">Text to Video</h1>
    </section>

    <!-- <section class="ttv-header__center d-flex justify-content-center">
      <div class="ttv-header__file-name position-relative">
        <input
          type="text"
          id="input-file-name"
          class="ttv-header__file-name-input text-white text-center"
          :value="fileName"
          spellcheck="false"
          title="File Name"
          @input="$emit('input:file-name', $event.target.value)"
        />
        <div
          class="ttv-header__file-name-label position-absolute d-flex align-items-center"
          :class="{ 'sr-only': fileName }"
        >
          <label for="input-file-name" class="ttv-header__file-name-label-text m-0">File Name</label>
          <img
            src="@/assets/images/icons/ic_file_name.svg"
            class="ttv-header__file-name-label-icon"
            alt=""
          />
        </div>
      </div>
    </section> -->

    <!-- <section class="ttv-header__right d-flex justify-content-end align-items-center">
      <div class="ttv-header__subscription d-flex align-items-center">
        <img
          :src="require('@/assets/images/icons/ic_subscription_info.svg')"
          class="ttv-header__subscription-icon"
          alt=""
        />
        <p class="ttv-header__subscription-text m-0">1 mins</p>
      </div>

      <div class="ttv-header__buttons d-flex">
        <button
          type="button"
          class="ttv-header__button ttv-header__button--export btn d-inline-flex align-items-center"
          title="Export"
          :disabled="disableExport"
          @click="$emit('click:export')"
        >
          <img
            :src="disableExport ? require('@/assets/images/icons/ic_export_tta_na.svg') : require('@/assets/images/icons/ic_export_tta.svg')"
            class="ttv-header__button-icon"
            alt=""
          />
          Export
        </button>
        <button
          type="button"
          class="ttv-header__button ttv-header__button--save btn d-inline-flex align-items-center"
          title="Save"
          :disabled="disableSave"
          @click="$emit('click:save')"
        >
          Save
        </button>
      </div>
    </section> -->
  </header>
</template>

<script>
export default {
  model: {
    prop: 'fileName',
    event: 'input:file-name',
  },
  props: {
    fileName: {
      type: String,
      default: '',
    },
    disableExport: {
      type: Boolean,
      default: false,
    },
    disableSave: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input:file-name', 'click:back', 'click:export', 'click:save'],
};
</script>

<style scoped>
.ttv-header {
  min-height: 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  border-bottom: 1px solid #2D2D2D;
}

.ttv-header__left {
  gap: 16px;
}

.ttv-header__back-button-icon {
  height: 18px;
}

.ttv-header__title {
  font-size: 18px;
  font-weight: 500;
}

.ttv-header__file-name {
  width: 100%;
}

.ttv-header__file-name-input {
  width: 100%;
  background: none;
  border: none;
  font-size: 18px;
}

.ttv-header__file-name-label {
  z-index: -1;
  gap: 8px;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  pointer-events: none;
}

.ttv-header__file-name-label-text {
  color: #585859;
  font-size: 18px;
  font-weight: 500;
}

.ttv-header__file-name-label-icon {
  height: 16px;
}

.ttv-header__right {
  gap: 16px;
}

.ttv-header__subscription {
  gap: 6px;
}

.ttv-header__subscription-icon {
  height: 14px;
}

.ttv-header__subscription-text {
  font-size: 14px;
  font-weight: 500;
}

.ttv-header__buttons {
  gap: 8px;
}

.ttv-header__button {
  gap: 6px;
  padding-block: 6px;
  border: 2px solid #6D75F6;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.25s, border-color 0.25s, color 0.25s;
}

.ttv-header__button--export {
  padding-inline: 12px;
  background-color: transparent;
  color: #6D75F6;
}

.ttv-header__button--export:not(:disabled):hover {
  background-color: #6D75F633;
}

.ttv-header__button--export:disabled {
  border-color: #1F1F1F;
  color: #585859;
}

.ttv-header__button--save {
  padding-inline: 16px;
  background-color: #6D75F6;
  color: #FFFFFF;
}

.ttv-header__button--save:not(:disabled):hover {
  background-color: #545EED;
  border-color: #545EED;
}

.ttv-header__button--save:disabled {
  background-color: #1F1F1F;
  border-color: #1F1F1F;
  color: #585859;
}

.ttv-header__button-icon {
  height: 14px;
}
</style>
