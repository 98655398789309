<template>
  <div class="ap d-flex flex-column text-white">
    <button
      type="button"
      class="ap__toggle btn p-0 rounded-0 mx-auto"
      :disabled="isLoading"
      :title="`${isPlaying ? 'Pause' : 'Play'} ${name}`"
      @click="toggleAudioPlayer"
    >
      <font-awesome-icon
        :icon="`fa-solid ${isPlaying ? 'fa-pause-circle' : 'fa-play-circle'}`"
        class="ap__toggle-icon"
        :class="{ 'ap__toggle-icon--small': size === 'small' }"
      />
    </button>
	<input
    	ref="slider"
    	type="range"
    	class="ap__slider"
    	:class="{ 'ap__slider--small': size === 'small' }"
    	v-model="currentTime"
    	min="0"
    	:max="sliderMax"
    	title="Slide the Audio"
    	@input="handleInputSlider"
    	@change="handleChangeSlider"
    >
	<div class="d-flex align-items-center justify-content-between mt-1">
    <div class="d-flex align-items-center type-content">
      <p
        class="ap__name m-0 text-truncate"
        :class="{ 'ap__name--small': size === 'small' }"
        >
          {{ truncatedName }}
        </p>
        <div v-if="type" class="type--wrapper">
          <p class="type--wrapper--text m-0">{{ capitalizeText(type) }}</p>
        </div>
    </div>

      <p class="ap__time m-0">{{ calculateTime(currentTime) }}</p>
	</div>
    <audio ref="audioPlayer" :src="src" preload="metadata" />
  </div>
</template>

<script>
import getBlobDuration from 'get-blob-duration';

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator(value) {
        return ['small', 'medium'].includes(value);
      },
      default: 'medium',
    },
    type: {
      type: String,
      default: null
    }
  },
  emits: ['play'],
  data() {
    return {
      duration: 0,
      sliderMax: 100,
      currentTime: 0,
      isLoading: false,
      isPlaying: false,
      doSliding: false,
    };
  },
  computed: {
    truncatedName() {
      const maxLength = 14;
      const ellipsis = '...';

      return this.name.length > maxLength ? this.name.substring(0, maxLength - ellipsis.length) + '...' : this.name;
    },
  },
  methods: {
    async loadedmetadataListener() {
      if (this.src.search('blob:') >= 0) {
        this.duration = await getBlobDuration(this.src);
      } else {
        this.duration = this.$refs.audioPlayer.duration;
      }
      this.sliderMax = Math.floor(this.duration);
    },
    timeupdateListener() {
      if (!this.doSliding) {
        this.currentTime = Math.floor(this.$refs.audioPlayer.currentTime);
      }

      if (this.$refs.audioPlayer.currentTime >= this.$refs.audioPlayer.duration && this.$refs.audioPlayer.paused) {
        this.isPlaying = false;
      }
    },
    calculateTime(secs) {
      const remainingTime = this.duration - secs;
			const minutes = Math.max(Math.floor(remainingTime / 60), 0);
			const seconds = Math.max(Math.floor(remainingTime % 60), 0);
			const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
			const returnedMinutes = minutes < 10 ? `${minutes}` : `${minutes}`;
			return `${returnedMinutes}:${returnedSeconds}`;
    },
    async toggleAudioPlayer() {
      if (!this.isLoading) {
        this.isLoading = true;

        try {
          if (this.isPlaying) {
            await this.$refs.audioPlayer.pause();
            this.isPlaying = false;
          } else {
            await this.$refs.audioPlayer.play();
            this.isPlaying = true;
            this.$emit('play', this.pauseAudioPlayer, this._uid);
          }
        } catch (error) {
          console.log(error);
        }

        this.isLoading = false;
      }
    },
    async pauseAudioPlayer() {
      this.isLoading = true;

      try {
        if (this.isPlaying) {
          await this.$refs.audioPlayer.pause();
          this.isPlaying = false;
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
    handleInputSlider() {
      this.doSliding = true;
    },
    handleMouseupSlider() {
      if (this.doSliding) {
        this.doSliding = false;
      }
    },
    handleChangeSlider(e) {
      this.$refs.audioPlayer.currentTime = e.target.value;
    },
    capitalizeText(text) {
			return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
		},
  },
  mounted() {
    this.$refs.audioPlayer.addEventListener('loadedmetadata', this.loadedmetadataListener);
    this.$refs.audioPlayer.addEventListener('timeupdate', this.timeupdateListener);
    document.addEventListener('mouseup', this.handleMouseupSlider);
  },
  beforeDestroy() {
    this.$refs.audioPlayer.removeEventListener('loadedmetadata', this.loadedmetadataListener);
    this.$refs.audioPlayer.removeEventListener('timeupdate', this.timeupdateListener);
    document.removeEventListener('mouseup', this.handleMouseupSlider);
  },
};
</script>

<style scoped>
.ap {
  gap: 6px;
}

.ap__toggle {
  background: none;
  width: fit-content;
}

.ap__toggle-icon {
  font-size: 32px;
  color: #FFFFFF;
}

.ap__toggle-icon--small {
  font-size: 24px;
}

.ap__name {
  font-weight: 500;
}

.ap__name--small {
  font-size: 13px;
}

.ap__slider {
  width: 100%;
  accent-color: #6D75F6;
  cursor: pointer;
}

.ap__slider--small {
  height: 4px;
}

.ap__time {
  font-size: 12px;
}

.player__inner {
  gap: 4px;
}

.type-content {
  gap: 4px;
}

.type--wrapper {
  border-radius: 10.5px;
  background: #585859;
  padding: 2px 10px;
}

.type--wrapper--text {
  font-size: 10px;
}
</style>
