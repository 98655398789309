<template>
  <div class="gv d-flex flex-column text-white">
    <GVHeader
      :file-name="fileName"
      :disable-save="isSaveDisabled"
      :loading="loadingSave"
      :disableExport="!videoSrc"
      :disableGenerate="isGenerateNavDisabled"
      :mode="mode"
      :allocation="status_subscriptions"
      :main-control-state="mainControlState"
      @click:export="$bvModal.show('eport-gv-modal')"
      @click:back="handleBack"
      @click:save="saveContent(false)"
      @click:generate="handleClickMainControl()"
      @input:file-name="(newFileName) => fileName = newFileName"
      @click:topup-tooltip="handleClickTopupToolip"
    />
    <main class="gv__main">
      <div class="gv__middle d-flex flex-grow-1">
        <GVLibrary
          :character="character"
          :mode="mode"
          :is-library-expanded="isLibraryExpanded"
          :is-takeatour="isTakeTourOn"
          :takeatour-step="isTakeTourStep"
          :disable-library="statusApi[2]?.status"
          @change:mode="(val) => mode = val"
          @change:expanded="(newVal) => isLibraryExpanded = newVal"
          @change:character="(newCharacter) => character = newCharacter"
          @use:video="handleAddImage"
          @click:preview-trim-stock-video="handlePreviewTrimStockVideo"
        />
        <GVVideo
          ref="video"

          :mode="mode"
          :switch-confirm="isSwitchNeedConfirm"
          :video-ratio="videoRatioCss"
          :character-image-src="character?.imageSrc ?? null"
          :vo-image="images[activeImageIndex]?.src"
          :background-colors="backgroundColors"
          :background-color="backgroundColor"
          :disabled="!(['play', 'pause'].includes(mainControlState))"
          @change:mode="(newMode) => mode = newMode"
          @change:background-color="(newBackgroundColor) => backgroundColor = newBackgroundColor"

          :video-src="videoSrc"
          :disable-switch-mode="statusApi[2]?.status"
          @get:video-player-duration="handleGetVideoPlayerDuration"
          @play:video-player="handlePlayVideoPlayer"
          @pause:video-player="handlePauseVideoPlayer"
          @ended:video-player="handleEndedVideoPlayer"
          @timeupdate:video-player="handleTimeupdateVideoPlayer"
        />
        <GVVoice
          ref="voice"
          :mode="mode"
          :allocation="status_subscriptions"

          :disable-prompt="statusApi[0]?.status"
          :is-takeatour="isTakeTourOn"
          :takeatour-step="isTakeTourStep"
          :clickUpgrade="stateClickUpgrade"
          @click:handle-upgrade-gv="handleClickUpgradeGv"

          :voices="voices"
          :active-script="selectedVoices"
          @update:active-script="(val) => selectedVoices = val"
          @change:voices="(newVoices) => voices = newVoices"
          @after:generate="resetMainControlState()"
          @change:voices-order="handleChangeVoicesOrder"

          @update:allocation="getAllocQuota"
          @play:audio-player="handlePlayAudioPlayer"
          @pause:audio-player="handlePauseAudioPlayer"
          @ended:audio-player="handleEndedAudioPlayer"
          @timeupdate:audio-player="handleTimeupdateAudioPlayer"
        />
      </div>

      <GVTimeline
        :main-control-state="mainControlState"
        :disable-main-control="isMainControlDisabled"
        :voice-volume="voiceVolume"
        :music-volume="musicVolume"
        :video-volume="videoVolume"
        :current-time="currentTime"
        :precise-duration="duration"
        :video-ratios="videoRatios"
        :video-ratio="videoRatio"
        :mode="mode"
        :is-takeatour="isTakeTourOn"
        :takeatour-step="isTakeTourStep"
        :active-image-index="activeImageIndex"
        :active-voices="selectedVoices"
        @update:selected-voice="(newSelectedVoice) => selectedVoices = newSelectedVoice"
        @update:active-image-index="(val) => activeImageIndex = val"
        @click:main-control="handleClickMainControl()"
        @change:voice-volume="(newVoiceVolume) => voiceVolume = newVoiceVolume"
        @change:music-volume="(newMusicVolume) => musicVolume = newMusicVolume"
        @change:video-volume="(newVideoVolume) => videoVolume = newVideoVolume"
        @change:current-time="handleChangeCurrentTimeFromTimeline"
        @change:video-ratio="(newVideoRatio) => videoRatio = newVideoRatio"

        :voices="voicesOnTimeline"
        @delete:voice="handleDeleteVoice"
        @change:voices-order="handleChangeVoicesOrder"
        @update:start-voices="handleUpdateStartVoices"

        :musics="musics"
        @click:add-music="$bvModal.show('add-timeline-music')"
        @delete:music="handleDeleteMusic"
        @change:music-duration="handleChangeMusicDuration"
        @change:musics-order="handleChangeMusicsOrder"
        @update:start-music="handleUpdateStartMusic"

        :images="images"
        :disable-images="mode === 'ai-characters'"
        @click:add-image="$bvModal.show('my-assets-modal')"
        @delete:image="handleDeleteImage"
        @change:image-duration="handleChangeImageDuration"
        @change:images-order="handleChangeImagesOrder"
      />
    </main>

    <MyAssets id="my-assets-modal" @use:image="handleAddImage" :allocation="status_subscriptions" @update:allocation="getAllocQuota" :stateUpgrade="stateClickUpgrade" @click:handle-upgrade-gv="handleClickUpgradeGv" @close:imgq-modal="willRedirectForUpgrade = false"/>
    <AddTimelineMusicModal id="add-timeline-music" @use:music="handleAddMusic" />
    <AddTimelineImageModal id="add-timeline-image" @use:image="handleAddImage" />
    <TTVInputEmailModal id="ttv-input-email" :loading="loading_ttv" v-model="email" @generate-final="handleClickMainControl()" @generate-final-instead="email = null"/>
    <ExportGv :loading-audio='loading_daudio' :loading-video='loading_dvideo' :loading-cl="loading_cl" @click:download-audio="handleDownloadAudio" @click:download-video="handleDownloadVideo" @click:copy-link="handleCopyLink" @reset-egv="handleResetEgv"/>
    <SwitchVoToAc :mode="mode" @reset-data="handleResetData"/>
    <Soca v-if="loadingSave"/>
    <SaveToDraftGv @save-draft="(draft) => saveContent(draft)" @cancel-draft="handleCancelDraft"/>
    <TTVFinalValidate />
    <ModalValidateScript />
    <TTSServiceErrorModal @clear-storage="removeDataFromStorage"/>
    <GVTAT :steps="steps" :tourCallbacks="tourCallbacks"/>
    <ServiceTTVMaintenance />
    <ServerErrorModal />
    <IBImageGenerator :clickUpgrade="stateClickUpgrade" @click:handle-upgrade-gv="handleClickUpgradeGv" @close:imgq-modal="willRedirectForUpgrade = false"/>
    <MultilingualQuotaModal :click-upgrade="stateClickUpgrade" @click:handle-upgrade-gv="handleClickUpgradeGv"/>
		<VideoTrimPreview id="vtp-modal-library" :video="choosedVideoStock" :loading="isTrim" @click:trim-video="handleTrimVideo" @reset:video-trim="choosedVideoStock = null"/>

    <div v-if="isTakeTourOn" class="overlay--gv"></div>
  </div>
</template>

<script>
import randomUUID from '@/helper/uuid';

import GVHeader from '@/components/generate-videos/header';
import GVVideo from '@/components/generate-videos/video';
import GVLibrary from '@/components/generate-videos/library';
import GVVoice from '@/components/generate-videos/voice';
import GVTimeline from '@/components/generate-videos/timeline';
import GVTAT from '@/components/generate-videos/take-a-tour';
import MyAssets from '@/components/generate-videos/my-assets';
import AddTimelineMusicModal from '@/components/modal/AddTimelineMusic';
import AddTimelineImageModal from '@/components/modal/AddTimelineImage';
import TTVInputEmailModal from '@/components/modal/TTVInputEmail';
import SwitchVoToAc from '@/components/modal/SwitchVoToAc';
import Soca from '@/components/loading/Soca'
import SaveToDraftGv from '@/components/modal/SaveToDraftGv.vue'
import ExportGv from '@/components/modal/ExportGv.vue'
import TTVFinalValidate from '@/components/modal/TTVFinalValidate';
import ModalValidateScript from '@/components/modal/ModalValidateScript';
import TTSServiceErrorModal from '@/components/modal/TTSServiceError';
import ServiceTTVMaintenance from '@/components/modal/ServiceTTVMaintenance';
import axios from "axios"
import ServerErrorModal from '@/components/modal/ServerError';
import IBImageGenerator from '@/components/modal/IBImageGenerator';
import MultilingualQuotaModal from '@/components/modal/MultilingualQuota';
import VideoTrimPreview from '@/components/generate-videos/video-trim-preview';

import { generateFinalTTV } from '@/services/ttv/ttv.service.js'
import { getGeneratedTextToAudioFinal, getJobStatus } from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
import { base_url_home, base_url_machine_learning } from '../../../config/base_url';
import { postContents, getDataContent, updateContent } from "@/services/contents/contents.service";
import { downloadAudio } from "@/services/audio/audio.service"
import { AlertUtils } from "@/mixins/AlertUtils";
import { exportUtils } from "@/mixins/ExportUtils";
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'
import { checkStatusApi } from '@/services/third_party/third_party.service'
import { checkTakeTourVideo, updateTakeTourVideo } from '@/services/user/user.service'
import { trimVideos } from "@/services/my-assets/my-assets.service"

export default {
  mixins: [AlertUtils, exportUtils],
  components: {
    GVHeader,
    GVVideo,
    GVLibrary,
    GVVoice,
    GVTimeline,
    AddTimelineMusicModal,
    AddTimelineImageModal,
    SwitchVoToAc,
    TTVInputEmailModal,
    SaveToDraftGv,
    ExportGv,
    TTVFinalValidate,
    ModalValidateScript,
    TTSServiceErrorModal,
    MyAssets,
    GVTAT,
    ServiceTTVMaintenance,
    ServerErrorModal,
    IBImageGenerator,
    MultilingualQuotaModal,
    VideoTrimPreview,
    Soca
  },
  data() {
    return {
      fileName: '',
      mode: 'voice-only', // ['voice-only', 'ai-characters']
      videoSrc: null,

      email: null,
      choosedVideoStock: null,

      intervalGenerateFinal: null,
      jobId : null,
      selectedVoices: null,

      activeMediaPlayerType: null, // [null, 'video', 'audio']
      isActiveMediaPlayerPlaying: false,

      loading_ttv : false,
      loading_daudio : false,
      loading_dvideo : false,
      loading_cl : false,

      isLibraryExpanded : false,
      isTrim: false,

      link: null,

      backgroundColors: [
        // 'transparent',
        '#1F1F1F',
        '#FFFFFF',
        '#CA9CF4',
        '#3CD298',
        '#FFD400',
        '#5856D6',
        '#FD696E',
      ],
      backgroundColor: '#1F1F1F',

      character: null,

      voices: [],
      musics: [],
      images: [],

      statusApi: [],

      status_subscriptions: {},

      mainControlState: 'generate',

      currentTime: 0,
      duration: null, // Set to 'null' to use dynamic duration (by voices, music, and images)

      voiceVolume: 1,
      musicVolume: 0.4,
      videoVolume: 0.4,

      activeImageIndex: 0,

      loadingSave: false,

      willRedirectForUpgrade: false,

      videoRatios: [
        {
          id: '9:16',
          css: '9 / 16',
          name: 'Reels, Shorts & Tiktok',
          imageSrc: require('@/assets/images/icons/large-video-phone.png'),
        },
        {
          id: '1:1',
          css: '1 / 1',
          name: 'FB & IG Feeds',
          imageSrc: require('@/assets/images/icons/medium-video-phone.png'),
        },
        {
          id: '16:9',
          css: '16 / 9',
          name: 'YouTube',
          imageSrc: require('@/assets/images/icons/small-video-phone.png'),
        },
      ],
      videoRatio: '16:9',

      isPopulatingAllDataOnStorage: false,

      // Stored reactive data
      storageKeys: {
        fileName: {
          key: 'gv::file-name',
        },
        mode: {
          key: 'gv::mode',
        },
        videoSrc: {
          key: 'gv::video-src',
        },
        backgroundColor: {
          key: 'gv::background-color',
        },
        character: {
          key: 'gv::character',
          isObject: true,
        },
        voices: {
          key: 'gv::voices',
          isObject: true,
        },
        musics: {
          key: 'gv::musics',
          isObject: true,
        },
        images: {
          key: 'gv::images',
          isObject: true,
        },
        mainControlState: {
          key: 'gv::main-control-state',
        },
        voiceVolume: {
          key: 'gv::voice-volume',
          isNumber: true,
        },
        musicVolume: {
          key: 'gv::music-volume',
          isNumber: true,
        },
        videoVolume: {
          key: 'gv::video-volume',
          isNumber: true,
        },
        videoRatio: {
          key: 'gv::video-ratio',
        },
        duration: {
          key: 'gv::duration',
          isNumber: true,
        },
        jobId: {
          key: 'gv::job-id',
        },
      },

      steps: [
        {
          target: '#v-step-0',  // We're using document.querySelector() under the hood
          content: `<div class="gv--step-text"><p class="gv--step-title">${ this.$t('tour-1') } </p> ${ this.$t('desc-tour--1') }</div>`,
          params: {
            placement: 'left'
          }
        },
        {
          target: '#v-step-1',
          content: `<div class="gv--step-text"><p class="gv--step-title">${this.$t('tour-2')} </p> ${ this.$t('desc-tour--2') }</div>`,
          params: {
            placement: 'left'
          }
        },
        {
          target: '#v-step-2',
          content: `<div class="gv--step-text"><p class="gv--step-title">${ this.$t('tour-3') } </p> ${ this.$t('desc-tour--3') }</div>`,
          params: {
            placement: 'top'
          }
        },
        {
          target: '#v-step-3',
          content: `<div class="gv--step-text"><p class="gv--step-title">${ this.$t('tour-4') }</p> ${ this.$t('desc-tour--4') }</div>`,
          params: {
            placement: 'bottom'
          }
        }
      ],

      isTakeTourOn: false,
      isTakeTourStep: 0,

      tourCallbacks: {
        onNextStep: this.customNextStepCallback,
        onStop: this.customStopCallback
      }
    };
  },
  computed: {
    voicesOnTimeline() {
      return this.voices.filter((voice) => voice.src).map((voice) => ({ ...voice, timelineId: voice.id }));
    },
    isMainControlDisabled() {
      if (this.mode === 'voice-only') {
        if (
          this.voicesOnTimeline.length <= 0 || (this.isActiveMediaPlayerPlaying == true && this.activeMediaPlayerType === 'audio')
        ) {
          return true;
        }
      } else if (this.mode === 'ai-characters') {
        if (
          this.voicesOnTimeline.length <= 0
          ||
          (!this.character || !this.backgroundColor)
        ) {
          return true;
        }
      }

      return false;
    },

    isGenerateNavDisabled() {
      if (this.mode === 'voice-only') {
        if (
          this.voicesOnTimeline.length <= 0 || (this.isActiveMediaPlayerPlaying == true && this.activeMediaPlayerType === 'audio')
        ) {
          return true;
        }
      } else if (this.mode === 'ai-characters') {
        if (
          this.voicesOnTimeline.length <= 0
          ||
          (!this.character || !this.backgroundColor)
        ) {
          return true;
        }
      }

      return false;
    },
    videoRatioCss() {
      return this.videoRatios.find((ratio) => ratio.id === this.videoRatio).css;
    },
    isSwitchNeedConfirm() {
      if(this.voices.length > 1 || this.voicesOnTimeline.length > 0 || this.musics.length || this.images.length || this.character) {
        return true
      }
      return false;
    },
    isSaveDisabled() {
      if (this.mode === 'voice-only') {
        if (
          this.voicesOnTimeline.length <= 0
          || !this.jobId
        ) {
          return true;
        }
      } else if (this.mode === 'ai-characters') {
        if (
          this.voicesOnTimeline.length <= 0
          || (!this.character || !this.backgroundColor)
        ) {
          return true;
        }
      }
      return false
    }
  },
  watch: {
    fileName(newFileName) {
      this.saveDataToStorage(this.storageKeys.fileName, newFileName);
    },
    mode(newMode) {
      if(newMode == 'ai-characters') {
        this.isLibraryExpanded = true
      }
      this.saveDataToStorage(this.storageKeys.mode, newMode);
      this.currentTime = 0;
      if(sessionStorage.getItem('gv::mode-generate')){
        this.mainControlState = sessionStorage.getItem('gv::mode') == sessionStorage.getItem('gv::mode-generate') ? 'play' : 'generate';
      }
    },
    videoSrc(newVideoSrc) {
      this.saveDataToStorage(this.storageKeys.videoSrc, newVideoSrc);
    },
    backgroundColor(newBackgroundColor) {
      this.saveDataToStorage(this.storageKeys.backgroundColor, newBackgroundColor);
      if(sessionStorage.getItem('gv::background-color-generate')){
        this.mainControlState = sessionStorage.getItem('gv::background-color') == sessionStorage.getItem('gv::background-color-generate') ? 'play' : 'generate';
      }
    },
    character: {
      handler(newCharacter) {
        this.saveDataToStorage(this.storageKeys.character, newCharacter);

        // if (newCharacter.id !== prevCharacter?.id) {
        //   this.resetMainControlState();
        // }
      },
      deep: true,
    },
    voices: {
      handler(newVoices) {
        this.saveDataToStorage(this.storageKeys.voices, newVoices);
        if(sessionStorage.getItem('gv::voices-generate')){
          this.mainControlState = sessionStorage.getItem('gv::voices') == sessionStorage.getItem('gv::voices-generate') ? 'play' : 'generate';
        }
      },
      deep: true,
    },
    musics: {
      handler(newMusics) {
        this.saveDataToStorage(this.storageKeys.musics, newMusics);
        if(sessionStorage.getItem('gv::musics-generate')){
          this.mainControlState = sessionStorage.getItem('gv::musics') == sessionStorage.getItem('gv::musics-generate') ? 'play' : 'generate';
        }
      },
      deep: true,
    },
    images: {
      handler(newImages) {
        this.saveDataToStorage(this.storageKeys.images, newImages);
        if(sessionStorage.getItem('gv::images-generate')){
          this.mainControlState = sessionStorage.getItem('gv::images') == sessionStorage.getItem('gv::images-generate') ? 'play' : 'generate';
        }
      },
      deep: true,
    },
    mainControlState(newMainControlState) {
      if (['generate', 'play'].includes(newMainControlState)) {
        this.saveDataToStorage(this.storageKeys.mainControlState, newMainControlState);
      }
      if (newMainControlState === 'play' && !this.videoSrc) {
        this.$nextTick(() => {
          this.mainControlState = 'generate';
        });
      }
    },
    voiceVolume(newVoiceVolume) {
      this.saveDataToStorage(this.storageKeys.voiceVolume, newVoiceVolume);
      if(sessionStorage.getItem('gv::voice-volume-generate')){
        this.mainControlState = sessionStorage.getItem('gv::voice-volume') == sessionStorage.getItem('gv::voice-volume-generate') ? 'play' : 'generate';
      }
    },
    musicVolume(newMusicVolume) {
      this.saveDataToStorage(this.storageKeys.musicVolume, newMusicVolume);
      if(sessionStorage.getItem('gv::music-volume-generate')){
        this.mainControlState = sessionStorage.getItem('gv::music-volume') == sessionStorage.getItem('gv::music-volume-generate') ? 'play' : 'generate';
      }
    },
    videoVolume(newVideoVolume) {
      this.saveDataToStorage(this.storageKeys.videoVolume, newVideoVolume);
      if(sessionStorage.getItem('gv::video-volume-generate')){
        this.mainControlState = sessionStorage.getItem('gv::video-volume') == sessionStorage.getItem('gv::video-volume-generate') ? 'play' : 'generate';
      }
    },
    videoRatio(newVideoRatio) {
      this.saveDataToStorage(this.storageKeys.videoRatio, newVideoRatio);
      if(sessionStorage.getItem('gv::video-ratio-generate')){
        this.mainControlState = sessionStorage.getItem('gv::video-ratio') == sessionStorage.getItem('gv::video-ratio-generate') ? 'play' : 'generate';
      }
    },
    duration(newDuration) {
      this.saveDataToStorage(this.storageKeys.duration, newDuration);
    },
    currentTime(newCurrentTime) {
      let audioTrackData = this.voices[this.trackAudioByNeedle(newCurrentTime)]
      this.selectedVoices = audioTrackData?.id
    }
  },
  methods: {
    async getInitData(){
      let mode = this.$route.params.mode
      let id = this.$route.params.id
      if(mode === 'draft' || mode === 'edit'){
        await getDataContent(id)
        .then((res) => {
          if(res.status) {
            res.data.image_array == "[]" || res.data.image_array == null || res.data.image_array == "null" ? this.imageArray = [] : this.imageArray = res.data.image_array
            res.data.music_array == "[]" || res.data.music_array == null || res.data.music_array == "null" ? this.musicArray = [] : this.musicArray = res.data.music_array
            let mode = res.data.content_type == 'audio' ? 'voice-only' : 'ai-characters'

            // if(res.data.audio_array != "[]" && res.data.audio_array != null) {
            //   this.voices = JSON.parse(res.data.audio_array)
            // }

            sessionStorage.setItem("gv::voices", JSON.stringify(res.data.audio_array) ? JSON.stringify(res.data.audio_array) : [])
            sessionStorage.setItem("gv::file-name", res.data.content_name ? res.data.content_name : null)
            sessionStorage.setItem("gv::video-src", res.data.content_file ? res.data.content_file : null)
            sessionStorage.setItem("gv::main-control-state", res.data.generate_button_state ? res.data.generate_button_state : 'generate')
            sessionStorage.setItem("gv::music-volume", res.data.volume_music ? res.data.volume_music : 0.4)
            sessionStorage.setItem("gv::voice-volume", res.data.volume_audio ? res.data.volume_audio : 1)
            sessionStorage.setItem("gv::video-volume", res.data.volume_video ? res.data.volume_video : 0.4)
            sessionStorage.setItem("gv::character", res.data.character ? res.data.character : null)
            sessionStorage.setItem("gv::background-color", res.data.background_color ? res.data.background_color : '#1F1F1F')
            sessionStorage.setItem("gv::video-ratio", res.data.ratio == 'undefined' ? '' : res.data.ratio ? res.data.ratio : '16:9')
            sessionStorage.setItem("gv::job-id", res.data.job_id ? res.data.job_id : null)
            sessionStorage.setItem("gv::images", this.imageArray.length == 0 ? JSON.stringify([]) : JSON.stringify(this.imageArray))
            sessionStorage.setItem("gv::musics", this.musicArray.length == 0 ? JSON.stringify([]) : JSON.stringify(this.musicArray))
            sessionStorage.setItem("gv::mode", mode)

            sessionStorage.setItem("gv::voices-generate", JSON.stringify(res.data.audio_array) ? JSON.stringify(res.data.audio_array) : [])
            sessionStorage.setItem("gv::music-volume-generate", res.data.volume_music ? res.data.volume_music : 0.4)
            sessionStorage.setItem("gv::voice-volume-generate", res.data.volume_audio ? res.data.volume_audio : 1)
            sessionStorage.setItem("gv::video-volume-generate", res.data.volume_video ? res.data.volume_video : 1)
            sessionStorage.setItem("gv::character-generate", res.data.character ? res.data.character : null)
            sessionStorage.setItem("gv::background-color-generate", res.data.background_color ? res.data.background_color : '#1F1F1F')
            sessionStorage.setItem("gv::video-ratio-generate", res.data.ratio == 'undefined' ? '' : res.data.ratio ? res.data.ratio : '16:9')
            sessionStorage.setItem("gv::images-generate", this.imageArray.length == 0 ? JSON.stringify([]) : JSON.stringify(this.imageArray))
            sessionStorage.setItem("gv::musics-generate", this.musicArray.length == 0 ? JSON.stringify([]) : JSON.stringify(this.musicArray))
            sessionStorage.setItem("gv::mode-generate", mode)

            sessionStorage.setItem("gv::video-src-init", res.data.content_file ? res.data.content_file : null)
            sessionStorage.setItem("gv::file-name-init", res.data.content_name ? res.data.content_name : null)
            sessionStorage.setItem("gv::voices-init", JSON.stringify(res.data.audio_array) ? JSON.stringify(res.data.audio_array) : [])
            sessionStorage.setItem("gv::character-init", res.data.character ? res.data.character : null)
            sessionStorage.setItem("gv::background-color-init", res.data.background_color ? res.data.background_color : '#1F1F1F')
            sessionStorage.setItem("gv::video-ratio-init", res.data.ratio == 'undefined' ? '' : res.data.ratio ? res.data.ratio : '16:9')
            sessionStorage.setItem("gv::images-init", this.imageArray.length == 0 ? JSON.stringify([]) : JSON.stringify(this.imageArray))
            sessionStorage.setItem("gv::musics-init", this.musicArray.length == 0 ? JSON.stringify([]) : JSON.stringify(this.musicArray))
            sessionStorage.setItem("gv::mode-init", mode)

            sessionStorage.setItem("gv::get-from-api", true)
            this.historyData2();
          }
        })
      } else {
        await this.populateAllDataOnStorage();
      }
    },
    saveDataToStorage(keyObject, data) {
      if (!this.isPopulatingAllDataOnStorage) {
        let dataToSave = data;

        if (keyObject.isObject) {
          dataToSave = JSON.stringify(dataToSave);
        }

        sessionStorage.setItem(keyObject.key, dataToSave);
      }
    },
    async populateAllDataOnStorage() {
      this.isPopulatingAllDataOnStorage = true;

      for (const reactiveData in this.storageKeys) {
        let dataOnStorage = sessionStorage.getItem(this.storageKeys[reactiveData].key);

        if ([null, undefined, 'null', 'undefined', ''].includes(dataOnStorage)) {
          continue;
        }

        if (this.storageKeys[reactiveData].isNumber) {
          dataOnStorage = Number(dataOnStorage);
        } else if (this.storageKeys[reactiveData].isObject) {
          dataOnStorage = await JSON.parse(dataOnStorage);
        }

        this[reactiveData] = dataOnStorage;
      }

      this.isPopulatingAllDataOnStorage = false;
    },
    resetMainControlState() {
      if (!this.isPopulatingAllDataOnStorage) {
        this.mainControlState = 'generate';
        this.videoSrc = null;
        this.duration = null;
      }
    },
    async handleClickMainControl() {
      if (this.mainControlState === 'generate') {
        // this.mainControlState = 'generating';
        if(this.mode == 'voice-only') {
          // TTA
          await this.generateFinalTTA()
        } else {
          // TTV
          await this.generateFinalTTV()
        }

      } else if (this.mainControlState === 'play') {
        await this.$refs.video.$refs.videoPlayer.play();
      } else if (this.mainControlState === 'pause') {
        await this.$refs.video.$refs.videoPlayer.pause();
      }
    },
    handleDeleteVoice(id) {
      this.voices = this.voices.filter((voice) => voice.id !== id);

      this.resetMainControlState();
    },
    handleChangeVoicesOrder(newVoicesOnTimeline) {
      const voicesOnTimelineIndex = [];

      for (const voice of this.voicesOnTimeline) {
        const index = this.voices.findIndex((v) => v.id === voice.id);

        if (index > -1) {
          voicesOnTimelineIndex.push(index);
        }
      }

      let i = 0;
      for (const index of voicesOnTimelineIndex) {
        this.voices.splice(index, 1, newVoicesOnTimeline[i]);
        i++;
      }
    },
    handleAddMusic(music) {
      let start = 0;
      let end = music.duration;

      if (this.musics.length > 0) {
        start = Number((this.musics[this.musics.length - 1].end + 0.01).toFixed(2));
        end = Number((start + music.duration).toFixed(2));
      }

      this.musics.push({
        timelineId: randomUUID(),
        start,
        end,
        ...music,
      });

      this.$bvModal.hide('add-timeline-music');
    },
    handleDeleteMusic(index) {
      this.musics.splice(index, 1);

      for (let i = index; i < this.musics.length; i++) {
        this.populateMusicStartAndEnd(i);
      }
    },
    handleChangeMusicDuration(index, newDuration) {
      if (newDuration >= 1) {
        this.musics[index].duration = newDuration;
        this.populateMusicStartAndEnd(index);
        // for (let i = index; i < this.musics.length; i++) {
        //   this.populateMusicStartAndEnd(i);
        // }
      }
    },
    handleChangeMusicsOrder(musics, oldIndex, newIndex) {
      this.musics = musics;

      const minIndex = Math.min(...[oldIndex, newIndex]);

      for (let i = minIndex; i < this.musics.length; i++) {
        this.populateMusicStartAndEnd(i);
      }
    },
    populateMusicStartAndEnd(index) {
      let start = 0;
      let end = this.musics[index].duration;

      if (index > 0) {
        start = this.musics[index].start ? this.musics[index].start : Number((this.musics[index - 1].end + 0.01).toFixed(2));
        end = Number((start + this.musics[index].duration).toFixed(2));
      } else {
        start = this.musics[index].start != null ? this.musics[index].start : 0
        end = Number((start + this.musics[index].duration).toFixed(2));
      }

      this.musics[index].start = start;
      this.musics[index].end = end;

      if(this.musics.length > 1 && this.musics[index].end >= this.musics[Number(index) + 1]?.start) {
        this.reCalculateStartEndMusic(index)
      }
    },
    handleAddImage(image) {
      const duration = image?.videoDurationOrigin || 5;
      let start = 0;
      let end = duration;

      if (this.images.length > 0) {
        start = Number((this.images[this.images.length - 1].end + 0.01).toFixed(2));
        end = Number((start + duration).toFixed(2));
      }

      this.images.push({
        timelineId: randomUUID(),
        duration,
        start,
        end,
        ...image,
      });

      this.$bvModal.hide('add-timeline-image');
      this.$bvModal.hide('my-assets-modal');
    },
    handleDeleteImage(index) {
      this.images.splice(index, 1);

      for (let i = index; i < this.images.length; i++) {
        this.populateImageStartAndEnd(i);
      }
    },
    handleChangeImageDuration(index, newDuration) {
      if (newDuration >= 1) {
        this.images[index].duration = newDuration;

        for (let i = index; i < this.images.length; i++) {
          this.populateImageStartAndEnd(i);
        }

        this.resetMainControlState();
      }
    },
    handleChangeImagesOrder(images, oldIndex, newIndex) {
      this.images = images;

      const minIndex = Math.min(...[oldIndex, newIndex]);

      for (let i = minIndex; i < this.images.length; i++) {
        this.populateImageStartAndEnd(i);
      }

      this.resetMainControlState();
    },
    populateImageStartAndEnd(index) {
      let start = 0;
      let end = this.images[index].duration;

      if (index > 0) {
        start = Number((this.images[index - 1].end + 0.01).toFixed(2));
        end = Number((start + this.images[index].duration).toFixed(2));
      }

      this.images[index].start = start;
      this.images[index].end = end;
    },
    async handleChangeCurrentTimeFromTimeline(newCurrentTime) {
      if (this.isActiveMediaPlayerPlaying) {
        if (this.activeMediaPlayerType === 'audio') {
          await this.$refs.voice.$refs.audioPlayer.pause();
        } else if (this.activeMediaPlayerType === 'video') {
          this.$refs.video.$refs.videoPlayer.currentTime = newCurrentTime;
        }
      } else {
        this.currentTime = newCurrentTime;
      }
    },
    handleGetVideoPlayerDuration(videoPlayerDuration) {
      this.duration = videoPlayerDuration;
      this.activeMediaPlayerType = 'video';
    },
    async handlePlayVideoPlayer() {
      if (this.activeMediaPlayerType === 'audio') {
        await this.$refs.voice.$refs.audioPlayer.pause();
      }

      this.activeMediaPlayerType = 'video';
      this.isActiveMediaPlayerPlaying = true;
      this.mainControlState = 'pause';
    },
    handlePauseVideoPlayer() {
      this.isActiveMediaPlayerPlaying = false;
      this.mainControlState = 'play';
    },
    handleEndedVideoPlayer() {
      this.isActiveMediaPlayerPlaying = false;
      this.mainControlState = 'play';
    },
    handleTimeupdateVideoPlayer(videoPlayerCurrentTime) {
      this.currentTime = videoPlayerCurrentTime;
    },
    getVoiceAudioPlayerOffset(voiceId) {
      let index = this.voicesOnTimeline.findIndex(item => item.id === voiceId);
      let offset = this.voicesOnTimeline[index]?.start || 0;
      return offset;
    },
    async handlePlayAudioPlayer(voiceId) {
      if (this.activeMediaPlayerType === 'video') {
        await this.$refs.video.$refs.videoPlayer.pause();
      }

      this.activeMediaPlayerType = 'audio';
      this.currentTime = this.getVoiceAudioPlayerOffset(voiceId);
      this.isActiveMediaPlayerPlaying = true;
    },
    handlePauseAudioPlayer() {
      this.isActiveMediaPlayerPlaying = false;
    },
    handleEndedAudioPlayer() {
      this.isActiveMediaPlayerPlaying = false;
    },
    handleTimeupdateAudioPlayer(audioPlayerCurrentTime, voiceId) {
      this.currentTime = audioPlayerCurrentTime + this.getVoiceAudioPlayerOffset(voiceId);
    },
    async handleSelectCharacterFromTemplate() {
      const templateCharacter = await JSON.parse(sessionStorage.getItem('gv::template-character'));

      if (templateCharacter) {
        this.mode = 'ai-characters';
        this.character = templateCharacter;
        sessionStorage.removeItem('gv::template-character');
      }
    },
    async generateFinalTTV() {
      let totalDurationVoice = this.calculateTotalDurationVoices()

      if (totalDurationVoice && totalDurationVoice > 30) {
        this.$bvModal.show('ttvfv-modal')
      } else {
        this.loadingSave = true

        const voiceVolumeInStorage = sessionStorage.getItem("gv::voice-volume") ? sessionStorage.getItem("gv::voice-volume") : 1
        const musicVolumeInStorage = sessionStorage.getItem("gv::music-volume") ? sessionStorage.getItem("gv::music-volume") : 0.4
        // const videoVolumeInStorage = sessionStorage.getItem("gv::video-volume") ? sessionStorage.getItem("gv::video-volume") : 0.4
        const musicOnTimelineStorage = JSON.parse(sessionStorage.getItem("gv::musics"))
        const characterInStorage = JSON.parse(sessionStorage.getItem("gv::character"))
        const bcInStorage = sessionStorage.getItem("gv::background-color") ? sessionStorage.getItem("gv::background-color") : '#1F1F1F'

        let list_tts_id = []
        let music = []

        if(musicOnTimelineStorage && musicOnTimelineStorage.length > 0 ){
          musicOnTimelineStorage.forEach((e) => {
            music.push({
                "music_id": e.id,
                "start": e.start,
                "end": e.end
            })
          })
        }

        this.voices.forEach((e) => {
          if(e.ttsId) {
            list_tts_id.push(e.ttsId)
          }
        })

        let formData = {
          course_id : this.voices[0]?.courseId || '',
          params : {
            tts_id : list_tts_id || [],
            music : music || [],
            avatars : {
              id_avatars : characterInStorage.id || null,
              link_url : null
            },
            volume_music : parseFloat(musicVolumeInStorage),
            volume_tts : parseFloat(voiceVolumeInStorage),
            // volume_vid : parseFloat(videoVolumeInStorage),
            background_type : 'color',
            background_value : bcInStorage,
            email: this.email || null
          }
        }

        await generateFinalTTV(formData)
        .then((response) => {
          if (response.status === 403) {
            this.loadingSave = false
            this.$bvModal.show('ttvfv-modal');
          } else if(response.status == 200) {
            this.jobId = response.data.job_id || null
            sessionStorage.setItem("gv::job-id", this.jobId)
            this.saveContentTtv(false)
          } else if(response.status == 402) {
            this.$bvModal.show('server-error-modal');
            this.loadingSave = false
          } else {
            this.$bvModal.show('server-error-modal');
          }
        })
        .catch(() => {
          this.loadingSave = false
          this.$bvModal.show('server-error-modal');
        })
      }
    },
    async generateFinalTTA() {
      this.mainControlState = 'generating';

      const voiceVolumeInStorage = sessionStorage.getItem("gv::voice-volume") ? sessionStorage.getItem("gv::voice-volume") : 1
      const musicVolumeInStorage = sessionStorage.getItem("gv::music-volume") ? sessionStorage.getItem("gv::music-volume") : 0.4
      const videoVolumeInStorage = sessionStorage.getItem("gv::video-volume") ? sessionStorage.getItem("gv::video-volume") : 0.4
      const ratioInStorage = sessionStorage.getItem("gv::video-ratio") ? sessionStorage.getItem("gv::video-ratio") : '16:9'
      const musicOnTimelineStorage = JSON.parse(sessionStorage.getItem("gv::musics"))
      const imagesOnTimelineStorage = JSON.parse(sessionStorage.getItem("gv::images"))

      let tts = []
      let musics = []
      let images = []

      this.voices.forEach((e) => {
        
        if(e.ttsId) {
          tts.push({
            "tts_id" : e.ttsId,
            "start" : e.start
          })
        }
      })

      //check jika skrip ada yang kosong
      let data = this.voices.filter(item => item.text === "" || item.text.length === 0);
      if(data.length > 0){
        this.$bvModal.show('validate-script-modal');
        this.mainControlState = 'generate';
        return;
      }

      if(musicOnTimelineStorage && musicOnTimelineStorage.length > 0 ){
        musicOnTimelineStorage.forEach((e) => {
          musics.push({
              "music_id": e.id,
              "start": e.start,
              "end": e.end
          })
        })
      }

      if(imagesOnTimelineStorage && imagesOnTimelineStorage.length > 0 ){
        imagesOnTimelineStorage.forEach((e) => {
          console.log(e)
          if(e.type == 'video') {
            images.push({
              "url": e.id,
              "start": e.start,
              "end": e.end
            })
          } else {
            images.push({
                "url": e.src,
                "start": e.start,
                "end": e.end
            })
          }
        })
      }

      let formData = {
        course_id : this.voices[0]?.courseId || '',
        params : {
          tts_id : tts,
          music : musics,
          images : images,
          volume_music : parseFloat(musicVolumeInStorage),
          volume_tts : parseFloat(voiceVolumeInStorage),
          volume_vid : parseFloat(videoVolumeInStorage),
          ratio : ratioInStorage
        }
      }

      await getGeneratedTextToAudioFinal(formData)
      .then((response) => {
        if(response.status == 200) {
          let jobId = response.data.job_id || null

          if(jobId) {
            this.intervalGenerateFinal = setInterval(() => {
              getJobStatus(jobId)
              .then((res) => {
                if(res.status == 200) {
                  if(res.data.status === "finish"){
                    this.videoSrc = base_url_machine_learning + res.data.final_path + '?v=' + Math.random();
                    this.mainControlState = 'play';
                    this.jobId = jobId

                    sessionStorage.setItem("gv::voices-generate", JSON.stringify(this.voices))
                    sessionStorage.setItem("gv::character-generate", JSON.stringify(this.character))
                    sessionStorage.setItem("gv::musics-generate", JSON.stringify(this.musics))
                    sessionStorage.setItem("gv::images-generate", JSON.stringify(this.images))
                    sessionStorage.setItem("gv::voice-volume-generate", voiceVolumeInStorage)
                    sessionStorage.setItem("gv::music-volume-generate", musicVolumeInStorage)
                    sessionStorage.setItem("gv::video-volume-generate", videoVolumeInStorage)
                    sessionStorage.setItem("gv::video-ratio-generate", ratioInStorage)
                    sessionStorage.setItem("gv::mode-generate", this.mode)
                    sessionStorage.setItem("gv::background-color-generate", this.backgroundColor)
                    sessionStorage.setItem("gv::job-id", this.jobId),


                    clearInterval(this.intervalGenerateFinal)
                  }
                } else if (response.status === 402 || response.status === 500) {
                  this.$bvModal.show('server-error-modal');
                  this.mainControlState = 'generate';
                } else {
                  this.mainControlState = 'generate';
                }
              })
              .catch((error) => {
                this.$bvModal.show('server-error-modal');
                this.mainControlState = 'generate';
                console.error(error)
              })
            }, 5000)
          } else {
            this.mainControlState = 'generate';
          }
        } else if (response.status === 402 || response.status === 500) {
          this.$bvModal.show('server-error-modal');
          this.mainControlState = 'generate';
        } else {
          this.$bvModal.show('server-error-modal');
          this.mainControlState = 'generate';
        }
      })
      .catch((error) => {
        this.$bvModal.show('server-error-modal');
        this.mainControlState = 'generate';
        console.error(error)
      })
    },
    async saveContent(draft){
      this.$bvModal.hide('std-gv')
      if(this.mode == 'voice-only'){
        await this.saveContentTta(draft, false)
      } else [
        await this.saveContentTtv(true)
      ]
    },
    async saveContentTta(draft, isexport) {
      let mode = this.$route.params.mode
      let id = this.$route.params.id
      let formData = {
        "content_type": "audio",
        "content_name": this.fileName ? this.fileName : null,
        "content_file": sessionStorage.getItem("gv::video-src"),
        "image_thumbnail" : this.images.length > 0 ? this.images[0].src : null,
        "image_array": sessionStorage.getItem("gv::images") || "[]",
        "audio_array": sessionStorage.getItem("gv::voices") || "[]",
        "music_array": sessionStorage.getItem("gv::musics") || "[]",
        "volume_music" : parseFloat(sessionStorage.getItem('gv::music-volume')) || 0.4,
        "volume_video" : parseFloat(sessionStorage.getItem('gv::video-volume')) || 0.4,
        "volume_audio" : parseFloat(sessionStorage.getItem('gv::voice-volume')) || 1,
        "generate_button_state": sessionStorage.getItem('gv::main-control-state') || 'play',
        "job_id" : sessionStorage.getItem("gv::job-id"),
        "ratio" : sessionStorage.getItem("gv::video-ratio") || '16:9',
        "is_draft": draft
      }

      this.loadingSave = true

      if(mode === 'draft' || mode === 'edit') {
        await updateContent(formData, id)
        .then((response) => {
          if(response.status) {
            this.link = base_url_home + 'share/' + (this.fileName ? this.fileName.replace(/ /g, '-') : 'Audio') + '/' + id;

            if(isexport && mode == 'draft') {
              this.loadingSave = false
              this.$router.replace({ params: { mode : 'edit', id : id } })
            } else {
              this.removeDataFromStorage()
              if(this.willRedirectForUpgrade) {
                this.$router.push({ name : 'TopUp' })
              } else {
                this.$router.push({ name : 'My-Quiz' })
              }
            }
          }
        })
      } else {
        await postContents(formData)
        .then((response) => {
          if(response.status) {
            // this.loadingSave = false
            this.link = base_url_home + 'share/' + (this.fileName ? this.fileName.replace(/ /g, '-') : 'Audio') + '/' + response.data._id;

            if(isexport) {
              this.$router.replace({ params: { mode : 'edit', id : response.data._id } })
            } else {
              this.removeDataFromStorage()
              if(this.willRedirectForUpgrade) {
                this.$router.push({ name : 'TopUp' })
              } else {
                this.$router.push({ name : 'My-Quiz' })
              }
            }
          }
        })
      }

    },
    async saveContentTtv(isdraft) {
      let mode = this.$route.params.mode
      let id = this.$route.params.id
      let formData = {
        "content_type": "video",
        "content_name": this.fileName ? this.fileName : null,
        "content_file": sessionStorage.getItem("gv::video-src"),
        "image_thumbnail" : this.images.length > 0 ? this.images[0].src : null,
        "character": sessionStorage.getItem("gv::character"),
        "audio_array": sessionStorage.getItem("gv::voices"),
        "music_array": sessionStorage.getItem("gv::musics") || "[]",
        "volume_music" : parseFloat(sessionStorage.getItem('gv::music-volume')) || 0.4,
        "volume_video" : parseFloat(sessionStorage.getItem('gv::video-volume')) || 0.4,
        "volume_audio" : parseFloat(sessionStorage.getItem('gv::voice-volume')) || 1,
        "background_color": sessionStorage.getItem("gv::background-color") || '#1F1F1F',
        "generate_button_state": sessionStorage.getItem('gv::main-control-state') || 'generate',
        "job_id" : sessionStorage.getItem("gv::job-id"),
        "is_draft": isdraft
      }

      this.loadingSave = true

      if(mode === 'draft' || mode === 'edit') {
        await updateContent(formData, id)
        .then((response) => {
          if(response.status) {
            // this.loadingSave = false
            this.removeDataFromStorage()
            if(this.willRedirectForUpgrade) {
              this.$router.push({ name : 'TopUp' })
            } else {
              this.$router.push({ name : 'My-Quiz' })
            }
          }
        })
      } else {
        await postContents(formData)
        .then((response) => {
          if(response.status) {
            // this.loadingSave = false
            this.removeDataFromStorage()
            if(this.willRedirectForUpgrade) {
              this.$router.push({ name : 'TopUp' })
            } else {
              this.$router.push({ name : 'My-Quiz' })
            }
          }
        })
      }
    },
    async handleResetData() {
      this.mode = this.mode === 'voice-only' ? 'ai-characters' : 'voice-only'
      await this.removeDataFromStorage('gv::mode')
      await this.historyData2()
      this.$bvModal.hide('svta-modal')
    },
    async historyData2(){
      if(sessionStorage.getItem("gv::file-name")){
        this.fileName = sessionStorage.getItem("gv::file-name")
      } else {
        this.fileName = null
      }

      if(sessionStorage.getItem("gv::mode")){
        this.mode = sessionStorage.getItem("gv::mode")
      } else {
        this.mode = 'voice-only'
      }

      if(sessionStorage.getItem("gv::video-src")){
        this.videoSrc = sessionStorage.getItem("gv::video-src") === "null" ? null : sessionStorage.getItem("gv::video-src");
      } else {
        this.videoSrc = null
      }

      if(sessionStorage.getItem("gv::background-color")){
        this.backgroundColor = sessionStorage.getItem("gv::background-color")
      } else {
        this.backgroundColor = '#1F1F1F'
      }

      if(sessionStorage.getItem("gv::character")){
        this.character = JSON.parse(sessionStorage.getItem("gv::character"))
      } else {
        this.character = null
      }

      if(sessionStorage.getItem("gv::voices")){
        this.voices = JSON.parse(sessionStorage.getItem("gv::voices"))
      } else {
        this.voices = []
      }

      if(sessionStorage.getItem("gv::musics")){
        this.musics = JSON.parse(sessionStorage.getItem("gv::musics"))
      } else {
        this.musics = []
      }

      if(sessionStorage.getItem("gv::images")){
        this.images = JSON.parse(sessionStorage.getItem("gv::images"))
      } else {
        this.images = []
      }

      if(sessionStorage.getItem("gv::main-control-state")){
        this.mainControlState = sessionStorage.getItem("gv::main-control-state")
      } else {
        this.mainControlState = 'generate'
      }

      if(sessionStorage.getItem("gv::voice-volume")){
        this.voiceVolume = Number(sessionStorage.getItem("gv::voice-volume"))
      } else {
        this.voiceVolume = 1
      }

      if(sessionStorage.getItem("gv::music-volume")){
        this.musicVolume = Number(sessionStorage.getItem("gv::music-volume"))
      } else {
        this.musicVolume = 0.4
      }

      if(sessionStorage.getItem("gv::video-volume")){
        this.videoVolume = Number(sessionStorage.getItem("gv::video-volume"))
      } else {
        this.videoVolume = 0.4
      }

      if(sessionStorage.getItem("gv::video-ratio")){
        this.videoRatio = sessionStorage.getItem("gv::video-ratio")
      } else {
        this.videoRatio = '16:9'
      }

      if(sessionStorage.getItem("gv::duration")){
        this.duration = Number(sessionStorage.getItem("gv::duration"))
      } else {
        this.duration = null
      }

      if(sessionStorage.getItem("gv::job-id")){
        this.jobId = sessionStorage.getItem("gv::job-id")
      } else {
        this.jobId = null
      }
    },
    handleBack() {
      let mode = this.$route.params.mode
      if(mode === 'draft' || mode === 'edit'){
        if(
          sessionStorage.getItem('gv::file-name') == sessionStorage.getItem('gv::file-name-init') &&
          sessionStorage.getItem('gv::video-src') == sessionStorage.getItem('gv::video-src-init') &&
          sessionStorage.getItem('gv::character') == sessionStorage.getItem('gv::character-init') &&
          sessionStorage.getItem('gv::images') == sessionStorage.getItem('gv::images-init') &&
          sessionStorage.getItem('gv::video-ratio') == sessionStorage.getItem('gv::video-ratio-init') &&
          sessionStorage.getItem('gv::voices') == sessionStorage.getItem('gv::voices-init') &&
          sessionStorage.getItem('gv::background-color') == sessionStorage.getItem('gv::background-color-init') &&
          sessionStorage.getItem('gv::musics') == sessionStorage.getItem('gv::musics-init')
        ) {
          this.removeDataFromStorage()
          this.$router.push({ name : 'Home' })
        } else {
          this.$bvModal.show('std-gv')
        }
      } else {
        if(
          !sessionStorage.getItem('gv::file-name') &&
          !sessionStorage.getItem('gv::video-src') &&
          !sessionStorage.getItem('gv::character') &&
          !sessionStorage.getItem('gv::images') &&
          !sessionStorage.getItem('gv::video-ratio') &&
          !sessionStorage.getItem('gv::voices') &&
          !sessionStorage.getItem('gv::musics')
        ) {
          this.removeDataFromStorage()
          this.$router.push({ name : 'Home' })
        } else if (sessionStorage.getItem('gv::voices')){
          let gv = JSON.parse(sessionStorage.getItem('gv::voices'))

          if(gv.length === 1 && gv[0].text === '' && !sessionStorage.getItem('gv::images') && !sessionStorage.getItem('gv::musics')){
            this.removeDataFromStorage()
            this.$router.push({ name : 'Home' })
          } else {
            this.$bvModal.show('std-gv')
          }
        } else {
          this.$bvModal.show('std-gv')
        }
      }
      // this.removeDataFromStorage()
      // this.$router.push({ name : 'Home' })
    },
    removeDataFromStorage(except) {
      if(!except) {
        sessionStorage.removeItem("gv::mode")
        sessionStorage.removeItem("gv::voices")
      }
      sessionStorage.removeItem("gv::character")
      sessionStorage.removeItem("gv::template-character")
      sessionStorage.removeItem("gv::musics")
      sessionStorage.removeItem("gv::images")
      sessionStorage.removeItem("gv::voice-volume")
      sessionStorage.removeItem("gv::music-volume")
      sessionStorage.removeItem("gv::video-volume")
      sessionStorage.removeItem("gv::video-ratio")
      sessionStorage.removeItem("gv::background-color")
      sessionStorage.removeItem("gv::file-name")
      sessionStorage.removeItem("gv::video-src")
      sessionStorage.removeItem("gv::main-control-state")
      sessionStorage.removeItem("gv::duration")
      sessionStorage.removeItem("gv::job-id")
      sessionStorage.removeItem("gv::get-from-api")
      // =======================
      sessionStorage.removeItem("gv::voices-generate")
      sessionStorage.removeItem("gv::character-generate")
      sessionStorage.removeItem("gv::musics-generate")
      sessionStorage.removeItem("gv::images-generate")
      sessionStorage.removeItem("gv::voice-volume-generate")
      sessionStorage.removeItem("gv::music-volume-generate")
      sessionStorage.removeItem("gv::video-volume-generate")
      sessionStorage.removeItem("gv::video-ratio-generate")
      sessionStorage.removeItem("gv::mode-generate")
      sessionStorage.removeItem("gv::background-color-generate")
      // =======================
      sessionStorage.removeItem("gv::file-name-init")
      sessionStorage.removeItem("gv::character-init")
      sessionStorage.removeItem("gv::voices-init")
      sessionStorage.removeItem("gv::musics-init")
      sessionStorage.removeItem("gv::images-init")
      sessionStorage.removeItem("gv::video-src-init")
      sessionStorage.removeItem("gv::video-ratio-init")
      sessionStorage.removeItem("gv::mode-init")
      sessionStorage.removeItem("gv::background-color-init")
      sessionStorage.removeItem("SSMLActiveSpeaker")
      sessionStorage.removeItem("SSMLPreviewHistory")
    },
    async isGetDataFirst() {
      if(sessionStorage.getItem("gv::get-from-api")) {
        await this.populateAllDataOnStorage();
      } else {
        await this.getInitData()
      }
    },
    handleCancelDraft() {
      this.removeDataFromStorage()
      this.willRedirectForUpgrade = false
      if(this.$route.params.mode == 'edit' || this.$route.params.mode == 'draft') {
        this.$router.push({ name : 'My-Quiz' })
      } else {
        this.$router.push({ name : 'Home' })
      }
    },
    handleDownloadAudio() {
      this.loading_daudio = true
      let formData = {
        job_id : this.jobId
      }

      downloadAudio(formData)
      .then((response) => {
        if(response.status == 200) {
          let mp3url = base_url_machine_learning + response.data
          axios.get(mp3url, { responseType: 'blob' })
          .then(res => {
            this.exportAudio(res.data, this.fileName ? this.fileName : 'Audio - SOCA AI')
            this.alertSuccess('Audio successfully downloaded')
            this.saveContentTta(false, true)
            this.loading_daudio = false
          })
          .catch(() => {
            this.alertFail('Something went wrong !')
            this.loading_daudio = false
          })
        }
      })
      .catch(() => {
        this.alertFail('Something went wrong !')
        this.loading_daudio = false
      })
    },
    handleDownloadVideo() {
      this.loading_dvideo = true
      axios.get(this.videoSrc, { responseType: 'blob' })
      .then((res) => {
        this.exportVidio(res.data, this.fileName ? this.fileName : 'Video - SOCA AI')
        this.alertSuccess(this.$t('content-successfully'))
        this.saveContentTta(false, true)
        this.loading_dvideo = false
      })
      .catch(() => {
        this.alertFail('Something went wrong !')
        this.loading_dvideo = false
      })
    },
    async handleCopyLink() {
      this.loading_cl = true
      await this.saveContentTta(false, true)
      if(this.link) {
        navigator.clipboard.writeText(this.link)
        .then(() => {
          this.alertSuccess('Link successfully copied')
          this.loading_cl = false
        })
        .catch(() => {
          this.alertFail('Link failed to copy');
          this.loading_cl = false
        })
      } else {
        this.alertFail('Link failed to copy')
        this.loading_cl = false
      }
    },
    handleResetEgv() {
      this.loading_cl = false
      this.loading_daudio = false
      this.loading_dvideo = false
    },
    async handleSelectVoiceFromTemplate() {
      const useVoice = await JSON.parse(sessionStorage.getItem('gv::use-voice'));

      if (useVoice) {
        this.voices[0].voiceType = useVoice
        sessionStorage.removeItem('gv::use-voice');
      }
    },
    calculateTotalDurationVoices() {
      let totalDuration = 0;
      this.voices.forEach((item) => {
        totalDuration += item?.duration || 0;
      });
      return totalDuration;
    },
    getAllocQuota() {
      subscriptionsStatus()
      .then((response) => {
        if(response.status) {
          this.status_subscriptions = response.data
        }
      })
    },
    checkStatusApi() {
      checkStatusApi()
      .then((response) => {
        if(response.status == 200) {
          this.statusApi = response.data
          if(this.statusApi[1]?.status == 'nok') {
            this.$bvModal.show('ttsse-modal')
          }

          if(this.statusApi[2]?.status == 'nok') {
            this.mode = 'voice-only'
          }
        }
      })
    },
    customNextStepCallback (currentStep) {
      this.isTakeTourStep = currentStep + 1
      if (currentStep === 3) {
        this.isLibraryExpanded = true
      } else {
        this.isLibraryExpanded = false
      }
    },
    customStopCallback() {
      this.updateTakeTourVideoUser()
      this.isTakeTourOn = false
    },
    checkTakeTourVideoUser(){
      checkTakeTourVideo()
      .then((response) => {
        if(!response.data) {
          this.$tours['TTVtour'].start()
          this.isTakeTourOn = true
        }
      })
    },
    updateTakeTourVideoUser() {
      updateTakeTourVideo()
    },
    async handleImportFile() {
      const useVoice = await JSON.parse(sessionStorage.getItem('gv::import-file'));

      if (useVoice) {
        let images = []
        useVoice.forEach((img, i) => {
          images.push({
            type: 'upload',
            id: randomUUID(),
            name: 'Upload image ' + (Number(i) + 1),
            src: img.image
          })
        })

        if(images.length > 0) {
          images.forEach((image) => {
            this.handleAddImage(image)
          })
        }
        sessionStorage.removeItem('gv::import-file');
      }
    },
    async handleUseVideoFromAsset() {
      const useVideo = await JSON.parse(sessionStorage.getItem('gv::use-video'));
      if(useVideo) {
        this.handleAddImage(useVideo)
        sessionStorage.removeItem('gv::use-video')
      }
    },
    stateClickUpgrade() {
      if (this.voices.length > 0 ||
          this.musics.length > 0 ||
          this.images.length > 0) {
        return () => {
          this.$bvModal.hide('audio-quota');
          this.$bvModal.show('alert-savedraft')
          this.willRedirectForUpgrade = true;
        };
      } else if(this.voices.length === 0 && this.voices[0]?.text) {
        return () => {
          this.$bvModal.hide('audio-quota');
          this.$bvModal.show('alert-savedraft')
          this.willRedirectForUpgrade = true;
        };
      } else {
        return null;
      }
    },
    handleClickUpgradeGv() {
      this.$bvModal.hide('audio-quota');
      this.$bvModal.hide('img-quota-modal')
      this.$bvModal.hide('qm-modal')
      this.$bvModal.show('std-gv')
      this.willRedirectForUpgrade = true;
    },
    handleClickTopupToolip() {
      if(this.stateClickUpgrade() !== null) {
        this.handleClickUpgradeGv()
      } else {
        this.$router.push({ name : 'TopUp' })
      }
    },
    handleUpdateStartVoices(val, i) {
      // CALCULATE NEW START & END
      let newStart = Number(val.toFixed(2))
      if(i > 0 && newStart < this.voicesOnTimeline[i - 1]?.end) {
        newStart = this.voicesOnTimeline[i - 1].end + 0.01
      }
      this.voicesOnTimeline[i].start = newStart

      let newEnd = Number((this.voicesOnTimeline[i].start + this.voicesOnTimeline[i].duration).toFixed(2));
      if(newEnd >= this.voicesOnTimeline[Number(i) + 1]?.start) {
        newEnd = this.voicesOnTimeline[Number(i) + 1]?.start - 0.01
        this.voicesOnTimeline[i].start = newEnd - this.voicesOnTimeline[i].duration
      }
      this.voicesOnTimeline[i].end = newEnd
      this.handleChangeVoicesOrder(this.voicesOnTimeline)

      // let newStart = Number(val.toFixed(2))
      // if(i > 0 && newStart < this.voicesOnTimeline[i - 1]?.end) {
      //   newStart = this.voicesOnTimeline[i - 1].end + 0.01
      // }
      // this.voicesOnTimeline[i].start = newStart

      // let newEnd = Number((this.voicesOnTimeline[i].start + this.voicesOnTimeline[i].duration).toFixed(2));
      // this.voicesOnTimeline[i].end = newEnd
      // if(this.voicesOnTimeline[i].end >= this.voicesOnTimeline[Number(i) + 1]?.start && this.voicesOnTimeline[i].start < this.voicesOnTimeline[Number(i) + 1]?.end) {
      //   this.reCalculateStartEnd(Number(i))
      // } else if(this.voicesOnTimeline[i].end >= this.voicesOnTimeline[Number(i) + 1]?.start && this.voicesOnTimeline[i].start > this.voicesOnTimeline[Number(i) + 1]?.end) {
      //   //SWITCH INDEX
      //   const temp = this.voicesOnTimeline[i];
      //   this.voicesOnTimeline[i] = this.voicesOnTimeline[Number(i) + 1];
      //   this.voicesOnTimeline[Number(i) + 1] = temp;
      // }
      // this.handleChangeVoicesOrder(this.voicesOnTimeline)
    },
    reCalculateStartEnd(index) {
      if(this.voicesOnTimeline.length > index + 1) {
        for (let i = index + 1; i < this.voicesOnTimeline.length; i++) {
          if(this.voicesOnTimeline[i - 1].end >= this.voicesOnTimeline[i].start) {
            this.voicesOnTimeline[i].start = Number((this.voicesOnTimeline[i-1].end + 0.01).toFixed(2))
            this.voicesOnTimeline[i].end = Number((this.voicesOnTimeline[i].start + this.voicesOnTimeline[i].duration).toFixed(2))
          }
        }
      }
    },
    handleUpdateStartMusic(val, i) {
      // CALCULATE NEW START & END
      let newStart = Number(val.toFixed(2))
      if(i > 0 && newStart < this.musics[i - 1]?.end) {
        newStart = this.musics[i - 1].end + 0.01
      }
      this.musics[i].start = newStart

      let newEnd = Number((this.musics[i].start + this.musics[i].duration).toFixed(2));
      if(newEnd >= this.musics[Number(i) + 1]?.start) {
        newEnd = this.musics[Number(i) + 1]?.start - 0.01
        this.musics[i].start = newEnd - this.musics[i].duration
      }
      this.musics[i].end = newEnd
    },
    reCalculateStartEndMusic(i) {
      let index = Number(i)
      if(this.musics.length > index + 1) {
        for (let i = index + 1; i < this.musics.length; i++) {
          if(this.musics[i - 1].end >= this.musics[i].start) {
            this.musics[i].start = Number((this.musics[i-1].end + 0.01).toFixed(2))
            this.musics[i].end = Number((this.musics[i].start + this.musics[i].duration).toFixed(2))
          }
        }
      }
    },
    trackAudioByNeedle(currentTime) {
      for (let i = 0; i < this.voices.length; i++) {
        if (currentTime >= this.voices[i].start && currentTime <= this.voices[i].end) {
          return i;
        }
      }
      return null;
    },
    handlingLoadingScript() {
      for(let i = 0; i <= this.voices.length - 1; i++){
        if(this.voices[i].actionState == 'loading') {
          this.handleVoiceActionState(i)
        }
      }
    },
    handleVoiceActionState(index) {
      if (
        this.voices[index].src
        && this.voices[index].text === this.voices[index].generateText
        && this.voices[index].voiceType.character.name === this.voices[index].generateSpeaker
      ) {
        this.voices[index].actionState = 'play';
      } else {
        this.voices[index].actionState = 'generate';
      }
    },
    handlePreviewTrimStockVideo(data) {
      this.choosedVideoStock = {
					id: data._id,
					src : data.url,
					video_thumbnail : data.video_thumbnail,
					duration: data.duration,
					name : data.video_name
				}
				this.$bvModal.show('vtp-modal-library')
    },
    handleTrimVideo(val) {
			this.isTrim = true
			if(val.isTrim) {
				let formData = {
					id_video : this.choosedVideoStock.id,
					start : val.durationStartCalculate || 0,
					end : val.durationEnd,
					save_to_asset : "False",
					from_stock : "True"
				}

				trimVideos(formData)
				.then((response) => {
					if(response.status == 200) {
						let usedVideo = {
							type: 'video',
							id: response.data.id_video,
							name: this.choosedVideoStock.name ? this.choosedVideoStock.name : 'Video',
							src: this.choosedVideoStock.video_thumbnail,
							videoDurationOrigin: val.durationEnd - val.durationStartCalculate || 0
						}
						this.handleAddImage(usedVideo)
						this.$bvModal.hide('vtp-modal-library')
					}
					this.isTrim = false
				})
			} else {
				let usedVideo = {
					type: 'video',
					id: this.choosedVideoStock.id,
					name: this.choosedVideoStock.name ? this.choosedVideoStock.name : 'Video',
					src: this.choosedVideoStock.video_thumbnail,
					videoDurationOrigin: val.durationEnd - val.durationStartCalculate || 0
				}
				this.isTrim = false
				this.handleAddImage(usedVideo)
				this.$bvModal.hide('vtp-modal-library')
			}
		}
  },
  async mounted() {
    document.documentElement.classList.add('gv--overflow-y-hidden');
    document.body.classList.add('gv--overflow-y-hidden');
    await this.isGetDataFirst();
    await this.handleSelectCharacterFromTemplate();
    await this.handleSelectVoiceFromTemplate();
    await this.handleImportFile()
    await this.handleUseVideoFromAsset();
    await this.handlingLoadingScript()
    this.getAllocQuota()
    this.checkStatusApi()
    this.checkTakeTourVideoUser()
  },
  beforeDestroy() {
    document.documentElement.classList.remove('gv--overflow-y-hidden');
    document.body.classList.remove('gv--overflow-y-hidden');
  }
};
</script>

<style scoped>
.gv {
  height: 100vh;
}

.gv__main {
  display: contents;
}

.gv__middle {
  min-height: 0;
}

.overlay--gv {
  z-index: 2000;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(3px);
  position: absolute;
}
</style>

<style>
.gv--overflow-y-hidden {
  overflow-y: hidden;
}
</style>
