<template>
  <section class="taiov d-flex flex-column px-5 py-3 text-left">
    <header class="d-flex justify-content-between">
      <TextEditorToolbarInput :editor="editor" />
      <div class="ml-auto">
        <QuestionOptions
          v-model="question"
          @delete-question="$emit('delete-question')"
        />
      </div>
    </header>

    <div class="taiov__question">
      <TextEditorInput
        v-model="question.question"
        placeholder="Type in your question"
        title="Question"
        @get-editor="(newEditor) => editor = newEditor"
        @focus="(editor) => $emit('focus-editor', editor)"
      />
    </div>

    <AnswerInputWrapper :totalAnswers="4">
      <template #default="{ answerNumber }">
        <AnswerImageInput
          v-model="question[`option_${answerNumber}`]"
          :correct="(answerNumber).toString() === question.correct_answer"
          :answer-number="answerNumber"
        />
        <AnswerCorrectInput
          v-model="question.correct_answer"
          :answer-number="answerNumber"
        />
      </template>
    </AnswerInputWrapper>
  </section>
</template>

<script>
import QuestionOptions from './QuestionOptions';
import TextEditorInput from './inputs/TextEditorInput';
import TextEditorToolbarInput from './inputs/TextEditorToolbarInput';
import AnswerInputWrapper from './inputs/AnswerInputWrapper';
import AnswerImageInput from './inputs/AnswerImageInput';
import AnswerCorrectInput from './inputs/AnswerCorrectInput';

export default {
  components: {
    QuestionOptions,
    TextEditorInput,
    TextEditorToolbarInput,
    AnswerInputWrapper,
    AnswerImageInput,
    AnswerCorrectInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['input', 'delete-question'],
  data() {
    return {
      question: this.value,
      editor: null,
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        this.$emit('input', newQuestion);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.question = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.taiov {
  gap: 16px;
  max-height: 100%;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.taiov__question {
  height: 300px;
  overflow-y: auto;
}
</style>
