import { Extension } from '@tiptap/core';

export default Extension.create({
  name: 'fullscreenStorage',
  addStorage() {
    return { 
      isFullscreen: true,
      generatePreviewSSML: null,
    };
  },
});
