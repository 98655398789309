export default async function customFetch(url, options) {
  let token = null;

  if (localStorage.getItem('users')) {
    const users = await JSON.parse(localStorage.getItem('users'));

    if (users.token) {
      token = users.token;
    } else {
      token = localStorage.getItem('token_client') ? localStorage.getItem('token_client') : null;
    }
  } else if (localStorage.getItem('token_client')) {
    token = localStorage.getItem('token_client');
  }

  let headers = {};

  if (token !== null) {
    headers.id_client = token;
    headers.token_client = token;
  }

  return fetch(url, {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  });
}
