<template>
        <b-modal id="cct-modal" no-close-on-backdrop centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" modal-class="cr__modal" content-class="cr__content" dialog-class="cr__dialog d-flex justify-content-center" size="lg">
			<div class="container-fluid">
				<div class="d-flex justify-content-end mb-2">
					<button
						class="btn button--cr__close d-flex align-items-center justify-content-center"
						@click="$bvModal.hide('cct-modal'), $emit('hidden:cct-closed')"
					>
						<font-awesome-icon icon="fa-solid fa-xmark"/>
					</button>
				</div>
				<div class="cr--main d-flex flex-column px-5">
					<h4 class="cr--title text-center mb-3">{{ $t('choose-cloning-type') }}</h4>
					<div class="cr--list-role d-flex flex-column px-4">
						<div v-for="(role, index) in roles" :key="index" class="cr--role__card cursor-pointer" :class="{ 'cr--role__card--selected' : selected == role.type }" @click="handleChooseRole(role.type)">
							<div class="cr--role__card-inner px-3 pt-4" :class="index == 2 ? 'pb-4' : 'pb-2'">
								<div class="container-fluid">
									<div class="row align-items-start">
										<div class="col-10 pl-3">
											<div class="cr--role__main d-flex flex-column">
												<h4 class="cr--role__title">{{ role.title }}</h4>
												<p class="cr--role__description m-0" v-html="$t(role.description)"></p>
												<p v-if="role.label" class="cr--role__label mb-0 mt-2" v-html="$t(role.label)"></p>
											</div>
										</div>
										<div class="col-2 d-flex align-items-center justify-content-center">
											<img :src="role.image" :alt="role.title">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="d-flex justify-content-center mb-3">
						<button :disabled="!selected" type="button" class="cr--button btn" @click="$emit('click:next')">Next</button>
					</div>
				</div>
			</div>
        </b-modal>
</template>

<script>
export default {
	props: {
		selected: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			roles: [
				{
					image: require('@/assets/images/icons/ic_cct_1.svg'),
					title: 'Monolingual Voice Cloning',
					description: 'monolingual-voice-cloning--p',
					label: 'monolingual-voice-cloning--note',
					type: 'monolingual'
				},
				{
					image: require('@/assets/images/icons/ic_cct_2.svg'),
					title: 'Multilingual Voice Cloning',
					description: 'multilingual-voice-cloning--p',
					label: 'multilingual-voice-cloning--note',
					type: 'multilingual'
				},
				{
					image: require('@/assets/images/icons/ic_cct_3.svg'),
					title: 'Advanced Voice Cloning',
					description: 'advanced-voice-cloning--p',
					type: 0,
				}
			]
		}
	},

	methods: {
		handleChooseRole(type) {
			if(type) {
				this.$emit('click:change-selected-clone-type', type)
			} else {
				window.open('https://forms.gle/3UBqnUchrhxmXTUW7', '_blank')
			}
		}
	}
}
</script>

<style scoped>
.button--cr__close {
	width: 27px;
	height: 27px;
	border-radius: 50%;
	background-color: #fff;
	color: black;
}

.cr--main {
	gap: 14px;
}

.cr--list-role {
	gap: 24px;
}

.cr--title {
	color: #FFF;
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.cr--role__card {
	border-radius: 10px;
	background: #2D2D2D;
	border: 1px solid #2D2D2D;

	transition: border 0.25s;
}

.cr--role__card--selected {
	border: 1px solid #6D75F6;
}

.cr--role__main {
	gap: 4px;
}

.cr--role__title {
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.cr--role__description {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.cr--role__label {
	color: #8C8C8C;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.cr--button {
	background-color: #6D75F6;
    padding: 5px 28px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;

    transition: background-color 0.25s;
}

.cr--button:not(:disabled):hover {
	background-color: #545EED;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#cct-modal .modal-content{
    border-radius: 12px !important;
    background-color: #1f1f1f;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

.cr__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.cr__dialog {
	max-width: none;
}

.cr__content {
	width: 740px !important;
}
</style>
