import { Extension } from '@tiptap/core';

export default Extension.create({
  name: 'disableEnter',
  addKeyboardShortcuts() {
    return {
      Enter: () => true,
    };
  },
});
