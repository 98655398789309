<template>
  <div class="text-color-popover">
    <div class="text-color-popover__colors">
      <ul
        v-for="colors, title in items"
        :key="title"
        class="text-color-popover__colors-list"
      >
        <li
          v-for="hex, index in colors"
          :key="hex"
          class="text-color-popover__colors-item"
        >
          <button
            type="button"
            class="text-color-popover__colors-button"
            :class="{ 'text-color-popover__colors-button--active': hex === color }"
            :style="{ backgroundColor: hex }"
            :title="`${title} #${index}`"
            @click="() => { value = hex; $emit('hidePopover') }"
          />
        </li>
      </ul>
    </div>
    <div class="text-color-popover__actions">
      <label
        tabindex="0"
        class="text-color-popover__action-pick"
        title="Pick Color"
      >
        <input
          type="color" 
          class="text-color-popover__action-pick-input"
          v-model="value"
        >
        <span class="text-color-popover__actiion-pick-text">
          <font-awesome-icon icon="fa-solid fa-eye-dropper" />
          Pick Color
        </span>
      </label>
      <button
        type="button"
        class="text-color-popover__action-unset"
        title="Unset Color"
        @click="() => { onUnset(); $emit('hidePopover') }"
      >
        <font-awesome-icon icon="fa-solid fa-link-slash" />
        Unset Color
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    onSet: {
      type: Function,
      required: true,
    },
    onUnset: {
      type: Function,
      required: true,
    },
  },
  emits: ['hidePopover'],
  data() {
    return {
      value: this.color,
      items: {
        'Grayscale': [
          '#ffffff',
          '#e1e1e1',
          '#c8c8c8',
          '#afafaf',
          '#969696',
          '#7d7d7d',
          '#646464',
          '#4b4b4b',
          '#323232',
          '#191919',
          '#000000',
        ],
        'Red': [
          '#ff7d7d',
          '#ff6464',
          '#ff4b4b',
          '#ff3232',
          '#ff1919',
          '#ff0000',
          '#e60000',
          '#cd0000',
          '#b40000',
          '#9b0000',
          '#820000',
        ],
        'Orange': [
          '#ffb27d',
          '#ffa364',
          '#ff944b',
          '#ff8532',
          '#ff7519',
          '#ff6600',
          '#e65c00',
          '#cd5200',
          '#b44800',
          '#9b3e00',
          '#823400',
        ],
        'Yellow': [
          '#ffff7d',
          '#ffff64',
          '#ffff4b',
          '#ffff32',
          '#ffff19',
          '#ffff00',
          '#e6e600',
          '#cdcd00',
          '#b4b400',
          '#9b9b00',
          '#828200',
        ],
        'Green': [
          '#b3ffb3',
          '#80ff80',
          '#66ff66',
          '#4dff4d',
          '#1aff1a',
          '#00e600',
          '#00cc00',
          '#00b300',
          '#008000',
          '#004d00',
          '#003300',
        ],
        'Blue': [
          '#b3d1ff',
          '#80b3ff',
          '#6699ff',
          '#4d88ff',
          '#3377ff',
          '#0055ff',
          '#0000ff',
          '#004de6',
          '#003cb3',
          '#002b80',
          '#001a4d',
        ],
        'Cyan': [
          '#b3ffff',
          '#80ffff',
          '#4dffff',
          '#33ffff',
          '#1affff',
          '#00ffff',
          '#00cccc',
          '#009999',
          '#006666',
          '#004d4d',
          '#003333',
        ],
        'Purple': [
          '#df9fdf',
          '#d98cd9',
          '#d279d2',
          '#cc66cc',
          '#c653c6',
          '#bf40bf',
          '#ac39ac',
          '#993399',
          '#660066',
          '#602060',
          '#4d004d',
        ],
        'Pink': [
          '#ff99c2',
          '#ff66a3',
          '#ff4d94',
          '#ff3385',
          '#ff1a75',
          '#ff0066',
          '#e6005c',
          '#cc0052',
          '#b30047',
          '#800033',
          '#660029',
        ],
      },
    };
  },
  watch: {
    value(newValue) {
      this.onSet(newValue);
    },
  },
};
</script>

<style scoped>
.text-color-popover {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text-color-popover__colors {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.text-color-popover__colors-list {
  display: flex;
  gap: 0.25rem;
  list-style: none;
}

.text-color-popover__colors-item {
  line-height: 1;
}

.text-color-popover__colors-button {
  width: 1rem;
  height: 1rem;
  border: none;
  border-radius: 50%;
  outline: 2px solid transparent;
  transition: outline 0.25s;
}

.text-color-popover__colors-button--active {
  outline: 2px solid #6d75f6;
}

.text-color-popover__actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.text-color-popover__action-pick, .text-color-popover__action-unset {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin: 0;
  color: #fff;
  font-size: 0.75rem;
  border: 1px solid #6d75f6;
  border-radius: 0.25rem;
  outline: 2px solid transparent;
  outline-offset: 1px;
  cursor: pointer;
  transition: outline 0.25s;
}

:is(.text-color-popover__action-pick, .text-color-popover__action-unset):focus {
  outline: 2px solid rgba(109, 117, 246, 0.5);
}

.text-color-popover__action-pick {
  position: relative;
  background-color: #6d75f6;
}

.text-color-popover__action-unset {
  background-color: transparent;
}

.text-color-popover__action-pick-input {
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  visibility: hidden;
}

.text-color-popover__action-pick-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
</style>
