<template>
  <section class="rvi d-flex flex-column px-5 py-4">
    <h1 class="rvi__title mb-4 text-center">Instructions</h1>
    <p class="rvi__paragraph">
      Let adventure started by creating the dataset needed to make your voice clone. We will prompt you with sentences that you must record yourself reading. For each sentence, press the “Record” button, read the sentence out loud, and then hit the “Record” button again to stop the recording.
    </p>
    <p class="rvi__paragraph">
      The quality of your voice clone depends on the quality of your data. Please listen to short test recordings to make sure it sounds clear and in the tone that you want your voice clone to sound like. You can revisit these instructions at any point during the recording process by pressing the “Instructions” button at the top right corner.
    </p>
    <button
      type="button"
      class="rvi__continue-button align-self-center btn px-5 py-2 mt-5 mb-3 text-white"
      title="Continue"
      @click="$emit('click:continue')"
    >
      Continue
    </button>
  </section>
</template>

<script>
export default {
  emits: ['click:continue'],
};
</script>

<style scoped>
.rvi {
  width: min(725px, 95%);
  background-color: #1F1F1F;
  border-radius: 8px;
}

.rvi__title {
  font-size: 30px;
  font-weight: 600;
}

.rvi__paragraph {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.6;
}

.rvi__paragraph + .rvi__paragraph {
  margin-top: 24px;
}

.rvi__continue-button {
  background-color: #6D75F6;
  border-radius: 6px;
  font-weight: 600;
  transition: background-color 0.25s;
}

.rvi__continue-button:hover {
  background-color: #545EED;
}
</style>
