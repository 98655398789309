
export const exportUtils = {
    methods: {
        exportData(data, filename) {
            console.log(filename)
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            var blob = new Blob([data], {
                type: 'octet/stream'
            });

            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${filename}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        exportDataPdf(data, filename) {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            var blob = new Blob([data], {
                type: 'octet/stream'
            });

            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${filename}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        exportDataCorupt(data, filename) {
            const blob = new Blob(
                [data], { type: 'octet/stream' })
            const aEle = document.createElement('a');
            const href = window.URL.createObjectURL(blob);
            aEle.href = href;
            aEle.download = filename + '.xlsx';
            document.body.appendChild(aEle);
            aEle.click();
            document.body.removeChild(aEle);
            window.URL.revokeObjectURL(href)
        },
        exportAudio(data, filename){
            // membuat URL objek dari blob response
            const url = URL.createObjectURL(data);

            // membuat anchor link untuk mendownload file
            const link = document.createElement('a');

            link.style = "display: none";
            link.href = url;
            link.download = filename + '.mp3';

            // menambahkan link ke halaman dan mengkliknya secara otomatis
            document.body.appendChild(link);
            link.click();

            // menghapus objek URL dari memory
            URL.revokeObjectURL(url);
        },
        exportVidio(data, filename){
            // membuat URL objek dari blob response
            const url = URL.createObjectURL(data);

            // membuat anchor link untuk mendownload file
            const link = document.createElement('a');

            link.style = "display: none";
            link.href = url;
            link.download = filename + '.mp4';

            // menambahkan link ke halaman dan mengkliknya secara otomatis
            document.body.appendChild(link);
            link.click();

            // menghapus objek URL dari memory
            URL.revokeObjectURL(url);
        },
        exportImage(data, filename) {
            const url = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.style = "display: none";
            link.href = url;
            link.download = filename + '.jpg';
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
        }
    }
}
