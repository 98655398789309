<template>
  <section class="tfv d-flex flex-column text-left" :class="isGenerate ? 'position-relative' : 'px-5 py-3'">
    <header class="d-flex justify-content-between">
      <div class="ml-auto">
        <QuestionOptions
          withoutPoint
          v-model="question"
          @delete-question="$emit('delete-question')"
        />
      </div>
    </header>

    <div class="bpi__wrapper" :class="{ 'bpi__wrapper-wt' : question.text != null }">
      <BlankPageInput :value-title="question.title" :value-subtitle="question.subtitle" withToolbar withSubTitle placeholder="Title" title="Blank" @update:title="(val) => question.title = val" @update:subtitle="(val) => question.subtitle = val"/>
    </div>

    <div v-if="question.text != null" class="tei__wrapper">
      <TextEditorInput withSubTitle v-model="question.text" withToolbar withDelete placeholder="Type in your text" title="Blank" @delete-text="$emit('delete-text')"/>
    </div>

    <Soca v-if="isGenerate" type="generate"/>
  </section>
</template>

<script>
import TextEditorInput from './inputs/TextEditorInput';
import BlankPageInput from './inputs/BlankPageInput';
import QuestionOptions from './QuestionOptions';

import Soca from '@/components/loading/Soca'

export default {
  components: {
    TextEditorInput,
    BlankPageInput,
    QuestionOptions,
    Soca
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    type: {
      validator(value) {
        return ['default', 'thumbnail'].includes(value);
      },
      default: 'default',
    },
  },
  emits: ['input', 'delete-question', 'focus-editor'],
  data() {
    return {
      question: this.value,
      editor: null,
    };
  },
  watch: {
    question: {
      handler(question) {
        this.$emit('input', question);
      },
      deep: true,
    },
    value: {
      handler(value) {
        this.question = value;
      },
      deep: true,
    },
  },
  methods: {
    handleEditorFocus(editor) {
      this.$emit('focus-editor', editor);
      this.editor = editor.editor;
    }
  }
};
</script>

<style scoped>
.tfv {
  gap: 16px;
  height: 100%;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.tfv__question {
  gap: 16px;
  height: 300px;
  overflow-y: auto;
  z-index: 1
}

.tfv__question > * {
  flex-grow: 1;
}

.tfv__question > *:nth-child(2) {
  max-width: 60%;
}

.tfv__answers-title {
  color: #8C8C8C;
  border-bottom: 1px solid #2D2D2D;
}

.bpi__wrapper {
  height: 100%;
}

.bpi__wrapper-wt, .tei__wrapper {
  height: 45%;
}
</style>
