<template>
    <div>
        <b-modal id="tfumb-modal" content-class="tfumb__content" dialog-class="tfumb__dialog d-flex justify-content-center" modal-class="tfumb__modal" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
			<div class="tfumb p-2">
				<div class="float-right">
					<button class="btn btn-close-m d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('tfumb-modal')"><img src="@/assets/images/icons/ic_close.svg" alt="" width="16"></button>
				</div>
				<div class="text-center mt-5">
					<h3 class="tfumb__title">Syarat & Ketentuan - Merdeka Bersuara</h3>
				</div>
				<div class="tfumb__inner px-3 mb-3 mt-4">
					<p class="tfumb__pharagraph mb-3">
						"Merdeka Bersuara" adalah event yang diinisiasi oleh SOCA. event ini bertujuan untuk merayakan kreativitas dan
						kemerdekaan berbicara melalui penciptaan konten dengan penggunaan teknologi voice AI. Dalam rangka
						memastikan partisipasi yang aman dan bertanggung jawab, kami telah menetapkan sejumlah ketentuan berikut.
						<br>
						<br>
						Dengan bergabung dan mengikuti event ini, Anda dianggap telah menyetujui syarat dan
						ketentuan berikut:
					</p>

					<p class="tfumb__pharagraph font-weight-bold">1. Konten yang Dilarang</p>

					<p class="tfumb__pharagraph mb-3">
						Peserta dilarang membuat konten yang mengandung unsur berikut:
					</p>

					<ul class="tfumb__list-upper-alpha pl-4 mb-3">
						<li>Bahasa kasar, vulgar, atau tidak sopan.</li>
						<li>Konten yang mengandung pornografi atau materi dewasa yang tidak sesuai dengan etika umum.</li>
						<li>Unsur kekerasan, ancaman, atau mendorong tindakan berbahaya.</li>
						<li>Konten yang mengandung atau mendukung pandangan politik atau isu politik tertentu.</li>
						<li>Materi yang menyinggung, menghina, atau merugikan pihak lain berdasarkan suku, agama, ras, gender, atau faktor identitas lainnya.</li>
					</ul>

					<p class="tfumb__pharagraph font-weight-bold">2. Kewajiban Peserta</p>

					<p class="tfumb__pharagraph mb-3">
						Peserta bertanggung jawab sepenuhnya atas konten yang mereka hasilkan selama acara "Merdeka Bersuara." Setiap peserta harus memastikan bahwa konten yang mereka buat tidak melanggar ketentuan yang disebutkan di atas. Peserta juga harus menjamin bahwa konten yang dihasilkan adalah karya asli mereka sendiri dan tidak melanggar hak kekayaan intelektual atau hak cipta milik pihak lain.
					</p>

					<p class="tfumb__pharagraph font-weight-bold">3.Penggunaan Teknologi AI Voice</p>

					<p class="tfumb__pharagraph mb-3">
						Peserta diharapkan menggunakan teknologi AI voice yang disediakan oleh SOCA AI dengan bijaksana dan sesuai dengan tujuan acara. Manipulasi atau pemalsuan konten menggunakan teknologi ini untuk tujuan merugikan atau menyesatkan tidak diperbolehkan.
					</p>

					<p class="tfumb__pharagraph font-weight-bold">4. Pengawasan Konten</p>

					<p class="tfumb__pharagraph mb-3">
						Tim penyelenggara "Merdeka Bersuara" berhak untuk memantau dan meninjau konten yang dihasilkan oleh peserta. Jika terdapat pelanggaran terhadap ketentuan di atas, tim penyelenggara berhak untuk mengambil tindakan, termasuk tetapi tidak terbatas pada penghapusan konten atau diskualifikasi peserta.
					</p>

					<p class="tfumb__pharagraph font-weight-bold">5. Hak Cipta dan Lisensi</p>

					<p class="tfumb__pharagraph mb-3">
						Peserta tetap memegang hak cipta penuh atas konten yang mereka hasilkan selama acara ini. Namun, dengan mengikuti acara "Merdeka Bersuara," peserta memberikan izin kepada SOCA AI dan tim penyelenggara untuk menggunakan, membagikan, dan mempromosikan konten tersebut dalam kaitannya dengan acara ini, tanpa kompensasi tambahan.
					</p>

					<p class="tfumb__pharagraph font-weight-bold">6. Tanggung Jawab Hukum</p>

					<p class="tfumb__pharagraph mb-3">
						Peserta bertanggung jawab penuh atas setiap konten yang mereka hasilkan selama acara "Merdeka Bersuara." Peserta dengan ini menyatakan bahwa konten mereka bebas dari klaim, gugatan, atau tuntutan dari pihak ketiga yang mungkin timbul akibat dari konten yang dibuat.
					</p>

					<p class="tfumb__pharagraph font-weight-bold">7. Perubahan Ketentuan</p>

					<p class="tfumb__pharagraph mb-3">
						Tim penyelenggara berhak untuk memperbaharui atau mengubah ketentuan ini sesuai kebijakannya. Peserta akan diberitahu tentang perubahan tersebut jika terjadi.
					</p>

					<p class="tfumb__pharagraph">Dengan mengikuti acara "Merdeka Bersuara" di platform SOCA AI, Anda menyatakan telah membaca, memahami, dan menyetujui ketentuan di atas. Jika Anda tidak setuju dengan ketentuan ini, kami mohon untuk tidak mengikuti acara ini. Mari kita berpartisipasi dengan penuh rasa hormat, menghormati hak-hak orang lain, dan merayakan keberagaman ide-ide kreatif dalam semangat merdeka bersuara!</p>
				</div>
			</div>
        </b-modal>
    </div>
</template>
<script>

export default {

}

</script>

<style scoped>
.btn-close-m {
  background-color: #ededed;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 0;
}

.tfumb__title {
	font-size: 30px;
}

.tfumb__inner {
	height: 430px;
	overflow-y: auto;
	padding-right: 10px;
}

.tfumb__pharagraph, .tfumb__list-upper-alpha li {
	font-size: 20px;
}

.tfumb__list-upper-alpha {
	list-style-type: upper-alpha;
}

.tfumb__inner::-webkit-scrollbar {
	width: 8px;
}

.tfumb__inner::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #585859;
}

.tfumb__inner::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background: #8C8C8C;

	transition: background 0.25s;
}

.tfumb__inner::-webkit-scrollbar-thumb:hover {
	background: #888;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#tfumb-modal .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#tfumb-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#tfumb-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

.btn-purple {
  background-color: #6d75f6;
  border: 1px solid #6d75f6;
  color: white;
  box-shadow: none !important;
}

button {
  box-shadow: none !important;
}

.tfumb__dialog {
	max-width: none;
}

.tfumb__content {
	width: 1000px;
}

.tfumb__modal + .modal-backdrop {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
  	backdrop-filter: blur(2px);
	color: white;
}
</style>