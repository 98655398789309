<template>
  <section class="lg d-flex flex-column">
    <header class="lg__header">
      <ul class="lg__tab-list d-flex pl-4 pr-3 m-0 list-unstyled">
        <li v-for="(tab, index) in tabs" :key="index">
          <button
            type="button"
            class="lg__tab-button btn px-0 py-2 rounded-0"
            :class="{ 'lg__tab-button--active': index === activeTabIndex }"
            :title="tab.title"
            @click="handleClickTab(index)"
          >
            {{ tab.title }}
          </button>
        </li>
      </ul>
    </header>
    <keep-alive>
      <component
        :is="tabs[activeTabIndex].component"
        :mode="mode"
        @change:mode="(val) => $emit('change:mode', val)"
        v-bind="tabs[activeTabIndex].props"
        v-on="tabs[activeTabIndex].events"
      />
    </keep-alive>
  </section>
</template>

<script>
import VideosGroup from './VideosGroup';
import TemplatesGroup from './TemplatesGroup';

export default {
  props: {
    character: {
      type: Object,
      default: null,
    },
    mode: {
      validator(value) {
        return ['voice-only', 'ai-characters'].includes(value);
      }
    },
  },
  emits: ['change:character'],
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  computed: {
    tabs() {
      return [
        {
          title: this.$t('stock-videos'),
          component: VideosGroup,
          props: {},
          events: {
            'use:video' : (val) => this.$emit('use:video', val),
            'click:preview-trim-stock-video' : (val) => this.$emit('click:preview-trim-stock-video', val)
          },
        },
        {
          title: this.$t('my-assets'),
          component: TemplatesGroup,
          props: {},
          events: {},
        },
      ];
    },
  },
  methods: {
    handleClickTab(index) {
      if(index == 0) {
        this.activeTabIndex = index
      } else {
        this.$bvModal.show('my-assets-modal')
      }
    }
  }
};
</script>

<style scoped>
.lg {
  gap: 16px;
  width: 100%;
  max-height: 100%;
}

.lg__header {
  border-bottom: 1px solid #2D2D2D;
}

.lg__tab-list {
  gap: 16px;
}

.lg__tab-button {
  background: none;
  border-bottom: 2px solid transparent;
  color: #FFFFFF;
  font-size: 12px;
  transition: border-color 0.25s, color 0.25s, font-weight 0.25s;
}

.lg__tab-button--active {
  border-bottom-color: #6D75F6;
  color: #6D75F6;
  font-weight: 600;
}
</style>
