<template>
  <section class="rc d-flex flex-column">
    <header class="rc__header px-5">
      <ul class="rc__tab-list d-flex justify-content-end m-0 list-unstyled">
        <li v-for="(tab, index) in tabs" :key="index">
          <button
            type="button"
            class="rc__tab-button btn text-white"
            :class="{ 'rc__tab-button--active': activeTabIndex === index }"
            :title="tab.title"
            @click="activeTabIndex = index"
            :disabled="disabledAction"
          >
            {{ tab.title }}
          </button>
        </li>
      </ul>
    </header>

    <section class="rc__main d-flex flex-column">
      <CharacterList
        v-model="selectedCharacter"
        :characters="reversedSelectedCharacters"
        :key="activeTabIndex"
        @click:upload="handleClickUpload"
        :disabled-action="disabledAnyAction"
      />
    </section>
  </section>
</template>

<script>
import { base_url_machine_learning } from '@/config/base_url';
import { get3DAvatars, getHumanAvatars } from '@/services/ttv/ttv.service.js';

import CharacterList from './CharacterList';

export default {
  components: {
    CharacterList,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedCharacter: this.value,
      disabledAnyAction: this.disabledAction,
      tabs: [
        {
          title: '3D',
          characters: [],
        },
        {
          title: 'Human',
          characters: [],
        },
      ],
      activeTabIndex: 0,
    };
  },
  computed: {
    reversedSelectedCharacters() {
      const characters = [...this.tabs[this.activeTabIndex].characters];
      return characters.reverse();
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.selectedCharacter = newValue;
      },
      deep: true,
    },
    selectedCharacter: {
      handler(newSelectedCharacter) {
        this.$emit('input', newSelectedCharacter);
      },
      deep: true,
    },
    disabledAction(newVal) {
      this.disabledAnyAction = newVal
    }
  },
  methods: {
    handleClickUpload() {
      const newId = this.tabs[this.activeTabIndex].characters.length + 1;

      if (this.activeTabIndex === 0) {
        this.tabs[this.activeTabIndex].characters.push({
          name: `3D ${newId}`,
          imageSrc: require('@/assets/images/placeholders/ttv_regular_character.png'),
        });
      } else {
        this.tabs[this.activeTabIndex].characters.push({
          name: `Human ${newId}`,
          imageSrc: `https://i.pravatar.cc/150?img=${newId}`,
        });
      }
    },
    async populate3DAvatars() {
      const { data: avatars } = await get3DAvatars();

      for (const avatar of avatars) {
        this.tabs[0].characters.push({
          name: avatar.name,
          imageSrc: base_url_machine_learning + avatar.path,
          type: 'regular',
          id: avatar._id.$oid
        });
      }
    },
    async populateHumanAvatars() {
      const { data: avatars } = await getHumanAvatars();

      for (const avatar of avatars) {
        this.tabs[1].characters.push({
          name: avatar.name,
          imageSrc: base_url_machine_learning + avatar.path,
          type: 'regular',
          id: avatar._id.$oid
        });
      }
    },
  },
  async mounted() {
    await this.populate3DAvatars();
    await this.populateHumanAvatars();
  },
};
</script>

<style scoped>
.rc {
  gap: 16px;
  min-height: 0;
  overflow-y: auto;
}

.rc__tab-list {
  gap: 8px;
}

.rc__tab-button {
  padding: 2px 12px;
  background-color: #1F1F1F;
  border-radius: 16px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.rc__tab-button:not(.rc__tab-button--active):hover {
  background-color: #2F2F2F;
}

.rc__tab-button--active {
  background-color: #6D75F6;
}

.rc__main {
  min-height: 0;
}
</style>
