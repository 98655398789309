<template>
  <b-modal
    :id="id"
    size="lg"
    centered
    hide-header
    hide-footer
    dialog-class="svm__dialog d-flex justify-content-center"
    content-class="svm__content"
    @show="handleShowModal"
    @shown="handleShownModal"
    @hide="handleHideModal"
    @hidden="$emit('hidden')"
  >
    <div class="container-svm">
      <div class="separator-bottom">
        <div class="container-fluid mt-2 mb-4">
          <div class="d-flex align-items-center w-100">
            <div>
              <h4>{{ $t('select-a') }} Voice</h4>
              <small class="text-nowrap">{{ $t('select-voices--note') }}</small>
            </div>
            <!-- <label for="search" class="w-100 mr-4 ml-5 mb-0">
              <input
                id="search"
                type="text"
                class="search-bar-voice text-white"
                placeholder="Find a voice name"
              >
            </label> -->
            <button
              class="btn ml-auto btn-close d-flex justify-content-center align-items-center close-image"
              @click="$bvModal.hide(id)"
            >
              <b-icon icon="x" />
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid" style="height: 30rem">
        <div class="row h-100 px-3">
          <div class="col-3 d-flex flex-column pt-3" style="border-right: 0.1px solid #2D2D2D">
            <div class="dropdown mr-4">
              <button
                :id="`${_uid}__choose-lang`"
                ref='buttonLang'
                class="btn btn-lang d-flex align-items-center"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center justify-content-between px-3 w-100">
                  <div class="d-flex">
                    <img
                      :src="internalSelectedLanguage?.flagSrc"
                      class="img-fluid"
                      style="width: 18px; height: 18px"
                    />
                    <span
                      class="ml-2 mr-1"
                      style="font-size: 15px !important"
                    >
                      {{ internalSelectedLanguage?.name }}
                    </span>
                  </div>
                  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L4 4L7 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </button>
              <div class="dropdown-menu mt-2 w-100" aria-labelledby="`${_uid}__choose-lang`">
                <div v-if="!loadingLanguages" class="px-1">
                  <div class="select-lang overflow-auto px-1 pt-0">
                    <button
                      v-for="(country) in sortedCountries"
                      class="btn btn-transparent px-3 d-block w-100"
                      :key="country.code + country.name"
                      @click="internalSelectedLanguage = country; isPersonalVoiceSectionActive = false"
                    >
                      <div class="d-flex align-items-center">
                        <img
                          :src="country.flagSrc"
                          class="img-fluid"
                          style="width: 18px; height: 18px"
                        />
                        <span
                          class="ml-2 text-white text-left"
                          style="font-size: 15px !important"
                        >
                          {{ country.name }}
                        </span>
                        <img src="@/assets/images/icons/ic_news.svg" alt="" class="ml-2" v-if="country.name == 'Multilingual'">
                      </div>
                    </button>
                  </div>
                </div>
                <div v-else class="px-1">
                  <div class="select-lang overflow-auto px-1 pt-0">
                    <button
                      v-for="index in 15"
                      class="btn btn-transparent px-3 d-block mr-0 w-100"
                      :key="index"
                    >
                      <div class="d-flex align-items-center w-100">
                        <b-skeleton type="avatar" />
                        <b-skeleton animation="wave" width="100%" class="ml-2" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2 mr-4">
              <button
                class="btn text-white w-100 mr-1"
                :style="selectedGender === 'Male' ? 'background-color: #6D75F6' : 'background-color: #2d2d2d'"
                :disabled="isPersonalVoiceSectionActive"
                @click="selectedGender = selectedGender === 'Male' ? null : 'Male'"
              >
                {{ $t('man') }}
              </button>
              <button
                class="btn text-white w-100 ml-1"
                :style="selectedGender === 'Female' ? 'background-color: #6D75F6' : 'background-color: #2d2d2d'"
                :disabled="isPersonalVoiceSectionActive"
                @click="selectedGender = selectedGender === 'Female' ? null : 'Female'"
              >
              {{ $t('woman') }}
              </button>
            </div>
            <button
              type="button"
              title="Use Personal Voice"
              class="pv__button btn mt-auto px-3 py-2 mr-4"
              :class="{ 'pv__button--active': isPersonalVoiceSectionActive }"
              @click="isPersonalVoiceSectionActive = !isPersonalVoiceSectionActive"
            >
              {{ $t('personal-voice') }}
            </button>
          </div>
          <div class="col-9 pt-3 h-100 overflow-auto">
            <div v-if="isPersonalVoiceSectionActive" class="pv__section d-flex flex-column">
              <div v-if="personalVoicesApi.length < 1" class="pv__empty-section d-flex flex-column justify-content-center align-items-center h-100">
                <p class="pv__empty-message m-0 text-center" v-html="$t('clone-new--p')"></p>
                <button
                  @click="$bvModal.show('cct-modal')"
                  class="pv__clone-button btn rounded-sm"
                  title="Clone New"
                >
                  {{ $t('clone-new') }}
                </button>
              </div>
              <div v-else class="pv__exist-section d-flex flex-column px-4 py-2">
                <h3 class="pv__title m-0">{{ $t('ur-personal-voice') }}</h3>
                <ul class="pv__voice-list d-flex flex-column m-0 list-unstyled overflow-auto">
                  <li v-for="voice in personalVoicesApi" :key="voice.character.id">
                    <button
                      type="button"
                      class="pv__voice-button btn d-flex align-items-center p-3 rounded-lg"
                      :class="{ 'pv__voice-button--selected': selectedPersonalVoice?.character.id === voice.character.id }"
                      :title="`Select ${voice.character.name}`"
                      @click="selectedPersonalVoice = voice"
                    >
                      <div
                        class="pv__voice-button-checkbox d-inline-flex justify-content-center align-items-center rounded-circle"
                        :class="{ 'pv__voice-button-checkbox--checked': selectedPersonalVoice?.character.id === voice.character.id }"
                      >
                        <font-awesome-icon
                          v-if="selectedPersonalVoice?.character.id === voice.character.id"
                          icon="fa-solid fa-check"
                        />
                      </div>
                      <AudioPlayer
                        :src="voice.character.sampleSrc"
                        :name="voice.character.name"
                        :type="voice.character.type"
                        size="small"
                        class="flex-grow-1"
                        @play="(pauseAudio, audioId) => handlePlayAudioPlayer(pauseAudio, audioId)"
                      />
                    </button>
                  </li>
                </ul>
                <div class="pv__actions d-flex justify-content-end">
                  <button
                    @click="$bvModal.show('cct-modal')"
                    class="pv__action pv__action--clone btn py-1 rounded-sm"
                    title="Clone New"
                  >
                    {{ $t('clone-new') }}
                  </button>
                  <button
                    type="button"
                    class="pv__action pv__action--select btn py-1 rounded-sm text-white"
                    :title="'Select ' + selectedPersonalVoice?.character.name"
                    :disabled="selectedPersonalVoice === null"
                    @click="$emit('change:voice-type', { language: selectedPersonalVoice.language, character: selectedPersonalVoice.character })"
                  >
                    {{ $t('select') }}
                  </button>
                </div>
              </div>
            </div>
            <div v-else-if="!(loadingLanguagesFilter === internalSelectedLanguage.name)" class="ml-3">
              <div class="container-fluid p-0">
                <p v-if="internalSelectedLanguage?.name == 'Multilingual'" class="multilingual--label" v-html="$t('multilingual-voice--p')"></p>
                <div class="grid__card-avatar mr-1">
                  <div
                    v-for="character in filteredCharacters"
                    :key="character.name"
                  >
                    <div class="card-avatar card position-relative">
                      <!-- <p class="name--avatar position-absolute m-0">{{ character.name }}</p> -->
                      <!-- <div v-if="internalSelectedLanguage?.name == 'Multilingual'"
                        :class="{
                          'multilingual--type-go' : typeOfMultilingual(character?.package_id) == 'Go',
                          'multilingual--type-pro' : typeOfMultilingual(character?.package_id) == 'Pro',
                          'd-none' : typeOfMultilingual(character?.package_id) == '-',
                        }"
                      >
                        {{ typeOfMultilingual(character?.package_id).toUpperCase() }}
                      </div> -->
                      <!-- <div v-if="internalSelectedLanguage?.name == 'Multilingual'" class="multilingual--category">Adult</div> -->
                      <!-- <img v-if="internalSelectedLanguage?.name == 'Multilingual'" src="@/assets/images/icons/ic_crown.svg" alt="" class="icon-crown position-absolute"> -->
                      <div class="fig--pr">
                        <div class="pr--label__char w-100 pt-2 px-2" v-if="character.isMultilingual">
                          <h6 class="char--name">{{character.name}}</h6>
                          <span class="badge--package" v-if="character.package_id.length == 3">PRO</span>
                        </div>
                        <img :src="character.thumbnailAvatarSrc" :alt="character.name" class="img-fluid img--char"/>
                      </div>
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <button
                            class="card-avatar__button-audio btn"
                            @click="handleClickAudioControl(character.name, character.sampleSrc)"
                          >
                            <font-awesome-icon
                              :icon="(playingAudioName === character.name && playingAudioState === 'playing') ? 'fa-solid fa-pause' : 'fa-solid fa-play'"
                              style="width: 9px"
                            />
                          </button>
                          <button
                            class="card-avatar__button-select btn btn-block ml-2"
                            :class="selectedCharacter.name === character.name ? 'btn-selected' : 'btn-purple'"
                            @click="internalSelectedLanguage?.name == 'Multilingual' && character.button_active == false && character.package_id.length == 3 ? $bvModal.show(`package-character-${character.name}`) : $emit('change:voice-type', { language: internalSelectedLanguage, character })"
                          >
                            {{ selectedCharacter.name === character.name ? $t('selected') : $t('select')}}
                          </button>
                        </div>
                      </div>
                    </div>
                    <VCCharacterPackageModal :id="`package-character-${character.name}`" :package-id="character.package_id"/>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="d-flex align-items-center justify-content-center h-100">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <audio ref="audioPlayer" />
    <audio id="audioPlayerSvr" ref="audioPlayerSvr" :src="recordedAudioSrc"/>
    <TTVRecordVoiceModal id="sv-record-voice" :loading-audio="loadingPlayAudio" :is-generate="isGenerate" :is-play="isPlay" @play-voice-clone-sample="handleVoiceCloneSample" @rest-vs="handleResetVs" @create-vc="$bvModal.show('pvi-modal'), $bvModal.hide('sv-record-voice')"/>
		<PVInstruction @continue="$bvModal.show('pvrecord-modal'), $bvModal.hide('cct-modal'), $bvModal.hide('pvi-modal')" />
		<PVRecord @update-table="getYourVoiceModel" @reset="selectedCloneType = null" @show-instruction="$bvModal.hide('pvrecord-modal'), $bvModal.show('pvi-modal')" :selected="selectedCloneType"/>
    <TermOfUseMB />
    <ChooseCloneTypeModal :selected="selectedCloneType" @hidden:cct-closed="selectedCloneType = null" @click:change-selected-clone-type="handleChangeSelectedCloneType" @click:next="handleClickNextCct"/>
		<QuotaModelsAlert :type="selectedCloneType" :click-upgrade="clickUpgrade" @click:handle-upgrade-gv="$emit('click:handle-upgrade-gv')"/>
		<VCMultilingualError @retry="handleRetryVCError" @cancel="handleCancelVCError"/>
    <ModalPackageError @click:upgrade="(id) => createInvoice(id)" :tipe_package="tipe_package"/>
  </b-modal>
</template>

<script>
import TTVRecordVoiceModal from '@/components/modal/TTVRecordVoice';
import { topupSwc } from '@/services/subscriptions/subscriptions.service'
import PVInstruction from '@/components/modal/PVInstruction';
import PVRecord from '@/components/modal/PVRecord';
import TermOfUseMB from '@/components/modal/TermOfUseMB';
import QuotaModelsAlert from '@/components/modal/QuotaModelsAlert';
import VCMultilingualError from '@/components/modal/VCMultilingualError';
import VCCharacterPackageModal from '@/components/modal/CharacterPackage';
import ChooseCloneTypeModal from '@/components/modal/ChooseCloneType.vue';
import ModalPackageError from '@/components/modal/ModalPackageError.vue';

import { base_url_machine_learning } from '@/config/base_url';
import { getMasterLanguages } from '@/services/master-languages/master_languages.service';
import { getVoiceModel, voiceCloneSample } from '@/services/ttv/ttv.service.js'

import AudioPlayer from '@/components/audio-player/AudioPlayer';

export default {
  components: {
    AudioPlayer,
    TTVRecordVoiceModal,
    PVInstruction,
    TermOfUseMB,
    ChooseCloneTypeModal,
		QuotaModelsAlert,
		VCMultilingualError,
    VCCharacterPackageModal,
    PVRecord,
    ModalPackageError
  },
  props: {
    id: {
      type: String,
      default: 'select-voice-type',
    },
    selectedLanguage: {
      type: Object,
      default: () => ({
        id: '6401ae940eb4d9111ec260d7',
        name: 'Indonesia',
        code: 'ID',
        flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
      }),
    },
    selectedCharacter: {
      type: Object,
      default: () => ({
        gender: 'Female',
        name: 'Sovia',
        avatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/Sovia.png',
        thumbnailAvatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/thumbnails/Sovia.png',
        sample: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/normal/1677909003.mp3',
      }),
    },
    initialLanguages: {
      type: Array,
      default: () => ([]),
    },
    allocation: {
      type: Object,
      default: () => {
        return {}
      }
    },
    clickUpgrade: {
      type: Function,
      default: null,
    },
  },
  emits: ['hidden', 'get:languages', 'change:voice-type'],
  data() {
    return {
      languages: this.initialLanguages,
      languagesFilter: [],
      loadingLanguages: false,
      loadingLanguagesFilter: false,
      loadingPlayAudio: false,

      isLanguagesSent: false,
      isGenerate: false,
      isPlay: false,

      voiceCloneSampleUrl: null,
      recordedAudioSrc: null,
      selectedCloneType: null,
      selectedCloneTypeAlert: null,

      finalFormVoiceSample: {
				text: '',
				speaker: ''
			},

      internalSelectedLanguage: this.selectedLanguage,
      selectedGender: null,

      personalVoices: [
        {
          language: {
            id: '6401ae940eb4d9111ec260d7',
            name: 'Indonesia',
            code: 'ID',
            flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
          },
          character: {
            id: 'Heri Tipsi',
            name: 'Heri Tipsi',
            avatarSrc: 'https://ui-avatars.com/api/?name=' + 'Heri Tipsi',
            sampleSrc: 'https://upload.wikimedia.org/wikipedia/en/4/43/Viva_la_Vida.ogg',
          },
        },
        {
          language: {
            id: '6401ae940eb4d9111ec260d7',
            name: 'Indonesia',
            code: 'ID',
            flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
          },
          character: {
            id: 'Jenny',
            name: 'Jenny',
            avatarSrc: 'https://ui-avatars.com/api/?name=' + 'Jenny',
            sampleSrc: 'https://upload.wikimedia.org/wikipedia/en/4/43/Viva_la_Vida.ogg',
          },
        },
      ],

      personalVoicesApi: [],
      selectedPersonalVoice: null,
      isPersonalVoiceSectionActive: false,
      latestPlayingAudioId: null,
      pauseLatestPlayingAudio: null,

      playingAudioName: null,
      playingAudioState: 'paused',

      eventMBLang : {
        code : "ID",
        flagSrc : "/img/ic_flag_indonesia.bfd56f3d.svg",
        id : "6401ae940eb4d9111ec260d7",
        name : "Indonesia"
      },

      eventMBJokowi : null,

      lang: [
        {
          id: '6401ae940eb4d9111ec260d7',
          name: 'Indonesia',
          code: 'ID',
          flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
        },
        {
          id: '64017a04a5fbff45c62e64d8',
          name: 'English-UK',
          code: 'EN',
          flagSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/english-UK/flags.png'
        }
      ],
      tipe_package: ""
    };
  },
  computed: {
    sortedCountries() {
      if (this.languages.length > 0) {
        const sortedLanguages = [...this.languages];

        sortedLanguages.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA === 'multilingual') {
            return -1; // Pindahkan 'Multilingual' ke atas
          }

          if (nameB === 'multilingual') {
            return 1; // Pindahkan 'Multilingual' ke atas
          }

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        return sortedLanguages;
      } else {
        return this.languages;
      }
    },
    filteredCharacters() {
      if (!this.selectedGender) {
        return this.languagesFilter.slice().sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }

      return this.languagesFilter
        .filter(character => character.gender === this.selectedGender)
        .slice()
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
    }
  },
  watch: {
    selectedLanguage: {
      handler(newSelectedLanguage) {
        this.internalSelectedLanguage = newSelectedLanguage;
      },
      deep: true,
      immediate: true,
    },
    internalSelectedLanguage: {
      async handler() {
        if (this.$refs.audioPlayer && !this.$refs.audioPlayer.paused) {
          await this.$refs.audioPlayer.pause();
          this.resetAudioPlayer();
        }

        await this.populateLanguagesFilter();
      },
      deep: true,
    },
  },
  methods: {
    createInvoice(id, community_id){
			this.load_topup = true;

			let formData = {
				package_id : id,
				community_id : community_id ? community_id : null
			}

			topupSwc(formData)
			.then((response) => {
				if(response.status == true){
					this.load_topup = false;
					this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id } })
				}
			})
			.catch((err) => {
				this.load_topup = false;
				console.log(err)
			})
		},
    openModalSubscription(char){
      console.log(char);
      // if (res.status == false && res.error_code == 1) {
			// 		this.tipe_package = "Creator";
			// 		this.$bvModal.show('package-error-msg')
			// }else if (res.status == false && res.error_code == 2) {
			// 	this.tipe_package = "Professional";
			// 	this.$bvModal.show('package-error-msg')
			// }
    },
    resetData() {
      this.internalSelectedLanguage = this.selectedLanguage;
      this.selectedGender = null;
      this.resetAudioPlayer();
    },
    async populateLanguages() {
      this.loadingLanguages = true;

      try {
        const { data: languages } = await getMasterLanguages();

        this.languages = [];

        for (const language of languages) {
          this.languages.push({
            name: language.DisplayName,
            code: language.LanguageCode.toUpperCase(),
            flagSrc: base_url_machine_learning + language.Flags,
          });
        }
      } catch (error) {
        console.error(error);
      }

      this.loadingLanguages = false;
    },
    async populateLanguagesFilter() {
      if (this.internalSelectedLanguage) {
        this.loadingLanguagesFilter = this.internalSelectedLanguage.name;

        try {
          const { data } = await getMasterLanguages({
            filter: this.internalSelectedLanguage.name,
            page: 1,
            limit: 20
          });

          this.languagesFilter = [];
          this.eventMBJokowi = null

          for (let i = 0; i < data[0].Detail.length; i++) {
            const character = data[0].Detail[i];
            const isUseModel = data[0].LanguageCode !== "multilingual" ? false : true;
            const isMultilingual = data[0].LanguageCode !== "multilingual" ? false : true;

            this.languagesFilter.push({
              gender: character.Gender,
              name: character.Name,
              avatarSrc: base_url_machine_learning + character?.PathAvatar,
              sampleSrc: base_url_machine_learning + character.PathSample?.normal,
              thumbnailAvatarSrc: base_url_machine_learning + character?.PathThumbnailAvatar,
              isUseModel: isUseModel,
              isMultilingual: isMultilingual,
              button_active : character.button_active,
              package_id : character.package_id || []
            });
          }

          this.internalSelectedLanguage.id = data[0]._id.$oid;
        } catch (error) {
          console.error(error);
        }

        this.loadingLanguagesFilter = false;
      }
    },
    async handleClickAudioControl(name, src) {
      const prevPlayingAudioName = this.playingAudioName;

      this.playingAudioName = name;

      if (prevPlayingAudioName !== name) {
        this.$refs.audioPlayer.src = src;
      }

      if (this.$refs.audioPlayer.paused) {
        await this.$refs.audioPlayer.play();
        this.playingAudioState = 'playing';
      } else {
        await this.$refs.audioPlayer.pause();
        this.playingAudioState = 'paused';
      }
    },
    handleEndedAudioPlayer() {
      this.resetAudioPlayer();
    },
    resetAudioPlayer() {
      this.playingAudioName = null;
      this.playingAudioState = 'paused';

      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.src = null;
      }
    },
    async handlePlayAudioPlayer(pauseAudio, audioId) {
      const playMediaEvent = new CustomEvent('play:media', {
        detail: {
          componentId: audioId,
        },
      });

      document.dispatchEvent(playMediaEvent);

      this.latestPlayingAudioId = audioId;
      this.pauseLatestPlayingAudio = pauseAudio;
    },
    async handlePlayMedia(e) {
      if (e.detail.componentId !== this.latestPlayingAudioId && this.pauseLatestPlayingAudio) {
        await this.pauseLatestPlayingAudio();
      }
    },
    async handleShowModal() {
      this.resetData();
      await this.getLanguages();
    },
    handleShownModal() {
      this.$refs.buttonLang.click()
      this.$refs.audioPlayer.addEventListener('ended', this.handleEndedAudioPlayer);
    },
    handleHideModal() {
      this.$refs.audioPlayer.removeEventListener('ended', this.handleEndedAudioPlayer);
    },
    async getLanguages() {
      if (this.languages?.length < 1) {
        await this.populateLanguages();

        if (this.languages.length > 0 && !this.isLanguagesSent) {
          this.$emit('get:languages', this.languages);
          this.isLanguagesSent = true;
        }
      }

      await this.populateLanguagesFilter();
    },
    getYourVoiceModel() {
      getVoiceModel()
      .then((response) => {
        if(response.status == 200) {
          this.personalVoicesApi = []
          let data = response.data
          data.forEach((e) => {
            let indexLang = this.lang.findIndex(item => item.id === e.lang);
            let dataLang = this.lang[indexLang]
            this.personalVoicesApi.push({
              language: {
                id: e.lang,
                name: dataLang.name,
                code: dataLang.code,
                flagSrc: dataLang.flagSrc,
              },
              character: {
                id: e.id,
                name: e.character_name,
                avatarSrc: 'https://ui-avatars.com/api/?name=' + e.character_name,
                sampleSrc: e.path_voice,
                lang: e.lang,
                isUseModel: true,
                type: e.type
              },
            })
          })
        }
      })
    },
    handleVoiceCloneSample(val) {
      if(this.finalFormVoiceSample.text == val.text && this.finalFormVoiceSample.speaker == val.speaker) {
        this.playVoiceCloneSample()
      } else {
        this.isGenerate = true
        voiceCloneSample(val)
        .then((response) => {
          let audio = new Audio(response.data)
          this.voiceCloneSampleUrl = audio
          this.isGenerate = false
          this.playVoiceCloneSample()
        })
      }
      this.finalFormVoiceSample.text = val.text
      this.finalFormVoiceSample.speaker = val.speaker
    },
    playVoiceCloneSample() {
      this.voiceCloneSampleUrl.onwaiting = () => {
        this.loadingPlayAudio = true
      }

      this.voiceCloneSampleUrl.onplaying = () => {
        this.loadingPlayAudio = false
      }

      if(!this.voiceCloneSampleUrl.paused) {
        this.isPlay = false
        clearInterval(this.voiceCloneSampleUrl.intervalId)
        this.voiceCloneSampleUrl.pause()
      } else {
        this.isPlay = true
        this.voiceCloneSampleUrl.play()

        this.voiceCloneSampleUrl.addEventListener('ended', () => {
          this.isPlay = false
        })

        this.voiceCloneSampleUrl.intervalId = setInterval(() => {
          this.voiceCloneSampleUrl.paused ? clearInterval(this.voiceCloneSampleUrl.intervalId) : null;
          if(this.voiceCloneSampleUrl.currentTime === this.voiceCloneSampleUrl.duration) {
            this.isPlay = false
          }
        }, 100)
      }
    },
    handleResetVs() {
      if(this.voiceCloneSampleUrl){
        this.voiceCloneSampleUrl.pause()
        clearInterval(this.voiceCloneSampleUrl.intervalId)
      }
      this.voiceCloneSampleUrl = null
      this.isGenerate = false
			this.isPlay = false
			this.finalFormVoiceSample = {
				text: '',
				speaker: ''
			}
    },

    handleClickNextCct() {
      if(!this.allocation?.voice_model_monolingual_quota && this.selectedCloneType == 'monolingual') {
				this.$bvModal.show('qm-modal')
			} else if (!this.allocation?.voice_model_multilingual_quota && this.selectedCloneType == 'multilingual') {
				this.$bvModal.show('qm-modal')
			} else {
				this.$bvModal.hide('cct-modal')
				this.$bvModal.show('pvi-modal')
			}
		},

    handleRetryVCError() {
			this.$bvModal.hide('vcm-modal')
			this.$bvModal.hide('pvrecord-modal')
			this.$bvModal.show('cct-modal')
		},

		handleCancelVCError() {
			this.$bvModal.hide('vcm-modal')
			this.$bvModal.hide('pvrecord-modal')
		},

    handleChangeSelectedCloneType(newVal) {
			this.selectedCloneType = newVal
    },

    typeOfMultilingual(flaging) {
      if(flaging.length == 1) {
        return 'Pro'
      } else if(flaging.length == 2 ) {
        return 'Go'
      } else {
        return '-'
      }
    }
  },
  async mounted() {
    this.$emit('change:voice-type', { language: this.internalSelectedLanguage, character: this.selectedCharacter });
    await this.getLanguages();
    await this.getYourVoiceModel();
    document.addEventListener('play:media', this.handlePlayMedia);
  },
  beforeDestroy() {
    this.$refs.audioPlayer?.removeEventListener('ended', this.handleEndedAudioPlayer);
    document.removeEventListener('play:media', this.handlePlayMedia);
  },
};
</script>

<style scoped>
.fig--pr{
  position: relative;
}
.pr--label__char{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
}
.img--char{
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.char--name{
  font-size: 14px;
  font-weight: 400;
}
.badge--package{
  font-size: 8px;
  background-color: #EBB12B;
  border-radius: 8.5px;
  padding: 1px 6px;
}
.grid__card-avatar {
  gap: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.container-svm label {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 25px !important;
}

.container-svm label:before {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 0.5rem;
  width: 40px;
  background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
  background-size: 20px;
}

.separator-bottom {
  border-bottom: 0.1px solid #2D2D2D;
}

.search-bar-voice {
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  padding-left: 45px !important;
  padding-right: 20px;
  background-color:#2D2D2D;
  border-radius: 6px;
  border: 1px solid #2D2D2D;
}

.btn-close {
  width: 15px;
  height: 26px;
  background-color: #EDEDED;
  border-radius: 50%;
}

.close-image {
  z-index: 100;
  right: 5px;
}

.dropdown span, .dropdown-menu span {
  font-size: 12px;
}

.dropdown-menu {
  min-width: 5rem;
  background-color: #2D2D2D;
  box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.75);
}

.select-lang {
  width: 100% !important;
  height: 18rem;
  padding: 10px 10px;
}

.select-lang::-webkit-scrollbar {
  width: 6px;
}

.select-lang::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

.select-lang::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  border-radius: 4px;
  background-clip: padding-box;
}

.select-lang::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.btn-lang {
  width: 100%;
  padding: 10px !important;
  background-color:transparent;
  border: 1px solid #2D2D2D;
  color: white;
}

.card-avatar {
  background-color: #2D2D2D;
  max-width: 10.2rem;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.card-avatar__button-audio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 3px !important;
  color: black;
}

.card-avatar__button-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 23px;
  border-radius: 3px !important;
  font-size: 12px;
}

.btn-selected {
  background-color: #585859;
  border: 1px solid #585859;
  color: white;
  box-shadow: none;
}

.btn-purple {
  border: 1px solid #6D75F6;
  background-color: #6D75F6;
  color: white;

  transition: background-color 0.25s, color 0.25s, border 0.25s;
}

.btn-purple:not(:disabled):hover {
  border: 1px solid #545EED;
  background-color: #545EED;
}

.btn-purple:disabled {
  background-color: #585859;
  border: 1px solid #585859;
  color: #8f8f8f;
}

.pv__button {
  background-color: #2D2D2D;
  border: 1px solid transparent;
  color: #6D75F6;
  font-weight: 600;
  transition: border-color 0.25s;
}

.pv__button:not(.pv__button--active):hover {
  border-color: #6D75F644;
}

.pv__button--active {
  border-color: #6D75F6;
}

.pv__section {
  width: 100%;
  height: 100%;
}

.pv__empty-section {
  gap: 16px;
}

.pv__empty-message {
  font-size: 14px;
}

.pv__clone-button {
  background: none;
  border: 1px solid #6D75F6;
  color: #6D75F6;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.25s;
}

.pv__clone-button:not(:disabled):hover {
  background-color: #6D75F633;
}

.pv__exist-section {
  gap: 16px;
}

.pv__title {
  font-size: 14px;
  font-weight: 500;
}

.pv__voice-list {
  gap: 8px;
  max-height: 24rem;
}

.pv__voice-button {
  gap: 16px;
  width: 100%;
  background: #2D2D2D;
  border: 1px solid transparent;
  overflow: hidden;
  transition: border-color 0.25s;
}

.pv__voice-button:not(
  .pv__voice-button--selected
):hover {
  border-color: #6D75F655;
}

.pv__voice-button--selected {
  border-color: #6D75F6;
}

.pv__voice-button-checkbox {
  width: 12px;
  height: 12px;
  border: 1px solid #FFFFFF;
  font-size: 8px;
  transition: background-color 0.25s, border-color 0.25s;
}

.pv__voice-button-checkbox--checked {
  background-color: #6D75F6;
  border-color: #6D75F6;
  color: #FFFFFF;
}

.pv__actions {
  gap: 8px;
}

.pv__action {
  min-width: 75px;
  background: none;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.25s, border-color 0.25s;
}

.pv__action--clone {
  color: #6D75F6;
  border-color: #6D75F6;
}

.pv__action--clone:not(:disabled):hover {
  background-color: #6D75F633;
}

.pv__action--select {
  background-color: #6D75F6;
}

.pv__action--select:not(:disabled):hover {
  background-color: #545EED;
}

.banner__nkri-78 {
  background-color: #2D2D2D;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  height: 130px;
}

.banner__nkri-img {
  position: absolute;
  right: 0;
  height: 130px;
}

.jd__inner {
  gap: 8px;
}

.jd__title {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  line-height: 10px;
}

.jd__sub-title {
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
}

.jd__pharagraph {
  font-size: 9px;
  color: #fff;
}

.jd__intro {
  gap: 6px;
}

.icon-crown {
  width: 16px;
  top: 16px;
  right: 12px;
}

.name--avatar {
  font-size: 16px;
  top: 12px;
  left: 14px;
}

.multilingual--label {
  color: #8C8C8C;
  font-family: Avenir Next;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.multilingual--type-go, .multilingual--type-pro {
  position: absolute;
  top: 16px;
  right: 12px;
  border-radius: 8.5px;
  font-size: 8px;
  font-weight: 600;
  padding: 1px 9px;
}

.multilingual--type-go {
  background: #6D75F6;
}

.multilingual--type-pro {
  background: #EBB12B;
}

.multilingual--category {
  position: absolute;
  bottom: 33%;
  left: 12px;
  border-radius: 8.5px;
  font-size: 10px;
  font-weight: 600;
  padding: 1px 8px;
  border-radius: 8.5px;
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(2px);
}
</style>

<style>
.modal-content {
  background-color: #1F1F1F;
  color: white;
}

.container-svm .b-skeleton-avatar {
  width: 1.5em !important;
  height: 1.5em !important;
}

.svm__dialog {
	max-width: none;
}

.svm__content {
	width: 1000px;
}
</style>
