<template>
  <b-modal
    :id="id"
    size="xl"
    modal-class="image-preview__modal"
    dialog-class="image-preview__dialog"
    content-class="image-preview__content"
    hide-header
    hide-footer
    centered
  >
    <!-- <div class="float-right">
      <button
        class="image-preview__close-button btn d-flex justify-content-center align-items-center rounded-circle"
        @click="$bvModal.hide(id)"
      >
        <b-icon icon="x" />
      </button>
    </div> -->
    <div class="w-100 d-flex justify-content-center mb-3">
      <img
        :src="src"
        :alt="alt"
        class="image-preview__image"
      />
      <button
        class="image-preview__close-button btn d-flex justify-content-center align-items-center rounded-circle ml-4"
        @click="$bvModal.hide(id)"
      >
        <b-icon icon="x" />
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'image-preview',
    },
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.image-preview__close-button {
  width: 35px;
  height: 35px;
  background-color: #EDEDED;
}

.image-preview__image {
  max-width: 100%;
  height: 30rem;
}
</style>

<style>
.image-preview__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.image-preview__content {
  background-color: transparent !important;
  border: none !important;
}

.image-preview__dialog {
  /* max-width: none;
  width: 500px; */
}
</style>
