<template>
	<div class="ma--unsplash py-3 pl-3 w-100">
		<label :for="`${_uid}__input-search`" class="ma--unsplash-search">
			<input type="text" :for="`${_uid}__input-search`" class="search-bar" :placeholder="$t('find-a-picture')" v-model="unsplashKeyword">
            <b-icon v-if="unsplashKeyword != null && unsplashKeyword != ''" @click="unsplashKeyword = null" class="sb__clear position-absolute cursor-pointer" icon="x"></b-icon>
		</label>
		<div class="d-flex align-items-center my-2">
			<span class="text-muted powered-by">Powered By </span>
            <img
                src="@/assets/images/icons/unsplash-logo.svg"
                alt="Unsplash"
                class="img-fluid ml-2"
                width="70"
            />
		</div>
		<div class="overflow-auto unsplash--img-wrapper">
			<b-card-group columns>
                  <b-card
                    v-for="image in galleryImages"
                    :key="image.id"
                    class="px-0 uil__item position-relative"
                    :img-src="image.thumbnailSrc"
                    :img-alt="image.name"
                    img-top
                    body-class="p-0 m-0"
                    :class="{ 'image-selected': selectedImage?.id === image.id }"
            	>
					<div class="uil__item-actions d-flex flex-column justify-content-center position-absolute p-5" :class="{ 'active': activeTippy === image.id }">
						<!-- <tippy :to="`uil-action-toggle-${image.id}`" theme="uil__wrapper-tippy" trigger="click" boundary="window" placement="right" :animate-fill="false" :duration="250" :z-index="9999999999" :max-width="500" visible interactive @shown="activeTippy = image.id" @hidden="activeTippy = null">
							<ul class="uil-at--action d-flex flex-column list-unstyled text-left m-0">
								<li @click="downloadImage(image.thumbnailSrc, image.name)">
									<a class="mx-2">Download</a>
								</li>
								<li @click="saveToMyAssets(image.thumbnailSrc)">
									<a class="mx-2">Save to My Assets</a>
								</li>
							</ul>
						</tippy>
						<button :name="`uil-action-toggle-${image.id}`" class="uil__button-action btn p-0" type="button">
							<font-awesome-icon icon="fa-solid fa-ellipsis" class="uil__ba-icon"/>
						</button> -->
						<div class="dropdown dropright uil__button-action">
							<button class="btn p-0 d-flex align-items-start" type="button" :id="`${_uid}__dropdown`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<b-icon
									icon="three-dots-vertical"
									style="color: #FFFFFF"
									font-scale="1"
								/>
							</button>
							<div class="dropdown-menu">
								<a class="dropdown-item cursor-pointer" @click="downloadImage(image.thumbnailSrc, image.name)">{{ $t('download') }}</a>
								<a class="dropdown-item cursor-pointer" @click="saveToMyAssets(image.thumbnailSrc)">{{ $t('save-as-assets') }}</a>
							</div>
						</div>
						<button type="button" class="uil__item-action-preview btn" :title="'Preview of ' + image.name" @click="previewImage(image.src, image.name)">
							{{ $t('preview') }}
						</button>
						<button type="button" class="uil__item-action btn text-white" :title="'Use ' + image.name" @click="$emit('click:new-image', image)">
							{{ $t('use') }}
						</button>
					</div>
				</b-card>
            </b-card-group>
		</div>
		<ImagePreviewModal id="image-preview-unsplash" :src="imagePreview.src" :alt="imagePreview.alt" />
	</div>
</template>

<script>
import ImagePreviewModal from '@/components/modal/ImagePreview';

import { getSearchImage } from "@/services/course/course.service";
import { exportUtils } from "@/mixins/ExportUtils";
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
	mixins:[exportUtils, AlertUtils],
	components: {
		ImagePreviewModal
	},
	props: {
		image: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			unsplashKeyword: '',
			selectedImage: this.image,

			totalGalleryImagesPerPage: 30,
      		currentGalleryPage: 1,

			activeTippy: null,

			galleryImages: [],

			imagePreview: {
				src: '',
				alt: '',
			},
		}
	},

	watch: {
		unsplashKeyword: {
			async handler(newUnsplashKeyword) {
				await this.getUnsplashImages(newUnsplashKeyword);
			},
			immediate: true
		},
		image: {
			handler(newVal) {
				this.selectedImage = newVal
			},
			deep: true
		},
	},

	methods: {
		async getUnsplashImages(keyword = 'wave audio') {
			this.galleryImages = []

			try {
				const res = await getSearchImage(keyword || 'wave audio', this.totalGalleryImagesPerPage, this.currentGalleryPage)

				if(res.status) {
					for(const image of res.data.results) {
						this.galleryImages.push({
							type: 'gallery',
							id: image.id,
							name: image.description,
							src: image.urls.regular,
							thumbnailSrc: image.urls.thumb,
						})
					}
				}
			} catch (error) {
				console.erro(error)
			}
		},

		previewImage(src, alt) {
			this.imagePreview = { src, alt };
			this.$bvModal.show('image-preview-unsplash');
		},

		async downloadImage(url, name) {
			try {
				const response = await fetch(url)

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const blobImage = await response.blob();

				this.exportImage(blobImage, name ? name : 'Image')
				this.alertSuccess("Image successfully downloaded")
			} catch (error) {
				console.error('Error during fetch:', error);
				this.alertFail("Failed to download image.")
			}
		},

		saveToMyAssets(url) {
			this.$emit('click:stma', url)
		}
	}
}
</script>

<style scoped>
.ma--unsplash-search {
	z-index: 2;
	position: relative;
	width: 100%;
	height: 40px;
	border-radius: 25px !important;
}

.ma--unsplash-search::before {
	content: "";
	position: absolute;
	top: 10px;
	bottom: 0;
	left: 1rem;
	width: 40px;
	background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
	background-size: 20px;
}

.search-bar {
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding-left: 48px !important;
  padding-right: 20px;
  background-color: transparent;
  border-radius: 25px;
  border: 1px solid #2D2D2D;
  color: white;
}

.sb__clear {
	top: 17px;
    right: 19px;
    color: #858585;
}

.image-selected {
  border: 3px solid #6D75F6;
  border-radius: 10px;
}
.unsplash--img-wrapper {
	height: 25rem;
}

.uil__item-actions {
  opacity: 0;
  visibility: hidden;

  inset: 0;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  transition: opacity 0.25s, visibility 0.25s;
}

.uil__item-actions.active {
	opacity: 1;
  	visibility: visible;
}

.uil__item:hover .uil__item-actions, .uil__item-actions.active {
  opacity: 1;
  visibility: visible;
}

.uil__item-action, .uil__item-action-preview {
  padding: 2px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.uil__item-action {
  	background-color: #6D75F6;
	color: white;
}

.uil__item-action-preview {
	color: #6D75F6;
	background-color: white;
}

.uil__item-action-preview:hover {
	background-color: #ffffffea;
}

.uil__item-action:hover {
  background-color: #545EED;
}

.uil__button-action {
	box-shadow: none;
	position: absolute;
	color: #fff;
	top: 10px;
	right: 10px;
	/* rotate: 90deg; */
}

.uil__ba-icon {
	font-size: 16px;
}

.uil-at--action {
	padding: 5px;
	padding: 10px 0px;
}

.uil-at--action li {
	padding: 3px 0px;
	cursor: pointer;
}

.uil-at--action li:hover {
	background-color: #8c8c8c;
}
</style>

<style>
.uil__wrapper-tippy-theme {
	background-color: #2D2D2D;
	padding: 0;
}

.uil__button-action .dropdown-menu {
	background: #2D2D2D;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
	border-radius: 6px;
	color: #fff;
	min-width: 140px !important;
	max-width: 140px !important;
	font-size: 12px;
}

.uil__button-action .dropdown-item:hover {
	background-color: #8C8C8C;
}
</style>