<template>
  <ul class="qo d-flex align-items-stretch m-0 list-unstyled">
    <li v-if="!withoutPoint">
      <select
        v-model.number="question.point"
        class="qo__select"
        title="Point"
      >
        <option value="10" class="qo__option">10 points</option>
        <option value="20" class="qo__option">20 points</option>
        <option value="30" class="qo__option">30 points</option>
      </select>
    </li>
    <li>
      <select
        v-model.number="question.duration"
        class="qo__select"
        title="Duration"
      >
        <option value="10" class="qo__option">10 seconds</option>
        <option value="30" class="qo__option">30 seconds</option>
        <option value="60" class="qo__option">60 seconds</option>
        <option value="90" class="qo__option">90 seconds</option>
        <option value="120" class="qo__option">120 seconds</option>
      </select>
    </li>
    <!-- <li>
      <button
        type="button"
        class="qo__delete-button btn d-flex justify-content-center align-items-center"
        title="Delete Question"
        @click="$emit('delete-question')"
      >
        <img src="@/assets/images/icons/ic_delete_2.svg" alt="Delete" height="12">
      </button>
    </li> -->
  </ul>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    withoutPoint: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['input', 'delete-question'],
  data() {
    return {
      question: this.value,
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        this.$emit('input', newQuestion);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.question = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.qo {
  gap: 8px;
}

.qo__select {
  padding: 6px 8px;
  background: none;
  font-size: 14px;
  border: 1px solid #2D2D2D;
  border-radius: 4px;
  color: #FFFFFF;
}

.qo__option {
  background-color: #2D2D2D;
  color: #FFFFFF;
}

.qo__delete-button {
  height: 100%;
  padding: 4px 10px;
  background-color: #2D2D2D;
  border-radius: 4px;
  transition: background-color 0.25s;
}

.qo__delete-button:hover {
  background-color: #F34D4D;
}
</style>
