<template>
    <div>
        <b-modal id="pvrecord-modal" centered :no-close-on-backdrop="recordControlState === 'stop' || playControlState === 'pause'" header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" body-class="px-0 pt-2" footer-class="border-0 pb-3 pt-0 mx-auto" size="xl" @shown="addEventAudio" @hidden="removeEventAudio()">
            <div class="container-fluid px-4 mt-3">
				<div class="d-flex align-items-center justify-content-between">
					<div class="pvr__header--lang d-flex align-items-center">
						<h5 class="pvr__lang">{{ $t('language') }} : </h5>
						<b-dropdown
							boundary="window"
							toggle-class="pvr__language-toggle btn d-inline-flex align-items-center text-white"
							menu-class="pvr__language-menu"
							no-caret
							title="Select Language"
							:disabled="recordControlState === 'stop' || playControlState === 'pause'"
						>
							<template #button-content>
								<img
									:src="selectedLanguageData.flagSrc"
									height="15"
									:alt="selectedLanguageData.name"
								/>
								<span class="title__lang">{{ selectedLanguageData.shortName }}</span>
								<font-awesome-icon icon="fa-solid fa-chevron-down arrow__lang" />
							</template>
							<b-dropdown-item-button
								v-for="language in languages"
								:key="language.id"
								button-class="pvr__language-button d-inline-flex align-items-center px-3 py-2 text-white"
								:title="`Select ${language.name}`"
								@click="selectedLanguage = language.id"
							>
								<img
									:src="language.flagSrc"
									height="16"
									:alt="language.name"
								/>
								{{ language.name }}
							</b-dropdown-item-button>
						</b-dropdown>
					</div>
					<button type="button" class="btn instuctions--button d-flex align-items-center" :disabled="recordControlState === 'stop' || playControlState === 'pause'" @click="$emit('show-instruction')">
						<img src="@/assets/images/icons/ic_instructions_purple.svg" alt="Instructions" width="15" class="ml-1">
						<span style="font-size: 14px; height: 14px">{{ $t('instructions') }}</span>
					</button>
				</div>
            </div>
			<hr style="background-color: #585859" class="my-2">
			<div class="conatiner-fluid px-4">
				<span class="pvr__suggestion--text">"{{ suggestionScript.text }}"</span>
				<div class="pvr__record--wrapper d-flex flex-column justify-content-center align-items-center mt-3 text-white">
					<div
						v-show="stream === null && !recordedAudioSrc"
						class="pvr__record-default-wave flex-column justify-content-center"
					>
						<div class="pvr__record-default-wave-inner" />
					</div>
					<audio id="audioPlayerPvr" ref="audioPlayerPvr" :src="recordedAudioSrc"/>
					<av-waveform
						v-if="recordedAudioSrc"
						ref-link="audioPlayerPvr"
						:audio-controls="false"
						:canv-width="600"
						:canv-height="80"
						:playtime="false"
						played-line-color="#6D75F6"
						noplayed-line-color="#8C8C8C"
						playtime-slider-color="#C43D30"
					/>
					<av-media
						v-show="stream && !recordedAudioSrc"
						type="frequ"
						:media="stream"
						:audio-controls="false"
						:canv-width="600"
						:canv-height="80"
						:frequ-lnum="200"
						:radius="16"
						line-color="#6D75F6"
						frequ-direction="mo"
						cors-anonym
					/>
					<div class="pvr__record-controls d-flex flex-column align-items-center">
						<p class="pvr__record-time m-0">{{ recordFullTime }}</p>
						<button
							type="button"
							class="pvr__record-control btn d-inline-flex justify-content-center align-items-center rounded-circle"
							:class="{
								'pvr__record-control--record': recordControlState === 'record',
								'pvr__record-control--stop': recordControlState === 'stop',
								'pvr__record-control--loading': recordControlState === 'loading',
							}"
							:disabled="recordControlState === 'loading' || playControlState === 'pause'"
							:title="recordControlState.charAt(0).toUpperCase() + recordControlState.slice(1)"
							@click="handleClickRecordControl"
						>
							<font-awesome-icon
								:icon="`fa-solid fa-${recordControlState === 'record' ? 'circle' : recordControlState === 'stop' ? 'stop' : 'spinner'}`"
								:spin="recordControlState === 'loading'"
							/>
						</button>
						<p class="pvr__record-status m-0">
							{{ $t(recordControlState.charAt(0).toLowerCase() + recordControlState.slice(1)) }}
						</p>
					</div>
				</div>
				<section class="pvr__buttons d-flex justify-content-center mt-4">
					<button
						type="button"
						class="pvr__play-control btn d-inline-flex justify-content-center align-items-center p-2"
						:class="{
							'pvr__play-control--play': playControlState === 'play',
							'pvr__play-control--pause': playControlState === 'pause',
						}"
						:title="playControlState.charAt(0).toUpperCase() + playControlState.slice(1)"
						:disabled="playControlState === 'loading' || recordControlState === 'stop' || !recordedAudioSrc"
						@click="handleClickPlayControl"
					>
						<font-awesome-icon
							:icon="`fa-solid fa-${playControlState === 'play' ? 'play' : playControlState === 'pause' ? 'pause' : 'spinner'}`"
							:spin="playControlState === 'loading'"
						/>
						{{ $t(playControlState.charAt(0).toLowerCase() + playControlState.slice(1)) }}
					</button>
					<button
						type="button"
						class="pvr__next-button btn p-2"
						title="Next"
						:disabled="!recordedAudioSrc || recordControlState === 'pause'"
						@click="$bvModal.show('ttv-save-recorded-voice')"
					>
						{{ $t('next') }}
					</button>
				</section>
			</div>
        </b-modal>
		<TTVSaveRecordedVoiceModal
			id="ttv-save-recorded-voice"
			:loading="load_save_record"
			@save="handleSave"
		/>
		<TTVSuccessfullyRecordModal
			id="ttv-successfully-record"
			@close:modal="closeModalPvr"
		/>
    </div>
</template>

<script>
import { getSVM, generateVoiceModel } from '@/services/ttv/ttv.service.js'

import RecordRTC from 'recordrtc';
import getBlobDuration from 'get-blob-duration';
import TTVSaveRecordedVoiceModal from '@/components/modal/TTVSaveRecordedVoice';
import TTVSuccessfullyRecordModal from '@/components/modal/TTVSuccessfullyRecord';

export default {
	components: {
		TTVSaveRecordedVoiceModal,
		TTVSuccessfullyRecordModal
	},
	data() {
		return {
			languages: [
				{
					id: 'eng',
					name: 'English',
					shortName: 'EN',
					flagSrc: require('@/assets/images/icons/ic_flag_uk.svg'),
				},
				{
					id: 'idn',
					name: 'Indonesia',
					shortName: 'ID',
					flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
				},
			],
			suggestionScript: {
				id_dataset_suggestion : null,
				text : null,
			},
			selectedLanguage: 'eng',

			load_save_record: false,

			stream: null,
			recorder: null,
			recordedVoiceSrc: null,

			recordTime: 0,
			recordTimeInterval: null,
			recordedAudioSrc: null,
			recordedAudioCurrentTime: 0,
			recordedAudioDuration: 0,

			playControlState: 'play', // ['play', 'pause', 'loading']
			recordControlState: 'record', // ['record', 'stop', 'loading']
		}
	},
	props: {
		selected: {
			type: String,
			default: 'monolingual'
		}
	},
	computed: {
		recordFullTime() {
			if (this.recordedAudioSrc) {
				return `${this.formatTime(this.recordedAudioCurrentTime)}`;
			} else {
				return this.formatTime(this.recordTime);
			}
		},
		selectedLanguageData() {
			return this.languages.find((language) => language.id === this.selectedLanguage);
		},
	},

	watch: {
		selectedLanguage(newVal) {
			this.getSuggestionScript(newVal)
		}
	},

	async mounted() {
		this.getSuggestionScript(this.selectedLanguage)
		// if (this.recordedAudioSrc) {
		// 	this.recordedAudioDuration = await getBlobDuration(this.recordedAudioSrc);
		// }
	},

	async beforeDestroy() {
		// this.$refs.audioPlayer.removeEventListener('timeupdate', this.handleTimeupdateAudioPlayer);
		// if (this.stream !== null) {
		// 	await this.deactivateRecorder();
		// }
	},

    methods: {
		async addEventAudio() {
			this.$refs.audioPlayerPvr.addEventListener('timeupdate', this.handleTimeupdateAudioPlayer);
			if (this.recordedAudioSrc) {
				this.recordedAudioDuration = await getBlobDuration(this.recordedAudioSrc);
			}
		},
		async removeEventAudio(){
			this.$refs.audioPlayerPvr?.removeEventListener('timeupdate', this.handleTimeupdateAudioPlayer);
			if (this.stream !== null) {
				await this.deactivateRecorder();
			}

			this.stream = null
			this.recorder = null
			this.recordedVoiceSrc = null

			this.recordTime = 0
			this.recordTimeInterval = null
			this.recordedAudioSrc = null
			this.recordedAudioCurrentTime = 0
			this.recordedAudioDuration = 0

			this.playControlState = 'play'
			this.recordControlState = 'record'
		},
		getSuggestionScript(lang) {
			getSVM(lang)
			.then((response) => {
				if(response.status == 200) {
					this.suggestionScript.text = response.data.suggest;
          			this.suggestionScript.id_dataset_suggestion = response.data._id.$oid
				}
			})
		},

		generateVm(url, name) {
			let formData = {
				id_dataset_suggestion : this.suggestionScript.id_dataset_suggestion,
				lang : this.selectedLanguage,
				character_name : name,
				base64_mp3 : url,
				type: this.selected
			}
			generateVoiceModel(formData)
			.then((response) => {
				if(response.status == 200) {
					this.load_save_record = false
					this.$bvModal.hide('ttv-save-recorded-voice');
					this.$bvModal.show('ttv-successfully-record');
					this.$emit('reset')
				} else if(response.status == 403) {
					this.$bvModal.hide('ttv-save-recorded-voice');
					this.$bvModal.show('qm-modal')
				} else if(response.status == 405 && this.selected == 'multilingual') {
					this.$bvModal.hide('ttv-save-recorded-voice');
					this.$bvModal.show('vcm-modal')
				} else {
					this.$bvModal.hide('ttv-save-recorded-voice');
					this.$bvModal.show('server-error-modal')
				}
				this.load_save_record = false
			})
			.catch((error) => {
				console.error(error)
				if(this.selected == 'multilingual') {
					this.$bvModal.hide('ttv-save-recorded-voice');
					this.$bvModal.show('vcm-modal')
				} else {
					this.$bvModal.hide('ttv-save-recorded-voice');
					this.$bvModal.show('server-error-modal')
				}
				this.load_save_record = false
			})
		},

		async handleSave(name) {
			this.load_save_record = true

			this.convertToBase64(name)
		},

		async convertToBase64(name) {
			try {
				const response = await fetch(this.recordedAudioSrc);
				const blob = await response.blob();

				const reader = new FileReader();
				reader.onloadend = () => {
					const base64Data = reader.result;
					this.generateVm(base64Data, name)
				};

				reader.readAsDataURL(blob);
			} catch (error) {
				console.error('Error converting audio to base64:', error);
			}
		},

		async handleClickRecordControl() {
			if (this.recordControlState === 'record') {
				this.recordControlState = 'loading';

				this.recordedAudioSrc = null;

				await this.activateRecorder();
				await this.recorder.startRecording();

				this.recordTime = 0;
				this.recordTimeInterval = setInterval(() => this.recordTime++, 1000);

				this.recordControlState = 'stop';
			} else if (this.recordControlState === 'stop') {
				this.recordControlState = 'loading';

				clearInterval(this.recordTimeInterval);
				this.recordTime = 0;

				await this.recorder.stopRecording((blob) => {
					this.recordedAudioSrc = blob;
				});
				await this.deactivateRecorder();


				this.stream = null;
				this.recorder = null;

				this.recordControlState = 'record';
			}
		},
		async handleClickPlayControl() {
			if (this.playControlState === 'play') {
				this.playControlState = 'loading';
				await this.$refs.audioPlayerPvr.play();
				this.playControlState = 'pause';
			} else if (this.playControlState === 'pause') {
				this.playControlState = 'loading';
				await this.$refs.audioPlayerPvr.pause();
				this.playControlState = 'play';
			}
		},
		async activateRecorder() {
			try {
				this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				this.recorder = new RecordRTC(this.stream, { type: 'audio' });
			} catch (error) {
				console.log(error);
			}
		},
		async deactivateRecorder() {
			for (const track of this.stream.getTracks()) {
				track.stop();
			}
		},
		formatTime(secs) {
			const minutes = Math.floor(secs / 60);
			const seconds = Math.floor(secs % 60);
			const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
			return `${minutes}:${returnedSeconds}`;
		},
		async handleTimeupdateAudioPlayer() {
			this.recordedAudioCurrentTime = this.$refs.audioPlayerPvr.currentTime;

			const duration = await getBlobDuration(this.recordedAudioSrc);

			if (this.$refs.audioPlayerPvr.currentTime >= duration && this.$refs.audioPlayerPvr.paused) {
				this.playControlState = 'play';
			}
		},
		closeModalPvr(){
			this.$emit('update-table')
			this.$bvModal.hide('ttv-successfully-record')
			this.$bvModal.hide('pvi-modal')
			this.$bvModal.hide('pvrecord-modal')
		}
    },
};
</script>

<style scoped>
.pvr__lang {
	font-size: 14px;
	color: #fff;
	font-weight: normal;
}

.instuctions--button {
	color: #6D75F6;
	font-size: 20px;
	font-weight: 600;
	gap: 4px;
}

.title__lang{
	font-size: 12px;
}
.fa-chevron-down{
	font-size: 12px;
}

.pvr__header--lang {
	gap: 6px;
}

.pvr__suggestion--text {
	font-size: 22px !important;
	/* font-weight: 400; */
	color: #fff;
	line-height: normal;
}

.pvr__record--wrapper {
	border-radius: 8px;
	background: #2D2D2D;
	gap: 12px;
	padding: 10px 10px;
}

.pvi__countinue--button {
    background-color: #6D75F6;
    padding: 5px 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;

    transition: background-color 0.25s;
}

.pvi__countinue--button:hover {
    background-color: #545EED;
}

.pvr__record-default-wave {
  display: flex;
  width: 600px;
  height: 86px;
}

.pvr__record-default-wave-inner {
  background-color: #6D75F6;
  width: 100%;
  height: 2px;
  margin-top: -6px;
}

.pvr__record-controls {
  gap: 8px;
}

.pvr__record-time {
  font-size: 12px;
}

.pvr__record-control {
  width: 45px;
  height: 45px;
  font-size: 20px;
  transition: background-color 0.25s, color 0.25s;
}

.pvr__record-control--record {
  background-color: #1F1F1F;;
  color: #C43D30;
}

.pvr__record-control--record:hover {
  background-color: #333333;
}

.pvr__record-control--stop {
  background-color: #C43D30;
  color: #FFFFFF;
}

.pvr__record-control--stop:hover {
  background-color: #DC4638;
}

.pvr__record-control--loading {
  background-color: #2D2D2D;
  color: #FFFFFF;
}

.pvr__record-status {
  font-size: 12px;
}

.pvr__buttons {
  gap: 12px;
}

.pvr__play-control {
  width: 100px;
  gap: 6px;
  background-color: transparent;
  border-width: 2px;
  font-weight: 600;
  transition: background-color 0.25s, border-color 0.25s, color 0.25s;
}

.pvr__play-control--play {
  border-color: #6D75F6;
  color: #6D75F6;
}

.pvr__play-control--play:not(:disabled):hover {
  background-color: #6D75F633;
}

.pvr__play-control--pause {
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.pvr__play-control--pause:not(:disabled):hover {
  background-color: #FFFFFF22;
}

.pvr__play-control:disabled {
  border-color: #1F1F1F;
  color: #585859;
}

.pvr__next-button {
  width: 100px;
  background-color: #6D75F6;
  border-radius: 6px;
  border-width: 2px;
  color: #FFFFFF;
  font-weight: 600;
  transition: background-color 0.25s, color 0.25s;
}

.pvr__next-button:not(:disabled):hover {
  background-color: #545EED;
}

.pvr__next-button:disabled {
  background-color: #1F1F1F;
  color: #585859;
}
</style>

<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#pvrecord-modal .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#pvrecord-modal .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
</style>

<style>
.pvr__language-toggle {
  gap: 4px;
  padding: 2px 6px;
  background-color: #2D2D2D !important;
  border: none;
  border-radius: 16px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.25s;
}

.pvr__language-toggle:hover, .show > .pvr__language-toggle {
  background-color: #4D4D4D !important;
}

.pvr__language-menu {
  min-width: auto;
  background-color: #2D2D2D;
}

.pvr__language-button {
  gap: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.25s;
}

.pvr__language-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>