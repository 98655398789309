<template>
    <b-modal :id="id" centered hide-header hide-footer dialog-class="vsu__dialog d-flex align-items-center justify-content-center" content-class="vsu" size="md">
        <div class="float-right">
            <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide(id)"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
        </div>
        <div class="mt-5 text-center text-white mb-3 px-3">
            <img src="@/assets/images/icons/modal_warning.svg" alt="Video Duration Exceeds" class="img-fluid">
            <div class="mt-3">
                <h4 class="font-weight-bold mb-2">{{ type }} Service Unavailable</h4>
                <h5 class="font-weight-normal mt-3">We're having trouble generating the voice. Please try again in a bit or refresh the page. Thank you for your patience!</h5>
				<p class="contact-us mb-0 mt-3">**contact our customer service <span class="cursor-pointer" @click="whatsapp">here</span>.</p>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <button class="btn btn-purple" @click="$bvModal.hide(id)"><p class="mx-2 mb-0 font-weight-bold">Try Again</p></button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
		id: {
			type: String,
			default: 'voice-service-unavailable'
		},
        type: {
            type: String,
            default: 'Monolingual'
        }
    },
	methods: {
		whatsapp(){
            let phoneNumber = "6282126719955"
            let url = "https://wa.me/" + phoneNumber;
            window.open(url, "_blank");
        },
	}
}
</script>

<style scoped>
.btn-purple {
    background-color: #6D75F6;
    color: white;
    box-shadow: none !important;
    border: none;
	transition: background-color 0.25s;
}

.btn-purple:hover {
	background-color: #545EED;
}

.contact-us {
	color: #FFF;
	text-align: center;
	font-family: Avenir Next;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.contact-us span {
	color: #6D75F6;
	font-family: Avenir Next;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

.vsu__dialog {
  max-width: none;
}

.vsu {
  width: 420px;
  background-color: #1F1F1F;
  border-radius: 10px;
}
</style>