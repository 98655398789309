<template>
<div>
  <div v-if="type == 'question'">
    <Toolbar >
      <ToolbarItem
        name="Bold"
        :isActive="editor?.isActive('bold')"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <font-awesome-icon icon="fa-solid fa-bold" />
      </ToolbarItem>
      <ToolbarItem
        name="Italic"
        :isActive="editor?.isActive('italic')"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <font-awesome-icon icon="fa-solid fa-italic" />
      </ToolbarItem>
      <ToolbarItem
        name="Underline"
        :isActive="editor?.isActive('underline')"
        @click="editor.chain().focus().toggleUnderline().run()"
      >
        <font-awesome-icon icon="fa-solid fa-underline" />
      </ToolbarItem>
      <ToolbarItem
        name="Align Left"
        :isActive="editor?.isActive({ textAlign: 'left' })"
        @click="editor.chain().focus().setTextAlign('left').run()"
      >
        <font-awesome-icon icon="fa-solid fa-align-left" />
      </ToolbarItem>
      <ToolbarItem
        name="Align Center"
        :isActive="editor?.isActive({ textAlign: 'center' })"
        @click="editor.chain().focus().setTextAlign('center').run()"
      >
        <font-awesome-icon icon="fa-solid fa-align-center" />
      </ToolbarItem>
      <ToolbarItem
        name="Align Right"
        :isActive="editor?.isActive({ textAlign: 'right' })"
        @click="editor.chain().focus().setTextAlign('right').run()"
      >
        <font-awesome-icon icon="fa-solid fa-align-right" />
      </ToolbarItem>
      <ToolbarItem
        name="Align Justify"
        :isActive="editor?.isActive({ textAlign: 'justify' })"
        @click="editor.chain().focus().setTextAlign('justify').run()"
      >
        <font-awesome-icon icon="fa-solid fa-align-justify" />
      </ToolbarItem>
      <ToolbarItem
        name="Text Color"
        :popover="{
          component: TextColorPopover,
          props: {
            color: editor?.getAttributes('textStyle').color || '#ffffff',
            onSet(color) {
              editor
                .chain()
                .focus()
                .setColor(color)
                .run();
            },
            onUnset() {
              editor
                .chain()
                .focus()
                .unsetColor()
                .run();
            },
          },
        }"
      >
        <div class="d-flex flex-column align-items-center">
          <font-awesome-icon icon="fa-solid fa-a" />
          <div
            :style="{
              height: '3px',
              width: 'calc(100% + 2px)',
              marginTop: '1px',
              backgroundColor: editor?.getAttributes('textStyle').color || '#FFFFFF',
            }"
          />
        </div>
      </ToolbarItem>
    </Toolbar>
  </div>
  <div v-else class="tlbr--answer">
    <Toolbar>
      <ToolbarItem
        name="Image"
        :popover="{
          component: ImagePopover,
          props: {
            onInsert({ src, alt }) {
              editor.chain().focus().setImage({ src, alt, title: alt }).run();
            },
          },
        }"
      >
        <font-awesome-icon icon="fa-solid fa-image" />
      </ToolbarItem>
    </Toolbar>
  </div>
</div>

</template>

<script>
import Toolbar from '@/components/creator-text-editor/Toolbar';
import ToolbarItem from '@/components/creator-text-editor/ToolbarItem';
import TextColorPopover from '@/components/creator-text-editor/popovers/TextColorPopover';
import ImagePopover from '@/components/creator-text-editor/popovers/ImagePopover';

export default {
  components: {
    Toolbar,
    ToolbarItem,
  },
  props: {
    editor: {
      type: Object,
      default() {
        return null;
      },
    },
    type: {
      validator(value) {
        return ['question', 'answer'].includes(value);
      },
      default: 'question',
    },
  },
  data() {
    return {
      TextColorPopover,
      ImagePopover,
    };
  },
};
</script>

<style scoped>
.tlbr--answer {
  position: absolute;
  z-index: 1;
}
</style>
