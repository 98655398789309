<template>
  <div class="ag d-flex flex-column position-relative" :class="{ 'px-4 pt-3' : !withHeader }">
    <div v-if="withHeader" class="ag__header d-flex align-items-center">
      <img :src="data.voiceType?.character?.avatarSrc" :alt="data.voiceType?.character?.name" class="ag__header--img">
      <span class="ag__header--text text-left">{{ $t('generate-heading', { name : data.voiceType?.character?.name }) }}</span>
    </div>
    <section class="ag__top d-flex flex-column" :class="{ 'px-4 pt-3' : withHeader }">
      <div v-if="useInputText" class="position-relative" ref="suggestionAi">
        <input
          ref="inputText"
          type="text"
          v-model="data.text"
          class="ag__input-text text-white"
          spellcheck="false"
          title="Type prompt here ..."
          @click="!data.text ? $emit('show-agi', true) : ''"
        />
        <AiGeneratorSuggestion :is-visible="showSuggestion" @suggest-prompt="(val) => data.text = val"/>
      </div>
      <textarea
        v-else
        ref="inputText"
        v-model="data.text"
        class="ag__textarea text-white"
        spellcheck="false"
        title="Type prompt here ..."
        @keydown.enter="$emit('generate')"
      />
    </section>

    <section class="ag__bottom d-flex align-items-center" :class="{ 'px-4' : withHeader }">
      <b-dropdown
        v-if="!disableOptions.scriptType"
        boundary="window"
        toggle-class="ag__script-type-toggle d-inline-flex align-items-center p-0 rounded-0"
        menu-class="ag__script-type-menu"
        no-caret
        title="Select Script Type"
      >
        <template #button-content>
          {{ data.scriptType.name }}
          <font-awesome-icon
            icon="fa-solid fa-chevron-down"
            class="ag__script-type-toggle-icon"
          />
        </template>
        <b-dropdown-item-button
          v-for="scriptType in scriptTypeList"
          :key="scriptType.id"
          button-class="ag__script-type-button px-3 py-2 text-white"
          :title="scriptType.name"
          @click="data.scriptType = scriptType"
        >
          {{ scriptType.name }}
        </b-dropdown-item-button>
      </b-dropdown>

      <button
        v-if="!disableOptions.voiceType"
        type="button"
        class="ag__voice-type-button btn d-inline-flex align-items-center text-white"
        title="Select Voice Type"
        @click="$emit('click:voice-type')"
      >
        <img
          :src="data.voiceType?.language?.flagSrc"
          height="14"
          :alt="data.voiceType?.language?.name"
        />
        {{ data.voiceType?.language?.code }}
      </button>

      <!-- <tippy
        v-if="!disableOptions.settings"
        size="small"
        trigger="click"
        boundary="window"
        placement="bottom-start"
        theme="ag__settings"
        :distance="5"
        :animate-fill="false"
        :duration="250"
        :on-show="() => isSettingsShown = true"
        :on-hide="() => {
          isSettingsShown = false;
          return true;
        }"
        interactive
      >
        <template v-slot:trigger>
          <button
            class="ag__settings-toggle btn d-inline-flex align-items-center px-2 py-1 text-white"
            :class="{ 'ag__settings-toggle--active': isSettingsShown }"
            :title="`${isSettingsShown ? 'Hide' : 'Show'} Settings`"
          >
          Settings
          </button>
        </template>

        <slot name="settings" />
      </tippy> -->

      <button
        type="button"
        class="ag__generate-button btn d-inline-flex align-items-center px-2 py-1 ml-auto"
        title="Generate"
        :disabled="disableGenerate || isGenerating"
        @click="$emit('generate')"
      >
        <div v-show="isGenerating">
          <b-spinner
            variant="light"
            label="Loading..."
            small
          />
        </div>
        {{ isGenerating ? 'Generating' : 'Generate' }}
      </button>
    </section>

  </div>
</template>

<script>
import AiGeneratorSuggestion from './Suggestion.vue'
export default {
  components : {
    AiGeneratorSuggestion
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        text: '',
        scriptType: {
          id: 'lessons',
          name: this.$t('lesson'),
        },
        voiceType: null,
        engine: null,
      }),
    },
    placeholders: {
      type: Array,
      default: () => [],
    },
    disableGenerate: {
      type: Boolean,
      default: false,
    },
    disableOptions: {
      type: Object,
      default: () => ({
        scriptType: false,
        voiceType: false,
        settings: false,
      }),
    },
    isGenerating: {
      type: Boolean,
      default: false,
    },
    useInputText: {
      type: Boolean,
      default: false,
    },
    showSuggestionAgi: {
      type: Boolean,
      default: false,
    },
    withHeader: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['input', 'generate', 'click:voice-type'],
  data() {
    return {
      data: this.value,
      showSuggestion: this.showSuggestionAgi,
      scriptTypeList: [
        {
          id: 'lessons',
          name: this.$t('lesson'),
        },
        {
          id: 'PRESENTATION',
          name: this.$t('presentation'),
        },
        {
          id: 'PROMOTION',
          name: this.$t('promotion'),
        },
        {
          id: 'STORYTELLING',
          name: this.$t('storytelling'),
        },
      ],
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.data = newValue;
      },
      deep: true,
    },
    data: {
      handler(newData) {
        this.$emit('input', newData);
      },
      deep: true,
    },
    'data.text'() {
      if (!this.useInputText) {
        this.autoResizeTextarea();
      }
      this.$emit('show-agi', false)
    },
    showSuggestionAgi(newVal) {
      this.showSuggestion = newVal
    }
  },
  methods: {
    typingTextareaPlaceholder() {
      const speed = 100;
      const waitingStep = 50;

      let activePlaceholderIndex = 0;
      let currentChar = 0;
      let currentWaitingStep = 0;
      let placeholder = '';

      this.$watch('placeholders', () => {
        activePlaceholderIndex = 0;
        currentChar = 0;
        currentWaitingStep = 0;
        placeholder = '';
      });

      this.typingPlaceholderInterval = () => {
        if (this.placeholders && this.$refs.inputText) {
          if (this.$refs.inputText.getAttribute('placeholder') === this.placeholders[activePlaceholderIndex]) {
            if (currentWaitingStep >= waitingStep) {
              if (activePlaceholderIndex < this.placeholders.length - 1)  {
                activePlaceholderIndex++;
              } else {
                activePlaceholderIndex = 0;
              }

              currentChar = 0;
              currentWaitingStep = 0;
              placeholder = '';

              this.$refs.inputText.setAttribute('placeholder', placeholder);
            } else {
              currentWaitingStep++;
            }
          } else {
            placeholder += this.placeholders[activePlaceholderIndex].charAt(currentChar);
            this.$refs.inputText.setAttribute('placeholder', placeholder);
            currentChar++;
          }
        }
      };

      setInterval(this.typingPlaceholderInterval, speed);
    },
    autoResizeTextarea() {
      const defaultHeight = 40;

      this.$refs.inputText.style.height = defaultHeight + 'px';
      if (this.data.text.trim() !== '') {
        this.$refs.inputText.style.height = this.$refs.inputText.scrollHeight + 'px';
      }
    },
    hidePopoverWhenClickOutside(e) {
      if (!this.$refs.suggestionAi?.contains(e.target) && this.showSuggestion) {
        this.$emit('show-agi', false)
      }
    },
  },
  mounted() {
    this.typingTextareaPlaceholder();
    document.addEventListener('click', this.hidePopoverWhenClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hidePopoverWhenClickOutside);
  },
};
</script>

<style scoped>
.ag {
  border-radius: 8px;
  border: 1px solid #6D75F6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ag__header {
  width: 100%;
  background: #2d2d2d;
  padding: 12px;
  border-radius: 8px 8px 0px 0px;
  gap: 6px;
}

.ag__header--text {
  font-size: 14px;
  font-weight: 400;
}

.ag__input-text {
  width: 100%;
  background: none;
  border: none;
}

.ag__input-text::placeholder {
  color: #585859;
}

.ag__textarea {
  width: 100%;
  height: 40px;
  max-height: 100px;
  background: none;
  border: none;
  resize: none;
}

.ag__textarea::placeholder {
  color: #585859;
}

.ag__bottom {
  gap: 8px;
  padding-block: 12px;
}

.ag__script-type-toggle-icon {
  font-size: 8px;
}

.ag__settings-toggle-icon {
  height: 14px;
  transition: rotate 0.5s;
}

.ag__voice-type-button {
  gap: 4px;
  padding: 2px 6px;
  border-radius: 16px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ag__voice-type-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.ag__settings-toggle {
  gap: 4px;
  background-color: #414141;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ag__settings-toggle--active {
  background-color: #515151;
}

.ag__settings-toggle:hover {
  background-color: #515151;
}

.ag__settings-toggle-icon--active {
  rotate: 270deg;
}

.ag__generate-button {
  gap: 8px;
  background-color: #6D75F6;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.25s;
}

.ag__generate-button:not(:disabled):hover {
  background-color: #545EED;
}

.ag__generate-button:disabled {
  background-color: #4D4D4D;
}

.ag__header--img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>

<style>
.ag__script-type-toggle {
  gap: 4px;
  background: none !important;
  border: none;
  font-size: 12px;
}

.ag__script-type-menu {
  min-width: auto;
  background-color: #2D2D2D;
}

.ag__script-type-button {
  font-size: 12px;
  transition: background-color 0.25s;
}

.ag__script-type-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.ag__settings-toggle {
  gap: 4px;
  background-color: #414141 !important;
  border-radius: 4px;
  border: none;
  font-size: 12px;
}

.ag__settings-toggle:hover, .show > .ag__settings-toggle {
  background-color: #515151 !important;
}

.show > .ag__settings-toggle .ag__settings-toggle-icon {
  rotate: 270deg;
}

.ag__settings-menu {
  min-width: auto;
  background-color: #2D2D2D;
}
</style>
