<template>
  <b-modal
      :id="id"
      size="sm"
      dialog-class="ttvr__dialog"
      content-class="ttvr mx-auto text-white text-center"
      body-class="ttvr__body d-flex flex-column p-3"
      centered
      hide-header
      hide-footer
      @hidden="$emit('rest-vs'), formData.text = '', formData.speaker = ''"
    >
      <button
        type="button"
        class="ttvr__close-button btn align-self-end d-inline-flex justify-content-center align-items-center p-0 rounded-circle"
        title="Close"
        @click="$bvModal.hide(id)"
      >
        &times;
      </button>
      <h2 class="ttvr__title px-4 m-0">Voice Clone Sample</h2>
      <ul class="ttvr__sample-list d-flex px-3 mt-3 mb-1 list-unstyled">
        <li v-for="(sample, index) in samples" :key="index">
          <button
            type="button"
            class="ttvr__sample-button btn position-relative d-flex flex-column align-items-center p-0"
            :class="{ 'ttvr__sample-button--selected': sample.id === formData.speaker }"
            :title="`Select ${sample.name}`"
            @click="formData.speaker = sample.id, activePlaceholder = index"
          >
            <img
              :src="sample.imageSrc"
              class="ttvr__sample-image"
              :alt="sample.name"
            />
            <div
              class="ttvr__sample-checkbox position-absolute d-inline-flex justify-content-center align-items-center"
              :class="{ 'ttvr__sample-checkbox--checked': sample.id === formData.speaker }"
            >
              <font-awesome-icon
                v-if="sample.id === formData.speaker"
                icon="fa-solid fa-check"
              />
            </div>
          </button>
          <h5 class="mt-3">{{ sample.name }}</h5>
          <span class="ttvr--desc">{{ sample.description }}</span>
        </li>
      </ul>
      <div class="ttvr__textarea-container d-flex flex-column align-items-end mx-3 mb-5">
        <textarea
          v-model="formData.text"
          class="ttvr__textarea px-3 py-2 text-white"
          spellcheck="false"
          ref="inputText"
          :placeholder="activePlaceholderValue"
        />
        <button
          type="button"
          class="ttvr__play-button btn d-inline-flex justify-content-center align-items-center px-3 py-1 m-2 text-white"
          :title="isGenerate ? 'Generating...' : isPlay ? 'Pause' : 'Play'"
          :disabled="!formData.text || !formData.speaker || isGenerate || loadingAudio"
          @click="$emit('play-voice-clone-sample', formData)"
        >
          <font-awesome-icon v-if="!isGenerate" :icon="isPlay ? 'fa-solid fa-pause' : 'fa-solid fa-play'" />
          {{ isGenerate ? 'Generating...' : loadingAudio ? 'loading...' : isPlay ? 'Pause' : 'Play' }}
        </button>
      </div>
      <!-- <router-link
        to="/text-to-video/record-voice"
        class="ttvr__create-button btn align-self-center text-white px-5 my-2"
        title="Create"
        append
      >
        Create
      </router-link> -->
      <button
        class="ttvr__create-button btn align-self-center text-white px-5 my-2"
        title="Create"
        type="button"
        @click="$emit('create-vc')"
      >
        Create
      </button>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'ttv-record-voice'
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    isPlay: {
      type: Boolean,
      default: false,
    },
    loadingAudio: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sampleText: '',
      selectedSample: '',
      typingPlaceholderInterval: null,
      activePlaceholder: 0,
      activePlaceholderValue: "",
      formData: {
        text: '',
        speaker: ''
      },
      samples: [
        {
          id: '1',
          name: 'Joko Widodo',
          imageSrc: require('@/assets/images/joko-widodo.jpg'),
          description: "President of the Republic of Indonesia"
        },
        {
          id: '2',
          name: 'Deddy Corbuzier',
          imageSrc: require('@/assets/images/deddy-corbuzier.jpg'),
          description: "Indonesia's Top Podcaster"
        },
      ],
      placeholder: ["Hai semuanya! saya adalah Joko Widodo, Presiden Indonesia.", "Saya adalah seorang youtuber dan dikenal dengan nama Deddy Corbuzier"]
    };
  },
  watch: {
    activePlaceholder() {
     this.startTyping()
    }
  },
  methods: {
    startTyping(){
      clearInterval(this.typingInterval);
      this.activePlaceholderValue = "";
      this.typingTextareaPlaceholder();
    },
    typingTextareaPlaceholder() {
      const speed = 100;
      let currentChar = 0;
      const placeholder = this.placeholder[this.activePlaceholder];

      this.typingInterval = setInterval(() => {
        if (currentChar < placeholder.length) {
          this.activePlaceholderValue += placeholder.charAt(currentChar);
          currentChar++;
        } else {
          clearInterval(this.typingInterval);
          setTimeout(() => {
            this.startTyping();
          }, 1000);
        }
      }, speed);
    },
  },
  mounted() {
    this.typingTextareaPlaceholder()
  },
};
</script>

<style scoped>
.ttvr__close-button {
  width: 20px;
  height: 20px;
  background-color: #D9D9D9;
  color: #1C1C1C;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ttvr__close-button:hover {
  background-color: #FFFFFF;
}

.ttvr__title {
  font-size: 18px;
  font-weight: 700;
}

.ttvr__sample-list {
  gap: 20px;
}

.ttvr__sample-button {
  gap: 12px;
  border-radius: 10px;
  border: 2px solid transparent;
  overflow: hidden;
  transition: border-color 0.25s;
}

.ttvr__sample-button:not(.ttvr__sample-button--selected):hover {
  border-color: #6D75F633;
}

.ttvr__sample-button--selected {
  border-color: #6D75F6;
}

.ttvr__sample-image {
  width: 170px;
  height: 205px;
}

.ttvr__sample-checkbox {
  inset: 8px 0 0 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  font-size: 12px;
  transition: background-color 0.25s, border-color 0.25s;
}

.ttvr__sample-checkbox--checked {
  background-color: #6D75F6;
  border-color: #6D75F6;
  color: #FFFFFF;
}


.ttvr__textarea-container {
  background-color: #2D2D2D;
  border-radius: 6px;
  border: 1px solid #6D75F6;
  overflow: hidden;
}

.ttvr__textarea {
  width: 100%;
  height: 50px;
  overflow-y: auto;
  background: none;
  border: none;
  font-size: 12px;
  resize: none;
}

.ttvr__textarea::placeholder {
  color: #585859;
}

.ttvr__play-button {
  gap: 6px;
  background-color: #6D75F6;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ttvr__play-button:not(:disabled):hover {
  background-color: #545EED;
}

.ttvr__create-button {
  background-color: #6D75F6;
  font-weight: 600;
  transition: background-color 0.25s;
}

.ttvr__create-button:hover {
  background-color: #545EED;
}

.ttvr--desc {
  color: #999999;
  font-size: 12px;
  line-height: 17px;
}
</style>

<style>
.ttvr__dialog {
  max-width: none;
}

.ttvr {
  width: 450px;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.ttvr__body {
  gap: 16px;
}
</style>

