<template>
  <ModalContainer
    :name="name"
    title="Insert Youtube Video"
    width="700"
    save-text="Use"
    :disable-save="!isValid"
    @before-open="beforeOpenModal"
    @closed="isFresh = true; videoUrl = value; videoId = null"
    @save="$emit('input', videoUrl)"
  >
    <div class="iym__body d-flex flex-column px-2">
      <label
        for="input-video-url"
        class="iym__label m-0"
      >
        Paste Youtube link here
      </label>
      <div class="iym__input-wrapper d-flex">
        <input
          type="text"
          id="input-video-url"
          v-model="videoUrl"
          class="iym__input-field flex-grow-1"
          placeholder="https://www.youtube.com/soca-ai/"
          spellcheck="false"
          title="Video URL"
        />
        <button
          type="button"
          class="iym__input-button btn"
          title="Find Video"
          :disabled="!videoUrl"
          @click="findVideo"
        >
          Find
        </button>
      </div>
      <div class="iym__preview-wrapper d-flex justify-content-center align-items-center">
        <iframe
          v-if="isValid"
          :src="`https://www.youtube.com/embed/${videoId}`"
          class="iym__preview-iframe"
        />
        <p v-else-if="isFresh" class="iym__preview-empty-message m-0">
          Video Preview
        </p>
        <div v-else class="iym__preview-invalid-wrapper d-flex flex-column align-items-center"
        >
          <span class="iym__preview-invalid-icon d-flex justify-content-center align-items-center">&times;</span>
          <p class="iym__preview-invalid-message m-0">Invalid Video</p>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';

export default {
  components: {
    ModalContainer,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'insert-youtube-modal',
    },
  },
  emits: ['input'],
  data() {
    return {
      videoUrl: this.value,
      videoId: null,
      isFresh: true,
      isValid: false,
    };
  },
  methods: {
    getYoutubeVideoId(url) {
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      let match = url.match(regExp);
      return (match && match[7].length === 11) ? match[7] : null;
    },
    findVideo(fromButton = true) {
      this.videoId = this.getYoutubeVideoId(this.videoUrl);

      if (this.videoId) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }

      if (fromButton) {
        this.isFresh = false;
      }
    },
    beforeOpenModal() {
      this.findVideo(false);
      if (this.videoUrl) {
        this.isFresh = false;
      }
    },
  },
  watch: {
    value(newValue) {
      this.videoUrl = newValue;
    },
  },
};
</script>

<style scoped>
.iym__body {
  gap: 16px;
}

.iym__label {
  font-size: 18px;
}

.iym__input-wrapper {
  border: 1px solid #585859;
  border-radius: 6px;
  overflow: hidden;
  transition: border 0.25s;
}

.iym__input-wrapper:focus-within {
  border-color: #999999;
}

.iym__input-field {
  padding: 12px 16px;
  background: none;
  border: none;
  font-size: 14px;
  color: #FFFFFF;
}

.iym__input-field::placeholder {
  color: #585859;
}

.iym__input-button {
  background: none;
  padding: 12px 16px;
  font-weight: 600;
  color: #6D75F6;
  transition: color 0.25s;
}

.iym__input-button:disabled {
  color: #585859;
}

.iym__preview-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.iym__preview-wrapper {
  height: 300px;
  background-color: #2D2D2D;
  border-radius: 6px;
  overflow: hidden;
}

.iym__preview-invalid-wrapper {
  gap: 16px;
}

.iym__preview-invalid-icon {
  width: 64px;
  height: 64px;
  border: 3px solid #6D75F6;
  border-radius: 50%;
  font-size: 64px;
  color: #6D75F6;
  user-select: none;
}

.iym__preview-empty-message, .iym__preview-invalid-message {
  font-size: 16px;
  font-weight: 500;
  color: #8C8C8C;
  user-select: none;
}
</style>
