<template>
  <section class="video-section d-flex flex-column align-items-center py-4">
    <video
      v-show="finalResultTtv"
      ref="videoPlayer"
      :src="finalResultTtv"
      class="video-section__video-player mx-4"
      controls
      @play="handlePlayVideo"
      @timeupdate="handleUpdateTimeVideo"
    />
    <div v-if="!finalResultTtv" class="video-section__null d-flex align-items-center justify-content-center">
      <p>No previews yet</p>
    </div>
    <CharacterChoices v-model="selectedCharacter" :disabled-action="disableAnyAction" :show-suggestion-agi="showSuggestion" @show-agi="(newVal) => this.$emit('show-agi', newVal)"/>
  </section>
</template>

<script>
import CharacterChoices from '../character-choices';

export default {
  components: {
    CharacterChoices,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    finalResultTtv: {
      type: String,
      default: null,
    },
    playVideo: {
      type: Boolean,
      default: false,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    showSuggestionAgi: {
      type: Boolean,
      default: false,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedCharacter: this.value,
      videoSrc: 'http://techslides.com/demos/sample-videos/small.mp4',
      isPlay: this.playVideo,
      videoCurrentTime: this.currentTime,
      showSuggestion: this.showSuggestionAgi,
      disableAnyAction: this.disabledAction
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.selectedCharacter = newValue;
      },
      deep: true,
    },
    selectedCharacter: {
      handler(newSelectedCharacter) {
        this.$emit('input', newSelectedCharacter);
      },
      deep: true,
    },
    playVideo(newVal) {
      this.isPlay = newVal
      this.handlePlayVideoTtv(newVal)
    },
    currentTime(newCurrentTime){
      this.videoCurrentTime = newCurrentTime
    },
    showSuggestionAgi(newVal) {
      this.showSuggestion = newVal
    },
    disabledAction(newVal) {
      this.disableAnyAction = newVal
    }
  },
  methods: {
    handlePlayVideo() {
      const playMediaEvent = new CustomEvent('play:media', {
        detail: {
          componentId: this._uid,
        },
      });

      document.dispatchEvent(playMediaEvent);
    },
    handlePlayVideoTtv(val){
      // PLAY VIDEO
      const video = this.$refs.videoPlayer;
      if(val == true) {
        video.currentTime = this.videoCurrentTime
        video.play();
      } else {
        video.pause()
      }

      video.addEventListener('ended', () => {
        this.$emit('video-ended', false)
      });
    },
    handleUpdateTimeVideo(){
      const video = this.$refs.videoPlayer;
      this.$emit('update:current-time', video.currentTime)
    },
    async handlePlayMedia(e) {
      if (e.detail.componentId !== this._uid && !this.$refs.videoPlayer.paused) {
        try {
          await this.$refs.videoPlayer.pause();
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  mounted() {
    document.addEventListener('play:media', this.handlePlayMedia);
  },
  beforeDestroy() {
    document.removeEventListener('play:media', this.handlePlayMedia);
  },
};
</script>

<style scoped>
.video-section {
  gap: 16px;
}

.video-section__video-player {
  height: 100%;
  max-height: 40%;
  aspect-ratio: 5 / 3;
  border-radius: 10px;
}

.video-section__null {
  height: 100%;
  max-height: 40%;
}
</style>
