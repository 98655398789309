<template>
  <div class="ttv d-flex flex-column text-white">
    <TTVHeader v-model="fileName" @click:back="handleBack"/>
    <main class="ttv__main">
      <div class="ttv__main-center flex-grow-1">
        <VoiceSection
          :ai-voices="aiVoices"
          :personal-voices="voiceModel"
          :loading-pv="loadingPv"
          :deleted-sei-id="seiDeletedId"
          :generated-pvld="generatedPvLastData"
          :disabled-action="mainControlState == 'generating'"
          @push-to-timeline="handlePushToTimeline"
          @click:record="$bvModal.show('ttv-record-voice')"
          @click:use="handleClickUsePersonalVoice"
          @reset:personal-voices="handleClearVoiceModel"
          @change:ai-voices="(newAiVoices) => aiVoices = newAiVoices"
          @click:generate="handleGenerateUseVoiceModel"
          @delete-se-internal="handleDeleteSeInternal"
          @reset-deleted-sei-id="seiDeletedId = null"
          @update-needle-timeline="handleUpdateNeedleTimeline"
          @update:current-time="handleUpdateCurrentTime"
          @reset:current-time="currentTime = 0"
          @play-pvg="handlePlayPvg"
        />
        <VideoSection v-model="selectedCharacter" :disabled-action="mainControlState == 'generating'" :show-suggestion-agi="showSuggestionAGI" :final-result-ttv="finalPathTtv" :play-video="playTtv" :current-time="currentTime" @update:current-time="(newCurrentTime) => currentTime = newCurrentTime" @video-ended="(newPlayStatus) => playTtv = newPlayStatus" @show-agi="(newVal) => showSuggestionAGI = newVal"/>
      </div>
      <TTVTimeline
        :voices="voicesOnTimeline"
        :musics="musicsOnTimeline"
        :mainControl="mainControlState"
        :disable-main-control="statusDisableMainControl()"
        :current-time-video="currentTime"
        :duration-ttv="durationFinalTtv"
        @final-generate="mainControlState == 'generate' ? $bvModal.show('ttv-input-email') : finalGenerate()"
        @click:add-music="$bvModal.show('add-timeline-music')"
        @delete:voice="handleDeleteVoiceOnTimeline"
        @delete:music="handleDeleteMusicOnTimeline"
        @change:music-duration="handleChangeMusicDuration"
        @change:voices-order="(newVoicesOnTimeline) => voicesOnTimeline = newVoicesOnTimeline"
        @change:musics-order="handleChangeMusicsOrder"
        @music-volume-change="(newMainControlState) => mainControlState = newMainControlState"
        @voice-volume-change="(newMainControlState) => mainControlState = newMainControlState"
        @update:current-time="(newCurrentTime) => currentTime = newCurrentTime"
      />
    </main>

    <TTVInputEmailModal id="ttv-input-email" v-model="email" @generate-final="finalGenerate()" @generate-final-instead="finalGenerate(), email = null"/>
    <TTVTutorialModal id="ttv-tutorial" />
    <TTVRecordVoiceModal id="ttv-record-voice" :is-generate="isGenerateVs" :is-play="isPlayVs" @play-voice-clone-sample="handleVoiceCloneSample" @rest-vs="handleResetVs"/>
    <AddTimelineMusicModal id="add-timeline-music" @use:music="handleAddMusic" />
  </div>
</template>

<script>
import randomUUID from '@/helper/uuid';

import TTVHeader from '@/components/generative-ai-text-to-video/header';
import VoiceSection from '@/components/generative-ai-text-to-video/voice-section';
import VideoSection from '@/components/generative-ai-text-to-video/video-section';
import TTVTimeline from '@/components/generative-ai-text-to-video/timeline';
import TTVRecordVoiceModal from '@/components/modal/TTVRecordVoice';
import TTVTutorialModal from '@/components/modal/TTVTutorial';
import TTVInputEmailModal from '@/components/modal/TTVInputEmail';
import AddTimelineMusicModal from '@/components/modal/AddTimelineMusic';

import { base_url_machine_learning } from "../../../../config/base_url";
import { getImageAiGenerator, voiceCloneSample, getVoiceModel, clearVoiceModel, useVoiceModel, generateFinalTTV } from '@/services/ttv/ttv.service.js'
import { getJobStatus } from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
import { EventBus } from '@/helper/event-bus.js';

export default {
  components: {
    TTVHeader,
    VoiceSection,
    VideoSection,
    TTVTimeline,
    TTVRecordVoiceModal,
    TTVTutorialModal,
    AddTimelineMusicModal,
    TTVInputEmailModal
  },
  data() {
    return {
      fileName: '',
      selectedCharacter: null,
      voiceCloneSampleUrl: null,
      seiDeletedId: null,
      email: null,

      generatedPvLastData: {
        id: null,
        src: null,
        name: null,
        audio: null,
        timelineId: null,
        actionState: 'play'
      },

      voices: [],
      aiVoices: [],
      personalVoices: [],
      voiceModel: [],

      voicesOnTimeline: [],
      musicsOnTimeline: [],

      aiCharacters: [],

      generateCharacter: {
        engine: '2',
        text: null
      },

      finalFormVoiceSample: {
        text: '',
        speaker: ''
      },

      firstOpen: true,
      isGenerateVs: false,
      isPlayVs: false,
      loadingPv: false,
      showSuggestionAGI: false,

      // FINAL
      intervalTtv : null,
      jobsId : null,
      finalPathTtv : null,
      mainControlState : 'generate',
      playTtv : false,
      currentTime : 0,
      durationSum : 0,
      durationFinalTtv : 0,
    };
  },
  watch: {
    aiVoices: {
      handler(newAiVoices) {
        sessionStorage.setItem('ttv::ai-voices', JSON.stringify(newAiVoices));
      },
      deep: true,
    },
    personalVoices(newPersonalVoices) {
      sessionStorage.setItem('ttv::personal-voices', JSON.stringify(newPersonalVoices));
    },
    voicesOnTimeline(newVoicesOnTimeline) {
      sessionStorage.setItem('ttv::voices-on-timeline', JSON.stringify(newVoicesOnTimeline));
      if(sessionStorage.getItem('ttv::voices-on-timeline') == sessionStorage.getItem('ttv::voices-on-timeline-generate')) {
        this.mainControlState = 'play'
      } else {
        this.mainControlState = 'generate'
      }
    },
    musicsOnTimeline: {
      handler(newMusicsOnTimeline) {
        sessionStorage.setItem('ttv::musics-on-timeline', JSON.stringify(newMusicsOnTimeline));
        if(sessionStorage.getItem('ttv::musics-on-timeline') == sessionStorage.getItem('ttv::musics-on-timeline-generate')) {
          this.mainControlState = 'play'
        } else {
          this.mainControlState = 'generate'
        }
      },
      deep: true,
    },
    mainControlState(newMainControlState) {
      sessionStorage.setItem('ttv::main-control-state', newMainControlState)
    },
    playTtv(newPlayTTV){
      if(newPlayTTV) {
        this.mainControlState = 'pause'
      } else {
        this.mainControlState = 'play'
      }
    },
    selectedCharacter: {
      handler(newSelectedCharacter) {
        sessionStorage.setItem('ttv::selected-character', JSON.stringify(newSelectedCharacter))
        if(sessionStorage.getItem('ttv::selected-character') == sessionStorage.getItem('ttv::selected-character-generate')) {
          this.mainControlState = 'play'
        } else {
          this.mainControlState = 'generate'
        }
      },
      deep: true,
    },
  },
  methods: {
    async handleAiVoicesInStorage() {
      const aiVoicesInStorage = await JSON.parse(sessionStorage.getItem('ttv::ai-voices'));

      if (aiVoicesInStorage?.length > 0) {
        this.aiVoices = aiVoicesInStorage;
      } else {
        this.aiVoices = []
        sessionStorage.removeItem('ttv::ai-voices');
      }
    },
    async handlePersonalVoicesInStorage() {
      const personalVoicesInStorage = await JSON.parse(sessionStorage.getItem('ttv::personal-voices'));

      if (personalVoicesInStorage?.length > 0) {
        let newPersonalVoicesInStorage = personalVoicesInStorage;

        for (const personalVoice of personalVoicesInStorage) {
          const isBlobSrc = personalVoice.src.search('blob:') !== -1;

          if (isBlobSrc) {
            let isBlobSrcValid = true;

            try {
              const res = await fetch(personalVoice.src);
              if (!res.blob()) {
                isBlobSrcValid = false;
              }
            } catch (error) {
              isBlobSrcValid = false;
            }

            if (!isBlobSrcValid) {
              newPersonalVoicesInStorage = newPersonalVoicesInStorage.filter((voice) => {
                return voice.id !== personalVoice.id;
              });
              continue;
            }
          }

          this.personalVoices.push(personalVoice);
        }

        if (newPersonalVoicesInStorage.length < 1) {
          sessionStorage.removeItem('ttv::personal-voices');
        }
      } else {
        sessionStorage.removeItem('ttv::personal-voices');
      }
    },
    async handleVoicesOnTimelineInStorage() {
      const voicesOnTimelineInStorage = await JSON.parse(sessionStorage.getItem('ttv::voices-on-timeline'));

      if (voicesOnTimelineInStorage?.length > 0) {
        let newVoicesOnTimelineInStorage = voicesOnTimelineInStorage;

        for (const voice of voicesOnTimelineInStorage) {
          const isBlobSrc = voice.src.search('blob:') !== -1;

          if (isBlobSrc) {
            let isBlobSrcValid = true;

            try {
              const res = await fetch(voice.src);
              if (!res.blob()) {
                isBlobSrcValid = false;
              }
            } catch (error) {
              isBlobSrcValid = false;
            }

            if (!isBlobSrcValid) {
              newVoicesOnTimelineInStorage = newVoicesOnTimelineInStorage.filter((v) => v.timelineId !== voice.timelineId);
              continue;
            }
          }

          this.voicesOnTimeline.push(voice);
        }

        if (newVoicesOnTimelineInStorage.length < 1) {
          sessionStorage.removeItem('ttv::voices-on-timeline');
        }

        this.firstOpen = false;
      } else {
        this.voicesOnTimeline = []
        sessionStorage.removeItem('ttv::voices-on-timeline');
      }
    },
    async handleMusicsOnTimelineInStorage() {
      const musicsOnTimelineInStorage = await JSON.parse(sessionStorage.getItem('ttv::musics-on-timeline'));

      if (musicsOnTimelineInStorage?.length > 0) {
        this.musicsOnTimeline = musicsOnTimelineInStorage;
        this.firstOpen = false;
      } else {
        this.musicsOnTimeline = []
        sessionStorage.removeItem('ttv::musics-on-timeline')
      }
    },
    async handleRecordedVoice() {
      const recordedVoice = await JSON.parse(sessionStorage.getItem('ttv::recorded-voice'));

      if (recordedVoice) {
        this.personalVoices.push({
          ...recordedVoice,
          id: randomUUID(),
        });

        sessionStorage.removeItem('ttv::recorded-voice');
        this.firstOpen = false;
      }
    },
    async getHistoryStorage(){
      await this.handleAiVoicesInStorage();
      await this.handlePersonalVoicesInStorage();
      await this.handleVoicesOnTimelineInStorage();
      await this.handleMusicsOnTimelineInStorage();
      await this.handleRecordedVoice();
      await this.getVoiceModel()

      const mainControlStateStorage = sessionStorage.getItem('ttv::main-control-state')
      const finalPathTtvStorage = sessionStorage.getItem('ttv::final-path-ttv')
      const selectedCharacterStorage = await JSON.parse(sessionStorage.getItem('ttv::selected-character'))
      const personalVoiceLastGeneratedDataStorage = await JSON.parse(sessionStorage.getItem('ttv::personal-voice-last-generated-data'))

      this.mainControlState = mainControlStateStorage ? mainControlStateStorage : 'generate'
      this.finalPathTtv = finalPathTtvStorage
      this.selectedCharacter = selectedCharacterStorage

      if(this.finalPathTtv) {
        this.getAudioDuration(this.finalPathTtv).then((duration) => {
          this.durationFinalTtv = duration
        })
      }

      if(personalVoiceLastGeneratedDataStorage) {
        this.generatedPvLastData =  personalVoiceLastGeneratedDataStorage
        this.generatedPvLastData.audio = new Audio(this.generatedPvLastData.src);
      }
    },
    async getVoiceModel() {
      await getVoiceModel()
      .then((response) => {
        if(response.status == 200) {
          let result = response.data
          this.voiceModel = result
        }
      })
    },
    async handleClearVoiceModel() {
      await clearVoiceModel()
      .then((response) => {
        if(response.status == 200) {
          this.getVoiceModel()
        }
      })
    },
    async finalGenerate() {
      if(this.mainControlState !== 'generate') {
        this.playTtv = !this.playTtv

        const audio = this.generatedPvLastData.audio
        if(audio){
          audio.pause()
          this.generatedPvLastData.actionState = 'play'
        }
      } else {
        let list_tts_id = []

        this.voicesOnTimeline.forEach((e) => {
          list_tts_id.push(e.id)
        })

        let isSelectedCharacterFromGenerate = this.selectedCharacter.type == 'ai' ? true : false
        let music = []

        const voiceVolumeInStorage = sessionStorage.getItem("ttv:voice-volume") ? sessionStorage.getItem("ttv:voice-volume") : 0.5
        const musicVolumeInStorage = sessionStorage.getItem("ttv:music-volume") ? sessionStorage.getItem("ttv:music-volume") : 0.5
        const musicOnTimelineStorage = JSON.parse(sessionStorage.getItem("ttv::musics-on-timeline"))

        if(musicOnTimelineStorage && musicOnTimelineStorage.length > 0 ){
          musicOnTimelineStorage.forEach((e) => {
            music.push({
                "music_id": e.id,
                "start": e.start,
                "end": e.end
            })
          })
        }

        let formData = {
          course_id : this.voicesOnTimeline[0]?.course_id || '',
          params : {
            tts_id : list_tts_id || [],
            music : music || [],
            avatars : {
              id_avatars : !isSelectedCharacterFromGenerate ? this.selectedCharacter.id : null,
              link_url : isSelectedCharacterFromGenerate ? this.selectedCharacter.imageSrc : null
            },
            volume_music : parseFloat(musicVolumeInStorage),
            volume_tts : parseFloat(voiceVolumeInStorage),
            email: this.email || null
          }
        }

        this.mainControlState = "generating"

        await generateFinalTTV(formData)
        .then((response) => {
          console.log(response)
          if(response.status == 200) {
            this.jobsId = response.data.job_id
            if(!this.email) {
              this.email = null
              this.intervalTtv = setInterval(() => {
                this.mainControlState = "generating"
                getJobStatus(this.jobsId)
                .then((res) => {
                  if(res.status == 200) {
                    if(res.data.status === "finish"){
                      this.getAudioDuration(res.data.final_path).then((duration) => {
                        this.finalPathTtv = res.data.final_path + '?v=' + Math.random()
                        this.mainControlState = "play"
                        this.durationFinalTtv = duration
                        sessionStorage.setItem('ttv::voices-on-timeline-generate', JSON.stringify(this.voicesOnTimeline));
                        sessionStorage.setItem('ttv::selected-character-generate', JSON.stringify(this.selectedCharacter));
                        sessionStorage.setItem('ttv::musics-on-timeline-generate', JSON.stringify(this.musicsOnTimeline))
                        sessionStorage.setItem('ttv::final-path-ttv', this.finalPathTtv)
                        sessionStorage.setItem("ttv:voice-volume-generate", voiceVolumeInStorage)
                        clearInterval(this.intervalTtv)
                      })
                    }
                  }
                })
              }, 5000)
            } else {
              this.email = null
              this.mainControlState = "generate"
              this.handleRemoveDataFromStorage()
              this.getHistoryStorage()
            }
          }
        })
      }
    },
    handleClickUsePersonalVoice(id) {
      const selectedPersonalVoice = this.personalVoices.find((voice) => voice.id === id);
      this.voicesOnTimeline.push({ ...selectedPersonalVoice, timelineId: randomUUID() });
    },
    handleAddMusic(music) {
      let start = 0;
      let end = music.duration;
      this.mainControlState = 'generate'

      if (this.musicsOnTimeline.length > 0) {
        start = Number((this.musicsOnTimeline[this.musicsOnTimeline.length - 1].end + 0.01).toFixed(2));
        end = Number((start + music.duration).toFixed(2));
      }

      this.musicsOnTimeline.push({
        timelineId: randomUUID(),
        start,
        end,
        ...music
      });

      this.$bvModal.hide('add-timeline-music');
    },
    handleChangeMusicDuration(index, newDuration) {
      if (newDuration >= 1) {
        this.musicsOnTimeline[index].duration = newDuration;

        for (let i = index; i < this.musicsOnTimeline.length; i++) {
          this.populateMusicStartAndEnd(i);
        }
      }
    },
    handleDeleteVoiceOnTimeline(timelineId, iavId) {
      this.voicesOnTimeline = this.voicesOnTimeline.filter((voice) => voice.timelineId !== timelineId);
      this.seiDeletedId = iavId || null
      if(timelineId == this.generatedPvLastData.timelineId){
        this.generatedPvLastData = {
          id: null,
          src: null,
          name: null,
          audio: null,
          timelineId: null,
          actionState: 'play'
        }
      }
    },
    handleChangeMusicsOrder(musics, oldIndex, newIndex) {
      this.musicsOnTimeline = musics;

      const minIndex = Math.min(...[oldIndex, newIndex]);

      for (let i = minIndex; i < this.musicsOnTimeline.length; i++) {
        this.populateMusicStartAndEnd(i);
      }
    },
    handleDeleteMusicOnTimeline(index) {
      this.musicsOnTimeline.splice(index, 1);

      for (let i = index; i < this.musicsOnTimeline.length; i++) {
        this.populateMusicStartAndEnd(i);
      }
    },
    populateMusicStartAndEnd(index) {
      let start = 0;
      let end = this.musicsOnTimeline[index].duration;

      if (index > 0) {
        start = Number((this.musicsOnTimeline[index - 1].end + 0.01).toFixed(2));
        end = Number((start + this.musicsOnTimeline[index].duration).toFixed(2));
      }

      this.musicsOnTimeline[index].start = start;
      this.musicsOnTimeline[index].end = end;
    },
    handleGenerateImageAiGenerator(){
      let formData = {
        engine: this.generateCharacter.engine, // 1 or 2 String
        text: this.generateCharacter.text
      }

      getImageAiGenerator(formData)
      .then((response) => {
        if(response.status == 200) {
          let resultImage = {
            name : 'Ai ' + this.aiCharacters.length + 1,
            imageSrc : base_url_machine_learning + response.data[0]
          }

          this.aiCharacters.push(resultImage)
        }
      })
    },
    handleVoiceCloneSample(val){
      if(this.finalFormVoiceSample.text == val.text && this.finalFormVoiceSample.speaker == val.speaker) {
        this.playVoiceCloneSample()
      } else {
        this.isGenerateVs = true
        voiceCloneSample(val)
        .then((response) => {
          let audio = new Audio(response.data)
          this.voiceCloneSampleUrl = audio
          this.isGenerateVs = false
          this.playVoiceCloneSample()
        })
      }
      this.finalFormVoiceSample.text = val.text
      this.finalFormVoiceSample.speaker = val.speaker
    },
    handleResetVs(){
      clearInterval(this.voiceCloneSampleUrl.intervalId)
      this.voiceCloneSampleUrl.pause()
      this.voiceCloneSampleUrl = null
      this.isGenerateVs = false
      this.isPlayVs = false
      this.finalFormVoiceSample = {
        text: '',
        speaker: ''
      }
    },
    playVoiceCloneSample(){
      if(!this.voiceCloneSampleUrl.paused) {
        this.isPlayVs = false
        clearInterval(this.voiceCloneSampleUrl.intervalId)
        this.voiceCloneSampleUrl.pause()
      } else {
        this.isPlayVs = true
        this.voiceCloneSampleUrl.play()
        this.voiceCloneSampleUrl.intervalId = setInterval(() => {
          this.voiceCloneSampleUrl.paused ? clearInterval(this.voiceCloneSampleUrl.intervalId) : null;
          if (this.voiceCloneSampleUrl.currentTime === this.voiceCloneSampleUrl.duration) {
            this.isPlayVs = false
          }
        }, 100)
      }
    },
    handleGenerateUseVoiceModel(id, prompt) {
      const aiVoicesInStorage = JSON.parse(sessionStorage.getItem('ttv::ai-voices'));
      const aiVoicesInStorageCourseId = aiVoicesInStorage ? aiVoicesInStorage[0].courseId : null

      this.loadingPv = true;
      let formData = {
        id_models : id,
        text : prompt,
        lang : 'eng',
        course_id : aiVoicesInStorageCourseId || this.voicesOnTimeline[0]?.course_id || null
      }
      useVoiceModel(formData)
      .then((response) => {
        if(response.status == 200) {
          this.getAudioDuration(response.data.path).then((duration) => {
            let result = {
              id : response.data.tts_id,
              src : response.data.path,
              name : 'AI Models ' + this.voicesOnTimeline.length,
              timelineId : randomUUID(),
              duration : duration,
              course_id : this.voicesOnTimeline[0]?.course_id || response.data.course_id
            }

            this.generatedPvLastData.id = result.id
            this.generatedPvLastData.src = result.src
            this.generatedPvLastData.audio = new Audio(result.src)
            this.generatedPvLastData.name = result.name
            this.generatedPvLastData.timelineId = result.timelineId

            sessionStorage.setItem('ttv::personal-voice-last-generated-data', JSON.stringify(this.generatedPvLastData));

            this.voicesOnTimeline.push(result);
            this.mainControlState = "generate"
            this.loadingPv = false;
          })
        } else {
          this.loadingPv = false
        }
      })
    },
    getAudioDuration(url) {
      return new Promise((resolve) => {
        const audio = new Audio(url);

        audio.addEventListener('loadedmetadata', () => {
          resolve(audio.duration);
        });
      });
    },
    statusDisableMainControl(){
      return (this.voicesOnTimeline.length == 0 || this.selectedCharacter == null) && this.mainControlState == 'generate'
    },
    handlePushToTimeline(val){
      const index = this.voicesOnTimeline.findIndex(item => item.iav_id === val.id);
      if(index !== -1) {
        this.voicesOnTimeline[index].id = val.ttsId
        this.voicesOnTimeline[index].src = val.src
        this.voicesOnTimeline[index].duration = val.duration
        this.voicesOnTimeline[index].timelineId = randomUUID()
        sessionStorage.setItem('ttv::voices-on-timeline', JSON.stringify(this.voicesOnTimeline));
      } else {
        let result = {
          id : val.ttsId,
          src : val.src,
          name : 'AI Models ' + this.voicesOnTimeline.length,
          timelineId : randomUUID(),
          duration : val.duration,
          course_id : this.voicesOnTimeline[0]?.course_id || val.courseId,
          iav_id : val.id
        }
        this.voicesOnTimeline.push(result);
      }
      this.mainControlState = "generate"
    },
    handleDeleteSeInternal(val) {
      this.voicesOnTimeline = this.voicesOnTimeline.filter((voice) => voice.iav_id !== val);
    },
    handleUpdateNeedleTimeline(val) {
      this.playTtv = false
      this.durationSum = 0
      this.currentTime = 0

      const index = this.voicesOnTimeline.findIndex(item => item.iav_id === val);
      const votBeforeIndex = this.voicesOnTimeline.slice(0, index);

      this.durationSum = votBeforeIndex.reduce((totalDuration, voice) => {
        return totalDuration + voice.duration;
      }, 0);

      this.currentTime = this.durationSum;

      const audioPv = this.generatedPvLastData.audio
      if(audioPv){
        audioPv.pause()
        this.generatedPvLastData.actionState = 'play'
      }
    },
    handleUpdateCurrentTime(val) {
      this.currentTime = this.durationSum + val
    },
    handleRemoveDataFromStorage() {
      // =============================================================
      sessionStorage.removeItem('ttv::ai-voices')
      sessionStorage.removeItem('ttv::main-control-state')
      sessionStorage.removeItem('ttv::final-path-ttv')
      sessionStorage.removeItem('ttv::selected-character')
      sessionStorage.removeItem('ttv:voice-volume')
      sessionStorage.removeItem('ttv:voice-music')
      sessionStorage.removeItem('ttv::voices-on-timeline')
      sessionStorage.removeItem('ttv::musics-on-timeline')
      sessionStorage.removeItem('ttv::personal-voice-last-generated-data')
      // =============================================================
      sessionStorage.removeItem('ttv::voices-on-timeline-generate')
      sessionStorage.removeItem('ttv:voice-music-generate')
      sessionStorage.removeItem('ttv:voice-volume-generate')
      sessionStorage.removeItem('ttv::musics-on-timeline-generate')
      // =============================================================

      // NON LOCAL
      this.playTtv = false
      this.currentTime = 0
      this.durationSum = 0
      this.durationFinalTtv = 0
      this.generatedPvLastData = {
          id: null,
          src: null,
          name: null,
          audio: null,
          timelineId: null,
          actionState: 'play'
        }
    },
    handleBack(){
      this.handleRemoveDataFromStorage()
      this.$router.back()
    },
    handlePlayPvg(val) {
      const audio = this.generatedPvLastData.audio
      this.playTtv = false
      EventBus.$emit('pauseAudioAi');

      if(!audio.paused) {
        audio.pause()
        this.generatedPvLastData.actionState = 'play'
      } else {
        audio.play()
        this.generatedPvLastData.actionState = 'pause'
      }

      audio.addEventListener('ended', () => {
        this.generatedPvLastData.actionState = 'play'
        this.currentTime = 0
      });

      this.durationSum = 0
      this.currentTime = 0

      const index = this.voicesOnTimeline.findIndex(item => item.id === val);
      const votBeforeIndex = this.voicesOnTimeline.slice(0, index);

      this.durationSum = votBeforeIndex.reduce((totalDuration, voice) => {
        return totalDuration + voice.duration;
      }, 0);

      audio.addEventListener('timeupdate', () => {
        this.currentTime = this.durationSum + audio.currentTime;
      });
    }
  },
  async mounted() {
    document.documentElement.style.overflow = 'hidden';

    await this.getHistoryStorage()

    if (this.firstOpen) {
      this.$bvModal.show('ttv-tutorial');
    }
  },
};
</script>

<style scoped>
.ttv {
  height: 100vh;
}

.ttv__main {
  display: contents;
}

.ttv__main-center {
  display: grid;
  grid-template-columns: 45% 55%;
  min-height: 0;
}

.ttv__main-center > * {
  overflow-y: auto;
}

.ttv__main-center > *:first-child {
  border-right: 1px solid #2D2D2D;
}
</style>
