<template>
  <ul class="aiw flex-grow-0 m-0 list-unstyled">
    <li
      v-for="answerNumber in [...Array(totalAnswers).keys()].map((i) => i + 1)"
      :key="answerNumber"
      class="aiw__item position-relative"
      :class="type === 'thumbnail' ? 'item--h80' : 'item--h120'"
    >
      <slot :answer-number="answerNumber" />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    totalAnswers: {
      type: Number,
      required: true,
    },
    type: {
      validator(value) {
        return ['default', 'thumbnail'].includes(value);
      },
      default: 'default',
    },
  },
};
</script>

<style scoped>
.aiw {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.aiw__item {
  border-radius: 10px;
  /* overflow: hidden; */
  /* overflow-x: hidden; */
  /* overflow-y: visible; */
}

.item--h80 {
  height: 80px;
}

.item--h120 {
  height: 120px;
}
</style>
