<template>
  <div class="qvi d-flex justify-content-center align-items-center">
    <div v-if="videoUrl" class="qvi__iframe-wrapper d-flex justify-content-center">
      <iframe
        :src="`https://www.youtube.com/embed/${getYoutubeVideoId(videoUrl)}`"
        class="qvi__iframe"
      />
      <button
        type="button"
        class="qvi__remove-button btn d-flex justify-content-center align-items-center"
        title="Remove Video"
        @click="videoUrl = ''"
      >
        <img src="@/assets/images/icons/ic_trash_fill.svg" alt="" height="16" />
      </button>
    </div>

    <button
      v-else
      type="button"
      class="qvi__insert-button btn d-flex flex-column justify-content-center align-items-center py-3 text-white"
      title="Find and Insert Video"
      @click="$modal.show(`insert-youtube-modal-${_uid}`)"
    >
      <span class="qvi__insert-icon d-flex justify-content-center align-items-center">
        <font-awesome-icon icon="fa-solid fa-play" />
      </span>
      Find & Insert Video
    </button>

    <InsertYoutubeModal :name="`insert-youtube-modal-${_uid}`" v-model="videoUrl" />
  </div>
</template>

<script>
import InsertYoutubeModal from '../../modals/InsertYoutubeModal';

export default {
  components: {
    InsertYoutubeModal,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  data() {
    return {
      videoUrl: this.value,
    };
  },
  methods: {
    getYoutubeVideoId(url) {
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      let match = url.match(regExp);
      return (match && match[7].length === 11) ? match[7] : '';
    },
  },
  watch: {
    videoUrl(newVideoUrl) {
      this.$emit('input', newVideoUrl);
    },
    value(newValue) {
      this.videoUrl = newValue;
    },
  },
};
</script>

<style scoped>
.qvi {
  height: 100%;
}

.qvi__iframe-wrapper {
  width: min(500px, 100%);
  height: 100%;
  gap: 16px;
}

.qvi__iframe {
  width: 100%;
  border: none;
  border-radius: 10px;
}

.qvi__remove-button {
  width: 40px;
  height: 40px;
  background-color: #2D2D2D;
  border-radius: 50%;
  transition: background-color 0.25s;
}

.qvi__remove-button:hover {
  background-color: #F34D4D;
}

.qvi__insert-button {
  width: 100%;
  height: 100%;
  gap: 8px;
  background-color: #2D2D2D;
  border-radius: 10px;
  font-size: 18px;
  transition: background-color 0.25s;
}

.qvi__insert-button:hover {
  background-color: #3D3D3D;
}

.qvi__insert-icon {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #000000;
}
</style>
