<template>
  <section class="gv-video d-flex flex-column align-items-center w-100 p-4">
    <!-- <section class="gv-video__mode d-flex justify-content-center align-items-center" id="v-step-3">
      <button
        type="button"
        class="gv-video__mode-button btn p-0 rounded-0 text-white"
        title="Change Mode to Voice Only"
        @click="$emit('change:mode', 'voice-only')"
      >
        Voice Only
      </button>
      <button
        type="button"
        class="gv-video__mode-toggle btn position-relative p-0 rounded-pill"
        :title="'Change Mode to ' + (mode === 'voice-only' ? 'AI Characters' : 'Voice Only')"
        :class="{ 'gv-video__mode-toggle--active': mode === 'ai-characters' }"
        @click="disableSwitchMode == 'nok' ? $bvModal.show('sttvm-modal') : (switchConfirm ? $bvModal.show('svta-modal') : $emit('change:mode', mode === 'voice-only' ? 'ai-characters' : 'voice-only'))"
      >
        <span class="sr-only">
          {{ 'Change Mode to ' + (mode === 'voice-only' ? 'AI Characters' : 'Voice Only') }}
        </span>
      </button>
      <button
        type="button"
        class="gv-video__mode-button btn p-0 rounded-0 text-white"
        title="Change Mode to AI Characters"
        @click="$emit('change:mode', 'ai-characters')"
      >
        AI Characters
      </button>
    </section> -->

    <div class="d-flex align-items-center justify-content-center w-100 h-100">
      <div class="flex-grow-1 d-flex flex-column justify-content-center" :class="{ 'gv-video__video-wrapper-safari' : isSafari, 'gv-video__video-wrapper' : !isSafari }">
        <div
          v-if="!videoSrc && characterImageSrc && backgroundColor"
          class="gv-video__preview flex-grow-1 d-flex justify-content-center align-items-end"
          :style="{
            backgroundColor: backgroundColor,
            aspectRatio: videoRatio,
          }"
        >
          <img
            v-if="characterImageSrc"
            :src="characterImageSrc"
            alt="Character"
            class="gv-video__preview-character"
            :style="{
              aspectRatio: videoRatio,
            }"
          />
        </div>
        <div
          v-if="!videoSrc && voImage && mode == 'voice-only'"
          class="gv-video__preview flex-grow-1 d-flex justify-content-center align-items-end"
          :style="{
            backgroundColor: backgroundColor,
            aspectRatio: videoRatio,
          }"
        >
          <img
            v-if="voImage"
            :src="voImage"
            alt="Character"
            class="gv-video__preview-character"
            :style="{
              aspectRatio: videoRatio,
            }"
          />
        </div>
        <div v-else-if="!videoSrc && !characterImageSrc && !voImage" class="np--wrapper flex-grow-1 d-flex flex-column justify-content-center">
          <p
            class="m-0"
          >
            {{ $t('no-review') }}<br />
            <!-- Choose Character and Background Colour First -->
          </p>
        </div>
        <video
          v-show="videoSrc"
          ref="videoPlayer"
          :src="videoSrc"
          class="gv-video__video w-100"
          preload="metadata"
          :controls="!disabled"
          controlsList="nodownload"

          @loadedmetadata="handleLoadedmetadataVideoPlayer"
          @play="handlePlayVideoPlayer"
          @pause="handlePauseVideoPlayer"
          @ended="handleEndedVideoPlayer"
          @timeupdate="handleTimeupdateVideoPlayer"
        />
        <div v-if="mode != 'voice-only'" class="gv-video__backgrounds d-flex flex-column text-left mt-3">
          <p class="gv-video__backgrounds-title m-0">Background Colour</p>
          <ul class="gv-video__background-list d-flex m-0 list-unstyled">
            <li>
              <tippy
                to="color-picker-toggle"
                trigger="click"
                boundary="window"
                placement="top"
                theme="cp__wrapper"
                :animate-fill="false"
                :duration="250"
                :z-index="101"
                :max-width="500"
                visible
                interactive
              >
                <GVColorPicker :color="backgroundColor" @change:color="(val) => $emit('change:background-color', val)"/>
              </tippy>
              <button name="color-picker-toggle" type="button" class="gv-video__background-button btn p-0 rounded-sm" :style="{ backgroundColor: backgroundColor }" title="Pick a color">
                <font-awesome-icon icon="fa-solid fa-eye-dropper" class="cpt--icon" :class="{ 'cpt--icon-dark' : isHexLight }"/>
              </button>
            </li>
            <li v-for="color in backgroundColors" :key="color">
              <button
                type="button"
                class="gv-video__background-button btn p-0 rounded-sm"
                :class="{ 'gv-video__background-button--selected': color === backgroundColor }"
                :style="{
                  backgroundColor: color,
                  backgroundImage: color === 'transparent' ? `url('${require('@/assets/images/icons/ic_background_color_transparent.svg')}')` : 'none',
                }"
                :title="'Select ' + color.charAt(0).toUpperCase() + color.slice(1)"
                :disabled="mode == 'voice-only'"
                @click="$emit('change:background-color', color)"
              >
                <span class="sr-only">{{ color.charAt(0).toUpperCase() + color.slice(1) }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>


  </section>
</template>

<script>
import GVColorPicker from '../color-picker'

export default {
  components :{
    GVColorPicker
  },
  props: {
    mode: {
      validator(value) {
        return ['voice-only', 'ai-characters'].includes(value);
      },
      required: true,
    },
    videoSrc: {
      type: String,
      default: null,
    },
    characterImageSrc: {
      type: String,
      default: '',
    },
    voImage: {
      type: String,
      default: ''
    },
    backgroundColors: {
      type: Array,
      default: () => [],
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    videoRatio: {
      type: String,
      default: '16 / 9',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    switchConfirm: {
      type: Boolean,
      default: false,
    },
    disableSwitchMode: {
      validator(value) {
        return ['ok', 'nok'].includes(value);
      },
    }
  },
  emits: [
    'change:mode',
    'change:background-color',
    'get:video-player-duration',
    'timeupdate:video-player',
    'play:video-player',
    'pause:video-player',
    'ended:video-player',
  ],
  data() {
    return {
      isSafari: false,
      hexForDarkIcon: [
        '#FFFFFF',
        '#FFFFE0',
        '#FFFACD',
        '#ADD8E6',
        '#87CEEB',
        '#00FF00',
        '#90EE90',
        '#FFC0CB',
        '#FFA500',
        '#E6E6FA',
        '#00FFFF',
        '#FFF700',
        '#FFFDD0',
        '#C0C0C0',
        '#FFFF00',
      ]
    };
  },
  created() {
    // Check browser type
    const userAgent = this.$browserDetect.isSafari
    this.isSafari = userAgent;
  },
  watch: {
    videoSrc() {
      this.handleLoadedmetadataVideoPlayer();
    },
  },
  computed: {
    isHexLight() {
      return this.hexForDarkIcon.includes(this.backgroundColor.toUpperCase());
    }
  },
  methods: {
    handleLoadedmetadataVideoPlayer() {
      if (this.videoSrc) {
        this.$emit('get:video-player-duration', this.$refs.videoPlayer.duration);
      }
    },
    handlePlayVideoPlayer() {
      this.$emit('play:video-player');
    },
    handlePauseVideoPlayer() {
      this.$emit('pause:video-player');
    },
    handleEndedVideoPlayer() {
      this.$emit('ended:video-player');
    },
    handleTimeupdateVideoPlayer() {
      if (this.videoSrc) {
        this.$emit('timeupdate:video-player', this.$refs.videoPlayer.currentTime);
      }
    },
  },
};
</script>

<style scoped>
.gv-video {
  gap: 20px;
  overflow-y: auto;
  background-color: #585859;;
}

.gv-video__mode {
  gap: 12px;
}

.gv-video__mode-button {
  font-size: 16px;
}

.gv-video__mode-toggle {
  width: 24px;
  height: 12px;
  background-color: #FFFFFF;
  transition: background-color 0.25s;
}

.gv-video__mode-toggle::after {
  content: "";
  position: absolute;
  inset: 0 auto;
  left: 0;
  width: 10px;
  background-color: #CCCCCC;
  border-radius: 50%;
  transition: left 0.25s, background-color 0.25s, box-shadow;
}

.gv-video__mode-toggle--active {
  background-color: #00D615;
}

.gv-video__mode-toggle--active::after {
  left: calc(100% - 10px);
  background-color: #FFFFFF;
  box-shadow: 0 0 6px #00000080;
}

.gv-video__video-wrapper {
  max-width: 700px;
  width: 100%;
  min-height: 400px;
  max-height: 400px;
}

.gv-video__video-wrapper-safari {
  width: 100%;
  max-width: 500px;
  min-height: 350px;
  max-height: 350px;
}

.gv-video__preview {
  height: 100%;
  overflow: hidden;
  /* width: 100%; */
  /* border-radius: 16px; */
}

.gv-video__preview-character {
  height: 100%;
  max-height: 100%;
}

.gv-video__video {
  height: 100%;
  aspect-ratio: 16 / 9;
  /* border-radius: 16px; */
  border: 2px solid #FFFFFF22;
  background-color: black;
}

.gv-video__backgrounds {
  align-self: stretch;
  gap: 4px;
}

.gv-video__backgrounds-title {
  font-size: 12px;
}

.gv-video__background-list {
  gap: 4px;
}

.gv-video__background-button {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid transparent;
  transition: border-color 0.25s;
}

.gv-video__background-button:not(
  .gv-video__background-button--selected
):hover {
  border-color: #FFFFFF44;
}

.gv-video__background-button--selected {
  border-color: #FFFFFF;
}

.np--wrapper {
  width: 100%;
  background-color: black;
}

.cpt--icon {
  color: #ffffff;

  transition: color 0.25s;
}

.cpt--icon-dark {
  color: black;
}
</style>

<style>
.cp__wrapper-theme {
  background-color: #1f1f1f;
  width: 300px;
  position: relative;
}

.cp__wrapper-theme::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #1f1f1f transparent; /* Warna panah */
}
</style>
