<template>
  <div
    ref="qgContent"
    class="qg d-flex flex-column"
    :class="{ 'qg--show': show }"
  >
    <header class="qg__header">
      <ul class="qg__header-list d-flex align-items-center m-0 list-unstyled">
        <li ref="questionType" class="position-relative">
          <button
            type="button"
            class="qg__header-button btn d-flex align-items-center"
            :class="{ 'qg__header-button--active': isTypeListShown }"
            title="Type"
            @click="isTypeListShown = !isTypeListShown"
          >
            <img :src="activeTypeData.iconSrc" :alt="activeTypeData.text" class="qg__icon-image" />
            {{ activeTypeData.title }}
            <font-awesome-icon
              icon="fa-solid fa-chevron-down"
              class="qg__chevron-icon"
              :class="{ 'qg__chevron-icon--active': isTypeListShown }"
            />
          </button>

          <ul
            class="qg__header-popover position-absolute d-flex flex-column m-0 list-unstyled"
            :class="{ 'qg__header-popover--show': isTypeListShown }"
          >
            <li v-for="questionType in typeList" :key="questionType.title">
              <button
                type="button"
                class="qg__type-button btn d-flex flex-column"
                :class="{ 'qg__type-button--active': questionType.val === formData.type }"
                :title="questionType.title"
                @click="formData.type = questionType.val; isTypeListShown = false; $emit('set-type', questionType.val)"
              >
                <div class="qg__type-title d-flex align-items-center">
                  <img :src="questionType.iconSrc" :alt="questionType.title" class="qg__type-icon-image" />
                  {{ questionType.title  }}
                </div>
                <span class="qg__type-description" :class="{ 'qg__type-description--active': questionType.val === formData.type }">{{ questionType.description }}</span>
              </button>
            </li>
          </ul>
        </li>
        <li ref="settings" class="position-relative">
          <button
            type="button"
            class="qg__header-button btn d-flex align-items-center"
            :class="{ 'qg__header-button--active': isSettingsShown }"
            title="Settings"
            @click="isSettingsShown = !isSettingsShown"
          >
            <img
              src="@/assets/images/icons/ic_gear.svg"
              alt="Settings"
              class="qg__icon-image qg__icon-image-settings"
              :class="{ 'qg__icon-image-settings--active': isSettingsShown }"
            />
            Settings
          </button>

          <div
            class="qg__header-popover qg__settings position-absolute text-left"
            :class="{ 'qg__header-popover--show': isSettingsShown }"
          >
            <div v-if="options.gradeLevels.length > 0" class="qg__settings-item">
              <label for="input-grade-level" class="m-0">Grade Levels</label>
              <span>:</span>
              <select
                id="input-grade-level"
                class="qg__settings-select"
                v-model="formData.settings.gradeLevel"
              >
                <option
                  v-for="(gradeLevel, i) in options.gradeLevels"
                  :key="i"
                  :value="gradeLevel.id"
                  class="qg__settings-option"
                  :title="gradeLevel.name"
                >
                  {{ gradeLevel.name }}
                </option>
              </select>
            </div>
            <div v-if="options.levels.length > 0" class="qg__settings-item">
              <label for="input-level" class="m-0">Levels</label>
              <span>:</span>
              <ul id="input-level" class="qg__settings-button-list d-flex m-0 list-unstyled">
                <li v-for="level in options.levels" :key="level.id">
                  <button
                    type="button"
                    class="qg__settings-button btn btn-sm"
                    :class="{ 'qg__settings-button--selected': level.id === formData.settings.level }"
                    :title="level.name"
                    @click="formData.settings.level = level.id"
                  >
                    {{ level.name }}
                  </button>
                </li>
              </ul>
            </div>
            <div v-if="options.gradeLevels?.find((gradeLevel) => gradeLevel.id === formData.settings.gradeLevel)?.subLevels.length > 0" class="qg__settings-item">
              <label for="input-sub-level" class="m-0">Sub Levels</label>
              <span>:</span>
              <ul id="input-sub-level" class="qg__settings-button-list d-flex m-0 list-unstyled">
                <li v-for="subLevel in options.gradeLevels?.find((gradeLevel) => gradeLevel.id === formData.settings.gradeLevel)?.subLevels" :key="subLevel.id">
                  <button
                    type="button"
                    class="qg__settings-button btn btn-sm"
                    :class="{ 'qg__settings-button--selected': subLevel.id === formData.settings.subLevel }"
                    :title="subLevel.name"
                    @click="formData.settings.subLevel = subLevel.id"
                  >
                    {{ subLevel.name }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="qg__settings-item">
              <label for="input-output" class="m-0">Outputs</label>
              <span>:</span>
              <ul id="input-output" class="qg__settings-button-list d-flex m-0 list-unstyled">
                <li v-for="output in options.outputs" :key="output.id">
                  <button
                    type="button"
                    class="qg__settings-button btn btn-sm"
                    :class="{ 'qg__settings-button--selected': output.id === formData.settings.output }"
                    :title="output"
                    @click="formData.settings.output = output.id"
                  >
                    {{ output.name }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li ref="languages" class="position-relative">
          <button
            type="button"
            class="qg__header-button btn d-flex align-items-center"
            :class="{ 'qg__header-button--active': isLangShown }"
            title="Languages"
            @click="isLangShown = !isLangShown"
          >
            <img :src="selectedLanguageData.flagUrl" :alt="selectedLanguageData.name" class="qg__icon-image" />
            {{ selectedLanguageData.name }}
          </button>

          <ul
            class="qg__header-popover position-absolute d-flex flex-column m-0 list-unstyled"
            :class="{ 'qg__header-popover--show': isLangShown }"
          >
            <li v-for="lang in options.languages" :key="lang.id">
              <button
                type="button"
                class="qg__type-button btn d-flex flex-column"
                :class="{ 'qg__type-button--active': lang.id === formData.type }"
                :title="lang.title"
                @click="formData.language = lang.id; isLangShown = false; $emit('set-language', lang.name)"
              >
                <div class="qg__type-title d-flex align-items-center">
                  <img :src="lang.flagUrl" :alt="lang.name" class="qg__type-icon-image" />
                  {{ lang.name  }}
                </div>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </header>

    <div class="qg__body d-flex flex-column align-items-end">
      <textarea
        ref="textarea"
        v-model="formData.description"
        class="qg__textarea"
        spellcheck="false"
        :readonly="fileDocSummarize"
      />
      <div class="qg__body--action d-flex align-items-center">
        <tippy v-if="!fileDocSummarize" content="Add files (doc,pdf) max.10MB" theme="qg__theme" placement="top" trigger="mouseenter focus">
          <template v-slot:trigger>
            <button
              type="button"
              class="qg__file-button btn"
              @click.stop="openUserDirectory"
            >
              <font-awesome-icon
                icon="fa-solid fa-paperclip"
                class="qg__paperclip-icon"
              />
            </button>
          </template>
        </tippy>
        <button v-else type="button" class="qg__selected-file-button btn p-0 position-relative d-flex align-items-center" title="Choose another File" @click="openUserDirectory">
          <div class="qgsfb__file-type-content">
            {{ fileLabel ? fileLabel : '-' }}
          </div>
          <div class="qgsfb__file-info-content text-left">
            <p class="file-info__name m-0" :title="fileDocSummarize.name">{{ fileDocSummarize.name ? truncatedText(fileDocSummarize.name) : '-' }}</p>
            <p class="file-info__size m-0">{{ fileSize ? convertToBytes(fileSize) : '-' }}</p>
          </div>
          <button class="qg__selected-file-button--close btn p-0 d-flex align-items-center justify-content-center" title="Remove File" @click.stop="resetSummarizeFile">
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="qg__xmark-icon"
            />
          </button>
        </button>
        <button
          type="button"
          class="qg__generate-button btn"
          :title="formData.description ? 'Generate Questions' : 'Fill Description First!'"
          @click="handleGenerate"
          :disabled="!formData.description || isGenerating"
        >
        {{ isGenerating ? 'Generating ...' : 'Generate' }}
        </button>
      </div>
    </div>
		<input ref="uploadDoc" type="file" style="display: none;" @change="handleFileSelect" accept=".pdf, .docx, .doc">
    <SummarizeQuizCharacter @click:clear="resetSummarizeFile" @click:reupload="handleReupload"/>
  </div>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";
import SummarizeQuizCharacter from "@/components/modal/SummarizeQuizCharacter"

export default {
  components: {
    SummarizeQuizCharacter
  },
  mixins: [AlertUtils],
  props: {
    placeholders: {
      type: Array,
      default() {
        return [
          'Buatkan soal mengenai koneksi ke database menggunakan JavaScript dengan level expert!',
          'Buatkan soal biologi mengenai anatomi tubuh manusia untuk tingkat SMA!',
          'Buatkan soal perkalian, pengurangan, dan penambahan dalam bentuk pecahan!',
        ];
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
    isGenerating: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['generate', 'set-type', 'set-language'],
  data() {
    return {
      typeList: [
        {
          title: 'STEM',
          val: 'Multiple choice',
          description: '(science, tech, eng, math)',
          iconSrc: require('@/assets/images/icons/ic_stem_type.svg'),
        },
        {
          title: 'Code Test',
          val: 'Code',
          description: '(20+ programming languages)',
          iconSrc: require('@/assets/images/icons/ic_code_test_type.svg'),
        },
      ],
      // formData: {
      //   type: 'Code',
      //   description: '',
      //   gradeLevel: '',
      //   level: '',
      //   subLevel: '',
      //   output: '',
      // },
      formData: {
        topic: null,
        type: 'Multiple choice',
        settings: {
          gradeLevel: null,
          level: null,
          subLevel: null,
          output: null,
        },
        language: null,
        description: '',
      },

      fileDocSummarize: null,
      fileLabel: null,
      fileSize: null,

      defaultLang : {
        id: '1',
        name: 'ID',
        flagUrl: 'https://flagicons.lipis.dev/flags/4x3/id.svg',
      },

      isTypeListShown: false,
      isSettingsShown: false,
      isLangShown: false,
    };
  },
  watch: {
    'options.topics'(topics) {
      this.formData.topic = topics?.length > 0 ? this.selectedOptions.topic || this.formData.topic || topics[0].id : null;
    },
    'options.gradeLevels'(gradeLevels) {
      this.formData.settings.gradeLevel = gradeLevels?.length > 0 ? this.selectedOptions.gradeLevel?.gradeLevel || this.formData.settings.gradeLevel || gradeLevels[0].id : null;

      if (gradeLevels?.length > 0) {
        const subLevels = gradeLevels.find((gradeLevel) => gradeLevel.id === this.formData.settings.gradeLevel).subLevels;

        this.formData.settings.subLevel = subLevels?.length > 0 ? this.selectedOptions.gradeLevel?.subLevel || this.formData.settings.subLevel || subLevels[0].id : null;
      }
    },
    'options.levels'(levels) {
      this.formData.settings.level = levels?.length > 0 ? this.selectedOptions.level || this.formData.settings.level || levels[0].id : null;
    },
    'options.outputs'(outputs) {
      this.formData.settings.output = outputs?.length > 0 ? this.selectedOptions.output || this.formData.settings.output || outputs[0].id : null;
    },
    'options.languages'(languages) {
      this.formData.language = languages?.length > 0 ? this.selectedOptions.language || this.formData.language || languages[0].id : null;
    },
    'formData.settings.gradeLevel'(value) {
      if (this.options.gradeLevels?.length > 0) {
        const subLevels = this.options.gradeLevels.find((gradeLevel) => gradeLevel.id === value)?.subLevels;

        this.formData.settings.subLevel = subLevels?.length > 0 ? this.selectedOptions.gradeLevel?.subLevel || this.formData.settings.subLevel || subLevels[0].id : null;
      }
    },
    'formData.language'(value) {
      if(value == '1' && this.fileDocSummarize) {
        this.formData.description = "Buatkan quiz dari file yang telah di upload"
      } else if(value == '2' && this.fileDocSummarize) {
        this.formData.description = "Generate quiz from the given file"
      }
    },
    fileDocSummarize(newFile) {
      if(newFile) {
        this.$emit("update:output")
      } else {
        this.$emit("reset:output")
      }
    }
  },
  computed: {
    activeTypeData() {
      return this.typeList.find((type) => type.val === this.formData.type);
    },
    selectedLanguageData() {
      return this.formData.language ? this.options.languages.find((language) => language.id === this.formData.language) : this.defaultLang;
    },
  },
  methods: {
    typingPlaceholder() {
      const speed = 100;
      const waitingStep = 50;

      let activePlaceholderIndex = 0;
      let currentChar = 0;
      let currentWaitingStep = 0;
      let placeholder = '';

      this.$watch('placeholders', () => {
        activePlaceholderIndex = 0;
        currentChar = 0;
        currentWaitingStep = 0;
        placeholder = '';
      });

      this.typingPlaceholderInterval = () => {
        if (this.placeholders && this.$refs.textarea) {
          if (this.$refs.textarea.getAttribute('placeholder') === this.placeholders[activePlaceholderIndex]) {
            if (currentWaitingStep >= waitingStep) {
              if (activePlaceholderIndex < this.placeholders.length - 1)  {
                activePlaceholderIndex++;
              } else {
                activePlaceholderIndex = 0;
              }

              currentChar = 0;
              currentWaitingStep = 0;
              placeholder = '';

              this.$refs.textarea.setAttribute('placeholder', placeholder);
            } else {
              currentWaitingStep++;
            }
          } else {
            placeholder += this.placeholders[activePlaceholderIndex].charAt(currentChar);
            this.$refs.textarea.setAttribute('placeholder', placeholder);
            currentChar++;
          }
        }
      };

      setInterval(this.typingPlaceholderInterval, speed);
    },
    closeTypeListWhenClickOutside(e) {
      if (this.isTypeListShown && !this.$refs.questionType.contains(e.target)) {
        this.isTypeListShown = false;
      }
    },
    closeSettingsWhenClickOutside(e) {
      if (this.isSettingsShown && !this.$refs.settings.contains(e.target)) {
        this.isSettingsShown = false;
      }
    },
    closeLangWhenClickOutSide(e){
      if (this.isLangShown && !this.$refs.languages.contains(e.target)) {
        this.isLangShown = false;
      }
    },
    closePromptWhenClickOutSide(e) {
      if (this.show && !this.$refs.qgContent.contains(e.target)) {
        this.$emit('update:close-prompt', false)
      }
    },
    truncatedText(text) {
			if (text.length > 15) {
				return text.substring(0, 15) + "...";
			} else {
				return text;
			}
		},
    handleFileSelect(event) {
      const selectedFiles = event.target.files;
			const allowedFileTypes = ['pdf', 'document', 'vnd.openxmlformats-officedocument.wordprocessingml.document'];
			const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

      for (let i = 0; i < selectedFiles.length; i++) {
				const file = selectedFiles[i];
				const fileType = file.type.split('/')[0] == 'application' ? file.type.split('/')[1] : file.type.split('/')[0]; // jenis file berdasarkan tipe MIME
				const fileSize = file.size;

				if (!allowedFileTypes.includes(fileType)) {
					this.alertFailIcon('File Extension not allowed')
				} else if (fileSize > maxSizeInBytes) {
          this.alertFailIcon('File size must be less than 10MB');
        } else {
          if (fileType === 'pdf') {
            this.fileLabel = 'PDF';
          } else if (fileType === 'document' || fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
            this.fileLabel = 'DOC';
          }
          this.fileSize = fileSize
          this.fileDocSummarize = file
          if(this.formData.language == '1') {
            this.formData.description = "Buatkan quiz dari file yang telah di upload"
          } else {
            this.formData.description = "Generate quiz from the given file"
          }
				}
			}
    },
    openUserDirectory() {
			this.$refs.uploadDoc.click();
		},
    resetSummarizeFile() {
      this.fileDocSummarize = null
      this.formData.description = ''
    },
    handleGenerate() {
      if(!this.fileDocSummarize) {
        this.$emit('generate', this.formData)
      } else {
        this.$emit('generate:summarize', { file : this.fileDocSummarize, length : this.formData.settings.output, lang : this.formData.language})
      }
    },
    convertToBytes(num) {
			const number = parseInt(num);
			const units = ['bytes', 'kb', 'mb', 'gb', 'tb'];
			let unitIndex = 0;
			let size = number;

			while (size >= 1024 && unitIndex < units.length - 1) {
				size /= 1024;
				unitIndex++;
			}

			return `${size.toFixed(2)} ${units[unitIndex]}`;
		},
    handleReupload() {
      this.$bvModal.hide('sqc-modal')
      this.resetSummarizeFile()
      this.openUserDirectory()
    }
  },
  mounted() {
    this.typingPlaceholder();
    document.addEventListener('click', this.closeTypeListWhenClickOutside);
    document.addEventListener('click', this.closeSettingsWhenClickOutside);
    document.addEventListener('click', this.closeLangWhenClickOutSide);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeTypeListWhenClickOutside);
    document.removeEventListener('click', this.closeSettingsWhenClickOutside);
    document.removeEventListener('click', this.closeLangWhenClickOutSide);
  },
};
</script>

<style scoped>
.qg {
  visibility: hidden;
  opacity: 0;
  transform: translateY(16px);

  gap: 16px;
  width: 100%;
  background-color: #1F1F1F;
  padding: 16px;
  border: 1px solid #6D75F6;
  border-radius: 10px;
  transition: all 0.25s;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}

.qg--show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.qg__header-list {
  gap: 8px;
}

.qg__header-button {
  gap: 4px;
  padding: 6px;
  background-color: #2D2D2D;
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 0.5;
  transition: background-color 0.25s;
}

.qg__header-button--active, .qg__header-button:hover {
  background-color: #3D3D3D;
}

.qg__header-popover {
  visibility: hidden;
  opacity: 0;
  bottom: 100%;
  transform: scale(0);

  left: 0;
  padding: 8px;
  background-color: #2D2D2D;
  border-radius: 10px;
  transform-origin: bottom left;
  transition: all 0.25s;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}

.qg__header-popover--show {
  visibility: visible;
  opacity: 1;
  bottom: calc(100% + 8px);
  transform: scale(1);
}

.qg__settings {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  font-size: 12px;
}

.qg__settings-item {
  display: contents;
}

.qg__settings-select {
  padding: 4px 6px;
  background-color: transparent;
  border: 1px solid #414141;
  border-radius: 4px;
  color: #FFFFFF;
}

.qg__settings-option {
  background-color: #2D2D2D;
  color: #FFFFFF;
}

.qg__settings-button-list {
  gap: 4px;
}

.qg__settings-button {
  min-width: 35px;
  background-color: #414141;
  color: #FFFFFF;
  font-size: 10px;
  transition: background-color 0.25s;
}

.qg__settings-button:hover {
  background-color: #515151;
}

.qg__settings-button--selected,
.qg__settings-button--selected:hover {
  background-color: #6D75F6;
}

.qg__icon-image {
  width: 12px;
  height: 12px;
  object-fit: contain;
  object-position: center;
}

.qg__icon-image-settings {
  transition: rotate 0.5s;
}

.qg__icon-image-settings--active {
  rotate: 270deg;
}

.qg__chevron-icon {
  font-size: 10px;
  transition: rotate 0.25s;
}

.qg__chevron-icon--active {
  rotate: -180deg;
}

.qg__type-button {
  padding: 8px 12px;
  width: 100%;
  background: none;
  color: #FFFFFF;
  text-align: left;
  transition: background-color 0.25s;
}

.qg__type-button:not(.qg__type-button--active):hover {
  background-color: #3D3D3D;
}

.qg__type-button--active {
  background-color: #6D75F6;
}

.qg__type-icon-image {
  width: 12px;
  height: 12px;
  object-fit: contain;
  object-position: center;
}

.qg__type-title {
  gap: 8px;
  width: max-content;
  font-size: 12px;
  font-weight: 600;
}

.qg__type-description {
  width: max-content;
  margin-left: calc(8px + 12px);
  font-size: 10px;
  color: #8C8C8C;
  transition: color 0.25s;
}

.qg__type-description--active {
  color: #FFFFFF;
}

.qg__body {
  gap: 8px;
}

.qg__textarea {
  width: 100%;
  height: 48px;
  background: none;
  border: none;
  resize: none;
  font-size: 16px;
  color: #FFFFFF;
  transition: color 0.25s;
}

.qg__textarea::placeholder {
  color: #585859;
}

.qg__generate-button, .qg__file-button, .qg__selected-file-button {
  padding: 4px 12px;
  color: #FFFFFF;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.25s;
}

.qg__generate-button {
  background-color: #6D75F6;
}

.qg__file-button {
  background-color: #2d2d2d;
}

.qg__file-button:hover {
  background-color: #383838;
}

.qg__generate-button:disabled {
  background-color: #2d2d2d;
  color: #585859;
}

.qg__generate-button:not(:disabled):hover {
  background-color: #545eed;
}

.qg__body--action {
  gap: 6px;
}

.qg__paperclip-icon {
  font-size: 16px;
}

.qgsfb__file-type-content {
  font-size: 8px;
  background-color: #6D75F6;
  padding: 8px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #6d75f6;
}

.qgsfb__file-info-content {
  background-color: #2D2D2D;;
  padding: 2px 4px;
  border-radius: 0 4px 4px 0;
  border: 1px solid #2D2D2D;;
}

.file-info__name {
  font-size: 10px;
}

.file-info__size {
  font-size: 7px;
  line-height: 9px;
  color: #8C8C8C;
}

.qg__selected-file-button--close {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #585859;
  color: #fff;
  position: absolute;
  top: -3px;
  right: -3px;
}

.qg__xmark-icon {
  font-size: 6px;
}
</style>

<style>
.qg__theme-theme, .qg__theme-theme .tippy-backdrop {
  background-color: black !important;
}

.qg__theme-theme .tippy-content {
  font-size: 10px;
}
</style>
