<template>
  <div class="ttvie__container">
    <b-modal
      :id="id"
      size="md"
      dialog-class="ttvie__dialog"
      content-class="ttvie mx-auto text-white text-center"
      body-class="ttvie__body d-flex flex-column"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      @show="$emit('input', null)"
    >
		<button
        type="button"
        class="ttvie__close-button btn align-self-end d-inline-flex justify-content-center align-items-center p-0 rounded-circle"
        title="Close"
        @click="$bvModal.hide(id)"
      >
        &times;
      </button>
      <h2 class="ttvie__title m-0">Your video is almost ready</h2>
      <p class="ttvie__message my-2 mb-0">Sometimes it takes a couple of minutes to finish your video. <br> Please input your email, and we will let you know once finished.</p>
	  <form @submit.prevent="submit">
      <input type="email" class="ttvie--input form-control" placeholder="Input your email" v-model="email">
      <div class="ttvie--action d-flex align-items-center justify-content-center mt-5">
        <button class="ttvie__cancel-button btn text-white" type="button" @click="$emit('generate-final-instead'), $bvModal.hide(id)">Not now</button>
        <button
          type="submit"
          class="ttvie__submit-button btn text-white"
          title="Submit"
          :disabled="!email || loading"
        >
          {{ loading ? 'Loading...' : 'Submit' }}
        </button>
		</div>
	  </form>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: {
    id: {
      type: String,
      default: 'ttv-input-email'
    },
    value: {
      type: String,
      default: null,
    },
    loading : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
		  email: this.value
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.email = newValue;
      },
      deep: true,
    },
    email: {
      handler(newEmail) {
        this.$emit('input', newEmail);
      },
      deep: true,
    },
  },
  methods : {
    submit(){
      this.$emit('generate-final')
    }
  }
};
</script>

<style scoped>
.ttvie__close-button {
  width: 30px;
  height: 30px;
  background-color: #D9D9D9;
  color: #1C1C1C;
  font-size: 16px;
  transition: background-color 0.25s;
}

.ttvie__close-button:hover {
  background-color: #FFFFFF;
}

.ttvie__close-button:hover {
  background-color: #FFFFFF;
}

.ttvie__title {
  font-size: 25px;
  font-weight: 700;
}

.ttvie__message {
  font-size: 18px;
  font-weight: normal;
}

.ttvie__submit-button, .ttvie__cancel-button {
	height: 40px;
	padding: 0 20px;
	transition: background-color 0.25s;
}

.ttvie__submit-button {
  background-color: #6D75F6;
  font-weight: 600;
}

.ttvie__submit-button:not(:disabled):hover {
  background-color: #545EED;
}

.ttvie__submit-button:disabled {
  background-color: #4D4D4D;
  border-color: #4D4D4D;
}

.ttvie__cancel-button {
	background-color: #2D2D2D;
}

.ttvie__cancel-button:hover {
	background-color: #292828;
}

.ttvie--input {
	height: 45px;
	background-color: transparent;
	border: 1px solid #585859;
	border-radius: 6px;
	box-shadow: none;
	padding: 10px;
	color: white;
}

.ttvie--action {
	gap: 10px;
}
</style>

<style>
.ttvie__dialog {
  max-width: none;
}

.ttvie {
  width: 585px;
  background-color: #1F1F1F;
  border-radius: 10px;
  padding: 20px;
}

.ttvie__body {
  gap: 12px;
}
</style>
