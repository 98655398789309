<template>
  <header class="header d-flex justify-content-between align-items-center px-5 py-2">
    <section class="header__left d-flex align-items-center">
      <button
        type="button"
        class="header__back-button btn px-0"
        title="Back"
        @click="$emit('back')"
      >
       <img src="@/assets/images/icons/ic_back_nav.svg" alt="back">
      </button>
      <h1 class="header__title">Generate Quizzes</h1>
    </section>

    <section class="header__center">
      <button
        type="button"
        class="header__file-name-button btn d-flex align-items-center text-nowrap"
        :class="{ 'header__file-name-button--error': !quizData.quizName || !quizData.topic }"
        title="File Name"
        @click="$modal.show('save-modal')"
      >
        <span>{{ quizData.quizName || 'File Name' }}</span>
        <img src="@/assets/images/icons/ic_file_name.svg" alt="" height="18" />
      </button>
    </section>

    <section class="header__right d-flex align-items-center">
      <div class="header__subscription d-flex align-items-center">
        <div class="header__subscription-icon-wrapper d-flex justify-content-center align-items-center">
          <font-awesome-icon icon="fa-solid fa-info fa-fw" class="header__subscription-icon" />
          <div class="header__subscription-tooltip">
            <span>{{ statusSubscriptions.question_generates_quota}} questions remaining to generate. </span>
            <br>
            <span>Top Up to get more questions.</span>
          </div>
        </div>
        <span class="header__subscription-text">{{ statusSubscriptions.question_generates_quota }}</span>
      </div>

      <div class="header__buttons d-flex align-items-center">
        <button
          type="button"
          class="header__cancel-button btn"
          title="Cancel"
          @click="$emit('back')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="header__save-button btn"
          title="Save and Publish"
          :disabled="!enableSave"
          @click="$emit('save-quiz')"
        >
          Save & Publish
        </button>
      </div>
    </section>

    <SaveModal v-model="quizData" :categories="categories" :options="options"/>
  </header>
</template>

<script>
import SaveModal from '@/components/generative-ai/modals/SaveModal';

export default {
  components: {
    SaveModal,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    statusSubscriptions: {
      type: Object,
      default() {
        return {};
      },
    },
    categories: {
      type: Array,
      default() {
        return [];
      }
    },
    enableSave: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input', 'save-quiz', 'back'],
  data() {
    return {
      quizData: this.value,
    };
  },
  watch: {
    quizData: {
      handler(newQuizData) {
        this.$emit('input', newQuizData);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.header {
  gap: 16px;
  border-bottom: 1px solid #2D2D2D;
}

.header__left {
  gap: 16px;
}

.header__back-button {
  font-size: 20px;
  color: #FFFFFF
}

.header__title {
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
}

.header__file-name-button {
  gap: 8px;
  background: none;
  font-size: 18px;
  font-weight: 500;
  color: #585859;
  transition: color 0.25s;
}

.header__file-name-button--error {
  color: #F34D4D;
}

.header__right {
  gap: 16px;
}

.header__subscription {
  gap: 4px;
}

.header__subscription-icon-wrapper {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}

.header__subscription-icon {
  font-size: 8px;
}

.header__subscription-tooltip {
  position: absolute;
  top: 55px;
  background-color: #1F1F1F;
  color: #ffffff;
  font-size: 12px;
  padding: 12px;
  border-radius:4px ;
  display: none;
  box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
  z-index: 501;
}

.header__subscription-icon-wrapper:hover .header__subscription-tooltip {
  display: block !important;
}

.header__buttons {
  gap: 8px;
}

.header__cancel-button, .header__save-button {
  padding: 6px 12px;
  color: #FFFFFF;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.25s, color 0.25s;
}

.header__cancel-button {
  background-color: #1F1F1F;
}

.header__cancel-button:hover {
  background-color: #282828;
}

.header__save-button {
  background-color: #6D75F6;
}

.header__save-button:disabled {
  background-color: #1F1F1F;
  color: #585859;
}

.header__save-button:not(:disabled):hover {
  background-color: #545EED;
}
</style>
