<template>
    <b-modal id="qm-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
        <div class="float-right">
            <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('qm-modal')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
        </div>
        <div class="mt-5 text-center text-white mb-3">
            <img src="@/assets/images/icons/modal_warning.svg" alt="Alert" class="img-fluid">
            <div class="mt-3">
                <h5 class="font-weight-bold mb-2" style="font-size:16px;">No Allocation</h5>
                <h5 v-if="type == 'multilingual'" class="font-weight-normal" style="font-size:14px;">You don't have allocation.<br> Please top up to get more.</h5>
                <h5 v-else class="font-weight-normal" style="font-size:14px;">You have used all <br> the free 5 models allocation.</h5>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <button v-if="type == 'multilingual'" class="btn ttvsrv__button ttvsrv__button--save" @click="handleClickUpgrade"><p class="mx-2 mb-0 font-weight-bold text-white">Top Up</p></button>
                <button v-else class="btn ttvsrv__button ttvsrv__button--cancel" @click="$bvModal.hide('qm-modal')"><p class="mx-2 mb-0 font-weight-bold text-white">Close</p></button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
      type: {
        type: String,
        default: 'monolingual'
      },
      clickUpgrade: {
        type: Function,
        default: null,
      },
    },
    methods: {
        handleClickUpgrade() {
          if (this.clickUpgrade !== null) {
            this.$emit('click:handle-upgrade-gv')
          } else {
            this.$router.push({ name: 'TopUp' });
          }
        },
    },
};
</script>

<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#qm-modal .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#qm-modal .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
</style>

<style scoped>
.ttvsrv__button {
  border-radius: 6px;
  transition: background-color 0.25s;
}

.ttvsrv__button--save {
  font-weight: 600;
  background-color: #6D75F6;
}

.ttvsrv__button--save:not(:disabled):hover {
  background-color: #545EED;
}

.ttvsrv__button--cancel {
  font-weight: 600;
  background: #2D2D2D;
}

.ttvsrv__button--cancel:hover {
	background: #292828;
}
</style>