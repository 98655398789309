var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"gv-video d-flex flex-column align-items-center w-100 p-4"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100 h-100"},[_c('div',{staticClass:"flex-grow-1 d-flex flex-column justify-content-center",class:{ 'gv-video__video-wrapper-safari' : _vm.isSafari, 'gv-video__video-wrapper' : !_vm.isSafari }},[(!_vm.videoSrc && _vm.characterImageSrc && _vm.backgroundColor)?_c('div',{staticClass:"gv-video__preview flex-grow-1 d-flex justify-content-center align-items-end",style:({
          backgroundColor: _vm.backgroundColor,
          aspectRatio: _vm.videoRatio,
        })},[(_vm.characterImageSrc)?_c('img',{staticClass:"gv-video__preview-character",style:({
            aspectRatio: _vm.videoRatio,
          }),attrs:{"src":_vm.characterImageSrc,"alt":"Character"}}):_vm._e()]):_vm._e(),(!_vm.videoSrc && _vm.voImage && _vm.mode == 'voice-only')?_c('div',{staticClass:"gv-video__preview flex-grow-1 d-flex justify-content-center align-items-end",style:({
          backgroundColor: _vm.backgroundColor,
          aspectRatio: _vm.videoRatio,
        })},[(_vm.voImage)?_c('img',{staticClass:"gv-video__preview-character",style:({
            aspectRatio: _vm.videoRatio,
          }),attrs:{"src":_vm.voImage,"alt":"Character"}}):_vm._e()]):(!_vm.videoSrc && !_vm.characterImageSrc && !_vm.voImage)?_c('div',{staticClass:"np--wrapper flex-grow-1 d-flex flex-column justify-content-center"},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('no-review'))),_c('br')])]):_vm._e(),_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoSrc),expression:"videoSrc"}],ref:"videoPlayer",staticClass:"gv-video__video w-100",attrs:{"src":_vm.videoSrc,"preload":"metadata","controls":!_vm.disabled,"controlsList":"nodownload"},on:{"loadedmetadata":_vm.handleLoadedmetadataVideoPlayer,"play":_vm.handlePlayVideoPlayer,"pause":_vm.handlePauseVideoPlayer,"ended":_vm.handleEndedVideoPlayer,"timeupdate":_vm.handleTimeupdateVideoPlayer}}),(_vm.mode != 'voice-only')?_c('div',{staticClass:"gv-video__backgrounds d-flex flex-column text-left mt-3"},[_c('p',{staticClass:"gv-video__backgrounds-title m-0"},[_vm._v("Background Colour")]),_c('ul',{staticClass:"gv-video__background-list d-flex m-0 list-unstyled"},[_c('li',[_c('tippy',{attrs:{"to":"color-picker-toggle","trigger":"click","boundary":"window","placement":"top","theme":"cp__wrapper","animate-fill":false,"duration":250,"z-index":101,"max-width":500,"visible":"","interactive":""}},[_c('GVColorPicker',{attrs:{"color":_vm.backgroundColor},on:{"change:color":(val) => _vm.$emit('change:background-color', val)}})],1),_c('button',{staticClass:"gv-video__background-button btn p-0 rounded-sm",style:({ backgroundColor: _vm.backgroundColor }),attrs:{"name":"color-picker-toggle","type":"button","title":"Pick a color"}},[_c('font-awesome-icon',{staticClass:"cpt--icon",class:{ 'cpt--icon-dark' : _vm.isHexLight },attrs:{"icon":"fa-solid fa-eye-dropper"}})],1)],1),_vm._l((_vm.backgroundColors),function(color){return _c('li',{key:color},[_c('button',{staticClass:"gv-video__background-button btn p-0 rounded-sm",class:{ 'gv-video__background-button--selected': color === _vm.backgroundColor },style:({
                backgroundColor: color,
                backgroundImage: color === 'transparent' ? `url('${require('@/assets/images/icons/ic_background_color_transparent.svg')}')` : 'none',
              }),attrs:{"type":"button","title":'Select ' + color.charAt(0).toUpperCase() + color.slice(1),"disabled":_vm.mode == 'voice-only'},on:{"click":function($event){return _vm.$emit('change:background-color', color)}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(color.charAt(0).toUpperCase() + color.slice(1)))])])])})],2)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }