<template>
  <div class="math-equation-modal__buttons">
    <div
      v-for="(texs, title) in items"
      :key="title"
      class="math-equation-modal__buttons-group"
    >
      <h3 class="math-equation-modal__buttons-heading">{{ title  }}</h3>
      <ul class="math-equation-modal__buttons-list">
        <li v-for="tex in texs" :key="tex">
          <button
            type="button"
            class="math-equation-modal__buttons-button"
            v-katex="tex"
            @click="$emit('click', tex)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['click'],
  data() {
    return {
      items: {
        'Symbols': [
          '+',
          '-',
          '\\pm',
          '\\times',
          '\\div',
          '=',
          '\\ne',
          '\\equiv',
          '<',
          '>',
          '\\le',
          '\\ge',
          '\\sim',
          '\\approx',
          '\\cong',
        ],
        'Basic': [
          'a^2',
          'a^x',
          'a_x',
          '\\sqrt{a}',
          '\\sqrt[x]{a}',
          '\\frac{a}{x}',
          '\\left|x\\right|',
          '\\lceil x\\rceil',
          '\\lfloor x\\rfloor',
          '\\frac{\\text{d}x}{\\text{d}y}',
          '\\frac{\\partial x}{\\partial y}',
          '\\int_x^y',
          '\\oint_x^y',
          '\\log_xy',
          '\\lim_{x\\rightarrow y}',
          '\\sum_x^y',
          '\\prod_x^y',
          '\\overleftarrow{xy}',
          '\\overline{xy}',
          '\\overrightarrow{xy}',
          '\\cdot',
        ],
        'Greek (Lowercase)': [
          '\\alpha',
          '\\beta',
          '\\gamma',
          '\\delta',
          '\\epsilon',
          '\\zeta',
          '\\eta',
          '\\theta',
          '\\iota',
          '\\kappa',
          '\\lambda',
          '\\mu',
          '\\nu',
          '\\xi',
          'o',
          '\\pi',
          '\\rho',
          '\\sigma',
          '\\tau',
          '\\upsilon',
          '\\phi',
          '\\chi',
          '\\psi',
          '\\omega',
        ],
        'Greek (Uppercase)': [
          'A',
          'B',
          '\\Gamma',
          '\\Delta',
          'E',
          'Z',
          'H',
          '\\Theta',
          'I',
          'K',
          '\\Lambda',
          'M',
          'N',
          '\\Xi',
          'O',
          '\\Pi',
          'P',
          '\\Sigma',
          'T',
          '\\Upsilon',
          '\\Phi',
          'X',
          '\\Psi',
          '\\Omega',
        ],
        'Advance': [
          '\\subset',
          '\\subseteq',
          '\\not\\subset',
          '\\not\\subseteq',
          '\\supset',
          '\\supseteq',
          '\\not\\supset',
          '\\not\\supseteq',
          '\\in',
          '\\ni',
          '\\not\\in',
          '\\not\\ni',
          '\\cup',
          '\\cap',
          '\\forall',
          '\\exists',
          '\\vee',
          '\\wedge',
          '\\because',
          '\\therefore',
        ],
        'Arrows': [
          '\\longleftarrow',
          '\\longrightarrow',
          '\\longleftrightarrow',
          '\\Longleftarrow',
          '\\Longrightarrow',
          '\\Longleftrightarrow',
          '\\uparrow',
          '\\Uparrow',
          '\\downarrow',
          '\\Downarrow',
          '\\updownarrow',
          '\\Updownarrow',
          '\\ldots',
          '\\cdots',
          '\\circ',
        ],
      },
    };
  },
}
</script>

<style scoped>
.math-equation-modal__buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 15rem;
  padding: 0.5rem 1rem 0.5rem 0;
  margin-bottom: 1.5rem;
  overflow: auto;
}

.math-equation-modal__buttons::-webkit-scrollbar {
  width: 0.5rem;
}

.math-equation-modal__buttons::-webkit-scrollbar-track {
  border-radius: 0.5rem;
}

.math-equation-modal__buttons:hover::-webkit-scrollbar-track  {
  background-color: rgba(255, 255, 255, 0.01);
}

.math-equation-modal__buttons::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
}

.math-equation-modal__buttons::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.math-equation-modal__buttons-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.math-equation-modal__buttons-heading {
  align-self: flex-start;
  font-size: 0.75rem;
}

.math-equation-modal__buttons-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(2.75rem, 1fr));
  gap: 0.25rem;
  list-style: none;
}

.math-equation-modal__buttons-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.75rem;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.25s;
}

.math-equation-modal__buttons-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
</style>
