<template>
  <div>
    <b-modal
      :id="name"
      centered
      header-class="border-0 pt-4 pb-0"
      :hide-footer="true"
      :hide-header="true"
      footer-class="border-0 pb-3 pt-0 mx-auto"
      size="lg"
      modal-class="gallery__modal"
      no-close-on-backdrop
    >
      <div class="position-relative mt-2">
        <button
          class="btn btn-close d-flex justify-content-center align-items-center position-absolute close-image"
          @click="$bvModal.hide(name)"
        >
          <b-icon icon="x"></b-icon>
        </button>
        <vs-tabs :color="colorx">
          <vs-tab label="Gallery">
            <div class="con-tab-ejemplo gallery mt-2">
              <div style="height: 28rem">
                <label for="search">
                  <input
                    type="text"
                    class="search-bar"
                    placeholder="Find a picture"
                    v-model="searchImage"
                    @change="getImageSearch(searchImage)"
                  />
                </label>
                <div class="d-flex align-items-center ml-3 mt-2">
                  <span class="text-muted powered-by">Powered By </span>
                  <img
                    src="@/assets/images/icons/unsplash-logo.svg"
                    alt="unsplash"
                    class="img-fluid ml-2"
                    width="70"
                  />
                </div>
                <div
                  class="container-fluid overflow-auto container-thumbnail mt-3"
                  id="img-load"
                  ref="imgLoad"
                >
                  <b-card-group columns>
                    <b-card
                      v-for="(value, index) in list_images"
                      :key="index"
                      class="px-0 unsplash-img"
                      :img-src="value.urls.thumb"
                      img-alt="Image"
                      img-top
                      body-class="p-0 m-0"
                      @click="selectImage(value.urls.regular, value.urls.thumb)"
                      :class="
                        selectedImage == value.urls.regular
                          ? 'image-selected'
                          : ''
                      "
                    >
                    </b-card>
                  </b-card-group>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Image Generator">
            <div class="gaim">
              <div class="mt-3">
                <div class="d-flex justify-content-end">
                  <div class="ma--img__subscription d-flex align-items-center dropdown mb-1">
                    <img
                      :src="require('@/assets/images/icons/ic_subscription_info.svg')"
                      class="ma--img__subscription-icon"
                      alt=""
                    />
                    <div class="ma--img__tooltip dropdown-menu">
                      <span> {{ allocation?.image_generates_quota ? allocation?.image_generates_quota : '0' }} images generation left. </span>
                      <br>
                      <span>Upgrade to get more image generation.</span>
                    </div>
                    <p class="ma--img__subscription-text m-0">{{ allocation?.image_generates_quota ? allocation?.image_generates_quota : '0' }} img</p>
                  </div>
                </div>
                <AiGeneratorImage
                  v-model="aiGeneratorData"
                  :is-generating="isAiGenerating"
                  @generate="handleGenerateAiImages"
                >
                  <template #settings>
                    <div class="ac__settings align-items-center p-3 text-white text-left">
                      <div class="ac__settings-section">
                        <p class="ac__settings-title m-0">Resolution</p>
                        <span class="ac__settings-divider">:</span>
                        <ul class="ac__settings-list d-flex m-0 list-unstyled">
                          <li v-for="resolution in resolutions" :key="resolution.value">
                            <button
                              type="button"
                              class="ac__settings-button ac__settings-button--resolution btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                              :class="{ 'ac__settings-button--active': resolution.value === aiGeneratorData.size }"
                              :title="`Select ${resolution.label}`"
                              @click="aiGeneratorData.size = resolution.value"
                            >
                              {{ resolution.label }}
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div class="ac__settings-section">
                        <p class="ac__settings-title m-0">Outputs</p>
                        <span class="ac__settings-divider">:</span>
                        <ul class="ac__settings-list d-flex m-0 list-unstyled">
                          <li v-for="output in outputs" :key="output">
                            <button
                              type="button"
                              class="ac__settings-button ac__settings-button--output btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                              :class="{ 'ac__settings-button--active': output === aiGeneratorData.output }"
                              :title="`Select ${output}`"
                              @click="aiGeneratorData.output = output"
                            >
                              {{ output }}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </AiGeneratorImage>
              </div>
              <!-- <p class="agi__text-info mt-2 mb-3">
                <img src="@/assets/images/icons/ic_info_navtt.svg" alt="Information" width="14" />
                &nbsp;you will be charged 5 SWC
              </p> -->
              <ul class="agi__list mt-3 mb-0 list-unstyled">
                <li
                  v-for="image in aiImages"
                  :key="image.id"
                  class="agi__item position-relative"
                  :class="{ 'agi__item--selected' : selectedImage === image.src }"
                >
                  <img :src="image.src" :alt="image.name" class="agi__item-image" />
                  <div class="agi__item-actions d-flex flex-column justify-content-center position-absolute p-4">
                    <button
                      type="button"
                      class="agi__item-action btn text-white"
                      :title="'Preview of ' + image.name"
                      @click="previewImage(image.src, image.name)"
                    >
                      Preview
                    </button>
                    <button
                      type="button"
                      class="agi__item-action btn text-white"
                      :title="'Use ' + image.name"
                      @click="selectImage(image.src)"
                    >
                      Use
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </vs-tab>
          <vs-tab label="Upload">
            <div
              class="con-tab-ejemplo upload-img"
              @dragover.prevent
              @dragenter.prevent="onDragEnter"
              @dragleave.prevent
              @drop.prevent
            >
              <div class="container-fluid">
                <b-form-group class="mt-2">
                  <b-alert
                    variant="danger"
                    :show="showAlertImgUpload"
                    fade
                    dismissible
                    @dismissed="showAlertImgUpload = false"
                    class="mb-0"
                  >
                    {{ responseImg }}
                  </b-alert>
                </b-form-group>
                <label
                  @drop="dragImage($event)"
                  :class="{ 'grabbing-cursor': isDrag }"
                  class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3"
                >
                  <div class="h-100 d-flex align-items-center">
                    <div v-if="!loadingImageUpload">
                      <img
                        src="@/assets/images/logo/paper_impor.svg"
                        alt=""
                        class="img-fluid"
                        width="30px;"
                      />
                      <h5
                        class="font-weight-normal mt-2"
                        style="color: #6d75f6"
                      >
                        Choose File or Drag Here
                      </h5>
                      <h6 class="font-weight-normal text-muted mt-1">
                        <i>format file : .jpg .png</i>
                      </h6>
                      <h6 class="font-weight-normal text-muted mt-1">
                        <i>max. file size 1MB</i>
                      </h6>
                    </div>
                    <div
                      v-else
                      class="spinner-border spinner-border-sm text-white"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <input
                    v-if="!loadingImageUpload"
                    type="file"
                    accept="image/jpeg, image/png"
                    style="display: none"
                    @change="imageUpload($event)"
                  />
                </label>
              </div>
              <div class="container-fluid">
                <h6 class="mt-4 font-weight-normal">Past Uploads</h6>
                <div v-if="!loadingGetImage">
                  <div
                    v-if="imageLibrary.length > 0"
                    class="row past-upload overflow-auto mt-1 pb-2"
                  >
                    <div
                      v-for="(data, index) in newImageLibrary"
                      :key="index"
                      class="col-3 mt-3"
                    >
                      <div
                        class="card cursor-pointer position-relative card__past-upload"
                        :class="
                          index % 4 == 0
                            ? 'mr-2 ml-3'
                            : index % 4 == 4
                            ? 'ml-2 mr-3'
                            : 'mx-2'
                        "
                        :style="
                          selectedImgLibrary == data._id
                            ? 'border: 3px solid #6D75F6'
                            : ''
                        "
                        @click="selectImageLibrary(data._id, data.image_url)"
                      >
                        <img
                          :src="data.image_url"
                          alt=""
                          class="card-img-top"
                        />
                        <img
                          v-if="selectedImgLibrary == data._id"
                          src="@/assets/images/icons/ic_imglib_selected.svg"
                          alt=""
                          class="position-absolute selected-img"
                        />
                        <div class="card-body p-2">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <p
                              class="mb-0 text-truncate"
                              style="font-size: 10px"
                            >
                              {{ data.filename ? data.filename : "-" }}
                            </p>
                            <div class="dropdown">
                              <button
                                class="btn aam__audio-option-button p-0"
                                type="button"
                                id="imglibrary"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <b-icon
                                  icon="three-dots-vertical"
                                  style="color: #fff"
                                  font-scale="0.8"
                                ></b-icon>
                              </button>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="imglibrary"
                              >
                                <li>
                                  <button
                                    class="btn btn-transparent pl-1 pr-2 py-0"
                                    @click="deleteImageLibrary(data._id)"
                                  >
                                    <div class="d-flex align-items-center">
                                      <span class="ml-2 text-white"
                                        >Delete</span
                                      >
                                    </div>
                                  </button>
                                </li>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="past-upload">
                    <div
                      class="d-flex align-items-center justify-content-center h-100"
                    >
                      <h6 class="font-weight-normal" style="color: #979797">
                        You don’t have any uploads
                      </h6>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row past-upload overflow-auto mt-1 pb-2">
                    <div v-for="index in 4" :key="index" class="col-3 mt-3">
                      <div
                        class="card"
                        :class="
                          index % 4 == 0
                            ? 'mr-2 ml-3'
                            : index % 4 == 4
                            ? 'ml-2 mr-3'
                            : 'mx-2'
                        "
                      >
                        <b-skeleton-img card-img="top"></b-skeleton-img>
                        <div class="card-body p-2">
                          <b-skeleton animation="wave" width="30%"></b-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
        <hr style="background-color: #2d2d2d" />
        <div class="float-right">
          <button class="btn btn-md btn-grey mr-2" @click="cancelImg">
            <p class="mb-0 mx-2 text-white">Cancel</p>
          </button>
          <button
            :disabled="!selectedImage"
            class="btn btn-purple btn-md text-white"
            @click="useImage()"
          >
            <p class="mb-0 mx-2">Use</p>
          </button>
        </div>
        <ImagePreviewModal id="image-preview" :src="imagePreview.src" :alt="imagePreview.alt" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import randomUUID from '@/helper/uuid';
import {
  postImageLibrary,
  getImageLibrary,
  deleteImageLibraryById,
} from "@/services/contents/contents.service";
import { getSearchImage } from "@/services/course/course.service";
import { getImageAiGenerator } from '@/services/ttv/ttv.service.js';
import { AlertUtils } from "@/mixins/AlertUtils";
import Swal from "sweetalert2";
import AiGeneratorImage from '@/components/generative-ai-text-to-audio/ai-generator-image';
import ImagePreviewModal from '@/components/modal/ImagePreview';
import { base_url_machine_learning } from '@/config/base_url';

export default {
  components: {
    AiGeneratorImage,
    ImagePreviewModal,
  },
  mixins: [AlertUtils],
  data() {
    return {
      colorx: "#6D75F6",
      perPageUnsplash: 30,
      pageUnsplash: 1,

      //data image
      searchImage: null,
      isLoadingSearch: false,
      list_images: [],
      selectedImage: this.value,
      imageArray: [],

      aiImages: [],
      aiGeneratorData: {
        text: '',
        engine: '1',
        size: '512x512',
        output: 2,
      },
      resolutions: [
        {
          label : '256px',
          value : '256x256',
        },
        {
          label : '512px',
          value : '512x512',
        },
        {
          label : '1024px',
          value : '1024x1024',
        },
      ],
      outputs: [1, 2, 3],
      isAiGenerating: false,

      //image user library
      imageLibrary: [],
      showAlertImgUpload: false,
      loadingImageUpload: false,
      loadingGetImage: false,
      responseImg: null,
      selectedImgLibrary: null,
      imagenameBefore: "",
      imageFileBefore: "",

      finalImage: this.value,
      isDrag: false,

      imagePreview: {
        src: '',
        alt: '',
      },
    };
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    allocation: {
			type: Object,
			default : () => {
				return null
			}
		},
  },

  watch: {
    value(newValue) {
      this.selectedImage = newValue;
    },
  },

  computed: {
    newImageLibrary() {
      return this.imageLibrary;
    },
  },

  mounted() {
    this.getImageLibrary();
    this.getImageSearch();
    this.getImageSearch();
  },

  methods: {
    getImageLibrary() {
      this.loadingGetImage = true;
      getImageLibrary().then((res) => {
        this.imageLibrary = res.data != null ? res.data : [];
        this.loadingGetImage = false;
      });
    },

    deleteImageLibrary(id) {
      deleteImageLibraryById(id).then((response) => {
        if (response.status == true) {
          this.getImageLibrary();
        }
      });
    },

    getImageSearch(search = "wallpaper") {
      var vm = this;
      vm.isLoadingSearch = true;
      vm.loadingUnsplash = true;
      vm.list_images = [];
      getSearchImage(search, vm.perPageUnsplash, vm.pageUnsplash)
        .then((response) => {
          if (response.status) {
            vm.list_images = response.data.results;
            vm.pageUnsplash += 1;
          } else {
            vm.list_images = [];
          }
          vm.isLoadingSearch = false;
          vm.loadingUnsplash = false;
        })
        .catch((err) => {
          console.log("data err", err);
          vm.isLoadingSearch = false;
        });
    },

    async handleGenerateAiImages() {
      this.aiImages = [];
      this.isAiGenerating = true;

      try {
        const res = await getImageAiGenerator(this.aiGeneratorData);

        if (res.status == 200) {
          for (const [index, image] of Object.entries(res.data)) {
            this.$emit('update:allocation')
            this.aiImages.push({
              type: 'ai',
              id: randomUUID(),
              name: 'AI Image ' + (Number(index) + 1),
              src: base_url_machine_learning + image,
            });
          }

          this.aiGeneratorData.text = '';
        } else if(res.status == 403) {
          this.$bvModal.show('img-quota-modal')
          this.aiGeneratorData.text = '';
        } else {
          this.$bvModal.show('server-error-modal');
        }
      } catch (error) {
        this.$bvModal.show('server-error-modal');
        console.error(error);
      }

      this.isAiGenerating = false;
    },

    dragImage(e) {
      this.isDrag = false;
      const file = [...e.dataTransfer.files];

      let type = file[0].type;
      const fileSize = file[0].size;

      if (type == "image/png" || type === "image/jpeg") {
        if (fileSize > 1000000) {
          Swal.fire({
            position: "top",
            background: "#FF000080",
            imageWidth: 18,
            imageHeight: 18,
            color: "white",
            title: this.$t("file-size-max-1mb"),
            titleFontSize: "14px",
            showConfirmButton: false,
            toast: true,
            timer: 2000,
            customClass: {
              container: "p-0",
            },
            didOpen: () => {
              const titleElement = document.querySelector(".swal2-title");

              // TITLE
              titleElement.style.display = "inline-block";
              titleElement.style.setProperty("padding-top", "0px");
              titleElement.style.setProperty("margin", "0px");
              titleElement.style.verticalAlign = "middle";
              titleElement.style.fontSize = "14px";
              titleElement.style.fontWeight = "normal";

              const containerElement = document.querySelector(".swal2-popup");
              containerElement.style.display = "flex";
              containerElement.style.justifyContent = "center";
              containerElement.style.alignItems = "center";
              containerElement.style.setProperty(
                "padding",
                "15px 30px 15px 30px"
              );
              containerElement.style.setProperty("margin-top", "15px");
              containerElement.style.setProperty("width", "auto");
            },
          });
        } else {
          this.imagenameBefore = file[0].name ? file[0].name : "Undefined";
          this.imageFileBefore = file[0];
          this.img_upload = URL.createObjectURL(this.imageFileBefore);
          this.convertImage(this.imageFileBefore, "image_upload", file[0].name);
        }
      } else {
        this.alertFail('The file must be in PNG, JPG or JPEG format')
      }
    },

    imageUpload(e) {
      let type = e.target.files[0].type;
      const fileSize = e.target.files[0].size;

      if (type == "image/png" || type === "image/jpeg") {
        if (fileSize > 1000000) {
          Swal.fire({
            position: "top",
            background: "#FF000080",
            imageWidth: 18,
            imageHeight: 18,
            color: "white",
            title: this.$t("file-size-max-1mb"),
            titleFontSize: "14px",
            showConfirmButton: false,
            toast: true,
            timer: 2000,
            customClass: {
              container: "p-0",
            },
            didOpen: () => {
              const titleElement = document.querySelector(".swal2-title");

              // TITLE
              titleElement.style.display = "inline-block";
              titleElement.style.setProperty("padding-top", "0px");
              titleElement.style.setProperty("margin", "0px");
              titleElement.style.verticalAlign = "middle";
              titleElement.style.fontSize = "14px";
              titleElement.style.fontWeight = "normal";

              const containerElement = document.querySelector(".swal2-popup");
              containerElement.style.display = "flex";
              containerElement.style.justifyContent = "center";
              containerElement.style.alignItems = "center";
              containerElement.style.setProperty(
                "padding",
                "15px 30px 15px 30px"
              );
              containerElement.style.setProperty("margin-top", "15px");
              containerElement.style.setProperty("width", "auto");
            },
          });
        } else {
          this.imagenameBefore = e.target.files[0].name
            ? e.target.files[0].name
            : "Undefined";
          this.imageFileBefore = e.target.files[0];
          this.img_upload = URL.createObjectURL(this.imageFileBefore);
          this.convertImage(
            this.imageFileBefore,
            "image_upload",
            e.target.files[0].name
          );
        }
      } else {
        this.alertFail('The file must be in PNG, JPG or JPEG format')
      }
    },

    convertImage(file, form, name) {
      var vm = this;
      var reader = new FileReader();
      reader.onloadend = function () {
        switch (form) {
          case "image_upload":
            vm.img_upload = reader.result;
            vm.postImageLibrary(reader.result, name);
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(file);
    },

    postImageLibrary(file, name) {
      this.loadingImageUpload = true;

      let formData = {
        images: file,
        filename: name,
      };

      postImageLibrary(formData).then((response) => {
        if (response.status == true) {
          this.loadingImageUpload = false;
          this.getImageLibrary();
        } else {
          this.loadingImageUpload = false;
        }
      });
    },

    selectImage(image_thumb) {
      this.selectedImage = image_thumb;
    },

    selectImageLibrary(id, image_url) {
      this.selectedImgLibrary = id;
      this.selectedImage = image_url;
    },

    cancelImg() {
      this.selectedImgLibrary = null;
      this.selectedImage = this.value;
      this.$bvModal.hide(this.name);
    },

    useImage() {
      this.$emit("input", this.selectedImage);
      this.$bvModal.hide(this.name);
    },
    onDragEnter(event) {
      event.preventDefault();
      this.isDrag = true;
    },
    previewImage(src, alt) {
      this.imagePreview = { src, alt };
      this.$bvModal.show('image-preview');
    },
  },
};
</script>

<style>
.modal-content {
  background-color: #1f1f1f;
  color: white;
  border-radius: 10px;
}

.gallery__modal .vs-tabs--li:nth-child(2) button {
  background-image:
    url('~@/assets/images/icons/ic_generator_star_default.svg'),
    url('~@/assets/images/icons/ic_generator_star_default.svg');
  background-repeat: no-repeat;
  background-position: left 13%, right 68%;
  background-size: 14px, 8px;
}

.gallery__modal .vs-tabs--li:nth-child(2).activeChild button {
  background-image:
    url('~@/assets/images/icons/ic_generator_star_active.svg'),
    url('~@/assets/images/icons/ic_generator_star_active.svg');
  background-position: left 8%, right 63%;
}
</style>

<style scoped>
.search-bar {
  height: 3rem;
  width: 100%;
  border-radius: 25px;
  border: 1px solid #2d2d2d;
  padding-left: 48px !important;
  background-color: transparent;
  padding-right: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  color: white;
}

.gallery label {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 25px !important;
  z-index: 2;
}

.gallery label:before {
  content: "";
  position: absolute;
  left: 1rem;
  top: 10px;
  bottom: 0;
  width: 40px;
  background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
  background-size: 20px;
}

.btn-impor-custom {
  border: 2px dashed #2d2d2d;
  border-radius: 10px;
}

.btn-impor-custom:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.container-thumbnail,
.btn-impor-custom {
  height: 25rem;
}

.container-thumbnail::-webkit-scrollbar,
.past-upload::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.container-thumbnail::-webkit-scrollbar-track,
.past-upload::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

/* Handle */
.container-thumbnail::-webkit-scrollbar-thumb,
.past-upload::-webkit-scrollbar-thumb {
  background: #b6b6b6;
  border-radius: 4px;
  background-clip: padding-box;
}

/* Handle on hover */
.container-thumbnail::-webkit-scrollbar-thumb:hover,
.past-upload::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.upload-img, .gaim {
  height: 28rem;
}

.ac__settings {
  display: grid;
  grid-template-columns: 70px 15px auto;
  gap: 8px 0;
  background-color: #2D2D2D;
  border-radius: 8px;
  font-size: 12px;
}

.ac__settings-section {
  display: contents;
}

.ac__settings-list {
  gap: 6px;
}

.ac__settings-button {
  gap: 4px;
  background-color: #414141;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ac__settings-button:not(.ac__settings-button--active):hover {
  background-color: #515151;
}

.ac__settings-button--active {
  background-color: #6D75F6;
}

.ac__settings-button--resolution {
  min-width: 50px;
}

.ac__settings-button--ratio {
  min-width: 50px;
}

.ac__settings-ratio-box {
  height: 12px;
  border-radius: 2px;
  border: 1px solid #FFFFFF;
}

.ac__settings-button--output {
  min-width: 40px;
}

.agi__text-info {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.agi__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  gap: 8px;
  padding-right: 24px;
  margin-right: 18px;
  overflow-y: auto;
}

.agi__item {
  width: 100%;
  height: 125px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: background-color 0.25s, border 0.25s;
}

.agi__item--selected {
  border-color: #6D75F6;
}

.agi__item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.agi__item-actions {
  opacity: 0;
  visibility: hidden;

  inset: 0;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.25s, visibility 0.25s;
}

.agi__item:hover .agi__item-actions {
  opacity: 1;
  visibility: visible;
}

.agi__item-action {
  padding: 2px;
  background-color: #6D75F6;
  font-size: 12px;
  transition: background-color 0.25s;
}

.agi__item-action:hover {
  background-color: #545EED;
}

.upload-img .btn-impor-custom {
  height: 10rem;
}

.upload-script .btn-impor-custom {
  height: 20rem;
}

.btn-purple {
  background-color: #6d75f6;
  border: 0px !important;
  color: white;
  transition: background-color 0.25s, color 0.25s;
}

.btn-grey {
  background-color: #2d2d2d !important;
  color: white;
  border: 0px !important;
  transition: background-color 0.25s, color 0.25s;
}

.btn-grey:hover {
  background-color: #282828 !important;
}

.btn-purple:disabled {
  background-color: #1f1f1f;
  color: #585859;
}

.btn-purple:not(:disabled):hover {
  background-color: #545eed;
}

.btn-close {
  background-color: #ededed;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}

.past-upload {
  height: 15rem;
}

.past-upload .card {
  border-radius: 8px;
  background-color: #2d2d2d;
}

.past-upload .card img {
  height: 109px;
  border-radius: 8px 8px 0px 0px !important;
}

.btn-transparent {
  color: white !important;
  background-color: transparent !important;
  box-shadow: none;
}

.image-selected {
  border: 1px solid #6D75F6 !important;
  border-radius: 10px !important;
}

.unsplash-img img {
  border: 1px solid transparent;
  border-radius: 10px;
}

.selected-img {
  top: 10px;
  right: 10px;
  width: 11px;
  height: 11px !important;
}

.close-image {
  right: 5px;
  z-index: 100;
}

.dropdown-menu {
  background-color: #2d2d2d;
  min-width: 5rem;
  box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.75);
}

.dropdown span,
.dropdown-menu span {
  font-size: 12px;
}

.dropdown-menu li {
  transition: background-color 0.25s;
}

.dropdown-menu li:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.card__past-upload {
  border: 3px solid transparent;
  transition: border 0.25s, color 0.25s;
}

.card__past-upload:hover {
  border: 3px solid #2d2d2d;
}

.aam__audio-option-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #ffffff;
  transition: background-color 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aam__audio-option-button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.grabbing-cursor {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}

.ma--img__subscription {
  gap: 6px;
}

.ma--img__subscription:hover .ma--img__tooltip {
  display: block;
}

.ma--img__tooltip {
  left: -265px;
  top: 0px;
  width: 260px !important;
  background-color: #1F1F1F;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}

.ma--img__subscription-icon {
  height: 14px;
}

.ma--img__subscription-text {
  font-size: 14px;
  font-weight: 500;
}
</style>

<style>
.gallery__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
</style>
