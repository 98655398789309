<template>
  <section class="tl d-flex flex-column">
    <TimelineHeader
      :is-timeline-shown="isTimelineShown"
      :disable-backward="disableBackward"
      :disable-main-control="disableMainControl"
      :disable-forward="disableForward"
      :main-control-state="mainControlState"
      :current-time="currentTime"
      :duration="durationTtv"
      :voice-volume="voiceVolume"
      :music-volume="musicVolume"
      :video-ratios="videoRatios"
      :video-ratio="videoRatio"
      @click:timeline-toggle="isTimelineShown = !isTimelineShown"
      @click:backward="handleClickBackward"
      @click:main-control="$emit('final-generate')"
      @click:forward="handleClickForward"
      @change:voice-volume="(newVoiceVolume) => voiceVolume = newVoiceVolume"
      @change:music-volume="(newMusicVolume) => musicVolume = newMusicVolume"
      @change:video-ratio="(newVideoRatio) => videoRatio = newVideoRatio"
    />
    <TimelineTrack
      :is-timeline-shown="isTimelineShown"
      :voices="voices"
      :musics="musics"
      :current-time="currentTime"
      :duration="duration"
      :extra-duration="durationReference === 'musics'"
      :disable-edit="mainControlState === 'generating' || mainControlState === 'pause'"
      @click:add-music="$emit('click:add-music')"
      @delete:voice="(id, iav_id) => $emit('delete:voice', id, iav_id)"
      @delete:music="(id) => $emit('delete:music', id)"
      @change:voices-order="(newVoice) => $emit('change:voices-order', newVoice)"
      @change:musics-order="(musics, oldIndex, newIndex) => $emit('change:musics-order', musics, oldIndex, newIndex)"
      @change:music-duration="(musicTimelineId, newMusicDuration) => $emit('change:music-duration', musicTimelineId, newMusicDuration)"
      @change:current-time="(newCurrentTime) => currentTime = newCurrentTime"
    />
  </section>
</template>

<script>
import TimelineHeader from './Header';
import TimelineTrack from './Track';

export default {
  components: {
    TimelineHeader,
    TimelineTrack,
  },
  props: {
    voices: {
      type: Array,
      default: () => [],
    },
    musics: {
      type: Array,
      default: () => [],
    },
    disableMainControl: {
      type: Boolean,
      default: false
    },
    mainControl: {
      type: String,
      validator(value) {
        return ['generate', 'generating', 'play', 'pause'].includes(value);
      },
      default: 'generate',
    },
    currentTimeVideo: {
      type: Number,
      default: 0,
    },
    durationTtv: {
      type: Number, // In seconds
      required: true,
    },
  },
  emits: ['click:add-music', 'delete:voice', 'delete:music', 'change:voices-order', 'change:musics-order', 'change:music-duration'],
  data() {
    return {
      isTimelineShown: true,

      mainControlState: this.mainControl,
      currentTime: this.currentTimeVideo,

      voiceVolume: 0.5,
      musicVolume: 0.5,

      videoRatios: [
        {
          id: '9:16',
          name: 'Reels, Shorts & Tiktok',
          imageSrc: require('@/assets/images/icons/large-video-phone.png'),
        },
        {
          id: '1:1',
          name: 'FB & IG Feeds',
          imageSrc: require('@/assets/images/icons/medium-video-phone.png'),
        },
        {
          id: '16:9',
          name: 'YouTube',
          imageSrc: require('@/assets/images/icons/small-video-phone.png'),
        },
      ],
      videoRatio: '16:9',
    };
  },
  computed: {
    duration() {
      const voicesTotalDuration = this.voices.reduce((totalDuration, voice) => {
        return totalDuration + voice.duration;
      }, 0);
      const musicsTotalDuration = this.musics.reduce((totalDuration, music) => {
        return totalDuration + music.duration;
      }, 0);

      if (voicesTotalDuration > musicsTotalDuration) {
        return voicesTotalDuration;
      } else {
        return musicsTotalDuration;
      }
    },
    durationReference() {
      const voicesTotalDuration = this.voices.reduce((totalDuration, voice) => {
        return totalDuration + voice.duration;
      }, 0);
      const musicsTotalDuration = this.musics.reduce((totalDuration, music) => {
        return totalDuration + music.duration;
      }, 0);

      if (voicesTotalDuration > musicsTotalDuration) {
        return 'voices';
      } else {
        return 'musics';
      }
    },
    disableBackward() {
      return this.currentTime === 0 || this.mainControlState === 'pause';
    },
    disableForward() {
      return this.currentTime === this.duration || this.mainControlState === 'pause';
    },
  },
  watch: {
    voices() {
      if (this.currentTime > this.duration) {
        this.currentTime = 0;
      }
    },
    musics() {
      if (this.currentTime > this.duration) {
        this.currentTime = 0;
      }
    },
    voiceVolume(newVoiceVolume) {
      sessionStorage.setItem("ttv:voice-volume", newVoiceVolume)
      if(sessionStorage.getItem("ttv:voice-volume") == sessionStorage.getItem("ttv:voice-volume-generate")) {
        this.$emit('voice-volume-change', 'play')
      } else {
        this.$emit('voice-volume-change', 'generate')
      }
    },
    musicVolume(newMusicVolume) {
      sessionStorage.setItem("ttv:music-volume", newMusicVolume)
      if(sessionStorage.getItem("ttv:music-volume") == sessionStorage.getItem("ttv:music-volume-generate")) {
        this.$emit('music-volume-change', 'play')
      } else {
        this.$emit('music-volume-change', 'generate')
      }
    },
    mainControl(newMainControl) {
      this.mainControlState = newMainControl
    },
    currentTimeVideo(newCurrentTime){
      this.currentTime = newCurrentTime
    },
    currentTime(newCurrentTime){
      this.$emit('update:current-time', newCurrentTime)
    }
  },
  methods: {
    handleClickBackward() {
      this.currentTime = 0;
    },
    handleClickMainControl() {
      if (this.mainControlState === 'generate') {
        this.mainControlState = 'generating';
        this.disableBackward = true;
        this.disableForward = true;

        const mockLoading = () => {
          this.mainControlState = 'play';
          this.disableBackward = false;
          this.disableForward = false;
          clearTimeout(mockLoading);
        };

        setTimeout(mockLoading, 5000);
      } else if (this.mainControlState === 'play') {
        this.mainControlState = 'pause';
      } else if (this.mainControlState === 'pause') {
        this.mainControlState = 'play';
      }
    },
    handleClickForward() {
      this.currentTime = this.duration;
    },
    getInitData(){
      const voiceVolumeInStorage = sessionStorage.getItem("ttv:voice-volume") ? sessionStorage.getItem("ttv:voice-volume") : 0.5
      const musicVolumeInStorage = sessionStorage.getItem("ttv:music-volume") ? sessionStorage.getItem("ttv:music-volume") : 0.5

      this.voiceVolume = parseFloat(voiceVolumeInStorage)
      this.musicVolume = parseFloat(musicVolumeInStorage)
    }
  },
  mounted(){
    this.getInitData()
  }
};
</script>

<style scoped>
</style>
