<template>
  <div class="ttq d-flex flex-column text-white">
    <Header
      v-model="updateQuizData"
      :options="quizOptions"
      :categories="fixed_categories"
      :enable-save="isEnableSaveButton"
      :status-subscriptions="status_subscriptions"
      @save-quiz="handleSaveQuiz"
      @back="back()"
    />
    <main class="ttq__main flex-grow-1 d-flex">
      <QuestionList
        ref="questionList"
        :questions="questions"
        :active-number="activeQuestionNumber"
        :disabled-action="isGenerating"
        @add-question="handleAddQuestion"
        @click-number="handleChangeActiveQuestionNumber"
        @click:duplicate="handleDuplicateQuestion"
        @click:delete="handleDeleteQuestion"
        @after-drag:question="handleChangeQuestionOrder"
        @update:hide-prompt="(questionsGeneratorStatus) => isQuestionsGeneratorShown = questionsGeneratorStatus"
        @import="$bvModal.show('import-quiz-excel')"
      />
      <div class="ttq__main-center position-relative flex-grow-1 d-flex flex-column">
        <QuestionView
          ref="questionView"
          v-model="questions[activeQuestionNumber - 1]"
          :is-generate="isGenerating"
          :layout="questions.length > 0 && activeQuestionNumber > 0 ? activeQuestion.layout : 'empty'"
          :allocation="status_subscriptions"
          @update:allocation="getStatusSubscriptions"
          @delete-question="handleDeleteQuestion"
          @delete-text="handleDeleteText"
          @focus-editor="(editor) => activeEditor = editor"
        />
        <div
          ref="promptWrapper"
          class="ttq__questions-generator-wrapper position-absolute"
          :class="{ 'ttq__questions-generator-wrapper--show': isQuestionsGeneratorShown }"
        >
          <QuestionsGenerator
            :options="generatorOptions"
            :show="isQuestionsGeneratorShown"
            :is-generating="isGenerating"
            :placeholders="placeholders"
            @generate="handleGenerateQuestions"
            @generate:summarize="handleGenerateSummarize"
            @set-type="handleType"
            @set-language="handleLanguage"
            @update:output="handleUpdateOutput"
            @reset:output="getOutput"
          />
        </div>
      </div>
      <ToolsSidebar
        :materials-disable-status="materialsDisableStatus"
        :show-questions-generator="isQuestionsGeneratorShown"
        @click-material-text="handleClickMaterialText"
        @click-material-equation="handleClickMaterialEquation"
        @click-material-image="handleClickMaterialImg"
        @click-material-youtube="handleClickMaterialYoutube"
        @click-material-audio="handleClickMaterialAudio"
        @toggle-questions-generator="(questionsGeneratorStatus) => isQuestionsGeneratorShown = questionsGeneratorStatus"
        @toggle-quiz-templates="(value) => isQuizTemplatesShown = value"
        @render:quiz="getInitData"
        @show:loading-quiz="(val) => isGenerating = val"
      />
    </main>
    <AlertResponse :icon-alert="iconAlert" :title-alert="titleAlert" :text-alert1="textAlert1" :text-alert2="textAlert2"/>
    <ImportQuizExcel id="import-quiz-excel" :initial-questions="questions" on-page @import="handleImportFromExcel" />
    <QuotaHabis :click-upgrade="handleClickUpgrade" />
    <ModalSaveDraftTTQ @delete="deleteDraft" @save-draft="saveToDraft" @cancel="willRedirectForUpgrade = false"/>
    <Soca v-if="isLoading || loadingQuiz"/>
    <Gallery2 name="material-img" :on-insert="insertedimg" :allocation="status_subscriptions" @update:allocation="getStatusSubscriptions"/>
    <ServerErrorModal />
    <IBImageGenerator :click-upgrade="handleClickUpgrade"/>
  </div>
</template>

<script>
import Header from '@/components/generative-ai/header/Header';
import QuestionList from '@/components/generative-ai/question-list/QuestionList';
import QuestionView from '@/components/generative-ai/question-view/QuestionView';
import QuestionsGenerator from '@/components/generative-ai/questions-generator-new/QuestionsGenerator';
import ToolsSidebar from '@/components/generative-ai/tools-sidebar/ToolsSidebar';
import AlertResponse from '@/components/generative-ai/modals/AlertResponse';
import ImportQuizExcel from '@/components/modal/ImportQuizExcel';
import QuotaHabis from '@/components/modal/QuotaHabis'
import ModalSaveDraftTTQ from '@/components/modal/ModalSaveDraftTTQ.vue'
import Soca from '@/components/loading/Soca'
import ServerErrorModal from '@/components/modal/ServerError';
import IBImageGenerator from '@/components/modal/IBImageGenerator';

import { getGrades } from '@/services/generative-ai/generative-ai.service'
import { getCategoriesTtq } from '@/services/quiz-categories/quiz_categories.service'
import { getGenerativeQuestionClassification } from '@/services/generative-ai/generative-ai.service'
import { base_url } from "../../../../config/base_url";
import { addQuizCreator, updateQuizCreator } from '@/services/quiz-creator/quiz-creator.service'
import { postQuestionCreator, putQuestionCreator, addQuestionCreator, deleteQuestionCreator } from '@/services/question-creator/question_creator.service'
import { getQuizCreatorById } from '@/services/quiz-creator/quiz-creator.service'
import { getQuestionCreator } from '@/services/question-creator/question_creator.service'
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'
import { AlertUtils } from "@/mixins/AlertUtils";
import { postDocuments } from "@/services/my-assets/my-assets.service"

import MathEquationModal, {
  properties as mathEquationModalProperties
} from '@/components/creator-text-editor/modals/MathEquationModal.vue';
import Gallery2 from '../../../../components/generative-ai/modals/Gallery2.vue';

import isTTQQuestionValid from '@/helper/is-ttq-question-valid';

export default {
  mixins: [AlertUtils],
  components: {
    Header,
    QuestionList,
    QuestionView,
    QuestionsGenerator,
    ToolsSidebar,
    AlertResponse,
    ImportQuizExcel,
    QuotaHabis,
    ModalSaveDraftTTQ,
    Gallery2,
    ServerErrorModal,
    IBImageGenerator,
    Soca
  },

  beforeRouteLeave(to, from, next) {
     window.addEventListener('popstate', () => {
        // this.back()
        this.removeLocalStorage()
      });
    next()
  },

  data() {
    return {
      questions: [],
      deletedQuestion: [],
      categories: [],
      fixed_categories: [],
      fixed_grade: [],
      list_level: [],
      list_output: [],
      list_language: [],
      list_grade: [],
      loadingQuiz: false,
      activeQuestionNumber: 0,
      status_subscriptions: {},
      quizData: {
        quizName: '',
        description: '',
        topic: '',
        gradeLevel: '',
        subLevel: '',
        coverUrl: '',
      },
      isQuestionsGeneratorShown: true,
      isQuizTemplatesShown: false,
      isGenerating: false,
      loadingSave: false,
      loadingGetData: false,
      activeEditor: null,
      iconAlert: null,
      titleAlert: "",
      textAlert1: "",
      textAlert2: "",
      type: 'Multiple choice',
      language: 'ID',

      test: {},

      willRedirectForUpgrade: false,
    };
  },
  computed: {
    activeQuestion() {
      return this.questions[this.activeQuestionNumber - 1];
    },

    materialsDisableStatus() {
      return {
        text: this.activeQuestion ? this.activeQuestion.layout !== 'video-and-images' || this.activeQuestion.question !== null : true,
        equation: this.activeEditor === null || this.activeQuestion?.layout == 'blank',
        youtube: (this.activeEditor != null && this.activeEditor.type != 'question') || this.activeQuestion?.layout == 'blank',
        audio : (this.activeEditor != null && this.activeEditor.type != 'question') || this.activeQuestion?.layout == 'blank',
        image: this.activeEditor === null || this.activeQuestion?.layout == 'blank',
        action: this.isGenerating || this.questions.length < 1,
        ai: this.isGenerating,
      };
    },

    generatorOptions() {
      return {
          topics: this.categories.map((category) => ({
              id: category._id, name: category.category_name,
          })),
          gradeLevels: this.list_grade.map((gradeLevel) => {
              const newGradeLevel = {
                  id: gradeLevel._id,
                  name: gradeLevel.grade,
              };

              newGradeLevel.subLevels = gradeLevel.sub_grade.map((subLevel) => ({
                  id: subLevel._id, name: subLevel.sub_grade,
              }));

              return newGradeLevel;
          }),
          levels: this.list_level.map((level) => ({
              id: level._id, name: level.level,
          })),
          outputs: this.list_output.map((output) => ({
              id: output._id, name: output.output,
          })),
          languages: this.list_language.map((language) => ({
              id: language._id, name: language.language, flagUrl: language.flag,
          })),
      };
    },

    quizOptions() {
      return {
          topics: this.fixed_categories.map((category) => ({
              id: category._id, name: category.category_name,
          })),
          gradeLevels: this.fixed_grade.map((gradeLevel) => {
              const newGradeLevel = {
                  id: gradeLevel._id,
                  name: gradeLevel.grade,
              };

              newGradeLevel.subLevels = gradeLevel.sub_grade.map((subLevel) => ({
                  id: subLevel._id, name: subLevel.sub_grade,
              }));

              return newGradeLevel;
          }),
      };
    },

    updateQuizData: {
      get() {
        return this.quizData;
      },
      set(value) {
        this.quizData = value;
      }
    },

    isLoading(){
      return this.loadingGetData || this.loadingSave;
    },

    placeholders() {
      let placeholders = [];

      if (this.language === 'ID') {
        if (this.type === 'Code') {
          placeholders = [
            'Buatkan soal tentang koneksi ke database mySQL menggunakan bahasa pemrograman PHP Laravel.',
            'Buatlah soal mengenai Exception Handling pada Java.',
            'Buatlah soal mengenai callback function pada JavaScript.',
            'Buatlah soal mengenai array pada PHP.',
            'Buatlah soal mengenai class dan struct pada Swift.',
            'Buatlah soal mengenai LINQ pada C#.',
            'Buatlah soal mengenai list dan tuple pada Python.',
            'Buatlah soal mengenai reference dan pointer pada C++.',
          ];
        } else {
          placeholders = [
            'Buatkan soal teorema Pythagoras dan aplikasinya dalam menghitung jarak dan sudut dalam segitiga siku-siku.',
            'Buatlah soal tentang trigonometri dasar (sinus, kosinus, dan tangen) dengan sudut-sudut antara 0-90 derajat.',
            'Buatlah soal tentang sejarah sains, mengenai ilmuwan terkenal dan penemuan mereka sepanjang sejarah.',
            'Buatkan soal tentang astronomi mengenai sistem tata surya, bintang, dan galaksi.',
            'Buatkan soal mengenai komponen perangkat keras beserta kegunaannya.',
            'Buatkan soal mengenai keamanan siber meliputi enkripsi data, keamanan jaringan, dan ancaman siber.',
            'Buatlah soal tentang teknik listrik, meliputi konsep hukum Ohm dan hukum Kirchhoff.',
            'Buatlah soal mengenai teknik kimia, mengenai termodinamika dan reaksi kimia.',
          ];
        }
      } else {
        if (this.type === 'Code') {
          placeholders = [
            'Create questions in python, for cleansing and looping data with pandas.',
            'Create a question about Exception Handling in Java.',
            'Create a question about callback functions in JavaScript.',
            'Create a question about arrays in PHP.',
            'Create a question about classes and structs in Swift.',
            'Create a question about LINQ in C#.',
            'Create a question about lists and tuples in Python.',
            'Create a question about references and pointers in C++.',
          ];
        } else {
          placeholders = [
            'Create questions about the Pythagorean theorem and its application in calculating distance and angles in right triangles.',
            'Create questions about basic trigonometry (sine, cosine, and tangent) with angles between 0-90 degrees.',
            'Create questions about the history of science, covering famous scientists and their discoveries throughout history.',
            'Create questions about astronomy, including the solar system, stars, and galaxies.',
            'Create questions about hardware components and their uses.',
            'Create questions about cybersecurity, covering data encryption, network security, and cyber threats.',
            'Create questions about electrical engineering, including the concepts of Ohm\'s law and Kirchhoff\'s laws.',
            'Create questions about chemical engineering, covering thermodynamics and chemical reactions.',
          ];
        }
      }

      return placeholders;
    },

    handleClickUpgrade() {
      if (this.questions.length > 0) {
        return () => {
          this.$bvModal.hide('audio-quota');
          this.$bvModal.show('alert-savedraft')
          this.willRedirectForUpgrade = true;
        };
      } else {
        return null;
      }
    },

    isEnableSaveButton() {
      if (this.questions.length > 0) {
        for (const question of this.questions) {
          if(question.layout != 'blank') {
            if (!isTTQQuestionValid(question)) {
              return false;
            }
          } else {
            let title = !!question.title?.trim() && question.title?.trim() !== '<p></p>'
            if(!title) {
              return false
            }
          }
        }

        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    questions(update){
      localStorage.listQuestionCreate = JSON.stringify(update)
    },

    deletedQuestion(update){
      localStorage.deletedQuestion = JSON.stringify(update)
    },

    activeQuestionNumber(active) {
      localStorage.question_active = active
    },

    type() {
      this.getLevel();
      this.getOutput();
      this.getLanguages();
      this.getListGrade();
      this.getCategories();
    }
  },

  mounted(){
    this.getLevel();
    this.getOutput();
    this.getLanguages();
    this.getListGrade();
    this.getCategories();
    this.getInitData();
    this.getStatusSubscriptions();
    // this.getHistoryData();
  },

  methods: {
    closePromptWhenClickOutSide(e) {
      if (this.isQuestionsGeneratorShown && (!this.$refs.promptWrapper.contains(e.target) || !this.$refs.toolsSidebarTtq.contains(e.target))) {
        console.log(e.target)
        this.isQuestionsGeneratorShown = false
      }
    },
    showAndHideLoadingRenderDuplicate(value){
      this.loadingQuiz = value;
    },
    handleAddQuestion(newQuestion) {
      this.questions.splice(this.activeQuestionNumber, 0, newQuestion);
      this.activeQuestionNumber++;
      this.isQuestionsGeneratorShown = false;
    },

    handleDuplicateQuestion(index) {
      const duplicateQuestion = { ...this.questions[index], _id: null };
      this.questions.splice(index + 1, 0, duplicateQuestion);

      if (this.activeQuestionNumber === index + 1) {
        this.activeQuestionNumber = index + 2; // Jump to the duplicate question
      } else if (this.activeQuestionNumber > index + 1) {
        this.activeQuestionNumber++;
      }
    },

    handleDeleteQuestion(index = this.activeQuestionNumber - 1) {
      const deletedData = this.questions.splice(index, 1);

      if(deletedData[0]._id){
        this.deletedQuestion.push(deletedData[0])
      }

      if (
        (this.activeQuestionNumber > index + 1)
        || ((this.activeQuestionNumber === index + 1) && (this.activeQuestionNumber === this.questions.length + 1))
      ) {
        this.activeQuestionNumber--;
      }
    },

    handleDeleteText(index = this.activeQuestionNumber - 1) {
      this.questions[index].text = null
    },

    handleChangeQuestionOrder(newQuestions, oldNumber, newNumber) {
      this.questions = newQuestions;

      if (this.activeQuestionNumber === oldNumber) {
        this.activeQuestionNumber = newNumber;
      } else if (this.activeQuestionNumber > oldNumber && this.activeQuestionNumber <= newNumber) {
        this.activeQuestionNumber = this.activeQuestionNumber - 1;
      } else if (this.activeQuestionNumber < oldNumber && this.activeQuestionNumber >= newNumber ) {
        this.activeQuestionNumber = this.activeQuestionNumber + 1;
      }
    },

    handleImportFromExcel() {
      this.questions = JSON.parse(localStorage.getItem('listQuestionCreate'));

      if (this.activeQuestionNumber < 1) {
        this.activeQuestionNumber = 1;
      }

      this.isQuestionsGeneratorShown = false;
    },

    handleChangeActiveQuestionNumber(newActiveQuestionNumber) {
      this.activeQuestionNumber = newActiveQuestionNumber;
    },

    handleClickMaterialText() {
      if(this.questions[this.activeQuestionNumber - 1].layout == 'blank') {
        this.questions[this.activeQuestionNumber - 1].text = '';
      } else if(this.questions[this.activeQuestionNumber - 1].layout != 'true-false') {
        this.questions[this.activeQuestionNumber - 1].image_url = null;
        this.questions[this.activeQuestionNumber - 1].voice = null;
        this.questions[this.activeQuestionNumber - 1].video = null;
        this.questions[this.activeQuestionNumber - 1].layout = 'text-only';
        this.questions[this.activeQuestionNumber - 1].layoutThumbnail = base_url + '/layout-thumbnail/fill_the_blank_wide.svg';
        this.questions[this.activeQuestionNumber - 1].question_type = 'Multiple Choice';
        this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
      } else {
        this.questions[this.activeQuestionNumber - 1].image_url = null;
        this.questions[this.activeQuestionNumber - 1].voice = null;
        this.questions[this.activeQuestionNumber - 1].video = null;
        this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
      }
    },

    handleClickMaterialImage() {
      this.questions[this.activeQuestionNumber - 1].image_url = '';
    },

    handleClickMaterialYoutube(){
      if(this.questions[this.activeQuestionNumber - 1].layout != 'true-false') {
        this.questions[this.activeQuestionNumber - 1].voice = null;
        this.questions[this.activeQuestionNumber - 1].image_url = null;
        this.questions[this.activeQuestionNumber - 1].video = '';
        this.questions[this.activeQuestionNumber - 1].layout = 'text-only';
        this.questions[this.activeQuestionNumber - 1].question_type = 'Multiple Choice';
        this.questions[this.activeQuestionNumber - 1].layoutThumbnail = base_url + '/layout-thumbnail/video_and_images_wide.svg';
        this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
      } else {
        this.questions[this.activeQuestionNumber - 1].voice = null;
        this.questions[this.activeQuestionNumber - 1].image_url = null;
        this.questions[this.activeQuestionNumber - 1].video = '';
        this.questions[this.activeQuestionNumber - 1].question_type = 'True or False';
        this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
      }
    },

    handleClickMaterialAudio() {
      if(this.questions[this.activeQuestionNumber - 1].layout != 'true-false') {
        this.questions[this.activeQuestionNumber - 1].video = null;
        this.questions[this.activeQuestionNumber - 1].image_url = null;
        this.questions[this.activeQuestionNumber - 1].voice = '';
        this.questions[this.activeQuestionNumber - 1].layout = 'text-only';
        this.questions[this.activeQuestionNumber - 1].question_type = 'Multiple Choice';
        this.questions[this.activeQuestionNumber - 1].layoutThumbnail = base_url + '/layout-thumbnail/audio_listening_wide.svg';
        this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
      } else {
        this.questions[this.activeQuestionNumber - 1].video = null;
        this.questions[this.activeQuestionNumber - 1].image_url = null;
        this.questions[this.activeQuestionNumber - 1].voice = '';
        this.questions[this.activeQuestionNumber - 1].question_type = 'True or False';
        this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
      }
    },

    handleClickMaterialImg() {
      if(this.activeEditor.type == 'question'){
        if(this.questions[this.activeQuestionNumber - 1].layout == 'true-false') {
          this.questions[this.activeQuestionNumber - 1].video = null;
          this.questions[this.activeQuestionNumber - 1].voice = null;
          this.questions[this.activeQuestionNumber - 1].image_url = '';
          this.questions[this.activeQuestionNumber - 1].question_type = 'True or False';
          this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
        } else if (this.questions[this.activeQuestionNumber - 1].layout != 'text-only') {
          this.questions[this.activeQuestionNumber - 1].video = null;
          this.questions[this.activeQuestionNumber - 1].voice = null;
          this.questions[this.activeQuestionNumber - 1].image_url = '';
          this.questions[this.activeQuestionNumber - 1].layout = 'text-only';
          this.questions[this.activeQuestionNumber - 1].question_type = 'Multiple Choice';
          this.questions[this.activeQuestionNumber - 1].layoutThumbnail = base_url + '/layout-thumbnail/fill_the_blank_wide.svg';
          this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
        } else {
          this.questions[this.activeQuestionNumber - 1].video = null;
          this.questions[this.activeQuestionNumber - 1].voice = null;
          this.questions[this.activeQuestionNumber - 1].image_url = '';
          this.questions[this.activeQuestionNumber - 1].question_type = 'Multiple Choice';
          this.questions[this.activeQuestionNumber - 1].correct_answer = this.questions[this.activeQuestionNumber - 1].correct_answer;
        }
      } else {
        this.$bvModal.show('material-img')
      }
    },

    insertedimg(data) {
      if(data){
        this.activeEditor.editor
        .chain()
        .focus()
        .setImage({ src : data.src })
        .run()
      }
    },

    handleClickMaterialEquation() {
      this.$modal.show(
        MathEquationModal,
        {
          onInsert: (tex) => {
            if (tex) {
              this.activeEditor.editor
                .chain()
                .focus()
                .insertContent(`<span class="tex">${tex}</span>`)
                .run();
            }
          },
        },
        mathEquationModalProperties,
      );
    },

    handleGenerateQuestions(formData) {
      this.isGenerating = true

      if(this.type == 'Code') {

        const fixFormDataCode = {
          subject: this.learnings?.find((learn)=>learn._id === formData.topic).category_name,
          description: formData.description,
          length: this.list_output?.find((output) => output._id === formData.settings.output).output,
          language : this.list_language?.find((lang) => lang._id === formData.language).objid,
          question_type: this.type,
          level: this.list_level?.find((level) =>level._id === formData.settings.level).level,
        }

        getGenerativeQuestionClassification(fixFormDataCode)
        .then((response) => {
          console.log(response)
          if(response?.status == 200 ) {
            if(Array.isArray(response.data.result)) {
              response.data.result.forEach((element) => {
                this.questions.push({
                  "layout": 'code-test',
                  "layoutThumbnail": base_url + '/layout-thumbnail/code_test_wide.svg',
                  "question_type": "Code Test",
                  "question": "<p>"+element.question+"</p>",
                  "code": element.code,
                  "code_language": "py",
                  "option_1": "<p>"+element.choices['1']+"</p>",
                  "option_2": "<p>"+element.choices['2']+"</p>",
                  "option_3": "<p>"+element.choices['3']+"</p>",
                  "option_4": "<p>"+element.choices['4']+"</p>",
                  "correct_answer": element.answer,
                  "point": 10,
                  "duration": 30,
                  "_id": 60750 + 0
                });
              });
            } else {
              this.questions.push({
                  "question_type": "Code Test",
                  "layout": 'code-test',
                  "layoutThumbnail": base_url + '/layout-thumbnail/fill_the_blank_wide.svg',
                  "question": "<p>"+response.data.result.question+"</p>",
                  "code": response.data.result.code,
                  "code_language": "py",
                  "option_1": "<p>"+response.data.result.choices['1']+"</p>",
                  "option_2": "<p>"+response.data.result.choices['2']+"</p>",
                  "option_3": "<p>"+response.data.result.choices['3']+"</p>",
                  "option_4": "<p>"+response.data.result.choices['4']+"</p>",
                  "correct_answer": response.data.result.answer,
                  "point": 10,
                  "duration": 30,
                  "_id": 60750 + 0
              });
            }
            this.getStatusSubscriptions()
            this.activeQuestionNumber = this.questions.length
            localStorage.setItem("listQuestionCreate", JSON.stringify(this.questions))
          } else if (response.response.status == 403){
            this.$bvModal.show('audio-quota')
          } else {
            this.iconAlert = require('@/assets/images/icons/modal_warning.svg');
            this.titleAlert = this.$t('service-unavailable');
            this.textAlert1 = this.$t('service-unavailable-p');
            this.$bvModal.show("alert-response");
          }

          this.isGenerating = false
        })
        .catch((error) => {
          this.isGenerating = false
          if(error.status == 403) {
            this.$bvModal.show('audio-quota')
          } else {
            this.iconAlert = require('@/assets/images/icons/modal_warning.svg');
            this.titleAlert = this.$t('service-unavailable');
            this.textAlert1 = this.$t('service-unavailable-p');
            this.$bvModal.show("alert-response");
          }
        })
      } else {
        const fixFormDataMcq = {
          subject: this.learnings?.find((learn)=>learn._id === formData.topic).category_name,
          description: formData.description,
          length: this.list_output?.find((output) => output._id === formData.settings.output).output,
          language : this.list_language?.find((lang) => lang._id === formData.language).objid,
          question_type: this.type,
          grade: formData.settings.gradeLevel,
          sub_grade: formData.settings.subLevel,
        }

        getGenerativeQuestionClassification(fixFormDataMcq)
        .then((response) => {
          if(response?.status == 200 ) {
            response.data.result.forEach((element) => {
              this.questions.push({
                "layout": 'text-only',
                "layoutThumbnail": base_url + '/layout-thumbnail/fill_the_blank_wide.svg',
                "question_type": "Multiple Choice",
                "question": "<p>"+element.question+"</p>",
                "option_1": "<p>"+element.choices['1']+"</p>",
                "option_2": "<p>"+element.choices['2']+"</p>",
                "option_3": "<p>"+element.choices['3']+"</p>",
                "option_4": "<p>"+element.choices['4']+"</p>",
                "correct_answer": element.answer,
                "video": null,
                "voice": null,
                "image_url": null,
                "point": 10,
                "duration": 30,
                "_id": 60750 + 0
              });
            });
            this.getStatusSubscriptions()
            this.activeQuestionNumber = this.questions.length
          } else if (response.response.status == 403){
            this.$bvModal.show('audio-quota')
          } else {
            this.iconAlert = require('@/assets/images/icons/modal_warning.svg');
            this.titleAlert = this.$t('service-unavailable');
            this.textAlert1 = this.$t('service-unavailable-p');
            this.$bvModal.show("alert-response");
          }
          this.isGenerating = false
        })
        .catch((error) => {
          this.isGenerating = false
          if(error.status == 403) {
            this.$bvModal.show('audio-quota')
          } else {
            this.iconAlert = require('@/assets/images/icons/modal_warning.svg');
            this.titleAlert = this.$t('service-unavailable');
            this.textAlert1 = this.$t('service-unavailable-p');
            this.$bvModal.show("alert-response");
          }
        })
      }
    },

    async handleGenerateSummarize(file) {
      this.isGenerating = true
      let length = this.list_output?.find((output) => output._id === file.length).output
      let formData = new FormData();
      let lang = this.list_language?.find((lang) => lang._id === file.lang).objid
  		formData.append('document', file.file);
  		formData.append('length', length);
  		formData.append('language', lang);

      await postDocuments(formData)
			.then((response) => {
				if(response.status == 200) {
          response.data.result.forEach((element) => {
            this.questions.push({
              "layout": 'text-only',
              "layoutThumbnail": base_url + '/layout-thumbnail/fill_the_blank_wide.svg',
              "question_type": "Multiple Choice",
              "question": "<p>"+element.question+"</p>",
              "option_1": "<p>"+element.options['a']+"</p>",
              "option_2": "<p>"+element.options['b']+"</p>",
              "option_3": "<p>"+element.options['c']+"</p>",
              "option_4": "<p>"+element.options['d']+"</p>",
              "correct_answer": element.answer == 'a' ? '1' : element.answer == 'b' ? '2' : element.answer == 'c' ? '3' : element.answer == 'd' ? '4' : '1',
              "video": null,
              "voice": null,
              "image_url": null,
              "point": 10,
              "duration": 30,
              "_id": 60750 + 0
            });
          });
          this.getStatusSubscriptions()
          this.activeQuestionNumber = this.questions.length
				} else if(response.status == 403) {
          this.$bvModal.show('audio-quota')
				} else if (response.status == false && response.response.status == 403) {
          this.$bvModal.show('audio-quota')
				} else if (response.status == 405 || response.response.status == 405) {
          this.$bvModal.show('sqc-modal')
        } else {
          this.iconAlert = require('@/assets/images/icons/modal_warning.svg');
          this.titleAlert = this.$t('service-unavailable');
          this.textAlert1 = this.$t('service-unavailable-p');
          this.$bvModal.show("alert-response");
        }
				this.isGenerating = false
			})
			.catch((error) => {
        if(error.status == 403) {
          this.$bvModal.show('audio-quota')
        } else {
          this.iconAlert = require('@/assets/images/icons/modal_warning.svg');
          this.titleAlert = this.$t('service-unavailable');
          this.textAlert1 = this.$t('service-unavailable-p');
          this.$bvModal.show("alert-response");
        }
				this.isGenerating = false
				console.error(error)
			})
    },

    handleSaveQuiz() {
      if(!this.quizData.quizName || !this.quizData.topic) {
        this.$modal.show('save-modal')
      } else {
        this.loadingSave = true

        // let query = this.$route.query

        let formData = {
          quiz_name: this.quizData.quizName,
          description: this.quizData.description,
          images_cover: this.quizData.coverUrl ? this.quizData.coverUrl : null ,
          category_id: this.quizData.topic,
          grade_id: this.quizData.gradeLevel,
          sub_grade_id: this.quizData.subLevel,
          is_draft: false,
          // is_use_template: query.author != undefined && query.author == 0 ? true : false,
          // quiz_template_id: query.idTemp ? query.idTemp : null,
        }

        let mode = this.$route.params.mode
        let id = this.$route.params.id

        if(mode == 'edit' || mode == 'draft'){
          this.handleQuestionsToDeleteInDatabase();

          updateQuizCreator(formData, id)
          .then(async (response) => {
            if(response.status) {
              if(this.deletedQuestion.length > 0) {
                for (const question of this.deletedQuestion) {
                  await deleteQuestionCreator(question._id);
                }
              }

              for (const question of this.questions) {
                if (question._id) {
                  await putQuestionCreator(question, question._id);
                } else {
                  await addQuestionCreator(question, id);
                }
              }

              const length = this.questions.reduce((totalDuration, question) => {
                  return totalDuration + (question.duration || 30);
              }, 0);

              if (localStorage.getItem("quizTime")) {
                localStorage.removeItem("quizTime")
              }

              if (localStorage.getItem("sisaWaktu")) {
                localStorage.removeItem("sisaWaktu")
              }
              localStorage.setItem("quizTime", length)
              this.removeLocalStorage()
              this.$router.push({ name: 'Use-Template', query: { key: id, creator: true } })
            }
          })
        } else {
          addQuizCreator(formData)
          .then(async (response) => {
            if(response.status) {
              for (const question of this.questions) {
                question._id = null;
                await postQuestionCreator(question, response.data._id);
              }

              this.removeLocalStorage();
              this.loadingSave = false;

              const length = this.questions.reduce((totalDuration, question) => {
                  return totalDuration + (question.duration || 30);
              }, 0);

              if (localStorage.getItem("quizTime")) {
                localStorage.removeItem("quizTime")
              }

              if (localStorage.getItem("sisaWaktu")) {
                localStorage.removeItem("sisaWaktu")
              }
              localStorage.setItem("quizTime", length)
              this.$router.push({ name: 'Use-Template', query: { key: response.data._id, creator: true } })
            }
          })
        }
      }
    },

    handleType(val){
      this.type = val
    },

    handleLanguage(newLanguage) {
      this.language = newLanguage;
    },

    getCategories() {
      if(this.type == 'Code') {
        this.categories = [
            {
                _id: '1',
                category_name: 'Python',
            },
            {
                _id: '2',
                category_name: 'JavaScript',
            },
            {
                _id: '3',
                category_name: 'Java',
            },
            {
                _id: '4',
                category_name: 'C',
            },
            {
                _id: '5',
                category_name: 'C++',
            },
        ];
      } else {
        getCategoriesTtq()
        .then((response) => {
          if(response.status == true) {
            this.categories = response.data
          } else {
            this.categories = []
          }
        })
        .catch((error) => {
          this.categories = []
          console.log(error)
        })
      }

      getCategoriesTtq()
      .then((response) => {
        if(response.status == true) {
          this.fixed_categories = response.data
        } else {
          this.fixed_categories = []
        }
      })
      .catch((error) => {
        this.fixed_categories = []
        console.log(error)
      })
    },

    getLevel() {
      if(this.type == 'Code') {
        this.list_level = [
            {
                _id: '1',
                level: 'Basic',
            },
            {
                _id: '2',
                level: 'Intermediate',
            },
            {
                _id: '3',
                level: 'Expert',
            },
        ];
      } else {
        this.list_level = []
      }
    },

    getOutput(){
      if(this.type == 'Code') {
        this.list_output = [
          {
            _id: '1',
            output: 2
          },
          {
            _id: '2',
            output: 5
          },
          {
            _id: '3',
            output: 7
          }
        ]
      } else {
        this.list_output = [
          {
            _id: '1',
            output: 2
          },
          {
            _id: '2',
            output: 5
          },
          {
            _id: '3',
            output: 10
          }
        ]
      }
    },

    handleUpdateOutput() {
      this.list_output = [
        {
          _id: '1',
          output: 2
        },
        {
          _id: '2',
          output: 5
        },
        {
          _id: '3',
          output: 8
        }
      ]
    },

    getLanguages() {
      this.list_language = [
          {
              _id: '1',
              language: 'ID',
              flag: 'https://flagicons.lipis.dev/flags/4x3/id.svg',
              objid: '6401ae940eb4d9111ec260d7'
          },
          {
              _id: '2',
              language: 'EN',
              flag: 'https://flagicons.lipis.dev/flags/4x3/us.svg',
              objid: '64017a04a5fbff45c62e64d8'
          },
      ];
    },

    getListGrade(){
      if(this.type != 'Code') {
        getGrades()
        .then((response) => {
          if(response.status) {
            this.list_grade = response.data
          }
        })
      } else {
        this.list_grade = []
      }

      getGrades()
      .then((response) => {
        if(response.status) {
          this.fixed_grade = response.data
        }
      })
    },

    getHistoryData(){
      if(localStorage.listQuestionCreate) {
        this.questions = JSON.parse(localStorage.getItem('listQuestionCreate'))
      }

      if(localStorage.deletedQuestion){
        this.deletedQuestion = JSON.parse(localStorage.deletedQuestion)
      }

      if(localStorage.question_active) {
        var getQuestionActive = localStorage.question_active
        this.activeQuestionNumber = parseInt(getQuestionActive)
      }

      if(localStorage.quiz_name){
        this.quizData.quizName = localStorage.quiz_name
      }

      if(localStorage.quiz_description){
          this.quizData.description = localStorage.quiz_description
      }

      if(localStorage.images_cover){
          this.quizData.coverUrl = localStorage.images_cover
      }

      if(localStorage.category_id){
          this.quizData.topic = localStorage.category_id
      }

      if(localStorage.grade){
          this.quizData.gradeLevel = localStorage.grade
      }

      if(localStorage.sub_grade){
          this.quizData.subLevel = localStorage.sub_grade
      }
    },

    removeLocalStorage(){
      localStorage.removeItem('quiz_name')
      localStorage.removeItem('quiz_description')
      localStorage.removeItem('images_cover')
      localStorage.removeItem('category_id')
      localStorage.removeItem('grade')
      localStorage.removeItem('sub_grade')
      localStorage.removeItem('listQuestionCreate')
      localStorage.removeItem('question_active')
      localStorage.removeItem('deletedQuestion')

      localStorage.removeItem('quiz_name_init')
      localStorage.removeItem('quiz_description_init')
      localStorage.removeItem('images_cover_init')
      localStorage.removeItem('category_id_init')
      localStorage.removeItem('grade_init')
      localStorage.removeItem('sub_grade_init')
      localStorage.removeItem('listQuestionCreate_init')
    },

    getInitData(){
      this.loadingGetData = true

      let mode = this.$route.params.mode
      let id = this.$route.params.id

      if(localStorage.quiz_name || localStorage.quiz_description || localStorage.images_cover || localStorage.category_id || localStorage.grade || localStorage.sub_grade || localStorage.listQuestionCreate){
        //Jika ada
        this.loadingGetData = false
        this.getHistoryData()
      } else {
        if(mode == 'edit' || mode == 'draft'){
          this.isQuestionsGeneratorShown = false
          getQuizCreatorById(id)
          .then((response) => {
            if(response.status) {
              let data = response.data[0]
              this.setInitQuiz(data)

              this.quizData.quizName = data.quiz_name
              this.quizData.description = data.description
              this.quizData.coverUrl = data.images_cover ? data.images_cover : ''
              this.quizData.topic = data.category_id
              this.quizData.gradeLevel = data.grade_id
              this.quizData.subLevel = data.sub_grade_id

              localStorage.quiz_name = data.quiz_name
              localStorage.quiz_description = data.description
              localStorage.images_cover = data.images_cover ? data.images_cover : ''
              localStorage.category_id = data.category_id
              localStorage.grade = data.grade_id
              localStorage.sub_grade = data.sub_grade_id

              getQuestionCreator(id)
              .then((res) => {
                if(res.status) {
                  this.questions = res.data
                  this.setInitQuestion(res.data)
                  localStorage.question_active = this.questions.length
                  this.getHistoryData()
                  this.loadingGetData = false
                } else {
                  this.loadingGetData = false
                }
              })
              .catch((error)=> {
                console.log(error)
                this.loadingGetData = false
              })
            } else {
              this.loadingGetData = false
            }
          })
          .catch((error) => {
            console.log(error)
            this.loadingGetData = false
          })
        } else {
          this.loadingGetData = false
        }
      }
    },

    //For Compare Change
    setInitQuiz(data){
      localStorage.quiz_name_init = data.quiz_name
      localStorage.quiz_description_init = data.description
      localStorage.images_cover_init = data.images_cover ? data.images_cover : ''
      localStorage.category_id_init = data.category_id
      localStorage.grade_init = data.grade_id
      localStorage.sub_grade_init = data.sub_grade_id
    },

    setInitQuestion(data){
      localStorage.setItem('listQuestionCreate_init', JSON.stringify(data))
    },

    back(){
      let mode = this.$route.params.mode

      if(mode == 'edit' || mode == 'draft') {
        if(
          localStorage.quiz_name_init == localStorage.quiz_name
          &&
          localStorage.quiz_description_init == localStorage.quiz_description
          &&
          localStorage.images_cover_init == localStorage.images_cover
          &&
          localStorage.category_id_init == localStorage.category_id
          &&
          localStorage.grade_init == localStorage.grade
          &&
          localStorage.listQuestionCreate_init == localStorage.listQuestionCreate
          &&
          localStorage.sub_grade_init == localStorage.sub_grade
        ) {
          this.deleteDraft()
        } else {
          this.$bvModal.show('alert-savedraft')
        }
      } else {
        if(localStorage.quiz_name || localStorage.quiz_description || localStorage.images_cover || localStorage.category_id || localStorage.grade || localStorage.sub_grade || localStorage.listQuestionCreate) {
          this.$bvModal.show('alert-savedraft')
        } else {
          this.deleteDraft()
        }
      }
    },

    saveToDraft(){
      let mode = this.$route.params.mode
      let id = this.$route.params.id

      let is_draft = true

      // let query = this.$route.query
      // let is_use_template = false
      // let id_template = null

      if(mode === 'edit'){is_draft = false}

      let formData = {
        quiz_name: this.quizData.quizName ? this.quizData.quizName : 'Untitled',
        description: this.quizData.description,
        images_cover: this.quizData.coverUrl ? this.quizData.coverUrl : null,
        category_id: this.quizData.topic,
        grade_id: this.quizData.gradeLevel,
        sub_grade_id: this.quizData.subLevel,
        is_draft: is_draft,
        // is_use_template: is_use_template,
        // quiz_template_id: id_template ? id_template : null,
      }

      if(mode == 'edit' || mode == 'draft'){
        this.handleQuestionsToDeleteInDatabase();

        updateQuizCreator(formData, id)
        .then(async (response) => {
          if(response.status) {
            if(this.deletedQuestion.length > 0) {
              for (const question of this.deletedQuestion) {
                await deleteQuestionCreator(question._id);
              }
            }

            for (const question of this.questions) {
              if (question._id) {
                await putQuestionCreator(question, question._id);
              } else {
                await addQuestionCreator(question, id);
              }
            }

            this.removeLocalStorage()

            if (this.willRedirectForUpgrade) {
              this.$router.push({ name: 'TopUp' });
            } else {
              this.$router.push('/projects');
            }
          }
        })
      } else {
        addQuizCreator(formData)
        .then(async (response) => {
          if(response.status) {
            for (const question of this.questions) {
              question._id = null;
              await postQuestionCreator(question, response.data._id);
            }

            this.removeLocalStorage();
            this.loadingSave = false;

            const length = this.questions.reduce((totalDuration, question) => {
                return totalDuration + (question.duration || 30);
            }, 0);

            if (localStorage.getItem("quizTime")) {
              localStorage.removeItem("quizTime")
            }

            if (localStorage.getItem("sisaWaktu")) {
              localStorage.removeItem("sisaWaktu")
            }
            localStorage.setItem("quizTime", length)

            if (this.willRedirectForUpgrade) {
              this.willRedirectForUpgrade = false;
              this.$router.push({ name: 'TopUp' });
            } else {
              this.$router.push('/projects');
            }
          }
        })
      }
    },

    async handleQuestionsToDeleteInDatabase() {
      const initialQuestions = await JSON.parse(localStorage.getItem('listQuestionCreate_init'));

      let firstQuestionIndexToDelete = null;

      for (const [questionIndex, question] of this.questions.entries()) {
        if (question?._id && question?._id !== initialQuestions[questionIndex]._id) {
          firstQuestionIndexToDelete = questionIndex;
          break;
        }
      }

      if (firstQuestionIndexToDelete !== null) {
        this.questions = this.questions.slice(firstQuestionIndexToDelete, this.questions.length).map((question) => {
          const isQuestionInDeletedList = this.deletedQuestion.find((deletedQuestion) => deletedQuestion._id === question._id);

          if (!isQuestionInDeletedList && question._id) {
            this.deletedQuestion.push(question);
            return ({ ...question, _id: null });
          }

          return question;
        });
      }
    },

    deleteDraft() {
      this.removeLocalStorage()
      this.$router.push('/home')
      // if (this.willRedirectForUpgrade) {
      //   this.willRedirectForUpgrade = false;
      //   this.$router.push({ name: 'TopUp' });
      // } else {
      //   this.$router.push('/home')
      // }
    },

    getStatusSubscriptions(){
      subscriptionsStatus()
      .then((response) => {
        if(response.status) {
          this.status_subscriptions = response.data
        }
      })
    },

    isLink(text) {
      const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/
      return urlPattern.test(text)
    },
  },
};
</script>

<style scoped>
.ttq {
  height: 100vh;
  max-height: 100vh;
}

.ttq__main {
  overflow: hidden;
}

.ttq__main-center {
  gap: 16px;
}

.ttq__questions-generator-wrapper {
  visibility: hidden;

  left: 50%;
  bottom: 32px;
  transform: translateX(-50%);
  width: 100%;
  max-width: 700px;
  transition: visibility 0.25s;
}

.ttq__questions-generator-wrapper--show {
  visibility: visible;
  z-index: 500;
}
</style>
