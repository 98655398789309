<template>
  <div
    class="qv p-4 flex-grow-1"
    :class="{ 'qv--thumbnail': type === 'thumbnail' }"
    :inert="type === 'thumbnail'"
  >
    <EmptyView v-if="layout === 'empty'" :is-generate="isGenerate"/>
    <CodeTestView
      v-else-if="layout === 'code-test'"
      v-model="question"
      :type="type"
      :is-generate="isGenerate"
      @delete-question="$emit('delete-question')"
    />
    <VideoAndAnswersView
      v-else-if="layout === 'video-and-answers'"
      v-model="question"
      :type="type"
      :is-generate="isGenerate"
      @delete-question="$emit('delete-question')"
      @focus-editor="(editor) => $emit('focus-editor', editor)"
    />
    <AudioListeningView
      v-else-if="layout === 'audio-listening'"
      v-model="question"
      :type="type"
      :is-generate="isGenerate"
      @delete-question="$emit('delete-question')"
      @focus-editor="(editor) => $emit('focus-editor', editor)"
    />
    <TextAndImageOptionsView
      v-else-if="layout === 'text-and-image-options'"
      v-model="question"
      @delete-question="$emit('delete-question')"
      @focus-editor="(editor) => $emit('focus-editor', editor)"
    />
    <TrueFalseView
      v-else-if="layout === 'true-false'"
      v-model="question"
      :type="type"
      :is-generate="isGenerate"
      :allocation="allocation"
      @update:allocation="$emit('update:allocation')"
      @delete-question="$emit('delete-question')"
      @focus-editor="(editor) => $emit('focus-editor', editor)"
    />
    <FillTheBlankView
      v-else-if="layout === 'fill-the-blank' || layout === 'text-only'"
      v-model="question"
      :type="type"
      :is-generate="isGenerate"
      :allocation="allocation"
      @update:allocation="$emit('update:allocation')"
      @delete-question="$emit('delete-question')"
      @focus-editor="(editor) => $emit('focus-editor', editor)"
    />
    <VideoAndImagesView
      v-else-if="layout === 'video-and-images'"
      v-model="question"
      @delete-question="$emit('delete-question')"
    />
    <BlankView
      v-else-if="layout === 'blank'"
      v-model="question"
      :type="type"
      :is-generate="isGenerate"
      @delete-question="$emit('delete-question')"
      @delete-text="$emit('delete-text')"
      @focus-editor="(editor) => $emit('focus-editor', editor)"
    />
  </div>
</template>

<script>
import EmptyView from './EmptyView';
import CodeTestView from './CodeTestView';
import VideoAndAnswersView from './VideoAndAnswersView';
import AudioListeningView from './AudioListeningView';
import TextAndImageOptionsView from './TextAndImageOptionsView';
import TrueFalseView from './TrueFalseView';
import FillTheBlankView from './FillTheBlankView';
import VideoAndImagesView from './VideoAndImagesView';
import BlankView from './BlankView';

export default {
  components: {
    EmptyView,
    CodeTestView,
    VideoAndAnswersView,
    AudioListeningView,
    TextAndImageOptionsView,
    TrueFalseView,
    FillTheBlankView,
    VideoAndImagesView,
    BlankView
  },
  props: {
    type: {
      validator(value) {
        return ['default', 'thumbnail'].includes(value);
      },
      default: 'default',
    },
    value: {
      type: Object,
      default: null,
    },
    layout: {
      validator(value) {
        return [
          'empty', 'code-test', 'video-and-answers', 'audio-listening', 'text-and-image-options', 'true-false', 'fill-the-blank', 'video-and-images', 'text-only', 'blank',
        ].includes(value);
      },
      default: 'empty',
    },
    isGenerate: {
      type: Boolean,
      default: false
    },
    allocation: {
			type: Object,
			default : () => {
				return null
			}
		},
  },
  emits: ['input', 'delete-question', 'focus-editor'],
  data() {
    return {
      question: this.value,
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        if (newQuestion) {
          this.$emit('input', newQuestion);
        }
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.question = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.qv {
  scroll-behavior: smooth;
  overflow: hidden;
}

.qv--thumbnail {
  position: absolute;
  width: 925px;
  height: 500px;
  transform: scale(0.14, 0.14);
  pointer-events: none;
}
</style>
