import axios from "../../config/axios"
import { base_url_machine_learning, base_url_bossman } from '../../config/base_url'
import ApiRoute from '../api.route';

export async function getGenerativeQuestion(formData) {
    return axios.post(base_url_machine_learning + 'quiz', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getGenerativeQuestionClassification(formData) {
    return axios.post(base_url_machine_learning + 'quiz/classification', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getGrades() {
    return axios({
        method : 'GET',
        url : base_url_bossman + ApiRoute.masterGrades + '/all'
    }).then((res) => {
        const data = {
            status:res.data.status,
            data:res.data.data,
            message:res.data.message
        }
        return data;
    }).catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function postRating(formData) {
    return axios.post(base_url_machine_learning + 'quiz/rating', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}
