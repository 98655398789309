<template>
  <div class="gv__color-picker p-2 d-flex flex-column align-items-center">
    <SketchPicker v-model="selectedColorA" @input="handleUpdateValue"/>
    <SliderPicker v-model="selectedColorA" />

    <div class="cp__form w-100">
		<div class="container-fluid">
			<div class="row align-items-center">
				<div class="col-2">
					<p class="m-0 text-left">HEX</p>
				</div>
				<div class="col-10 text-left">
					<div>
						<div class="cp__form--input d-flex align-items-center">
							<div class="cp__preview" :style="{ backgroundColor : hexColor }"></div>
							<div class="d-flex">
								<input v-model="hexColor" class="cp__form--input--hex"/>
							</div>
						</div>
					</div>
				</div>
				<div class="col-2 mt-2">
					<p class="m-0 text-left">RGB</p>
				</div>
				<div class="col-10 mt-2">
					<div class="rgb--wrapper d-flex">
						<input class="cp__form--input cp__form--input--rgb" v-model="rgbColor.r" type="number" min="0" max="255" @input="updateColorFromRGB" @focus="isRgbFocus = true" @blur="isRgbFocus = false" />
						<input class="cp__form--input cp__form--input--rgb" v-model="rgbColor.g" type="number" min="0" max="255" @input="updateColorFromRGB" @focus="isRgbFocus = true" @blur="isRgbFocus = false" />
						<input class="cp__form--input cp__form--input--rgb" v-model="rgbColor.b" type="number" min="0" max="255" @input="updateColorFromRGB" @focus="isRgbFocus = true" @blur="isRgbFocus = false" />
					</div>
				</div>
			</div>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  props :{
	color : {
		type : String,
		default : '#1F1F1F'
	}
  },
  data() {
    return {
      selectedColorA : this.color,
      hue: 0,
      hexColor: this.color,
      rgbColor: {
        r: 0,
        g: 0,
        b: 0
      },
      selectedColor: '#000000',
      isHexFocus: false,
      isRgbFocus: false,
    };
  },
  mounted() {
  },
  watch :{
    hexColor(newVal) {
      this.selectedColorA = newVal
      if(!this.isRgbFocus) {
        this.updateRGBFromHex()
      }
      this.selectedColor = newVal
	    this.$emit('change:color', newVal ? newVal : '#1F1F1F')
    },
	color(newVal) {
		this.hexColor = newVal
	}
  },
  methods: {
    handleUpdateValue(){
      this.hexColor = this.selectedColorA.hex
    },
    updateColorFromHex() {
      this.selectedColor = this.hexColor;
      this.updateRGBFromHex();
    },
    updateRGBFromHex() {
      const hex = this.hexColor.replace('#', '');
      this.rgbColor.r = parseInt(hex.substr(0, 2), 16);
      this.rgbColor.g = parseInt(hex.substr(2, 2), 16);
      this.rgbColor.b = parseInt(hex.substr(4, 2), 16);
    },
    updateColorFromRGB() {
      this.hexColor = this.rgbToHex(this.rgbColor.r, this.rgbColor.g, this.rgbColor.b);
      this.selectedColorA = this.hexColor;
    },
    rgbToHex(r, g, b) {
      const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      };
      const red = componentToHex(parseInt(r) || 0);
      const green = componentToHex(parseInt(g) || 0);
      const blue = componentToHex(parseInt(b) || 0);

      return `#${red}${green}${blue}`;
    }
  }
};
</script>

<style scoped>
.gv__color-picker {
	gap: 13px;
}
.cp__form--input {
	border-radius: 3px;
	border: 0.575px solid #585859;
	background: #2D2D2D;
	color: #fff;
	padding: 6px 4px;
	gap: 6px;
	width: fit-content;
	text-align: center;
}

.cp__form--input--hex {
	background-color: transparent;
	border: none;
	color: #fff;
	width: 80px;
}

.cp__form--input--rgb::-webkit-inner-spin-button,
.cp__form--input--rgb::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cp__preview {
	width: 20px;
	height: 22px;
	border-radius: 2px;
}

.rgb--wrapper {
	gap: 6px;
}
</style>

<style>
.gv__color-picker .vc-sketch {
  	padding: 0;
  	width: 100%;
}
.gv__color-picker .vc-slider {
	width: 100%;
}
.gv__color-picker .vc-sketch-controls, .gv__color-picker .vc-sketch-field, .gv__color-picker .vc-sketch-presets, .gv__color-picker .vc-slider-swatches{
  	display: none;
}
</style>
