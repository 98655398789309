<template>
    <div>
        <b-modal :id="id" class="pc--modal" :no-close-on-backdrop="load_topup" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm" dialog-class="pcc__dialog d-flex justify-content-center" content-class="pcc__content">
            <div class="float-right">
                <button :disabled="load_topup" class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide(id)"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Warn">
                <div class="mt-4 text-white" v-if="packageId.length == 3">
                    <h4 class="font-weight-bold mb-4">{{$t('multi-pro-subscription-required')}}</h4>
                    <h5 class="font-weight-normal" v-html="$t('multi-pro-subscription-required__desc')"></h5>
                </div>
                <div class="mt-4 text-white" v-else-if="packageId.length == 6">
                    <h4 class="font-weight-bold mb-4">{{ $t('multi-creator-subscription-required') }}</h4>
                    <h5 class="font-weight-normal" v-html="$t('multi-creator-subscription-required__desc')"></h5>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4">
                    <button :disabled="load_topup" class="btn btn-grey mr-1" @click="$bvModal.hide(id)"><p class="mx-2 mb-0 font-weight-bold text-white">Later</p></button>
                    <button :disabled="load_topup" class="btn btn-purple ml-1" @click="$router.push({ name:'TopUp' })"><p class="mx-2 mb-0 font-weight-bold text-white">{{ load_topup ? 'Loading...' : 'Upgrade Now' }}</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { topupSwc } from '@/services/subscriptions/subscriptions.service'

export default {
	props: {
		id: {
			type: String,
			default: 'package-character-modal'
		},
		packageId: {
			type: Array,
			default: () => {
				return []
			}
		}
	},
	data() {
		return {
			load_topup: false
		}
	},
	methods: {
		clickUpgrade() {
			if(this.packageId.length == 3) {
				this.createInvoice('65b9fae964aac1efa774a3f0')
			} else if (this.packageId.length == 6) {
				this.createInvoice('65b9fad164aac1efa774a3ee')
			}
		},
		createInvoice(id){
			this.load_topup = true;

			let formData = {
				package_id : id,
			}

			topupSwc(formData)
			.then((response) => {
				if(response.status == true){
					this.$bvModal.hide(id)
					this.load_topup = false;
					this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id, redirect : this.$route.fullPath } })
				} else {
					this.load_topup = false;
				}
			})
			.catch((err) => {
				this.load_topup = false;
				console.log(err)
			})
		},
	}

}
</script>

<style scoped>
.btn-purple {
  background-color: #6d75f6;
  color: white;
  box-shadow: none !important;
  transition: background-color 0.25s;
}

.btn-grey {
  background-color: #2D2D2D;
  color: white;
  box-shadow: none !important;
  transition: background-color 0.25s;
}

.btn-purple:not(:disabled):hover {
	background-color: #545EED;
}

.btn-grey:not(:disabled):hover {
	background-color: #292828;
}
</style>
<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

.pcc__dialog .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pcc__dialog .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

.pcc__dialog .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

button {
  box-shadow: none !important;
}
</style>