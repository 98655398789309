<template>
  <header class="tlh position-relative d-flex justify-content-center align-items-center p-2">
    <button
      type="button"
      class="tlh__timeline-toggle btn position-absolute d-inline-flex align-items-center px-2 py-1 rounded-pill text-white"
      :title="isTimelineShown ? 'Hide Timeline' : 'Show Timeline'"
      @click="$emit('click:timeline-toggle')"
    >
      <font-awesome-icon
        icon="fa-solid fa-chevron-up"
        class="tlh__timeline-toggle-icon"
        :class="{ 'tlh__timeline-toggle-icon--bottom': isTimelineShown }"
      />
      Timeline
    </button>

    <div class="tlh__controls d-flex align-items-center">
      <button
        type="button"
        class="tlh__control-button tlh__control-button--step btn d-inline-flex justify-content-center align-items-center rounded-circle text-white"
        title="Backward"
        :disabled="disableBackward"
        @click="$emit('click:backward')"
      >
        <font-awesome-icon icon="fa-solid fa-backward-step" />
        <span class="sr-only">Backward</span>
      </button>
      <p
        v-if="mainControlState === 'generating'"
        class="tlh__generating-message d-flex align-items-center mb-0 mx-2"
      >
        <font-awesome-icon icon="fa-solid fa-spinner" spin />
        Generating Video
      </p>
      <button
        v-else
        type="button"
        class="tlh__control-button btn d-inline-flex justify-content-center align-items-center rounded-circle"
        :class="{
          'tlh__control-button--main': mainControlState !== 'generate',
          'tlh__control-button--generate': mainControlState === 'generate'
        }"
        :title="mainControlState.charAt(0).toUpperCase() + mainControlState.slice(1)"
        :disabled="disableMainControl"
        @click="$emit('click:main-control')"
      >
        <font-awesome-icon
          :icon="`fa-solid ${mainControlState === 'pause' ? 'fa-pause' : 'fa-play'}`"
        />
        <span class="sr-only">
          {{ mainControlState.charAt(0).toUpperCase() + mainControlState.slice(1) }}
        </span>
      </button>
      <button
        type="button"
        class="tlh__control-button tlh__control-button--step btn d-inline-flex justify-content-center align-items-center rounded-circle text-white"
        title="Forward"
        :disabled="disableForward"
        @click="$emit('click:forward')"
      >
        <font-awesome-icon icon="fa-solid fa-forward-step" />
        <span class="sr-only">Forward</span>
      </button>
    </div>

    <div class="tlh__timestamp d-flex align-items-center text-white">
      <span class="tlh__time">{{ calculateTime(currentTime) }}</span>
      <span class="tlh__time tlh__time--gray">/</span>
      <span class="tlh__time tlh__time--gray">{{ calculateTime(duration) }}</span>
    </div>

    <div class="tlh__extras">
      <button
        type="button"
        name="equalizer"
        class="tlh__extras-toggle btn d-inline-flex justify-content-center align-items-center"
        :class="{ 'tlh__extras-toggle--active': isEqualizerShown }"
        :title="`${isEqualizerShown ? 'Hide' : 'Show'} Equalizer`"
        :disabled="mainControlState == 'generating'"
      >
        <img
          :src="require('@/assets/images/icons/ic_settings_timeline.svg')"
          class="tlh__extras-toggle-icon"
          alt="Equalizer"
        />
        <span class="sr-only">Equalizer</span>
      </button>

      <tippy
        to="equalizer"
        trigger="click"
        interactive
        :animate-fill="false"
        :on-show="() => isEqualizerShown = true"
        :on-hide="() => {
          isEqualizerShown = false;
          return true;
        }"
        :duration="250"
        :max-width="500"
        theme="tlh__extras"
      >
        <div class="tlh__extras-menu d-flex flex-column p-3">
          <p class="tlh__extras-title m-0">Equalizer</p>
          <div class="tlh__equalizer d-flex">
            <div class="tlh__equalizer-slider d-flex flex-column">
              <span class="sr-only">Voice Volume</span>
              <VueSlider
                :value="voiceVolume"
                direction="btt"
                :width="8"
                :height="120"
                :min="0"
                :max="1"
                :interval="0.1"
                adsorb
                tooltip="none"
                dot-style="tlh__equalizer-slider-dot"
                title="Voice Volume"
                @change="(newVoiceVolume) => $emit('change:voice-volume', newVoiceVolume)"
              />
              <img
                :src="require('@/assets/images/icons/ic_mic_eq.svg')"
                class="tlh__equalizer-slider-icon"
                alt="Voice"
              />
            </div>
            <div class="tlh__equalizer-slider d-flex flex-column">
              <span class="sr-only">Music Volume</span>
              <VueSlider
                :value="musicVolume"
                direction="btt"
                :width="8"
                :height="120"
                :min="0"
                :max="1"
                :interval="0.1"
                adsorb
                tooltip="none"
                dot-style="tlh__equalizer-slider-dot"
                title="Music Volume"
                @change="(newMusicVolume) => $emit('change:music-volume', newMusicVolume)"
              />
              <img
                :src="require('@/assets/images/icons/ic_music_eq.svg')"
                class="tlh__equalizer-slider-icon"
                alt="Music"
              />
            </div>
          </div>
        </div>
      </tippy>
    </div>

    <div class="tlh__extras">
      <!-- <button
        type="button"
        name="videoPreferences"
        class="tlh__extras-toggle btn d-inline-flex justify-content-center align-items-center"
        :class="{ 'tlh__extras-toggle--active': isVideoPreferencesShown }"
        :title="`${isVideoPreferencesShown ? 'Hide' : 'Show'} Video Preferences`"
      >
        <img
          :src="require('@/assets/images/icons/ic-menu-phone.svg')"
          class="tlh__extras-toggle-icon"
          alt="Video Preferences"
        />
        <span class="sr-only">Video Preferences</span>
      </button>

      <tippy
        to="videoPreferences"
        trigger="click"
        interactive
        :animate-fill="false"
        :on-show="() => isVideoPreferencesShown = true"
        :on-hide="() => {
          isVideoPreferencesShown = false;
          return true;
        }"
        :duration="250"
        :max-width="500"
        theme="tlh__extras"
      >
        <div class="tlh__extras-menu d-flex flex-column p-3">
          <p class="tlh__extras-title m-0">Video Preferences</p>
          <div class="tlh__video-preferences d-flex">
            <button
              v-for="ratio of videoRatios"
              :key="ratio.id"
              type="button"
              class="tlh__video-preferences-button btn d-flex flex-column align-items-center p-0 text-white"
              :title="`Select ${ratio.name}`"
              @click="$emit('change:video-ratio', ratio.id)"
            >
              <img
                :src="ratio.imageSrc"
                class="tlh__video-preferences-image"
                :class="{ 'tlh__video-preferences-image--selected': ratio.id === videoRatio }"
                alt="ratio.name"
                height="200"
              />
              {{ ratio.name }}
            </button>
          </div>
        </div>

      </tippy> -->
    </div>
  </header>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default {
  components: {
    VueSlider,
  },
  props: {
    isTimelineShown: {
      type: Boolean,
      default: true,
    },
    disableBackward: {
      type: Boolean,
      default: false,
    },
    disableMainControl: {
      type: Boolean,
      default: false,
    },
    disableForward: {
      type: Boolean,
      default: false,
    },
    mainControlState: {
      type: String,
      validator(value) {
        return ['generate', 'generating', 'play', 'pause'].includes(value);
      },
      default: 'play',
    },
    currentTime: {
      type: Number, // In seconds
      required: true,
    },
    duration: {
      type: Number, // In seconds
      required: true,
    },
    voiceVolume: {
      type: Number,
      required: true,
    },
    musicVolume: {
      type: Number,
      required: true,
    },
    videoRatios: {
      type: Array,
      required: true,
    },
    videoRatio: {
      type: String,
      required: true,
    },
  },
  emits: [
    'click:timeline-toggle',
    'click:backward',
    'click:main-control',
    'click:forward',
    'change:voice-volume',
    'change:music-volume',
    'change:video-ratio',
  ],
  data() {
    return {
      isEqualizerShown: false,
      isVideoPreferencesShown: false,
    };
  },
  methods: {
    calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
  },
};
</script>

<style scoped>
.tlh {
  min-height: 45px;
  gap: 12px;
  border-block: 1px solid #2D2D2D;
}

.tlh__timeline-toggle {
  top: 50%;
  left: 16px;
  translate: 0 -50%;
  gap: 6px;
  background-color: #2D2D2D;
  font-size: 12px;
  transition: background-color 0.25s;
}

.tlh__timeline-toggle:hover {
  background-color: #3D3D3D;
}

.tlh__timeline-toggle-icon {
  font-size: 10px;
  transition: rotate 0.25s;
}

.tlh__timeline-toggle-icon--bottom {
  rotate: 180deg;
}

.tlh__controls {
  gap: 4px;
}

.tlh__control-button {
  width: 30px;
  height: 30px;
  font-size: 16px;
  transition: background-color 0.25s, color 0.25s;
}

.tlh__control-button--main {
  background-color: #FFFFFF;
}

.tlh__control-button--main:not(:disabled):hover {
  background-color: #DDDDDD;
}

.tlh__control-button--generate {
  background-color: #6D75F6;
  color: #FFFFFF;
}

.tlh__control-button--generate:not(:disabled):hover {
  background-color: #545EED;
}

.tlh__control-button--step:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.tlh__generating-message {
  gap: 8px;
  font-size: 14px;
}

.tlh__timestamp {
  gap: 4px;
  font-size: 12px;
}

.tlh__time--gray {
  color: #4D4D4D;
}

.tlh__extras-toggle {
  width: 30px;
  height: 30px;
  transition: background-color 0.25s;
}

.tlh__extras-toggle:hover,
.tlh__extras-toggle--active {
  background-color: rgba(255, 255, 255, 0.15);
}

.tlh__extras-toggle-icon {
  height: 16px;
}

.tlh__extras-menu {
  gap: 16px;
}

.tlh__extras-title {
  font-size: 13px;
}

.tlh__equalizer {
  gap: 20px;
}

.tlh__equalizer-slider {
  gap: 20px;
}

.tlh__equalizer-slider-icon {
  height: 16px;
}

.tlh__video-preferences {
  gap: 12px;
}

.tlh__video-preferences-button {
  gap: 8px;
  font-size: 10px;
}

.tlh__video-preferences-image {
  border-radius: 16px;
  border: 2px solid transparent;
  overflow: hidden;
  transition: border-color 0.25s;
}

.tlh__video-preferences-button:hover .tlh__video-preferences-image:not(
  .tlh__video-preferences-image--selected
) {
  border-color: #6D75F633;
}

.tlh__video-preferences-image--selected {
  border-color: #6D75F6;
}
</style>

<style>
.tlh__extras-theme {
  padding: 0;
}

.tlh__equalizer-slider .vue-slider-process {
  background-color: #6D75F6;
}

.tlh__equalizer-slider .vue-slider:hover .vue-slider-process {
  background-color: #545EED;
}
</style>
