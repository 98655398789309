<template>
  <section class="ftbv d-flex flex-column text-left" :class="isGenerate ? 'position-relative' : 'px-5 py-3'">
    <header class="d-flex justify-content-between">
      <TextEditorToolbarInput :editor="editor" />
      <div class="ml-auto">
        <QuestionOptions
          v-model="question"
          @delete-question="$emit('delete-question')"
        />
      </div>
    </header>

    <div class="ftbv__question d-flex">
      <div v-if="question.image_url != null || question.voice != null || question.video != null" :style="{ minWidth: '40%', maxWidth: '40%' }">
        <QuestionImageInput v-if="question.image_url != null" v-model="question.image_url" :allocation="allocation" @update:allocation="$emit('update:allocation')"/>
        <QuestionAudioInput v-if="question.voice != null" v-model="question.voice" />
        <QuestionVideoInput v-if="question.video != null" v-model="question.video" />
      </div>
      <TextEditorInput
        v-model="question.question"
        placeholder="Type in your question"
        title="Question"
        @get-editor="(newEditor) => editor = newEditor"
        @focus="(editor) => handleEditorFocus(editor)"
      />
    </div>
    <p class="ftbv__answers-title py-1 m-0">Answer Choices</p>

    <AnswerInputWrapper :totalAnswers="4" :type="type" :key="question.idDum ? question.idDum : question._id">
      <template #default="{ answerNumber }">
        <TextEditorInput
          type="answer"
          :type-layout="type"
          v-model="question[`option_${answerNumber}`]"
          :correct="(answerNumber).toString() === question.correct_answer"
          placeholder="Type in choice here.."
          :title="`Choice #${answerNumber}`"
          @focus="(editor) => handleEditorFocus(editor)"
        />
        <AnswerCorrectInput
          v-model="question.correct_answer"
          :answer-number="answerNumber"
        />
      </template>
    </AnswerInputWrapper>
    <Soca v-if="isGenerate" type="generate"/>
  </section>
</template>

<script>
import QuestionOptions from './QuestionOptions';
import TextEditorInput from './inputs/TextEditorInput';
import TextEditorToolbarInput from './inputs/TextEditorToolbarInput';
import AnswerInputWrapper from './inputs/AnswerInputWrapper';
import AnswerCorrectInput from './inputs/AnswerCorrectInput';
import QuestionImageInput from './inputs/QuestionImageInput.vue';
import QuestionAudioInput from './inputs/QuestionAudioInput.vue';
import QuestionVideoInput from './inputs/QuestionVideoInput';
import Soca from '@/components/loading/Soca'

export default {
  components: {
    QuestionOptions,
    TextEditorInput,
    TextEditorToolbarInput,
    AnswerInputWrapper,
    AnswerCorrectInput,
    QuestionImageInput,
    QuestionAudioInput,
    QuestionVideoInput,
    Soca
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    type: {
      validator(value) {
        return ['default', 'thumbnail'].includes(value);
      },
      default: 'default',
    },
    allocation: {
			type: Object,
			default : () => {
				return null
			}
		},
  },
  emits: ['input', 'delete-question', 'focus-editor'],
  data() {
    return {
      question: this.value,
      editor: null,
      editor_1: null,
      editor_2: null,
      editor_3: null,
      editor_4: null,
    };
  },
  watch: {
    question: {
      handler(question) {
        this.$emit('input', question);
      },
      deep: true,
    },
    value: {
      handler(value) {
        this.question = value;
      },
      deep: true,
    },
  },
  methods: {
    handleEditorFocus(editor) {
      this.$emit('focus-editor', editor);
      this.editor = editor.editor;
    }
  }
};
</script>

<style scoped>
.ftbv {
  gap: 16px;
  max-height: 100%;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.ftbv__question {
  gap: 16px;
  height: 300px;
  overflow: hidden;
  z-index: 1
}

.ftbv__question > * {
  flex-grow: 1;
}

.ftbv__question > *:nth-child(2) {
  max-width: 60%;
}

.ftbv__answers-title {
  color: #8C8C8C;
  border-bottom: 1px solid #2D2D2D;
}
</style>
