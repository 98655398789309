<template>
  <NodeViewWrapper
    as="span"
    class="ssmln"
    :class="{
      'ssmln--default': !editor.storage.fullscreenStorage?.isFullscreen,
      'ssmln--fullscreen': editor.storage.fullscreenStorage?.isFullscreen,
    }"
  >
    <tippy
      v-if="editor.storage.fullscreenStorage?.isFullscreen"
      v-bind="tippyProps"
    >
      <template v-slot:trigger>
        <SSMLNodeContent
          :editor="editor"
          :active="isOptionsShown"
          :button-text="buttonText"
          @click:button="isOptionsShown = !isOptionsShown"
        />
      </template>

      <SSMLOptionsMenu
        v-model="options"
        :plain-text="plainText"
        @generate-preview-ssml="(data) => editor.storage.fullscreenStorage?.generatePreviewSSML(data)"
      />
    </tippy>
    <SSMLNodeContent
      v-else
      :editor="editor"
      :active="isOptionsShown"
      :button-text="buttonText"
      @click:button="isOptionsShown = !isOptionsShown"
    />
  </NodeViewWrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';

import SSMLNodeContent from './SSMLNodeContent';
import SSMLOptionsMenu from '../menus/SSMLOptionsMenu';

export default {
  components: {
    NodeViewWrapper,
    SSMLNodeContent,
    SSMLOptionsMenu,
  },
  props: nodeViewProps,
  data() {
    return {
      options: {
        speed: 'fe-normal',
        pause: null,
        tone: null,
      },
      plainText: '',
      plainTextObserver: null,
      isOptionsShown: false,
      listSpeed: [
        {
          id: 'super_slow',
          shortText: 'Super Slow',
        },
        {
          id: 'slow',
          shortText: 'Slow',
        },
        {
          id: 'fe-normal',
          shortText: 'Normal',
        },
        {
          id: 'fast',
          shortText: 'Fast',
        },
        {
          id: 'super_fast',
          shortText: 'Super Fast',
        },
      ],
      listPause: [
        {
          id: 'br1',
          shortText: '0.5s',
        },
        {
          id: 'br2',
          shortText: '0.75s',
        },
        {
          id: 'br3',
          shortText: '1s',
        },
      ],
      listTone: [
        {
          id: 'calm',
          shortText: 'Calm',
        },
        {
          id: 'disappointed',
          shortText: 'Disappointed',
        },
        {
          id: 'excited',
          shortText: 'Excited',
        },
        {
          id: 'fear',
          shortText: 'Fear',
        },
        {
          id: 'happy',
          shortText: 'Happy',
        },
        {
          id: 'sad',
          shortText: 'Sad',
        },
        {
          id: 'surprised',
          shortText: 'Surprised',
        },
      ],
    };
  },
  computed: {
    speedShortText() {
      return this.listSpeed.find((speed) => speed.id === this.options.speed)?.shortText;
    },
    pauseShortText() {
      let pause = this.listPause.find((pause) => pause.id === this.options.pause)?.shortText
      if(pause) {
        return this.listPause.find((pause) => pause.id === this.options.pause)?.shortText;
      } else {
        return this.shortTextCustom(this.options?.pause)
      }
    },
    toneShortText() {
      return this.listTone.find((tone) => tone.id === this.options.tone)?.shortText;
    },
    buttonText() {
      if (this.options.tone === null && this.options.speed === 'fe-normal') {
        return `[${this.pauseShortText}]`;
      } else {
        return `[${this.toneShortText || this.speedShortText}${this.pauseShortText ? `, ${this.pauseShortText}` : ''}]`;
      }
    },
    tippyProps() {
      return {
        interactive: true,
        placement: 'bottom',
        flip: ['bottom', 'top'],
        maxWidth: 'none',
        animation: 'shift-away',
        duration: 150,
        delay: 0,
        hideOnClick: false,
        animateFill: false,
        theme: 'ssmln__menu',
        trigger: 'manual',
        visible: this.isOptionsShown,
      };
    },
  },
  watch: {
    'node.attrs': {
      handler(newAttrs) {
        if (
          newAttrs.type !== this.options.speed
          || newAttrs.pause !== this.options.pause
          || newAttrs.type !== this.options.tone
        ) {
          this.populateAttrsToOptions();
        }
      },
      deep: true,
    },
    options: {
      handler(newOptions) {
        if (newOptions.speed === 'fe-normal' && !newOptions.pause && !newOptions.tone) {
          this.editor
            .chain()
            .focus()
            .insertContentAt(this.getPos(), this.plainText)
            .run();

          this.deleteNode();
        } else {
          this.updateAttributes({
            type: newOptions.tone || newOptions.speed,
            pause: newOptions.pause,
          });
        }
      },
      deep: true,
    },
    plainText(newPlainText) {
      if (!newPlainText) {
        this.deleteNode();
      }
    },
  },
  methods: {
    populateAttrsToOptions() {
      this.options.speed = this.listSpeed.find((speed) => speed.id === this.node.attrs.type)?.id || 'fe-normal';
      this.options.pause = this.listPause.find((pause) => pause.id === this.node.attrs.pause)?.id || this.node.attrs.pause || null;
      this.options.tone = this.listTone.find((tone) => tone.id === this.node.attrs.type)?.id || null;
    },
    showOptionsWhenFocus({ editor }) {
      const { from: cursorFrom, to: cursorTo } = editor.state.selection;

      if (cursorFrom === cursorTo) {
        const cursorPos = cursorFrom;
        const nodeFrom = this.getPos();
        const nodeTo = nodeFrom + this.node.nodeSize;

        if ((nodeFrom <= cursorPos) && (cursorPos <= nodeTo)) {
          this.isOptionsShown = true;
        } else {
          this.isOptionsShown = false;
        }
      } else {
        this.isOptionsShown = false;
      }
    },
    hideOptionsWhenClickOutside(e) {
      const bubbleMenuContainer = document.querySelector('.ssmln__menu-theme');

      if (
        !this.$el.contains(e.target)
        && !bubbleMenuContainer?.contains(e.target)
        && this.isOptionsShown
      ) {
        this.isOptionsShown = false;
      }
    },
    shortTextCustom(item) {
      if (item) {
        const numberMatch = item.match(/\d+/);
        return numberMatch ? numberMatch[0] + "s" : null;
      }
      return null;
    }
  },
  mounted() {
    this.populateAttrsToOptions();

    this.editor.on('selectionUpdate', this.showOptionsWhenFocus);
    document.addEventListener('click', this.hideOptionsWhenClickOutside);
  },
  updated() {
    if (this.plainTextObserver === null) {
      const plainTextElement = this.editor.view.nodeDOM(this.getPos())?.querySelector('[data-node-view-content]');

      if (plainTextElement) {
        this.plainText = plainTextElement.textContent;

        this.plainTextObserver = new MutationObserver((mutationRecords) => {
          for (const mutationRecord of mutationRecords) {
            if (['characterData', 'childList'].includes(mutationRecord.type)) {
              this.plainText = mutationRecord.target.textContent;
            }
          }
        });

        this.plainTextObserver.observe(
          plainTextElement,
          { characterData: true, childList: true, subtree: true }
        );
      }
    }
  },
  beforeDestroy() {
    this.plainTextObserver?.disconnect();
    this.editor.off('selectionUpdate', this.showOptionsWhenFocus);
    document.removeEventListener('click', this.hideOptionsWhenClickOutside);
  },
};
</script>

<style>
.ssmln__menu-theme {
  padding: 0 20px;
  background: none;
}

.ssmln [data-tippy-component] {
  font-size: 0;
  display: inline;
}

.ssmln [data-tippy-component-trigger] {
  display: inline;
}

.ssmln--default [data-tippy-component-trigger] {
  font-size: 14px;
}

.ssmln--fullscreen [data-tippy-component-trigger] {
  font-size: 1.15rem;
}
</style>
