<template>
  <header class="gv-header align-items-center px-4 py-3">
    <section class="gv-header__left d-flex align-items-center">
      <button
        type="button"
        class="gv-header__back-button btn p-0 rounded-0"
        title="Back"
        @click="$emit('click:back')"
      >
        <img
          src="@/assets/images/icons/ic_back_nav.svg"
          alt="Back"
          height="18"
        />
        <span class="sr-only">{{ $t('back') }}</span>
      </button>
      <h1 class="gv-header__title">Voice Studio</h1>
    </section>

    <section class="gv-header__center d-flex justify-content-center">
      <div class="gv-header__file-name position-relative">
        <input
          type="text"
          id="input-file-name"
          class="gv-header__file-name-input text-white text-center"
          :value="fileName"
          spellcheck="false"
          title="File Name"
          autocomplete="false"
          @input="$emit('input:file-name', $event.target.value)"
        />
        <div
          class="gv-header__file-name-label position-absolute d-flex align-items-center text-nowrap"
          :class="{ 'sr-only': fileName }"
        >
          <label for="input-file-name" class="gv-header__file-name-label-text m-0">{{ $t('file-name') }}</label>
          <img
            src="@/assets/images/icons/ic_file_name.svg"
            class="gv-header__file-name-label-icon"
            alt=""
          />
        </div>
      </div>
    </section>

    <section class="gv-header__right d-flex justify-content-end align-items-center">
      <div class="gv-header__subscription d-flex align-items-center dropdown">
        <img
          :src="require('@/assets/images/icons/ic_subscription_info.svg')"
          class="gv-header__subscription-icon"
          alt=""
        />
        <div class="gv-header__tooltip dropdown-menu">
					<span>{{ $t('audio-duration-left') }} <b class="cursor-pointer gv__tooltip--topup" @click="$emit('click:topup-tooltip')">{{ $t('top-up-more') }}</b></span>
          <div class="gv__tooltip--package d-flex align-items-center mt-2">
							<div class="gv__tooltip--package-wrapper p-2">
								<div class="gv__tooltip--package-wrapper-inner d-flex align-items-start">
									<div class="gv__tooltip--package-quota d-flex flex-column">
										<p class="m-0 tooltip--package__text">Basic</p>
										<p class="m-0 tooltip--package__quota">{{ allocation?.audio_generates_quota || '0' }}<span class="tooltip--package__text"> {{ $t('mins') }}</span></p>
									</div>
								</div>
							</div>
							<div class="gv__tooltip--package-wrapper p-2">
								<div class="gv__tooltip--package-wrapper-inner d-flex align-items-start">
									<div class="gv__tooltip--package-quota d-flex flex-column">
										<p class="m-0 tooltip--package__text">Multilingual</p>
										<p class="m-0 tooltip--package__quota">{{ allocation?.audio_multilingual_generates_quota || '0' }}<span class="tooltip--package__text"> {{ $t('mins') }}</span><span class="tooltip--package__exp ml-3" v-if="allocation?.expired_date && allocation?.audio_multilingual_generates_quota">exp. {{ getMonthDate(allocation?.expired_date) }}</span></p>
									</div>
								</div>
							</div>
						</div>
        </div>
        <p class="gv-header__subscription-text m-0">{{ mode == 'voice-only' ? (allocation?.audio_generates_quota + allocation?.audio_multilingual_generates_quota).toFixed(2) : allocation?.video_generates_quota }}{{ mode == 'voice-only' ? $t('mins') : 's' }}</p>
      </div>

      <div class="gv-header__buttons d-flex">
        <button
          id="v-step-3"
          type="button"
          class="gv-header__button gv-header__button--save btn d-inline-flex align-items-center"
          title="Generate"
          :disabled="disableGenerate || mainControlState != 'generate'"
          @click="$emit('click:generate')"
        >
          Generate
        </button>
        <div class="dropdown gvhb__dropdown">
          <button
            type="button"
            class="gv-header__button gv-header__button--save btn d-inline-flex align-items-center"
            title="Save"
            data-toggle="dropdown"
            aria-expanded="false"
            :disabled="disableSave || loading"

          >
            {{ $t('save') }}
            <font-awesome-icon
              icon="fa-solid fa-chevron-down"
            />
          </button>
          <div class="dropdown-menu dropdown-menu-right mt-1">
            <button class="btn dm--button" type="button" @click="$emit('click:save')">{{ $t('save-to-projects') }}</button>
            <button v-if="mode == 'voice-only'" :disabled="disableExport" class="btn dm--button" type="button" @click="$emit('click:export')">{{ $t('export-file') }}</button>
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      default: '',
    },
    disableExport: {
      type: Boolean,
      default: false,
    },
    disableSave: {
      type: Boolean,
      default: false,
    },
    disableGenerate: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    allocation: {
      type: Object,
      default : () => {
        return null
      }
    },
    mode: {
      validator(value) {
        return ['voice-only', 'ai-characters'].includes(value);
      }
    },
    mainControlState: {
      type: String,
      validator(value) {
        return ['generate', 'generating', 'play', 'pause'].includes(value);
      },
      default: 'generate',
    },
  },
  emits: ['input:file-name', 'click:back', 'click:export', 'click:save'],
  methods: {
    getMonthDate(dateTime) {
			const months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];

			const dateObject = new Date(dateTime);
			const tanggal = dateObject.getUTCDate();
			const monthIndex = dateObject.getUTCMonth();
			const month = months[monthIndex];

			return `${month} ${tanggal}`;
		},
  }
};
</script>

<style scoped>
.gv-header {
  min-height: 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  border-bottom: 1px solid #2D2D2D;
}

.gv-header__left {
  gap: 16px;
}

.gv-header__back-button-icon {
  height: 18px;
}

.gv-header__title {
  font-size: 18px;
  font-weight: 500;
}

.gv-header__file-name {
  width: 100%;
}

.gv-header__file-name-input {
  width: 100%;
  background: none;
  border: none;
  font-size: 18px;
}

.gv-header__file-name-label {
  z-index: -1;
  gap: 8px;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  pointer-events: none;
}

.gv-header__file-name-label-text {
  color: #585859;
  font-size: 18px;
  font-weight: 500;
}

.gv-header__file-name-label-icon {
  height: 16px;
}

.gv-header__right {
  gap: 16px;
}

.gv-header__subscription {
  gap: 6px;
}

.gv-header__subscription:hover .gv-header__tooltip {
  display: block;
}

.gv-header__tooltip {
  top: 14px;
  left: -230px !important;
  width: auto !important;
  background-color: #1F1F1F;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 15px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.gv-header__subscription-icon {
  height: 14px;
}

.gv-header__subscription-text {
  font-size: 14px;
  font-weight: 500;
}

.gv-header__buttons {
  gap: 8px;
}

.gv-header__button {
  gap: 6px;
  padding-block: 6px;
  border: 2px solid #6D75F6;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.25s, border-color 0.25s, color 0.25s;
}

.gv-header__button--export {
  padding-inline: 12px;
  background-color: transparent;
  color: #6D75F6;
}

.gv-header__button--export:not(:disabled):hover {
  background-color: #6D75F633;
}

.gv-header__button--export:disabled {
  border-color: #1F1F1F;
  color: #585859;
}

.gv-header__button--save {
  padding-inline: 16px;
  background-color: #6D75F6;
  color: #FFFFFF;
}

.gv-header__button--save:not(:disabled):hover {
  background-color: #545EED;
  border-color: #545EED;
}

.gv-header__button--save:disabled {
  background-color: #1F1F1F;
  border-color: #1F1F1F;
  color: #585859;
}

.gv-header__button-icon {
  height: 14px;
}

.gvhb__dropdown .dropdown-menu{
  border-radius: 4px;
  background: #1F1F1F;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.50);
}

.dm--button {
  color: #fff;
  width: 100%;
  text-align: left;
  border-radius: 0px;
}

.dm--button:hover , .dm--button:active {
  background-color: #2D2D2D;
  color: #fff;
}

.gv__tooltip--topup {
	color: #6D75F6;
}

.gv__tooltip--package {
	gap: 6px;
}

.gv__tooltip--package-wrapper {
	border-radius: 4px;
	background: #2D2D2D;
}

.gv__tooltip--package-wrapper-inner {
	gap: 4px;
}

.tooltip--package__text {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.tooltip--package__quota {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.gv__tooltip--package-quota {
	gap: 4px;
}

.tooltip--package__exp {
	color: #8C8C8C;
	font-family: Avenir Next;
	font-size: 8px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}
</style>
