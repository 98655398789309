<template>
  <section class="gv-voice position-relative d-flex flex-column text-left" :class="{ 'gv-voice__nexpanded' : !isExpanded }">
    <h2
      class="gv-voice__title mt-4"
      :class="{
        'px-4': !isExpanded,
        'gv-voice__title--nexpanded pl-3 pr-4': isExpanded,
      }"
    >
      Voice
    </h2>
    <button
      type="button"
      class="gv-voice__expand-toggle btn position-absolute d-flex justify-content-center align-items-center text-white"
      :class="{ 'gv-voice__expand-toggle--expanded p-0 rounded-circle': isExpanded, 'gv-voice__expand-toggle--nexpanded' : !isExpanded }"
      :title="isExpanded ? 'Collapse' : 'Expand'"
      @click="isExpanded = !isExpanded"
    >
      <font-awesome-icon
        icon="fa-solid fa-chevron-left"
        class="gv-voice__expand-toggle-icon"
        :class="{ 'gv-voice__expand-toggle-icon--expanded': isExpanded }"
      />
      <span class="gv-voice__expand-text" :class="{ 'gv-voice__expand-text--expanded' : !isExpanded }">
        {{  !isExpanded ? 'Voice' : '' }}
      </span>
    </button>
    <div
      class="gv-voice__content d-flex flex-column"
      :class="{ 'gv-voice__content--expanded': isExpanded }"
    >
      <div
        ref="contentInner"
        class="gv-voice__content-inner d-flex flex-column pl-3"
        @scroll="handleAiGeneratorWrapperVisibility"
      >
        <ul class="gv-voice__list d-flex flex-column m-0 list-unstyled" ref="listScriptVoice">
          <li v-for="(voice, index) in internalVoices" :key="voice.id" :data-id="voice.id">
            <ScriptEditor
              id="v-step-1"
              ref="scriptEditors"
              :text="voice.text"
              :character-name="voice.voiceType?.character.name"
              :character-avatar-src="voice.voiceType?.character.avatarSrc"
              :action-state="voice.actionState"
              :disable-action="voice.disableAction || disabledAction"
              :disable-delete="voice.disableDelete"
              :disable-ssml="voice.voiceType?.character.isUseModel"
              :is-active="activeScriptBox == voice.id"
              @focus:box-script="handleFocusBoxScript(voice.id)"
              @blur:box-script="handleBlurBoxScript"
              @change:text="(newText) => handleChangeVoiceText(index, newText)"
              @click:voice-type="$bvModal.show(`select-voice-type--script-editor-${index}`)"
              @click:action="(state) => handleClickVoiceAction(index, state)"
              @delete="internalVoices.splice(index, 1), $emit('delete-se-internal', voice.id)"
              @get:editor="(editor) => scriptEditors.splice(index, 1, editor)"
              @get:expanded-editor="(editor) => expandedScriptEditors.splice(index, 1, editor)"
              @show:expanded-script-editor="activeExpandedScriptEditorIndex = index; changeSSMLActiveSpeaker({ index })"
              @hide:expanded-script-editor="activeExpandedScriptEditorIndex = null"
              @generate:preview-ssml="handleGeneratePreviewSSML"
            />
            <SelectVoiceTypeModal
              :id="`select-voice-type--script-editor-${index}`"
              :selected-language="voice.voiceType?.language"
              :selected-character="voice.voiceType?.character"
              :allocation="allocation"
              :click-upgrade="clickUpgrade"
              :initial-languages="languages"
              @click:handle-upgrade-gv="$emit('click:handle-upgrade-gv')"
              @change:voice-type="(newVoiceType) => {
                handleChangeVoiceType(index, newVoiceType);
                $bvModal.hide(`select-voice-type--script-editor-${index}`);
              }"
            />
            <VoiceServiceUnavailableModal :id="`voice-service-unavailable-${voice.id}`" :type="voice.voiceType?.language.name == 'Multilingual' ? 'Multilingual' : 'Monolingual'"/>
          </li>
        </ul>
        <audio
          ref="audioPlayer"
          @timeupdate="handleTimeupdateAudioPlayer"
          @pause="handlePauseAudioPlayer"
          @ended="$emit('ended:audio-player')"
        />
        <div class="gv-voice__actions d-flex justify-content-center align-items-center">
          <button
            type="button"
            class="gv-voice__action d-inline-flex justify-content-center align-items-center px-4 py-2 rounded-pill text-white"
            title="Add New"
            @click="handleAddNewScript"
          >
            <font-awesome-icon icon="fa-solid fa-plus" />
            {{ $t('add') }}
          </button>
          <button
            type="button"
            class="gv-voice__action d-inline-flex justify-content-center align-items-center px-4 py-2 rounded-pill text-white"
            title="Import Script"
            @click="$bvModal.show('import-audio-scripts')"
          >
            <img src="@/assets/images/icons/ic_import_script.svg" height="12">
            {{ $t('import-script') }}
          </button>
        </div>
      </div>
    </div>

    <div
      class="gv-voice__ai-generator-container position-absolute d-flex justify-content-end"
      :class="{ 'gv-voice__ai-generator-container--show': isAiGeneratorWrapperShown }"
      id="v-step-00"
    >
      <tippy
        ref="aiGeneratorWrapper"
        to="ai-generator-toggle"
        trigger="click"
        boundary="window"
        placement="left-end"
        theme="gv-voice__ai-generator-wrapper"
        :animate-fill="false"
        :duration="250"
        :z-index="isTakeatour && takeatourStep == 0 ? 2001 : 101"
        :max-width="500"
        visible
        :on-show="() => isAiGeneratorShown = true"
        :on-hide="() => {
          if (!isSelectVoiceTypeModalShown) {
            isAiGeneratorShown = false;
            return true;
          } else {
            return false;
          }
        }"
        interactive
      >
        <AIGenerator
          id="v-step-0"
          ref="aiGenerator"
          withHeader
          v-model="aiGeneratorData"
          class="gv-voice__ai-generator"
          :placeholders="aiGeneratorPlaceholders"
          :disable-options="{
            settings: true,
          }"
          :disable-generate="!aiGeneratorData.text"
          :is-generating="isAiGenerating"
          @click:voice-type="isSelectVoiceTypeModalShown = true; $bvModal.show('select-voice-type--ai-generator')"
          @generate="handleGenerateAiGenerator"
        />
      </tippy>
      <button
        ref="buttonOpenPrompt"
        type="button"
        name="ai-generator-toggle"
        class="gv-voice__ai-generator-toggle d-flex justify-content-center align-items-center btn rounded-circle"
        :class="{ 'gv-voice__ai-generator-toggle--show': isAiGeneratorShown, 'gv-voice__ai-generator-toggle--takeatour' : isTakeatour && takeatourStep == 0 }"
        :title="(isAiGeneratorShown ? 'Hide' : 'Show') + ' AI Generator (Ctrl/Command + B)'"
        :disabled="disablePrompt == 'nok'"
      >
        <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles" />
      </button>
    </div>

    <div class="gv-voice__modals position-absolute">
      <SelectVoiceTypeModal
        id="select-voice-type--ai-generator"
        :selected-language="aiGeneratorData.voiceType?.language"
        :selected-character="aiGeneratorData.voiceType?.character"
        :allocation="allocation"
        :click-upgrade="clickUpgrade"
        @click:handle-upgrade-gv="$emit('click:handle-upgrade-gv')"
        @hidden="isSelectVoiceTypeModalShown = false"
        @get:languages="(newLanguages) => (languages.length < 1) && (languages = newLanguages)"
        @change:voice-type="(newVoiceType) => {
          aiGeneratorData.voiceType = newVoiceType;
          $bvModal.hide('select-voice-type--ai-generator')
        }"
      />
      <ImportAudioScriptsModal
        id="import-audio-scripts"
        :allocation="allocation"
        :click-upgrade="clickUpgrade"
        @get:languages="(newLanguages) => (languages.length < 1) && (languages = newLanguages)"
        @import="handleImportScripts"
      />
      <QuotaHabisModal :click-upgrade="clickUpgrade" @click:handle-upgrade-gv="$emit('click:handle-upgrade-gv')"/>
      <!-- <ServerErrorModal /> -->
      <SentimentAnalysisWarningModal @hide="handleHideSentimentAnalysisWarning" />
    </div>
  </section>
</template>

<script>
import randomUUID from '@/helper/uuid';
import { base_url_machine_learning } from '@/config/base_url';
import { getGeneratedTextToAudio, getGeneratedTextToAudioSSML, getGeneratedTextToAudioWithAgreements } from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
import { useVoiceModel, voiceCloneSample } from '@/services/ttv/ttv.service.js'

import ScriptEditor from '@/components/generate-videos/script-editor';
import AIGenerator from '@/components/generate-videos/ai-generator';
import SelectVoiceTypeModal from '@/components/modal/SelectVoiceType';
import ImportAudioScriptsModal from '../../modal/ImportAudioScripts';
import QuotaHabisModal from '@/components/modal/QuotaHabis';
import VoiceServiceUnavailableModal from '@/components/modal/VoiceServiceUnavailable';
// import ServerErrorModal from '@/components/modal/ServerError';
import SentimentAnalysisWarningModal from '@/components/modal/SentimentAnalysisWarning';

export default {
  components: {
    ScriptEditor,
    AIGenerator,
    SelectVoiceTypeModal,
    ImportAudioScriptsModal,
    QuotaHabisModal,
    // ServerErrorModal,
    SentimentAnalysisWarningModal,
    VoiceServiceUnavailableModal
  },
  props: {
    voices: {
      type: Array,
      required: true,
    },
    deletedSeiId: {
      type: String,
      default: null
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
    isTakeatour: {
      type: Boolean,
      default: false,
    },
    takeatourStep: {
      type: Number,
      default: 0
    },
    mode: {
      validator(value) {
        return ['voice-only', 'ai-characters'].includes(value);
      },
      required: true,
    },
    disablePrompt: {
      validator(value) {
        return ['ok', 'nok'].includes(value);
      },
    },
    clickUpgrade: {
      type: Function,
      default: null,
    },
    activeScript: {
      type: String,
      default: null
    },
    allocation: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: [
    'change:voices',
    'after:generate',
    'play:audio-player',
    'pause:audio-player',
    'ended:audio-player',
    'timeupdate:audio-player',
  ],
  data() {
    return {
      isExpanded: true,
      contentInnerResizeObserver: null,

      internalVoices: this.voices,
      aiGeneratorPlaceholders: [
        "Introducing our new product - the XYZ Widget! It's sleek, powerful, and designed to make your life easier.",
        "Learn how to make delicious chocolate chip cookies from scratch with this step-by-step recipe.",
        "Explore the breathtaking beauty of the majestic mountains in our travel destination.",
      ],
      aiGeneratorData: {
        text: '',
        scriptType: {
          id: 'lessons',
          name: this.$t('lesson'),
        },
        voiceType: null,
      },
      isAiGenerating: false,
      isAiGeneratorShown: false,
      isAiGeneratorWrapperShown: true,
      isSelectVoiceTypeModalShown: false,

      languages: [],

      scriptEditors: [],
      expandedScriptEditors: [],
      activeExpandedScriptEditorIndex: null,

      latestPlayingAudioId: null,
      pauseLatestPlayingAudio: null,
      activeScriptBox: this.activeScript
    };
  },
  watch: {
    voices: {
      handler(newVoices) {
        this.internalVoices = newVoices;
      },
      deep: true,
    },
    internalVoices: {
      handler(newInternalVoices) {
        if (newInternalVoices.length < 1) {
          console.log(this.aiGeneratorData.voiceType)
          this.internalVoices.push({
            id: randomUUID(),
            courseId: '',
            ttsId: '',
            text: '',
            scriptType: 'lessons',
            voiceType: this.aiGeneratorData.voiceType,
            src: null,
            duration: null,
            actionState: 'generate',
            disableAction: true,
            disableDelete: true,
            generateText: null,
            generateSpeaker: null,
            start: 0,
            end: null
          });
        }

        if (newInternalVoices.length === 1) {
          this.internalVoices[0].disableDelete = this.internalVoices[0].text.trim() === '';
        } else {
          this.internalVoices[0].disableDelete = false;
        }

        this.$emit('change:voices', newInternalVoices);
      },
      deep: true,
      immediate: true,
    },
    isExpanded(newIsExpanded) {
      if (newIsExpanded === false) {
        this.isAiGeneratorWrapperShown = true;
      } else {
        this.handleAiGeneratorWrapperVisibility();
      }
    },
    isAiGeneratorWrapperShown(newIsAiGeneratorWrapperShown) {
      if (newIsAiGeneratorWrapperShown === false) {
        this.$refs.aiGeneratorWrapper.tip.hide();
      }
    },
    activeScript:{
      handler(newVal) {
        this.activeScriptBox = newVal
        this.lookUpScriptBox(newVal)
      },
      deep: true,
    },
    activeScriptBox:{
      handler(newVal) {
        this.$emit('update:active-script', newVal)
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    showAiGeneratorTippy() {
      this.$refs.aiGeneratorWrapper.tip.show();
    },
    async handleGenerateAiGenerator() {
      this.isAiGenerating = true;

      const formData = {
        course_id: '',
        text: this.aiGeneratorData.text,
        context: this.aiGeneratorData.scriptType.id,
        params: {
          speaker: this.aiGeneratorData.voiceType.character.name.toLowerCase(),
          pitch: 0,
          speed: 1,
          emotion: 'normal',
          regenerate: 'true',
          lang: this.aiGeneratorData.voiceType.language.id,
        },
      };

      try {
        const res = await getGeneratedTextToAudio(formData);

        if (res.status === 403) {
          if(this.aiGeneratorData.voiceType.language.name == 'Multilingual') {
						this.$bvModal.show('multilingual-quota')
          } else {
            this.$bvModal.show('audio-quota');
          }
        } else if (res.status === 402 || res.status === 500) {
          this.$bvModal.show('server-error-modal');
        } else if (res.status === 406) {
          sessionStorage.setItem('sentimentFormData', JSON.stringify(formData));
          sessionStorage.setItem('sentimentSource', JSON.stringify({
            inputType: 'ai-generator',
            data: this.aiGeneratorData,
          }));
          sessionStorage.setItem('isAgreedSentiment', false);
          this.$bvModal.show('sentiment-analysis-warning');
        } else {
          this.$refs.aiGeneratorWrapper.tip.hide();
          this.$emit('update:allocation')
          await this.populateGeneratedAiData({
            reader: res.body.getReader(),
            scriptType: this.aiGeneratorData.scriptType.id,
            voiceType: this.aiGeneratorData.voiceType,
          });
          this.aiGeneratorData.text = '';
        }
      } catch (error) {
        console.log(error);
        this.$bvModal.show('server-error-modal');
      }

      this.isAiGenerating = false;
    },
    async populateGeneratedAiData({ data, reader, scriptType, voiceType }) {
      let index = 0;

      if (this.internalVoices.length > 1 ||this.internalVoices[0]?.text.trim() !== '') {
        this.internalVoices.push({
          id: randomUUID(),
          courseId: '',
          ttsId: '',
          text: '',
          scriptType: scriptType ?? 'lessons',
          voiceType: voiceType ?? null,
          src: null,
          duration: null,
          actionState: 'loading',
          disableAction: true,
          disableDelete: true,
          generateText: null,
          generateSpeaker: null,
          start: 0,
          end: null
        });
        index = this.internalVoices.length - 1;
      }

      this.internalVoices[index].courseId = data?.course_id ?? '';
      this.internalVoices[index].ttsId = data?.tts_id ?? '';
      this.internalVoices[index].text = data?.text ?? '';
      this.internalVoices[index].scriptType = scriptType ?? 'lessons';
      this.internalVoices[index].voiceType = voiceType ?? null;
      this.internalVoices[index].src = data?.path ? base_url_machine_learning + data.path : null;
      this.internalVoices[index].duration = data?.['length'] ?? null;
      this.internalVoices[index].actionState = 'loading';
      this.internalVoices[index].disableAction = true;
      this.internalVoices[index].disableDelete = true;
      this.internalVoices[index].generateText = data?.text ?? null;
      this.internalVoices[index].generateSpeaker = voiceType?.character?.name ?? null;

      this.isExpanded = true;
      this.$nextTick(() => {
        this.$refs.contentInner.scrollTop = this.$refs.contentInner.scrollHeight - this.$refs.contentInner.offsetHeight;
      });

      if (reader) {
        let isReading = true;

        while (isReading) {
          const { value, done } = await reader.read();

          if (done) {
            isReading = false;
            break;
          }

          if (value) {
            // this.$refs.scriptEditors[index].$refs.textarea.focus();
            this.internalVoices[index].text += new TextDecoder('utf-8').decode(value).replaceAll('\n', '');
            this.scriptEditors[index].commands.focus('end');
            // this.$refs.scriptEditors[index].$refs.textarea.selectionStart = this.internalVoices[index].text.length;
            // this.$refs.scriptEditors[index].$refs.textarea.scrollTop = this.$refs.scriptEditors[index].$refs.textarea.scrollHeight - this.$refs.scriptEditors[index].$refs.textarea.offsetHeight;
          }
        }
      }

      if (this.internalVoices[index].text.trim() !== '') {
        this.internalVoices[index].disableAction = false;
        this.internalVoices[index].disableDelete = false;
      }
      this.internalVoices[index].actionState = 'generate';

      return Promise.resolve();
    },
    handleChangeVoiceText(index, newText) {
      this.internalVoices[index].text = newText;
      this.internalVoices[index].disableAction = newText.trim() === '';
      this.handleVoiceActionState(index);
    },
    handleChangeVoiceType(index, newVoiceType) {
      if(!this.internalVoices[index]?.voiceType?.character.isUseModel) {
        if(newVoiceType.character?.isUseModel) {
          this.internalVoices[index].text = this.removeTagsSSML(this.internalVoices[index].text)
        }
      }
      this.internalVoices[index].voiceType = newVoiceType;
      this.changeSSMLActiveSpeaker({ name: newVoiceType.character.name.toLowerCase() });
      this.handleVoiceActionState(index);
    },
    handleVoiceActionState(index) {
      if (this.internalVoices[index].actionState !== 'loading') {
        if (
          this.internalVoices[index].src
          && this.internalVoices[index].text === this.internalVoices[index].generateText
          && this.internalVoices[index].voiceType.character.name === this.internalVoices[index].generateSpeaker
        ) {
          this.internalVoices[index].actionState = 'play';
        } else {
          this.internalVoices[index].actionState = 'generate';
        }
      }
    },
    async handleClickVoiceAction(index, state, isExpanded) {
      if (state === 'generate') {
        await this.checkUseModel(index, isExpanded);
        // this.$emit('after:generate');
      } else if (state === 'play') {
        this.internalVoices[index].actionState = 'loading';

        if (this.$refs.audioPlayer.src !== this.internalVoices[index].src) {
          this.$refs.audioPlayer.src = this.internalVoices[index].src;
        }

        await this.$refs.audioPlayer.play();
        this.$emit('play:audio-player', this.internalVoices[index].id);
        this.$refs.audioPlayer.dataset.index = index;
        this.$refs.audioPlayer.dataset.id = this.internalVoices[index].id;
        this.updateStateOtherAudio(index)

        const playMediaEvent = new CustomEvent('play:media', {
          detail: {
            componentId: this.internalVoices[index].id,
            name: 'gv::script-editor'
          },
        });
        document.dispatchEvent(playMediaEvent);

        this.$refs.audioPlayer.addEventListener('ended', () => {
          if (this.$refs.audioPlayer.src === this.internalVoices[index].src) {
            if(this.internalVoices[index].actionState != 'generate') {
              this.internalVoices[index].actionState = 'play';
            }
          }
        }, { once: true });

        const pauseWhenAnotherMediaPlay = async (e) => {
          if (e.detail.componentId !== this.internalVoices[index].id) {
            const prevActionState = this.internalVoices[index].actionState;
            this.internalVoices[index].actionState = 'loading';
            if (e.detail.name !== 'gv::script-editor') {
              await this.$refs.audioPlayer.pause();
            }
            if (this.internalVoices[index].actionState === 'pause') {
              this.internalVoices[index].actionState = 'play';
            } else {
              this.internalVoices[index].actionState = prevActionState;
            }
            document.removeEventListener('play:media', pauseWhenAnotherMediaPlay);
          }
        }
        document.addEventListener('play:media', pauseWhenAnotherMediaPlay);

        this.internalVoices[index].actionState = 'pause';
      } else if (state === 'pause') {
        this.internalVoices[index].actionState = 'loading';
        await this.$refs.audioPlayer.pause();
        if(this.internalVoices[index].actionState != 'generate') {
          this.internalVoices[index].actionState = 'play';
        }
      }
    },
    updateStateOtherAudio(index) {
      for(let i = 0; i <= this.internalVoices.length - 1; i++){
        if(i != index) {
          this.handleVoiceActionState(i)
        }
      }
    },
    async handleGenerateVoice(index, isExpanded) {
      const voicesOnTimelineInStorage = await JSON.parse(sessionStorage.getItem('gv::voices'));
      const voicesOnTimelineInStorageCourseId = voicesOnTimelineInStorage && voicesOnTimelineInStorage.length > 0 ? voicesOnTimelineInStorage[0].courseId : null
      const prevActionState = this.internalVoices[index].actionState;

      this.internalVoices[index].actionState = 'loading';

      let generatedText = this.internalVoices[index].text

      const formData = {
        course_id: voicesOnTimelineInStorageCourseId || this.internalVoices[0]?.courseId || '',
        text: this.internalVoices[index].text,
        context: this.internalVoices[index].scriptType,
        params: {
          speaker: this.internalVoices[index].voiceType.character.name.toLowerCase(),
          pitch: 0,
          speed: 1,
          emotion: 'normal',
          regenerate: 'True',
          lang: this.internalVoices[index].voiceType.language.id,
          with_char : this.mode == "ai-characters" ? "False" : "False"
        },
      };

      try {
        const res = await getGeneratedTextToAudioSSML(formData);

        if (res.status === 403) {
          if(this.internalVoices[index].voiceType.language.name == 'Multilingual') {
						this.$bvModal.show('multilingual-quota')
          } else {
            this.$bvModal.show('audio-quota');
          }
        } else if (res.status === 402) {
          this.$bvModal.show(`voice-service-unavailable-${this.internalVoices[index].id}`);
        } else if (res.status === 500) {
          this.$bvModal.show('server-error-modal');
        } else if (res.status === 406) {
          sessionStorage.setItem('sentimentFormData', JSON.stringify(formData));
          sessionStorage.setItem('sentimentSource', JSON.stringify({
            inputType: isExpanded ? 'expanded-script-editor' : 'script-editor',
            data: this.internalVoices[index],
            index,
          }));
          sessionStorage.setItem('isAgreedSentiment', false);
          this.$bvModal.show('sentiment-analysis-warning');
        } else {
          const { data } = res;
          let fixedData = {
            course_id : data.course_id,
            length : data.length,
            path : data.path,
            sentiment : data.sentiment || "",
            text : generatedText || data.text,
            tts_id : data.tts_id,
          }

          this.$emit('update:allocation')
          await this.populateGeneratedVoice({ index, data : fixedData, speaker: this.internalVoices[index].voiceType.character.name });
          this.internalVoices[index].actionState = 'play';
        }
      } catch (error) {
        console.log(error);
        this.$bvModal.show('server-error-modal');
      }

      if (this.internalVoices[index].actionState === 'loading') {
        this.internalVoices[index].actionState = prevActionState;
      }

      return Promise.resolve();
    },
    async populateGeneratedVoice({ index, data, speaker = 'Sovia' }) {
      this.internalVoices[index].courseId = data.course_id;
      this.internalVoices[index].ttsId = data.tts_id;
      this.internalVoices[index].src = base_url_machine_learning + data.path;
      this.internalVoices[index].duration = data['length'];
      this.internalVoices[index].generateText = data.text;
      this.internalVoices[index].generateSpeaker = speaker;
      this.populateStartEnd(this.internalVoices[index].id)
      this.$emit('push-to-timeline', this.internalVoices[index])

      return Promise.resolve();
    },
    changeSSMLActiveSpeaker({ index = null, name = null }) {
      let speaker = name;

      if (index !== null) {
        speaker = this.internalVoices[index].voiceType.character.name.toLowerCase();
      }

      if (speaker !== null && sessionStorage.getItem('SSMLActiveSpeaker') !== speaker) {
        document.dispatchEvent(new Event('change-ssml-active-speaker'));
        sessionStorage.setItem('SSMLActiveSpeaker', speaker);
      }
    },
    async handleGeneratePreviewSSML({
        plainText, SSMLText, setAudioSrc, options
    }) {
      const formData = {
        course_id: this.internalVoices[0]?.courseId || '',
        text: SSMLText,
        context: this.aiGeneratorData.scriptType.id,
        params: {
          speaker: sessionStorage.getItem('SSMLActiveSpeaker'),
          pitch: 0,
          speed: 1,
          emotion: 'normal',
          regenerate: 'True',
          lang: this.internalVoices[this.activeExpandedScriptEditorIndex].voiceType.language.id,
          with_char : this.mode == "ai-characters" ? "False" : "False"
        },
      };

      try {
        const res = await getGeneratedTextToAudioSSML(formData);

        let path = null;

        if (res.status === 403) {
          if(this.internalVoices[this.activeExpandedScriptEditorIndex].voiceType.language.name == 'Multilingual') {
            this.$bvModal.show('multilingual-quota')
          } else {
            this.$bvModal.show('audio-quota');
          }
        } else if (res.status === 402) {
          this.$bvModal.show(`voice-service-unavailable-${this.internalVoices[this.activeExpandedScriptEditorIndex].id}`);
        } else if (res.status === 500) {
          this.$bvModal.show('server-error-modal');
        } else if (res.status === 406) {
          const { path } = res.data;
          sessionStorage.setItem('sentimentFormData', JSON.stringify({
            ...options,
            plainText,
            SSMLText,
            audioSrc: base_url_machine_learning + path,
          }));
          sessionStorage.setItem('sentimentSource', JSON.stringify({
            inputType: `ssml-preview`,
            index: this.activeExpandedScriptEditorIndex,
          }));
          sessionStorage.setItem('isAgreedSentiment', false);
          this.$bvModal.show('sentiment-analysis-warning');
        } else if (res.status === 200) {
          ({ path } = res.data);
        }

        setAudioSrc(path ? base_url_machine_learning + path : false, this.internalVoices[this.activeExpandedScriptEditorIndex].voiceType.character.name.toLowerCase());
      } catch (error) {
        console.log(error);
        this.$bvModal.show('server-error-modal')
        setAudioSrc(false);
      }
    },
    async handleHideSentimentAnalysisWarning() {
      const source = await JSON.parse(sessionStorage.getItem('sentimentSource'));

      if (source.inputType) {
        if (source.inputType === 'ai-generator') {
          this.$refs.aiGenerator.$refs.inputText.focus();
          this.$refs.aiGenerator.$refs.inputText.selectionStart = this.aiGeneratorData.text.length;
          this.$refs.aiGenerator.$refs.inputText.scrollTop = this.$refs.aiGenerator.$refs.inputText.scrollHeight - this.$refs.aiGenerator.$refs.inputText.offsetHeight;
          this.$refs.aiGenerator.$el.scrollIntoView({ behavior: 'smooth' });
        } else if (source.inputType === 'script-editor') {
          // this.$refs.scriptEditors[source.index].$refs.textarea.focus();
          // this.$refs.scriptEditors[source.index].$refs.textarea.selectionStart = this.internalVoices[source.index].text.length;
          // this.$refs.scriptEditors[source.index].$refs.textarea.scrollTop = this.$refs.scriptEditors[source.index].$refs.textarea.scrollHeight - this.$refs.scriptEditors[source.index].$refs.textarea.offsetHeight;
          // this.$refs.scriptEditors[source.index].$el.scrollIntoView({ behavior: 'smooth' });
          this.scriptEditors[source.index].commands.focus('end');
        } else if (source.inputType === 'expanded-script-editor') {
          this.expandedScriptEditors[source.index].commands.focus('end');
        }
      }

      this.removeSentimentStorage();
    },
    async handleSentimentAnalysisData() {
      if (sessionStorage.getItem('sentimentFormData')) {
        const source = await JSON.parse(sessionStorage.getItem('sentimentSource'));

        if (source.inputType === 'ai-generator') {
          this.aiGeneratorData = source.data;
          this.$refs.aiGenerator.$refs.inputText.focus();
          this.$refs.aiGenerator.$refs.inputText.selectionStart = this.aiGeneratorData.text.length;
          this.$refs.aiGenerator.$refs.inputText.scrollTop = this.$refs.aiGenerator.$refs.inputText.scrollHeight - this.$refs.aiGenerator.$refs.inputText.offsetHeight;
          this.$refs.aiGenerator.$el.scrollIntoView({ behavior: 'smooth' });
        } else if (source.inputType === 'script-editor') {
          // this.$nextTick(() => {
          //   this.$refs.scriptEditors[source.index].$refs.textarea.focus();
          //   this.$refs.scriptEditors[source.index].$refs.textarea.selectionStart = this.internalVoices[source.index].text.length;
          //   this.$refs.scriptEditors[source.index].$refs.textarea.scrollTop = this.$refs.scriptEditors[source.index].$refs.textarea.scrollHeight - this.$refs.scriptEditors[source.index].$refs.textarea.offsetHeight;
          //   this.$refs.scriptEditors[source.index].$el.scrollIntoView({ behavior: 'smooth' });
          // });
          this.$nextTick(() => {
            this.scriptEditors[source.index].commands.focus('end');
          });
        } else if (['expanded-script-editor', 'ssml-preview'].includes(source.index)) {
          this.$bvModal.show(`${this.$refs.scriptEditors[source.index]._uid}-expanded-script-editor`);
          this.$nextTick(() => {
            this.expandedScriptEditors[source.index].commands.focus('end');
          });
        }

        if (sessionStorage.getItem('isAgreedSentiment')) {
          if (source.inputType === 'ai-generator') {
            this.isAiGenerating = true;
          } else if (['script-editor', 'expanded-script-editor'].includes(source.inputType)) {
            this.$nextTick(() => {
              this.internalVoices[source.index].actionState = 'loading';
            });
          }

          const formData = await JSON.parse(sessionStorage.getItem('sentimentFormData'));
          formData.result = '';
          formData.status = 'agree';

          let data = null;

          try {
            const res = await getGeneratedTextToAudioWithAgreements(formData);

            if (res.status === 403) {
              this.$bvModal.show('audio-quota');
            } else if (res.status === 402 || res.status === 500) {
              this.$bvModal.show('server-error-modal');
            } else if (res.status === 200) {
              ({ data } = res);
            }
          } catch (error) {
            console.log(error);
            this.$bvModal.show('server-error-modal');
          }

          if (data !== null) {
            if (source.inputType === 'ai-generator') {
              this.populateGeneratedAiData({
                data,
                scriptType: source.data.scriptType.id,
                voiceType: source.data.voiceType,
              });
            } else if (['script-editor', 'expanded-script-editor'].includes(source.inputType)) {
              this.populateGeneratedVoice({
                index: source.index,
                speaker: formData.speaker,
                data,
              });
            } else if (source.inputType === 'ssml-preview') {
              const SSMLPreviewHistory = await JSON.parse(sessionStorage.getItem('SSMLPreviewHistory')) || [];
              SSMLPreviewHistory.push(data);
              sessionStorage.setItem('SSMLPreviewHistory', JSON.stringify(SSMLPreviewHistory));
            }
          }

          if (source.inputType === 'ai-generator') {
            this.isAiGenerating = false;
          } else if (['script-editor', 'expanded-script-editor'].includes(source.inputType)) {
            if (data !== null) {
              this.internalVoices[source.index].actionState = 'play';
            } else {
              this.internalVoices[source.index].actionState = 'generate';
            }
          }
        }

        this.removeSentimentStorage();
      }
    },
    removeSentimentStorage() {
      sessionStorage.removeItem('sentimentFormData');
      sessionStorage.removeItem('sentimentInput');
      sessionStorage.removeItem('sentimentSource');
      sessionStorage.removeItem('isAgreedSentiment');
    },
    async handlePlayAudioPlayer(pauseAudio, audioId) {
      const playMediaEvent = new CustomEvent('play:media', {
        detail: {
          componentId: audioId,
        },
      });

      document.dispatchEvent(playMediaEvent);

      this.latestPlayingAudioId = audioId;
      this.pauseLatestPlayingAudio = pauseAudio;
    },
    async handlePlayMedia(e) {
      if (e.detail.componentId !== this.latestPlayingAudioId && this.pauseLatestPlayingAudio) {
        await this.pauseLatestPlayingAudio();
      }
    },
    handlePauseAudioPlayer() {
      this.$emit('pause:audio-player');
      if(this.internalVoices[this.$refs.audioPlayer?.dataset?.index].actionState != 'generate') {
        this.internalVoices[this.$refs.audioPlayer.dataset.index].actionState = 'play';
      }
    },
    handleTimeupdateAudioPlayer() {
      this.$emit('timeupdate:audio-player', this.$refs.audioPlayer.currentTime, this.$refs.audioPlayer.dataset.id);
    },
    handleAddNewScript() {
      const lastVoice = this.internalVoices[this.internalVoices.length - 1];

      this.internalVoices.push({
        id: randomUUID(),
        courseId: '',
        ttsId: '',
        text: '',
        scriptType: lastVoice.scriptType,
        voiceType: lastVoice.voiceType,
        src: null,
        duration: null,
        actionState: 'generate',
        disableAction: true,
        disableDelete: false,
        generateText: null,
        generateSpeaker: null,
        start: null,
        end: null
      });

      this.$nextTick(() => {
        this.scriptEditors[this.internalVoices.length - 1].commands.focus('end');
      });
    },
    handleImportScripts(scripts, voiceType) {
      const lastVoice = this.internalVoices[this.internalVoices.length - 1];

      for (const script of scripts) {
        this.internalVoices.push({
          id: randomUUID(),
          courseId: '',
          ttsId: '',
          text: script.replaceAll('\n', '').trim(),
          scriptType: lastVoice.scriptType,
          voiceType: voiceType,
          src: null,
          duration: null,
          actionState: 'generate',
          disableAction: true,
          disableDelete: false,
          generateText: null,
          generateSpeaker: null,
          start: null,
          end: null
        });
      }

      if(this.internalVoices[0].text == '') {
        this.internalVoices.splice(0, 1)
      }

      this.$bvModal.hide('import-audio-scripts');
    },
    async checkUseModel(index, isExpanded) {
      let voice = this.internalVoices[index];
      if(voice.voiceType?.character.isUseModel && !voice.voiceType?.character?.isMultilingual){
        await this.handleGenerateVoiceModel(index, isExpanded)
      } else {
        await this.handleGenerateVoice(index, isExpanded);
      }
    },
    async handleGenerateVoiceModel(index, isExpanded) {
      const voicesOnTimelineInStorage = await JSON.parse(sessionStorage.getItem('gv::voices'));
      const voicesOnTimelineInStorageCourseId = voicesOnTimelineInStorage && voicesOnTimelineInStorage.length > 0 ? voicesOnTimelineInStorage[0].courseId : null
      const prevActionState = this.internalVoices[index].actionState;

      this.internalVoices[index].actionState = 'loading';

      const formData = {
        id_models : this.internalVoices[index].voiceType.character.id,
        text : this.internalVoices[index].text,
        lang : this.internalVoices[index].voiceType.character.lang,
        course_id : voicesOnTimelineInStorageCourseId || this.internalVoices[0]?.courseId || null,
        params : {
          with_char : this.mode == "ai-characters" ? "False" : "False"
        }
      }

      let generatedText = this.internalVoices[index].text

      try {
        const res = await useVoiceModel(formData)
        if (res.status === 403) {
          this.$bvModal.show('audio-quota');
        } else if (res.status === 402 || res.status === 500) {
          this.$bvModal.show('server-error-modal');
        } else if (res.status === 406) {
          sessionStorage.setItem('sentimentFormData', JSON.stringify(formData));
          sessionStorage.setItem('sentimentSource', JSON.stringify({
            inputType: isExpanded ? 'expanded-script-editor' : 'script-editor',
            data: this.internalVoices[index],
            index,
          }));
          sessionStorage.setItem('isAgreedSentiment', false);
          this.$bvModal.show('sentiment-analysis-warning');
        } else {
          const { data } = res;
          let fixedData = {
            course_id : data.course_id,
            path : data.path,
            sentiment : data.sentiment || "",
            text : generatedText || data.text,
            tts_id : data.tts_id,
          }
          this.$emit('update:allocation')
          await this.populateGeneratedVoiceModel({ index, data: fixedData, speaker: this.internalVoices[index].voiceType.character.name });
          this.internalVoices[index].actionState = 'play';
        }
      } catch (error) {
        console.log(error)
        this.$bvModal.show('server-error-modal');
      }

      if (this.internalVoices[index].actionState === 'loading') {
        this.internalVoices[index].actionState = prevActionState;
      }

      return Promise.resolve();
    },
    async handleGenerateEventJokowi(index, isExpanded) {
      const voicesOnTimelineInStorage = await JSON.parse(sessionStorage.getItem('gv::voices'));
      const voicesOnTimelineInStorageCourseId = voicesOnTimelineInStorage && voicesOnTimelineInStorage.length > 0 ? voicesOnTimelineInStorage[0].courseId : null
      const prevActionState = this.internalVoices[index].actionState;

      let formData = {
        speaker : '1',
        text : this.internalVoices[index].text,
        generate : true,
        course_id : voicesOnTimelineInStorageCourseId || this.internalVoices[0]?.courseId || null
      }

      this.internalVoices[index].actionState = 'loading';

      let generatedText = this.internalVoices[index].text

      try {

        const res = await voiceCloneSample(formData)

        if (res.status === 403) {
          this.$bvModal.show('audio-quota');
        } else if (res.status === 402 || res.status === 500) {
          this.$bvModal.show('server-error-modal');
        } else if (res.status === 406) {
          sessionStorage.setItem('sentimentFormData', JSON.stringify(formData));
          sessionStorage.setItem('sentimentSource', JSON.stringify({
            inputType: isExpanded ? 'expanded-script-editor' : 'script-editor',
            data: this.internalVoices[index],
            index,
          }));
          sessionStorage.setItem('isAgreedSentiment', false);
          this.$bvModal.show('sentiment-analysis-warning');
        } else {
          const { data } = res;

          let fixedData = {
            course_id : data.course_id,
            length : data.length,
            path : base_url_machine_learning + data.path,
            sentiment : data.sentiment,
            text : generatedText || data.text,
            tts_id : data.tts_id,
          }

          this.$emit('update:allocation')
          await this.populateGeneratedVoiceModel({ index, data : fixedData, speaker: this.internalVoices[index].voiceType.character.name });
          this.internalVoices[index].actionState = 'play';
        }
      } catch (error) {
        console.error(error)
        this.$bvModal.show('server-error-modal');
      }

      if (this.internalVoices[index].actionState === 'loading') {
        this.internalVoices[index].actionState = prevActionState;
      }

      return Promise.resolve();
    },
    async populateGeneratedVoiceModel({ index, data, speaker = 'Sovia' }) {
      this.internalVoices[index].courseId = data.course_id;
      this.internalVoices[index].ttsId = data.tts_id;
      this.internalVoices[index].src = data.path;
      this.internalVoices[index].generateText = data.text;
      this.internalVoices[index].generateSpeaker = speaker;

      try {
        const duration = await this.getAudioDuration(data.path);
        this.internalVoices[index].duration = duration;
        this.populateStartEnd(this.internalVoices[index].id)
      } catch (error) {
        console.error('Failed to get audio duration:', error);
      }

      this.$emit('push-to-timeline', this.internalVoices[index])

      return Promise.resolve();
    },
    async getAudioDuration(url) {
      return new Promise((resolve, reject) => {
        const audio = new Audio(url);
        audio.addEventListener('loadedmetadata', () => {
          resolve(audio.duration);
        });
        audio.addEventListener('error', (error) => {
          reject(error);
        });
      });
    },
    handleAiGeneratorWrapperVisibility() {
      if (this.isExpanded) {
        if (
          this.$refs.contentInner.scrollHeight <= this.$refs.contentInner.offsetHeight
          || Math.round(this.$refs.contentInner.scrollTop + 1) >= this.$refs.contentInner.scrollHeight - this.$refs.contentInner.offsetHeight
        ) {
          this.isAiGeneratorWrapperShown = true;
        } else {
          this.isAiGeneratorWrapperShown = false;
        }
      }
    },
    populateStartEnd(id) {
      let voices = this.internalVoices.filter((voice) => voice.src)
      let index = voices.findIndex(item => item.id === id);
      let start = 0;
      let end = voices[index].duration;

      if (index > 0) {
        start = voices[index].start ? voices[index].start : Number((voices[index - 1].end + 0.01).toFixed(2));
        end = Number((start + voices[index].duration).toFixed(2));
      } else {
        start = voices[index].start != null ? voices[index].start : 0 ;
        end = Number((start + voices[index].duration).toFixed(2));
      }

      voices[index].start = start;
      voices[index].end = end;

      if(voices.length > 1 && voices[index].end >= voices[index + 1]?.start) {
        this.reCalculateStartEnd(index)
      }
      this.$emit('change:voices-order', voices)
    },
    reCalculateStartEnd(index) {
      let voices = this.internalVoices.filter((voice) => voice.src)
      if(voices.length > index + 1) {
        for (let i = index + 1; i < voices.length; i++) {
          if(voices[i - 1].end >= voices[i].start) {
            voices[i].start = Number((voices[i-1].end + 0.01).toFixed(2))
            voices[i].end = Number((voices[i].start + voices[i].duration).toFixed(2))
          }
        }
      }
    },
    lookUpScriptBox(id) {
      if(id) {
        const selectedItem = this.$refs.listScriptVoice.querySelector(`[data-id="${id}"]`);
        const overflowParent = this.$refs.contentInner;

        if (selectedItem && overflowParent) {
          const selectedItemOffset = selectedItem.offsetTop;
          const overflowParentScrollTop = overflowParent.scrollTop;
          const overflowParentClientHeight = overflowParent.clientHeight;

          if (selectedItemOffset > overflowParentScrollTop + overflowParentClientHeight) {
            const scrollY = selectedItemOffset - overflowParentClientHeight + selectedItem.clientHeight;
            overflowParent.scrollTop = scrollY;
          } else if (selectedItemOffset < overflowParentScrollTop) {
            overflowParent.scrollTop = (selectedItemOffset - 70);
          }
        }
      }
    },
    handleFocusBoxScript(id) {
      this.activeScriptBox = id
    },
    handleBlurBoxScript() {
      this.activeScriptBox = null
    },
    removeTagsSSML(input) {
      const regex = /<\/?[^>]+(>|$)/g;
      return input.replace(regex, '');
    },
    handleKeyPressVoicesComponent(event) {
      // Command + K (Mac) atau Ctrl + B (Windows/Linux) = buka prompt AI Generator
      if ((event.metaKey || event.ctrlKey) && event.key === "b") {
        const triggerOpenPrompt = this.$refs.buttonOpenPrompt
        triggerOpenPrompt.click();
      }
    },
  },
  async mounted() {
    this.contentInnerResizeObserver = new ResizeObserver(this.handleAiGeneratorWrapperVisibility);
    this.contentInnerResizeObserver.observe(this.$refs.contentInner);

    document.addEventListener('play:media', this.handlePlayMedia);
    window.addEventListener("keydown", this.handleKeyPressVoicesComponent);
    await this.handleSentimentAnalysisData();
    this.$nextTick(() => {
      this.showAiGeneratorTippy();
    });
  },
  beforeDestroy() {
    this.contentInnerResizeObserver.disconnect();

    document.removeEventListener('play:media', this.handlePlayMedia);
    window.removeEventListener("keydown", this.handleKeyPressVoicesComponent);
  },
};
</script>

<style scoped>
.gv-voice {
  gap: 16px;
  border-left: 1px solid #2D2D2D;

  transition: background-color 0.25s;
}

.gv-voice__nexpanded {
  border: none;
  background-color: #585859;
}

.gv-voice__expand-toggle {
  inset: 50px auto auto 45px;
  width: auto;
  height: auto;
  background-color: #1F1F1F;
  font-size: 12px;
  transition: background-color 0.25s, rotate 0.25s, inset 0.55s;
}

.gv-voice__expand-toggle:hover {
  background-color: #2F2F2F;
}

.gv-voice__expand-toggle--expanded {
  inset: 50px auto auto -14px;
  rotate: -180deg;
  width: 28px;
  height: 28px;
}

.gv-voice__expand-toggle--nexpanded {
  padding: 10px 6px;
  border-radius: 36px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.30) !important;
}

.gv-voice__title {
  font-size: 18px;
  font-weight: 500;

  visibility: hidden;
  opacity: 0;

  transition: padding 0.25s, visibility 0.65s, opacity 0.55s;
}

.gv-voice__title--nexpanded {
  opacity: 1;
  visibility: visible;
}

.gv-voice__content {
  visibility: hidden;
  opacity: 0;
  max-width: 0;

  width: 350px;
  max-height: 100%;
  overflow-x: hidden;
  transition: visibility 0.65s, opacity 0.55s, max-width 0.65s;
}

.gv-voice__content--expanded {
  visibility: visible;
  opacity: 1;
  max-width: 350px;
}

.gv-voice__content-inner {
  gap: 16px;
  width: 350px;
  padding-right: 18px;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

.gv-voice__list {
  gap: 8px;
}

.gv-voice__actions {
  gap: 8px;
  margin-bottom: 80px;
}

.gv-voice__action {
  gap: 8px;
  background: none;
  border: 1px solid #2D2D2D;
  font-size: 10px;
  font-weight: 500;
  transition: background-color 0.25s;
}

.gv-voice__action:hover {
  background-color: #FFFFFF11;
}

.gv-voice__ai-generator-container {
  opacity: 0;
  visibility: hidden;

  inset: auto 24px 24px auto;
  transition: opacity 0.25s, visibility 0.25s;
}

.gv-voice__ai-generator-container--show {
  opacity: 1;
  visibility: visible;
}

.gv-voice__ai-generator {
  background-color: #1f1f1f;
}

.gv-voice__ai-generator-toggle {
  width: 36px;
  height: 36px;
  background-color: #2D2D2D;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.25s;
}

.gv-voice__ai-generator-toggle--takeatour {
  z-index: 2001
}

.gv-voice__ai-generator-toggle:not(:disabled):hover, .gv-voice__ai-generator-toggle--show {
  background-color: #3D3D3D;
  color: #6D75F6;
}

.gv-voice__expand-text {
  opacity: 0;
  visibility: hidden;

  color: #fff;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  line-height: 16px;
  font-size: 18px;

  transition: opacity 0.25s, visibility 0.25s;
}

.gv-voice__expand-text--expanded {
  opacity: 1;
  visibility: visible;
}
</style>

<style>
.gv-voice__ai-generator-wrapper-theme {
  width: 450px;
  padding: 0;
  background-color: transparent;
}
</style>
