<template>
  <div class="ap d-flex align-items-center text-white">
    <button
      type="button"
      class="ap__toggle btn p-0 rounded-0"
      :disabled="isLoading"
      :title="`${isPlaying ? 'Pause' : 'Play'} ${name}`"
      @click="toggleAudioPlayer"
    >
      <font-awesome-icon
        :icon="`fa-solid ${isPlaying ? 'fa-pause-circle' : 'fa-play-circle'}`"
        class="ap__toggle-icon"
        :class="{ 'ap__toggle-icon--small': size === 'small' }"
      />
    </button>
    <div class="container-fluid">
      <div class="row">
        <div :class="type ? 'col-2' : 'col-4'" class="player__inner d-flex align-items-center pr-1">
          <p
            class="ap__name m-0 text-truncate"
            :class="{ 'ap__name--small': size === 'small' }"
            :title="name"
          >
            {{ name }}
          </p>
        </div>
        <div v-if="type" class="col-2 d-flex align-items-center">
          <div class="type--wrapper">
            <p class="ap__time m-0">{{ capitalizeText(type) }}</p>
          </div>
        </div>
        <div class="col-8 player__inner d-flex align-items-center">
          <input
            ref="slider"
            type="range"
            class="ap__slider ml-2"
            :class="{ 'ap__slider--small': size === 'small' }"
            v-model="currentTime"
            min="0"
            :max="sliderMax"
            title="Slide the Audio"
            @input="handleInputSlider"
            @change="handleChangeSlider"
          >
          <p class="ap__time m-0">{{ calculateTime(currentTime) }}</p>
        </div>
      </div>
    </div>
    <audio ref="audioPlayer" :src="src" preload="metadata" />
  </div>
</template>

<script>
import getBlobDuration from 'get-blob-duration';

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator(value) {
        return ['small', 'medium'].includes(value);
      },
      default: 'medium',
    },
    type: {
      type: String,
      default: null
    }
  },
  emits: ['play'],
  data() {
    return {
      duration: 0,
      sliderMax: 100,
      currentTime: 0,
      isLoading: false,
      isPlaying: false,
      doSliding: false,
    };
  },
  computed: {
    truncatedName() {
      const maxLength = 10;
      const ellipsis = '...';

      return this.name.length > maxLength ? this.name.substring(0, maxLength - ellipsis.length) + '...' : this.name;
    },
  },
  methods: {
    async loadedmetadataListener() {
      if (this.src.search('blob:') >= 0) {
        this.duration = await getBlobDuration(this.src);
      } else {
        this.duration = this.$refs.audioPlayer.duration;
      }
      this.sliderMax = Math.floor(this.duration);
    },
    timeupdateListener() {
      if (!this.doSliding) {
        this.currentTime = Math.floor(this.$refs.audioPlayer.currentTime);
      }

      if (this.$refs.audioPlayer.currentTime >= this.$refs.audioPlayer.duration && this.$refs.audioPlayer.paused) {
        this.isPlaying = false;
      }
    },
    calculateTime(secs) {
      const remainingTime = this.duration - secs;
			const minutes = Math.max(Math.floor(remainingTime / 60), 0);
			const seconds = Math.max(Math.floor(remainingTime % 60), 0);
			const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
			const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
			return `${returnedMinutes}:${returnedSeconds}`;
    },
    async toggleAudioPlayer() {
      if (!this.isLoading) {
        this.isLoading = true;

        try {
          if (this.isPlaying) {
            await this.$refs.audioPlayer.pause();
            this.isPlaying = false;
          } else {
            await this.$refs.audioPlayer.play();
            this.isPlaying = true;
            this.$emit('play', this.pauseAudioPlayer, this._uid);
          }
        } catch (error) {
          console.log(error);
        }

        this.isLoading = false;
      }
    },
    async pauseAudioPlayer() {
      this.isLoading = true;

      try {
        if (this.isPlaying) {
          await this.$refs.audioPlayer.pause();
          this.isPlaying = false;
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
    handleInputSlider() {
      this.doSliding = true;
    },
    handleMouseupSlider() {
      if (this.doSliding) {
        this.doSliding = false;
      }
    },
    handleChangeSlider(e) {
      this.$refs.audioPlayer.currentTime = e.target.value;
    },
    capitalizeText(text) {
			return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
		},
  },
  mounted() {
    this.$refs.audioPlayer.addEventListener('loadedmetadata', this.loadedmetadataListener);
    this.$refs.audioPlayer.addEventListener('timeupdate', this.timeupdateListener);
    document.addEventListener('mouseup', this.handleMouseupSlider);
  },
  beforeDestroy() {
    this.$refs.audioPlayer.removeEventListener('loadedmetadata', this.loadedmetadataListener);
    this.$refs.audioPlayer.removeEventListener('timeupdate', this.timeupdateListener);
    document.removeEventListener('mouseup', this.handleMouseupSlider);
  },
};
</script>

<style scoped>
.ap {
  gap: 12px;
}

.ap__toggle {
  background: none;
}

.ap__toggle-icon {
  font-size: 32px;
  color: #FFFFFF;
}

.ap__toggle-icon--small {
  font-size: 24px;
}

.ap__name {
  font-weight: 500;
}

.ap__name--small {
  font-size: 13px;
}

.ap__slider {
  width: 100%;
  accent-color: #6D75F6;
  cursor: pointer;
}

.ap__slider--small {
  height: 4px;
}

.ap__time {
  font-size: 12px;
}

.player__inner {
  gap: 4px;
}

.type--wrapper {
  border-radius: 10.5px;
  background: #585859;
  padding: 2px 10px;
}
</style>
