<template>
  <section class="ac d-flex flex-column" :class="{ 'ac--ovyv' : showSuggestionAgi}">
    <section class="ac__ai-generator-container px-5">
      <AIGenerator
        v-model="formData"
        class="ac__ai-generator"
        :placeholders="[
          'Design a mythical creature with the head of a lion, wings of an eagle, and the tail of a serpent.',
          'Illustrate a face expressing deep sadness and tears rolling down the cheeks.',
          'Generate an image resembling Leonardo da Vinci, the famous Italian artist and polymath.',
        ]"
        :disable-options="{
          scriptType: true,
          voiceType: true,
        }"
        use-input-text
        :is-generating="isGenerating"
        :disable-generate="!formData.text || !formData.engine || disabledAction"
        @generate="handleGenerate"
        :show-suggestion-agi="showSuggestion"
        @show-agi="(newVal) => this.$emit('show-agi', newVal)"
      >
        <template #settings>
          <div
            class="ac__settings align-items-center p-3 text-white text-left"
          >
            <div class="ac__settings-section">
              <p class="ac__settings-title m-0">Resolution</p>
              <span class="ac__settings-divider">:</span>
              <ul class="ac__settings-list d-flex m-0 list-unstyled">
                <li v-for="resolution in resolutions" :key="resolution">
                  <button
                    type="button"
                    class="ac__settings-button ac__settings-button--resolution btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                    :class="{ 'ac__settings-button--active': resolution === formData.resolution }"
                    :title="`Select ${resolution}`"
                    @click="formData.resolution = resolution"
                  >
                    {{ resolution }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="ac__settings-section">
              <p class="ac__settings-title m-0">Outputs</p>
              <span class="ac__settings-divider">:</span>
              <ul class="ac__settings-list d-flex m-0 list-unstyled">
                <li v-for="output in outputs" :key="output">
                  <button
                    type="button"
                    class="ac__settings-button ac__settings-button--output btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                    :class="{ 'ac__settings-button--active': output === formData.output }"
                    :title="`Select ${output}`"
                    @click="formData.output = output"
                  >
                    {{ output }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </AIGenerator>
    </section>

    <section class="ac__character-list-container d-flex flex-column">
      <CharacterList
        v-if="characters.length > 0"
        v-model="selectedCharacter"
        :characters="characters"
        :disabled-action="disabledAction"
      />
    </section>
  </section>
</template>

<script>
import { getImageAiGenerator } from '@/services/ttv/ttv.service.js';
import { base_url_machine_learning } from "../../../config/base_url";

import AIGenerator from '../ai-generator';
import CharacterList from './CharacterList';

export default {
  components: {
    AIGenerator,
    CharacterList,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    showSuggestionAgi: {
      type: Boolean,
      default: false,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedCharacter: this.value,
      characters: [],
      resolutions: ['256px', '512px', '1080px'],
      outputs: [1, 2, 3],
      formData: {
        text: '',
        engine: '2', // 1 or 2 String
        // resolution: '512px',
        // output: 1,
      },
      isGenerating: false,
      showSuggestion: this.showSuggestionAgi,
      apawe: null,
    };
  },
  computed: {
    reversedCharacters() {
      const characters = [...this.characters];
      return characters.reverse();
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.selectedCharacter = newValue;
      },
      deep: true,
    },
    selectedCharacter: {
      handler(newSelectedCharacter) {
        this.$emit('input', newSelectedCharacter);
      },
      deep: true,
    },
    showSuggestionAgi(newVal) {
      this.showSuggestion = newVal
    }
  },
  methods: {
    handleGenerate() {
      this.isGenerating = true;
      this.characters = [];

      if (this.selectedCharacter?.type === 'ai') {
        this.selectedCharacter = null;
      }

      getImageAiGenerator(this.formData)
        .then((response) => {

          const characters = response.data;

          if (characters?.length > 0) {
            for (const character of characters) {
              this.characters.push({
                name : 'AI ' + (this.characters.length + 1),
                imageSrc: base_url_machine_learning + character,
                type: 'ai',
              });
            }
          }

          this.isGenerating = false;
        });
    }
  },
};
</script>

<style scoped>
.ac {
  gap: 20px;
  min-height: 0;
  overflow-y: auto;
}

.ac--ovyv{
  overflow-y: visible !important;
}

.ac__ai-generator {
  background-color: #2D2D2D;
}

.ac__settings {
  display: grid;
  grid-template-columns: 70px 15px auto;
  gap: 8px 0;
  background-color: #2D2D2D;
  border-radius: 8px;
  font-size: 12px;
}

.ac__settings-section {
  display: contents;
}

.ac__settings-list {
  gap: 6px;
}

.ac__settings-button {
  background-color: #414141;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ac__settings-button:not(.ac__settings-button--active):hover {
  background-color: #515151;
}

.ac__settings-button--active {
  background-color: #6D75F6;
}

.ac__settings-button--resolution {
  min-width: 50px;
}

.ac__settings-button--output {
  min-width: 40px;
}

.ac__character-list-container {
  min-height: 0;
}
</style>
