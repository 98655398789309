<template>
  <b-modal
    :id="id"
    size="sm"
    dialog-class="ttvsrv__dialog"
    content-class="ttvsrv mx-auto text-white text-center"
    body-class="ttvsrv__body d-flex flex-column px-4 py-5"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    @show="characterName = ''"
  >
    <h2 class="ttvsrv__title mx-2 mb-0">{{ $t('save-voice') }}</h2>
    <div class="ttvsrv__input-group d-flex flex-column mx-2 text-left">
      <label
        for="input-character-name"
        class="ttvsrv__input-label m-0"
      >
        {{ $t('character-name') }}
      </label>
      <input
        v-model="characterName"
        type="text"
        class="ttvsrv__input-field text-white"
        :placeholder="$t('character-name--placeholder')"
        spellcheck="false"
        :readonly="loading"
        @keyup.enter="handleEnterKey"
      />
    </div>
    <div class="ttvsrv__buttons d-flex justify-content-center mx-2">
      <button
        type="button"
        class="ttvsrv__button ttvsrv__button--back btn px-4 py-2 text-white"
        title="Back"
        @click="$bvModal.hide(id)"
        :disabled="loading"
      >
        {{ $t('back') }}
      </button>
      <button
        type="button"
        class="ttvsrv__button ttvsrv__button--save btn px-4 py-2 text-white"
        title="Save"
        :disabled="!characterName || loading"
        @click="$emit('save', characterName)"
      >
        {{ loading ? 'Loading...' : $t('save') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'ttv-save-recorded-voice'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['save'],
  data() {
    return {
      characterName: '',
    };
  },
  methods: {
    handleEnterKey() {
      if (!this.loading && this.characterName) {
        this.$emit('save', this.characterName);
      }
    },
  },
};
</script>

<style scoped>
.ttvsrv__title {
  font-size: 24px;
}

.ttvsrv__input-group {
  gap: 8px;
}

.ttvsrv__input-label {
  font-size: 16px;
}

.ttvsrv__input-field {
  padding: 12px 16px;
  background: none;
  border-radius: 6px;
  border: 1px solid #585859;
  font-size: 14px;
}

.ttvsrv__input-field::placeholder {
  color: #585859;
}

.ttvsrv__buttons {
  gap: 8px;
}

.ttvsrv__button {
  border-radius: 6px;
  transition: background-color 0.25s;
}

.ttvsrv__button--back {
  font-weight: 500;
  background-color: #2D2D2D;
}

.ttvsrv__button--back:not(:disabled):hover {
  background-color: #3D3D3D;
}

.ttvsrv__button--save {
  font-weight: 600;
  background-color: #6D75F6;
}

.ttvsrv__button--save:not(:disabled):hover {
  background-color: #545EED;
}
</style>

<style>
.ttvsrv__dialog {
  max-width: none;
}

.ttvsrv {
  width: 400px;
  background-color: #1F1F1F;
  border-radius: 8px;
}

.ttvsrv__body {
  gap: 36px;
}
</style>
