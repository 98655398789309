<template>
  <section class="ev flex-grow-1 d-flex flex-column align-items-center" :class="isGenerate ? 'pt-0 position-relative h-100' : ''">
    <img src="@/assets/images/icons/ic_empty_view.svg" alt="" height="45" />
    <h2 class="ev__text">Create your own content using<br />AI generator or crafting from scratch</h2>
    <Soca v-if="isGenerate" type="generate"/>
  </section>
</template>

<script>
import Soca from '@/components/loading/Soca'

export default {
  components: {
    Soca
  },
  props: {
    isGenerate: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style scoped>
.ev {
  gap: 16px;
  padding-top: 5rem;
}

.ev__text {
  font-size: 24px;
  font-weight: 600;
  color: #585859
}
</style>
