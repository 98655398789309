<template>
  <ModalContainer
    :name="name"
    title="Add Image"
    width="800"
    :tab-names="['Gallery', 'Upload']"
    save-text="Use"
    :disable-save="!imageUrl"
    @save="$emit('input', imageUrl)"
    @before-open="refresh"
  >
    <template #tab-1>
      <div class="aim__gallery d-flex flex-column">
        <div class="aim__keyword-wrapper position-relative">
          <font-awesome-icon
            icon="fa-solid fa-magnifying-glass"
            class="aim__keyword-icon position-absolute"
          />
          <input
            type="text"
            v-model="keyword"
            class="aim__keyword-field"
            title="Find Image"
            placeholder="Find image"
            @change="handleChangeKeyword"
          />
        </div>

        <div class="aim__powered d-flex align-items-center">
          <span>Powered by</span>
          <img src="@/assets/images/logo/unsplash.svg" alt="Unsplash" height="16" />
        </div>

        <ul v-if="unsplashImages.length > 0" class="aim__image-list aim__image-list--gallery m-0 list-unstyled">
          <li v-for="image in unsplashImages" :key="image.id">
            <button
              type="button"
              :title="image.description"
              class="aim__image-button btn position-relative p-0"
              :class="{ 'aim__image-button--selected': image.urls.regular === imageUrl }"
              @click="imageUrl = image.urls.regular"
            >
              <img
                :src="image.urls.small"
                :alt="image.description"
                class="aim__image aim__image--gallery"
              />
              <img
                src="@/assets/images/icons/ic_selected.svg"
                alt=""
                height="16"
                class="aim__image-selected-icon position-absolute"
                :class="{ 'aim__image-selected-icon--show': image.urls.regular === imageUrl }"
              />
            </button>
          </li>
        </ul>

        <div v-else class="aim__empty-message d-flex flex-column justify-content-center align-items-center">
          <p class="aim__empty-text m-0 text-center">No images found</p>
        </div>
      </div>
    </template>

    <template #tab-2>
      <label
        ref="uploadLabel"
        for="input-image"
        class="aim__upload-label d-flex flex-column justify-content-center align-items-center p-4"
        :class="{ 'aim__upload-label--hover': isUploadLabelHovered }"
        title="Choose or Drop Image"
        @dragover.prevent="isUploadLabelHovered = true"
        @dragleave.prevent="isUploadLabelHovered = false"
        @drop.prevent="handleDropUploadLabel"
      >
        <img
          src="@/assets/images/icons/ic_upload_drop.svg"
          alt=""
          height="36"
        />
        <p class="d-flex flex-column justify-content-center align-items-center m-0 text-center">
          <span class="aim__upload-label-text1">Choose file</span>
          <span class="aim__upload-label-text2">or drag & drop here</span>
          <span class="aim__upload-label-text3">format file : .jpg .png</span>
        </p>
      </label>
      <input
        ref="uploadInput"
        type="file"
        :accept="supportedImageTypes"
        id="input-image"
        class="d-none"
        @change="handleChangeUploadInput"
      />
      <div v-if="pastUploadImages.length > 0" class="aim__past-uploads d-flex flex-column">
        <h3 class="aim__past-uploads-title m-0">Past Uploads</h3>
        <ul class="aim__image-list aim__image-list--past-upload m-0 list-unstyled">
          <li v-for="(image, index) in reversedPastUploadImages" :key="index">
            <button
              type="button"
              class="aim__image-button btn position-relative p-0"
              :class="{ 'aim__image-button--selected': image.url === imageUrl }"
              :title="image.filename"
              @click="imageUrl = image.url"
            >
              <img
                :src="image.url"
                alt=""
                class="aim__image aim__image--past-upload"
              />
              <img
                src="@/assets/images/icons/ic_selected.svg"
                alt=""
                height="16"
                class="aim__image-selected-icon position-absolute"
                :class="{ 'aim__image-selected-icon--show': image.url === imageUrl }"
              />
              <div
                class="aim__image-description d-flex justify-content-between align-items-center"
              >
                <p class="aim__image-description-text m-0 text-truncate">{{ image.filename }}</p>
                <button
                  type="button"
                  class="aim__image-option-button btn flex-shrink-0 d-flex justify-content-center align-items-center p-0"
                  title="Options"
                  @click.stop="true"
                >
                  <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
                </button>
              </div>
            </button>
          </li>
        </ul>
      </div>
      <div v-else class="aim__empty-message d-flex flex-column justify-content-center align-items-center">
        <p class="aim__empty-text m-0 text-center">You don't have any uploads</p>
      </div>
    </template>
  </ModalContainer>
</template>

<script>
import { getSearchImage } from "@/services/course/course.service";

import ModalContainer from './ModalContainer.vue';

export default {
  components: {
    ModalContainer,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'add-image-modal',
    },
  },
  data() {
    return {
      imageUrl: this.value,
      supportedImageTypes: ['image/png', 'image/jpeg'],
      keyword: '',
      unsplashImages: [],
      pastUploadImages: [],
      isUploadLabelHovered: false,
    };
  },
  methods: {
    refresh() {
      this.keyword = '';
      this.getUnsplashImages();
      this.getPastUploadImages();
    },
    async getUnsplashImages() {
      try {
        const response = await getSearchImage(this.keyword, 20, 1);

        if (response.status) {
          this.unsplashImages = response.data.results;
        } else {
          this.unsplashImages = [];
        }
      } catch (error) {
        console.log(error);
        this.unsplashImages = [];
      }
    },
    getPastUploadImages() {
      this.pastUploadImages = [
        {
          url: 'https://source.unsplash.com/random/600x500/?mountain',
          filename: 'The Mountain.jpg',
        },
        {
          url: 'https://source.unsplash.com/random/500x500/?sea',
          filename: 'Blue Sea.jpg',
        },
        {
          url: 'https://source.unsplash.com/random/500x500/?forest',
          filename: 'Rain Forest.jpg',
        },
        {
          url: 'https://source.unsplash.com/random/500x500/?night',
          filename: 'Night Vibes.jpg',
        },
      ];
    },
    handleChangeKeyword() {
      this.getUnsplashImages();
    },
    handleChangeUploadInput() {
      for (const file of this.$refs.uploadInput.files) {
        if (this.supportedImageTypes.includes(file.type)) {
          const fileReader = new FileReader();

          fileReader.addEventListener('load', (e) => {
            this.pastUploadImages.push({
              url: e.target.result,
              filename: file.name,
            });
          });

          fileReader.readAsDataURL(file);
        } else {
          alert('Format unsupported!');
        }
      }
    },
    handleDropUploadLabel(e) {
      this.$refs.uploadInput.files = e.dataTransfer.files;
      this.handleChangeUploadInput();
      this.isUploadLabelHovered = false;
    },
  },
  computed: {
    reversedPastUploadImages() {
      let reversedPastUploadImages = this.pastUploadImages;
      reversedPastUploadImages.reverse();
      return reversedPastUploadImages;
    },
  },
  watch: {
    value(newValue) {
      this.imageUrl = newValue;
    },
  },
};
</script>

<style scoped>
.aim__gallery {
  gap: 20px;
}

.aim__keyword-wrapper {
  border: 1px solid #2D2D2D;
  border-radius: 36px;
  overflow: hidden;
  transition: border 0.25s;
}

.aim__keyword-wrapper:focus-within {
  border-color: #555555;
}

.aim__keyword-icon {
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #C4C4C4;
}

.aim__keyword-field {
  width: 100%;
  padding: 16px 16px 16px 64px;
  background: none;
  border: none;
  font-size: 14px;
  color: #FFFFFF;
}

.aim__keyword-field::placeholder {
  color: #555555;
}

.aim__powered {
  gap: 8px;
  color: #C4C4C4;
}

.aim__upload-label {
  gap: 4px;
  min-height: 190px;
  border: 3px dashed #2D2D2D;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.25s;
}

.aim__upload-label:hover, .aim__upload-label--hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.aim__upload-label-text1 {
  font-size: 18px;
  font-weight: 500;
  color: #6D75F6;
}

.aim__upload-label-text2 {
  color: #979797;
}

.aim__upload-label-text3 {
  font-size: 12px;
  font-style: italic;
  color: #979797;
}

.aim__past-uploads {
  gap: 16px;
}

.aim__past-uploads-title {
  font-size: 16px;
  font-weight: 400;
}

.aim__empty-message {
  min-height: 200px;
}

.aim__empty-text {
  color: #979797;
}

.aim__image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-width), 1fr));
  gap: 6px;
  overflow-y: auto;
}

.aim__image-list--gallery {
  --min-width: 130px;
  max-height: 420px;
}

.aim__image-list--past-upload {
  --min-width: 160px;
  max-height: 250px;
}

.aim__image-list::-webkit-scrollbar-thumb {
  background-color: #B6B6B6;
}

.aim__image-button {
  overflow: hidden;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: border 0.25s;
}

.aim__image-button:not(.aim__image-button--selected):hover {
  border-color: rgba(255, 255, 255, 0.1);
}

.aim__image-button--selected {
  border-color: #6D75F6;
}

.aim__image {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.aim__image--gallery {
  aspect-ratio: 1 / 1;
}

.aim__image--past-upload {
  height: 100px;
}

.aim__image-selected-icon {
  visibility: hidden;
  opacity: 0;

  top: 4px;
  right: 4px;
  transition: all 0.25s;
}

.aim__image-selected-icon--show {
  visibility: visible;
  opacity: 1;
}

.aim__image-description {
  gap: 4px;
  padding: 4px 4px 4px 12px;
  background-color: #2D2D2D;
}

.aim__image-description-text {
  font-size: 12px;
  color: #FFFFFF;
}

.aim__image-option-button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #FFFFFF;
  transition: background-color 0.25s;
}

.aim__image-option-button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
</style>
