<template>
  <ModalContainer
    :name="name"
    title="Add Audio"
    width="800"
    :tab-names="['Upload', 'Voice Record']"
    save-text="Use"
    :disable-save="!audioUrl"
    @save="$emit('input', audioUrl)"
    @before-open="refresh"
    @before-close="stopStream"
    @open-tab-1="stopStream"
    @open-tab-2="handleOpenTab2"
  >
    <template #tab-1>
      <div class="px-2" style="height:28rem;">
        <label
          ref="uploadLabel"
          for="input-audio"
          class="aam__upload-label d-flex flex-column justify-content-center align-items-center p-4"
          :class="{ 'aam__upload-label--hover': isUploadLabelHovered, 'grabbing-cursor' : isDrag }"
          title="Choose or Drop Audio"
          @dragover.prevent="handleDragoverLabel"
          @dragleave.prevent="handleDragleaveLabel"
          @drop.prevent="handleDropLabel"
          @dragenter.prevent="onDragEnter"
        >
          <img
            src="@/assets/images/icons/ic_upload_drop.svg"
            alt=""
            height="36"
          />
          <p class="d-flex flex-column justify-content-center align-items-center m-0 text-center">
            <span class="aam__upload-label-text1">Choose File or Drag Here</span>
            <span class="aam__upload-label-text3"><i>format file : .mp3 .wav .ogg</i></span>
            <span class="aam__upload-label-text3"><i>max. file size 1MB</i></span>
          </p>
        </label>
        <input
          ref="uploadInput"
          type="file"
          :accept="supportedAudioTypes"
          id="input-audio"
          class="d-none"
          @change="handleChangeInput"
        />
        <div v-if="isload">
          <div class="aam__empty-message d-flex flex-column justify-content-center align-items-center">
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="pastUploadAudios.length > 0" class="aam__past-uploads d-flex flex-column">
            <h3 class="aam__past-uploads-title m-0">Past Uploads</h3>
            <audio ref="audioPlayer" @ended="handleEndedAudioPlayer" />
            <div class=" m-0 row overflow-auto list-unstyled" style="height:15rem;">
              <div v-for="audio in reversedPastUploadAudios" :key="audio._id" class="col-3">
                <div
                  :title="audio.filename"
                  class="aam__audio-button btn position-relative d-flex flex-column align-items-stretch p-0  mr-2 mt-2"
                  :class="{ 'aam__audio-button--selected': audio._id === audioId }"
                  @click="audioUrl = audio.voice_url; audioId = audio._id"
                >
                  <div
                    class="aam__audio-button-player d-flex justify-content-center align-items-center"
                  >
                    <button
                      type="button"
                      class="aam__audio-button-player-button btn p-0"
                      :title="`${(playedAudioSrc === audio._id && !playedAudioPaused) ? 'Pause' : 'Play'} ${audio.filename}`"
                      @click.stop="toggleAudioPlayer(audio.voice_url, audio._id)"
                    >
                      <font-awesome-icon
                        :icon="`fa-solid ${(playedAudioSrc === audio._id && !playedAudioPaused) ? 'fa-circle-pause' : 'fa-circle-play'}`"
                        class="aam__audio-button-player-icon"
                      />
                    </button>
                  </div>
                  <img
                    src="@/assets/images/icons/ic_selected.svg"
                    alt=""
                    height="16"
                    class="aam__audio-selected-icon position-absolute"
                    :class="{ 'aam__audio-selected-icon--show': audio._id === audioId }"
                  />
                  <div
                    class="aam__audio-description d-flex justify-content-between align-items-center"
                  >
                    <p class="aam__audio-description-text m-0 text-truncate">{{ audio.filename }}</p>
                    <div class="dropdown">
                      <button
                        class="btn aam__audio-option-button p-0"
                        type="button"
                        id="imglibrary"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <b-icon
                          icon="three-dots-vertical"
                          style="color: #fff"
                          font-scale="0.8"
                        ></b-icon>
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="imglibrary"
                      >
                  <li>
                        <button
                          class="btn btn-transparent pl-1 pr-2 py-0"
                          @click="deleteAudioLibrary(audio._id)"
                        >
                          <div class="d-flex align-items-center">
                            <span class="ml-2 text-white">Delete</span>
                          </div>
                        </button>
                  </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="aam__empty-message d-flex flex-column justify-content-center align-items-center">
              <p class="aam__empty-text m-0 text-center">You don't have any uploads</p>
          </div>
        </div>
      </div>
    </template>

    <template #tab-2>
      <div class="aam__record d-flex flex-column px-2">
        <div v-if="audioUrl === recordSrc" class="aam__recorded-audio p-4">
          <AudioPlayer
            :src="audioUrl"
            name="Voice 1"
          />
        </div>
        <div v-else class="aam__recorder flex-grow-1 d-flex flex-column justify-content-center align-items-center">
          <av-media
            type="frequ"
            :media="stream"
            line-color="#6D75F6"
            :canv-width="580"
            :frequ-lnum="100"
            frequ-direction="mo"
            :radius="16"
          />
          <div class="aam__recorder-toggle-wrapper d-flex flex-column align-items-center">
            <p class="aam__recorder-time m-0">{{ calculateTime(recordDuration) }}</p>
            <button
              type="button"
              :title="`${isRecording ? 'Stop' : 'Record'}`"
              class="aam__recorder-toggle btn d-flex justify-content-center align-items-center"
              :disabled="recordErrorMessage"
              @click="toggleVoiceRecorder"
            >
              <font-awesome-icon
                :icon="`fa-solid ${isRecording ? 'fa-stop' : 'fa-circle'}`"
                class="aam__recorder-toggle-icon"
              />
            </button>
            <p class="aam__recorder-toggle-text m-0">{{ isRecording ? 'Stop' : 'Record' }}</p>
          </div>
          <p v-if="recordErrorMessage" class="aam__recorder-error-message m-0 mt-4">
            Can't use voice recorder: {{ recordErrorMessage }}
          </p>
        </div>
      </div>
    </template>

  </ModalContainer>
</template>

<script>
import RecordRTCPromiseHandler from 'recordrtc';
import ModalContainer from './ModalContainer';
import AudioPlayer from '@/components/audio-player/AudioPlayer.vue';
import { postAudioLibrary, getAudioLibrary, deleteAudioLibraryById } from "@/services/contents/contents.service";

import { AlertUtils } from "@/mixins/AlertUtils";

export default {
  mixins: [AlertUtils],
  components: {
    ModalContainer,
    AudioPlayer,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'add-audio-modal',
    },
  },
  data() {
    return {
      audioUrl: this.value,
      audioId: null,
      supportedAudioTypes: ['audio/mpeg', 'audio/wav', 'audio/ogg'],
      playedAudioSrc: null,
      playedAudioPaused: false,
      pastUploadAudios: [],
      isUploadLabelHovered: false,
      isDrag : false,
      loading: false,
      stream: null,
      recorder: null,
      recordErrorMessage: null,
      isRecording: false,
      recordDuration: 0,
      recordSrc: null,
      addRecordDurationInterval: null,
    };
  },
  computed: {
    reversedPastUploadAudios() {
      let reversedPastUploadAudios = this.pastUploadAudios;
      reversedPastUploadAudios.reverse();
      return reversedPastUploadAudios;
    },

    isload() {
      return this.loading
    }
  },
  mounted() {
    this.getAudio()
  },
  methods: {
    async refresh() {
      this.audioUrl = '';
      this.recordDuration = 0;
      this.recordSrc = null;
      this.playedAudioSrc = null;
      this.playedAudioPaused = false;
      this.getAudio();
    },
    // getPastUploadAudios() {
    //   this.pastUploadAudios = [
    //     {
    //       url: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Indonesia_Raya_Simfoni_dan_Vokal_1_Bait.ogg',
    //       filename: 'Indonesia Raya.ogg',
    //     },
    //     {
    //       url: 'https://upload.wikimedia.org/wikipedia/en/4/43/Viva_la_Vida.ogg',
    //       filename: 'Viva la Vida.ogg',
    //     },
    //   ];
    // },
    handleChangeInput() {
      for (const file of this.$refs.uploadInput.files) {
        if (file.size > 1000000){
          this.alertFail(this.$t("file-size-max-1mb"))
        } else if (this.supportedAudioTypes.includes(file.type)) {
          this.convertAudioToBs64(file, 'audio', file.name)
        } else {
          this.alertFail("Format unsupported!")
        }
      }
    },
    handleDragoverLabel() {
      this.isUploadLabelHovered = true;
    },
    handleDragleaveLabel() {
      this.isUploadLabelHovered = false;
    },
    handleDropLabel(e) {
      this.isDrag = false;
      let file = e.dataTransfer.files
      console.log(file)
      this.$refs.uploadInput.files = e.dataTransfer.files;
      this.handleChangeInput();
      this.isUploadLabelHovered = false;
    },
    async toggleAudioPlayer(src, id) {
      const audioPlayer = this.$refs.audioPlayer;

      if (audioPlayer.src !== src) {
        audioPlayer.src = src;
        this.playedAudioSrc = id;
      }

      try {
        if (audioPlayer.paused) {
          await audioPlayer.play();
          this.playedAudioPaused = false;
        } else {
          await audioPlayer.pause();
          this.playedAudioPaused = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleEndedAudioPlayer() {
      this.$refs.audioPlayer.src = '';
      this.playedAudioSrc = '';
      this.playedAudioPaused = false;
    },
    async handleOpenTab2() {
      if (this.recordSrc) {
        this.audioUrl = this.recordSrc;
      } else {
        if (!this.stream && !this.recorder) {
          try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.recorder = new RecordRTCPromiseHandler(this.stream, {
              type: 'audio',
            });
          } catch (error) {
            console.log(error);
            this.recordErrorMessage = error.message;
          }
        }
      }
    },
    async toggleVoiceRecorder() {
      if (this.isRecording) {
        await this.recorder.stopRecording((blobUrl) => {
          this.recordSrc = blobUrl;
          this.audioUrl = blobUrl;
          this.stopStream();
        });
        this.isRecording = false;
      } else {
        this.recorder.startRecording();
        console.log('s');
        this.addRecordDurationInterval = setInterval(() => this.recordDuration += 1, 1000);
        this.isRecording = true;
      }
    },
    stopStream() {
      this.stream?.getTracks().forEach((track) => track.stop());
      this.stream = null;
      this.recorder = null;
      this.recordErrorMessage = null;
      this.isRecording = false;
      this.recordDuration = 0;
      clearInterval(this.addRecordDurationInterval);
    },
    calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`
    },
    handlePostAudio(audio, name){
      this.loading = true
      let formData = {
        filename: name,
        voice: audio
      }
      postAudioLibrary(formData)
      .then((response) => {
        if(response.status) {
          this.getAudio()
        }
      })
    },
    getAudio(){
      this.loading = true;
      getAudioLibrary()
      .then((response) => {
        if(response.status) {
          this.pastUploadAudios = response.data
          this.loading = false;
        } else {
          this.pastUploadAudios = []
          this.loading = false
        }
      })
    },
    deleteAudioLibrary(id){
      deleteAudioLibraryById(id)
      .then((response) => {
        if(response.status){
          this.getAudio()
        }
      })
    },
    convertAudioToBs64(file, form, name) {
      var vm = this;
      var reader = new FileReader();
      reader.onloadend = function () {
        switch (form) {
          case 'audio':
            vm.handlePostAudio(reader.result, name)
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(file);
    },
    onDragEnter(event) {
      event.preventDefault();
      this.isDrag = true;
    },
  },
  watch: {
    value(newValue) {
      this.audioUrl = newValue;
    },
  },
};
</script>

<style scoped>
.aam__upload-label {
  gap: 4px;
  min-height: 190px;
  border: 3px dashed #2D2D2D;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.25s;
}

.aam__upload-label:hover, .aam__upload-label--hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.aam__upload-label-text1 {
  font-size: 18px;
  font-weight: 500;
  color: #6D75F6;
}

.aam__upload-label-text2 {
  color: #979797;
}

.aam__upload-label-text3 {
  font-size: 12px;
  font-style: italic;
  color: #979797;
}

.aam__past-uploads {
  gap: 16px;
}

.aam__past-uploads-title {
  font-size: 16px;
  font-weight: 400;
}

.aam__empty-message {
  min-height: 200px;
}

.aam__empty-text {
  color: #979797;
}

.aam__audio-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 6px;
  max-height: 300px;
  overflow-y: auto;
}

.aam__audio-list::-webkit-scrollbar-thumb {
  background-color: #B6B6B6;
}

.aam__audio-button {
  /* overflow: hidden; */
  /* width: 100%; */
  border: 2px solid transparent;
  border-radius: 8px;
  transition: border 0.25s;
}

.aam__audio-button:not(.aam__audio-button--selected):hover {
  border-color: rgba(255, 255, 255, 0.1);
}

.aam__audio-button--selected {
  border-color: #6D75F6;
}

.aam__audio-button-player {
  height: 120px;
  background-color: #383838;
  border-radius: 8px 8px 0px 0px;
}

.aam__audio-button-player-button {
  background: none;
}

.aam__audio-button-player-icon {
  font-size: 36px;
  color: #FFFFFF;
}

.aam__audio-selected-icon {
  visibility: hidden;
  opacity: 0;

  top: 4px;
  right: 4px;
  transition: all 0.25s;
}

.aam__audio-selected-icon--show {
  visibility: visible;
  opacity: 1;
}

.aam__audio-description {
  gap: 4px;
  padding: 4px 4px 4px 12px;
  border-radius: 0px 0px 8px 8px;
  background-color: #2D2D2D;
}

.aam__audio-description-text {
  font-size: 10px;
  color: #FFFFFF;
}

.aam__audio-option-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #FFFFFF;
  font-size: 12px;
  transition: background-color 0.25s;
}

.aam__audio-option-button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.aam__record {
  height: 28rem;
}

.aam__recorder {
  gap: 16px;
}

.aam__recorder-time {
  font-size: 10px;
}

.aam__recorder-toggle-wrapper {
  gap: 8px;
}

.aam__recorder-toggle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #2D2D2D;
  transition: background-color 0.25s;
}

.aam__recorder-toggle:not(:disabled):hover {
  background-color: #555555;
}

.aam__recorder-toggle-icon {
  font-size: 20px;
  color: #FF6B61;
}

.aam__recorder-toggle-text {
  font-size: 12px;
  font-weight: 500;
}

.aam__recorder-error-message {
  color: #FF6B61;
}

.aam__recorded-audio {
  background-color: #2D2D2D;
  border-radius: 6px;
}
.dropdown-menu{
    z-index: 100;
    background-color: #2D2D2D;
    min-width: 5rem;
    box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
}

.dropdown span, .dropdown-menu span {
    font-size: 12px;
}

.dropdown-menu li {
  transition: background-color 0.25s;
}

.dropdown-menu li:hover {
	background-color: rgba(255, 255, 255, 0.25)
}

.grabbing-cursor {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}
</style>
