<template>
  <b-modal
    :id="id"
    size="sm"
    dialog-class="ttvsr__dialog"
    content-class="ttvsr mx-auto text-white text-center"
    body-class="ttvsr__body d-flex flex-column justify-content-center align-items-center p-5"
    centered
    hide-header
    hide-footer
    @shown="startCloseTimeout"
    @hide="$emit('close:modal')"
  >
    <h2 class="ttvsr__title m-0">{{ $t('thank-you') }}</h2>
    <p class="ttvsr__message m-0">{{ $t('recording-saved') }}</p>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'ttv-succesfully-record',
    },
  },
  emits: ['close:modal'],
  methods: {
    startCloseTimeout() {
      const closeModalAfterInMs = 3000;
      const closeModalTimeout = setTimeout(() => {
        this.$bvModal.hide(this.id);
        clearTimeout(closeModalTimeout);
      }, closeModalAfterInMs);
    },
  },
};
</script>

<style scoped>
.ttvsr__title {
  font-size: 24px;
}

.ttvsr__message {
  font-size: 16px;
}
</style>

<style>
.ttvsr__dialog {
  max-width: none;
}

.ttvsr {
  width: 325px;
  background-color: #1F1F1F;
  border-radius: 8px;
}

.ttvsr__body {
  gap: 14px;
}
</style>
