<template>
  <li>
    <div v-if="popover" class="item-popover" ref="itemPopover">
      <button
        type="button"
        class="item-popover__button"
        :class="{
          'item-popover__button--normal': type === 'normal',
          'item-popover__button--minimal': type === 'minimal',
          'item-popover__button--active': showPopover,
        }"
        :title="name"
        @click="showPopover = !showPopover"
      >
        <slot />
      </button>
      <div
        class="item-popover__popover"
        :class="{
          'item-popover__popover--show': showPopover,
        }"
      >
        <component
          :is="popover.component"
          v-bind="{ ...popover.props }"
          @hidePopover="showPopover = false"
        />
      </div>
    </div>
    <button
      v-else
      type="button"
      class="item-regular"
      :class="{
        'item-regular--normal': type === 'normal',
        'item-regular--minimal': type === 'minimal',
        'item-regular--active': isActive,
      }"
      :title="name"
      @click="$emit('click')"
    >
      <slot />
    </button>
  </li>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    popover: {
      type: Object,
      default() {
        return null;
      },
    },
    type: {
      validator(value) {
        return ['normal', 'minimal'].includes(value);
      },
      default: 'normal',
    },
  },
  emits: ['click'],
  data() {
    return {
      showPopover: false,
    };
  },
  methods: {
    hidePopoverWhenClickOutside(e) {
      if (!this.$refs.itemPopover.contains(e.target) && this.showPopover) {
        this.showPopover = false;
      }
    },
  },
  mounted() {
    if (this.popover) {
      document.addEventListener('click', this.hidePopoverWhenClickOutside);
    }
  },
  beforeDestroy() {
    if (this.popover) {
      document.removeEventListener('click', this.hidePopoverWhenClickOutside);
    }
  }
};
</script>

<style scoped>
.item-popover {
  position: relative;
}

.item-popover__popover {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-0.5rem);

  z-index: 9999;
  position: absolute;
  top: calc(100% + -2rem);
  left: 35px;
  padding: 1rem;
  background-color: #1f1f1f;
  color: #fff;
  border-radius: 0.5rem;
  transition: visibility 0.25s, transform 0.25s, opacity 0.25s;
}

.item-popover__popover--show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.item-regular, .item-popover__button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.25s;
}

.item-regular--normal, .item-popover__button--normal {
  width: 1.75rem;
  height: 1.75rem;
  background-color: transparent;
  font-size: 1rem;
}

.item-regular--minimal, .item-popover__button--minimal {
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 0.75rem;
}

.item-regular--active, .item-popover__button--active {
  background-color: #fff;
  color: #000;
}

:is(
  .item-regular:not(.item-regular--active),
  .item-popover__button:not(.item-popover__button--active)
):hover {
  background-color: rgba(255, 255, 255, 0.25);
}
</style>
