<template>
  <div class="qii d-flex justify-content-center align-items-center">
    <div v-if="imageUrl" class="qii__image-wrapper d-flex justify-content-center">
	    <img :src="imageUrl" alt="" class="qii__image" />
      <button
        type="button"
        class="qii__remove-button btn d-flex justify-content-center align-items-center"
        title="Remove Image"
        @click="imageUrl = ''"
      >
        <img src="@/assets/images/icons/ic_trash_fill.svg" alt="" height="16" />
      </button>
    </div>

    <button
      v-else
      type="button"
      class="qii__insert-button btn d-flex flex-column justify-content-center align-items-center py-3 text-white"
      title="Find and Insert Image"
      @click="$bvModal.show('add-image-question-input')"
    >
      <span class="qii__insert-icon d-flex justify-content-center align-items-center">
        <font-awesome-icon icon="fa-solid fa-plus" />
      </span>
      Find & Insert Image
    </button>

	<Gallery :allocation="allocation" @update:allocation="$emit('update:allocation')" v-model="imageUrl" name="add-image-question-input"/>
  </div>
</template>

<script>
import Gallery from '../../modals/Gallery';

export default {
  components: {
    Gallery,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    allocation: {
			type: Object,
			default : () => {
				return null
			}
		},
  },
  emits: ['input'],
  data() {
    return {
      imageUrl: this.value,
    };
  },
  watch: {
    imageUrl(newImageUrl) {
      this.$emit('input', newImageUrl);
    },
    value(newValue) {
      this.imageUrl = newValue;
    },
  },
};
</script>

<style scoped>
.qii {
  height: 100%;
}

.qii__image-wrapper {
  width: min(500px, 100%);
  height: 100%;
  gap: 16px;
}

.qii__image {
  width: 300px;
  border: none;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.qii__remove-button {
  width: 40px;
  height: 40px;
  background-color: #2D2D2D;
  border-radius: 50%;
  transition: background-color 0.25s;
}

.qii__remove-button:hover {
  background-color: #F34D4D;
}

.qii__insert-button {
  width: 100%;
  height: 100%;
  gap: 8px;
  background-color: #2D2D2D;
  border-radius: 10px;
  font-size: 18px;
  transition: background-color 0.25s;
}

.qii__insert-button:hover {
  background-color: #3D3D3D;
}

.qii__insert-icon {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #000000;
}
</style>
