<template>
  <div class="rv d-flex flex-column text-white text-left">
    <header class="rv__header d-flex justify-content-between align-items-center px-5 py-3">
      <button
        type="button"
        class="rv__back-button btn p-0 rounded-0"
        :title="backButtonTitle"
        @click="handleClickBackButton"
      >
        <img
          src="@/assets/images/icons/ic_back_nav.svg"
          alt="Back"
          height="18"
        />
        <span class="sr-only">{{ backButtonTitle }}</span>
      </button>
      <button
        v-if="!(['instructions', 'instructions-guide'].includes(activePage))"
        type="button"
        class="rv__instructions-button btn d-inline-flex justify-content-center align-items-center px-3 py-1 text-white"
        title="Instructions"
        @click="activePageBeforeGuide = activePage; activePage = 'instructions-guide'"
      >
        <img
          src="@/assets/images/icons/ic_instructions.svg"
          alt="Instructions"
          height="14"
        />
        Instructions
      </button>
    </header>

    <main class="rv__main flex-grow-1 d-flex flex-column align-items-center p-4">
      <RVInstructions
        v-if="['instructions', 'instructions-guide'].includes(activePage)"
        @click:continue="handleClickContinueInstructions"
      />
      <RVRecordRoom
        v-else-if="activePage === 'record-room'"
        :recorded-voice-src="recordedVoiceSrc"
        :languages="languages"
        :selected-language="selectedLanguage"
        :suggestion-script="suggestionScript.text"
        @change:recorded-voice-src="(newRecordedVoiceSrc) => recordedVoiceSrc = newRecordedVoiceSrc"
        @click:next="$bvModal.show('ttv-save-recorded-voice')"
        @change:language="(newLanguage) => selectedLanguage = newLanguage"
      />
      <!-- <RVInputScript
        v-else-if="activePage === 'input-script'"
        @close:submit-modal="handleSubmit"
      /> -->
      <!-- <RVFinish
        v-else-if="activePage = 'finish'"
        @click:submit="handleSubmit"
        @click:download="handleDownload"
      /> -->
    </main>

    <TTVSaveRecordedVoiceModal
      id="ttv-save-recorded-voice"
      :loading="load_save_record"
      @save="handleSave"
    />
    <TTVSuccessfullyRecordModal
      id="ttv-successfully-record"
      @close:modal="$router.back()"
    />
  </div>
</template>

<script>
import getBlobDuration from 'get-blob-duration';

import RVInstructions from '@/components/generative-ai-text-to-video/record-voice/Instructions';
import RVRecordRoom from '@/components/generative-ai-text-to-video/record-voice/RecordRoom';
// import RVInputScript from '@/components/generative-ai-text-to-video/record-voice/InputScript';
// import RVFinish from '@/components/generative-ai-text-to-video/record-voice/Finish';

import TTVSaveRecordedVoiceModal from '@/components/modal/TTVSaveRecordedVoice';
import TTVSuccessfullyRecordModal from '@/components/modal/TTVSuccessfullyRecord';

import { getSVM, generateVoiceModel } from '@/services/ttv/ttv.service.js'

export default {
  components: {
    RVInstructions,
    RVRecordRoom,
    // RVInputScript,
    // RVFinish,
    TTVSaveRecordedVoiceModal,
    TTVSuccessfullyRecordModal,
  },
  data() {
    return {
      activePage: 'instructions', // ['instructions', 'instructions-guide', 'record-room']
      activePageBeforeGuide: null,
      recordedVoiceSrc: null,
      load_save_record: false,
      languages: [
        {
          id: 'eng',
          name: 'English',
          shortName: 'EN',
          flagSrc: require('@/assets/images/icons/ic_flag_uk.svg'),
        },
        {
          id: 'idn',
          name: 'Indonesia',
          shortName: 'ID',
          flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
        },
      ],
      selectedLanguage: 'eng',
      suggestionScript: {
        id_dataset_suggestion : null,
        text : null,
      },
    };
  },
  watch: {
    selectedLanguage: {
      handler(newLanguage) {
        this.handleGetScriptSuggestion(newLanguage);
      },
      immediate: true,
    },
  },
  computed: {
    backButtonTitle() {
      if (this.activePage === 'instructions') {
        return 'Back to Workspace';
      } else if (this.activePage === 'instructions-guide') {
        if (this.activePageBeforeGuide === 'record-room') {
          return 'Back to Record Room';
        }
        if (this.activePageBeforeGuide === 'finish') {
          return 'Back to Finish';
        }
      } else if (this.activePage === 'record-room') {
        return 'Back to Instructions';
      }
      // else if (this.activePage === 'input-script') {
      //   return 'Back to Record Room';
      // }
      // else if (this.activePage === 'finish') {
      //   return 'Back to Input Script';
      // }

      return 'Back';
    },
  },
  methods: {
    handleClickBackButton() {
      if (this.activePage === 'instructions') {
        this.$router.back();
      } else if (this.activePage === 'instructions-guide') {
        this.activePage = this.activePageBeforeGuide;
      } else if (this.activePage === 'record-room') {
        this.activePage = 'instructions';
      }
      // else if (this.activePage === 'input-script') {
      //   this.activePage = 'record-room';
      // }
      // else if (this.activePage === 'finish') {
      //   this.activePage = 'input-script';
      // }
    },
    handleClickContinueInstructions() {
      if (this.activePage === 'instructions') {
        this.activePage = 'record-room';
      } else if (this.activePage === 'instructions-guide') {
        this.activePage = this.activePageBeforeGuide;
      }
    },
    async handleSave(name) {
      this.load_save_record = true
      const recordedVoiceDuration = await getBlobDuration(this.recordedVoiceSrc);
      const recordedVoice = {
        src: this.recordedVoiceSrc,
        duration: Math.round(recordedVoiceDuration),
        name
      };

      sessionStorage.setItem('ttv::recorded-voice', JSON.stringify(recordedVoice));
      this.convertToBase64(name)
    },
    // async handleSubmit() {
    //   const recordedVoiceDuration = await getBlobDuration(this.recordedVoiceSrc);
    //   const recordedVoice = {
    //     src: this.recordedVoiceSrc,
    //     duration: Math.round(recordedVoiceDuration),
    //   };

    //   sessionStorage.setItem('ttv::recorded-voice', JSON.stringify(recordedVoice));

    //   this.$router.back();
    // },
    handleDownload() {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = this.recordedVoiceSrc;
      a.download = 'TTV Recorded Voice - Soca AI.mp3';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleGetScriptSuggestion(lang) {
      getSVM(lang)
        .then((response) => {
          this.suggestionScript.text = response.data.suggest;
          this.suggestionScript.id_dataset_suggestion = response.data._id.$oid
        });
    },
    async convertToBase64(name) {
      try {
        const response = await fetch(this.recordedVoiceSrc);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result;
          this.generateVm(base64Data, name)
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error converting audio to base64:', error);
      }
    },

    generateVm(url, name){
      let formData = {
        id_dataset_suggestion : this.suggestionScript.id_dataset_suggestion,
        lang : this.selectedLanguage,
        character_name : name,
        base64_mp3 : url
      }
      generateVoiceModel(formData)
      .then((response) => {
        if(response.status == 200) {
          this.load_save_record = false
          this.$bvModal.hide('ttv-save-recorded-voice');
          this.$bvModal.show('ttv-successfully-record');
        } else {
          this.load_save_record = false
        }
      })
    }
  },
};
</script>

<style scoped>
.rv {
  height: 100vh;
}

.rv__header {
  min-height: 70px;
  gap: 16px;
  border-bottom: 1px solid #2D2D2D;
}

.rv__instructions-button {
  gap: 8px;
  background-color: transparent;
  border: 2px solid #FFFFFF;
  font-weight: 500;
  transition: background-color 0.25s;
}

.rv__instructions-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.rv__main {
  min-height: 0;
  overflow-y: auto;
}

.rv__main > *:first-child {
  margin-top: auto;
}

.rv__main > *:last-child {
  margin-bottom: auto;
}
</style>
