import axios from "../../config/axios"
import apiRoute from "../api.route";

export async function postContents(formData) {
    return axios.post(apiRoute.contents, formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getDataContent(id) {
    return axios.get(apiRoute.contents + '/detail/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function updateContent(formData, id){
    return axios.put(apiRoute.contents + '/update/' + id, formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function postImageLibrary(formData) {
    return axios.post(apiRoute.contents + "/user-image-library", formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getImageLibrary() {
    return axios.get(apiRoute.contents + '/user-image-library')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function deleteImageLibraryById(id) {
    return axios.delete(apiRoute.contents + '/user-image-library/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function deleteContents(id) {
    return axios.delete(apiRoute.contents + '/delete/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getAudioLibrary() {
    return axios.get(apiRoute.contents + '/user-voice-library')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function postAudioLibrary(formData) {
    return axios.post(apiRoute.contents + "/user-voice-library", formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function deleteAudioLibraryById(id) {
    return axios.delete(apiRoute.contents + '/user-voice-library/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getShareContent(id) {
    return axios.get(apiRoute.contents + '/share/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}