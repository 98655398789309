<template>
	<div class="ma--assets py-3 pl-3 w-100">
		<label :for="`${_uid}__input-search`" class="ma--assets-search">
			<input type="text" :for="`${_uid}__input-search`" class="search-bar" :placeholder="$t('find-asset')" v-model="search">
            <b-icon v-if="search != null && search != ''" @click="search = null" class="sb__clear position-absolute cursor-pointer" icon="x"></b-icon>
		</label>
		<div v-if="!isDetail" class="maa__header d-flex align-items-center mt-3">
			<div class="maa__header-tab d-flex">
				<button :disabled="uploading" v-for="tab in tabs" :key="tab" class="maa__header--button btn" :class="{ 'maa__header--button-active' : tab === activeTab}" @click="activeTab = tab" :title="'Click to see ' + tab">{{ tab.toUpperCase() }}</button>
			</div>
			<div class="uap d-flex align-items-center ml-auto">
				<button class="uap--button-upload btn mr-3" type="button" :disabled="uploading" @click="openUserDirectory">{{ $t('uploud') }}</button>
				<button :disabled="uploading" class="ml-2 btn uap--button-display" type="button" title="Display Bars" @click="view = 'tabs'">
					<img v-if="view == 'grid'" src="@/assets/images/icons/ic_bars-nactive.svg" alt="Bars">
					<img v-else src="@/assets/images/icons/ic_bars-active.svg" alt="Bars">
				</button>
				<button :disabled="uploading" class="ml-1 btn uap--button-display" type="button" title="Display Grid" @click="view = 'grid'">
					<img v-if="view == 'grid'" src="@/assets/images/icons/ic_grid-active.svg" alt="Bars">
					<img v-else src="@/assets/images/icons/ic_grid-nactive.svg" alt="Bars">
				</button>
			</div>
		</div>
		<main>
			<template v-if="!uploading">
				<div v-if="isDetail" class="is__detail d-flex align-items-center mt-3 mb-1">
					<button class="is__detail--button btn p-0" @click="$emit('change:my-asset')">
						<font-awesome-icon
							icon="fa-solid fa-chevron-left"
						/>
						{{ folderName }}
					</button>
				</div>
				<template v-if="view == 'tabs'">
					<div class="overflow-auto mt-3" :class="{ 'ma--view-tabs' : dataTable.length > 0 && !isDetail, 'ma--view-tabs-detail' : dataTable.length > 0 && isDetail }">
						<Table :select-all="selectAll" :activeTabs="activeTab" :items="dataTable" :fields="fields" :selected-data="selectedImage" @click:row-table="handleRow" @click:open-folder="(val) => $emit('click:open-folder', val)" @click:download-all-image="(val) => $emit('click:download-all-image', val)" @click:rename-asset="(val) => $emit('click:rename-asset', val)" @update:select-all="(val) => $emit('update:select-all', val)" @click:delete-asset="(val) => $emit('click:delete-asset', val)"/>
					</div>
				</template>
				<template v-else>
					<div v-if="activeTab == $t('all') || activeTab == $t('image')" class="d-flex align-items-start mt-4 mb-3">
						<vs-checkbox color="#6D75F6" v-model="isAll" class="text-left folder__lsa">Select All</vs-checkbox>
					</div>
					<div class="overflow-auto mt-2 pb-2" :class="{ 'ma--view-grid' : dataTable.length > 0 && !isDetail, 'ma--view-grid-detail' : dataTable.length > 0 && isDetail, 'mt-4 ma--view-grid-wsa' : dataTable.length > 0 && activeTab != $t('all') && activeTab != $t('image') }">
						<div v-for="(data, index) in dataTable" :key="index" class="card vg__card position-relative" :class="{ 'vg__card--choosed' : selectedImage?.id === data.id || data.selected }">
							<img v-if="data.file_type == 'video'" :src="data.video_thumbnail" :alt="data.name" class="img__vg cursor-pointer" @click="$emit('click:new-image', data)">
							<div v-else-if="data.file_type == 'file_group'" class="folder__vg d-flex align-items-center justify-content-center cursor-pointer" @click="$emit('click:new-image', data)">
								<img src="@/assets/images/icons/ic_stack-file.svg" alt="Folder" class="folder__icon">
							</div>
							<img v-else :src="data.src" :alt="data.name" class="img__vg cursor-pointer" @click="$emit('click:new-image', data)">
							<div v-if="data.file_type != 'file_group' && data.file_type != 'video'" class="cb__select position-absolute">
								<vs-checkbox color="#6D75F6" v-model="data.selected"></vs-checkbox>
							</div>
							<div class="card-body p-2">
								<div class="d-flex align-items-center justify-content-between">
									<p class="card__title m-0 text-truncate" :title="data.name">{{ data.name }}</p>
									<div class="dropdown">
										<button class="btn p-0 d-flex align-items-start" type="button" :id="`${_uid}__dropdown`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<b-icon
												icon="three-dots-vertical"
												style="color: #FFFFFF"
												font-scale="0.8"
											/>
										</button>
										<div class="dropdown-menu">
											<a v-if="data.file_type == 'file_group'" class="dropdown-item cursor-pointer" @click="$emit('click:open-folder', { id : data.id , name : data.name })">{{ $t('open') }}</a>
											<a class="dropdown-item cursor-pointer" @click="downloadImage(data.id, data.src, data.name, data.file_type)">{{ $t('download') }}</a>
											<a v-if="data.file_type != 'file_group'" class="dropdown-item cursor-pointer" @click="$emit('click:rename-asset', { id : data.id , name : data.name })">{{ $t('change-name') }}</a>
											<a class="dropdown-item cursor-pointer" @click="$emit('click:delete-asset', { id : data.id, file_type : data.file_type })">{{ $t('delete') }}</a>
										</div>
									</div>
								</div>
								<div class="d-flex aling-items-center justify-content-between mt-1">
									<p v-if="data.file_type != 'file_group'" class="m-0 card__text-detail">{{ data.file_size ? convertToBytes(data.file_size) : '-' }}</p>
									<p v-else class="m-0 card__text-detail">{{ data.total_file ? data.total_file : '-' }} files</p>
									<p class="m-0 card__text-detail">{{ getDate(data.createdAt) }}</p>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-if="myassets.length == 0 && !search">
					<div class="nay d-flex flex-column align-items-center justify-content-center mt-3">
						<p class="nay--text my-3">{{ $t('no-assets') }}</p>
						<button class="nay--button-upload btn" :disabled="uploading" @click="openUserDirectory">{{ $t('uploud-now') }}</button>
						<span class="nay--text-2 mb-3">Format file : .jpg, .png {{ $t('up-to') }} 5MB, .mp4 {{ $t('up-to') }} 20MB</span>
					</div>
				</template>
				<template v-if="dataTable.length == 0 && search">
					<div class="mt-4 w-100">
						<p class="text--empty m-0 text-center">No asset found</p>
					</div>
				</template>
			</template>
			<template v-else>
				<div class="loading__section d-flex flex-column align-items-center justify-content-center mt-3">
					<vue-lottie
                        ref="lottieAnimation"
                        :options="animationOptions"
                        :height="150" :width="150"
                    ></vue-lottie>
                    <h5 class="font-weight-normal text-muted mt-1"><i>{{ $t('uplouding') }}</i></h5>
				</div>
			</template>
			<input ref="myAssetUpload" type="file" style="display: none;" @change="handleFileSelect" accept=".jpg, .png, .mp4">
		</main>
	</div>
</template>

<script>
import Table from './Table.vue'
import moment from 'moment';
import VueLottie from 'vue-lottie';
import loadingAnimation from '@/assets/soca-load.json';

import { exportUtils } from "@/mixins/ExportUtils";
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
	mixins:[AlertUtils, exportUtils],
	components: {
		Table,
		VueLottie
	},
	props : {
		image: {
			type: Object,
			default: null,
		},
		uploading: {
			type: Boolean,
			default: false,
		},
		isDetail: {
			type: Boolean,
			default: false,
		},
		selectAll: {
			type: Boolean,
			defalt: false
		},
		folderName: {
			type: String,
			default: null
		},
		assets: {
			type: Array,
			default: () => {
				return []
			}
		},
		detailAssets: {
			type: Array,
			default: () => {
				return []
			}
		},
		activeTabs: {
			type: String,
			// validator(value) {
			// 	return ['all', 'image', 'video', 'files'].includes(value);
			// },
			default: 'all',
		},
	},

	computed: {
		dataTable() {
			if(this.isDetail) {
				if(this.search) {
					return this.detailAssetsFolder.filter((item) => {
						return this.search.toLowerCase().split(" ").every((v) => item.name.toLowerCase().includes(v));
					})
				} else {
					return this.detailAssetsFolder
				}
			} else {
				if(this.search) {
					return this.myassets.filter((item) => {
						return this.search.toLowerCase().split(" ").every((v) => item.name.toLowerCase().includes(v));
					})
				} else {
					return this.myassets
				}
			}
		}
	},

	data() {
		return {
			activeTab : this.activeTabs,
			tabs: [this.$t('all'), this.$t('image'), 'video', this.$t('files')],

			myassets: this.assets,
			detailAssetsFolder: this.detailAssets,
			fields: [
				{
					key:'selectAll',
					label: '',
					class: 'px-0'
				},
				{
					key : 'name',
					label : this.$t('name'),
					class : 'pl-0'
				},
				{
					key : 'file_type',
					label : this.$t('type'),
					class: 'text-center'
				},
				{
					key : 'file_size',
					label : this.$t('size'),
					class: 'text-center',
					sortable: true
				},
				{
					key : 'createdAt',
					label : this.$t('created'),
					class: 'text-center',
					sortable: true
				},
				{
					key : 'action',
					label : '',
				},
			],

			view: 'tabs',

			selectedImage: this.image,

			search: null,

			isAll: this.selectAll,

			animationOptions: {
                animationData: loadingAnimation,
                loop: true,
                autoplay: true,
                scale: 0.1,
            },
		}
	},

	watch: {
		image: {
			handler(newVal) {
				this.selectedImage = newVal
			},
			deep: true
		},

		selectAll(newVal) {
			this.isAll = newVal
		},

		isAll(newVal) {
			this.$emit('update:select-all', newVal)
		},

		assets(newVal) {
			this.myassets = newVal
		},

		detailAssets(newVal) {
			this.detailAssetsFolder = newVal
		},

		activeTabs(newVal) {
			this.activeTab = newVal
		},

		activeTab(newVal) {
			console.log("HITTED!");
			console.log(newVal)
			this.$emit('update:active-tab', newVal)
		},
	},

	methods: {
		async downloadImage(id, url, name, type) {
			try {
				const response = await fetch(url)

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const blobImage = await response.blob();

				if(type == 'video') {
					this.exportVidio(blobImage, name ? name : 'Video')
				} else if(type == 'file_group') {
					this.$emit('click:download-all-image', { id, name })
				} else {
					this.exportImage(blobImage, name ? name : 'Image')
				}
				if( type != 'file_group' ) { this.alertSuccess(`${type == 'video' ? 'Video' : 'Image'} successfully downloaded`) }
			} catch (error) {
				console.error('Error during fetch:', error);
				this.alertFail("Failed to download image.")
			}
		},
		handleRow(val) {
			if(val) {
				this.$emit('click:new-image', val)
			}
		},
		openUserDirectory() {
			this.$refs.myAssetUpload.click();
		},
		handleFileSelect(event) {
			const selectedFiles = event.target.files;
			const allowedFileTypes = ['image', 'video'];
			const maxSizeInBytes = 25 * 1024 * 1024; // 25MB
			const maxSizeInBytesImage = 5 * 1024 * 1024; // 5MB
			const maxSizeInBytesVideo = 20 * 1024 * 1024; // 20MB

			for (let i = 0; i < selectedFiles.length; i++) {
				const file = selectedFiles[i];
				const fileType = file.type.split('/')[0]; // jenis file berdasarkan tipe MIME
				const fileSize = file.size;

				if (!allowedFileTypes.includes(fileType)) {
					this.alertFailIcon('File Extension not allowed');
				} else if (fileType === 'image' && fileSize > maxSizeInBytesImage) {
					this.alertFailIcon('Image size must less than 5MB');
				} else if (fileType === 'video' && fileSize > maxSizeInBytesVideo) {
					this.alertFailIcon('Video size must less than 20MB');
				} else if (fileSize > maxSizeInBytes) {
					this.alertFailIcon('File size must less than 25MB');
				} else {
					console.log(fileSize, maxSizeInBytesVideo)
					if (fileType === 'video') {
						this.$emit('click:upload-video', file);
					} else {
						this.$emit('click:upload-image', file);
					}
				}
			}
		},
		convertToBytes(num) {
			const number = parseInt(num);
			const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			let unitIndex = 0;
			let size = number;

			while (size >= 1024 && unitIndex < units.length - 1) {
				size /= 1024;
				unitIndex++;
			}

			return `${size.toFixed(2)} ${units[unitIndex]}`;
		},
		getDate(item){
			const date = moment(item);
			let now = moment();
			now = now.add(420, 'minutes')
			const diff = now.diff(date, 'seconds');

			if (diff < 1) {
				return this.$t('a-few-secs-ago');
			} else if (diff < 60) {
				return diff + ' ' + this.$t('secs-ago');
			} else if (diff < 3600) {
				const minutes = Math.floor(diff / 60);
				return minutes + ' ' + this.$t('minutes-ago');
			} else if (diff < 86400) {
				const hours = Math.floor(diff / 3600);
				return hours + ' ' + this.$t('hours-ago');
			} else if (diff < 604800) {
				const days = Math.floor(diff / 86400);
				return days + ' ' + this.$t('days-ago');
			} else if (diff < 2592000) {
				const weeks = Math.floor(diff / 604800);
				return weeks + ' ' + this.$t('weeks-ago');
			} else if (diff < 31536000) {
				const months = Math.floor(diff / 2592000);
				return months + ' ' + this.$t('months-ago');
			} else {
				const years = Math.floor(diff / 31536000);
				return years + ' ' + this.$t('years-ago');
			}
		},
	}
}
</script>

<style scoped>
.ma--assets-search {
	z-index: 2;
	position: relative;
	width: 100%;
	height: 40px;
	border-radius: 25px !important;
}

.ma--assets-search::before {
	content: "";
	position: absolute;
	top: 10px;
	bottom: 0;
	left: 1rem;
	width: 40px;
	background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
	background-size: 20px;
}

.search-bar {
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding-left: 48px !important;
  padding-right: 20px;
  background-color: transparent;
  border-radius: 25px;
  border: 1px solid #2D2D2D;
  color: white;
}

.sb__clear {
	top: 17px;
    right: 19px;
    color: #858585;
}

.maa__header--button {
	font-weight: 400;
	font-size: 12px;
	color: #8C8C8C;
	border: 1px solid #2D2D2D;
	padding: 6px 25px;
	border-radius: 25px;

	transition: background-color 0.25s, border 0.25s;
}

.maa__header--button-active {
	color: white;
	background-color: #6D75F6;
	border: 1px solid #6D75F6;
}

.maa__header--button:not(.maa__header--button-active):hover {
	background-color: #585859;
}

.maa__header-tab {
	gap: 6px;
}

.uap--button-upload {
	background-color: #6D75F6;
	color: white;
	font-size: 12px;
	padding: 6px 18px;
	border: none;

	transition: background-color 0.25s
}

.uap--button-upload:hover {
	background-color: #545EED;
}

.uap--button-display {
	padding: 0;
}

.nay {
	height: 12rem;
	background-color: #2D2D2D;
	gap: 6px;
}

.nay--text {
	color: #8C8C8C;
	font-size: 14px;
}

.nay--button-upload {
	box-shadow: none;
	border: none;
	color: #6D75F6;
	font-size: 16px;
	font-weight: 600;
	padding: 0;

	transition: color 0.25s;
}

.nay--button-upload:hover {
	color: #545EED;
}

.nay--text-2 {
	font-size: 8px;
	color: #8C8C8C;
}

.ma--view-grid, .ma--view-grid-detail {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 0.6rem;
}

.ma--view-grid, .loading__section {
	height: 20rem;
}

.ma--view-tabs {
	height: 22.8rem;
}

.ma--view-tabs-detail {
	height: 24rem;
}

.ma--view-grid-detail {
	height: 21.2rem;
}

.ma--view-grid-wsa {
	height: 22rem;
}

.loading__section {
	background-color: #2d2d2d;
}

.vg__card {
	background-color: #2D2D2D;
	border-radius: 8px;
	max-width: 165px;
	width: 100%;
	height: fit-content;
}

.vg__card--choosed {
	border: 2px solid #6D75F6;
}

.card__title {
	font-size: 10px;
}

.card__text-detail {
	font-size: 8px;
	font-weight: 400;
	color: #8c8c8c;
}

.img__vg, .folder__vg {
	width: 100%;
	height: 110px;
	border-radius: 6px 6px 0 0;
}

.folder__vg {
	background-color: #585859;
}

.folder__icon {
	width: 52px;
}

.is__detail {
	gap: 6px;
}

.is__detail--button {
	color: white;
}

.is__detail--button, .is__detail--text {
	font-size: 12px;
}

.is__detail--text {
	color: #585859;
}

.ma {
	gap: 6px;
}

.ma__text {
	font-size: 10px;
	color: #8c8c8c;
	margin: 0;
}

.text--empty {
	font-size: 12px;
}

.cb__select {
	top: 10px;
	left: 6px;
}

.folder__lsa {
	font-size: 12px;
}
</style>

<style>
.vg__card .dropdown-menu {
	background: #2D2D2D;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
	border-radius: 6px;
	color: #fff;
	min-width: 140px !important;
	max-width: 140px !important;
	font-size: 12px;
}

.vg__card .dropdown-item:hover {
	background-color: #8C8C8C;
}
</style>