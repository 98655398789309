<template>
  <ul class="ts d-flex flex-column justify-content-center align-items-center px-4 m-0 list-unstyled">
    <div class="m-auto pt-5">
      <li ref="materialItem" class="position-relative">
        <button
          type="button"
          class="ts__button btn d-flex justify-content-center align-items-center"
          :class="{ 'ts__button--active': isMaterialListShown }"
          :disabled="materialsDisableStatus.action"
          title="Add Materials"
          @click="isMaterialListShown = !isMaterialListShown"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />
        </button>
        <div
          class="ts__material-button-list-wrapper position-absolute d-flex flex-column"
          :class="{ 'ts__material-button-list-wrapper--show': isMaterialListShown }"
        >
          <!-- <h3 class="ts__material-title">Add materials</h3> -->
          <ul class="ts__material-button-list list-unstyled">
            <li class="ts__material-button-text-item">
              <button
                type="button"
                class="ts__material-button btn d-flex flex-column justify-content-center align-items-center"
                title="Text"
                @click="$emit('click-material-text')"
              >
                <img src="@/assets/images/icons/ic_material_text.svg" alt="" height="24">
                Text
              </button>
            </li>
            <li>
              <button
                type="button"
                class="ts__material-button btn d-flex flex-column justify-content-center align-items-center"
                title="Image"
                :disabled="materialsDisableStatus.image"
                @click="$emit('click-material-image')"
              >
                <img src="@/assets/images/icons/ic_material_image.svg" alt="" height="24">
                Image
              </button>
            </li>
            <li>
              <button
                type="button"
                class="ts__material-button btn d-flex flex-column justify-content-center align-items-center"
                title="YouTube"
                :disabled="materialsDisableStatus.youtube"
                @click="$emit('click-material-youtube')"
              >
                <img src="@/assets/images/icons/ic_material_youtube.svg" alt="" height="24">
                YouTube
              </button>
            </li>
            <li>
              <button
                type="button"
                class="ts__material-button btn d-flex flex-column justify-content-center align-items-center"
                title="Audio"
                :disabled="materialsDisableStatus.audio"
                @click="$emit('click-material-audio')"
              >
                <img src="@/assets/images/icons/ic_material_audio.svg" alt="" height="24">
                Audio
              </button>
            </li>
            <li>
              <button
                type="button"
                class="ts__material-button btn d-flex flex-column justify-content-center align-items-center"
                title="Equation"
                :disabled="materialsDisableStatus.equation"
                @click="$emit('click-material-equation')"
              >
                <img src="@/assets/images/icons/ic_material_equation.svg" alt="" height="24">
                Equation
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="my-3">
        <button
          type="button"
          class="ts__button btn d-flex justify-content-center align-items-center"
          :class="{ 'ts__button--active': isQuestionsGeneratorShown }"
          title="Questions Generator"
          @click="toggleQuestionsGenerator"
          :disabled="materialsDisableStatus.ai"
        >
          <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles" />
        </button>
      </li>
      <li ref="quizTemplate" class="position-relative">
        <button
          type="button"
          class="ts__button btn d-flex justify-content-center align-items-center"
          :class="{ 'ts__button--active': isQuizTemplatesShown }"
          title="Quiz Template"
          @click="isQuizTemplatesShown = !isQuizTemplatesShown"
        >
        <QuizTemplateActiveIcon v-show="isQuizTemplatesShown" />
        <QuizTemplateIcon v-show="!isQuizTemplatesShown" />
        </button>
        <!-- tips -->
        <div ref="tipsTemplate" class="tips position-absolute" :class="{'tips__show': isTipsQuizTemplateShown}">
          <div class="d-flex align-items-center justify-content-end">
            <TipsIcon class="tips__lamp-icons"/>
            <font-awesome-icon icon="fa-solid fa-xmark" class="cursor-pointer" style="color: #585859;" @click="handleCloseTips" />
          </div>
          <p class="tips__content">If you are confused about what quiz you want to make, let's try using a template from other creators</p>
        </div>
        <!-- end-tips -->
        <!-- QUIZ-TEMPLATE -->
        <div
          class="ts__quiz-template-list-wrapper position-absolute d-flex flex-column"
          :class="{ 'ts__quiz-template-list-wrapper--show': isQuizTemplatesShown }"
        >
          <h3 class="ts__quiz-template-title">Quiz Templates</h3>
          <label for="search" id="search-bar" class="ts__search-wrapper">
            <input
              type="text"
              class="search-bar"
              placeholder="Find a templates"
              v-model="filterQuiz"
            />
            <b-icon v-show="filterQuiz != null && filterQuiz != ''" @click="filterQuiz = null" class="sb__clear position-absolute cursor-pointer" icon="x"></b-icon>
          </label>
          <div class="t__inner t__inner--hide-shadow h-100 overflow-auto" ref="scrollableList" @scroll="handleGradientVisibility" :class="{'t__inner--show-bottom-shadow': showBottomShadow}" v-if="filterQuiz == null || filterQuiz == ''">
            <div class="t__row">
              <div v-for="(quiz, index) in list_quiz" :key="index" class="mb-2 t__col position-relative">
              <div class="card h-100 cursor-pointer">
                <div class="ct__character-button ct__character-button--selectable p-0">
                  <img :src=quiz.images_cover alt="" v-if="quiz.images_cover != null && quiz.images_cover != 'undefined' && quiz.images_cover != 'null'" class="card-img-overflow-y img-fluid ct__character-button-image" >
                  <img src="@/assets/images/default-cover.png" class="card-img-overflow-y ct__character-button-image" v-else>
                  <div class="ct__cbt--wrapper btn" @click="duplicateQuiz(quiz._id, quiz.author_quiz)">
                    <span class="ct__character-button-text">Duplicate</span>
                  </div>
                </div>
                <div class="card-body pb-2 px-3 text-left">
                  <h5 class="card-title" title="Quiz Matematika">{{ quiz.quiz_name }}</h5>
                  <div class="d-flex justify-content-between align-items-center desc-card">
                    <div class="d-flex align-items-center ts__quiz-template-action">
                      <PlayLightIcon />
                      <p class="mb-0 mr-2">{{quiz.total_play}}</p>
                      <QuestionsIcon />
                      <p class="mb-0">{{ quiz.total_question }}</p>
                    </div>
                    <div class="d-flex align-items-center ts__quiz-template-action">
                      <StarActiveIcon v-if="quiz.favorite" />
                      <StarIcon v-else/>
                      <p class="mb-0" style="margin-top: 0.1rem !important;">{{ quiz.score ? quiz.score : 0 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <button v-if="!loadingScrollQuizTemplate" class="h__button-scroll btn d-flex align-items-center mx-auto" @click="openLibrary()">
              <p class="m-0">Explore more templates!</p>
              <font-awesome-icon icon="fa-solid fa-arrow-right" />
            </button>
            <div v-if="loadingScrollQuizTemplate" class="d-flex align-items-center justify-content-center loading__style h-100">
                <div class="spinner-border spinner-border-sm m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-if="list_quiz.length == 0 && !loadingScrollQuizTemplate" class="d-flex align-items-center justify-content-center loading__style h-100">
                <!-- <p class="m-0">Sorry, we do not have what you are looking for.</p> -->
                <p class="m-0">Quiz your looking for is not found.</p>
            </div>
          </div>

          <div class="t__inner t__inner--hide-shadow h-100 overflow-auto" ref="scrollableList" @scroll="handleGradientVisibility" :class="{'t__inner--show-top-shadow': showTopShadow, 't__inner--show-bottom-shadow': showBottomShadow}" v-else>
            <div class="t__row">
              <div v-for="(quiz, index) in searchQuiz" :key="index" class="mb-2 t__col position-relative">
              <div class="card h-100 cursor-pointer">
                <div class="ct__character-button ct__character-button--selectable p-0">
                  <img :src=quiz.images_cover alt="" v-if="quiz.images_cover != null && quiz.images_cover != 'undefined' && quiz.images_cover != 'null'" class="card-img-overflow-y img-fluid ct__character-button-image" >
                  <img src="@/assets/images/default-cover.png" class="card-img-overflow-y ct__character-button-image" v-else>
                  <div class="ct__cbt--wrapper btn" @click="duplicateQuiz(quiz._id, quiz.author_quiz)">
                    <span class="ct__character-button-text">Duplicate</span>
                  </div>
                </div>
                <div class="card-body pb-2 px-3 text-left">
                  <h5 class="card-title" title="Quiz Matematika">{{ quiz.quiz_name }}</h5>
                  <div class="d-flex justify-content-between align-items-center desc-card">
                    <div class="d-flex align-items-center ts__quiz-template-action">
                      <PlayLightIcon />
                      <p class="mb-0 mr-2">{{quiz.total_play}}</p>
                      <QuestionsIcon />
                      <p class="mb-0">{{ quiz.total_question }}</p>
                    </div>
                    <div class="d-flex align-items-center ts__quiz-template-action">
                      <StarActiveIcon v-if="quiz.favorite" />
                      <StarIcon v-else/>
                      <p class="mb-0" style="margin-top: 0.1rem !important;">{{ quiz.score ? quiz.score : 0 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <button v-if="!loadingScrollQuizTemplate" class="h__button-scroll btn d-flex align-items-center mx-auto" @click="$router.push({ name: 'Library' })">
              <p class="m-0">Explore more templates!</p>
              <font-awesome-icon icon="fa-solid fa-arrow-right" />
            </button>
            <div v-if="loadingScrollQuizTemplate" class="d-flex align-items-center justify-content-center loading__style h-100">
                <div class="spinner-border spinner-border-sm m-5 h" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-if="searchQuiz.length == 0" class="d-flex align-items-center justify-content-center loading__style h-100">
                <!-- <p class="m-0">Sorry, we do not have what you are looking for.</p> -->
                <p class="m-0">Quiz your looking for is not found.</p>
            </div>
          </div>

        </div>

        <!-- END-QUIZ-TEMPLATE -->
      </li>
    </div>
    <li ref="helpItem" class="position-relative mt-auto pb-3">
      <!-- <button
        type="button"
        class="ts__button btn d-flex justify-content-center align-items-center"
        :class="{ 'ts__button--active': isHelpListShown }"
        :disabled="materialsDisableStatus.action"
        title="Helps"
        @click="isHelpListShown = !isHelpListShown"
      >
        <font-awesome-icon icon="fa-solid fa-question" />
      </button> -->
      <ul
        class="ts__help-button-list position-absolute d-flex flex-column list-unstyled"
        :class="{ 'ts__help-button-list--show': isHelpListShown }"
      >
        <li>
          <button
            type="button"
            class="ts__help-button btn d-flex align-items-center text-nowrap"
            title="Getting started guide"
          >
            <img src="@/assets/images/icons/ic_bulb.svg" alt="" height="10" />
            <span>Getting started guide</span>
          </button>
        </li>
        <!-- <li>
          <button
            type="button"
            class="ts__help-button btn d-flex align-items-center"
            title="Chat with agent"
            @click="chatWithAgent()"
          >
            <img src="@/assets/images/icons/ic_whatsapp.svg" alt="" height="10" />
            <span>Chat with agent</span>
          </button>
        </li> -->
        <li>
          <button
            type="button"
            class="ts__help-button btn d-flex align-items-center"
            title="Frequently Asked Questions"
          >
            <img src="@/assets/images/icons/ic_faq.svg" alt="" height="10" />
            <span>FAQ</span>
          </button>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { QuizTemplateActiveIcon, QuizTemplateIcon, TipsIcon, PlayLightIcon, QuestionsIcon, StarIcon, StarActiveIcon} from '@/assets/images/icons/components/index'
import { getQuizTemplate, getQuizById } from "@/services/quiz/quiz.service";
import { getQuizCreatorById } from '@/services/quiz-creator/quiz-creator.service'
import { getQuestions } from '@/services/question/question.service'
import { getQuestionCreator } from '@/services/question-creator/question_creator.service'
import { base_url_home } from '@/config/base_url'

export default {
  components: {
    QuizTemplateActiveIcon,
    QuizTemplateIcon,
    TipsIcon,
    PlayLightIcon,
    QuestionsIcon,
    StarIcon,
    StarActiveIcon
  },
  props: {
    materialsDisableStatus: {
      type: Object,
      required: true,
    },
    showQuestionsGenerator: {
      type: Boolean,
      default: true,
    },
    showTipsQuizTemplate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click-material-text', 'click-material-equation', 'toggle-questions-generator', 'click-material-image', 'click-material-youtube', 'click-material-audio', 'toggle-quiz-templates', 'render:quiz', 'show:loading-quiz'],
  data() {
    return {
      scrollPosition: 0,
      previousValueScroll: null,
      showTopShadow: false,
			showBottomShadow: true,
      isMaterialListShown: false,
      isQuestionsGeneratorShown: this.showQuestionsGenerator,
      isQuizTemplatesShown: false,
      isHelpListShown: false,
      isTipsQuizTemplateShown: false,
      loadingQuizTemplate: false,
      loadingScrollQuizTemplate: false,
      filterQuiz: null,
      pageQuiz: 1,
      perPageQuiz: 10,
      list_quiz: [],
      dataQuiz: [],
      list_quiz_concat_all: [],
      list_questions: []
    };
  },
  computed: {
    searchQuiz(){
      if (this.filterQuiz) {
          return this.list_quiz.filter((item) => {

            return this.filterQuiz.toLowerCase().split(" ").every((v) => item.quiz_name.toLowerCase().includes(v) || (item.author && item.author.toLowerCase().includes(v)) || (item.creator_name && item.creator_name.toLowerCase().includes(v)));
        });
      } else {
          return this.list_quiz;
      }
    },
  },
  methods: {
    getQuiz(keyword = ''){
      this.loadingScrollQuizTemplate = true;

      var data = {
          page: this.pageQuiz,
          limit: this.perPageQuiz,
      }
      getQuizTemplate(data, keyword || '')
      .then((response) => {
          if(response.status){
              this.list_quiz = response.data
              this.list_quiz_concat_all = this.list_quiz_concat_all.concat(this.list_quiz)
              this.pageQuiz = this.pageQuiz + 1;
              this.loadingScrollQuizTemplate = false;
              this.loadingQuizTemplate = false;
          } else {
            this.list_quiz = []
            this.loadingQuizTemplate = false;
              this.loadingScrollQuizTemplate = false;
          }
      })
    },
    duplicateQuiz(id, author){
      this.isQuizTemplatesShown = false;
      this.$emit('show:loading-quiz', true);
      this.getDetailQuiz(id, author);
    },

    getDetailQuiz(id, author){
      if(author == 0){
      getQuizById(id).then((response) =>{
          if(response.status == true){
            this.dataQuiz = response.data[0]
            this.getQuestions(id, author);
          }
        }).catch(error => {
          console.log(error)})
        } else {
          getQuizCreatorById(id).then((response) => {
            if(response.status == true){
              this.dataQuiz = response.data[0]
              this.getQuestions(id, author);
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    getQuestions(id, author){
      if(author == 0){
          getQuestions(id).then((response) => {
            if(response.status == true){
              const { data } = response;

              if(!localStorage.quiz_name) {
                localStorage.quiz_name = this.dataQuiz.quiz_name
              }

              if(!localStorage.quiz_description) {
                localStorage.quiz_description = this.dataQuiz.description != undefined ? this.dataQuiz.description : ""
              }

              if(!localStorage.images_cover) {
                localStorage.images_cover = this.dataQuiz.images_cove
              }

              if(!localStorage.category_id) {
                localStorage.category_id = this.dataQuiz.category_id
              }

              if(!localStorage.listQuestionCreate) {
                localStorage.question_active = data.length

                let fixedData = []

                data.forEach((e) => {
                  fixedData.push({
                    _id : e._id,
                    quiz_id : e.quiz_id,
                    question : e.question,
                    question_type : e.question_type,
                    option_1 : e.option_1 || null,
                    option_2 : e.option_2 || null,
                    option_3 : e.option_3 || null,
                    option_4 : e.option_4 || null,
                    option_5 : e.option_5 || null,
                    image_url : e.image_url || null,
                    voice : e.voice || null,
                    video : e.video || null,
                    title : e.title || null,
                    subtitle : e.subtitle,
                    text : e.text,
                    correct_answer : e.correct_answer,
                    duration : e.duration,
                    point : e.point,
                    layout : e.layout,
                    layoutThumbnail : e.layoutThumbnail,
                  })
                })

                localStorage.setItem('listQuestionCreate', JSON.stringify(fixedData));
              } else {
                let questions = JSON.parse(localStorage.getItem('listQuestionCreate'))

                data.forEach((e) => {
                  questions.push({
                    _id : e._id,
                    quiz_id : e.quiz_id,
                    question : e.question,
                    question_type : e.question_type,
                    option_1 : e.option_1 || null,
                    option_2 : e.option_2 || null,
                    option_3 : e.option_3 || null,
                    option_4 : e.option_4 || null,
                    option_5 : e.option_5 || null,
                    image_url : e.image_url || null,
                    voice : e.voice || null,
                    video : e.video || null,
                    title : e.title || null,
                    subtitle : e.subtitle,
                    text : e.text,
                    correct_answer : e.correct_answer,
                    duration : e.duration,
                    point : e.point,
                    layout : e.layout,
                    layoutThumbnail : e.layoutThumbnail,
                  })
                })

                localStorage.question_active = questions.length
                localStorage.setItem('listQuestionCreate', JSON.stringify(questions));
              }

              this.$emit('render:quiz');
              this.$emit('show:loading-quiz', false);
            }
          }).catch((err)=>{
            console.log(err)
          })
      } else {
        getQuestionCreator(id).then((response)=>{
          if(response.status == true){
            const { data } = response;

            if(!localStorage.quiz_name) {
              localStorage.quiz_name = this.dataQuiz.quiz_name
            }

            if(!localStorage.quiz_description) {
              localStorage.quiz_description = this.dataQuiz.description != undefined ? this.dataQuiz.description : ""
            }

            if(!localStorage.images_cover) {
              localStorage.images_cover = this.dataQuiz.images_cove
            }

            if(!localStorage.category_id) {
              localStorage.category_id = this.dataQuiz.category_id
            }

            if(!localStorage.listQuestionCreate) {
              localStorage.question_active = data.length
              localStorage.setItem('listQuestionCreate', JSON.stringify(data));
            } else {
              let questions = JSON.parse(localStorage.getItem('listQuestionCreate'))

              data.forEach((e) => {
                questions.push(e)
              })

              localStorage.question_active = questions.length
              localStorage.setItem('listQuestionCreate', JSON.stringify(questions));
            }

            this.$emit('render:quiz');
            this.$emit('show:loading-quiz', false);
          }
        })
      }
    },
    onScrollQuiz() {
      const scrollPosition = this.$refs.scrollableList.scrollTop;
      const scrollHeight = scrollPosition - this.$refs.scrollableList.scrollHeight
      const clientHeight = scrollPosition - this.$refs.scrollableList.clientHeight
      const positionScroll = Math.ceil(scrollPosition)
      const maxScroll = clientHeight - scrollHeight
      if(maxScroll == positionScroll || maxScroll - 1 == positionScroll){
          this.getQuiz(this.filterQuiz)
      }

    },
    handleGradientVisibility() {
			const { scrollTop, scrollHeight, clientHeight } = this.$refs.scrollableList;

			this.showTopShadow = scrollTop > 0;
			this.showBottomShadow = scrollTop < scrollHeight - clientHeight;
		},
    handleScrollDown(){
			let min = 100;
			let max = 200;

			do {
				this.scrollPosition = Math.floor(Math.random() * (max - min + 1)) + min;
			} while (this.scrollPosition === this.previousValueScroll);
			this.previousValueScroll = this.scrollPosition;
		},
    toggleQuestionsGenerator() {
      this.isQuestionsGeneratorShown = !this.isQuestionsGeneratorShown;
      this.$emit('toggle-questions-generator', this.isQuestionsGeneratorShown);
    },

    handleCloseTips(){
      this.isTipsQuizTemplateShown = false;
    },

    toggleQuizTemplates() {
      this.isQuizTemplatesShown = !this.isQuizTemplatesShown;
      this.$emit('toggle-quiz-templates', this.isQuizTemplatesShown);
    },
    closeMaterialListWhenClickOutside(e) {
      if (this.isMaterialListShown && !this.$refs.materialItem.contains(e.target)) {
        this.isMaterialListShown = false;
      }
    },
    closeQuizTemplateWhenClickOutside(e) {
      if (this.isQuizTemplatesShown && !this.$refs.quizTemplate.contains(e.target)) {
        this.isQuizTemplatesShown = false;
      }
    },
    closeHelpListWhenClickOutside(e) {
      if (this.isHelpListShown && !this.$refs.helpItem.contains(e.target)) {
        this.isHelpListShown = false;
      }
    },
    chatWithAgent(){
      let phoneNumber = "6282126719955"
      let url = "https://wa.me/" + phoneNumber;
      window.open(url, "_blank");
    },
    closeTipsTemplateWhenClickOutside(e) {
      if(this.isTipsQuizTemplateShown && !this.$refs.tipsTemplate.contains(e.target)) {
       this.isTipsQuizTemplateShown = false
      }
    },
    openLibrary() {
      window.open(base_url_home + 'products/library', "_blank");
    },
  },
  mounted() {

    if(this.isQuizTemplatesShown){
      this.isQuizTemplatesShown = false;
    }

    setTimeout(() => {
      this.isTipsQuizTemplateShown = true;
    }, 500);
    document.addEventListener('click', this.closeMaterialListWhenClickOutside);
    document.addEventListener('click', this.closeHelpListWhenClickOutside);
    document.addEventListener('click', this.closeQuizTemplateWhenClickOutside);
    document.addEventListener('click', this.closeTipsTemplateWhenClickOutside);
    this.$emit('toggle-questions-generator', this.isQuestionsGeneratorShown);

    // this.handleGradientVisibility();
		// window.addEventListener('resize',this.handleGradientVisibility);

    this.getQuiz(this.filterQuiz);
    // this.$refs.scrollableList.addEventListener('scroll', () => {
    //   this.onScrollQuiz()
    // }, false);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeMaterialListWhenClickOutside);
    document.removeEventListener('click', this.closeHelpListWhenClickOutside);
    document.removeEventListener('click', this.closeQuizTemplateWhenClickOutside);
    document.removeEventListener('click', this.closeTipsTemplateWhenClickOutside);
    window.removeEventListener('resize',this.handleGradientVisibility);
  },
  watch: {
    isQuizTemplatesShown(val){
      if(val){
        this.isTipsQuizTemplateShown = false;
        this.$emit('toggle-questions-generator', false);
      }
    },
    scrollPosition(newVal) {
			this.scrollPage(newVal)
		},
    showQuestionsGenerator(newStatus) {
      if (newStatus !== this.isQuestionsGeneratorShown) {
        this.isQuestionsGeneratorShown = newStatus;
      }
    },
    isMaterialListShown(value){
      if(value){
        this.isTipsQuizTemplateShown = false;
      }
    },
  },
};
</script>

<style scoped>

.card:hover .ct__cbt--wrapper{
  opacity: 1;
}
.ct__character-button-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
}

.card:hover .ct__character-button-image {
	filter: brightness(70%);
}

.ct__cbt--wrapper {
  position: absolute;
  bottom: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.25s;
  padding: 4px 12px;
  background-color: #6D75F6;
  border-radius: 4px;
}

.search-bar {
  height: 2.5rem;
  width: 100%;
  border-radius: 25px;
  border: 1px solid #585859;
  padding-left: 44px !important;
  background: #2D2D2D;
  padding-right: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  color: white;
  font-size: 12px;
}

.sb__clear {
	top: 13px;
  right: 18px;
  color: #858585;
}

.loading__style{
  /* position: absolute;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s, visibility 0.25s;
  bottom: 0;
  width: 91%;
  height: 53px;
  margin-right: 1rem !important; */
}
.h__button-scroll {
	  position: absolute;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s, visibility 0.25s;
    left: 0;
    right: 0;
    bottom: 1.5rem;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    color: #fff;
    border: 0px solid #fff;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    /* background: rgba(255, 255, 255, 0.10); */
    backdrop-filter: blur(1.8823530673980713px);
    border-radius: 23px;
    width: -moz-fit-content;
    width: fit-content;
    transition: color 0.25s;
}

.h__button-scroll:hover {
  color: #6D75F6;
}

.ts {
  gap: 12px;
  height: 100%;
  border-left: 1px solid #2D2D2D;
}

.ts__button {
  width: 45px;
  height: 45px;
  background-color: #2D2D2D;
  font-size: 20px;
  color: #FFFFFF;
  border-radius: 50%;
  transition: background-color 0.25s, color 0.25s;
}

.ts__button:not(:disabled):hover {
  background-color: #3D3D3D;
}

.ts__button--active {
  color: #6D75F6;
}

.ts__material-button-list-wrapper, .ts__help-button-list, .ts__quiz-template-list-wrapper {
  visibility: hidden;
  opacity: 0;
  right: 100%;
  transform: translateY(-50%) scale(0);

  top: 50%;
  gap: 8px;
  padding: 12px;
  background-color: #1F1F1F;
  border-radius: 10px;
  transform-origin: center right;
  transition: all 0.25s;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}

.ts__search-wrapper {
  z-index: 2;
  position: relative;
  width: 100%;
}

.ts__search-wrapper::before {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 0px;
  bottom: 0;
  width: 40px;
  background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
  background-size: 20px;
}

.t__row {
	display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
}

.t__inner {
	scroll-behavior: smooth;
	transition: scroll-behavior 0.5s;
}

.t__inner::-webkit-scrollbar-thumb {
	display: none;
}

.t__inner::before {
  opacity: 1;
  visibility: visible;
  content: "";
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  height: 25px;
  background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  transition: opacity 0.25s, visibility 0.25s;
}

.t__inner--hide-shadow::before {
	opacity: 0;
  	visibility: hidden;
}

.t__inner::after {
  opacity: 1;
  visibility: visible;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
  transition: opacity 0.25s, visibility 0.25s;
  border-radius: 10px;
}

.t__inner--hide-shadow::after {
	opacity: 0;
  	visibility: hidden;
}

.t__inner::before,
.t__inner::after {
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.t__inner--hide-shadow::before,
.t__inner--hide-shadow::after {
	box-shadow: none;
}

.t__inner--show-top-shadow::before {
	opacity: 1;
	visibility: visible;
	z-index: 2;
}

.t__inner--hide-shadow.t__inner--show-bottom-shadow::after {
	opacity: 1;
	visibility: visible;
}

.t__row .card{
  width: 149px;
  background-color: #2D2D2D !important;
}
.ts__quiz-template-list .card-title{
  font-size: 12px;
}
.ts__quiz-template-title{
  font-size: 14px;
  padding-top: 1rem;
}
.ts__quiz-template-list-wrapper{
  height: 506px;
  width: 337px;
  top: 162%;
}

.tips{
  width: 247px;
  text-align: left;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.40);
}
.tips__lamp-icons{
  position: absolute;
  top: -16px;
  left: -9px;
  /* animations */
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.ts__material-button-list-wrapper--show, .ts__help-button-list--show, .ts__quiz-template-list-wrapper--show {
  visibility: visible;
  opacity: 1;
  right: calc(100% + 12px);
  transform: translateY(-50%) scale(1);
  z-index: 500;
}

.ts__help-button-list--show{
  top: 6% !important;
}

.tips__show{
    visibility: visible !important;
    opacity: 1;
    transform: scale(1) !important;
    z-index: 500;
  }

.tips{
    visibility: hidden;
    opacity: 1;
    right: 127%;
    transform: scale(0);
    bottom: 0;
    gap: 8px;
    padding: 12px;
    background-color: #2D2D2D;
    border-radius: 10px;
    transform-origin: center right;
    transition: all 0.25s;
}
.tips::after{
    content: "";
    position: absolute;
    background: inherit;
    width: 23px;
    height: 11px;
    right: -18px;
    bottom: 23%;
    transform: translate(-50%) rotate(45deg);
    z-index: -2;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  0% {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(25deg);
    -o-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes rotating {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -ms-transform: rotate(25deg);
    -moz-transform: rotate(25deg);
    -webkit-transform: rotate(25deg);
    -o-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  50% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  75% {
    -ms-transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    -webkit-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
  100% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.tips__content{
  font-size: 12px;
  margin-bottom: 7px;
  padding: 0.1rem 0px 0px 0.5rem;
}
.ts__material-title {
  padding: 6px 0;
  font-size: 12px;
  font-weight: 400;
}

.ts__material-button-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
}

.ts__material-button-text-item {
  grid-column: 1 / -1;
}
.ts__quiz-template-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
}
.ts__quiz-template-list .ts__quiz-template-action p{
  font-size: 11px;
  margin-left: 0.1rem;
}
.ts__quiz-template-text-item {
  grid-column: 1 / -1;
}
.ts__quiz-template {
  gap: 8px;
  width: 100%;
  min-width: 80px;
  padding: 12px 0;
  background-color: #585859;
  font-size: 10px;
  color: #FFFFFF;
  border-radius: 6px;
  transition: background-color 0.25s;
}

.ts__material-button {
  gap: 8px;
  width: 100%;
  min-width: 80px;
  padding: 12px 0;
  background-color: #585859;
  font-size: 10px;
  color: #FFFFFF;
  border-radius: 6px;
  transition: background-color 0.25s;
}

.ts__material-button:not(:disabled):hover {
  background-color: #6D75F6;
}

.ts__help-button {
  gap: 6px;
  width: max-content;
  min-width: 100%;
  padding: 8px 12px;
  background-color: #585859;
  border-radius: 4px;
  font-size: 10px;
  color: #FFFFFF;
  line-height: 0.5;
  transition: background-color 0.25s;
}

.ts__help-button:hover {
  background-color: #6D75F6;
}
</style>
