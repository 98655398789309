<template>
  <section class="vaav d-flex flex-column text-left" :class="isGenerate ? 'position-relative' : 'px-5 py-3'">
    <header class="d-flex justify-content-between">
      <div class="ml-auto">
        <QuestionOptions
          v-model="question"
          @delete-question="$emit('delete-question')"
        />
      </div>
    </header>

    <div class="vaav__question d-flex">
      <QuestionVideoInput v-model="question.video" />
      <TextEditorInput
        v-if="question.question != null"
        v-model="question.question"
        with-toolbar
        type="question"
        placeholder="Type in your question"
        title="Question"
        @get-editor="(newEditor) => editor = newEditor"
        @focus="(editor) => $emit('focus-editor', editor)"
      />
    </div>
    <p class="vaav__answers-title py-1 m-0">Answer Choices</p>

    <AnswerInputWrapper :totalAnswers="4" :type="type">
      <template #default="{ answerNumber }">
        <TextEditorInput
          type="answer"
          :type-layout="type"
          v-model="question[`option_${answerNumber}`]"
          :correct="(answerNumber).toString() === question.correct_answer"
          placeholder="Type in choice here.."
          :title="`Choice #${answerNumber}`"
          @focus="(editor) => $emit('focus-editor', editor)"
        />
        <AnswerCorrectInput
          v-model="question.correct_answer"
          :answer-number="answerNumber"
        />
      </template>
    </AnswerInputWrapper>
    <Soca v-if="isGenerate" type="generate"/>
  </section>
</template>

<script>
import QuestionOptions from './QuestionOptions';
import QuestionVideoInput from './inputs/QuestionVideoInput';
import AnswerInputWrapper from './inputs/AnswerInputWrapper';
import TextEditorInput from './inputs/TextEditorInput';
import AnswerCorrectInput from './inputs/AnswerCorrectInput';
import Soca from '@/components/loading/Soca'

export default {
  components: {
    QuestionOptions,
    QuestionVideoInput,
    AnswerInputWrapper,
    TextEditorInput,
    AnswerCorrectInput,
    Soca
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    type: {
      validator(value) {
        return ['default', 'thumbnail'].includes(value);
      },
      default: 'default',
    },
  },
  emits: ['input', 'delete-question', 'focus-editor'],
  data() {
    return {
      question: this.value,
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        this.$emit('input', newQuestion);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.question = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.vaav {
  gap: 16px;
  max-height: 100%;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.vaav__question {
  height: 300px;
  gap: 16px;
  overflow-y: auto;
  z-index: 1
}

.vaav__question > * {
  flex-grow: 1;
}

.vaav__question > *:nth-child(2) {
  max-width: 60%;
}

.vaav__answers-title {
  color: #8C8C8C;
  border-bottom: 1px solid #2D2D2D;
}
</style>
