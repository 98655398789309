<template>
  <div class="ttvt__container">
    <b-modal
      :id="id"
      size="sm"
      dialog-class="ttvt__dialog"
      content-class="ttvt mx-auto text-white text-center"
      body-class="ttvt__body d-flex flex-column p-3"
      centered
      hide-header
      hide-footer
    >
      <button
        type="button"
        class="ttvt__close-button btn align-self-end d-inline-flex justify-content-center align-items-center p-0 rounded-circle"
        title="Close"
        @click="$bvModal.hide(id)"
      >
        &times;
      </button>
      <h2 class="ttvt__title px-4 m-0">Welcome Creators!</h2>
      <p class="ttvt__message px-4 mt-2 mb-0">In this feature, you will discover a new experience to personalize your voice and character for your content.<br /><br />Before you begin, let's explore the endless possibilities.</p>
      <button
        type="button"
        class="ttvt__tour-button btn align-self-center text-white px-4 mt-3 mb-3"
        title="Take a Tour"
        @click="$bvModal.hide(id); $bvModal.show(introduceFeatureModalId)"
      >
        Take a Tour
      </button>
    </b-modal>

    <IntroduceFeatureModal
      :id="introduceFeatureModalId"
      title="Text To Video"
      :slides="slides"
    />
  </div>
</template>

<script>
import IntroduceFeatureModal from './IntroduceFeature';

export default {
  components: {
    IntroduceFeatureModal,
  },
  props: {
    id: {
      type: String,
      default: 'ttv-tutorial'
    },
  },
  data() {
    return {
      slides: [
        {
          name: 'step-1',
          animationPath: '/assets/animations/introduce-ai-speech-editor/step-1.json',
          gifPath: require('@/assets/animations/ttv_tat1.gif'),
          rawText: 'Learning with 20+ languages',
        },
        {
          name: 'step-2',
          animationPath: '/assets/animations/introduce-ai-speech-editor/step-2.json',
          gifPath: require('@/assets/animations/ttv_tat2.gif'),
          rawText: 'Broadcast and personalized your video with ease',
        },
        {
          name: 'step-3',
          animationPath: '/assets/animations/introduce-ai-speech-editor/step-3.json',
          gifPath: require('@/assets/animations/ttv_tat3.gif'),
          rawText: 'Create A/B testing for your advertisements',
        },
      ],
    };
  },
  computed: {
    introduceFeatureModalId() {
      return `${this.id}__introduce-ttv`;
    },
  },
};
</script>

<style scoped>
.ttvt__close-button {
  width: 20px;
  height: 20px;
  background-color: #D9D9D9;
  color: #1C1C1C;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ttvt__close-button:hover {
  background-color: #FFFFFF;
}

.ttvt__title {
  font-size: 18px;
  font-weight: 700;
}

.ttvt__message {
  font-size: 16px;
}

.ttvt__tour-button {
  background-color: #6D75F6;
  font-weight: normal;
  transition: background-color 0.25s;
}

.ttvt__tour-button:hover {
  background-color: #545EED;
}
</style>

<style>
.ttvt__dialog {
  max-width: none;
}

.ttvt {
  width: 375px;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.ttvt__body {
  gap: 16px;
}
</style>
