<template>
  <section class="vaiv d-flex flex-column px-5 py-3 text-left">
    <header class="d-flex justify-content-between">
      <div class="ml-auto">
        <QuestionOptions
          v-model="question"
          @delete-question="$emit('delete-question')"
        />
      </div>
    </header>

    <div class="vaiv__question d-flex">
      <QuestionVideoInput v-model="question.video" />
      <TextEditorInput
        v-if="question.question !== null"
        with-toolbar
        v-model="question.question"
        placeholder="Type in your question"
        title="Question"
      />
    </div>

    <AnswerInputWrapper :totalAnswers="4">
      <template #default="{ answerNumber }">
        <AnswerImageInput
          v-model="question[`option_${answerNumber}`]"
          :correct="(answerNumber).toString() === question.correct_answer"
          :answerNumber="answerNumber"
        />
        <AnswerCorrectInput
          v-model="question.correct_answer"
          :answer-number="answerNumber"
        />
      </template>
    </AnswerInputWrapper>
  </section>
</template>

<script>
import QuestionOptions from './QuestionOptions';
import QuestionVideoInput from './inputs/QuestionVideoInput';
import TextEditorInput from './inputs/TextEditorInput';
import AnswerInputWrapper from './inputs/AnswerInputWrapper';
import AnswerImageInput from './inputs/AnswerImageInput';
import AnswerCorrectInput from './inputs/AnswerCorrectInput';

export default {
  components: {
    QuestionOptions,
    QuestionVideoInput,
    TextEditorInput,
    AnswerInputWrapper,
    AnswerImageInput,
    AnswerCorrectInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['input', 'delete-question'],
  data() {
    return {
      question: this.value,
    };
  },
  watch: {
    question: {
      handler(question) {
        this.$emit('input', question);
      },
      deep: true,
    },
    value: {
      handler(value) {
        this.question = value;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.vaiv {
  gap: 16px;
  max-height: 100%;
  background-color: #1F1F1F;
  border-radius: 10px;
}

.vaiv__question {
  gap: 16px;
  height: 300px;
  overflow-y: auto;
}

.vaiv__question > * {
  flex-grow: 1;
}

.vaiv__question > *:nth-child(2) {
  max-width: 60%;
}
</style>
