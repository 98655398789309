<template>
  <b-modal
    :id="id"
    size="lg"
    dialog-class="import-audio-scripts__modal-dialog"
    centered
    hide-header
    hide-footer
    @show="handleShowModal"
    @shown="handleShownModal"
    @hide="handleHideModal"
  >
    <div v-show="scripts.length === 0" class="container-fluid my-2">
      <div class="d-flex justify-content-between">
        <h3 class="font-weight-normal">Import Script</h3>
        <button
          class="btn btn-close d-flex justify-content-center align-items-center close-image"
          @click="$bvModal.hide(id)"
        >
          <b-icon icon="x" />
        </button>
      </div>
      <div
        class="upload-script mt-3"
        @dragover.prevent
        @dragenter.prevent
        @dragleave.prevent
        @drop.prevent
      >
        <label
          :for="`${_uid}__input-upload`"
          class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3"
          @drop="prepareForUploadScriptsFile([...$event.dataTransfer.files][0])"
        >
          <div class="h-100 w-100 d-flex align-items-center justify-content-center">
            <div v-if="scriptsFile === null && scriptsFileName === null">
              <img src="@/assets/images/logo/paper_impor.svg" alt="" class="img-fluid" width="30" />
              <h5 class="font-weight-normal mt-2" style="color: #6D75F6">Choose File or Drag Here</h5>
              <h6 class="font-weight-normal text-muted mt-1"><i>format file : .docs .txt .vtt</i></h6>
              <h6 class="font-weight-normal text-muted mt-1"><i>max. file size 1MB</i></h6>
            </div>
            <div v-else class="w-75">
              <p v-if="isUploadingProgressShown" class="mb-2 text-white">Uploading...</p>
              <h5 class="text-white">"{{ scriptsFileName }}"</h5>
              <div v-if="isUploadingProgressShown" class="w-100 mt-3">
                <div class="progress progress__upload" height="10">
                  <div
                    class="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: uploadProgress + '%' }"
                  />
                </div>
              </div>
              <button
                v-if="isUploadingProgressShown"
                class="btn btn-grey mt-4"
                @click="cancelWhileUploadScriptsFile"
              >
                <p class="mb-0 font-weight-bold mx-2">Cancel</p>
              </button>
            </div>
          </div>
          <input
            type="file"
            :id="`${_uid}__input-upload`"
            accept=".doc,.txt,.srt, .docs, .docx, .vtt"
            style="display: none"
            @change="prepareForUploadScriptsFile($event.target.files[0])"
          />
        </label>
        <div v-if="!isUploadingProgressShown" class="w-100 d-flex justify-content-end mt-5">
          <button class="btn btn-grey mr-2" @click="$bvModal.hide(id)">Cancel</button>
          <button v-if="scriptsFile === null && scriptsFileName === null" class="btn btn-disable">Upload</button>
          <button v-else class="btn btn-purple" @click="uploadScriptsFile">Upload</button>
        </div>
      </div>
    </div>
    <div v-show="scripts?.length > 0" class="container-svm">
      <div class="separator-bottom">
        <div class="container-fluid mt-2 mb-4">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div>
              <h4 class="font-weight-normal">Select an Import Preference</h4>
              <small class="text-nowrap">
                Importing
                <span class="font-weight-bold">"{{ scriptsFileName }}"</span>
              </small>
            </div>
            <button
              class="btn btn-close d-flex justify-content-center align-items-center close-image"
              @click="$bvModal.hide(id)"
            >
              <b-icon icon="x" />
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid" style="height:33rem;">
        <div class="row" style="height:29rem; border-bottom: 0.1px solid #2D2D2D">
          <div class="col-4 pt-3 pl-3" style="border-right: 0.1px solid #2D2D2D">
            <div class="separator__bottom">
              <h6 class="mb-2 font-weight-normal">Read all script by</h6>
              <div class="dropdown mr-4">
                <button
                  ref="buttonLang"
                  :id="`${_uid}__choose-lang`"
                  class="btn btn-lang d-flex align-items-center"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div class="d-flex align-items-center justify-content-between px-3 w-100">
                    <div class="d-flex">
                      <img
                        :src="selectedLanguage?.flagSrc"
                        class="img-fluid"
                        style="width: 18px; height: 18px"
                      />
                      <span
                        class="ml-2 mr-1"
                        style="font-size: 15px !important"
                      >
                        {{ selectedLanguage?.name }}
                      </span>
                    </div>
                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L4 4L7 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </button>
                <div class="dropdown-menu mt-2 w-100" :aria-labelledby="`${_uid}__choose-lang`">
                  <div v-if="!loadingLanguages" class="px-1">
                    <div class="select-lang overflow-auto px-1 pt-0">
                      <button
                        v-for="(country) in sortedCountries"
                        class="btn btn-transparent px-3 d-block w-100"
                        :key="country.code + country.name"
                        @click="selectedLanguage = country, isPersonalVoiceSectionActive = false"
                      >
                        <div class="d-flex align-items-center">
                          <img
                            :src="country.flagSrc"
                            class="img-fluid"
                            style="width: 18px; height: 18px"
                          />
                          <span
                            class="ml-2 text-white"
                            style="font-size: 15px !important"
                          >
                            {{ country.name }}
                          </span>
                          <img src="@/assets/images/icons/ic_news.svg" alt="" class="ml-2" v-if="country.name == 'Multilingual'">
                        </div>
                      </button>
                    </div>
                  </div>
                  <div v-else class="px-1">
                    <div class="select-lang overflow-auto px-1 pt-0">
                      <button
                        v-for="index in 15"
                        class="btn btn-transparent px-3 d-block mr-0 w-100"
                        :key="index"
                      >
                        <div class="d-flex align-items-center w-100">
                          <b-skeleton type="avatar" />
                          <b-skeleton animation="wave" width="100%" class="ml-2" />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center my-2 mr-4">
                <button
                  class="btn text-white w-100 mr-1"
                  :style="selectedGender === 'Male' ? 'background-color: #6D75F6' : 'background-color: #2d2d2d'"
                  @click="selectedGender = selectedGender === 'Male' ? null : 'Male'"
                >
                  Male
                </button>
                <button
                  class="btn text-white w-100 ml-1"
                  :style="selectedGender === 'Female' ? 'background-color: #6D75F6' : 'background-color: #2d2d2d'"
                  @click="selectedGender = selectedGender === 'Female' ? null : 'Female'"
                >
                  Female
                </button>
              </div>
            </div>
            <div class="char__wrapper mb-3" ref="mainList" @scroll="checkScroll">
              <div v-if="!isPersonalVoiceSectionActive" class="my-2 mr-4 char__container">
                <div
                  v-for="(character, index) in filteredCharacters"
                  :key="index"
                  class="card-avatar card"
                >
                  
                  <div class="fig--pr">
                    <div class="pr--label__char w-100 pt-2 px-2" v-if="character.isMultilingual">
                      <h6 class="char--name">{{character.name}}</h6>
                      <span class="badge--package" v-if="character.package_id.length == 2">PRO</span>
                    </div>
                    <img :src="character.thumbnailAvatarSrc" :alt="character.name" class="img-fluid img--char"/>
                  </div>
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <button
                        class="card-avatar__button-audio btn"
                        @click="handleClickAudioControl(character.name, character.sampleSrc)"
                      >
                        <font-awesome-icon
                          :icon="(playingAudioName === character.name && playingAudioState === 'playing') ? 'fa-solid fa-pause' : 'fa-solid fa-play'"
                          style="width: 9px"
                        />
                      </button>
                      <button
                        class="card-avatar__button-select btn btn-block ml-2"
                        :class="selectedCharacter.name === character.name ? 'btn-selected' : 'btn-purple'"
                        @click="selectedLanguage?.name == 'Multilingual' && character.button_active == false ? $bvModal.show(`package-character-import-${character.name}`) : selectedCharacter = character"
                      >
                        {{ selectedCharacter.name === character.name ? 'Selected' : 'Select'}}
                      </button>
                    </div>
                  </div>
                  <VCCharacterPackageModal :id="`package-character-import-${character.name}`" :package-id="character.package_id"/>
                </div>
              </div>
              <div v-else class="my-2 mr-4 pv__content d-flex flex-column">
                <template v-if="personalVoicesApi.length < 1">
                  <p class="pv__empty-message mb-0 mt-5 text-center">
                  You don't have any voices.<br />
                  Clone your voice now! Clone New
                  </p>
                </template>
                <template v-else>
                  <div v-for="voice in personalVoicesApi" :key="voice.character.id">
                    <button
                      type="button"
                      class="pv__voice-button btn d-flex align-items-start p-3 rounded-lg"
                      :class="{ 'pv__voice-button--selected': selectedPersonalVoice?.character.id === voice.character.id }"
                      :title="`Select ${voice.character.name}`"
                      @click="selectedPersonalVoice = voice"
                    >
                      <div
                        class="pv__voice-button-checkbox d-inline-flex justify-content-center align-items-center rounded-circle"
                        :class="{ 'pv__voice-button-checkbox--checked': selectedPersonalVoice?.character.id === voice.character.id }"
                      >
                        <font-awesome-icon
                          v-if="selectedPersonalVoice?.character.id === voice.character.id"
                          icon="fa-solid fa-check"
                        />
                      </div>
                      <AudioPlayer
                        :src="voice.character.sampleSrc"
                        :name="voice.character.name"
                        :type="voice.character.type"
                        size="small"
                        class="flex-grow-1"
                        @play="(pauseAudio, audioId) => handlePlayAudioPlayer(pauseAudio, audioId)"
                      />
                    </button>
                  </div>
                </template>
              </div>
            </div>
            <div class="pv__actions d-flex align-items-center justify-content-center">
              <template v-if="!isPersonalVoiceSectionActive">
                <button
                  type="button"
                  title="Use Personal Voice"
                  class="pv__button btn px-3 py-2"
                  :class="{ 'pv__button--active': isPersonalVoiceSectionActive }"
                  @click="isPersonalVoiceSectionActive = !isPersonalVoiceSectionActive"
                >
                  Use Personal Voice
                </button>
              </template>
              <template v-else>
                <button
                  @click="$bvModal.show('cct-modal')"
                  class="pv__action pv__action--clone btn py-2 px-3 rounded-sm"
                  title="Clone New"
                >
                  Clone New
                </button>
                <button
                  type="button"
                  class="pv__action pv__action--select btn py-2 px-3 rounded-sm text-white"
                  :title="'Select ' + selectedPersonalVoice?.character.name"
                  :disabled="selectedPersonalVoice === null"
                  @click="selectedCharacter = selectedPersonalVoice.character"
                >
                    Select
                </button>
              </template>
            </div>
          </div>
          <div class="col-8 pt-3">
            <div class="ml-3 pr-3 container-list__script">
              <div
                v-for="(script, index) in scripts"
                :key="index"
                class="card mb-3"
                style="background-color: #2d2d2d !important"
              >
                <div class="container-fluid py-2 px-4">
                  <div class="container-list__script__char">
                    <img
                      :src="selectedCharacter.avatarSrc || require(`@/assets/images/icons/sovia_editor.svg`)"
                      :alt="selectedCharacter.name || 'Sovia'"
                      style="height: 22.5px; width: 22.5px; border-radius: 20px" class="mx-2"
                    >
                    <span class="text-white mr-2">{{ selectedCharacter.name || 'Sovia' }}</span>
                  </div>
                </div>
                <hr class="m-0" style="height: 0.1px; background-color:#585859">
                <div class="container-fluid py-2 px-4">
                  <span>{{ script }}</span>
                </div>
              </div>
            </div>
            <div class="bottom-note">
              <div class="bottom-note__inner">
                <div class="pl-4 py-1">
                  <small>*Script split by paragraph</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-end align-items-center" style="height: 4rem">
          <button class="btn btn-grey mr-2 font-weight-bold" @click="$bvModal.hide(id)">Cancel</button>
          <button class="btn btn-purple font-weight-bold" @click="$emit('import', scripts, { language: selectedLanguage, character: selectedCharacter })">Import</button>
        </div>
      </div>
    </div>

    <audio ref="audioPlayer" />

    <TermOfUseMB />
    <TTVRecordVoiceModal id="sv-record-voice" :loading-audio="loadingPlayAudio" :is-generate="isGenerate" :is-play="isPlay" @play-voice-clone-sample="handleVoiceCloneSample" @rest-vs="handleResetVs" @create-vc="$bvModal.show('pvi-modal'), $bvModal.hide('sv-record-voice')"/>
		<PVInstruction @continue="$bvModal.show('pvrecord-modal'), $bvModal.hide('cct-modal'), $bvModal.hide('pvi-modal')" />
		<PVRecord @update-table="getYourVoiceModel" @reset="selectedCloneType = null" @show-instruction="$bvModal.hide('pvrecord-modal'), $bvModal.show('pvi-modal')" :selected="selectedCloneType"/>
    <ChooseCloneTypeModal :selected="selectedCloneType" @hidden:cct-closed="selectedCloneType = null" @click:change-selected-clone-type="handleChangeSelectedCloneType" @click:next="handleClickNextCct"/>
		<QuotaModelsAlert :type="selectedCloneType" :click-upgrade="clickUpgrade" @click:handle-upgrade-gv="$emit('click:handle-upgrade-gv')"/>
		<VCMultilingualError @retry="handleRetryVCError" @cancel="handleCancelVCError"/>
  </b-modal>
</template>

<script>
import axios from 'axios';

import { base_url_machine_learning } from '@/config/base_url';
import { getMasterLanguages } from '@/services/master-languages/master_languages.service';
import { importScriptAudio} from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
import { AlertUtils } from "@/mixins/AlertUtils";
import { getVoiceModel, voiceCloneSample } from '@/services/ttv/ttv.service.js'

import TermOfUseMB from '@/components/modal/TermOfUseMB';
import TTVRecordVoiceModal from '@/components/modal/TTVRecordVoice';
import PVInstruction from '@/components/modal/PVInstruction';
import PVRecord from '@/components/modal/PVRecord';
import QuotaModelsAlert from '@/components/modal/QuotaModelsAlert';
import VCMultilingualError from '@/components/modal/VCMultilingualError';
import VCCharacterPackageModal from '@/components/modal/CharacterPackage';
import ChooseCloneTypeModal from '@/components/modal/ChooseCloneType.vue';
import AudioPlayer from '@/components/audio-player/AudioPlayerImportScript';

export default {
  mixins: [AlertUtils],
  components: {
    TermOfUseMB,
    AudioPlayer,
    TTVRecordVoiceModal,
    PVInstruction,
    ChooseCloneTypeModal,
		QuotaModelsAlert,
		VCMultilingualError,
    VCCharacterPackageModal,
    PVRecord
  },
  props: {
    id: {
      type: String,
      default: 'import-voice-scripts',
    },
    initialLanguages: {
      type: Array,
      default: () => ([]),
    },
    allocation: {
      type: Object,
      default: () => {
        return {}
      }
    },
    clickUpgrade: {
      type: Function,
      default: null,
    },
  },
  emits: ['import', 'get:languages'],
  data() {
    return {
      scripts: [],
      scriptsFile: null,
      scriptsFileName: null,

      isUploadingProgressShown: false,
      uploadProgress: 0,
      cancelTokenSsource: null,

      languages: this.initialLanguages,
      languagesFilter: [],
      loadingLanguages: false,
      loadingLanguagesFilter: false,
      loadingPlayAudio: false,
      isLanguagesSent: false,
      isGenerate: false,
      isPlay: false,

      selectedGender: null,
      selectedLanguage: {
        id: '6401ae940eb4d9111ec260d7',
        name: 'Indonesia',
        code: 'ID',
        flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
      },
      selectedCharacter: {
        gender: 'Female',
        name: 'Sovia',
        avatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/Sovia.png',
        thumbnailAvatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/thumbnails/Sovia.png',
        sample: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/normal/1677909003.mp3',
      },

      playingAudioName: null,
      playingAudioState: 'paused',

      eventMBJokowi : null,
      scrolledBottom: false,
      showShadow: false,

      personalVoicesApi: [],
      selectedPersonalVoice: null,
      latestPlayingAudioId: null,
      pauseLatestPlayingAudio: null,
      voiceCloneSampleUrl: null,
      isPersonalVoiceSectionActive: false,

      selectedCloneType: null,

      lang: [
        {
          id: '6401ae940eb4d9111ec260d7',
          name: 'Indonesia',
          code: 'ID',
          flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
        },
        {
          id: '64017a04a5fbff45c62e64d8',
          name: 'English-UK',
          code: 'EN',
          flagSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/english-UK/flags.png'
        }
      ],
      finalFormVoiceSample: {
				text: '',
				speaker: ''
			},
    };
  },
  computed: {
    sortedCountries() {
      if (this.languages.length > 0) {
        const sortedLanguages = [...this.languages];

        sortedLanguages.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA === 'multilingual') {
            return -1; // Pindahkan 'Multilingual' ke atas
          }

          if (nameB === 'multilingual') {
            return 1; // Pindahkan 'Multilingual' ke atas
          }

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        return sortedLanguages;
      } else {
        return this.languages;
      }
    },
    filteredCharacters() {
      if (!this.selectedGender) {
        return this.languagesFilter;
      }

      return this.languagesFilter.filter((character) => {
        return character.gender === this.selectedGender;
      });
    },
  },
  watch: {
    selectedLanguage: {
      async handler() {
        if (this.$refs.audioPlayer && !this.$refs.audioPlayer.paused) {
          await this.$refs.audioPlayer.pause();
          this.resetAudioPlayer();
        }

        await this.populateLanguagesFilter();
      },
      deep: true,
    },
    scripts(newValue) {
      if(newValue.length > 0) {
        this.$nextTick(() => {
          this.$refs.buttonLang.click()
        })
      }
    }
  },
  methods: {
    checkScroll() {
      const mainList = this.$refs.mainList;
      const scrolledBottom = mainList?.scrollTop + mainList?.clientHeight + 1 >= mainList?.scrollHeight;
      if (this.scrolledBottom !== scrolledBottom) {
        this.scrolledBottom = scrolledBottom;
      }
		},
    checkContentOverflow() {
      const mainList = this.$refs.mainList;
      this.showShadow = mainList?.scrollHeight > mainList?.clientHeight;
    },
    prepareForUploadScriptsFile(file) {
      const { type, size, name } = file;
      let handleType = name.split('.').pop()

      if (
        type === 'text/plain'
        || type === 'text/vtt'
        || handleType  === 'vtt'
        || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        || type === 'application/msword'
      ) {
        if (size > 1000000) {
          this.alertFail(this.$t("file-size-max-1mb"));
        } else {
          this.scriptsFile = file;
          this.scriptsFileName = name || 'Script Import';
        }
      } else {
        console.log(type)
        this.alertFail(this.$t('docs-txt--vtt'));
      }
    },
    async uploadScriptsFile() {
      const formData = new FormData();
      formData.append('uploadfile', this.scriptsFile);

      const config = {
        onUploadProgress: (progressEvent) => {
          this.isUploadingProgressShown = true;
          this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        cancelToken: new axios.CancelToken(cancel => {
          this.cancelTokenSource = cancel;
        }),
      };

      try {
        const res = await importScriptAudio(formData, config);

        if (res.status){
          for (const script of res.data) {
            if (script !== '') {
              this.scripts.push(script.replace('\n', '').trim());
            }
          }
        }
      } catch (error) {
        console.error(error);
      }

      this.isUploadingProgressShown = false;
      this.uploadProgress = 0;
    },
    cancelWhileUploadScriptsFile() {
      this.cancelTokenSource();
      this.resetUploadProcess();
    },
    resetUploadProcess() {
      this.scripts = [];
      this.scriptsFile = null
      this.scriptsFileName = null;

      this.isUploadingProgressShown = false;
      this.uploadProgress = 0;
      this.cancelTokenSource = null;
    },
    resetData() {
      this.selectedGender = null;

      this.resetUploadProcess();
      this.resetAudioPlayer();
    },
    async populateLanguages() {
      this.loadingLanguages = true;

      try {
        const { data: languages } = await getMasterLanguages();

        this.languages = [];

        for (const language of languages) {
          this.languages.push({
            name: language.DisplayName,
            code: language.LanguageCode.toUpperCase(),
            flagSrc: base_url_machine_learning + language.Flags,
          });
        }
      } catch (error) {
        console.error(error);
      }

      this.loadingLanguages = false;
    },
    async populateLanguagesFilter() {
      if (this.selectedLanguage) {
        this.loadingLanguagesFilter = this.selectedLanguage.name;

        try {
          const { data } = await getMasterLanguages({
            filter: this.selectedLanguage.name,
          });

          this.languagesFilter = [];
          this.eventMBJokowi = null

          for (let i = 0; i < data[0].Detail.length; i++) {
            const character = data[0].Detail[i];
            const isMultilingual = data[0].LanguageCode !== "multilingual" ? false : true;

            this.languagesFilter.push({
              gender: character.Gender,
              name: character.Name,
              avatarSrc: base_url_machine_learning + character.PathAvatar,
              sampleSrc: base_url_machine_learning + character.PathSample.normal,
              thumbnailAvatarSrc: base_url_machine_learning + character.PathThumbnailAvatar,
              isMultilingual: isMultilingual,
              button_active : character.button_active,
              package_id : character.package_id || null
            });
          }


          this.scrolledBottom = false
          this.checkContentOverflow()
          this.selectedLanguage.id = data[0]._id.$oid;
        } catch (error) {
          console.error(error);
        }

        this.loadingLanguagesFilter = false;
      }
    },
    async handleClickAudioControl(name, src) {
      const prevPlayingAudioName = this.playingAudioName;

      this.playingAudioName = name;

      if (prevPlayingAudioName !== name) {
        this.$refs.audioPlayer.src = src;
      }

      if (this.$refs.audioPlayer.paused) {
        await this.$refs.audioPlayer.play();
        this.playingAudioState = 'playing';
      } else {
        await this.$refs.audioPlayer.pause();
        this.playingAudioState = 'paused';
      }
    },
    handleEndedAudioPlayer() {
      this.resetAudioPlayer();
    },
    resetAudioPlayer() {
      this.playingAudioName = null;
      this.playingAudioState = 'paused';

      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.src = null;
      }
    },
    async handleShowModal() {
      this.resetData();
      await this.getLanguages();
    },
    handleShownModal() {
      this.$refs.audioPlayer.addEventListener('ended', this.handleEndedAudioPlayer);
    },
    handleHideModal() {
      this.$refs.audioPlayer.removeEventListener('ended', this.handleEndedAudioPlayer);
    },
    async getLanguages() {
      if (this.languages?.length < 1) {
        await this.populateLanguages();

        if (this.languages.length > 0 && !this.isLanguagesSent) {
          this.$emit('get:languages', this.languages);
          this.isLanguagesSent = true;
        }
      }

      await this.populateLanguagesFilter();
    },
    getYourVoiceModel() {
      getVoiceModel()
      .then((response) => {
        if(response.status == 200) {
          this.personalVoicesApi = []
          let data = response.data
          data.forEach((e) => {
            let indexLang = this.lang.findIndex(item => item.id === e.lang);
            let dataLang = this.lang[indexLang]
            this.personalVoicesApi.push({
              language: {
                id: e.lang,
                name: dataLang.name,
                code: dataLang.code,
                flagSrc: dataLang.flagSrc,
              },
              character: {
                id: e.id,
                name: e.character_name,
                avatarSrc: 'https://ui-avatars.com/api/?name=' + e.character_name,
                sampleSrc: e.path_voice,
                lang: e.lang,
                isUseModel: true,
                type: e.type
              },
            })
          })
        }
      })
    },
    async handlePlayAudioPlayer(pauseAudio, audioId) {
      const playMediaEvent = new CustomEvent('play:media', {
        detail: {
          componentId: audioId,
        },
      });

      document.dispatchEvent(playMediaEvent);

      this.latestPlayingAudioId = audioId;
      this.pauseLatestPlayingAudio = pauseAudio;
    },
    async handlePlayMedia(e) {
      if (e.detail.componentId !== this.latestPlayingAudioId && this.pauseLatestPlayingAudio) {
        await this.pauseLatestPlayingAudio();
      }
    },
    handleClickPersonalVoice(voice) {
      this.selectedPersonalVoice = voice
      this.selectedCharacter = voice.character
    },
    handleVoiceCloneSample(val) {
      if(this.finalFormVoiceSample.text == val.text && this.finalFormVoiceSample.speaker == val.speaker) {
        this.playVoiceCloneSample()
      } else {
        this.isGenerate = true
        voiceCloneSample(val)
        .then((response) => {
          let audio = new Audio(response.data)
          this.voiceCloneSampleUrl = audio
          this.isGenerate = false
          this.playVoiceCloneSample()
        })
      }
      this.finalFormVoiceSample.text = val.text
      this.finalFormVoiceSample.speaker = val.speaker
    },
    playVoiceCloneSample() {
      this.voiceCloneSampleUrl.onwaiting = () => {
        this.loadingPlayAudio = true
      }

      this.voiceCloneSampleUrl.onplaying = () => {
        this.loadingPlayAudio = false
      }

      if(!this.voiceCloneSampleUrl.paused) {
        this.isPlay = false
        clearInterval(this.voiceCloneSampleUrl.intervalId)
        this.voiceCloneSampleUrl.pause()
      } else {
        this.isPlay = true
        this.voiceCloneSampleUrl.play()

        this.voiceCloneSampleUrl.addEventListener('ended', () => {
          this.isPlay = false
        })

        this.voiceCloneSampleUrl.intervalId = setInterval(() => {
          this.voiceCloneSampleUrl.paused ? clearInterval(this.voiceCloneSampleUrl.intervalId) : null;
          if(this.voiceCloneSampleUrl.currentTime === this.voiceCloneSampleUrl.duration) {
            this.isPlay = false
          }
        }, 100)
      }
    },
    handleResetVs() {
      if(this.voiceCloneSampleUrl){
        this.voiceCloneSampleUrl.pause()
        clearInterval(this.voiceCloneSampleUrl.intervalId)
      }
      this.voiceCloneSampleUrl = null
      this.isGenerate = false
			this.isPlay = false
			this.finalFormVoiceSample = {
				text: '',
				speaker: ''
			}
    },

    handleClickNextCct() {
      if(!this.allocation?.voice_model_monolingual_quota && this.selectedCloneType == 'monolingual') {
				this.$bvModal.show('qm-modal')
			} else if (!this.allocation?.voice_model_multilingual_quota && this.selectedCloneType == 'multilingual') {
				this.$bvModal.show('qm-modal')
			} else {
				this.$bvModal.hide('cct-modal')
				this.$bvModal.show('pvi-modal')
			}
		},

    handleRetryVCError() {
			this.$bvModal.hide('vcm-modal')
			this.$bvModal.hide('pvrecord-modal')
			this.$bvModal.show('cct-modal')
		},

		handleCancelVCError() {
			this.$bvModal.hide('vcm-modal')
			this.$bvModal.hide('pvrecord-modal')
		},

    handleChangeSelectedCloneType(newVal) {
			this.selectedCloneType = newVal
    }
  },
  async mounted() {
    await this.getLanguages();
    await this.getYourVoiceModel();
    document.addEventListener('play:media', this.handlePlayMedia);
  },
  beforeDestroy() {
    this.$refs.audioPlayer?.removeEventListener('ended', this.handleEndedAudioPlayer);
    document.removeEventListener('play:media', this.handlePlayMedia);
  },
};
</script>

<style scoped>
.fig--pr{
  position: relative;
}
.pr--label__char{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
}
.char--name{
  font-size: 14px;
  font-weight: 400;
}
.img--char{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.badge--package{
  font-size: 8px;
  background-color: #EBB12B;
  border-radius: 8.5px;
  padding: 1px 6px;
}
.btn-close {
  width: 15px;
  height: 26px;
  background-color: #EDEDED;
  border-radius: 50%;
}

.close-image {
  z-index: 100;
  right: 5px;
}

.upload-script .btn-impor-custom {
  height: 20rem;
}

.btn-impor-custom {
  border-radius: 10px;
  border: 2px dashed #2D2D2D;
}

.upload-script .btn-impor-custom {
  height: 20rem;
}

.progress__upload .progress-bar {
  background-color: #6D75F6;
}

.btn-grey {
  background-color: #2D2D2D !important;
  border: 0px !important;
  color: white;
  transition: background-color 0.5s;
}

.btn-grey:hover {
  background-color: #414141 !important;
}

.btn-disable {
  background-color: #2D2D2D;
  border: 1px solid #2D2D2D;
  color: #585859;
}

.btn-purple {
  background-color: #6D75F6;
  border: 1px solid #6D75F6;
  color: white;
}

.container-svm label {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 25px !important;
}

.container-svm label::before {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 0.5rem;
  width: 40px;
  background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
  background-size: 20px;
}

.separator-bottom {
  border-bottom: 0.1px solid #2D2D2D;
}

.separator__bottom {
  border-bottom: 0.1px solid #2D2D2D;
}

.btn-close {
  width: 15px;
  height: 26px;
  background-color: #EDEDED;
  border-radius: 50%;
}

.dropdown span, .dropdown-menu span {
  font-size: 12px;
}

.dropdown-menu {
  min-width: 5rem;
  background-color: #2D2D2D;
  box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.75);
}

.select-lang {
  width: 100% !important;
  height: 18rem;
  padding: 10px 10px;
}

.select-lang::-webkit-scrollbar {
  width: 6px;
}

.select-lang::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

.select-lang::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  border-radius: 4px;
  background-clip: padding-box;
}

.select-lang::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.btn-lang {
  width: 100%;
  padding: 10px !important;
  background-color:transparent;
  border: 1px solid #2D2D2D;
  color: white;
}

.card-avatar {
  background-color: #2D2D2D;
}

.card-avatar__single {
  max-width: 8.9rem;
}

.card-avatar__button-audio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 3px !important;
  color: black;
}

.card-avatar__button-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 23px;
  border-radius: 3px !important;
  font-size: 12px;
}

.btn-selected {
  background-color: #585859;
  border: 1px solid #585859;
  color: white;
  box-shadow: none;
}

.btn-purple {
  background-color: #6D75F6;
  border: 1px solid #6D75F6;
  color: white;
}

.container-list__script {
  height: 26rem;
  overflow: auto;
}

.container-list__script span {
  font-size: 12px;
  color: #8C8C8C;
}

.card {
  border-radius: 6px;
}

.container-list__script__char {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 28px;
  background-color: #3C3C3C;
  border-radius: 20px;
}

.bottom-note {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #6D75F61A;
}

.char__wrapper {
  height: 16rem;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.char__wrapper::-webkit-scrollbar {
  width: 0;
}

.banner__mb {
  position: relative;
  min-height: 112px;
  border-radius: 6px;
  background: #2D2D2D;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.banner__nkri-img {
  position: absolute;
  right: 0;
}

.jd__inner {
  gap: 8px;
  padding-top: 6.5rem !important;
}

.jd__title {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  line-height: 10px;
}

.jd__sub-title {
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
}

.jd__pharagraph {
  font-size: 8px;
  color: #fff;
}

.jd__intro {
  gap: 6px;
}

.char__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.pv__content {
  gap: 8px;
}

.pv__button {
  background-color: #2D2D2D;
  border: 1px solid transparent;
  color: #6D75F6;
  font-weight: 600;
  transition: border-color 0.25s;
}

.pv__button:not(.pv__button--active):hover {
  border-color: #6D75F644;
}

.pv__button--active {
  border-color: #6D75F6;
}

.pv__voice-button {
  /* gap: 16px; */
  width: 100%;
  background: #2D2D2D;
  border: 1px solid transparent;
  overflow: hidden;
  transition: border-color 0.25s;
}

.pv__voice-button:not(
  .pv__voice-button--selected
):hover {
  border-color: #6D75F655;
}

.pv__voice-button--selected {
  border-color: #6D75F6;
}

.pv__voice-button-checkbox {
  width: 12px;
  height: 12px;
  border: 1px solid #FFFFFF;
  font-size: 8px;
  transition: background-color 0.25s, border-color 0.25s;
}

.pv__voice-button-checkbox--checked {
  background-color: #6D75F6;
  border-color: #6D75F6;
  color: #FFFFFF;
}

.pv__actions {
  gap: 8px;
}

.pv__action {
  min-width: 75px;
  background: none;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.25s, border-color 0.25s;
}

.pv__action--clone {
  color: #6D75F6;
  border-color: #6D75F6;
}

.pv__action--clone:not(:disabled):hover {
  background-color: #6D75F633;
}

.pv__action--select {
  background-color: #6D75F6;
}

.pv__action--select:not(:disabled):hover {
  background-color: #545EED;
}
</style>

<style>
.import-audio-scripts__modal-dialog {
  max-width: none;
  display: flex;
  justify-content: center;
}

#import-audio-scripts .modal-content {
  width: 1000px;
}
</style>
