<template>
	<section>
		<v-tour name="TTVtour" :steps="steps" :options="{ highlight: true }" :callbacks="tourCallbacks">
			<template slot-scope="tour">
				<v-step
					v-if="tour.steps[tour.currentStep]"
					:key="tour.currentStep"
					:step="tour.steps[tour.currentStep]"
					:previous-step="tour.previousStep"
					:next-step="tour.nextStep"
					:stop="tour.stop"
					:skip="tour.skip"
					:is-first="tour.isFirst"
					:is-last="tour.isLast"
					:labels="tour.labels"
					:highlight="tour.highlight"
					:class="{ 'mr-2' : tour.currentStep === 0 || tour.currentStep === 1, 'ml-2' : tour.currentStep === 2 }"
				>
					<template v-if="tour.currentStep === 3">
						<div slot="actions" class="gv--tour d-flex justify-content-end">
							<button @click="tour.stop" class="btn gv--next--button ml-auto">{{ $t('done') }}</button>
						</div>
					</template>
					<template v-else>
						<div slot="actions" class="gv--tour d-flex align-items-center justify-content-between">
							<button @click="tour.stop" class="btn gv--skip--button">{{ $t('skip')  }}</button>
							<button @click="tour.nextStep" class="btn gv--next--button">{{ $t('next')  }} <font-awesome-icon icon="fa-solid fa-chevron-right" class="gvnb--icon"/></button>
						</div>
					</template>
				</v-step>
			</template>
		</v-tour>
	</section>
</template>

<script>
export default {
	props: {
		steps: {
			type: Array,
			default: () => {
				return []
			}
		},
		tourCallbacks: {
			type: Object,
			default: () => {
				return {}
			}
		}
	}
}
</script>

<style scoped>
.gv--skip--button, .gv--next--button {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}

.gv--skip--button {
  padding: 6px 0px;
  background-color: transparent;
}

.gv--next--button {
  background-color: #6D75F6;
  transition: background-color 0.25s
}

.gv--next--button:hover {
  background-color: #545EED;
}

.gvnb--icon {
  font-size: 12px
}

.gv--tour {
  border-top: 0.1px solid #2D2D2D;
  padding-top: 1rem;
}

.ml-6 {
	margin-left: 4.5rem !important;
}
</style>

<style>
.v-tour__target--highlighted {
  box-shadow: none;
}

.gv .v-step {
  border-radius: 10px;
  background: #1F1F1F;
  max-width: 360px;
}

.gv--step-text {
  text-align: left;
  font-size: 16px;
}

.gv--step-title {
  font-weight: bold;
}
</style>