var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"tlh position-relative d-flex justify-content-center align-items-center p-2"},[_c('button',{staticClass:"tlh__timeline-toggle btn position-absolute d-inline-flex align-items-center px-2 py-1 rounded-pill text-white",attrs:{"type":"button","title":_vm.isTimelineShown ? 'Hide Timeline' : 'Show Timeline'},on:{"click":function($event){return _vm.$emit('click:timeline-toggle')}}},[_c('font-awesome-icon',{staticClass:"tlh__timeline-toggle-icon",class:{ 'tlh__timeline-toggle-icon--bottom': _vm.isTimelineShown },attrs:{"icon":"fa-solid fa-chevron-up"}}),_vm._v(" Timeline ")],1),_c('div',{staticClass:"tlh__controls d-flex align-items-center"},[_c('button',{staticClass:"tlh__control-button tlh__control-button--step btn d-inline-flex justify-content-center align-items-center rounded-circle text-white",attrs:{"type":"button","title":"Backward","disabled":_vm.disableBackward},on:{"click":function($event){return _vm.$emit('click:backward')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-backward-step"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Backward")])],1),(_vm.mainControlState === 'generating')?_c('p',{staticClass:"tlh__generating-message d-flex align-items-center mb-0 mx-2"},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-spinner","spin":""}}),_vm._v(" Generating Video ")],1):_c('button',{staticClass:"tlh__control-button btn d-inline-flex justify-content-center align-items-center rounded-circle",class:{
        'tlh__control-button--main': _vm.mainControlState !== 'generate',
        'tlh__control-button--generate': _vm.mainControlState === 'generate'
      },attrs:{"type":"button","title":_vm.mainControlState.charAt(0).toUpperCase() + _vm.mainControlState.slice(1),"disabled":_vm.disableMainControl},on:{"click":function($event){return _vm.$emit('click:main-control')}}},[_c('font-awesome-icon',{attrs:{"icon":`fa-solid ${_vm.mainControlState === 'pause' ? 'fa-pause' : 'fa-play'}`}}),_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.mainControlState.charAt(0).toUpperCase() + _vm.mainControlState.slice(1))+" ")])],1),_c('button',{staticClass:"tlh__control-button tlh__control-button--step btn d-inline-flex justify-content-center align-items-center rounded-circle text-white",attrs:{"type":"button","title":"Forward","disabled":_vm.disableForward},on:{"click":function($event){return _vm.$emit('click:forward')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-forward-step"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Forward")])],1)]),_c('div',{staticClass:"tlh__timestamp d-flex align-items-center text-white"},[_c('span',{staticClass:"tlh__time"},[_vm._v(_vm._s(_vm.calculateTime(_vm.currentTime)))]),_c('span',{staticClass:"tlh__time tlh__time--gray"},[_vm._v("/")]),_c('span',{staticClass:"tlh__time tlh__time--gray"},[_vm._v(_vm._s(_vm.calculateTime(_vm.duration)))])]),_c('div',{staticClass:"tlh__extras"},[_c('button',{staticClass:"tlh__extras-toggle btn d-inline-flex justify-content-center align-items-center",class:{ 'tlh__extras-toggle--active': _vm.isEqualizerShown },attrs:{"type":"button","name":"equalizer","title":`${_vm.isEqualizerShown ? 'Hide' : 'Show'} Equalizer`,"disabled":_vm.mainControlState == 'generating'}},[_c('img',{staticClass:"tlh__extras-toggle-icon",attrs:{"src":require('@/assets/images/icons/ic_settings_timeline.svg'),"alt":"Equalizer"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Equalizer")])]),_c('tippy',{attrs:{"to":"equalizer","trigger":"click","interactive":"","animate-fill":false,"on-show":() => _vm.isEqualizerShown = true,"on-hide":() => {
        _vm.isEqualizerShown = false;
        return true;
      },"duration":250,"max-width":500,"theme":"tlh__extras"}},[_c('div',{staticClass:"tlh__extras-menu d-flex flex-column p-3"},[_c('p',{staticClass:"tlh__extras-title m-0"},[_vm._v("Equalizer")]),_c('div',{staticClass:"tlh__equalizer d-flex"},[_c('div',{staticClass:"tlh__equalizer-slider d-flex flex-column"},[_c('span',{staticClass:"sr-only"},[_vm._v("Voice Volume")]),_c('VueSlider',{attrs:{"value":_vm.voiceVolume,"direction":"btt","width":8,"height":120,"min":0,"max":1,"interval":0.1,"adsorb":"","tooltip":"none","dot-style":"tlh__equalizer-slider-dot","title":"Voice Volume"},on:{"change":(newVoiceVolume) => _vm.$emit('change:voice-volume', newVoiceVolume)}}),_c('img',{staticClass:"tlh__equalizer-slider-icon",attrs:{"src":require('@/assets/images/icons/ic_mic_eq.svg'),"alt":"Voice"}})],1),_c('div',{staticClass:"tlh__equalizer-slider d-flex flex-column"},[_c('span',{staticClass:"sr-only"},[_vm._v("Music Volume")]),_c('VueSlider',{attrs:{"value":_vm.musicVolume,"direction":"btt","width":8,"height":120,"min":0,"max":1,"interval":0.1,"adsorb":"","tooltip":"none","dot-style":"tlh__equalizer-slider-dot","title":"Music Volume"},on:{"change":(newMusicVolume) => _vm.$emit('change:music-volume', newMusicVolume)}}),_c('img',{staticClass:"tlh__equalizer-slider-icon",attrs:{"src":require('@/assets/images/icons/ic_music_eq.svg'),"alt":"Music"}})],1)])])])],1),_c('div',{staticClass:"tlh__extras"})])
}
var staticRenderFns = []

export { render, staticRenderFns }