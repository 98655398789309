<template>
  <div class="cl position-relative d-flex flex-column">
    <ul
      ref="scrollableList"
      class="cl__list cl__list--at-bottom pl-5 mb-0 list-unstyled"
      @scroll="handleBottomGradientVisibility"
    >
      <li v-if="withUpload">
        <button
          type="button"
          class="cl__character-button cl__character-button--upload btn d-flex flex-column justify-content-center align-items-center text-center text-white"
          title="Upload"
          @click="$emit('click:upload')"
        >
          <font-awesome-icon
            icon="fa-solid fa-plus"
            class="cl__character-button-icon"
          />
          Upload
        </button>
      </li>
      <li v-for="(character, index) in characters" :key="index">
        <button
          type="button"
          class="cl__character-button cl__character-button--selectable btn p-0"
          :class="{ 'cl__character-button--selected': selectedCharacter?.name === character.name && selectedCharacter?.imageSrc === character.imageSrc }"
          :title="`Select ${character.name}`"
          @click="$emit('change:selected-character', character)"
          :disabled="disabledAction"
        >
          <img
            :src="character.imageSrc"
            class="cl__character-button-image"
            :alt="character.name"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selectedCharacter',
    event: 'change:selected-character',
  },
  props: {
    selectedCharacter: {
      type: Object,
      default: null,
    },
    characters: {
      type: Array,
      required: true,
    },
    withUpload: {
      type: Boolean,
      default: false,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change:selected-character', 'click:upload'],
  methods: {
    handleBottomGradientVisibility() {
      const { scrollHeight, clientHeight, scrollTop, } = this.$refs.scrollableList;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
        this.$refs.scrollableList.classList.add('cl__list--at-bottom');
      } else {
        this.$refs.scrollableList.classList.remove('cl__list--at-bottom');
      }
    },
  },
  mounted() {
    this.handleBottomGradientVisibility();
    window.addEventListener('resize',this.handleBottomGradientVisibility);
  },
  beforeDestroy() {
    window.removeEventListener('resize',this.handleBottomGradientVisibility);
  },
};
</script>

<style scoped>
.cl {
  min-height: 50px;
}

.cl__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding-right: 24px;
  margin-right: 18px;
  overflow-y: auto;
}

.cl__list::after {
  opacity: 1;
  visibility: visible;

  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
  transition: opacity 0.25s, visibility 0.25s;
}

.cl__list--at-bottom::after {
  opacity: 0;
  visibility: hidden;
}

.cl__character-button {
  width: 100%;
  height: 125px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: background-color 0.25s, border 0.25s;
}

.cl__character-button--upload {
  gap: 4px;
  background-color: #1F1F1F;
  font-size: 14px;
}

.cl__character-button--upload:hover {
  background-color: #252525;
}

.cl__character-button--selectable:not(
  .cl__character-button--selected
):hover {
  border-color: #6D75F655;
}

.cl__character-button--selected {
  border-color: #6D75F6;
}

.cl__character-button-icon {
  font-size: 20px;
}

.cl__character-button-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
