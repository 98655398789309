<template>
  <b-modal
    :id="id"
    size="xl"
    modal-class="add-timeline-music__modal"
    content-class="add-timeline-music__content"
    dialog-class="add-timeline-music__dialog d-flex justify-content-center"
    centered
    hide-header
    hide-footer
    @show="handleShowModal"
  >
    <div class="header__atm container-fluid d-flex justify-content-between align-items-center mb-4 mt-2 pl-4">
      <p class="header__title mb-0">Music Library</p>
      <button
        class="btn btn-close d-flex justify-content-center align-items-center"
        @click="$bvModal.hide(id)"
      >
        <b-icon icon="x" />
      </button>
    </div>
    <div class="d-flex align-items-start w-100 main__atm">
      <div class="w-100 pl-4">
        <div>
          <div class="container-fluid">
            <div class="row" style="max-height: 99vh">
              <div class="col-md-3 hr-vertical">
                <div class="pr-2">
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <p class="title-filter">Filter</p>
                  </div>
                  <div class="music-filter__wrapper d-flex flex-column">
                    <div v-for="(data, index) in musicFilterType" :key="index">
                      <button type="button" class="btn mf__toggle d-flex justify-content-between pl-0 pr-3 w-100" :aria-expanded="data.isVisible ? 'true' : 'false'" :aria-controls="`collapse-${index}`" @click="data.isVisible = !data.isVisible">
                        <span class="mf__text">{{ data.title }}</span>
                        <font-awesome-icon
                          icon="fa-solid fa-chevron-right"
                          class="mf-card__toggle-chevron-icon"
                          :class="{ 'mf-card__toggle-chevron-icon--active': data.isVisible }"
                        />
                      </button>
                      <b-collapse
                        v-model="data.isVisible"
                        :id="`collapse-${index}`"
                      >
                        <div class="mf__collapse px-3 mt-2">
                          <ul>
                            <li
                              v-for="(musicType, i) in data.category"
                              :key="musicType"
                              class="d-flex mb-2"
                            >
                              <vs-checkbox
                                v-model="selectedMusicTypes"
                                :vs-value="musicType"
                                :id="`music-${i}-${_uid}`"
                                color="#6D75F6"
                                :disabled="isLoadingGetMusics"
                              >
                                {{ musicType }}
                              </vs-checkbox>
                            </li>
                          </ul>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-1 hr-vertical">
                <span class="d-none" />
              </div> -->
              <div class="col-md-9 pl-3 h-100">
                <div class="position-relative mt-3 text-white">
                  <div class="gallery">
                    <label :for="`input-search-${_uid}`">
                      <input
                        type="text"
                        v-model="keyword"
                        :id="`input-search-${_uid}`"
                        class="search-bar"
                        :placeholder="$t('find-music')"
                      >
                    </label>
                    <div
                      class="container-music overflow-auto mt-2 mb-1"
                      @scroll="handleScrollMusicsContainer"
                    >
                      <div v-show="filteredMusics.length > 0">
                        <div
                          v-for="(music, index) in filteredMusics"
                          :key="music.id + index"
                          class="card card-music"
                          :class="{ 'selected-music' : selectedMusic?.id === music.id, 'mt-3' : index != 0 }"
                        >
                          <div class="container-fluid py-3 w-100" @click="selectedMusic = music">
                            <AudioPlayer
                              :src="music.src"
                              :name="music.name"
                              @play="(pauseAudio, audioId) => handlePlayAudioPlayer(pauseAudio, audioId)"
                            />
                          </div>
                        </div>
                        <!-- <div v-show="isLoadingGetMusics" class="w-100 my-3 text-center">
                          <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div> -->
                      </div>
                      <div v-if="filteredMusics.length === 0 && !isLoadingGetMusics">
                        <p class="mb-0 mt-5 pt-5 text-center">Music not found</p>
                      </div>
                      <div v-if="isLoadingGetMusics" class="d-flex align-items-center justify-content-center my-2">
                        <div class="spinner-border spinner-border-sm" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div v-if="isLoadingGetMusics" class="h-100 d-flex align-items-center justify-content-center my-2">
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <vs-tab label="Upload" disabled></vs-tab> -->
      </div>
    </div>
    <div class="container-fluid mt-3">
     <div class="float-right">
        <button class="btn btn-md btn-grey mr-2" @click="$bvModal.hide(id)">
          <p class="mb-0 mx-2">{{ $t('cancel') }}</p>
        </button>
        <button :disabled="!selectedMusic" class="btn btn__use-music btn-md text-white px-3" @click="$emit('use:music', selectedMusic)">
          <p class="mb-0 mx-2">{{ $t('use') }}</p>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { base_url_machine_learning } from '@/config/base_url';
import { getMasterMusicsAll, getMasterMusicsByClass } from "@/services/course/course.service";
import AudioPlayer from '@/components/audio-player/AudioPlayer';

export default {
  components: {
    AudioPlayer,
  },
  props: {
    id: {
      type: String,
      default: 'add-timeline-music',
    },
  },
  emits: ['use:music'],
  data() {
    return {
      musicTypes: [
        'All',
        'Acoustic',
        'Beat',
        'Blues',
        'Classic',
        'Country',
        'Fun',
        'Funky',
        'Hip Hop',
        'Indie',
        'Jazz',
        'Metal',
        'Middle East',
        'Reggae',
        'Rock',
        'Soft',
        'Techno',
      ],

      musicFilterType: [
        {
          title: 'Sound FX',
          isVisible: false,
          category: [
            'Applause',
            'Beach Waves',
            'Chirping Birds',
            'Creepy Tomb Ambiance',
            'Rainfall',
            'Thunderstorm',
            'Urban City',
            'Chirping Crickets',
            'Owl Hooting',
            'Scary Ghost',
            'Wolf Roar',
            'Animals',
            'Battle War'
          ]
        },
        {
          title: 'Genre',
          isVisible: false,
          category: [
            'Acoustic',
            'Beat',
            'Blues',
            'Classic',
            'Country',
            'Fun',
            'Funky',
            'Hip Hop',
            'Indie',
            'Jazz',
            'Metal',
            'Middle East',
            'Reggae',
            'Rock',
            'Soft',
            'Techno',
          ]
        }
      ],

      selectedMusicTypes: [],

      keyword: '',

      musics: [],
      isLoadingGetMusics: false,
      isLoadingScrollMusic: false,
      selectedMusic: null,

      totalPage: null,
      musicPage: 1,

      latestPlayingAudioId: null,
      pauseLatestPlayingAudio: null,
    };
  },
  computed: {
    filteredMusics() {
      if (!this.keyword) {
        return this.musics;
      }

      return this.musics.filter((music) => {
        return this.keyword
          .toLowerCase()
          .split(" ")
          .every((v) => music.name.toLowerCase().includes(v));
      });
    },
  },
  watch: {
    selectedMusicTypes: {
      handler() {
        if (this.pauseLatestPlayingAudio) {
          this.pauseLatestPlayingAudio();
        }

        this.musicPage = 1;
        this.musics = [];

        if (this.selectedMusicTypes.length > 0 && this.selectedMusicTypes[0] !== 'All') {
          this.getMusicsByType();
        } else {
          this.getMusics();
        }
      },
      immediate: true,
    },
    filteredMusics: {
      handler() {
        if (this.pauseLatestPlayingAudio) {
          this.pauseLatestPlayingAudio();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getMusics() {
      this.isLoadingGetMusics = true;

      // const params = {
      //   page: this.musicPage,
      // };

      try {
        const res = await getMasterMusicsAll();

        if (res.status) {
          this.musicPage++;
          this.totalPage = res.totalPage;

          for (const music of res.data) {
            this.musics.push({
              id: music._id.$oid,
              name: music.name,
              src: base_url_machine_learning + music.path.replace('src/', ''),
              duration: Number((music.duration).toFixed(2)),
              durationOrigin: Number((music.duration).toFixed(2)),
            });
          }
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoadingGetMusics = false;
      this.isLoadingScrollMusic = false
    },
    async getMusicsByType() {
      this.isLoadingGetMusics = true;

      const params = {
        page: 1,
        perPage: 20,
        class: this.selectedMusicTypes,
      };

      try {
        const res = await getMasterMusicsByClass(params);

        if (res.status) {
          for (const music of res.data) {
            this.musics.push({
              id: music._id.$oid,
              name: music.name,
              src: base_url_machine_learning + music.path.replace('src/', ''),
              duration: Number((music.duration).toFixed(2)),
              durationOrigin: Number((music.duration).toFixed(2)),
            });
          }
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoadingGetMusics = false;
    },
    handleShowModal() {
      this.selectedMusicTypes = [];
      this.selectedMusic = null;
    },
    async handleScrollMusicsContainer(e) {
      const isOnBottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
      const shouldLoadMore = !this.isLoadingGetMusics && (this.selectedMusicTypes.length === 0 || this.selectedMusicTypes[0] === 'All') && (this.totalPage === null || this.musicPage <= this.totalPage);

      if (isOnBottom && shouldLoadMore) {
        // await this.getMusics();
      }
    },
    async handlePlayAudioPlayer(pauseAudio, audioId) {
      const playMediaEvent = new CustomEvent('play:media', {
        detail: {
          componentId: audioId,
          name: 'add-timeline-music',
        },
      });

      document.dispatchEvent(playMediaEvent);

      this.latestPlayingAudioId = audioId;
      this.pauseLatestPlayingAudio = pauseAudio;
    },
    async handlePlayMedia(e) {
      if (e.detail.componentId !== this.latestPlayingAudioId && this.pauseLatestPlayingAudio) {
        await this.pauseLatestPlayingAudio();
      }

      if (!e.detail.name === 'add-timeline-music') {
        this.latestPlayingAudioId = null;
        this.pauseLatestPlayingAudio = null;
      }
    },
  },
  mounted() {
    document.addEventListener('play:media', this.handlePlayMedia);
  },
  beforeDestroy() {
    document.removeEventListener('play:media', this.handlePlayMedia);
  }
};
</script>

<style scoped>
.title-filter {
  color: #8C8C8C;
}

.hr-vertical {
  border-right: 1px solid #2D2D2D;
}

.gallery label {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 25px !important;
}

.gallery label:before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 1rem;
  width: 40px;
  background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
  background-size: 20px;
}

.search-bar {
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding-left: 48px !important;
  padding-right: 20px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid #2D2D2D;
  color: white;
}

.container-music {
  height: 30rem;
  padding-right: 1em;
}

.container-music::-webkit-scrollbar {
  width: 6px;
}

.container-music::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

.container-music::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  background-clip: padding-box;
  border-radius: 4px;
}

.container-music::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.card-music {
  background-color: #2D2D2D;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.25s;
}

.card-music:not(.selected-music):hover {
  border-color: #6D75F633;
}

.selected-music {
  border-color: #6D75F6;
}

.btn-close {
  width: 15px;
  height: 26px;
  background-color: #EDEDED;
  border-radius: 50%;
}

.close-image {
  right: 5px;
  z-index: 100;
}

.btn-grey {
  background-color: #2D2D2D !important;
  border: 0px !important;
  color: white;
  transition: background-color 0.5s;
}

.btn-grey:hover {
  background-color: #414141 !important;
}

.btn__use-music {
  background-color: #6D75F6;
  color: white;
}

.btn__use-music:not(:disabled):hover {
  background-color: #545EED;
  border-color: #545EED;
}

.btn__use-music:disabled {
  background-color: #2D2D2D;
  border-color: #2D2D2D;
  color: #585859;
}

.header__title {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.main__atm {
  border-top: 0.1px solid #2D2D2D;
  border-bottom: 0.1px solid #2D2D2D;
}

.mf__text {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mf-card__toggle-chevron-icon {
  color: #FFFFFF;
  font-size: 14px;
  transition: rotate 0.25s;
}

.mf-card__toggle-chevron-icon--active {
  rotate: -90deg;
}

.music-filter__wrapper {
  max-height: 31rem;
  overflow-y: auto;
}
</style>

<style>
.vs-checkbox {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  transform: rotate(-90deg);
  cursor: pointer;
  overflow: hidden;
  transition: all .2s ease;
}

.vs-checkbox--check .vs-icon, .vs-input--icon {
  font-size: 0.8rem;
}
</style>

<style>
.add-timeline-music__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.add-timeline-music__dialog {
	max-width: none;
}

.add-timeline-music__content {
	width: 1000px;
}
</style>
