<template>
  <div class="code-editor d-flex flex-column overflow-hidden">
    <header class="code-editor__header px-4 py-2">
      <h3 class="code-editor__header-text">{{ title }}</h3>
    </header>
    <PrismEditor
      v-model="code"
      :highlight="highlighter"
      :tabSize="tabSize"
      :readonly="readonly"
      @input="(newCode) => $emit('input', newCode)"
    />
  </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs-components-importer/esm';

import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/themes/prism-okaidia.css';

export default {
  components: {
    PrismEditor,
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    tabSize: {
      type: Number,
      default: 4,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Code Editor',
    },
  },
  emits: ['input'],
  data() {
    return {
      code: this.value,
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages[this.language], this.language);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.code = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.code-editor {
  height: 100%;
  border-radius: 0.5rem;
}

.code-editor__header {
  background-color: #2d2d2d;
}

.code-editor__header-text {
  font-size: 0.8rem;
  font-weight: 400;
}
</style>

<style>
.prism-editor-wrapper .prism-editor__container {
  background-color: #000;
  max-height: 150px;
  overflow-y: auto;
}

.prism-editor-wrapper :is(.prism-editor__textarea, .prism-editor__editor) {
  padding: 1rem 2rem;
}

.prism-editor-wrapper .prism-editor__editor {
  color: #fff;
}

.token.operator {
  background-color: transparent;
}
</style>
