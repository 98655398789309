<template>
  <ModalContainer
    :name="name"
    title="Quiz Settings"
    width="800"
    :disable-save="!quizData.quizName || !quizData.topic"
    @closed="cancel()"
    @save="$emit('input', quizData); saveToLocal()"
  >
    <div class="sm__body d-flex justify-content-between align-items-start px-2">
      <div class="sm__form d-flex flex-column">
        <div class="sm__form-group d-flex flex-column" title="Quiz Name">
          <label
            for="input-quiz-name"
            class="sm__form-label m-0"
            :class="{ 'sm__form-label--error': !quizData.quizName }"
          >
            Quiz Name*
          </label>
          <input
            type="text"
            id="input-quiz-name"
            v-model="quizData.quizName"
            class="sm__form-input"
            :class="{ 'sm__form-input--error': !quizData.quizName }"
            spellcheck="false"
            required
          />
        </div>

        <div class="sm__form-group d-flex flex-column" title="Description">
          <label
            for="input-description"
            class="sm__form-label m-0"
          >
            Description
          </label>
          <textarea
            id="input-description"
            v-model="quizData.description"
            class="sm__form-textarea"
            spellcheck="false"
          />
        </div>

        <div class="sm__form-group d-flex flex-column" title="Topics">
          <label
            for="input-topics"
            class="sm__form-label m-0"
            :class="{ 'sm__form-label--error': !quizData.topic }"
          >
            Topics*
          </label>
          <select
            id="input-topics"
            v-model="quizData.topic"
            class="sm__form-select"
            :class="{ 'sm__form-select--error': !quizData.topic }"
            required
          >
            <option v-if="categories.length == 0">No data to show</option>
            <option v-else v-for="(val, index) in categories" :key="index" :value="val._id" class="sm__form-option">{{ val.category_name }}</option>
          </select>
        </div>

        <div class="sm__form-group d-flex flex-column" title="Grade Level">
          <label
            for="input-grade-level"
            class="sm__form-label m-0"
          >
            Grade level
          </label>
          <select
            id="input-grade-level"
            class="sm__form-select"
            v-model="quizData.gradeLevel"
          >
            <option v-if="options.gradeLevels.length == 0">No data to show</option>
            <option v-else v-for="(val, index) in options.gradeLevels" :key="index" :value="val.id" class="sm__form-option">{{ val.name }}</option>
          </select>
        </div>

        <div v-if="options.gradeLevels?.find((gradeLevel) => gradeLevel.id === quizData.gradeLevel)?.subLevels.length > 0" class="sm__form-group d-flex flex-column" title="Sub Level">
          <label class="sm__form-label m-0">Sub level</label>
          <ul class="sm__form-sub-level-list d-flex m-0 list-unstyled">
            <li v-for="subLevel in options.gradeLevels?.find((gradeLevel) => gradeLevel.id === quizData.gradeLevel)?.subLevels" :key="subLevel.id">
              <button
                type="button"
                class="sm__form-sub-level-button btn"
                :class="{ 'sm__form-sub-level-button--active': subLevel.id === quizData.subLevel }"
                :title="subLevel"
                @click="quizData.subLevel = subLevel.id"
              >
                {{ subLevel.name }}
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div class="sm__form-group d-flex flex-column" title="Cover Image">
        <label class="sm__form-label m-0">Cover Image*</label>
        <button
          type="button"
          class="sm__cover-button btn position-relative p-0"
          @click="$bvModal.show(`gallery-modal-${_uid}`)"
        >
          <img :src="quizData.coverUrl || require('@/assets/images/background/cover_image.png')" alt="" class="sm__cover-image" />
          <span class="sm__cover-text position-absolute d-flex justify-content-center align-items-center">Tap to<br />change image</span>
        </button>
      </div>
    </div>

    <AddImageModal :name="`gallery-modal-${_uid}`" v-model="quizData.coverUrl" />
    <Gallery :name="`gallery-modal-${_uid}`" v-model="quizData.coverUrl" />
  </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';
import AddImageModal from './AddImageModal';
import Gallery from './Gallery.vue';

export default {
  components: {
    ModalContainer,
    AddImageModal,
    Gallery,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    name: {
      type: String,
      default: 'save-modal',
    },
    categories: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  emits: ['input'],
  data() {
    return {
      quizData: this.value,
    };
  },
  methods: {
    saveToLocal(){
      localStorage.quiz_name = this.quizData.quizName
      localStorage.quiz_description = this.quizData.description
      localStorage.images_cover = this.quizData.coverUrl
      localStorage.category_id = this.quizData.topic
      localStorage.grade = this.quizData.gradeLevel
      localStorage.sub_grade = this.quizData.subLevel
    },

    cancel(){
      if(localStorage.quiz_name) {
        this.value.quizName = localStorage.quiz_name
      }

      if(localStorage.quiz_description) {
        this.value.description = localStorage.quiz_description
      }

      if(localStorage.images_cover) {
        this.value.coverUrl = localStorage.images_cover
      }

      if(localStorage.category_id) {
        this.value.topic = localStorage.category_id
      }

      if(localStorage.grade) {
        this.value.gradeLevel = localStorage.grade
      }

      if(localStorage.sub_grade) {
        this.value.subLevel = localStorage.sub_grade
      }

      this.quizData = { ...this.value }
    }
  },
};
</script>

<style scoped>
.sm__body {
  gap: 16px;
}

.sm__form {
  gap: 11px;
}

.sm__form-group {
  gap: 5px;
}

.sm__form-label {
  transition: color 0.25s;
}

.sm__form-label--error {
  color: #F34D4D;
}

.sm__form-input, .sm__form-select, .sm__form-textarea {
  width: 370px;
  padding: 12px 18px;
  background: none;
  border: 1px solid #585859;
  border-radius: 7px;
  color: #FFFFFF;
  transition: border 0.25s;
}

.sm__form-textarea {
  height: 75px;
  resize: none;
}

:is(
  .sm__form-input:not(.sm__form-input--error),
  .sm__form-select:not(.sm__form-select--error),
  .sm__form-textarea:not(.sm__form-textarea--eror)
  ):focus {
  border-color: #888888;
}

.sm__form-input--error, .sm__form-select--error, .sm__form-textarea--error {
  border-color: #F34D4D;
}

.sm__form-option {
  background-color: #2D2D2D;
  color: #FFFFFF;
}

.sm__form-sub-level-list {
  gap: 8px;
}

.sm__form-sub-level-button {
  padding: 6px 20px;
  background-color: #2D2D2D;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  transition: background-color 0.25s;
}

.sm__form-sub-level-button:not(.sm__form-sub-level-button--active):hover {
  background-color: #3D3D3D;
}

.sm__form-sub-level-button--active {
  background-color: #6D75F6;
}

.sm__cover-button {
  background: none;
  border-radius: 8px;
  overflow: hidden;
}

.sm__cover-image {
  width: 300px;
  height: 190px;
  object-fit: cover;
  object-position: center;
}

.sm__cover-text {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  transition: background-color 0.5s;
}

.sm__cover-button:hover .sm__cover-text {
  background-color: rgba(0, 0, 0, 0.6);
}
</style>
