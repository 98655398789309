<template>
  <ul
    class="toolbar"
    :class="{
      'toolbar--normal': type === 'normal',
      'toolbar--minimal': type === 'minimal',
    }"
  >
    <slot />
  </ul>
</template>

<script>
export default {
  props: {
    type: {
      validator(value) {
        return ['normal', 'minimal'].includes(value);
      },
      default: 'normal',
    },
  },
};
</script>

<style scoped>
.toolbar {
  display: flex;
  align-items: center;
  margin: 0;
  list-style: none;
}

.toolbar--normal {
  gap: 0.25rem;
}

.toolbar--minimal {
  gap: 0.3rem;
  margin-left: 0.5rem;
}
</style>
